<template lang="html">
    <div class="SignedIn" />
</template>

<script setup>
import {
    onBeforeMount,
    onMounted
} from 'vue'
import { useStore } from 'vuex'
import * as mainMutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import emitter from 'global-emitter'
import { isEmpty } from 'global-utils'
import { useRouter } from 'vue-router'
import LoginManager from 'user/Login/managers/LoginManager'
import config from 'config'
import { useLogin } from '@/StoreWeb/js/composables/login-utils'

const store = useStore()
const router = useRouter()
const { isAuthenticated } = useLogin()

onBeforeMount(() => {
    emitter.on('invalidLoginCode', () => {
        store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
        router.push({ name: 'home' })
    })
})

onMounted(() => {
    if (!config.use_web_harmony) {
        if (!isAuthenticated.value) {
            LoginManager.checkAuthorizationCodeAction()
            store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
        } else {
            const redirectToLink = LoginManager.getLocalStorageItem('redirectTo')
            store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })

            if (!isEmpty(redirectTo)) {
                localStorage.removeItem('redirectTo')
                redirectTo(redirectToLink)
            } else {
                router.push({ name: 'home' })
            }
        }
    }
})

function redirectTo (redirectTo) {
    if (typeof redirectTo === 'string') {
        window.location.href = redirectTo
    } else {
        router.push(redirectTo)
    }
}
</script>
