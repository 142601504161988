import * as actionTypes from './action-types'
import CatalogManager from '@/StoreWeb/managers/CatalogManagerSingleton'
const catalogManager = CatalogManager.getInstance()

export default {
    /**
     * Check product compatibility with selected support
     * @param params
     */
    [actionTypes.CHECK_COMPATIBILITY] (...args) {
        const [, params] = args
        return catalogManager.checkCompatibilityAction(params)
    },
    /**
     * Get catalog
     * @param params
     */
    [actionTypes.GET_CATALOG] (...args) {
        const [, params] = args
        return catalogManager.getCatalogAction(params)
    },
    /**
     * Get catalog by products
     * @param params
     */
    [actionTypes.GET_CATALOG_BY_PRODUCTS] (...args) {
        const [, params] = args
        return catalogManager.getCatalogByProductIdsAction(params)
    },
    /**
     * Get provided fare media's catalog
     * @param params
     */
    [actionTypes.GET_FARE_MEDIA_CATALOG] (...args) {
        const [, params] = args
        catalogManager.getFareMediaCatalogAction(params)
    },
    /**
     * Get fareMedia type products
     * @param params
     */
    [actionTypes.GET_FARE_MEDIAS] (...args) {
        const [, params] = args
        return catalogManager.getFareMediasAction(params)
    },
    /**
     * Get fine type products
     * @param params
     */
    [actionTypes.GET_FINES] (...args) {
        const [, params] = args
        catalogManager.getFinesAction(params)
    },
    /**
     * Get list by parameter name
     * @param params
     */
    [actionTypes.GET_LIST_BY_PARAMETER_NAME] (...args) {
        const [, params] = args
        return catalogManager.getListByParameterName(params)
    },
    /**
     * Get quotations
     * @param params
     */
    [actionTypes.GET_QUOTATIONS] (...args) {
        const [, params] = args
        return catalogManager.getQuotationsAction(params)
    },
    /**
     * Load product details
     * @param params
     */
    [actionTypes.LOAD_PRODUCT_DETAILS] (...args) {
        const [, params] = args
        catalogManager.loadProductDetailsAction(params)
    }
}
