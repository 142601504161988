export default class {
    constructor (
        id, name
    ) {
        this._id = id
        this._name = name
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get name () {
        return this._name
    }

    set name (name) {
        this._name = name
    }

    toString () {
        return this.name
    }

    toJSON () {
        return {
            id: this.id,
            name: this.name
        }
    }
}
