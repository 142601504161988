import i18n from 'i18n'

export default class {
    constructor () {
        this._externalStatus = ''
        this._invoiceId = ''
        this._lastUpdate = ''
        this._providerOrderId = ''
    }

    get externalStatus () {
        return this._externalStatus
    }

    set externalStatus (externalStatus) {
        this._externalStatus = externalStatus
    }

    get invoiceId () {
        return this._invoiceId
    }

    set invoiceId (invoiceId) {
        this._invoiceId = invoiceId
    }

    get lastUpdate () {
        return this._lastUpdate
    }

    set lastUpdate (lastUpdate) {
        this._lastUpdate = lastUpdate
    }

    get providerOrderId () {
        return this._providerOrderId
    }

    set providerOrderId (providerOrderId) {
        this._providerOrderId = providerOrderId
    }

    getStatusLabel () {
        return i18n.global.t('basket:external_status:' + this.externalStatus.toLowerCase())
    }

    toJSON () {
        return {
            externalStatus: this.externalStatus,
            invoiceId: this.invoiceId,
            lastUpdate: this.lastUpdate,
            providerOrderId: this.providerOrderId
        }
    }
}
