import HistoryBasket from '@/StoreWeb/models/user/order/HistoryBasket'
import HistoryProductNormalizer from '@/StoreWeb/normalizers/user/order/HistoryProductNormalizerSingleton'
import HistoryBasketExternalStatusNormalizer from '@/StoreWeb/normalizers/user/order/HistoryBasketExternalStatusNormalizerSingleton'
import RecipientUserNormalizer from '@/StoreWeb/normalizers/user/RecipientUserNormalizerSingleton'
import { isEmpty } from 'global-utils'

const historyBasketExternalStatusNormalizer = HistoryBasketExternalStatusNormalizer.getInstance()
const historyProductNormalizer = HistoryProductNormalizer.getInstance()
const recipientUserNormalizer = RecipientUserNormalizer.getInstance()

export default class {
    normalize (item) {
        const historyBasket = new HistoryBasket()

        historyBasket.accountId = item.accountId ?? ''
        historyBasket.accountUserFirstName = item.accountUserFirstName ?? ''
        historyBasket.accountUserLastName = item.accountUserLastName ?? ''
        historyBasket.basketInfo = item.basketInfo ?? ''
        historyBasket.basketType = item.basketType ?? ''
        if (!isEmpty(item.externalStatus)) {
            historyBasket.externalStatus = historyBasketExternalStatusNormalizer.normalize(item.externalStatus)
        }
        historyBasket.orderId = item.orderId ?? ''
        historyBasket.products = historyProductNormalizer.normalizeArray(item.products)
        historyBasket.providerBasketId = item.providerBasketId ?? ''
        historyBasket.providerFareMediaId = item.providerFareMediaId ?? ''
        historyBasket.providerId = item.providerId ?? ''
        historyBasket.providerUserId = item.providerUserId ?? ''
        historyBasket.providerUserFirstName = item.providerUserFirstName ?? ''
        historyBasket.providerUserLastName = item.providerUserLastName ?? ''
        historyBasket.recipientUser = recipientUserNormalizer.normalize(item.recipientUser)
        historyBasket.status = item.status ?? ''
        historyBasket.updateDate = item.updateDate ?? ''

        return historyBasket
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
