import store from '@/StoreWeb/store/store'
import { useRoute, useRouter } from 'vue-router'
import * as applicationSessionMutationTypes from '@/StoreWeb/store/modules/application-session/mutation-types'
import * as applicationSessionActionTypes from '@/StoreWeb/store/modules/application-session/action-types'
import * as externalActionTypes from '@/StoreWeb/store/modules/application-session/external-action-types'
import * as internalActionTypes from '@/StoreWeb/store/modules/application-session/internal-action-types'
import AddToCartFlowManager from '@/StoreWeb/managers/AddToCartFlowManagerSingleton'
import { isEmpty } from 'global-utils'
import { computed, ref } from 'vue'
import { startLogInProcess } from '@/StoreWeb/js/composables/login-utils'

export default function () {
    const router = useRouter()
    const route = useRoute()

    const addToCartFlowManager = ref(AddToCartFlowManager.getInstance())

    const isMobileApp = computed(() => store.state.isMobileApp)
    const applicationSession = computed(() => store.state.applicationSessionModule.applicationSession)

    const storeAddCartFlow = async (params) => {
        if (
            isEmpty(addToCartFlowManager.value.product) ||
            (
                params?.product?.id &&
                addToCartFlowManager.value.product.id !== params.product.id
            )
        ) {
            addToCartFlowManager.value.initAddCartFlow(params.product)
        }
        addToCartFlowManager.value.setAddToCartFlowStateToLocalStorage()
    }

    const triggerLogin = async (params) => {
        if (isMobileApp.value) {
            let baseUrl
            if (!isEmpty(params) && !isEmpty(params.mode) && params.mode === 'PRODUCT_DETAILS') {
                baseUrl = `${window.location.origin}/internalLinks/${internalActionTypes.GO_TO_PRODUCT_DETAILS}?sessionId=${applicationSession.value.id}&initialRequest=${externalActionTypes.LOG_IN}`
            } else {
                baseUrl = `${window.location.origin}/internalLinks/${internalActionTypes.GO_TO_CATALOG}?sessionId=${applicationSession.value.id}&initialRequest=${externalActionTypes.LOG_IN}`
            }

            applicationSession.value.data.addToCartFlow = localStorage.getItem('addToCartFlow')
            applicationSession.value.sessionContext = {
                currentStep: baseUrl,
                resumeStepSuccess: `${baseUrl}&status=success`,
                resumeStepError: `${baseUrl}&status=error`
            }

            store.commit(applicationSessionMutationTypes.SET_APPLICATION_SESSION, applicationSession.value)
            await store.dispatch(applicationSessionActionTypes.UPDATE_APPLICATION_SESSION)
            const externalLink = router.resolve({ name: 'externalLinks', params: { action: externalActionTypes.LOG_IN }, query: { sessionId: applicationSession.value.id } })
            if (!isEmpty(externalLink)) {
                window.location.href = window.location.origin + externalLink.href
            }
        } else {
            startLogInProcess(params.route || route)
        }

        if (params.callback) {
            params.callback()
        }
    }

    return {
        addToCartFlowManager,
        applicationSession,
        isMobileApp,
        storeAddCartFlow,
        triggerLogin
    }
}
