import UserAction from '@/StoreWeb/models/user/wallet/UserAction'
import { isEmpty } from 'global-utils'

export default class {
    normalize (item) {
        const userAction = new UserAction()

        userAction.category = item.category
        userAction.disabled = item.disabled ?? false
        userAction.external = item.external ?? true
        userAction.label = item.label
        userAction.parameters = item.parameters ?? []
        userAction.type = item.type

        return userAction
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
