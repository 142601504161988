import { isEmpty } from 'global-utils'
import i18n from '@/StoreWeb/i18n'
import { numeric, regex, required } from '@/StoreWeb/utils/i18n-validators'
import { helpers } from '@vuelidate/validators'

export const getFormRules = (template) => {
    const rules = {}

    if (!isEmpty(template) && !isEmpty(template.fields)) {
        template.fields.forEach(field => {
            rules[field.id] = {}

            if (field.required) {
                rules[field.id].required = required
            }

            if (field.params.pattern) {
                rules[field.id].regexCustom = helpers.withMessage(
                    () => {
                        const key = field.params.patternValidationMessage
                        let error = key ? i18n.global.t(key) : ''

                        if (isEmpty(error)) {
                            error = i18n.global.t('validations:regex')
                        }

                        return error
                    },
                    regex(new RegExp(field.params.pattern))
                )
            }

            if (field.params.subType === 'number') {
                rules[field.id].numeric = numeric
            }

            if (!isEmpty(field.params.list) && !isEmpty(field.value)) {
                const items = field.params.list.map(item => item.id)
                const isBelongingToList = (value) => items.includes(value)
                const error = i18n.global.t('validations.mustBeInList')

                rules[field.id].mustBeInList = helpers.withMessage(error, isBelongingToList)
            }
        })
    }

    return rules
}
