import * as mutationTypes from './mutation-types'
import i18n from 'i18n'

export default {
    [mutationTypes.SET_CURRENT_ROUTE] (state, currentRoute) {
        state.currentRoute = currentRoute
    },
    [mutationTypes.SET_CURRENT_LANGUAGE] (state, currentLanguage) {
        i18n.locale = currentLanguage
        i18n.global.locale = currentLanguage
        state.currentLanguage = currentLanguage
    },
    [mutationTypes.SET_FEEDBACK_ERROR] (state, feedbackError) {
        state.feedbackError = feedbackError
        state.showErrorToast = true
    },
    [mutationTypes.SET_FEEDBACK_SUCCESS] (state, feedbackSuccess) {
        state.feedbackSuccess = feedbackSuccess
        state.showSuccessToast = true
    },
    [mutationTypes.SET_SHOW_SUCCESS_TOAST] (state, showToast) {
        state.showSuccessToast = showToast
    },
    [mutationTypes.SET_SHOW_ERROR_TOAST] (state, showToast) {
        state.showErrorToast = showToast
    },
    [mutationTypes.SET_IS_CHECK_ACCOUNT_ENABLED] (state, isCheckAccountEnabled) {
        state.isCheckAccountEnabled = isCheckAccountEnabled
    },
    [mutationTypes.SET_IS_LOGIN_MODULE_LOADED] (state, isLoginModuleLoaded) {
        state.isLoginModuleLoaded = isLoginModuleLoaded
    },
    [mutationTypes.SET_IS_MOBILE_APP] (state, isMobileApp) {
        state.isMobileApp = isMobileApp
    },
    [mutationTypes.SET_IS_MOBILE_OR_TABLET] (state, isMobileOrTablet) {
        state.isMobileOrTablet = isMobileOrTablet
    },
    [mutationTypes.SET_MAIN_COLOR] (state, mainColor) {
        state.mainColor = mainColor
    },
    [mutationTypes.SET_MAIN_COLOR_DARK] (state, mainColorDark) {
        state.mainColorDark = mainColorDark
    },
    [mutationTypes.SET_MAIN_COLOR_LIGHT] (state, mainColorLight) {
        state.mainColorLight = mainColorLight
    },
    [mutationTypes.SET_PAGE_CONFIG] (state, pageConfig) {
        state.pageConfig = pageConfig
    },
    [mutationTypes.SET_PENDING_REQUEST] (state, pendingRequest) {
        if (state.pendingRequests[pendingRequest.key] !== undefined) {
            state.pendingRequests[pendingRequest.key] = pendingRequest.value
        }
    },
    [mutationTypes.SET_SHOW_GLOBAL_LOADER] (state, showGlobalLoader) {
        const { keepShow, resetTexts, title, subtitle, value } = showGlobalLoader
        const current = state.showGlobalLoader

        typeof keepShow !== 'undefined' && (current.keepShow = keepShow)

        const keepTexts = !!(current.keepShow === true && (title || subtitle))

        current.title = resetTexts ? undefined : keepTexts ? title : current.title || undefined
        current.subtitle = resetTexts ? undefined : keepTexts ? subtitle : current.subtitle || undefined
        current.value = current.keepShow ? true : value

        state.showGlobalLoader = structuredClone(current)
    },
    [mutationTypes.SET_SHOW_MAIN_NAV] (state, showMainNav) {
        state.showMainNav = showMainNav
    },
    [mutationTypes.SET_SHOW_MODAL] (state, showModal) {
        state.showModal = showModal
    }
}
