export default class {
    constructor (
        type
    ) {
        this._category = ''
        this._disabled = false
        this._external = true
        this._label = ''
        this._parameters = []
        this._type = type
    }

    get category () {
        return this._category
    }

    set category (category) {
        this._category = category
    }

    get disabled () {
        return this._disabled
    }

    set disabled (disabled) {
        this._disabled = disabled
    }

    get external () {
        return this._external
    }

    set external (external) {
        this._external = external
    }

    get label () {
        return this._label
    }

    set label (label) {
        this._label = label
    }

    get parameters () {
        return this._parameters
    }

    set parameters (parameters) {
        this._parameters = parameters
    }

    get type () {
        return this._type
    }

    set type (type) {
        this._type = type
    }

    toJSON () {
        return {
            category: this.category,
            disabled: this.disabled,
            external: this.external,
            label: this.label,
            parameters: this.parameters,
            type: this.type
        }
    }
}
