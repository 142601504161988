<template>
    <main class="OrderSuccess">
        <div class="Container OrderSuccess-container">
            <finalized-order
                class="fadeInAfterIcon"
                :title="$t('payment:order:success:title')"
            />
        </div>
    </main>
</template>

<script setup>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import * as userActionTypes from '@/StoreWeb/store/modules/user/action-types'
import * as cartMutationTypes from '@/StoreWeb/store/modules/cart/mutation-types'
import FinalizedOrder from '@/StoreWeb/components/checkout/FinalizedOrder'
import { useLogin } from '@/StoreWeb/js/composables/login-utils'

const store = useStore()
const { isAuthenticated } = useLogin()

onMounted(() => {
    if (isAuthenticated.value) {
        store.dispatch(userActionTypes.GET_WALLET, {
            wsOptions: {
                cacheAsync: false,
                cacheValidity: 0
            },
            cachiosOptions: {
                force: true
            }
        })
    } else {
        store.commit(cartMutationTypes.SET_IS_GUEST_PURCHASE_FLOW, false)
    }
})
</script>

<style lang='scss' scoped>
@import 'globalScss';

.OrderSuccess {
    background: #fff;
    text-align: center;

    &-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: 80px;
    }
}
</style>
