export default class {
    constructor () {
        this._credit = 0
        this._debit = 0
        this._externalId = ''
        this._operation = ''
        this._paymentStatus = ''
        this._paymentUpdateDate = ''
        this._providerId = ''
    }

    get credit () {
        return this._credit
    }

    set credit (credit) {
        this._credit = credit
    }

    get debit () {
        return this._debit
    }

    set debit (debit) {
        this._debit = debit
    }

    get externalId () {
        return this._externalId
    }

    set externalId (externalId) {
        this._externalId = externalId
    }

    get operation () {
        return this._operation
    }

    set operation (operation) {
        this._operation = operation
    }

    get paymentStatus () {
        return this._paymentStatus
    }

    set paymentStatus (paymentStatus) {
        this._paymentStatus = paymentStatus
    }

    get paymentUpdateDate () {
        return this._paymentUpdateDate
    }

    set paymentUpdateDate (paymentUpdateDate) {
        this._paymentUpdateDate = paymentUpdateDate
    }

    get providerId () {
        return this._providerId
    }

    set providerId (providerId) {
        this._providerId = providerId
    }

    toJSON () {
        return {
            credit: this.credit,
            debit: this.debit,
            externalId: this.externalId,
            operation: this.operation,
            paymentStatus: this.paymentStatus,
            paymentUpdateDate: this.paymentUpdateDate,
            providerId: this.providerId
        }
    }
}
