<template>
    <div class="OrderDetails">
        <div class="Container OrderDetails-container">
            <p class="OrderDetails-back">
                <router-link
                    :to="{ name: 'purchases', params: { page: orderPage } }"
                    class="OrderDetails-backButton"
                >
                    {{ $t('button:back') }}
                </router-link>
            </p>
            <div
                v-if="getShowOrderLoader"
                class="text-center mt-5"
            >
                <v-progress-circular
                    indeterminate
                    color="primary"
                />
            </div>
            <div v-else-if="selectedOrder !== null">
                <h1 class="h1">
                    {{ getSelectedOrderTitle }}
                </h1>
                <p
                    v-if="$config.features.orders.id_display === 'both' && getSelectedOrderExternalId !== ''"
                    class="mb-5 text-body-1 font-weight-bold text-medium-emphasis"
                >
                    {{ getSelectedOrderExternalId }}
                </p>
                <div
                    v-if="getSelectedOrderStatus"
                    class="mb-6"
                >
                    <v-chip class="OrderDetails-status text-body-1 font-weight-bold">
                        {{ getSelectedOrderStatus }}
                    </v-chip>
                </div>
                <order-details
                    v-if="selectedOrder !== null"
                    :order="selectedOrder"
                />
            </div>
            <alert-message
                v-else-if="getOrderNotFoundMessage !== ''"
                :message="getOrderNotFoundMessage"
                type="error"
                class="OrderDetails-noFoundAlert"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import DefaultView from '@/StoreWeb/views/DefaultView'
import PageConfigManager from '@/StoreWeb/managers/PageConfigManagerSingleton'
import OrderDetails from '@/StoreWeb/components/account/order/OrderDetails'
import OrderNormalizer from '@/StoreWeb/normalizers/user/order/OrderNormalizerSingleton'
import AlertMessage from '@/StoreWeb/components/common/AlertMessage'
import * as userActionTypes from '@/StoreWeb/store/modules/user/action-types'
import emitter from 'global-emitter'

export default {
    components: {
        AlertMessage,
        OrderDetails
    },
    extends: DefaultView,
    data () {
        return {
            id: this.$route.params.id,
            order: this.selectedOrder !== null ? this.selectedOrder : null,
            orderErrorMessage: '',
            orderNormalizer: OrderNormalizer.getInstance(),
            pageConfigManager: PageConfigManager.getInstance()
        }
    },
    computed: {
        ...mapState({
            selectedOrder: state => state.userModule.selectedOrder,
            orderPage: state => state.userModule.orderPage,
            userPendingRequests: state => state.userModule.userPendingRequests
        }),
        getOrderNotFoundMessage () {
            return this.orderErrorMessage
        },
        getSelectedOrderStatus () {
            return this.selectedOrder !== null ? this.selectedOrder.getStatus() : ''
        },
        getSelectedOrderTitle () {
            return this.selectedOrder !== null ? this.selectedOrder.getTitle() : ''
        },
        getSelectedOrderExternalId () {
            return this.selectedOrder !== null ? this.selectedOrder.getExternalId() : ''
        },
        getShowOrderLoader () {
            return this.userPendingRequests.getOrder
        }
    },
    onMounted () {
        this.pageConfigManager.setConfig({
            title: 'account:order_details:title',
            currentAccountPage: 'orderDetails'
        })
    },
    mounted () {
        emitter.on('orderLoaded', this.onOrderSuccessHandler)
        emitter.on('orderLoadedError', this.onOrderErrorHandler)
        this.$store.dispatch(userActionTypes.GET_ORDER, {
            orderId: this.id,
            orderPage: this.orderPage
        })
    },
    unmounted () {
        emitter.off('orderLoaded', this.onOrderSuccessHandler)
        emitter.off('orderLoadedError', this.onOrderErrorHandler)
    },
    methods: {
        onOrderErrorHandler (errorMessage) {
            this.orderErrorMessage = errorMessage
        },
        onOrderSuccessHandler () {
            this.orderErrorMessage = ''
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.OrderDetails {
    background: $color-lightest;

    &-backButton {
        font-size: 14px;
        font-weight: bold;
    }

    &-status {
        background-color: $color-brandSecondaryLightBackground !important;
        color: $color-brandSecondary !important;
    }
}
</style>
