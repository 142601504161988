import HistoryProduct from '@/StoreWeb/models/user/order/HistoryProduct'
import ProductParameterFieldNormalizer from '@/StoreWeb/normalizers/common/ProductParameterFieldNormalizerSingleton'
import StoredDocumentNormalizer from '@/StoreWeb/normalizers/common/StoredDocumentNormalizerSingleton'
import HistoryProductModalCounterNormalizer from '@/StoreWeb/normalizers/user/order/HistoryProductModalCounterNormalizerSingleton'
import HistoryProductModalProductNormalizer from '@/StoreWeb/normalizers/user/order/HistoryProductModalProductNormalizerSingleton'
import { isEmpty } from 'global-utils'

const historyProductModalProductNormalizer = HistoryProductModalProductNormalizer.getInstance()
const historyProductModalCounterNormalizer = HistoryProductModalCounterNormalizer.getInstance()
const productParameterFieldNormalizer = ProductParameterFieldNormalizer.getInstance()
const storedDocumentNormalizer = StoredDocumentNormalizer.getInstance()

export default class {
    normalize (item) {
        const historyProduct = new HistoryProduct()

        historyProduct.address = item.address
        historyProduct.amount = item.amount
        historyProduct.fareExcludingTaxes = item.fareExcludingTaxes
        historyProduct.fareIncludingTaxes = item.fareIncludingTaxes
        if (!isEmpty(item.modalCounter)) {
            historyProduct.modalCounter = historyProductModalCounterNormalizer.normalize(item.modalCounter)
            historyProduct.id = historyProduct.modalCounter.id
        }
        historyProduct.modalCounterTotalValue = item.modalCounterTotalValue
        historyProduct.modalCounterValue = item.modalCounterValue
        if (!isEmpty(item.modalProduct)) {
            historyProduct.modalProduct = historyProductModalProductNormalizer.normalize(item.modalProduct)
            historyProduct.id = historyProduct.modalProduct.id
        }
        historyProduct.name = item.modalProduct.name
        historyProduct.productParameters = productParameterFieldNormalizer.normalizeArray(item.productParameters)
        historyProduct.providerFinePaymentId = item.providerFinePaymentId
        historyProduct.providerItemDescription = item.providerItemDescription
        historyProduct.providerNetworkLabel = item.providerNetworkLabel
        historyProduct.quantity = item.quantity
        historyProduct.storedDocuments = storedDocumentNormalizer.normalizeArray(item.storedDocuments)
        historyProduct.technicalProductExternalId = item.technicalProductExternalId
        historyProduct.totalFareExcludingTaxes = item.totalFareExcludingTaxes
        historyProduct.totalFareIncludingTaxes = item.totalFareIncludingTaxes
        historyProduct.totalVatAmount = item.totalVatAmount
        historyProduct.vatAmout = item.vatAmout
        historyProduct.vatRate = item.vatRate
        historyProduct.version = item.modalProduct.version

        return historyProduct
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
