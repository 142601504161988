<template>
    <transition
        name="fade"
        class="GlobalLoader"
        mode="out-in"
        @:after-leave="animationFinished"
    >
        <div
            v-show="showGlobalLoader"
            class="GlobalLoader-mask"
        >
            <div class="GlobalLoader-container">
                <div
                    v-if="title || subtitle"
                    class="GlobalLoader-texts"
                >
                    <h1
                        v-if="title"
                        class="GlobalLoader-title"
                    >
                        {{ title }}
                    </h1>
                    <h2
                        v-if="subtitle"
                        class="GlobalLoader-subtitle"
                    >
                        {{ subtitle }}
                    </h2>
                </div>
                <svg>
                    <circle
                        class="GlobalLoader-loader"
                        r="30"
                        cx="50%"
                        cy="50%"
                    />
                </svg>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        showGlobalLoader: {
            type: Boolean,
            default: false
        },
        subtitle: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        }
    },
    methods: {
        animationFinished () {
            this.$emit('animationFinished')
        }
    }
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

.GlobalLoader {
    &-mask {
        @include containerMask;

        z-index: 10000;
    }

    &-container {
        @include verticalCenter;

        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    &-texts {
        text-align: center;
        color: $color-white;
    }

    &-title {
        margin-bottom: $s4;
        font-size: $font-large;
    }

    &-subtitle {
        font-size: $font-medium;
    }

    &-loader {
        fill: none;
        stroke-width: 5;
        stroke: $color-white;
        animation: loader-animation 3s infinite ease-in-out, loader-rotate 3s infinite ease-in-out;
        transform-origin: center;
    }
}

@keyframes loader-rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes loader-animation {
    0% {
        stroke-dasharray: 0, 30;
        stroke-dashoffset: 0;
    }

    100% {
        stroke-dasharray: 180, 270;
        stroke-dashoffset: -180;
    }
}
</style>
