export default class {
    constructor (
        id
    ) {
        this._id = id
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    toString () {
        return this.id
    }

    toJSON () {
        return {
            id: this.id
        }
    }
}
