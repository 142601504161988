<template>
    <div class="InternalLinks">
        <alert-message
            v-if="showError"
            :message="errorMessage"
            type="error"
            class="InternalLinks-alertMessage"
        />
        <template v-else>
            <v-progress-circular
                indeterminate
                color="primary"
            />
            <p>
                {{ $t('internal_links:pending_redirection') }}
            </p>
        </template>
    </div>
</template>

<script setup>
import {
    computed,
    onBeforeMount,
    onMounted,
    onUnmounted,
    ref
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import i18n from 'i18n'
import emitter from 'global-emitter'
import { isEmpty } from 'global-utils'
import { removeSelectedFareMediaCookie } from '@/StoreWeb/js/mixins/wallet-utils'
import * as mainMutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import * as userMutationTypes from '@/StoreWeb/store/modules/user/mutation-types'
import * as internalActionTypes from '@/StoreWeb/store/modules/application-session/internal-action-types'
import * as externalActionTypes from '@/StoreWeb/store/modules/application-session/external-action-types'
import LoginManager from 'user/Login/managers/LoginManager'
import AlertMessage from '@/StoreWeb/components/common/AlertMessage'
import FareMedia from '@/StoreWeb/models/user/wallet/FareMedia'

const store = useStore()
const route = useRoute()
const router = useRouter()

const action = ref(route.params.action)
const errorMessage = ref('')
const routeParams = ref(null)
const showError = ref(false)
const waitForLoggedInEvent = ref(false)

const applicationSession = computed(() => store.state.applicationSessionModule.applicationSession)
const debugMode = computed(() => store.state.debuggingModule.debugMode)

const onApplicationSessionLoadedHandler = () => {
    if (!isEmpty(applicationSession.value.data)) {
        switch (action.value) {
            case internalActionTypes.GO_TO_PRODUCT_DETAILS:
                if (!isEmpty(applicationSession.value.data.addToCartFlow)) {
                    const addCartFlow = JSON.parse(applicationSession.value.data.addToCartFlow)

                    if (!isEmpty(addCartFlow.productId)) {
                        routeParams.value = {
                            id: addCartFlow.productId
                        }
                    }
                }
                break
        }
    }

    if (!isEmpty(applicationSession.value.data) && !isEmpty(applicationSession.value.data.userToken)) {
        if (!debugMode.value || !localStorage.getItem('usingFakeUserToken')) {
            waitForLoggedInEvent.value = true
            let userToken

            try {
                userToken = JSON.parse(applicationSession.value.data.userToken)
            } catch (e) { // If the token is using the old structure instead of a stringified object
                userToken = {
                    accessToken: applicationSession.value.data.userToken,
                    expirationDate: applicationSession.value.data.expirationDate,
                    refreshToken: applicationSession.value.data.refreshToken
                }
            }
            LoginManager.getUserTokenSuccessHandler(userToken)
        } else {
            onLoggedInHandler()
        }
    } else {
        waitForLoggedInEvent.value = false

        if (
            applicationSession.value?.data &&
            applicationSession.value?.data?.fareMediaId &&
            applicationSession.value?.data?.addToCartFlow
        ) {
            const addToCartFlow = JSON.parse(applicationSession.value.data.addToCartFlow)
            const nfcProvidedFareMedia = new FareMedia(applicationSession.value.data.fareMediaId, addToCartFlow.productProvider)
            store.commit(userMutationTypes.SET_SELECTED_SUPPORT, nfcProvidedFareMedia)
        }
        redirectToAction()
    }
}

const onApplicationSessionLoadingErrorHandler = () => {
    showError.value = true
    errorMessage.value = i18n.global.t('application_session:get_session:error:default:message')
}

const onLoggedInHandler = () => {
    removeSelectedFareMediaCookie()
    redirectToAction()
}

const redirectToAction = () => {
    const route = {}
    store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })

    switch (action.value) {
        case internalActionTypes.GO_TO_ACCOUNT_ASSOCIATION:
            route.name = 'accountAssociations'
            break
        case internalActionTypes.GO_TO_ADDRESSES:
            route.name = 'informations'
            break
        case internalActionTypes.GO_TO_ALERTING_PREFERENCES:
            route.name = 'alertingPreferences'
            break
        case internalActionTypes.GO_TO_BANK_ACCOUNTS:
            route.name = 'bankAccounts'
            break
        case internalActionTypes.GO_TO_CATALOG:
            route.name = 'catalog'
            break
        case internalActionTypes.GO_TO_CONTACT:
            route.name = 'contact'
            break
        case internalActionTypes.GO_TO_DIRECT_DEBITS:
            route.name = 'directDebits'
            break
        case internalActionTypes.GO_TO_FINE_REGULARIZATION:
            route.name = 'fineRegularization'
            break
        case internalActionTypes.GO_TO_USER_INFO:
            route.name = 'informations'
            break
        case internalActionTypes.GO_TO_OPEN_PAYMENT_TRIPS:
            route.name = 'openPaymentTrips'
            break
        case internalActionTypes.GO_TO_ORDERS:
            route.name = 'purchases'
            break
        case internalActionTypes.GO_TO_PERSONAL_DATA:
            route.name = 'personalData'
            break
        case internalActionTypes.GO_TO_PRODUCT_DETAILS:
            if (!isEmpty(routeParams.value.id)) {
                route.name = 'product'
                route.params = {
                    id: routeParams.value.id
                }
            }
            break
        case internalActionTypes.GO_TO_REDUCED_MOBILITY:
            route.name = 'reducedMobility'
            break
        case internalActionTypes.GO_TO_WALLET:
            route.name = 'tickets'
            break
    }

    if (route.params) {
        route.params.lang = i18n.global.locale
    } else {
        route.params = {
            lang: i18n.global.locale
        }
    }

    router.push(route)
}

onBeforeMount(() => {
    store.commit(mainMutationTypes.SET_IS_MOBILE_APP, true)
    localStorage.setItem('isMobileAppContext', 1)
})

onMounted(async () => {
    emitter.on('loggedIn', onLoggedInHandler)

    if (applicationSession.value?.id) {
        onApplicationSessionLoadedHandler()
    } else {
        onApplicationSessionLoadingErrorHandler()
    }
    if (!isEmpty(route.query?.status)) {
        if (route.query.status === 'error') {
            let messageKey = 'application_session:resume:error:default:message'
            if (!isEmpty(route.query.initialRequest)) {
                switch (route.query.initialRequest) {
                    case externalActionTypes.GET_FARE_MEDIA_ID_THROUGH_NFC:
                        messageKey = 'application_session:resume:error:get_fare_media_id_through_nfc:message'
                        break
                    case externalActionTypes.LOG_IN:
                        messageKey = 'application_session:resume:error:login:message'
                        break
                    default:
                        break
                }
            }
            store.commit(mainMutationTypes.SET_FEEDBACK_ERROR, i18n.global.t(messageKey))
        } else if (route.query.status === 'success') {
            store.commit(mainMutationTypes.SET_FEEDBACK_SUCCESS, i18n.global.t('application_session:resume:success:default:message'))
        }
    }
})

onUnmounted(() => {
    emitter.off('loggedIn', onLoggedInHandler)
})
</script>

<style lang='scss' scoped>
@import 'globalScss';

.InternalLinks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: $s5;
    padding: $s10 $s5;
}
</style>
