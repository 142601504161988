import i18n from 'i18n'
import moment from 'moment'
import { isEmpty } from 'global-utils'
import Invoice from '@/StoreWeb/models/user/order/Invoice'

const getInvoices = (state) => {
    const months = []
    if (!isEmpty(state.invoices)) {
        const invoices = []
        state.invoices.forEach(item => {
            const invoice = new Invoice(item.id)
            invoice.orderId = item.orderId
            invoice.creationDate = item.creationDate
            invoice.path = item.path
            invoice.status = item.status
            invoices.push({
                invoice
            })
        })

        invoices.forEach(item => {
            const monthHeader = moment(item.invoice.creationDate).format('YYYY-MM')
            let count = months.length
            if (count === 0 || months[count - 1].id !== monthHeader) {
                months.push({
                    id: monthHeader,
                    label: moment(item.invoice.creationDate).format(i18n.global.t('date_format:litteral_month_with_year')).ucFirst(),
                    invoices: []
                })
                count++
            }
            months[count - 1].invoices.push(item)
        })
    }

    return months
}

const normalizeInvoice = (data) => {
    const item = new Invoice()

    item.version = data.modalProductVersion

    return item
}

export default {
    getInvoices,
    normalizeInvoice
}
