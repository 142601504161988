import * as mutationTypes from './mutation-types'

export default {
    [mutationTypes.SET_APPLICATION_SESSION] (state, applicationSession) {
        if (applicationSession?.id) {
            localStorage.setItem('applicationSessionId', applicationSession.id)
        } else {
            localStorage.removeItem('applicationSessionId')
        }
        state.applicationSession = applicationSession
    },
    [mutationTypes.SET_APPLICATION_SESSION_PENDING_REQUEST] (state, pendingRequest) {
        if (state.applicationSessionPendingRequests[pendingRequest.key] !== undefined) {
            state.applicationSessionPendingRequests[pendingRequest.key] = pendingRequest.value
        }
    }
}
