export default class {
    constructor (
        id,
        providerId
    ) {
        this._accountId = ''
        this._deviceType = ''
        this._id = id
        this._label = ''
        this._products = []
        this._providerId = providerId
        this._providerUserId = ''
        this._recipientUser = null
        this._providerDeviceId = id
    }

    get accountId () {
        return this._accountId
    }

    set accountId (accountId) {
        this._accountId = accountId
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get label () {
        return this._label
    }

    set label (label) {
        this._label = label
    }

    get products () {
        return this._products
    }

    set products (products) {
        this._products = products
    }

    get providerId () {
        return this._providerId
    }

    set providerId (providerId) {
        this._providerId = providerId
    }

    get providerUserId () {
        return this._providerUserId
    }

    set providerUserId (providerUserId) {
        this._providerUserId = providerUserId
    }

    get recipientUser () {
        return this._recipientUser
    }

    set recipientUser (recipientUser) {
        this._recipientUser = recipientUser
    }

    get status () {
        return this._status
    }

    set status (status) {
        this._status = status
    }

    get type () {
        return this._deviceType
    }

    set type (type) {
        this._deviceType = type
    }

    getIcon () {
        return 'mdi mdi-cellphone'
    }

    getSubtitle () {
        return this.label
    }

    getFullName () {
        return this._recipientUser.firstName + ' ' + this._recipientUser.lastName
    }

    getTitle () {
        return this.getFullName()
    }

    get providerDeviceId () {
        return this._providerDeviceId
    }

    set providerDeviceId (providerDeviceId) {
        this._providerDeviceId = providerDeviceId
    }

    toJSON () {
        return {
            accountId: this.accountId,
            id: this.id,
            label: this.label,
            products: this.products,
            providerId: this.providerId,
            providerUserId: this.providerUserId,
            recipientUser: this.recipientUser,
            status: this.status,
            type: this.type,
            providerDeviceId: this.providerDeviceId
        }
    }
}
