<template>
    <component
        :is="'li'"
        :class="[notification.read === false ? 'NotificationItem--unread' : '']"
        class="NotificationItem"
        @click="doNotificationAction"
    >
        <span class="NotificationItem-message">Notification #{{ notification.id }}</span>
        <span
            v-if="!notification.read"
            class="NotificationItem-readStatusIcon"
        />
    </component>
</template>

<script>
import * as userActionTypes from '@/StoreWeb/store/modules/user/action-types'

export default {
    props: {
        notification: {
            type: Object,
            required: true
        }
    },
    methods: {
        doNotificationAction () {
            if (!this.notification.read) {
                this.$store.dispatch(userActionTypes.SET_NOTIFICATION_AS_READ, {
                    id: this.notification.id
                })
            }
            this.notification.getAction()
        }
    }
}
</script>
<style lang="scss" scoped>
@import 'globalScss';

.NotificationItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-top: 1px solid $color-lightgray4;
    background: $color-lightest;
    font-size: 14px;
    color: $color-lighterText;

    &--unread {
        background: $color-lightgray1;
    }

    &-readStatusIcon {
        width: 6px;
        height: 6px;
        border-radius: 6px;
        background: $color-brandSecondary;
    }
}
</style>
