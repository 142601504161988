import { isEmpty } from 'global-utils'
import Notification from '../../../models/user/notification/Notification'
import NotificationList from '../../../models/user/notification/NotificationList'

const normalizeNotificationList = (notifications, data) => {
    let notificationList
    if (!isEmpty(notifications)) {
        notificationList = notifications
    } else {
        notificationList = new NotificationList()
        notificationList.items = []
    }

    if (!isEmpty(data.content) && (notificationList.lastLoadedPage === null || notificationList.lastLoadedPage < data.number)) {
        data.content.forEach(item => {
            notificationList.items.push({
                notification: normalizeNotification(item)
            })
        })
        notificationList.lastLoadedPage = data.number
    }
    notificationList.totalElements = data.totalElements
    notificationList.totalPages = data.totalPages

    return notificationList
}

const normalizeNotification = (data) => {
    const item = new Notification(data.id)

    item.creationDate = data.creationDate
    item.data = data.data
    item.deleted = data.deleted
    item.key = data.key
    item.read = data.read
    item.sendDate = data.sendDate

    return item
}

const markNotificationAsRead = (notifications, id) => {
    if (!isEmpty(notifications.items)) {
        const readNotification = notifications.items.find(item => item.notification.id === id)

        if (!isEmpty(readNotification)) {
            readNotification.notification.read = true
        }
    }

    return notifications
}

const getHasUnreadNotifications = (notifications) => {
    if (!isEmpty(notifications) && !isEmpty(notifications.items)) {
        for (const i in notifications.items) {
            if (notifications.items[i].notification.read) {
                return true
            }
        }
    }

    return false
}

export default {
    getHasUnreadNotifications,
    markNotificationAsRead,
    normalizeNotificationList
}
