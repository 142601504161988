import Device from '@/StoreWeb/models/user/wallet/Device'
import DeviceProductNormalizer from '@/StoreWeb/normalizers/Devices/DeviceProductNormalizerSingleton'
import IdLabelPatternNormalizer from '@/StoreWeb/normalizers/patterns/IdLabelPatternNormalizerSingleton'
import UserActionNormalizer from '@/StoreWeb/normalizers/common/UserActionNormalizerSingleton'
import { isEmpty } from 'global-utils'

export default class {
    constructor () {
        this._deviceProductNormalizer = DeviceProductNormalizer.getInstance()
        this._idLabelPatternNormalizer = IdLabelPatternNormalizer.getInstance()
        this._userActionNormalizer = UserActionNormalizer.getInstance()
    }

    get deviceProductNormalizer () {
        return this._deviceProductNormalizer
    }

    get idLabelPatternNormalizer () {
        return this._idLabelPatternNormalizer
    }

    get userActionNormalizer () {
        return this._userActionNormalizer
    }

    normalize (item, params) {
        const device = new Device(item.id, params.providerId)
        device.actions = this.userActionNormalizer.normalizeArray(item.actions)
        if (!isEmpty(params.accountId)) {
            device.accountId = params.accountId
            device.providerUserId = params.accountId
        }
        device.label = item.label
        device.products = []
        if (!isEmpty(item.products)) {
            device.products = this.deviceProductNormalizer.normalizeArray(item.products, params)
            device.products.forEach(product => {
                if (!isEmpty(product.type)) {
                    device.profile = product.label
                }
            })
        }
        if (!isEmpty(params.recipientUser)) {
            device.recipientUser = params.recipientUser
        }
        if (!isEmpty(item.type)) {
            device.type = this.idLabelPatternNormalizer.normalize(item.type)
        }
        return device
    }

    normalizeArray (items, params) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item, params))
            })
        }

        return normalizedItems
    }
}
