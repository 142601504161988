<template lang="html">
    <div class="SaleOptionProviderFareMediaUseCardReaderOrOrderCard">
        <h2 class="SaleOptionProviderFareMediaUseCardReaderOrOrderCard-title">
            {{ $t('mandatory_card_title') }}
        </h2>
        <span
            class="SaleOptionProviderFareMediaUseCardReaderOrOrderCard-description"
            v-html="$sanitize($t('mandatory_card_desc'))"
        />
        <authentication-descriptions class="mt-4 mb-8" />
        <div class="SaleOptionProviderFareMediaUseCardReaderOrOrderCard-buttons">
            <v-btn
                :to="sanitizeUrl(getCardOrderUrl())"
                color="primary"
                size="large"
                elevation="0"
                type="submit"
            >
                {{ $t('card_order_label') }}
            </v-btn>
            <v-btn
                size="large"
                variant="text"
                @click.prevent="useSupportReader"
            >
                {{ $t('button:read_card') }}
            </v-btn>
        </div>
        <support-reader-modal
            v-model="showSupportReaderModal"
            :headerTitle="$t('card_reader:scan_modal:title')"
            :operationType="'READ'"
            @operation-finished="onSupportReaderOperationFinished"
        />
    </div>
</template>

<script setup>
import { sanitizeUrl } from '@braintree/sanitize-url'
import SupportReaderModal from '@/StoreWeb/components/miscellaneous/conduent-support-reader/SupportReaderModal'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { checkAndGetFareMedia } from '@/StoreWeb/js/mixins/faremedia-utils'
import { isEmptyTranslation } from 'global-utils'
import AuthenticationDescriptions from '@/StoreWeb/components/common/AuthenticationDescriptions'
import emitter from 'global-emitter'

const emit = defineEmits(['updateSaleOptionFromCardReaderWithOrderCardFallBack', 'supportReaderBasketInfoUsed'])

const props = defineProps({
    provider: {
        type: String,
        default: ''
    }
})

const { t } = useI18n()

const showSupportReaderModal = ref(false)

function useSupportReader () {
    showSupportReaderModal.value = true
    emit('supportReaderBasketInfoUsed', 'PROVIDERFAREMEDIA')
}

async function onSupportReaderOperationFinished (data) {
    let support = data
    if (data?.support?.fareMediaId) {
        support = await checkAndApplyFareMedia(data, true)
    }
    showSupportReaderModal.value = false
    emit('updateSaleOptionFromCardReaderWithOrderCardFallBack', {
        support: {
            fareMediaId: support.support.fareMediaId,
            providerId: support.support.providerId,
            providerUserExternalId: support.support?.providerUserExternalId
        }
    })
}

async function checkAndApplyFareMedia (data, isCadMode = false) {
    const response = await checkAndGetFareMedia({
        providerId: props.provider,
        fareMediaId: data.support.fareMediaId
    }, true, isCadMode)

    if (response.isValid && response?.fareMedia?.providerUserExternalId) {
        data.support.providerUserExternalId = response.fareMedia.providerUserExternalId
    }

    return data
}

function getCardOrderUrl () {
    emitter.emit('saveProductToPutOnCard')
    const translationKey = `link_to_card_order_${process.env.VUE_APP_ENV}`
    return !isEmptyTranslation(translationKey) ? t(translationKey) : t('link_to_card_order')
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.SaleOptionProviderFareMediaUseCardReaderOrOrderCard {
    &-title {
        margin-bottom: $margin-small;
        font-size: $font-smedium;
        font-weight: bold;
        opacity: .9;
    }

    &-description {
        font-size: $font-small;
    }

    &-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $s4;
        padding: $s4 0;
    }
}
</style>
