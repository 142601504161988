import actions from './actions'
import mutations from './mutations'
import ApplicationSession from '@/StoreWeb/models/application-session/ApplicationSession'

export default {
    namespaced: false,
    actions,
    mutations,
    state: {
        applicationSessionPendingRequests: {
            createApplicationSession: false,
            getApplicationSession: false,
            updateApplicationSession: false
        },
        applicationSession: new ApplicationSession()
    }
}
