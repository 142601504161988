export default class {
    constructor (
        id, providerId
    ) {
        this._account = ''
        this._actions = []
        this._description = ''
        this._endDate = ''
        this._id = id
        this._label = ''
        this._product = null
        this._provider = providerId
        this._remainingTickets = null
        this._serialNumber = ''
        this._startDate = ''
        this._status = null
        this._type = null
        this._renewableProducts = []
    }

    get account () {
        return this._account
    }

    set account (account) {
        this._account = account
    }

    get actions () {
        return this._actions
    }

    set actions (actions) {
        this._actions = actions
    }

    get description () {
        return this._description
    }

    set description (description) {
        this._description = description
    }

    get endDate () {
        return this._endDate
    }

    set endDate (endDate) {
        this._endDate = endDate
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get label () {
        return this._label
    }

    set label (label) {
        this._label = label
    }

    get product () {
        return this._product
    }

    set product (product) {
        this._product = product
    }

    get provider () {
        return this._provider
    }

    set provider (provider) {
        this._provider = provider
    }

    get remainingTickets () {
        return this._remainingTickets
    }

    set remainingTickets (remainingTickets) {
        this._remainingTickets = remainingTickets
    }

    get serialNumber () {
        return this._serialNumber
    }

    set serialNumber (serialNumber) {
        this._serialNumber = serialNumber
    }

    get startDate () {
        return this._startDate
    }

    set startDate (startDate) {
        this._startDate = startDate
    }

    get status () {
        return this._status
    }

    set status (status) {
        this._status = status
    }

    get type () {
        return this._type
    }

    set type (type) {
        this._type = type
    }

    get renewableProducts () {
        return this._renewableProducts
    }

    set renewableProducts (renewableProducts) {
        this._renewableProducts = renewableProducts
    }

    toJSON () {
        return {
            account: this.account,
            actions: this.actions,
            description: this.description,
            endDate: this.endDate,
            id: this.id,
            label: this.label,
            product: this.product,
            provider: this.provider,
            remainingTickets: this.remainingTickets,
            serialNumber: this.serialNumber,
            startDate: this.startDate,
            status: this.status,
            type: this.type,
            renewableProducts: this.renewableProducts
        }
    }
}
