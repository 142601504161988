import store from '@/StoreWeb/store/store'
import { isEmpty } from 'global-utils'
import PageConfigManager from '@/StoreWeb/managers/PageConfigManagerSingleton'
import { nextTick, onMounted, onBeforeMount } from 'vue'

const pageConfigManager = PageConfigManager.getInstance()

export function useLifecycleHooks () {
    onBeforeMount(() => {
        removePreviousPageClasses()
    })

    onMounted(async () => {
        await nextTick()

        addNewPageClasses()
    })
}

export const addNewPageClasses = () => {
    if (hasBodyClasses()) {
        document.body.classList.add(this.pageConfig.bodyClasses)
    }
}

export const hasBodyClasses = () => {
    return !isEmpty(store.state.pageConfig) && !isEmpty(store.state.pageConfig.bodyClasses)
}

export const removePreviousPageClasses = () => {
    if (hasBodyClasses()) {
        document.body.classList.remove(this.pageConfig.bodyClasses)
    }
    pageConfigManager.setConfig({})
}
