import CatalogProductParameter from '@/StoreWeb/models/catalog/CatalogProductParameter'
import { isEmpty } from 'global-utils'

export default class {
    normalize (item, params) {
        const productParameter = new CatalogProductParameter(item.id)

        productParameter.label = item.label
        if (typeof item.maxValue !== 'undefined') {
            productParameter.maxValue = item.maxValue
        }
        if (typeof item.minValue !== 'undefined') {
            productParameter.minValue = item.minValue
        }
        if (typeof item.online !== 'undefined') {
            productParameter.online = item.online
        }
        if (typeof item.pagination !== 'undefined') {
            productParameter.pagination = item.pagination
        }
        if (typeof params.preselectedProductParameter !== 'undefined') {
            productParameter.preselection = params.preselectedProductParameter
        }
        if (typeof item.required !== 'undefined') {
            productParameter.required = item.required
        }
        if (typeof item.search !== 'undefined') {
            productParameter.search = item.search
        }
        if (typeof item.type !== 'undefined') {
            productParameter.type = item.type
        }
        if (productParameter.type === 'INTEGER') {
            productParameter.maxValue = parseInt(productParameter.maxValue)
            productParameter.minValue = parseInt(productParameter.minValue)
        }

        if (typeof item.index !== 'undefined') {
            productParameter.index = item.index
        }

        return productParameter
    }

    normalizeArray (items, params) {
        const normalizedItems = []

        if (typeof params === 'undefined') {
            params = {}
        }

        if (!isEmpty(items)) {
            items.forEach(item => {
                const itemParams = Object.assign({}, params)
                if (typeof params.preselectedProductParameters !== 'undefined') {
                    const preselectedProductParameter = params.preselectedProductParameters.filter(preselectedProductParameter => preselectedProductParameter.id === item.id)
                    delete itemParams.preselectedProductParameters
                    if (!isEmpty(preselectedProductParameter)) {
                        itemParams.preselectedProductParameter = preselectedProductParameter
                    }
                }
                normalizedItems.push(this.normalize(item, itemParams))
            })
        }

        normalizedItems.sort(this.compareByIndex)

        return normalizedItems
    }

    compareByIndex (a, b) {
        return a.index - b.index
    }
}
