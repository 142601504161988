<template>
    <div
        :class="[{ 'BlockLoader-loaderContainer--inline' : inline, 'BlockLoader-loaderContainer--hidden' : showGlobalLoader }, 'BlockLoader-loaderContainer--size' + size ]"
        class="BlockLoader-loaderContainer"
    >
        <svg>
            <circle
                class="BlockLoader-loader"
                r="10"
                cx="50%"
                cy="50%"
            />
        </svg>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    props: {
        inline: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: 'Medium',
            validator: (val) => ['Large', 'Medium', 'Small'].includes(val)
        }
    },
    computed: {
        ...mapState({
            showGlobalLoader: state => state.showGlobalLoader
        })
    },
    methods: {
        animationFinished () {
            this.$emit('animationFinished')
        }
    }
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

.BlockLoader-loader {
    fill: none;
    stroke-width: 5;
    stroke: rgba(0, 0, 0, .5);
    animation: loader-animation 3s infinite ease-in-out, loader-rotate 3s infinite ease-in-out;
    transform-origin: center;
}

.BlockLoader-loaderContainer {
    @include verticalCenter;

    justify-content: center;
    width: 100%;
    height: 100%;

    &--hidden {
        visibility: hidden;
    }

    &--sizeLarge {
        height: 52px;
    }

    &--sizeMedium {
        height: 42px;

        .BlockLoader-loader {
            stroke-width: 2;
        }
    }

    &--sizeSmall {
        height: 34px;

        .BlockLoader-loader {
            stroke-width: 2;
        }
    }

    &--inline {
        &.BlockLoader-loaderContainer {
            &--sizeLarge {
                width: 52px;
            }

            &--sizeMedium {
                width: 42px;
            }

            &--sizeSmall {
                width: 34px;
            }
        }
    }
}

@keyframes loader-rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes loader-animation {
    0% {
        stroke-dasharray: 0, 30;
        stroke-dashoffset: 0;
    }

    100% {
        stroke-dasharray: 180, 270;
        stroke-dashoffset: -180;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .3s ease-in-out;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
