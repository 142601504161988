import { isEmpty } from 'global-utils'
import config from 'config'
import store from '@/StoreWeb/store/store'
import emitter from 'global-emitter'
import * as userMutationTypes from '@/StoreWeb/store/modules/user/mutation-types'
import Cookies from 'js-cookie'
import cloneDeep from 'lodash.clonedeep'

const duration = config.features.fare_media_selection.cookie_duration * 1000
const safetyDelay = config.features.fare_media_selection.cookie_refresh_safety_delay * 1000
let selectedFareMediaTimeout

export const getSelectedFareMediaCookie = () => {
    let selectedFareMediaCookie = Cookies.get('selectedSupport')

    if (!isEmpty(selectedFareMediaCookie)) {
        selectedFareMediaCookie = JSON.parse(selectedFareMediaCookie)

        if (selectedFareMediaCookie.expires) {
            delete selectedFareMediaCookie.expires
        }

        return selectedFareMediaCookie
    }

    return null
}

export const setSelectedFareMediaCookie = (value, isCadMode = false) => {
    const expiresTimestamp = Date.now() + duration

    value.expires = expiresTimestamp
    value.isCadMode = isCadMode

    Cookies.set('selectedSupport', JSON.stringify(value), {
        expires: new Date(expiresTimestamp)
    })

    scheduleRefreshSelectedSupport()
}

export const refreshSelectedFareMediaCookie = () => {
    const value = getSelectedFareMediaCookie()

    if (!isEmpty(value)) {
        const expiresTimestamp = Date.now() + duration

        value.expires = expiresTimestamp

        setSelectedFareMediaCookie(value)
    }
}

export const removeSelectedFareMediaCookie = () => {
    Cookies.remove('selectedSupport')
    localStorage.removeItem('selectedSupport')
    clearTimeout(selectedFareMediaTimeout)
}

const getSelectedFareMediaCookieRemainingTime = () => {
    let selectedFareMediaCookie = Cookies.get('selectedSupport')

    if (!isEmpty(selectedFareMediaCookie)) {
        selectedFareMediaCookie = JSON.parse(selectedFareMediaCookie)

        if (!isEmpty(selectedFareMediaCookie.expires)) {
            const remainingTime = selectedFareMediaCookie.expires - Date.now()

            return remainingTime > 0 ? remainingTime : 0
        }
    }

    return -1
}

export const scheduleRefreshSelectedSupport = () => {
    const remainingTime = getSelectedFareMediaCookieRemainingTime()

    if (!isNaN(remainingTime) && remainingTime > safetyDelay) {
        const delay = Math.floor(remainingTime - safetyDelay)

        clearTimeout(selectedFareMediaTimeout)
        selectedFareMediaTimeout = setTimeout(() => {
            refreshSelectedFareMediaCookie()
        }, delay)
    } else if (!isNaN(remainingTime) && remainingTime > 0) {
        refreshSelectedFareMediaCookie()
    }
}

export const autoSetSelectedFareMedia = () => {
    const lastSelectedFareMediaId = store.state.userModule.wallet.lastSelectedFareMediaId ?? null
    let selectedFareMediaId
    const selectedFareMediaCookie = getSelectedFareMediaCookie()

    if (!isEmpty(selectedFareMediaCookie)) {
        selectedFareMediaId = selectedFareMediaCookie.fareMediaId
    }

    let chosenFareMediaId = 'ALL'

    if (!isEmpty(selectedFareMediaId)) {
        if (selectedFareMediaId !== 'ALL') {
            chosenFareMediaId = selectedFareMediaId
        }
    } else if (!isEmpty(lastSelectedFareMediaId)) {
        chosenFareMediaId = lastSelectedFareMediaId
    }

    if (chosenFareMediaId !== 'ALL' && isEmpty(store.state.userModule.selectedFareMedia)) {
        const walletFareMedias = store.getters.getWalletFareMedias
        let selectedFareMedia = walletFareMedias.find(walletFareMedia => walletFareMedia.id === chosenFareMediaId)

        if (isEmpty(selectedFareMedia) && !isEmpty(walletFareMedias)) {
            [selectedFareMedia] = walletFareMedias

            setSelectedFareMediaCookie({
                providerId: selectedFareMedia.providerId,
                fareMediaId: selectedFareMedia.id
            })
        }

        if (!isEmpty(selectedFareMedia)) {
            store.commit(userMutationTypes.SET_SELECTED_SUPPORT, selectedFareMedia)
            emitter.emit('selectedSupportChanged')
        }
    }
}

export const getWalletProvidersWithCartFareMedias = () => {
    const providers = cloneDeep(store.getters.getWalletProviders)
    const cartFareMedias = store.getters.getCartFareMedias

    if (!isEmpty(cartFareMedias)) {
        cartFareMedias.forEach(cartFareMedia => {
            const provider = providers.find(provider => provider.id === cartFareMedia.providerId)

            if (!isEmpty(provider) && !isEmpty(provider.accounts)) {
                const providerAccount = provider.accounts.find(account => account.providerUserExternalId === cartFareMedia.providerUserExternalId)

                if (providerAccount) {
                    const cartFareMediasAddedForCurrentAccount = cartFareMedias.filter(fareMedia => fareMedia.providerUserExternalId === providerAccount.providerUserExternalId)

                    if (providerAccount && cartFareMediasAddedForCurrentAccount.length > 0) {
                        if (providerAccount.fareMedias) {
                            providerAccount.fareMedias = [...cartFareMediasAddedForCurrentAccount, ...providerAccount.fareMedias]
                        } else {
                            providerAccount.fareMedias = [...cartFareMediasAddedForCurrentAccount]
                        }
                    }
                }
            }
        })
    }

    return providers
}
