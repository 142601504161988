<template>
    <component
        :is="'li'"
        class="ProviderAccountProductItem"
    >
        <div class="ProviderAccountProductItem-header">
            <div class="ProviderAccountProductItem-headerIconAndLabel">
                <div class="ProviderAccountProductItem-headerIconWrapper">
                    <icomoon-icon
                        name="Ticket--filled"
                        class="ProviderAccountProductItem-headerIcon"
                    />
                </div>
                <div class="ProviderAccountProductItem-infos">
                    <strong class="ProviderAccountProductItem-name">{{ providerAccountProduct.label }}</strong>
                    <span
                        v-if="providerAccountProduct.startDate !== ''"
                        class="ProviderAccountProductItem-startDate"
                        v-html="$sanitize(getValidityStartDate)"
                    />
                    <span
                        v-if="providerAccountProduct.endDate !== ''"
                        class="ProviderAccountProductItem-endDate"
                        v-html="$sanitize(getValidityEndDate)"
                    />
                </div>
            </div>
            <dropdown-menu class="ProviderAccountProductItem-dropdownMenu">
                <ul class="DropdownMenu-list">
                    <li
                        v-for="action in getActions"
                        :key="action.type"
                        v-bind="action"
                        class="DropdownMenu-item"
                    >
                        <v-btn
                            variant="text"
                            class="justify-start w-100 rounded-0"
                            color="primary"
                            size="small"
                            @click="executeAction(action)"
                        >
                            {{ action.label }}
                            <v-icon
                                :aria-label="$t('open_new_tab')"
                                :aria-hidden="false"
                                class="pl-1"
                                color="primary"
                                size="medium"
                            >
                                mdi-open-in-new
                            </v-icon>
                        </v-btn>
                    </li>
                </ul>
            </dropdown-menu>
        </div>
    </component>
</template>

<script>
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon'
import DropdownMenu from '@/StoreWeb/components/common/dropdown-menu/DropdownMenu'
import * as userActionTypes from '@/StoreWeb/store/modules/user/action-types'
import i18n from 'i18n'

export default {
    components: {
        DropdownMenu,
        IcomoonIcon
    },
    props: {
        providerAccountProduct: {
            type: Object,
            required: true
        }
    },
    computed: {
        getActions () {
            return this.providerAccountProduct.actions
        },
        getValidityEndDate () {
            if (this.providerAccountProduct.endDate !== '') {
                return i18n.global.t('date:end_of_validity').replace('%date%', `<strong>${this.$moment(this.providerAccountProduct.endDate).format(i18n.global.t('date_format:default'))}</strong>`)
            }
            return ''
        },
        getValidityStartDate () {
            if (this.providerAccountProduct.endDate !== '') {
                return i18n.global.t('date:start_of_validity').replace('%date%', `<strong>${this.$moment(this.providerAccountProduct.startDate).format(i18n.global.t('date_format:default'))}</strong>`)
            }
            return ''
        }
    },
    methods: {
        executeAction (action) {
            switch (action.type) {
                case 'CERTIFICATE_DOWNLOAD':
                    this.$store.dispatch(userActionTypes.GET_CERTIFICATE, {
                        providerId: this.providerAccountProduct.provider,
                        accountId: this.providerAccountProduct.account,
                        serialNumber: this.providerAccountProduct.serialNumber
                    })
                    break
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.ProviderAccountProductItem {
    display: inline-flex;
    width: 100%;
    margin-bottom: 12px;
    border-radius: 6px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, .09);
    background: $color-lightest;
    color: $color-lightText;

    &:last-child {
        margin-bottom: 0;
    }

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        width: 100%;
        padding: 16px 12px;

        @media (min-width: $tablet-breakpoint) {
            padding-right: 25px;
            padding-left: 25px;
        }

        &IconAndLabel {
            display: inline-flex;
            align-items: center;
            flex-grow: 1;
            gap: 12px;
        }
    }

    &-headerIcon {
        font-size: 22px;
        color: $color-lightText;

        &Wrapper {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            min-width: 42px;
            min-height: 42px;
            border-radius: 42px;
            background: $color-lightgray3;
        }
    }

    &-name {
        display: block;
        font-size: 16px;
        font-weight: $fontWeight-defaultMedium;
    }

    &-endDate,
    &-startDate {
        display: block;
        margin-top: 6px;
        font-size: 12px;
    }

    &-startDate {
        & + .ProviderAccountProductItem-endDate {
            margin-top: 0;
        }
    }
}
</style>
