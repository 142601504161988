import { isEmpty } from 'global-utils'
import config from 'config'
import { ref } from 'vue'
import emitter from 'global-emitter'

export function useModalActions () {
    const afterCloseAction = ref(null)
    const showFareMediaAddExistantModal = ref(false)
    const showFareMediaAddModal = ref(false)
    const showFareMediaAddNewModal = ref(false)
    const showFareMediaChoiceModal = ref(false)

    function getAvailableActions (providerId) {
        let isAddFareMediaEnabled = false
        let isOrderFareMediaEnabled = false

        if (!isEmpty(config.providers)) {
            const selectedProvider = config.providers.find(provider => provider.id === providerId)

            if (selectedProvider?.fare_media) {
                isAddFareMediaEnabled = !!selectedProvider.fare_media?.add
                isOrderFareMediaEnabled = !!selectedProvider.fare_media?.order
            }
        }

        return {
            isAddFareMediaEnabled,
            isOrderFareMediaEnabled
        }
    }

    function getFareMediaAddActionModal (providerId) {
        let action = 'shouldShowFareMediaAddModal'

        const { isAddFareMediaEnabled, isOrderFareMediaEnabled } = getAvailableActions(providerId)

        if (isAddFareMediaEnabled && isOrderFareMediaEnabled) {
            action = 'shouldShowFareMediaAddModal'
        } else if (isAddFareMediaEnabled) {
            action = 'shouldShowFareMediaAddExistantModal'
        } else if (isOrderFareMediaEnabled) {
            action = 'shouldShowFareMediaAddNewModal'
        }

        return action
    }

    function getBackAction ({ providerId = 'conduent', currentModal, isComingFromFareMediaChoiceModal = true }) {
        let backAction = null
        const { isAddFareMediaEnabled, isOrderFareMediaEnabled } = getAvailableActions(providerId)

        if (isAddFareMediaEnabled && isOrderFareMediaEnabled && ['FareMediaAddExistantModal', 'FareMediaAddNewModal'].includes(currentModal)) {
            backAction = 'shouldShowFareMediaAddModal'
        } else if (
            (currentModal === 'FareMediaAddModal' && isComingFromFareMediaChoiceModal) ||
            (isAddFareMediaEnabled && currentModal === 'FareMediaAddExistantModal' && isComingFromFareMediaChoiceModal) ||
            (isOrderFareMediaEnabled && currentModal === 'FareMediaAddNewModal' && isComingFromFareMediaChoiceModal)
        ) {
            backAction = 'shouldShowFareMediaChoiceModal'
        }

        return backAction
    }

    function callCallbackIfDefined (callback) {
        if (typeof callback === 'function') {
            callback()
        }
    }

    function setShowFareMediaAddExistantModal (callback) {
        showFareMediaAddExistantModal.value = true
        callCallbackIfDefined(callback)
    }

    function setShowFareMediaAddModal (callback) {
        showFareMediaAddModal.value = true
        callCallbackIfDefined(callback)
    }

    function setShowFareMediaAddNewModal (callback) {
        showFareMediaAddNewModal.value = true
        callCallbackIfDefined(callback)
    }

    function setShowFareMediaChoiceModal (callback) {
        showFareMediaChoiceModal.value = true
        callCallbackIfDefined(callback)
    }

    function setHideFareMediaAddExistantModal (callback) {
        showFareMediaAddExistantModal.value = false
        callCallbackIfDefined(callback)
    }

    function setHideFareMediaAddModal (callback) {
        showFareMediaAddModal.value = false
        callCallbackIfDefined(callback)
    }

    function setHideFareMediaAddNewModal (callback) {
        showFareMediaAddNewModal.value = false
        callCallbackIfDefined(callback)
    }

    function setHideFareMediaChoiceModal (callback) {
        showFareMediaChoiceModal.value = false
        callCallbackIfDefined(callback)
    }

    function closeAllFareMediaChoiceOrAddModals () {
        setHideFareMediaAddExistantModal()
        setHideFareMediaAddModal()
        setHideFareMediaAddNewModal()
        setHideFareMediaChoiceModal()
    }

    function fareMediaAddExistantModalCloseAction (action) {
        if (action === 'shouldShowFareMediaAddExistantModal') {
            setShowFareMediaAddExistantModal()
        } else if (action === 'shouldShowFareMediaAddModal') {
            setShowFareMediaAddModal()
        } else if (action === 'shouldShowFareMediaChoiceModal') {
            setShowFareMediaChoiceModal()
        }
    }

    function fareMediaAddModalCloseAction (action) {
        if (action === 'shouldShowFareMediaAddExistantModal') {
            setShowFareMediaAddExistantModal()
        } else if (action === 'shouldShowFareMediaAddNewModal') {
            setShowFareMediaAddNewModal()
        } else if (action === 'shouldShowFareMediaChoiceModal') {
            setShowFareMediaChoiceModal()
        }
    }

    function fareMediaAddNewModalCloseAction (action, product) {
        if (action === 'startOrderProcess' && product !== null) {
            emitter.emit('showProductModal', product)
        } else if (action === 'shouldShowFareMediaAddModal') {
            setShowFareMediaAddModal()
        } else if (action === 'shouldShowFareMediaChoiceModal') {
            setShowFareMediaChoiceModal()
        }
    }

    function fareMediaChoiceModalAction (action) {
        if (action === 'shouldShowFareMediaAddModal') {
            setShowFareMediaAddModal()
        } else if (action === 'shouldShowFareMediaAddExistantModal') {
            setShowFareMediaAddExistantModal()
        } else if (action === 'shouldShowFareMediaAddNewModal') {
            setShowFareMediaAddNewModal()
        }
    }

    return {
        afterCloseAction,
        closeAllFareMediaChoiceOrAddModals,
        fareMediaAddExistantModalCloseAction,
        fareMediaAddModalCloseAction,
        fareMediaAddNewModalCloseAction,
        fareMediaChoiceModalAction,
        getAvailableActions,
        getBackAction,
        getFareMediaAddActionModal,
        setShowFareMediaAddExistantModal,
        setShowFareMediaAddModal,
        setShowFareMediaAddNewModal,
        setShowFareMediaChoiceModal,
        showFareMediaAddExistantModal,
        showFareMediaAddModal,
        showFareMediaAddNewModal,
        showFareMediaChoiceModal
    }
}
