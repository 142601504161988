import { isEmpty } from 'global-utils'
import store from '@/StoreWeb/store/store'

export default class {
    constructor (id) {
        this._counters = []
        this._email = ''
        this._firstName = ''
        this._id = id
        this._isMaster = false
        this._lastName = ''
        this._plans = []
        this._providers = []
        this._type = null
        this._userId = ''
    }

    get counters () {
        return this._counters
    }

    set counters (counters) {
        this._counters = counters
    }

    get email () {
        return this._email
    }

    set email (email) {
        this._email = email
    }

    get firstName () {
        return this._firstName
    }

    set firstName (firstName) {
        this._firstName = firstName
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get isMaster () {
        return this._isMaster
    }

    set isMaster (isMaster) {
        this._isMaster = isMaster
    }

    get lastName () {
        return this._lastName
    }

    set lastName (lastName) {
        this._lastName = lastName
    }

    get plans () {
        return this._plans
    }

    set plans (plans) {
        this._plans = plans
    }

    get providers () {
        return this._providers
    }

    set providers (providers) {
        this._providers = providers
    }

    get type () {
        return this._type
    }

    set type (type) {
        this._type = type
    }

    get userId () {
        return this._userId
    }

    set userId (userId) {
        this._userId = userId
    }

    getFullName () {
        return (!isEmpty(this.firstName) ? this.firstName + ' ' : '') + (!isEmpty(this.lastName) ? this.lastName : '')
    }

    getNonEmptyWalletProviderAccounts () {
        const providersAccounts = []
        this.providers.forEach(provider => {
            if (!isEmpty(provider.accounts)) {
                provider.accounts.forEach(account => {
                    if (!isEmpty(account.products)) {
                        providersAccounts.push(account)
                    }
                })
            }
        })

        return providersAccounts
    }

    getFareMedias () {
        const walletFareMedias = []

        if (!isEmpty(this.providers)) {
            this.providers.forEach(walletProvider => {
                if (!isEmpty(walletProvider.accounts)) {
                    walletProvider.accounts.forEach(providerAccount => {
                        if (!isEmpty(store.getters.getCartFareMedias)) {
                            const cartFareMediasAddedForCurrentAccount = store.getters.getCartFareMedias.filter(fareMedia => fareMedia.providerUserExternalId === providerAccount.providerUserExternalId)

                            if (cartFareMediasAddedForCurrentAccount.length > 0) {
                                cartFareMediasAddedForCurrentAccount.forEach(fareMedia => {
                                    walletFareMedias.push(fareMedia)
                                })
                            }
                        }
                        if (!isEmpty(providerAccount.fareMedias)) {
                            providerAccount.fareMedias.forEach(fareMedia => {
                                walletFareMedias.push(fareMedia)
                            })
                        }
                    })
                }
            })
        }

        return walletFareMedias
    }

    getAllSupports () {
        const walletSupports = []
        if (!isEmpty(this.providers)) {
            this.providers.forEach(walletSupport => {
                if (!isEmpty(walletSupport.accounts)) {
                    walletSupport.accounts.forEach(providerAccount => {
                        if (!isEmpty(providerAccount.fareMedias)) {
                            providerAccount.fareMedias.forEach(fareMedia => {
                                walletSupports.push(fareMedia)
                            })
                        }
                        if (!isEmpty(providerAccount.devices)) {
                            providerAccount.devices.forEach(devices => {
                                walletSupports.push(devices)
                            })
                        }
                    })
                }
            })
        }
        return walletSupports
    }

    getPlans () {
        return this.plans
    }

    getCounters () {
        return this.counters
    }

    hasDevices () {
        return this.providers.some(provider => provider.hasDevices())
    }

    hasETickets () {
        return this.providers.some(provider => provider.hasETickets())
    }

    hasFareMedias () {
        return this.providers.some(provider => provider.hasFareMedias())
    }

    toJSON () {
        return {
            counters: this.counters,
            email: this.email,
            firstName: this.firstName,
            id: this.id,
            isMaster: this.isMaster,
            lastName: this.lastName,
            plans: this.plans,
            providers: this.providers,
            type: this.type,
            userId: this.userId
        }
    }
}
