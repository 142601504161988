<template>
    <div
        :style="{ zIndex : (headerBarZIndex - 2) }"
        class="ProductFilterBar d-flex justify-center align-center py-6 px-4"
    >
        <strong class="ProductFilterBar-title ma-0 mr-6">
            {{ $t('catalog:filter:modal_title') }} :
        </strong>
        <product-filter-select-list class="ProductFilterBar-filters" />
    </div>
</template>

<script setup>
import {
    computed
} from 'vue'
import { useStore } from 'vuex'
import ProductFilterSelectList from '@/StoreWeb/components/product/ProductFilterSelectList'

const store = useStore()

const headerBarZIndex = computed(() => store.state.headerBarZIndex)
</script>

<style lang="scss" scoped>
@import 'globalScss';

.ProductFilterBar {
    position: relative;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, .11);
    background: $color-lightest;

    &-title {
        font-family: $fontFamily-title;
        font-size: 18px;
        font-weight: $fontWeight-titleBold;
        white-space: nowrap;
        color: $color-defaultText;
    }
}
</style>
