<template>
    <div class="AccountAssociationRequestList">
        <AccountAssociationDescription
            class="AccountAssociationRequestList-description mb-8"
        />
        <div
            v-if="showAccountAssociationListLoader"
            class="AccountAssociationRequestList-loader"
        >
            <v-progress-circular
                indeterminate
                color="primary"
            />
        </div>
        <ul
            v-if="userAccountAssociations.length"
            class="AccountAssociationRequestList-list"
        >
            <account-association-request-item
                v-for="accountAssociationRequestItem in userAccountAssociations"
                :key="accountAssociationRequestItem.id"
                :accountAssociationRequest="accountAssociationRequestItem"
                class="AccountAssociationRequestList-item"
            />
        </ul>
        <v-alert
            v-else
            class="AccountAssociationRequestList-empty"
            color="secondary"
            variant="tonal"
        >
            {{ $t('account_association:empty_list') }}
        </v-alert>
        <div
            v-if="accountAssociationTypes?.length"
            class="d-flex justify-center pt-5"
        >
            <v-btn
                v-if="config.features?.account_association?.add && !showAccountAssociationListLoader && !showAccountAssociationAddLoaderAssociations"
                color="primary"
                variant="text"
                class="AccountAssociationRequestList-addAssociation"
                @click="showAccountAssociationAddForm"
            >
                <v-icon
                    icon="mdi-account-multiple-plus"
                    class="mr-2"
                />
                {{ $t('button:associate_an_account') }}
            </v-btn>
            <account-association-add-modal
                v-if="showAccountAssociationAddModal"
                v-model="showAccountAssociationAddModal"
            />
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import AccountAssociationRequestItem from './AccountAssociationRequestItem.vue'
import AccountAssociationAddModal from './AccountAssociationAddModal.vue'
import * as actionTypes from '@/StoreWeb/store/modules/account-association/action-types'
import config from 'config'

const store = useStore()

const showAccountAssociationAddModal = ref(false)

const accountAssociationTypes = computed(() => store.state.accountAssociationModule.accountAssociationTypes)
const userAccountAssociations = computed(() => store.state.accountAssociationModule.userAccountAssociations)
const accountAssociationPendingRequests = computed(() => store.state.accountAssociationModule.accountAssociationPendingRequests)
const showAccountAssociationAddLoaderAssociations = computed(() => accountAssociationPendingRequests.value.getAssociationTypes)
const showAccountAssociationListLoader = computed(() => {
    return accountAssociationPendingRequests.value.getUserAssociations ||
        accountAssociationPendingRequests.value.createAccountAssociation ||
        accountAssociationPendingRequests.value.deleteAccountAssociation
})

onMounted(async () => {
    await store.dispatch(actionTypes.GET_ACCOUNT_ASSOCIATION_TYPES)
    await store.dispatch(actionTypes.GET_USER_ACCOUNT_ASSOCIATIONS)
})

function showAccountAssociationAddForm () {
    showAccountAssociationAddModal.value = true
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.AccountAssociationRequestList {
    &-loader {
        width: 100%;
        margin-bottom: $s4;
        text-align: center;
    }

    &-block {
        display: flex;
        flex-direction: column;
        gap: $s4;
    }

    &-list {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &-addAssociation {
        width: max-content;
        font-weight: $fontWeight-defaultBold;
    }
}
</style>
