<template>
    <div class="FareMediaAddActions">
        <ul class="FareMediaAddActions-actionList">
            <li
                class="FareMediaAddActions-actionItem"
            >
                <a
                    class="FareMediaAddActions-actionLink"
                    @click="orderFareMedia"
                >
                    <span class="FareMediaAddActions-actionIconWrapper">
                        <icomoon-icon
                            class="FareMediaAddActions-actionIcon"
                            :layers-count="5"
                            name="CardOrderIllust"
                        />
                    </span>
                    <strong class="FareMediaAddActions-actionTitle">
                        {{ t('fare_media:add_modal:i_dont_own_a_card:title') }}
                    </strong>
                    <span class="FareMediaAddActions-actionDescription">
                        {{ t('fare_media:add_modal:i_dont_own_a_card:description') }}
                    </span>
                </a>
            </li>
            <li
                v-if="isAddFareMediaShown || isAddAccountShown"
                class="FareMediaAddActions-actionItem"
            >
                <a
                    class="FareMediaAddActions-actionLink"
                    @click="linkFareMedia"
                >
                    <span class="FareMediaAddActions-actionIconWrapper">
                        <icomoon-icon
                            class="FareMediaAddActions-actionIcon"
                            :layers-count="4"
                            name="CardSyncIllust"
                        />
                    </span>
                    <strong class="FareMediaAddActions-actionTitle">
                        {{ t('fare_media:add_modal:i_own_a_card:title') }}
                    </strong>
                    <span class="FareMediaAddActions-actionDescription">
                        {{ t('fare_media:add_modal:i_own_a_card:description') }}
                    </span>
                </a>
            </li>
        </ul>
    </div>
</template>

<script setup>
import {
    computed
} from 'vue'
import config from 'config'
import { useI18n } from 'vue-i18n'
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon'
import { isEmpty } from 'global-utils'

const props = defineProps({
    providerId: {
        type: String,
        default: ''
    }
})
const emit = defineEmits(['linkFareMedia', 'orderFareMedia'])

const { t } = useI18n()

const selectedProvider = computed(() => {
    if (!isEmpty(config.providers)) {
        if (props.providerId !== '') {
            const provider = config.providers.find(provider => provider.id === props.providerId)

            if (provider?.id) {
                return provider
            }
        } else {
            const [provider] = config.providers

            return provider
        }
    }

    return null
})
const isAddAccountShown = computed(() => {
    return selectedProvider.value === null || selectedProvider.value?.account?.add
})
const isAddFareMediaShown = computed(() => {
    return selectedProvider.value === null || selectedProvider.value?.fare_media?.add
})

const linkFareMedia = () => {
    emit('linkFareMedia')
}

const orderFareMedia = () => {
    emit('orderFareMedia')
}
</script>

<style scoped lang="scss">
@import 'globalScss';

.FareMediaAddActions {
    &-action {
        &List {
            padding: 0;
            list-style: none;
        }

        &Item {
            margin-bottom: $s8;
        }

        &Link {
            display: block;
            padding: 0 $s6;
            text-align: center;
        }

        &IconWrapper {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 100px;
            height: 100px;
            margin-bottom: $s4;
            border-radius: 50px;
            background: $color-lightgray1;
        }

        &Icon {
            font-size: 70px;
        }

        &Title,
        &Description {
            display: block;
        }

        &Title {
            margin-bottom: $s3;
            font-family: $fontFamily-title;
            font-size: 18px;
            font-weight: $fontWeight-titleBold;
            color: $color-defaultText;
        }

        &Description {
            font-size: 12px;
            font-weight: $fontWeight-defaultLight;
            color: $color-lightText;
        }
    }
}
</style>
