export default class {
    constructor (email) {
        this._address = ''
        this._addressDetail = ''
        this._birthdate = ''
        this._city = ''
        this._country = ''
        this._email = email
        this._externalUserId = ''
        this._firstName = ''
        this._gender = ''
        this._id = ''
        this._image = ''
        this._internationalPhone = ''
        this._lastName = ''
        this._nationality = ''
        this._phone = ''
        this._postalCode = ''
        this._technicalId = ''
    }

    get address () {
        return this._address
    }

    set address (address) {
        this._address = address
    }

    get addressDetail () {
        return this._addressDetail
    }

    set addressDetail (addressDetail) {
        this._addressDetail = addressDetail
    }

    get birthdate () {
        return this._birthdate
    }

    set birthdate (birthdate) {
        this._birthdate = birthdate
    }

    get city () {
        return this._city
    }

    set city (city) {
        this._city = city
    }

    get country () {
        return this._country
    }

    set country (country) {
        this._country = country
    }

    get email () {
        return this._email
    }

    set email (email) {
        this._email = email
    }

    get externalUserId () {
        return this._externalUserId
    }

    set externalUserId (externalUserId) {
        this._externalUserId = externalUserId
    }

    get firstName () {
        return this._firstName
    }

    set firstName (firstName) {
        this._firstName = firstName
    }

    get gender () {
        return this._gender
    }

    set gender (gender) {
        this._gender = gender
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get image () {
        return this._image
    }

    set image (image) {
        this._image = image
    }

    get internationalPhone () {
        return this._internationalPhone
    }

    set internationalPhone (internationalPhone) {
        this._internationalPhone = internationalPhone
    }

    get lastName () {
        return this._lastName
    }

    set lastName (lastName) {
        this._lastName = lastName
    }

    get nationality () {
        return this._nationality
    }

    set nationality (nationality) {
        this._nationality = nationality
    }

    get phone () {
        return this._phone
    }

    set phone (phone) {
        this._phone = phone
    }

    get postalCode () {
        return this._postalCode
    }

    set postalCode (postalCode) {
        this._postalCode = postalCode
    }

    get technicalId () {
        return this._technicalId
    }

    set technicalId (technicalId) {
        this._technicalId = technicalId
    }

    getFullName () {
        return this.firstName + ' ' + this.lastName
    }

    toJSON () {
        return {
            address: this.address,
            addressDetail: this.addressDetail,
            birthdate: this.birthdate,
            city: this.city,
            country: this.country,
            email: this.email,
            externalUserId: this.externalUserId,
            firstName: this.firstName,
            gender: this.gender,
            id: this.id,
            image: this.image,
            internationalPhone: this.internationalPhone,
            lastName: this.lastName,
            nationality: this.nationality,
            phone: this.phone,
            postalCode: this.postalCode,
            technicalId: this.technicalId
        }
    }
}
