<template>
    <div class="AccountFareMediaAddModal">
        <modal
            v-model="showModal"
            :title="$t('faremedia_attachment:title')"
        >
            <v-form
                v-if="getFormType"
                @submit.prevent="attachFareMedia"
            >
                <div
                    v-for="field in getTemplate.fields"
                    :key="field.id"
                >
                    <v-text-field
                        v-model="formFields[field.id]"
                        :type="field.type"
                        :label="field.label"
                        :error-messages="v$[field.id].$errors[0]?.$message ? $t(v$[field.id].$errors[0].$message) : ''"
                        variant="underlined"
                        :required="field.required"
                        :disabled="field.readonly"
                        clearable
                        @update:model-value="v$[field.id].$touch()"
                    />
                </div>
                <WalletAddAccount />
            </v-form>
            <template #actions>
                <v-btn
                    variant="text"
                    @click="closeModal"
                >
                    {{ $t('button:cancel') }}
                </v-btn>
                <v-btn
                    class="bg-primary ml-2"
                    @click="submitForm"
                >
                    {{ $t('button:add') }}
                </v-btn>
            </template>
        </modal>
    </div>
</template>

<script setup>
import emitter from 'global-emitter'
import { isEmpty } from 'global-utils'
import * as actionTypes from '@/StoreWeb/store/modules/user/action-types'
import { computed, onBeforeUnmount, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { required } from '@/StoreWeb/utils/i18n-validators'
import { useVuelidate } from '@vuelidate/core'
import { useI18n } from 'vue-i18n'
import FormNormalizerSingleton from '@/StoreWeb/normalizers/form/FormNormalizerSingleton'
import { useLogin } from '@/StoreWeb/js/composables/login-utils'

const store = useStore()
const { t } = useI18n()
const formNormalizer = FormNormalizerSingleton.getInstance()
const { rawUserInfos, userInfos } = useLogin()

const showModal = ref(false)
const formType = ref('')
const providerId = ref('')

const getFormType = computed(() => {
    const supportedFormTypes = ['FAREMEDIA_BIRTHDATE', 'FIRSTNAME_LASTNAME_BIRTHDATE', 'EMAIL_PASSWORD']
    if (supportedFormTypes.includes(formType.value)) {
        return formType.value
    }
    return null
})

const formFields = computed(() => {
    const fields = {}

    const templateFields = getTemplate.value?.fields || []
    const userAttributes = rawUserInfos.value.attributes || {}

    templateFields.forEach(field => {
        const { id, value } = field

        switch (id) {
            case 'firstName':
                fields[id] = userInfos.value.firstName.ucFirst()
                break
            case 'lastName':
                fields[id] = userInfos.value.lastName.ucFirst()
                break
            case 'birthdate':
                fields[id] = userAttributes.birthDate || ''
                if (!isEmpty(userAttributes.birthDate)) {
                    field.readonly = true
                }
                break
            default:
                fields[id] = value ?? ''
                break
        }
    })

    return reactive(fields)
})

const rules = computed(() => {
    const rules = {}

    if (!isEmpty(getTemplate.value) && !isEmpty(getTemplate.value.fields)) {
        getTemplate.value.fields.forEach(field => {
            rules[field.id] = {}
            if (field.required) {
                rules[field.id].required = required
            }
        })
    }

    return rules
})

const getTemplate = computed(() => {
    switch (formType.value) {
        case 'FAREMEDIA_BIRTHDATE':
            return formNormalizer.normalize({
                fields: [
                    {
                        id: 'lastName',
                        label: t('lastname'),
                        params: {
                            subType: 'text'
                        },
                        required: true,
                        readonly: true,
                        type: 'text',
                        value: ''
                    },
                    {
                        id: 'firstName',
                        label: t('firstname'),
                        params: {
                            subType: 'text'
                        },
                        required: true,
                        readonly: true,
                        type: 'text',
                        value: ''
                    },
                    {
                        id: 'birthdate',
                        label: t('birthdate'),
                        params: {
                            subType: 'date'
                        },
                        required: true,
                        type: 'date',
                        value: ''
                    },
                    {
                        id: 'fareMediaId',
                        label: t('fare_media:card_number').replace('%cardNumber%', ''),
                        params: {
                            subType: 'text'
                        },
                        required: true,
                        type: 'text',
                        value: ''
                    }
                ]
            })
        case 'FIRSTNAME_LASTNAME_BIRTHDATE':
            return formNormalizer.normalize({
                fields: [
                    {
                        id: 'firstname',
                        label: t('firstname'),
                        params: {
                            subType: 'text'
                        },
                        required: true,
                        type: 'text',
                        value: ''
                    },
                    {
                        id: 'lastname',
                        label: t('lastname'),
                        params: {
                            subType: 'text'
                        },
                        required: true,
                        type: 'text',
                        value: ''
                    },
                    {
                        id: 'birthdate',
                        label: t('birthdate'),
                        params: {
                            subType: 'date'
                        },
                        required: true,
                        type: 'date',
                        value: ''
                    }
                ]
            })
        case 'EMAIL_PASSWORD':
            return formNormalizer.normalize({
                fields: [
                    {
                        id: 'email',
                        label: t('email'),
                        params: {
                            subType: 'text'
                        },
                        required: true,
                        type: 'text',
                        value: ''
                    },
                    {
                        id: 'password',
                        label: t('password'),
                        params: {
                            subType: 'password'
                        },
                        required: true,
                        type: 'text',
                        value: ''
                    }
                ]
            })
    }
    return null
})

onMounted(() => {
    emitter.on('showAddFareMediaModal', onShowAddFareMedia)
})

onBeforeUnmount(() => {
    emitter.off('showAddFareMediaModal', onShowAddFareMedia)
})

function onShowAddFareMedia (params) {
    if (!isEmpty(params)) {
        if (!isEmpty(params.walletId)) {
            providerId.value = params.walletId
        }
        if (!isEmpty(params.formType)) {
            formType.value = params.formType
        }
    }

    showModal.value = true
}

async function submitForm () {
    v$.value.$touch()
    const isValid = await v$.value.$validate()
    if (!v$.value.$invalid && isValid) {
        attachFareMedia()
    }
}

function attachFareMedia () {
    store.dispatch(actionTypes.LINK_FARE_MEDIA, {
        data: formFields.value,
        providerId: providerId.value,
        formType: formType.value
    }).then(() => {
        if (!isEmpty(formFields.value?.birthdate)) {
            updateUserBirthdate()
        }
        closeModal()
    }).catch(() => {
        closeModal()
    })
}

function updateUserBirthdate () {
    store.dispatch(actionTypes.UPDATE_PERSONAL_INFO, {
        attributes: {
            email: userInfos.value.email,
            firstName: userInfos.value.firstName.ucFirst(),
            lastName: userInfos.value.lastName.ucFirst(),
            birthDate: formFields.value?.birthdate
        },
        silent: true
    })
}

function closeModal () {
    showModal.value = false
}

const v$ = useVuelidate(rules, formFields)

</script>
