<template>
    <div class="AddNewProfileModal">
        <modal
            v-if="fareProfiles.length > 0"
            v-model="showModal"
            :title="$t('add_new_profile:title')"
        >
            <div class="AddNewProfileModal-body">
                <span class="AddNewProfileModal-description">
                    {{ $t('add_new_profile:description') }}
                </span>
                <v-select
                    v-model="selectedProfile"
                    :items="fareProfiles"
                    item-title="name"
                    item-value="id"
                    @update:model-value="fareProfileSelected(selectedProfile)"
                />
                <div>
                    <file-input-field-with-label
                        v-for="askedDocument in askedDocuments"
                        :key="askedDocument.id"
                        :fileId="askedDocument.id"
                        :label="askedDocument.name"
                        :max-size="askedDocument.size"
                        :mime-types="getDocumentMimeTypes(askedDocument.supportedTypes)"
                        class="AddNewProfileModal-documents"
                        @file-input-error="setUploadedFileError"
                        @field-value-changed="documentChanged"
                    />
                </div>
            </div>
            <template #actions>
                <v-btn
                    :disabled="isDisabled"
                    class="bg-primary ml-2"
                    @click="addNewProfile"
                >
                    {{ $t('next') }}
                </v-btn>
            </template>
        </modal>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import FileInputFieldWithLabel from '@/StoreWeb/components/common/FileInputFieldWithLabel'
import { getMimeTypesExtension } from '@/StoreWeb/managers/FileManager'
import { isEmpty } from 'global-utils'
import * as actionTypes from '@/StoreWeb/store/modules/main/action-types'
import * as userActionTypes from '@/StoreWeb/store/modules/user/action-types'
import * as mainMutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
    account: {
        type: Object,
        required: true
    },
    fareProfiles: {
        type: Array,
        required: true
    },
    getProfileErrorMessage: {
        type: String,
        default: null
    },
    modelValue: {
        type: Boolean,
        required: true
    },
    providerAccount: {
        type: Object,
        default: null
    }
})

const { t } = useI18n()
const store = useStore()
const route = useRoute()

const askedDocuments = ref([])
const errorMessage = ref(null)
const selectedProfile = ref([])
const uploadedFiles = ref([])
const formError = ref(false)

const getWalletProviderAccounts = computed(() => store.getters.getWalletProviderAccounts)
const getWalletAssociatedAccounts = computed(() => store.getters.getWalletAssociatedAccounts)

const showModal = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue)
})

const isDisabled = computed(() => {
    if (askedDocuments.value.length > 0) {
        return !(selectedProfile.value.documents.length === uploadedFiles.value.length && formError.value === false)
    }
    return false
})

const getRecipientUser = computed(() => {
    if (props.providerAccount) {
        return {
            associationId: props.providerAccount.id,
            firstName: props.providerAccount.firstName,
            lastName: props.providerAccount.lastName,
            userId: props.providerAccount.userId,
            type: props.providerAccount?.type?.id
        }
    }
    return null
})

onMounted(() => {
    selectedProfile.value = route.params?.profileId || props.fareProfiles[0].id
})

async function addNewProfile () {
    if (formError.value === false) {
        try {
            const uploadedFilesPromises = uploadedFiles.value.map(async (uploadedFile) => {
                const params = {
                    formData: getFormData(uploadedFile.file),
                    id: uploadedFile.id
                }
                const document = await store.dispatch(actionTypes.UPLOAD_DOCUMENT, params)

                return {
                    uploadedFileId: document.documentId,
                    documentId: uploadedFile.id
                }
            })
            const documents = await Promise.all(uploadedFilesPromises)
            const params = {
                providerId: props.account.provider,
                accountId: props.account.id,
                id: selectedProfile.value?.id,
                documents,
                providerUserExternalId: props.providerAccount.providerUserExternalId,
                providerUserId: props.providerAccount.id
            }

            const associatedProvider = getWalletAssociatedAccounts.value.find(obj => obj.id === props.providerAccount.id)
            const fatherAccount = getWalletProviderAccounts.value[0]

            // Manage associated provider
            if (!isEmpty(associatedProvider)) {
                params.recipientUser = getRecipientUser.value
                params.providerUserId = fatherAccount.id
                params.providerUserExternalId = fatherAccount.providerUserExternalId
            }

            await store.dispatch(userActionTypes.SET_NEW_PROFILE_ACCOUNT, params)
            store.commit(mainMutationTypes.SET_FEEDBACK_SUCCESS, t('add:profile:success'))
            closeModal()
        } catch (error) {
            errorMessage.value = t('profiles:set:error')
        }
    } else {
        store.commit(mainMutationTypes.SET_FEEDBACK_ERROR, t('profiles:set:error'))
        closeModal()
    }
}

function closeModal () {
    showModal.value = false
}

function documentChanged (event) {
    const idParsed = event.target.id.split('js-file-')[1]
    const file = event?.target?.files?.[0]
    const existingFileObject = uploadedFiles.value.find(fileObj => fileObj.id === idParsed)

    if (existingFileObject) {
        if (existingFileObject.file !== file) {
            existingFileObject.file = file
        }
    } else {
        uploadedFiles.value.push({ file, label: event.label, id: idParsed })
    }
}

function fareProfileSelected (value) {
    const selected = props.fareProfiles.find((fareProfile) => fareProfile.id === value)
    formError.value = false

    if (selected.documents) {
        askedDocuments.value = selected.documents
        selectedProfile.value = selected
    } else {
        askedDocuments.value = []
        uploadedFiles.value = []
        selectedProfile.value = selected
    }
}

function getDocumentMimeTypes (extensions) {
    return getMimeTypesExtension(extensions)
}

function getFormData (file) {
    const formData = new FormData()
    if (!isEmpty(file.name)) {
        formData.append('file', file, file.name)
    } else {
        formData.append('file', file)
    }
    return formData
}

function setUploadedFileError (event) {
    formError.value = event !== false
}

</script>

<style lang="scss" scoped>
@import 'globalScss';

.AddNewProfileModal {
    &-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &-label {
        flex-grow: 1;
        font-size: 14px;
    }

    &-documents {
        margin: 15px 0;
    }
}
</style>
