import FormFieldParams from '@/StoreWeb/models/form/FormFieldParams'
import IdNamePatternNormalizer from '@/StoreWeb/normalizers/patterns/IdNamePatternNormalizerSingleton'
import moment from 'moment'
import { isEmpty } from 'global-utils'

export default class {
    constructor () {
        this._idNamePatternNormalizer = IdNamePatternNormalizer.getInstance()
    }

    get idNamePatternNormalizer () {
        return this._idNamePatternNormalizer
    }

    normalize (item) {
        const formFieldParams = new FormFieldParams()

        if (!isEmpty(item.autocomplete)) {
            formFieldParams.autocomplete = item.autocomplete
        }
        if (!isEmpty(item.displayMode)) {
            formFieldParams.displayMode = item.displayMode
        }
        if (!isEmpty(item.fileMaxSize)) {
            formFieldParams.fileMaxSize = item.fileMaxSize
        }
        if (!isEmpty(item.fileMimeTypes)) {
            formFieldParams.fileMimeTypes = item.fileMimeTypes
        }
        if (!isEmpty(item.list)) {
            const options = []
            item.list.forEach(option => {
                options.push({
                    id: option.value,
                    name: option.label
                })
            })
            formFieldParams.list = this.idNamePatternNormalizer.normalizeArray(options)
        }
        if (!isEmpty(item.dependenciesSchemas)) {
            // TODO : Use a normalizer once everything will be specified
            formFieldParams.dependenciesSchemas = item.dependenciesSchemas
        }
        if (!isEmpty(item.maxDate)) {
            if (item.maxDate === 'now') {
                formFieldParams.maxDate = moment(Date.now()).format('YYYY-MM-DD')
            } else {
                formFieldParams.maxDate = item.maxDate
            }
        }
        if (!isEmpty(item.maxLength)) {
            formFieldParams.maxLength = item.maxLength
        }
        if (!isEmpty(item.minDate)) {
            if (item.minDate === 'now') {
                formFieldParams.minDate = moment(Date.now()).format('YYYY-MM-DD')
            } else {
                formFieldParams.minDate = item.minDate
            }
        }
        if (!isEmpty(item.minLength)) {
            formFieldParams.minLength = item.minLength
        }
        if (!isEmpty(item.multiple)) {
            formFieldParams.multiple = item.multiple
        }
        if (!isEmpty(item.pattern)) {
            formFieldParams.pattern = item.pattern
        } else if (!isEmpty(item.regex)) {
            formFieldParams.pattern = item.regex
        }
        if (!isEmpty(item.regexValidationMessage)) {
            formFieldParams.patternValidationMessage = item.regexValidationMessage
        }
        if (!isEmpty(item.subType)) {
            formFieldParams.subType = item.subType
        }
        if (!isEmpty(item.validationConditions)) {
            // TODO : Use a normalizer once everything will be specified
            formFieldParams.validationConditions = item.validationConditions
        }

        return formFieldParams
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
