<template>
    <form class="FareMediaLinkingIdBirthdateForm">
        <div class="FareMediaLinkingIdBirthdateForm-mainFields">
            <labelled-input
                :defaultValue="getFareMediaId"
                :inputType="'text'"
                :label="$t('form:fare_media_linking:fare_media_id:label')"
                class="FareMediaLinkingIdBirthdateForm-field FareMediaLinkingIdBirthdateForm-fareMediaIdField"
                @field-value-changed="updateFareMediaId"
            />
            <date-input-field-with-label
                :defaultValue="getBirthdate"
                :inputType="'date'"
                :label="$t('form:user_infos:birthdate:label')"
                class="FareMediaLinkingIdBirthdateForm-field FareMediaLinkingIdBirthdateForm-birthdateField"
                @field-value-changed="updateBirthdate"
            />
            <v-btn
                class="bg-primary"
                type="submit"
                size="large"
                elevation="0"
                @click.prevent="submit"
            >
                {{ $t('button:fare_media_linking_form:add_fare_media') }}
            </v-btn>
        </div>
    </form>
</template>

<script>
import DateInputFieldWithLabel from '@/StoreWeb/components/common/DateInputFieldWithLabel'
import LabelledInput from '@/StoreWeb/components/common/LabelledInput'
import * as userActionTypes from '@/StoreWeb/store/modules/user/action-types'

export default {
    components: {
        DateInputFieldWithLabel,
        LabelledInput
    },
    props: {
        providerId: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            birthdate: '',
            fareMediaId: ''
        }
    },
    computed: {
        getBirthdate () {
            return this.birthdate
        },
        getFareMediaId () {
            return this.fareMediaId
        }
    },
    methods: {
        checkFormError () {
            return (
                this.fareMediaId !== '' &&
                this.birthdate !== ''
            )
        },
        updateBirthdate (value) {
            this.birthdate = value
        },
        updateFareMediaId (value) {
            this.fareMediaId = value
        },
        submit () {
            if (this.checkFormError()) {
                this.$store.dispatch(userActionTypes.LINK_FARE_MEDIA, {
                    providerId: this.providerId,
                    fareMediaId: this.fareMediaId,
                    birthdate: this.birthdate
                })
            }
        }
    }
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

.FareMediaLinkingIdBirthdateForm {
    &-field {
        margin-bottom: 15px;
    }
}
</style>
