export default class {
    constructor (
        id
    ) {
        this._id = id
        this._name = ''
        this._totalValue = 0
        this._unitCode = ''
        this._unitLabel = ''
        this._valueSeries = []
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get name () {
        return this._name
    }

    set name (name) {
        this._name = name
    }

    get totalValue () {
        return this._totalValue
    }

    set totalValue (totalValue) {
        this._totalValue = totalValue
    }

    get unitCode () {
        return this._unitCode
    }

    set unitCode (unitCode) {
        this._unitCode = unitCode
    }

    get unitLabel () {
        return this._unitLabel
    }

    set unitLabel (unitLabel) {
        this._unitLabel = unitLabel
    }

    get valueSeries () {
        return this._valueSeries
    }

    set valueSeries (valueSeries) {
        this._valueSeries = valueSeries
    }

    toJSON () {
        return {
            id: this.id,
            name: this.name,
            totalValue: this.totalValue,
            unitCode: this.unitCode,
            unitLabel: this.unitLabel,
            valueSeries: this.valueSeries
        }
    }
}
