<template>
    <div
        :role="inputRole"
        :class="['DateInputFieldWithLabel']"
        class="DateInputFieldWithLabel"
    >
        <input
            :id="'js-input-' + inputId"
            v-model="currentValue"
            :aria-label="label"
            :aria-required="isRequired"
            :class="getInputClass"
            :max="getMaxDate"
            :min="getMinDate"
            :style="{ borderColor: getBorderColor, 'box-shadow': getBoxShadow }"
            :type="inputType"
            :readonly="readonly"
            :required="isRequired"
            class="DateInputFieldWithLabel-input"
            tabindex="0"
            @input="fieldValueChanged"
            @focus="focused=true"
            @blur="focused=false"
        >
        <button
            v-if="!readonly"
            :aria-label="$t('form:input:clear_field')"
            :tabindex="getTabIndexIcon"
            class="DateInputFieldWithLabel-button Accessibility-reachableByTab"
            type="button"
            @click="clearInputField"
        >
            <span
                :class="{ 'DateInputFieldWithLabel-icon--hoverable': getCurrentValueNotEmpty }"
                tabindex="-1"
                class="DateInputFieldWithLabel-icon material-icons Accessibility-noReachableByClick"
            >
                {{ getIconValue }}
            </span>
        </button>
        <label
            :for="inputId"
            class="DateInputFieldWithLabel-label"
        >
            {{ label }}
        </label>
        <div
            v-if="description !== ''"
            class="DateInputFieldWithLabel-description"
        >
            {{ description }}
        </div>
        <div
            :id="'js-error-' + inputId"
            :aria-label="errorLabel"
            :tabindex="getErrorTabIndex"
            :class="{ 'DateInputFieldWithLabel-error--displayed': hasError }"
            class="DateInputFieldWithLabel-error"
        >
            {{ errorLabel }}
        </div>
        <div
            :id="'js-success-' + inputId"
            :aria-label="successLabel"
            :tabindex="getSuccessTabIndex"
            :class="{ 'DateInputFieldWithLabel-success--displayed': isValid }"
            class="DateInputFieldWithLabel-success"
        >
            {{ successLabel }}
        </div>
    </div>
</template>

<script>
import { isEmpty } from 'global-utils'
import BaseInputField from './BaseInputField'

export default {
    name: 'DateInputFieldWithLabel',
    extends: BaseInputField,
    props: {
        inputType: {
            type: String,
            default: 'text',
            validator: (val) => ['date', 'datetime-local', 'time'].includes(val)
        },
        max: {
            type: String,
            default: ''
        },
        min: {
            type: String,
            default: ''
        }
    },
    computed: {
        getMaxDate () {
            if (!isEmpty(this.max)) {
                if (this.inputType === 'date') {
                    return this.$moment(this.max).format('YYYY-MM-DD')
                } else if (this.inputType === 'datetime-local') {
                    return this.$moment(this.max).format('YYYY-MM-DDTHH:mm')
                } else if (this.inputType === 'time') {
                    return this.$moment(this.max).format('HH:mm')
                }
            }

            return ''
        },
        getMinDate () {
            if (!isEmpty(this.min)) {
                if (this.inputType === 'date') {
                    return this.$moment(this.min).format('YYYY-MM-DD')
                } else if (this.inputType === 'datetime-local') {
                    return this.$moment(this.min).format('YYYY-MM-DDTHH:mm')
                } else if (this.inputType === 'time') {
                    return this.$moment(this.min).format('HH:mm')
                }
            }

            return ''
        }
    }
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

.DateInputFieldWithLabel {
    position: relative;
    font-family: $fontLato;

    @media (min-width: $tablet-breakpoint) {
        width: 200px;
    }

    &-label {
        display: block;
        position: absolute;
        top: $margin-small;
        left: $input-medium-horizontalPadding + $input-borderWidth;
        font-size: 14px;
        line-height: calc(#{$padding-medium + $font-small} / 2);
        color: $color-black-light;
        pointer-events: none;
        opacity: .7;
        transition: top .3s ease-in-out, font-size .3s ease-in-out, line-height .3s ease-in-out;
    }

    &-button {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: calc($input-medium-minHeight / 4);
        right: calc(#{$font-large} / 2);
        width: calc($input-medium-minHeight / 2);
        height: calc($input-medium-minHeight / 2);
        padding: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;
    }

    &-icon.material-icons {
        font-size: $font-large;
        color: $color-grey-dark;
        transition: color .3s ease-in-out;
    }

    &-icon--hoverable:hover {
        cursor: pointer;
        color: $color-black;
    }

    &-description {
        margin-top: 4px;
        font-size: 14px;
        color: $color-lighterText;
    }

    &-error,
    &-success {
        margin-bottom: 0;
        opacity: 0;
        transition: all .3s ease-in-out;

        &--displayed {
            margin-top: 10px;
            opacity: 1;
        }
    }

    &-error--displayed {
        @include errorBloc;
    }

    &-success--displayed {
        @include successBloc;
    }

    &--Chrome {
        .DateInputFieldWithLabel-button {
            right: 44px;
        }
    }
}

.DateInputFieldWithLabel-input {
    @include outline0;

    width: 100%;
    min-height: $input-medium-minHeight;
    padding: 20px $padding-small 10px $padding-small;
    border-width: 1px;
    border-style: solid;
    border-radius: $borderRadius-medium;
    font-size: 14px;
    line-height: 14px;
    color: $color-black-light;
    transition: border-color .3s ease-in-out, box-shadow .3s ease-in-out;

    &[type='date'],
    &[type='time'],
    &[type='datetime-local'] {
        background: #fff;

        &::-webkit-calendar-picker-indicator {
            position: absolute;
            top: 15px;
            right: calc(#{$font-large} / 2);
            cursor: pointer;
            opacity: .6;
            transition: opacity .5s;

            &:hover {
                opacity: 1;
            }
        }
    }

    &--inactive {
        border-color: $color-grey-light;
    }

    &:read-only {
        border-color: $color-grey-light;
        box-shadow: none;
        background: $color-lightgray1;
        cursor: not-allowed;
    }

    &--notEmpty,
    &:focus,
    &[type='date'],
    &[type='time'],
    &[type='datetime-local'] {
        & ~ .DateInputFieldWithLabel-label {
            top: 6px;
            font-size: 12px;
            line-height: 12px;
        }
    }
}
</style>
