<template>
    <div class="HeaderCart">
        <header-cart-toggle-button
            ref="button"
            :class="[(showCart ? 'HeaderCartToggleButton--active' : '')]"
            class="HeaderCart-toggle"
            @button-clicked="updateHeaderCartContentDisplay"
        />
        <header-cart-content
            v-if="showCart"
            v-click-outside="clickOutside"
            :class="[ 'HeaderCart-content', headerCartContentClass ]"
        />
    </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'
import * as cartMutationTypes from '@/StoreWeb/store/modules/cart/mutation-types'
import config from 'config'
import HeaderCartContent from './HeaderCartContent'
import HeaderCartToggleButton from './HeaderCartToggleButton'

const store = useStore()

const isMobileApp = computed(() => store.state.isMobileApp)
const showCart = computed(() => store.state.cartModule.showCart)
const headerCartContentClass = computed(() => {
    if (isMobileApp.value) {
        return 'HeaderCart-content--mobileApp'
    }

    if (config.header.template === 'sytral') {
        return 'HeaderCart-content--sytral'
    }

    return 'HeaderCart-content--default'
})

function clickOutside (event) {
    if (!(event.target.className.includes('HeaderCart'))) {
        hideHeaderCartContent()
    }
}

function hideHeaderCartContent () {
    store.commit(cartMutationTypes.SET_SHOW_CART, false)
}

function updateHeaderCartContentDisplay () {
    store.commit(cartMutationTypes.SET_SHOW_CART, !showCart.value)
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.HeaderCart {
    position: relative;

    &-content {
        position: absolute;
        top: 50px;
        right: -32px;

        @media (min-width: $tablet-breakpoint) {
            left: 8px;
        }

        &--mobileApp {
            top: 60px;
            right: 0;
            left: auto;
        }

        &--sytral {
            left: -42px;

            @media (min-width: $tablet-breakpoint) {
                left: 8px;
            }

            @media (min-width: $desktop-breakpoint) {
                right: 0;
                left: auto;
            }
        }
    }
}
</style>
