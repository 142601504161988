<template>
    <div class="Addresses">
        <address-list
            :showHeader="false"
            class="Addresses-addressList"
        />
    </div>
</template>

<script>
import AddressList from '@/StoreWeb/components/address/AddressList'
import DefaultView from '@/StoreWeb/views/DefaultView'
import PageConfigManager from '@/StoreWeb/managers/PageConfigManagerSingleton'
import { isEmpty } from 'global-utils'
const pageConfigManager = PageConfigManager.getInstance()

export default {
    components: {
        AddressList
    },
    extends: DefaultView,
    mounted () {
        if (
            !this.$config.features.addresses ||
            isEmpty(this.$config.account_menu_item_enabled.address) ||
            this.$config.account_menu_item_enabled.address === false
        ) {
            this.$router.push({ name: 'home' })
        }

        pageConfigManager.setConfig({
            title: 'account:addresses:title',
            currentAccountPage: 'addresses'
        })
    }
}
</script>
