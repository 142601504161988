import * as actionTypes from './action-types'
import AccountAssociationManager from '@/StoreWeb/managers/AccountAssociationManagerSingleton'
const accountAssociationManager = AccountAssociationManager.getInstance()

export default {
    /**
     * Create an association
     * @param params
     */
    [actionTypes.CREATE_ACCOUNT_ASSOCIATION] (...args) {
        const [, params] = args
        return accountAssociationManager.createAccountAssociationAction(params)
    },
    /**
     * Get an association by id
     * @param params
     */
    [actionTypes.GET_ACCOUNT_ASSOCIATION] (...args) {
        const [, params] = args
        accountAssociationManager.getAccountAssociationAction(params)
    },
    /**
     * Get user's associations
     * @param params
     */
    [actionTypes.GET_USER_ACCOUNT_ASSOCIATIONS] (...args) {
        const [, params] = args
        return accountAssociationManager.getUserAccountAssociationsAction(params)
    },
    /**
     * Get association types
     * @param params
     */
    [actionTypes.GET_ACCOUNT_ASSOCIATION_TYPES] (...args) {
        const [, params] = args
        return accountAssociationManager.getAccountAssociationTypesAction(params)
    },
    /**
     * Delete an association
     * @param params
     */
    [actionTypes.DELETE_ACCOUNT_ASSOCIATION] (...args) {
        const [, params] = args
        return accountAssociationManager.deleteAccountAssociationAction(params)
    }
}
