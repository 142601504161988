<template>
    <component
        :is="tag"
        :class="{ 'AddressSelectorItem--selectedStatus' : isSelected }"
        class="AddressSelectorItem"
        @click="selectAddress"
    >
        <strong
            v-if="address.getFullName() !== ''"
            class="AddressSelectorItem-line"
        >
            {{ address.getFullName() }}
        </strong>
        <span class="AddressSelectorItem-line">
            {{ address.getFullStreetName() }}
        </span>
        <span
            v-if="address.streetLine2"
            class="AddressItem-line"
        >
            {{ address.streetLine2 }}
        </span>
        <span
            v-if="address.getZipCodeAndCity() !== ''"
            class="AddressSelectorItem-line"
        >
            {{ address.getZipCodeAndCity() }}
        </span>
        <span
            v-if="address.getCountry() !== ''"
            class="AddressSelectorItem-line"
        >
            {{ address.getCountry() }}
        </span>
        <div
            v-if="showEditButton"
            class="AddressSelectorItem-buttonsArea"
        >
            <a
                class="AddressSelectorItem-editButton"
                @click="editAddress"
            >
                {{ $t('button:edit') }}
            </a>
        </div>
    </component>
</template>

<script setup>
const emit = defineEmits(['addressSelected', 'showEditAddressForm'])

const props = defineProps({
    address: {
        type: Object,
        required: true
    },
    isSelected: {
        type: Boolean,
        default: false
    },
    showEditButton: {
        type: Boolean,
        default: true
    },
    tag: {
        type: String,
        default: 'li'
    }
})

const editAddress = () => {
    emit('showEditAddressForm', props.address.id)
}

const selectAddress = () => {
    emit('addressSelected', props.address.id)
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.AddressSelectorItem {
    padding: 15px;
    border-radius: 6px;
    box-shadow: inset 0 0 0 3px $color-lightgray4;
    background: $color-lightest;
    font-size: 14px;
    color: $color-lightText;

    &--selectedStatus {
        box-shadow: inset 0 0 0 3px $color-brandPrimary;
    }

    &-line {
        display: block;
        line-height: 1.5;
    }

    &-buttonsArea {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        padding-top: 10px;
    }

    &-editButton {
        cursor: pointer;
        font-weight: bold;
    }
}
</style>
