<template>
    <transition
        name="fade"
        @:after-leave="animationFinished"
    >
        <div
            v-if="true"
            :class="getHeaderNotificationsClass"
            class="HeaderNotificationListContent"
        >
            <notification-list
                :display-style="'card'"
                :limit="3"
                class="HeaderNotificationListContent-list"
            />
        </div>
    </transition>
</template>

<script>
import NotificationList from './NotificationList'

export default {
    components: {
        NotificationList
    },
    computed: {
        getHeaderNotificationsClass () {
            if (this.$config.header.template === 'sytral') {
                return 'HeaderNotificationListContent--sytral'
            }
            return ''
        }
    },
    methods: {
        animationFinished () {
            this.$emit('headerNotificationListContentOpened')
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.HeaderNotificationListContent {
    width: 325px;
    max-width: 80vw;
    padding: 12px 20px 25px;
    border-radius: 5px;
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, .2);
    background: $color-lightgray1;

    &::before {
        content: '';
        position: absolute;
        top: -9px;
        right: 10px;
        width: 0;
        height: 0;
        border-width: 0 9px 9px;
        border-style: solid;
        border-color: transparent transparent $color-lightgray2 transparent;
    }

    &-list {
        margin: 0;
        padding: 0;
        list-style: none;
        text-align: left;
    }

    &-item {
        margin: 0;
    }

    &-itemLink {
        display: block;
        position: relative;
        padding: 7px 0 7px 30px;
        cursor: pointer;
        font-size: 14px;
        transition: color .3s;

        &:hover,
        &:active {
            color: darken($color-brandPrimary, 10%);
        }
    }

    &-itemIcon {
        position: absolute;
        top: 5px;
        left: 0;
        font-size: 20px;
    }
}
</style>
