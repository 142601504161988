import * as actionTypes from './action-types'
import PaymentManager from '@/StoreWeb/managers/PaymentManagerSingleton'
const paymentManager = PaymentManager.getInstance()

export default {
    /**
     * Create bank account
     * @param args
     */
    [actionTypes.CREATE_BANK_ACCOUNT] (...args) {
        const [, params] = args
        return paymentManager.createBankAccountAction(params)
    },
    /**
     * Update bank account
     * @param args
     */
    [actionTypes.UPDATE_BANK_ACCOUNT] (...args) {
        const [, params] = args
        return paymentManager.updateBankAccountAction(params)
    },
    /**
     * Get bank accounts
     * @param args
     */
    [actionTypes.GET_BANK_ACCOUNTS] (...args) {
        const [, params] = args
        paymentManager.getBankAccountsAction(params)
    },
    /**
     * Init payment
     * @param args
     */
    [actionTypes.PAYMENT_INIT] (...args) {
        const [, params] = args
        return paymentManager.paymentInitAction(params)
    },
    /**
     * Get mandate
     * @param args
     */
    [actionTypes.GET_MANDATE_BINARY] (...args) {
        const [, params] = args
        paymentManager.getMandateBinaryAction(params)
    },
    /**
     * Sign mandate
     * @param args
     */
    [actionTypes.SIGN_MANDATE] (...args) {
        const [, params] = args
        return paymentManager.signMandateAction(params)
    },
    /**
     * POST bankAccounts
     * @param args
     */
    [actionTypes.POST_BANK_ACCOUNTS] (...args) {
        const [, params] = args
        paymentManager.postBankAccounts(params)
    }
}
