export default class {
    constructor () {
        this._content = null
        this._empty = true
        this._first = true
        this._last = true
        this._number = 0
        this._numberOfElements = 0
        this._pageable = null
        this._size = 0
        this._sort = null
        this._totalElements = 0
        this._totalPages = 0
    }

    get content () {
        return this._content
    }

    set content (content) {
        this._content = content
    }

    get empty () {
        return this._empty
    }

    set empty (empty) {
        this._empty = empty
    }

    get first () {
        return this._first
    }

    set first (first) {
        this._first = first
    }

    get last () {
        return this._last
    }

    set last (last) {
        this._last = last
    }

    get number () {
        return this._number
    }

    set number (number) {
        this._number = number
    }

    get numberOfElements () {
        return this._numberOfElements
    }

    set numberOfElements (numberOfElements) {
        this._numberOfElements = numberOfElements
    }

    get pageable () {
        return this._pageable
    }

    set pageable (pageable) {
        this._pageable = pageable
    }

    get size () {
        return this._size
    }

    set size (size) {
        this._size = size
    }

    get sort () {
        return this._sort
    }

    set sort (sort) {
        this._sort = sort
    }

    get totalElements () {
        return this._totalElements
    }

    set totalElements (totalElements) {
        this._totalElements = totalElements
    }

    get totalPages () {
        return this._totalPages
    }

    set totalPages (totalPages) {
        this._totalPages = totalPages
    }

    toJSON () {
        return {
            content: this.content,
            empty: this.empty,
            first: this.first,
            last: this.last,
            number: this.number,
            numberOfElements: this.numberOfElements,
            pageable: this.pageable,
            size: this.size,
            sort: this.sort,
            totalElements: this.totalElements,
            totalPages: this.totalPages
        }
    }
}
