<template>
    <div class="PaymentSuccessWithDeliveryModes">
        <div class="PaymentSuccessWithDeliveryModes-validationContainer">
            <icomoon-icon
                :name="'Check'"
                class="PaymentSuccessWithDeliveryModes-icon"
            />
            <h1 class="PaymentSuccessWithDeliveryModes-title h1">
                {{ title }}
            </h1>
        </div>
        <div
            v-if="validationAnimationFinished"
            :class="{ 'PaymentSuccessWithDeliveryModes-cards--onlyOne': cards.length === 1}"
            class="PaymentSuccessWithDeliveryModes-cards"
        >
            <payment-delivery-card
                v-for="(card, index) in cards"
                :id
                :key="`payment_card_${index}`"
                v-bind="card"
                @open-support-reader-modal="openSupportReaderModal()"
            />
        </div>
        <support-reader-modal
            v-model="showSupportReaderModal"
            :headerTitle="$t('card_reader:scan_modal:title')"
            :operationType="'WRITE'"
            @operation-finished="onSupportReaderOperationFinished"
        />
    </div>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue'
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon'
import PaymentDeliveryCard from '@/StoreWeb/components/payment/PaymentDeliveryCard'
import SupportReaderModal from '@/StoreWeb/components/miscellaneous/conduent-support-reader/SupportReaderModal'

const props = defineProps({
    baskets: {
        type: Array,
        required: true
    },
    id: {
        type: Number,
        required: true
    },
    title: {
        type: String,
        required: true
    }
})

const showSupportReaderModal = ref(false)
const validationAnimationFinished = ref(false)

const cards = computed(() => {
    const deliveryModes = {}

    props.baskets.forEach(basket => {
        basket.basket.products.forEach(product => {
            const deliveryMode = ['DISTRIBUTION_CARDREADER', 'CARDREADER_MANDATORY', 'MOBILE', 'POSTAL', 'POSTALONPRODUCT'].includes(product.item.deliveryMode) ? product.item.deliveryMode : 'DEFAULT'

            if (!deliveryModes[deliveryMode]) {
                deliveryModes[deliveryMode] = []
            }

            deliveryModes[deliveryMode].push(product.item)
        })
    })

    const cardsWithDeliveryModes = Object.keys(deliveryModes).map(deliveryMode => {
        const filteredBaskets = props.baskets.map(basket => {
            return {
                ...basket,
                basket: {
                    ...basket.basket,
                    products: basket.basket.products.filter(product => {
                        const productDeliveryMode = ['DISTRIBUTION_CARDREADER', 'CARDREADER_MANDATORY', 'MOBILE', 'POSTAL', 'POSTALONPRODUCT'].includes(product.item.deliveryMode) ? product.item.deliveryMode : 'DEFAULT'
                        return productDeliveryMode === deliveryMode
                    })
                }
            }
        }).filter(basket => basket.basket.products.length > 0)

        return {
            baskets: filteredBaskets,
            buttonLabel: ['DISTRIBUTION_CARDREADER', 'CARDREADER_MANDATORY'].includes(deliveryMode) ? `payment:success:${deliveryMode}:button` : '',
            description: deliveryMode === 'DEFAULT' ? '' : `payment:success:${deliveryMode}:description`,
            icon: deliveryMode,
            needCardReader: deliveryMode.includes('CARDREADER'),
            title: `payment:success:${deliveryMode}:title`
        }
    })

    return cardsWithDeliveryModes
})

function openSupportReaderModal () {
    showSupportReaderModal.value = true
}

function onSupportReaderOperationFinished () {
    showSupportReaderModal.value = false
}

onMounted(() => {
    setTimeout(() => {
        // Use this instead of css to avoid glitch
        validationAnimationFinished.value = true
    }, 3000)
})
</script>

<style lang='scss' scoped>
@import 'globalScss';

.PaymentSuccessWithDeliveryModes {
    display: flex;
    flex-direction: column;
    gap: $s10;

    &-validationContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        opacity: 0;
        transform: scale(2.2) translateY(100px);
        animation: animation-validation 3s forwards;
    }

    &-icon {
        font-size: 64px;
        color: $color-brandSecondary;
    }

    &-title {
        margin: 0;
    }

    &-cards {
        display: grid;
        grid-template-columns: 1fr;
        gap: $s4;
        border-radius: $borderRadius-xlarge;
        background: $backgroundColor-greyGradient;
        opacity: 0;
        transform: translateY(50px);
        animation: animation-cards 1s forwards;

        @media (min-width: $desktop-breakpoint) {
            grid-template-columns: repeat(2, 1fr);
            gap: $s6;
            border-radius: 0;
            background: $color-white;

            &--onlyOne {
                grid-template-columns: 1fr;
            }
        }
    }
}

@keyframes animation-validation {
    0% {
        opacity: 0;
        transform: scale(2.2) translateY(100px);
    }

    50% {
        opacity: 1;
        transform: scale(1) translateY(100px);
    }

    100% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
}

@keyframes animation-cards {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
</style>
