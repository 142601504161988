import { isEmpty } from 'global-utils'
import * as mainMutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import * as mutationTypes from '@/StoreWeb/store/modules/account-association/mutation-types'
import cachios from 'cachios'
import AccountAssociationNormalizer from '@/StoreWeb/normalizers/account-association/AccountAssociationNormalizerSingleton'
import AccountAssociationTypeNormalizer from '@/StoreWeb/normalizers/account-association/AccountAssociationTypeNormalizerSingleton'
import DebuggingManager from '@/StoreWeb/managers/DebuggingManagerSingleton'
import ErrorManager from '@/StoreWeb/managers/ErrorManager'

export default class {
    constructor () {
        this._debuggingManager = DebuggingManager.getInstance()
        this._accountAssociationNormalizer = AccountAssociationNormalizer.getInstance()
        this._accountAssociationTypeNormalizer = AccountAssociationTypeNormalizer.getInstance()
        this._store = null
    }

    get accountAssociationNormalizer () {
        return this._accountAssociationNormalizer
    }

    get accountAssociationTypeNormalizer () {
        return this._accountAssociationTypeNormalizer
    }

    get debuggingManager () {
        return this._debuggingManager
    }

    get store () {
        return this._store
    }

    set store (store) {
        this._store = store
    }

    /* =============================================================================================================
    =================================================== ACTIONS ====================================================
    ============================================================================================================= */
    createAccountAssociationAction (params) {
        return new Promise((resolve, reject) => {
            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
            this.store.commit(mutationTypes.SET_ACCOUNT_ASSOCIATION_PENDING_REQUEST, { key: 'createAccountAssociation', value: true })

            const body = {}

            if (params.type !== undefined && params.type !== null) {
                body.type = params.type
            }
            if (params.isMaster !== undefined && params.isMaster !== null) {
                body.isMaster = params.isMaster
            }
            if (params.mode !== undefined && params.mode !== null) {
                body.mode = params.mode
            }
            if (params.key !== undefined && params.key !== null) {
                body.key = params.key
            }
            if (params?.providerId) {
                body.providerId = params.providerId
            }
            if (params?.providerUserId) {
                body.providerUserId = params.providerUserId
            }
            if (params?.providerUserExternalId) {
                body.providerUserExternalId = params.providerUserExternalId
            }

            cachios
                .post(`${process.env.VUE_APP_API_URL}/accounts/associations/create`, body)
                .then(response => {
                    this.store.commit(mutationTypes.SET_ACCOUNT_ASSOCIATION_PENDING_REQUEST, { key: 'createAccountAssociation', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })

                    resolve(response.data)
                })
                .catch(error => {
                    this.store.commit(mutationTypes.SET_ACCOUNT_ASSOCIATION_PENDING_REQUEST, { key: 'createAccountAssociation', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })

                    reject(error)
                })
        })
    }

    getAccountAssociationAction (params) {
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
        this.store.commit(mutationTypes.SET_ACCOUNT_ASSOCIATION_PENDING_REQUEST, { key: 'getAssociation', value: true })
        cachios
            .get(`${process.env.VUE_APP_API_URL}/accounts/associations/read/${params.id}`)
            .then(response => {
                this.store.commit(mutationTypes.SET_ACCOUNT_ASSOCIATION_PENDING_REQUEST, { key: 'getAssociation', value: false })
                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
            })
            .catch(error => {
                ErrorManager.displayErrorModal('account_association:get_association', error)
                this.store.commit(mutationTypes.SET_ACCOUNT_ASSOCIATION_PENDING_REQUEST, { key: 'getAssociation', value: false })
                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
            })
    }

    getUserAccountAssociationsAction (params) {
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
        this.store.commit(mutationTypes.SET_ACCOUNT_ASSOCIATION_PENDING_REQUEST, { key: 'getUserAssociations', value: true })

        return new Promise((resolve, reject) => {
            if (this.debuggingManager.getMockActionState('getUserAccountAssociations')) {
                this.debuggingManager.getResponse('getUserAccountAssociations', (response) => {
                    const normalizeUserAccountAssociations = this.accountAssociationNormalizer.normalizeArray(response.data.associations)

                    this.store.commit(mutationTypes.SET_USER_ACCOUNT_ASSOCIATIONS, this.accountAssociationNormalizer.normalizeArray(response.data.associations))
                    this.store.commit(mutationTypes.SET_ACCOUNT_ASSOCIATION_PENDING_REQUEST, { key: 'getUserAssociations', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })

                    resolve(normalizeUserAccountAssociations)
                })
            } else {
                cachios
                    .get(`${process.env.VUE_APP_API_URL}/accounts/associations/list`, !isEmpty(params) && typeof params.cachiosOptions !== 'undefined' ? params.cachiosOptions : {})
                    .then(response => {
                        const normalizeUserAccountAssociations = this.accountAssociationNormalizer.normalizeArray(response.data.associations)

                        this.store.commit(mutationTypes.SET_USER_ACCOUNT_ASSOCIATIONS, normalizeUserAccountAssociations)
                        this.store.commit(mutationTypes.SET_ACCOUNT_ASSOCIATION_PENDING_REQUEST, { key: 'getUserAssociations', value: false })
                        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })

                        resolve(normalizeUserAccountAssociations)
                    })
                    .catch(error => {
                        ErrorManager.displayErrorModal('account_association:get_user_associations', error)
                        this.store.commit(mutationTypes.SET_ACCOUNT_ASSOCIATION_PENDING_REQUEST, { key: 'getUserAssociations', value: false })
                        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })

                        reject(error)
                    })
            }
        })
    }

    getAccountAssociationTypesAction (params) {
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
        this.store.commit(mutationTypes.SET_ACCOUNT_ASSOCIATION_PENDING_REQUEST, { key: 'getAssociationTypes', value: true })

        return new Promise((resolve, reject) => {
            if (this.debuggingManager.getMockActionState('getAccountAssociationTypes')) {
                this.debuggingManager.getResponse('getAccountAssociationTypes', (response) => {
                    const normalizedAssociationTypes = this.accountAssociationTypeNormalizer.normalizeArray(response.data.associationTypes)

                    this.store.commit(mutationTypes.SET_ACCOUNT_ASSOCIATION_TYPES, normalizedAssociationTypes)
                    this.store.commit(mutationTypes.SET_ACCOUNT_ASSOCIATION_PENDING_REQUEST, { key: 'getAssociationTypes', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })

                    resolve(normalizedAssociationTypes)
                })
            } else {
                cachios
                    .get(`${process.env.VUE_APP_API_URL}/accounts/associations/types`)
                    .then(response => {
                        const normalizedAssociationTypes = this.accountAssociationTypeNormalizer.normalizeArray(response.data.associationTypes)

                        this.store.commit(mutationTypes.SET_ACCOUNT_ASSOCIATION_TYPES, normalizedAssociationTypes)
                        this.store.commit(mutationTypes.SET_ACCOUNT_ASSOCIATION_PENDING_REQUEST, { key: 'getAssociationTypes', value: false })
                        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })

                        resolve(normalizedAssociationTypes)
                    })
                    .catch(error => {
                        ErrorManager.displayErrorModal('account_association:get_associations_types', error)
                        this.store.commit(mutationTypes.SET_ACCOUNT_ASSOCIATION_PENDING_REQUEST, { key: 'getAssociationTypes', value: false })
                        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })

                        reject(error)
                    })
            }
        })
    }

    deleteAccountAssociationAction (params) {
        return new Promise((resolve, reject) => {
            this.store.commit(mutationTypes.SET_ACCOUNT_ASSOCIATION_PENDING_REQUEST, { key: 'deleteAccountAssociation', value: true })
            const body = {}

            if (params?.associationId) {
                body.associationId = params.associationId
            }
            if (params?.providerId) {
                body.providerId = params.providerId
            }
            if (params?.providerUserId) {
                body.providerUserId = params.providerUserId
            }
            if (params?.providerUserExternalId) {
                body.providerUserExternalId = params.providerUserExternalId
            }

            cachios
                .post(`${process.env.VUE_APP_API_URL}/accounts/associations/delete`, body, params.cachiosOptions ?? {})
                .then(response => {
                    this.store.commit(mutationTypes.SET_ACCOUNT_ASSOCIATION_PENDING_REQUEST, { key: 'deleteAccountAssociation', value: false })

                    resolve(response.data)
                })
                .catch(error => {
                    ErrorManager.displayErrorModal('account_association:revoke_association', error)
                    this.store.commit(mutationTypes.SET_ACCOUNT_ASSOCIATION_PENDING_REQUEST, { key: 'deleteAccountAssociation', value: false })

                    reject(error)
                })
        })
    }
}
