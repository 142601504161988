import DirectDebit from '@/StoreWeb/models/user/direct-debit/DirectDebit'
import IdLabelPatternNormalizer from '@/StoreWeb/normalizers/patterns/IdLabelPatternNormalizerSingleton'
import { isEmpty } from 'global-utils'

export default class {
    constructor () {
        this._idLabelPatternNormalizer = IdLabelPatternNormalizer.getInstance()
    }

    get idLabelPatternNormalizer () {
        return this._idLabelPatternNormalizer
    }

    normalize (item) {
        const directDebit = new DirectDebit()

        directDebit.id = item.id
        directDebit.amount = item.amount
        if (!isEmpty(item.bankDebitId)) {
            directDebit.bankDebitId = item.bankDebitId
        }
        directDebit.date = item.date
        if (!isEmpty(item.effectiveDate)) {
            directDebit.effectiveDate = item.effectiveDate
        }
        directDebit.productLabel = item.productLabel
        directDebit.providerFareMediaId = item.providerFareMediaId
        directDebit.providerId = item.providerId
        directDebit.status = this.idLabelPatternNormalizer.normalize(item.status)
        directDebit.supportOwnerName = item.supportOwnerName

        return directDebit
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
