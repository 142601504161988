import CatalogProductSaleOption from '@/StoreWeb/models/catalog/CatalogProductSaleOption'
import IdLabelPatternNormalizer from '@/StoreWeb/normalizers/patterns/IdLabelPatternNormalizerSingleton'
import IdPatternNormalizer from '@/StoreWeb/normalizers/patterns/IdPatternNormalizerSingleton'
import { isEmpty } from 'global-utils'

export default class {
    constructor () {
        // Normalizers
        this._idLabelPatternNormalizer = IdLabelPatternNormalizer.getInstance()
        this._idPatternNormalizer = IdPatternNormalizer.getInstance()
    }

    get idLabelPatternNormalizer () {
        return this._idLabelPatternNormalizer
    }

    get idPatternNormalizer () {
        return this._idPatternNormalizer
    }

    normalize (item) {
        const saleOption = new CatalogProductSaleOption()

        if (!isEmpty(item.basketInfo)) {
            saleOption.basketInfo = this.idLabelPatternNormalizer.normalize(item.basketInfo)
        }
        if (!isEmpty(item.basketInfoSelectMode)) {
            saleOption.basketInfoSelectMode = this.idLabelPatternNormalizer.normalize(item.basketInfoSelectMode)
        }
        if (!isEmpty(item.deliveryMode)) {
            saleOption.deliveryMode = this.idLabelPatternNormalizer.normalize(item.deliveryMode)
        }
        if (!isEmpty(item.index)) {
            saleOption.index = item.index
        }
        if (!isEmpty(item.provider)) {
            saleOption.provider = this.idPatternNormalizer.normalize(item.provider)
        }
        if (!isEmpty(item.target)) {
            saleOption.target = this.idLabelPatternNormalizer.normalize(item.target)
        }
        if (!isEmpty(item.userAuthMode)) {
            saleOption.userAuthMode = this.idLabelPatternNormalizer.normalize(item.userAuthMode)
        }

        return saleOption
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
