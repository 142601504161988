export default class {
    constructor (
        id
    ) {
        this._billingAddress = null
        this._creationDate = null
        this._id = id
        this._orderAmountPaid = null
        this._orderId = null
        this._orderTaxPercent = null
        this._orderTotalAfterTax = null
        this._orderTotalAmountDue = null
        this._orderTotalBeforeTax = null
        this._orderTotalTax = null
        this._path = null
        this._receiverName = null
        this._status = status
        this._updateDate = null
        this._version = null
    }

    get billingAddress () {
        return this._billingAddress
    }

    set billingAddress (billingAddress) {
        this._billingAddress = billingAddress
    }

    get creationDate () {
        return this._creationDate
    }

    set creationDate (creationDate) {
        this._creationDate = creationDate
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get orderAmountPaid () {
        return this._orderAmountPaid
    }

    set orderAmountPaid (orderAmountPaid) {
        this._orderAmountPaid = orderAmountPaid
    }

    get orderId () {
        return this._orderId
    }

    set orderId (orderId) {
        this._orderId = orderId
    }

    get orderTaxPercent () {
        return this._orderTaxPercent
    }

    set orderTaxPercent (orderTaxPercent) {
        this._orderTaxPercent = orderTaxPercent
    }

    get orderTotalAfterTax () {
        return this._orderTotalAfterTax
    }

    set orderTotalAfterTax (orderTotalAfterTax) {
        this._orderTotalAfterTax = orderTotalAfterTax
    }

    get orderTotalAmountDue () {
        return this._orderTotalAmountDue
    }

    set orderTotalAmountDue (orderTotalAmountDue) {
        this._orderTotalAmountDue = orderTotalAmountDue
    }

    get orderTotalBeforeTax () {
        return this._orderTotalBeforeTax
    }

    set orderTotalBeforeTax (orderTotalBeforeTax) {
        this._orderTotalBeforeTax = orderTotalBeforeTax
    }

    get orderTotalTax () {
        return this._orderTotalTax
    }

    set orderTotalTax (orderTotalTax) {
        this._orderTotalTax = orderTotalTax
    }

    get path () {
        return this._path
    }

    set path (path) {
        this._path = path
    }

    get receiverName () {
        return this._receiverName
    }

    set receiverName (receiverName) {
        this._receiverName = receiverName
    }

    get status () {
        return this._status
    }

    set status (status) {
        this._status = status
    }

    get updateDate () {
        return this._updateDate
    }

    set updateDate (updateDate) {
        this._updateDate = updateDate
    }

    get version () {
        return this._version
    }

    set version (version) {
        this._version = version
    }

    toJSON () {
        return {
            aaaAaa: this.aaaAaa,
            bbbBbb: this.bbbBbb
        }
    }
}
