export const SET_CURRENT_LANGUAGE = 'setCurrentLanguage'
export const SET_CURRENT_ROUTE = 'setCurrentRoute'
export const SET_FEEDBACK_ERROR = 'setFeedbackError'
export const SET_FEEDBACK_SUCCESS = 'setFeedbackSuccess'
export const SET_SHOW_SUCCESS_TOAST = 'setShowSuccessToast'
export const SET_SHOW_ERROR_TOAST = 'setShowErrorToast'
export const SET_IS_CHECK_ACCOUNT_ENABLED = 'setIsCheckAccountEnabled'
export const SET_IS_LOGIN_MODULE_LOADED = 'setIsLoginModuleLoaded'
export const SET_IS_MOBILE_APP = 'setIsMobileApp'
export const SET_IS_MOBILE_OR_TABLET = 'setIsMobileOrTablet'
export const SET_MAIN_COLOR = 'setMainColor'
export const SET_MAIN_COLOR_DARK = 'setMainColorDark'
export const SET_MAIN_COLOR_LIGHT = 'setMainColorLight'
export const SET_PAGE_CONFIG = 'setPageConfig'
export const SET_PENDING_REQUEST = 'setPendingRequest'
export const SET_SHOW_GLOBAL_LOADER = 'setShowGlobalLoader'
export const SET_SHOW_MAIN_NAV = 'setShowMainNav'
export const SET_SHOW_MODAL = 'setShowModal'
