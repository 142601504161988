import * as mutationTypes from './mutation-types'

export default {
    [mutationTypes.SET_APPLIED_FILTERS] (state, appliedFilters) {
        state.appliedFilters = appliedFilters
    },
    [mutationTypes.SET_CURRENT_CATALOG_PARAMS] (state, currentCatalogParams) {
        state.currentCatalogParams = currentCatalogParams
    },
    [mutationTypes.SET_FILTERS] (state, filters) {
        state.filters = filters
    },
    [mutationTypes.SET_FARE_MEDIA_AVAILABLE_PRODUCTS] (state, fareMediaAvailableProducts) {
        state.fareMediaAvailableProducts = fareMediaAvailableProducts
    },
    [mutationTypes.SET_FARE_MEDIAS] (state, fareMedias) {
        state.fareMedias = fareMedias
    },
    [mutationTypes.SET_FINES] (state, fines) {
        state.fines = fines
    },
    [mutationTypes.SET_CATALOG_PENDING_REQUEST] (state, pendingRequest) {
        if (state.catalogPendingRequests[pendingRequest.key] !== undefined) {
            state.catalogPendingRequests[pendingRequest.key] = pendingRequest.value
        }
    },
    [mutationTypes.SET_PRODUCT_DETAILS] (state, productDetails) {
        state.productDetails = productDetails
    },
    [mutationTypes.SET_PRODUCT_TO_PUT_ON_CARD] (state, productToPutOnCard) {
        state.productToPutOnCard = productToPutOnCard
    },
    [mutationTypes.SET_PRODUCTS] (state, products) {
        state.products = products
    },
    [mutationTypes.SET_SECTIONS] (state, sections) {
        state.sections = sections
    },
    [mutationTypes.SET_WAITING_FOR_FILTERS] (state, waitingForFilters) {
        state.waitingForFilters = waitingForFilters
    }
}
