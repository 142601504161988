import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
    namespaced: false,
    actions,
    getters,
    mutations,
    state: {
        bankAccounts: null,
        pastDirectDebits: null,
        fareMediaProduct: null,
        futureDirectDebits: null,
        invoices: [],
        isAuthenticated: false,
        isLoggingOut: false,
        kasConfigurationSettings: null,
        userPendingRequests: {
            activateAccount: false,
            deactivateAccount: false,
            getCertificate: false,
            downloadFareMediaCertificate: false,
            getFutureDirectDebits: false,
            getPastDirectDebits: false,
            getInvoice: false,
            getInvoiceBinary: false,
            getInvoiceInfos: false,
            getInvoices: false,
            getNotifications: false,
            getOrder: false,
            getOrders: false,
            getPersonalInfo: false,
            getWallet: false,
            linkFareMedia: false,
            setNotificationAsRead: false,
            setEmail: false,
            setPersonalInfo: false,
            setPhone: false,
            terminatePlan: false,
            updatePersonalInfo: false,
            unlinkFareMedia: false,
            setNewAccount: false,
            setSubscription: false,
            getNewProfileAccount: false,
            setNewProfileAccount: false,
            getConsents: false,
            createProviderAccount: false,
            executeActionOnWalletContract: false
        },
        notifications: {},
        orders: [],
        personalInfo: null,
        orderPage: null,
        rawUserInfos: null,
        selectedFareMedia: null,
        selectedOrder: null,
        showAccountNav: false,
        showNotifications: false,
        userInfos: null,
        userStatus: '',
        waitingForActionFareMedia: null,
        wallet: null,
        downloadFareMediaCertificateError: null
    }
}
