<template>
    <component
        :is="'li'"
        class="HeaderCartItem"
    >
        <div class="HeaderCartItem-labelAndQuantity">
            <div class="HeaderCartItem-label">
                <span class="HeaderCartItem-quantity">
                    {{ item.quantity }}x
                </span>
                {{ item.name }}
                <div
                    v-if="displayProviderItemDescription"
                    class="HeaderCartItem-providerDescription"
                    v-html="$sanitize(item.providerItemDescription)"
                />
            </div>
            <p class="HeaderCartItem-providerNetworkLabel">
                {{ item.providerNetworkLabel }}
            </p>
            <formatted-fare
                v-if="item.totalFareIncludingTaxes && item.totalFareIncludingTaxes > 0"
                :currency="item?.currency"
                :fare="item?.totalFareIncludingTaxes"
                :size="'XSmall'"
                class="HeaderCartItem-fare"
            />
            <strong
                v-else
                class="HeaderCartItem-free"
            >
                {{ $t('catalog:free') }}
            </strong>
        </div>
        <template v-if="actions.length > 0">
            <router-link
                v-for="(action, index) in actions"
                :key="item.id + '-' + index"
                :to="action.route"
                class="HeaderCartItem-action"
            >
                {{ action.label }}
            </router-link>
        </template>
        <div
            v-if="!isEmpty(item.address)"
            class="HeaderCartItem-productAddress ml-2 mt-1 mb-1"
        >
            <p class="ma-0">
                {{ item.address.receiverFirstname }} {{ item.address.receiverLastname }}
            </p>
            <p class="ma-0">
                {{ item.address.streetNumber }} {{ item.address.streetName }}
            </p>
            <p class="ma-0">
                {{ item.address.zipCode }} {{ item.address.city }}
            </p>
            <p class="ma-0">
                {{ item.address.country }}
            </p>
        </div>
    </component>
</template>

<script setup>
import { computed } from 'vue'
import FormattedFare from '@/StoreWeb/components/common/FormattedFare'
import { isEmpty } from 'global-utils'

const props = defineProps({
    actions: {
        type: Array,
        default () { return [] }
    },
    item: {
        type: Object,
        required: true
    }
})

const displayProviderItemDescription = computed(() => props.item.providerItemDescription !== '' && props.item.id.indexOf('regularisation') !== -1)
</script>

<style lang="scss" scoped>
@import 'globalScss';

.HeaderCartItem {
    display: block;
    overflow: auto;
    padding: 15px 0;
    border-top: 1px solid $color-lightgray2;

    &-labelAndQuantity {
        display: flex;
        text-align: left;
    }

    &-label {
        display: block;
        padding-right: 5px;
        font-size: 14px;
        font-weight: $fontWeight-defaultMedium;
        color: $color-lightText;
    }

    &-productAddress {
        font-size: 12px;
        text-align: left;
    }

    &-providerDescription {
        font-size: 12px;
        color: $color-lightText;
    }

    &-providerNetworkLabel {
        font-size: 12px;
        font-weight: $fontWeight-defaultMedium;
        color: $color-lightText;
    }

    &-quantity {
        font-size: 12px;
        font-weight: bold;
        color: $color-brandPrimary;
    }

    &-fare {
        flex-grow: 1;
        text-align: end;
        color: $color-defaultText;
    }

    &-action {
        display: block;
        margin-top: $s1;
        font-size: 12px;
        text-align: left;
    }
}
</style>
