<template>
    <div class="HeaderNotificationList">
        <header-notification-list-toggle-button
            ref="button"
            :class="[(getShowNotificationList ? 'HeaderNotificationListToggleButton--active' : '')]"
            class="HeaderNotificationList-toggle"
            @button-clicked="updateHeaderNotificationListContentDisplay"
        />
        <header-notification-list-content
            v-if="$vuetify.display.mdAndUp"
            v-show="getShowNotificationList"
            v-click-outside="clickOutside"
            :class="'HeaderNotificationList-content' + getClassSuffixe"
            class="HeaderNotificationList-content"
        />
        <modal
            v-else
            v-model="getShowNotificationList"
            :title="$t('notification:modal:title')"
            :width="'full'"
            @close-modal="hideHeaderNotificationListContent"
        >
            <notification-list class="HeaderNotificationList-fullScreenModalList" />
        </modal>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import HeaderNotificationListContent from './HeaderNotificationListContent'
import HeaderNotificationListToggleButton from './HeaderNotificationListToggleButton'
import NotificationList from './NotificationList'
import { isEmpty, isOrContainsTarget } from 'global-utils'
import * as userMutationTypes from '@/StoreWeb/store/modules/user/mutation-types'
import config from 'config'

const store = useStore()

const notifications = computed(() => store.state.userModule.notifications)
const showNotifications = computed(() => store.state.userModule.showNotifications)

const getShowNotificationList = computed(() => {
    return showNotifications.value && !isEmpty(notifications.value.items)
})

const getClassSuffixe = computed(() => {
    return config.header.template === 'sytral' ? '--sytral' : '--default'
})

function clickOutside (event) {
    if (!isOrContainsTarget(event.target, document.querySelector('.HeaderNotificationList-toggle'))) {
        hideHeaderNotificationListContent()
    }
}

function hideHeaderNotificationListContent () {
    store.commit(userMutationTypes.SET_SHOW_NOTIFICATIONS, false)
}

function updateHeaderNotificationListContentDisplay () {
    if (!isEmpty(notifications.value.items)) {
        store.commit(userMutationTypes.SET_SHOW_NOTIFICATIONS, !showNotifications.value)
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.HeaderNotificationList {
    position: relative;

    &-content {
        position: absolute;
        top: 60px;
        right: 8px;
        padding: 0;

        &--sytral {
            top: 68px;

            @media (min-width: $desktop-breakpoint) {
                top: 67px;
            }
        }

        .NotificationItem.NotificationList-item {
            &:last-child {
                border-radius: 0 0 6px 6px;
            }
        }
    }
}
</style>
