import config from 'config'
import * as mainMutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import * as mutationTypes from '@/StoreWeb/store/modules/catalog/mutation-types'
import { isEmpty } from 'global-utils'
import emitter from 'global-emitter'
import cachios from 'cachios'
import CatalogProductNormalizer from '@/StoreWeb/normalizers/catalog/CatalogProductNormalizerSingleton'
import CatalogFilterNormalizer from '@/StoreWeb/normalizers/catalog/CatalogFilterNormalizerSingleton'
import CatalogSectionNormalizer from '@/StoreWeb/normalizers/catalog/CatalogSectionNormalizerSingleton'
import DebuggingManager from '@/StoreWeb/managers/DebuggingManagerSingleton'
import ErrorManager from '@/StoreWeb/managers/ErrorManager'
import * as cartMutationTypes from '@/StoreWeb/store/modules/cart/mutation-types'
import AddToCartFlowManager from '@/StoreWeb/managers/AddToCartFlowManagerSingleton'
import CatalogFilter from '@/StoreWeb/models/catalog/CatalogFilter'
import Fuse from 'fuse.js'
import { completeBodyWithProductParameters } from '@/StoreWeb/js/composables/add-to-cart-flow-utils'
// import CatalogMock from '@/StoreWeb/mocks/ODExample.json'
// import CheckCompatibilityMock from '@/StoreWeb/mocks/_qa/catalog/check-compatibility/check-compatibility-57-qa-37912-with-2001686524.json'
// import FareMediaCatalogMock from '@/StoreWeb/mocks/catalog/get-fare-media-catalog/get-fare-media-catalog-57-new-anonymous-faremedia-2023-06-29.json'
// import CatalogMock from '@/StoreWeb/mocks/catalog/get-catalog/get-catalog-57-sale-options.json'
// import CatalogMock from '@/StoreWeb/mocks/catalog/get-catalog-all46-v4.json'
// import CatalogMock from '@/StoreWeb/mocks/catalog/get-catalog-57-all-new-format.json'
// import CatalogMock from '@/StoreWeb/mocks/catalog/get-fine.json'
// import ProductDetailsMock from '@/StoreWeb/mocks/catalog/get-product-details/get-product-details-conduent41215-57-2024-02-06.json'

export default class {
    constructor () {
        this._catalogFilterNormalizer = CatalogFilterNormalizer.getInstance()
        this._catalogProductNormalizer = CatalogProductNormalizer.getInstance()
        this._catalogSectionNormalizer = CatalogSectionNormalizer.getInstance()
        this._debuggingManager = DebuggingManager.getInstance()

        this._appliedFilters = []
        this._currentCatalogParams = {}
        this._fareMediaAvailableProducts = []
        this._fareMediaProducts = []
        this._filters = []
        this._fines = []
        this._normalizedFareMediaProducts = []
        this._normalizedFilters = []
        this._normalizedFines = []
        this._normalizedProductDetails = null
        this._normalizedProducts = []
        this._normalizedSections = []
        this._products = []
        this._sections = []
        this._store = null
    }

    get catalogFilterNormalizer () {
        return this._catalogFilterNormalizer
    }

    get catalogProductNormalizer () {
        return this._catalogProductNormalizer
    }

    get catalogSectionNormalizer () {
        return this._catalogSectionNormalizer
    }

    get appliedFilters () {
        return this._appliedFilters
    }

    set appliedFilters (appliedFilters) {
        this._appliedFilters = appliedFilters
    }

    get currentCatalogParams () {
        return this._currentCatalogParams
    }

    set currentCatalogParams (currentCatalogParams) {
        const catalogParams = this.normalizeCurrentCatalogParams(currentCatalogParams)
        this.store.commit(mutationTypes.SET_CURRENT_CATALOG_PARAMS, catalogParams)
        this._currentCatalogParams = catalogParams
    }

    get debuggingManager () {
        return this._debuggingManager
    }

    get fareMediaAvailableProducts () {
        return this._fareMediaAvailableProducts
    }

    set fareMediaAvailableProducts (fareMediaAvailableProducts) {
        this._fareMediaAvailableProducts = fareMediaAvailableProducts
    }

    get fareMediaProducts () {
        return this._fareMediaProducts
    }

    set fareMediaProducts (fareMediaProducts) {
        this._fareMediaProducts = fareMediaProducts
    }

    get filters () {
        return this._filters
    }

    set filters (filters) {
        this._filters = filters
    }

    get fines () {
        return this._fines
    }

    set fines (fines) {
        this._fines = fines
    }

    get normalizedFareMediaAvailableProducts () {
        return this._normalizedFareMediaAvailableProducts
    }

    set normalizedFareMediaAvailableProducts (normalizedFareMediaAvailableProducts) {
        this._normalizedFareMediaAvailableProducts = normalizedFareMediaAvailableProducts
    }

    get normalizedFareMediaProducts () {
        return this._normalizedFareMediaProducts
    }

    set normalizedFareMediaProducts (normalizedFareMediaProducts) {
        this._normalizedFareMediaProducts = normalizedFareMediaProducts
    }

    get normalizedFilters () {
        return this._normalizedFilters
    }

    set normalizedFilters (normalizedFilters) {
        this._normalizedFilters = normalizedFilters
    }

    get normalizedFines () {
        return this._normalizedFines
    }

    set normalizedFines (normalizedFines) {
        this._normalizedFines = normalizedFines
    }

    get normalizedProductDetails () {
        return this._normalizedProductDetails
    }

    set normalizedProductDetails (normalizedProductDetails) {
        this._normalizedProductDetails = normalizedProductDetails
    }

    get normalizedProducts () {
        return this._normalizedProducts
    }

    set normalizedProducts (normalizedProducts) {
        this._normalizedProducts = normalizedProducts
    }

    get normalizedSections () {
        return this._normalizedSections
    }

    set normalizedSections (normalizedSections) {
        this._normalizedSections = normalizedSections
    }

    get products () {
        return this._products
    }

    set products (products) {
        this._products = products
    }

    get sections () {
        return this._sections
    }

    set sections (sections) {
        this._sections = sections
    }

    get store () {
        return this._store
    }

    set store (store) {
        this._store = store
    }

    normalizeCurrentCatalogParams (params) {
        const catalogParams = {}
        if (params.providerId) {
            catalogParams.providerId = params.providerId
        }
        if (params.providerUserId) {
            catalogParams.providerUserId = params.providerUserId
        }
        if (params.providerFareMediaId) {
            catalogParams.providerFareMediaId = params.providerFareMediaId
        } else if (params.fareMediaId) {
            catalogParams.providerFareMediaId = params.fareMediaId
        }
        if (params.providerDeviceId) {
            catalogParams.providerDeviceId = params.providerDeviceId
        } else if (params.deviceId) {
            catalogParams.providerDeviceId = params.deviceId
        }
        if (params.conditions) {
            catalogParams.conditions = params.conditions
        }
        return catalogParams
    }

    isProductOrPartOfProduct (product, id, expressPurchase) {
        if (product.id === id && product.expressPurchase === expressPurchase) {
            return true
        } else {
            if (!isEmpty(product.subProducts)) {
                let foundSubProduct
                product.subProducts.forEach(subProduct => {
                    if (subProduct.id === id) {
                        foundSubProduct = subProduct
                    } else if (this.isProductOrPartOfProduct(subProduct, id, expressPurchase)) {
                        return true
                    }
                })
                if (!isEmpty(foundSubProduct)) {
                    return true
                }
            }
            if (!isEmpty(product.variations)) {
                let foundVariation
                product.variations.forEach(variation => {
                    if (variation.id === id) {
                        foundVariation = variation
                    } else if (this.isProductOrPartOfProduct(variation, id, expressPurchase)) {
                        return true
                    }
                })
                if (!isEmpty(foundVariation)) {
                    return true
                }
            }
        }
        return false
    }

    getCompatibleProducts (products, filtersCompatibleProducts = null) {
        const compatibleProducts = []

        if (!isEmpty(products)) {
            products.forEach(product => {
                if (filtersCompatibleProducts === null || (filtersCompatibleProducts !== null && filtersCompatibleProducts.indexOf(product.id) !== -1)) {
                    compatibleProducts.push(product)
                }
            })
        }

        return compatibleProducts
    }

    getDisplayableSections ({ state, sections, filtersCompatibleProducts, level }) {
        const sectionsWithProducts = []
        let i = 0
        let key

        for (key in sections) {
            sectionsWithProducts[i] = {
                id: sections[key].id,
                name: sections[key].name,
                sections: sections[key].sections,
                sectionStyle: sections[key].sectionStyle
            }
            sectionsWithProducts[i].products = this.getCompatibleProducts(sections[key].products, filtersCompatibleProducts)
            sectionsWithProducts[i].level = level
            sectionsWithProducts[i].sectionStyle = sections[key].sectionStyle
            sectionsWithProducts[i].sections = this.getDisplayableSections({
                state,
                sections: sections[key].sections,
                filtersCompatibleProducts,
                level: level + 1
            })

            if (sectionsWithProducts[i].products.length === 0 && isEmpty(sections[key].sections)) {
                sectionsWithProducts.pop()
            } else {
                i++
            }
        }

        return sectionsWithProducts
    }

    getProductsPerSection (state) {
        const filtersCompatibleProducts = !isEmpty(state.appliedFilters) ? this.getFiltersCompatibleProductsIds(state) : null
        const sectionsWithProducts = this.getDisplayableSections({
            state,
            sections: state.sections,
            filtersCompatibleProducts,
            level: 1
        })

        return sectionsWithProducts
    }

    getFineProducts (state) {
        const fines = []
        if (!isEmpty(state.fines)) {
            let i
            for (i in state.fines) {
                if (state.fines[i].modalType === 'MODALPRODUCT' && state.fines[i].productType.id === 'FINE') {
                    fines.push(state.fines[i])
                }
            }
        }

        return fines
    }

    searchInProducts (products, filtersProductIds, search) {
        const options = {
            shouldSort: false,
            threshold: 0.3,
            location: 0,
            distance: 100,
            minMatchCharLength: 1,
            keys: [
                'name',
                'description'
            ]
        }

        let filteredProducts = products

        if (!isEmpty(filtersProductIds)) {
            filteredProducts = products.filter(product => filtersProductIds.includes(product.id))
        }

        const fuse = new Fuse(filteredProducts, options)
        const result = fuse.search(search)
        const ids = result.flatMap(product => product.item.id)

        return ids
    }

    getFiltersCompatibleProductsIds (state) {
        let filtersCompatibleProductsIds = []
        let isFirst = true

        for (const appliedFilter of state.appliedFilters) {
            if (appliedFilter.id === 'search' && !isEmpty(appliedFilter.value)) {
                const products = this.searchInProducts(state.products, filtersCompatibleProductsIds, appliedFilter.value)

                if (!isEmpty(products)) {
                    if (isFirst) {
                        filtersCompatibleProductsIds = products
                        isFirst = false
                    } else {
                        filtersCompatibleProductsIds = filtersCompatibleProductsIds.filter(value => products.includes(value))
                    }
                } else {
                    filtersCompatibleProductsIds = []
                }
            } else if (appliedFilter.value !== '') {
                const filter = appliedFilter.filters.find(item => item.id === appliedFilter.value)

                if (filter?.products) {
                    const products = filter.products
                    if (isFirst) {
                        filtersCompatibleProductsIds = products
                        isFirst = false
                    } else {
                        filtersCompatibleProductsIds = filtersCompatibleProductsIds.filter(value => products.includes(value))
                    }
                }
            }
        }

        return filtersCompatibleProductsIds
    }

    getFareMediaAvailableProducts (state) {
        if (!isEmpty(state.fareMediaAvailableProducts)) {
            if (!isEmpty(state.appliedFilters)) {
                const filtersCompatibleProducts = this.getFiltersCompatibleProductsIds(state)
                return state.fareMediaAvailableProducts.filter(product => filtersCompatibleProducts.indexOf(product.id) !== -1)
            }
            return state.fareMediaAvailableProducts
        }
        return []
    }

    getNormalizedSectionsAndFiltersFromCatalog (catalog) {
        return {
            filters: (!isEmpty(catalog) && !isEmpty(catalog.filters)) ? this.catalogFilterNormalizer.normalizeArray(catalog.filters) : [],
            sections: (!isEmpty(catalog) && !isEmpty(catalog.sections)) ? this.catalogSectionNormalizer.normalizeArray(catalog.sections) : []
        }
    }

    getCatalogHandler (data, eventName, pendingRequestKey, payload = {}) {
        let filtersAndSections
        this.normalizedFilters = {}
        this.normalizedSections = {}
        this.normalizedProducts = {}
        if (!isEmpty(data) && !isEmpty(data.catalog)) {
            filtersAndSections = this.getNormalizedSectionsAndFiltersFromCatalog(data.catalog)

            if (config?.catalog?.filters?.search) {
                const searchFilter = new CatalogFilter('search')
                searchFilter.displayMode = 'SEARCH'
                searchFilter.value = ''
                filtersAndSections.filters.push(searchFilter)
            }

            this.normalizedFilters = filtersAndSections?.filters ?? null
            this.normalizedSections = filtersAndSections?.sections ?? null
            this.normalizedProducts = this.getAllSectionsProducts(this.normalizedSections)
        }

        this.currentCatalogParams = payload
        this.store.commit(mutationTypes.SET_FILTERS, this.normalizedFilters)
        this.store.commit(mutationTypes.SET_PRODUCTS, this.normalizedProducts)
        this.store.commit(mutationTypes.SET_SECTIONS, this.normalizedSections)

        emitter.emit(eventName)

        this.store.commit(mutationTypes.SET_CATALOG_PENDING_REQUEST, { key: pendingRequestKey, value: false })
    }

    getFareMediasSuccessHandler (data) {
        this.fareMediaProducts = []
        if (data?.catalog?.sections) {
            this.fareMediaProducts = this.getAllSectionsProducts(this.catalogSectionNormalizer.normalizeArray(data.catalog.sections))
        }
        this.store.commit(mutationTypes.SET_FARE_MEDIAS, this.fareMediaProducts)
        emitter.emit('fareMediaCatalogLoaded')
        this.store.commit(mutationTypes.SET_CATALOG_PENDING_REQUEST, { key: 'getFareMedias', value: false })
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
    }

    getFinesSuccessHandler (data) {
        this.fines = []
        if (!isEmpty(data) && !isEmpty(data.catalog) && !isEmpty(data.catalog.product)) {
            this.fines = this.catalogProductNormalizer.normalizeArray([data.catalog.product])
        } else if (!isEmpty(data) && !isEmpty(data.catalog) && !isEmpty(data.catalog.sections)) {
            this.fines = this.getAllSectionsProducts(this.catalogSectionNormalizer.normalizeArray(data.catalog.sections))
        }
        this.store.commit(mutationTypes.SET_FINES, this.fines)
        emitter.emit('finesLoaded')
        this.store.commit(mutationTypes.SET_CATALOG_PENDING_REQUEST, { key: 'getFines', value: false })
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
    }

    getProductDetailsSuccessHandler (data, eventName, pendingRequestKey) {
        this.normalizedProductDetails = null

        if (!isEmpty(data?.catalog?.product)) {
            this.normalizedProductDetails = this.catalogProductNormalizer.normalize(data.catalog.product)
            this.store.commit(mutationTypes.SET_PRODUCT_DETAILS, this.normalizedProductDetails)
            emitter.emit(eventName)
        }

        this.store.commit(mutationTypes.SET_CATALOG_PENDING_REQUEST, { key: pendingRequestKey, value: false })
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
    }

    getAllSectionsProducts (sections, products) {
        if (isEmpty(products)) {
            products = []
        }

        if (!isEmpty(sections)) {
            sections.forEach(section => {
                if (!isEmpty(section.products)) {
                    section.products.forEach(product => {
                        products.push(product)
                    })
                }
                if (!isEmpty(section.sections)) {
                    products = this.getAllSectionsProducts(section.sections, products)
                }
            })
        }

        return products
    }

    findProductInCatalog (response, params) {
        let catalog = null
        const correspondingProducts = []
        if (response) {
            if (response?.data?.catalog) {
                catalog = response.data.catalog
            } else if (response?.catalog) {
                catalog = response.catalog
            }
        }
        if (catalog) {
            const filtersAndSections = this.getNormalizedSectionsAndFiltersFromCatalog(catalog)
            const products = this.getAllSectionsProducts(filtersAndSections.sections)

            if (products && params.productId && !isEmpty(params.productExpressPurchase)) {
                products.forEach(product => {
                    if (this.isProductOrPartOfProduct(product, params.productId, params.productExpressPurchase)) {
                        correspondingProducts.push(product)
                    }
                })
            }
        }

        return correspondingProducts
    }

    isCurrentCatalogParamsIdenticals (params) {
        if (
            (
                !isEmpty(this.currentCatalogParams) &&
                !isEmpty(params)
            ) ||
            (
                isEmpty(this.currentCatalogParams) &&
                isEmpty(params)
            )
        ) {
            switch (true) {
                case this.currentCatalogParams?.providerId !== params?.providerId:
                    return false
                case this.currentCatalogParams?.providerUserId !== params?.providerUserId:
                    return false
                case this.currentCatalogParams?.providerFareMediaId !== params?.providerFareMediaId:
                    return false
                case this.currentCatalogParams?.providerDeviceId !== params?.providerDeviceId:
                    return false
            }
            if (
                !isEmpty(this.currentCatalogParams.conditions) && !isEmpty(params.conditions) &&
                JSON.stringify(this.currentCatalogParams.conditions) !== JSON.stringify(params.conditions)
            ) {
                return false
            }
            return true
        }
        return false
    }

    /* =============================================================================================================
    =================================================== ACTIONS ====================================================
    ============================================================================================================= */
    checkCompatibilityAction (params) {
        this.store.commit(mutationTypes.SET_CATALOG_PENDING_REQUEST, { key: 'checkCompatibility', value: true })
        if (this.debuggingManager.getMockActionState('checkCompatibility')) {
            return this.debuggingManager.getResponse('checkCompatibility', (response) => {
                this.store.commit(mutationTypes.SET_CATALOG_PENDING_REQUEST, { key: 'checkCompatibility', value: true })
                const correspondingProducts = this.findProductInCatalog(response.data, {
                    productId: params.product.id
                })

                return {
                    correspondingProducts,
                    isCompatible: correspondingProducts.length > 0,
                    status: response.status
                }
            })
        } else {
            let payload
            if (params) {
                payload = {
                    store: 'WEB',
                    conditions: []
                }
                if (!isEmpty(params.providerId)) {
                    payload.providerId = params.providerId
                }
                if (!isEmpty(params.providerUserExternalId)) {
                    payload.providerUserExternalId = params.providerUserExternalId
                }
                if (!isEmpty(params.providerUserId)) {
                    payload.providerUserId = params.providerUserId
                }
                if (!isEmpty(params.fareMediaId)) {
                    payload.providerFareMediaId = params.fareMediaId
                }
                if (!isEmpty(params.conditions)) {
                    payload.conditions = params.conditions
                }
                if (!isEmpty(params.deviceId)) {
                    payload.providerDeviceId = params.deviceId
                }
            } else {
                if (!isEmpty(this.store.state.userModule.selectedFareMedia)) {
                    payload = {
                        store: 'WEB',
                        providerId: this.store.state.userModule.selectedFareMedia.providerId,
                        providerUserExternalId: this.store.state.userModule.selectedFareMedia.providerUserExternalId,
                        providerUserId: this.store.state.userModule.selectedFareMedia.accountId,
                        providerFareMediaId: this.store.state.userModule.selectedFareMedia.providerFareMediaId,
                        providerDeviceId: this.store.state.userModule.selectedFareMedia.providerDeviceId,
                        conditions: []
                    }
                } else {
                    payload = {
                        store: 'WEB',
                        conditions: []
                    }
                }
            }
            return cachios
                .post(`${process.env.VUE_APP_API_URL}/catalog`, payload, {
                    force: true
                })
                .then(async data => {
                    let response = data
                    if (this.debuggingManager.getMockActionState('getCatalog')) {
                        await this.debuggingManager.getResponse('getCatalog', (data) => {
                            response = data
                        })
                    }
                    this.store.commit(mutationTypes.SET_CATALOG_PENDING_REQUEST, {
                        key: 'checkCompatibility',
                        value: false
                    })
                    const correspondingProducts = this.findProductInCatalog(response.data, {
                        productId: params.product.id,
                        productExpressPurchase: params.product.expressPurchase
                    })
                    const isCompatibleProduct = correspondingProducts.length > 0
                    if (isCompatibleProduct) {
                        // Update the add-to-cart steps and update the product with the personalized catalog
                        const addToCartFlowManager = AddToCartFlowManager.getInstance()
                        addToCartFlowManager.updateAddCartFlow(correspondingProducts[0])
                    }

                    return {
                        correspondingProducts,
                        isCompatible: isCompatibleProduct,
                        status: response.status
                    }
                })
                .catch(error => {
                    ErrorManager.displayErrorModal('catalog:check_product_compatibility', error)
                    this.store.commit(mutationTypes.SET_CATALOG_PENDING_REQUEST, { key: 'checkCompatibility', value: false })
                    return {
                        error,
                        status: error?.response?.status
                    }
                })
        }
    }

    getCatalogAction (params) {
        this.store.commit(mutationTypes.SET_CATALOG_PENDING_REQUEST, { key: 'getCatalog', value: true })
        return new Promise((resolve, reject) => {
            if (this.debuggingManager.getMockActionState('getCatalog')) {
                this.debuggingManager.getResponse('getCatalog', (response) => {
                    if (typeof params !== 'undefined' && typeof params.callback !== 'undefined' && typeof params.callback === 'function') {
                        params.callback({ response })
                    } else {
                        // response.data = CatalogMock
                        this.getCatalogHandler(response.data, 'productsCatalogLoaded', 'getCatalog')
                    }
                    resolve()
                })
            } else {
                let payload
                if (params) {
                    payload = {
                        store: 'WEB',
                        conditions: []
                    }
                    if (!isEmpty(params.providerId)) {
                        payload.providerId = params.providerId
                    }
                    if (!isEmpty(params.providerUserId)) {
                        payload.providerUserId = params.providerUserId
                    }
                    if (!isEmpty(params.providerUserExternalId)) {
                        payload.providerUserExternalId = params.providerUserExternalId
                    }
                    if (!isEmpty(params.fareMediaId)) {
                        payload.providerFareMediaId = params.fareMediaId
                    }
                    if (!isEmpty(params.conditions)) {
                        payload.conditions = params.conditions
                    }
                    if (!isEmpty(params.deviceId)) {
                        payload.providerDeviceId = params.deviceId
                    }
                    if (!isEmpty(params.productIds)) {
                        payload.productsId = params.productIds
                    }
                } else {
                    if (!isEmpty(this.store.state.userModule.selectedFareMedia)) {
                        payload = {
                            store: 'WEB',
                            providerId: this.store.state.userModule.selectedFareMedia.providerId,
                            conditions: []
                        }
                        if (this.store.state.userModule.selectedFareMedia.providerFareMediaId) {
                            payload.providerFareMediaId = this.store.state.userModule.selectedFareMedia.providerFareMediaId
                        }
                        if (this.store.state.userModule.selectedFareMedia.providerDeviceId) {
                            payload.providerDeviceId = this.store.state.userModule.selectedFareMedia.providerDeviceId
                        }
                        if (this.store.state.userModule.selectedFareMedia.accountId) {
                            payload.providerUserId = this.store.state.userModule.selectedFareMedia.accountId
                        } else if (this.store.state.userModule.selectedFareMedia.providerUserId) {
                            payload.providerUserId = this.store.state.userModule.selectedFareMedia.providerUserId
                        }
                        if (this.store.state.userModule.selectedFareMedia.providerUserExternalId) {
                            payload.providerUserExternalId = this.store.state.userModule.selectedFareMedia.providerUserExternalId
                        }
                    } else {
                        payload = {
                            store: 'WEB',
                            conditions: []
                        }
                    }
                }
                cachios
                    .post(`${process.env.VUE_APP_API_URL}/catalog`, payload, {
                        force: true
                    })
                    .then(response => {
                        if (typeof params !== 'undefined' && typeof params.callback !== 'undefined' && typeof params.callback === 'function') {
                            params.callback({ response })
                        } else {
                            this.getCatalogHandler(response.data, 'productsCatalogLoaded', 'getCatalog', payload)
                        }
                        resolve()
                    })
                    .catch(error => {
                        ErrorManager.displayErrorModal('catalog:get_catalog:custom', error)
                        this.getCatalogHandler(null, 'productsCatalogLoaded', 'getCatalog', payload)
                        this.store.commit(mutationTypes.SET_CATALOG_PENDING_REQUEST, { key: 'getCatalog', value: false })
                    })
            }
        })
    }

    getCatalogByProductIdsAction (params) {
        this.store.commit(mutationTypes.SET_CATALOG_PENDING_REQUEST, { key: 'getCatalogByProducts', value: true })
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
        return new Promise((resolve, reject) => {
            const payload = {
                store: 'WEB',
                conditions: []
            }
            if (!isEmpty(params.providerId)) {
                payload.providerId = params.providerId
            }
            if (!isEmpty(params.providerFareMediaId)) {
                payload.providerFareMediaId = params.providerFareMediaId
            }
            if (!isEmpty(params.providerUserId)) {
                payload.providerUserId = params.providerUserId
            }
            if (!isEmpty(params.providerUserExternalId)) {
                payload.providerUserExternalId = params.providerUserExternalId
            }
            if (!isEmpty(params.productIds)) {
                payload.productIds = params.productIds
            }
            cachios
                .post(`${process.env.VUE_APP_API_URL}/catalog/products`, payload)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    ErrorManager.displayErrorModal('catalog:get_catalog', error)
                })
                .finally(() => {
                    this.store.commit(mutationTypes.SET_CATALOG_PENDING_REQUEST, { key: 'getCatalogByProducts', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                })
        })
    }

    getFareMediaCatalogAction (params) {
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
        this.store.commit(mutationTypes.SET_CATALOG_PENDING_REQUEST, { key: 'getFareMediaCatalog', value: true })

        if (this.debuggingManager.getMockActionState('getFareMediaCatalog')) {
            this.debuggingManager.getResponse('getFareMediaCatalog', (response) => {
                this.fareMediaAvailableProducts = []
                if (!isEmpty(response.data) && !isEmpty(response.data.catalog)) {
                    if (!isEmpty(response.data.catalog.sections)) {
                        this.fareMediaAvailableProducts = this.getAllSectionsProducts(this.catalogSectionNormalizer.normalizeArray(response.data.catalog.sections))
                    }
                    if (!isEmpty(response.data.catalog.filters)) {
                        this.normalizedFilters = this.catalogFilterNormalizer.normalizeArray(response.data.catalog.filters)
                    }
                }

                const ids = []
                this._normalizedFareMediaAvailableProducts = []
                this.fareMediaAvailableProducts.forEach((product, index) => {
                    if (typeof ids[product.id] === 'undefined') {
                        ids[product.id] = true
                        this._normalizedFareMediaAvailableProducts.push(product)
                    }
                })

                this.store.commit(mutationTypes.SET_FARE_MEDIA_AVAILABLE_PRODUCTS, this._normalizedFareMediaAvailableProducts)
                this.store.commit(mutationTypes.SET_FILTERS, this.normalizedFilters)
                emitter.emit('fareMediaCatalogLoaded')

                this.store.commit(mutationTypes.SET_CATALOG_PENDING_REQUEST, {
                    key: 'getFareMediaCatalog',
                    value: false
                })
                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
            })
        } else {
            const body = {
                store: 'WEB',
                conditions: []
            }

            if (!isEmpty(params)) {
                if (params.providerId) {
                    body.providerId = params.providerId
                }
                if (params.providerUserId) {
                    body.providerUserId = params.providerUserId
                }
                if (params.providerUserExternalId) {
                    body.providerUserExternalId = params.providerUserExternalId
                }
                if (params.id) {
                    body.providerFareMediaId = params.id
                }
            }

            cachios
                .post(`${process.env.VUE_APP_API_URL}/catalog`, body, {
                    force: true
                })
                .then(response => {
                    this.fareMediaAvailableProducts = []
                    if (!isEmpty(response.data) && !isEmpty(response.data.catalog)) {
                        if (!isEmpty(response.data.catalog.sections)) {
                            this.fareMediaAvailableProducts = this.getAllSectionsProducts(this.catalogSectionNormalizer.normalizeArray(response.data.catalog.sections))
                        }
                        if (!isEmpty(response.data.catalog.filters)) {
                            this.normalizedFilters = response.data.catalog.filters
                        }
                    }

                    const ids = []
                    this._normalizedFareMediaAvailableProducts = []
                    this.fareMediaAvailableProducts.forEach((product, index) => {
                        if (typeof ids[product.id] === 'undefined') {
                            ids[product.id] = true
                            this._normalizedFareMediaAvailableProducts.push(product)
                        }
                    })

                    this.store.commit(mutationTypes.SET_FARE_MEDIA_AVAILABLE_PRODUCTS, this._normalizedFareMediaAvailableProducts)
                    this.store.commit(mutationTypes.SET_FILTERS, this.normalizedFilters)
                    emitter.emit('fareMediaCatalogLoaded')

                    this.store.commit(mutationTypes.SET_CATALOG_PENDING_REQUEST, {
                        key: 'getFareMediaCatalog',
                        value: false
                    })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                })
                .catch(error => {
                    ErrorManager.displayErrorModal('catalog:get_fare_media_catalog', error)
                    this.store.commit(mutationTypes.SET_CATALOG_PENDING_REQUEST, {
                        key: 'getFareMediaCatalog',
                        value: false
                    })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                })
        }
    }

    getFareMediasAction (params) {
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
        this.store.commit(mutationTypes.SET_CATALOG_PENDING_REQUEST, { key: 'getFareMedias', value: true })
        return new Promise((resolve) => {
            if (this.debuggingManager.getMockActionState('getFareMedias')) {
                this.debuggingManager.getResponse('getFareMedias', (response) => {
                    this.getFareMediasSuccessHandler(response.data)
                    resolve()
                })
            } else {
                const payload = {
                    store: 'WEB',
                    conditions: [{
                        modalProductTypes: {
                            values: [
                                'FAREMEDIA'
                            ]
                        }
                    }]
                }
                if (params) {
                    if (!isEmpty(params.providerId)) {
                        payload.providerId = params.providerId
                    }
                    if (!isEmpty(params.providerUserId)) {
                        payload.providerUserId = params.providerUserId
                    }
                }
                cachios
                    .post(`${process.env.VUE_APP_API_URL}/catalog`, payload)
                    .then(response => {
                        this.getFareMediasSuccessHandler(response.data)
                        resolve(response.data)
                    })
                    .catch(error => {
                        ErrorManager.displayErrorModal('catalog:get_fare_medias', error)
                        this.store.commit(mutationTypes.SET_CATALOG_PENDING_REQUEST, { key: 'getFareMedias', value: false })
                        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                    })
            }
        })
    }

    getFinesAction (params) {
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
        this.store.commit(mutationTypes.SET_CATALOG_PENDING_REQUEST, { key: 'getFines', value: true })

        const body = {
            store: 'WEB'
        }

        if (!isEmpty(params) && !isEmpty(params.id)) {
            body.productId = params.id
        } else {
            body.conditions = [{
                modalProductTypes: {
                    values: [
                        'FINE'
                    ]
                }
            }]
        }

        if (this.debuggingManager.getMockActionState('getFines')) {
            this.debuggingManager.getResponse('getFines', (response) => {
                this.getFinesSuccessHandler(response.data)
            })
        } else {
            cachios
                .post(`${process.env.VUE_APP_API_URL}/catalog`, body)
                .then(response => {
                    this.getFinesSuccessHandler(response.data)
                })
                .catch(error => {
                    ErrorManager.displayErrorModal('catalog:get_fines', error)
                    this.store.commit(mutationTypes.SET_CATALOG_PENDING_REQUEST, { key: 'getFines', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                })
        }
    }

    completeBodyWithSelectedSaleOptionParameters (body, saleOptionStep) {
        if (
            !isEmpty(saleOptionStep.selectedSaleOptionParameters)
        ) {
            if (saleOptionStep.selectedSaleOptionParameters.providerId) {
                body.providerId = saleOptionStep.selectedSaleOptionParameters.providerId
            } else if (!isEmpty(saleOptionStep.selectedSaleOptionParameters.provider)) {
                body.providerId = saleOptionStep.selectedSaleOptionParameters.provider.id
            }
            if (saleOptionStep.selectedSaleOptionParameters.providerUserExternalId) {
                body.providerUserExternalId = saleOptionStep.selectedSaleOptionParameters.providerUserExternalId
            }
            if (saleOptionStep.selectedSaleOptionParameters.providerUserId) {
                body.providerUserId = saleOptionStep.selectedSaleOptionParameters.providerUserId
            }
            if (saleOptionStep.selectedSaleOptionParameters.fareMediaId) {
                body.providerFareMediaId = saleOptionStep.selectedSaleOptionParameters.fareMediaId
            } else if (!isEmpty(saleOptionStep.selectedSaleOptionParameters.fareMedia)) {
                body.providerFareMediaId = saleOptionStep.selectedSaleOptionParameters.fareMedia.id
            }
        }
        if (typeof body.providerId === 'undefined' && saleOptionStep.selectedSaleOption) {
            body.providerId = saleOptionStep.selectedSaleOption.provider.id
        }
    }

    getListByParameterName (params) {
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
        this.store.commit(mutationTypes.SET_CATALOG_PENDING_REQUEST, { key: 'getListByParameterName', value: true })

        return new Promise((resolve, reject) => {
            let url = `${process.env.VUE_APP_API_URL}/catalog/products/${params.product.id}/parameters/${params.productParameter.id}`
            const body = {}

            if (params.saleOptionStep) {
                this.completeBodyWithSelectedSaleOptionParameters(body, params.saleOptionStep)
            }

            completeBodyWithProductParameters(body, params)

            if (params?.currentProductParameterOptions?.subParameter) {
                url += `:${params.currentProductParameterOptions.subParameter.toUpperCase()}`
            }

            if (this.debuggingManager.getMockActionState('getListByParameterName')) {
                this.debuggingManager.getResponse('getListByParameterName', (response) => {
                    this.store.commit(mutationTypes.SET_CATALOG_PENDING_REQUEST, { key: 'getListByParameterName', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                    resolve(response.data)
                })
            } else {
                cachios.post(url, body, {
                    force: true
                })
                    .then(response => {
                        this.store.commit(mutationTypes.SET_CATALOG_PENDING_REQUEST, { key: 'getListByParameterName', value: false })
                        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                        resolve(response.data)
                    })
                    .catch(error => {
                        ErrorManager.displayErrorModal('catalog:get_list_by_parameter_name', error)
                        this.store.commit(mutationTypes.SET_CATALOG_PENDING_REQUEST, { key: 'getListByParameterName', value: false })
                        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })

                        reject(error)
                    })
            }
        })
    }

    getQuotationsAction (params) {
        return new Promise((resolve, reject) => {
            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
            this.store.commit(mutationTypes.SET_CATALOG_PENDING_REQUEST, { key: 'getQuotations', value: true })

            if (this.debuggingManager.getMockActionState('getQuotations')) {
                return this.debuggingManager.getResponse('getQuotations', (response) => {
                    emitter.emit('quotationsLoaded', response.data)

                    this.store.commit(mutationTypes.SET_CATALOG_PENDING_REQUEST, { key: 'getQuotations', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                    resolve(response)
                })
            }

            const body = {
                item: {
                    type: params.product.modalType,
                    id: params.product.id,
                    version: params.product.version,
                    parameters: []
                }
            }

            params.productParameters.forEach(item => {
                const param = {
                    productParameter: item.productParameter,
                    code: item.code ?? 'VALUE',
                    value: item.value
                }
                if (item.label) {
                    param.label = item.label
                }
                body.item.parameters.push(param)
            })

            if (params.saleOptionStep) {
                this.completeBodyWithSelectedSaleOptionParameters(body, params.saleOptionStep)
            }

            cachios.post(`${process.env.VUE_APP_API_URL}/catalog/products/${params.product.id}/quotations`, body, { force: true })
                .then(response => {
                    this.store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, false)
                    if (isEmpty(response.data.quotations)) {
                        this.store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, true)
                    }

                    this.store.commit(mutationTypes.SET_CATALOG_PENDING_REQUEST, { key: 'getQuotations', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                    resolve(response.data)
                })
                .catch(error => {
                    ErrorManager.displayErrorModal('catalog:get_quotations', error)
                    this.store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, true)
                    this.store.commit(mutationTypes.SET_CATALOG_PENDING_REQUEST, { key: 'getQuotations', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                    reject(error)
                })
        })
    }

    loadProductDetailsAction (params) {
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
        this.store.commit(mutationTypes.SET_CATALOG_PENDING_REQUEST, { key: 'getProductDetails', value: true })

        return new Promise((resolve, reject) => {
            if (this.debuggingManager.getMockActionState('getProductDetails')) {
                this.debuggingManager.getResponse('getProductDetails', (response) => {
                    this.getProductDetailsSuccessHandler(response.data, 'productLoaded', 'getProductDetails')
                    resolve(this.normalizedProductDetails)
                })
            } else {
                cachios
                    .post(`${process.env.VUE_APP_API_URL}/catalog`, {
                        store: 'WEB',
                        productId: params.id
                    })
                    .then(response => {
                        this.getProductDetailsSuccessHandler(response.data, 'productLoaded', 'getProductDetails')
                        resolve(this.normalizedProductDetails)
                    })
                    .catch(error => {
                        ErrorManager.displayErrorModal('catalog:get_product_details', error)
                        this.store.commit(mutationTypes.SET_CATALOG_PENDING_REQUEST, { key: 'getProductDetails', value: false })
                        reject(error)
                    })
            }
        })
    }
}
