<template>
    <div class="GridProductList">
        <div class="ProductList-products">
            <grid-product-item
                v-for="(product, productIndex) in products"
                :key="product.id"
                :product="product"
                :index="productIndex"
                class="ProductList-item"
            />
        </div>
    </div>
</template>

<script>
import GridProductItem from './GridProductItem'

export default {
    components: {
        GridProductItem
    },
    props: {
        products: {
            type: Array,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.ProductList {
    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-products {
        display: grid;
        grid-template-columns: repeat(2, calc(50% - 15px / 2));
        column-gap: 15px;
        row-gap: 15px;

        @media (min-width: $tablet-breakpoint) {
            grid-template-columns: repeat(3, calc(100% / 3 - 30px / 3));
        }

        @media (min-width: $desktop-breakpoint) {
            grid-template-columns: repeat(4, calc(25% - 45px / 4));
        }
    }
}
</style>
