<template>
    <v-app>
        <div id="app">
            <router-view />
        </div>
    </v-app>
</template>

<script setup>
import { onBeforeMount, provide } from 'vue'
import { INJECT_MAIN_APPLICATION_CONTEXT } from '@instant-system/web-harmony-widget'
import config from 'config'

provide(INJECT_MAIN_APPLICATION_CONTEXT, 'VAD')

onBeforeMount(async () => {
    if (config.use_web_harmony) {
        const whConfig = await import('@/../config/wh-config.json')

        if (whConfig?.default?.iconsCdn) {
            const link = document.createElement('link')
            link.href = whConfig.default.iconsCdn
            link.rel = 'stylesheet'
            document.head.appendChild(link)
        }
    }
})
</script>
