<template>
    <component
        :is="'li'"
        class="SaleOptionProviderDeviceChoiceItem mt-2"
    >
        <a
            :class="{ 'SaleOptionProviderDeviceChoiceItem-link--current': isActive }"
            class="SaleOptionProviderDeviceChoiceItem-link"
            @click.stop="selectDevice"
        >
            <span class="SaleOptionProviderDeviceChoiceItem-iconWrapper">
                <span
                    :class="getIcon"
                    class="SaleOptionProviderDeviceChoiceItem-icon"
                >
                    <span class="path1" />
                    <span class="path2" />
                    <span class="path3" />
                </span>
                <span
                    v-if="mdiIcon"
                    class="SaleOptionProviderDeviceChoiceItem-icon"
                    :class="mdiIcon"
                />
            </span>
            <span class="SaleOptionProviderDeviceChoiceItem-label">
                <strong class="SaleOptionProviderDeviceChoiceItem-title">{{ getLabel }}</strong>
                <span class="SaleOptionProviderDeviceChoiceItem-subtitle">{{ getDescription }}</span>
            </span>
        </a>
    </component>
</template>

<script>
import { isEmpty } from 'global-utils'

export default {
    props: {
        isActive: {
            type: Boolean,
            default: false
        },
        device: {
            type: Object,
            required: true
        },
        icon: {
            type: String,
            default: 'Phone'
        },
        mdiIcon: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        }
    },
    computed: {
        getLabel () {
            if (!isEmpty(this.device)) {
                return this.device.recipientUser.getFullName()
            }
            return ''
        },
        getIcon () {
            return 'Icon' + this.icon + this.mdiIcon
        },
        getDescription () {
            return this.device.label
        }
    },
    methods: {
        selectDevice () {
            this.$emit('deviceClicked', this.device)
        }
    }
}
</script>
<style lang="scss" scoped>
@import 'globalScss';

.SaleOptionProviderDeviceChoiceItem {
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 0;
    }

    &-link {
        display: flex;
        padding: 10px 10px 14px;
        border-radius: 6px;
        box-shadow: $box-shadow-light;
        background: $color-lightest;
        color: $color-lighterText;
        transition: background .3s;

        &:hover,
        &:active,
        &--current {
            background: $color-lightgray3;
        }
    }

    &:first-child {
        padding-top: 0;
        border-top: none;
    }

    &-iconWrapper {
        position: relative;
        width: 32px;
    }

    &-icon {
        position: absolute;
        font-size: 30px;
        color: $color-brandPrimary;
    }

    .Icon {
        &FareMediaIllust {
            top: 0;
            left: -2px;

            .path3 {
                color: $color-brandPrimary;
            }
        }

        &Card {
            top: -3px;
            left: -2px;
            color: $color-brandPrimary;
        }

        &Phone {
            top: 0;
            left: -2px;
        }
    }

    &-label {
        flex-grow: 1;
    }

    &-title {
        display: block;
        font-family: $fontFamily-title;
        font-size: 18px;
        font-weight: $fontWeight-titleBold;
        color: $color-defaultText;
    }

    &-subtitle {
        font-size: 12px;
        font-weight: $fontWeight-defaultMedium;
    }
}
</style>
