import ETicket from '@/StoreWeb/models/user/wallet/ETicket'
import ETicketProductNormalizer from '@/StoreWeb/normalizers/eticket/ETicketProductNormalizerSingleton'
import { isEmpty } from 'global-utils'

export default class {
    constructor () {
        this._eTicketProductNormalizer = ETicketProductNormalizer.getInstance()
    }

    get eTicketProductNormalizer () {
        return this._eTicketProductNormalizer
    }

    normalize (item) {
        const eTicket = new ETicket()

        eTicket.creationDate = item.creationDate
        eTicket.product = this.eTicketProductNormalizer.normalize(item.product)

        return eTicket
    }

    normalizeArray (items, params) {
        const normalizedItems = []

        if (typeof params === 'undefined') {
            params = {}
        }

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item, params))
            })
        }

        return normalizedItems
    }
}
