import Counter from '@/StoreWeb/models/user/wallet/Counter'
import UserModalCounterTransactionNormalizer from '@/StoreWeb/normalizers/user/wallet/UserModalCounterTransactionNormalizerSingleton'
import { isEmpty } from 'global-utils'

export default class {
    constructor () {
        this._userModalCounterTransactionNormalizer = UserModalCounterTransactionNormalizer.getInstance()
    }

    get userModalCounterTransactionNormalizer () {
        return this._userModalCounterTransactionNormalizer
    }

    normalize (item) {
        const counter = new Counter(item.id)

        counter.name = item.name
        counter.totalValue = item.totalValue
        counter.unitCode = item.unitCode
        counter.unitLabel = item.unitLabel
        counter.unitLabel = item.unitLabel
        counter.transactions = []

        if (!isEmpty(item.transactions)) {
            counter.transactions = this.userModalCounterTransactionNormalizer.normalizeArray(item.transactions)
        }

        return counter
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
