<template>
    <div class="FareMediaSelector mb-8">
        <v-card class="rounded-xl">
            <v-card-title
                class="h3 ma-0 FareMediaSelector-title"
            >
                <template v-if="!isEmpty(selectedFareMedia?.id)">
                    <span
                        :class="'Icon' + selectedFareMedia.getIcon()"
                        class="FareMediaSelector-typeIcon"
                    />
                    <span class="FareMediaSelector-description">{{ t('sale_option:basket_info:provider_account_and_faremedia') }}</span>
                </template>
                <template v-else>
                    <span class="FareMediaSelector-description">{{ t('fare_media_selector:default_title') }}</span>
                </template>
            </v-card-title>
            <v-card-text>
                <div
                    v-if="isAuthenticated"
                    class="d-flex justify-space-between align-start gap-3"
                >
                    <div
                        v-if="fareMediaTitle !== ''"
                        class="FareMediaSelector-state"
                    >
                        <span>
                            <router-link
                                :to="{ name: 'tickets', params: { fareMediaId: selectedFareMedia.id }}"
                                class="FareMediaSelector-cardName"
                            >
                                {{ fareMediaTitle }}
                            </router-link>
                            <span v-if="fareMediaSubtitle !== ''">
                                {{ fareMediaSubtitle }}
                            </span>
                        </span>
                    </div>
                    <v-btn
                        v-if="allFareMedias.length > 0"
                        class="rounded-xl"
                        color="primary"
                        elevation="0"
                        @click="setShowFareMediaChoiceModal"
                    >
                        <template
                            v-if="isEmpty(selectedFareMedia)"
                        >
                            {{ t('fare_media:select_card_button_text') }}
                        </template>
                        <template v-else>
                            <v-icon>
                                mdi-swap-horizontal
                            </v-icon>
                            {{ t('button:switch') }}
                        </template>
                    </v-btn>
                    <div
                        v-else
                        class="d-flex flex-wrap gap-2"
                    >
                        <v-btn
                            v-if="providersSupportingLinking.length > 0"
                            class="rounded-xl"
                            color="primary"
                            elevation="0"
                            @click="setShowFareMediaLinkingModal"
                        >
                            <template
                                v-if="isEmpty(selectedFareMedia)"
                            >
                                {{ t('fare_media:select_card_button_text') }}
                            </template>
                            <template v-else>
                                <v-icon>
                                    mdi-swap-horizontal
                                </v-icon>
                                {{ t('button:switch') }}
                            </template>
                        </v-btn>
                        <v-btn
                            v-if="showFareMediaIdModalButton"
                            class="rounded-xl"
                            color="primary"
                            elevation="0"
                            variant="outlined"
                            @click="setShowFareMediaIdModal"
                        >
                            {{ t('button:read_card') }}
                        </v-btn>
                    </div>
                </div>
                <template v-else-if="selectedFareMedia?.id">
                    <div class="d-flex justify-space-between align-start gap-3">
                        <div
                            v-if="fareMediaTitle !== ''"
                            class="FareMediaSelector-state"
                        >
                            <span>
                                <router-link
                                    :to="{ name: 'walletLight', params: { providerId: selectedFareMedia.providerId, fareMediaId: selectedFareMedia.id }}"
                                    class="FareMediaSelector-cardName"
                                >
                                    {{ fareMediaTitle }}
                                </router-link>
                                <span v-if="fareMediaSubtitle !== ''">
                                    {{ fareMediaSubtitle }}
                                </span>
                            </span>
                        </div>
                        <v-btn
                            class="rounded-xl"
                            color="primary"
                            elevation="0"
                            @click="setShowFareMediaIdModal"
                        >
                            <v-icon>
                                mdi-swap-horizontal
                            </v-icon>
                            {{ t('button:switch') }}
                        </v-btn>
                    </div>
                    <div class="FareMediaSelector-loginMessage">
                        {{ t('fare_media_selector:login_for_retrieving_card') }}
                        <v-btn
                            class="FareMediaSelector-loginMessageButton d-inline-flex py-0"
                            color="primary"
                            elevation="0"
                            variant="text"
                            size="x-small"
                            @click="logIn"
                        >
                            {{ t('button:login') }}
                        </v-btn>
                    </div>
                </template>
                <div
                    v-else
                    class="d-flex flex-wrap gap-2"
                >
                    <v-btn
                        class="rounded-xl"
                        color="primary"
                        elevation="0"
                        @click="logIn"
                    >
                        {{ t('button:login') }}
                    </v-btn>
                    <v-btn
                        class="rounded-xl"
                        color="primary"
                        elevation="0"
                        variant="outlined"
                        @click="setShowFareMediaIdModal"
                    >
                        {{ t('button:read_card') }}
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
        <fare-media-id-modal v-model="showFareMediaIdModal" />
        <fare-media-linking-modal v-model="showFareMediaLinkingModal" />
    </div>
</template>

<script setup>
import {
    computed,
    onMounted,
    ref
} from 'vue'
import { useRoute } from 'vue-router'
import config from 'config'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { isEmpty } from 'global-utils'
import emitter from 'global-emitter'
import FareMediaIdModal from '@/StoreWeb/components/fare-media/FareMediaIdModal'
import FareMediaLinkingModal from '@/StoreWeb/components/fare-media/FareMediaLinkingModal'
import * as mainMutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import * as userMutationTypes from '@/StoreWeb/store/modules/user/mutation-types'
import { checkAndGetFareMedia } from '@/StoreWeb/js/mixins/faremedia-utils'
import { getSelectedFareMediaCookie, removeSelectedFareMediaCookie } from '@/StoreWeb/js/mixins/wallet-utils'
import { startLogInProcess, useLogin } from '@/StoreWeb/js/composables/login-utils'

const store = useStore()
const { isAuthenticated } = useLogin()

const emit = defineEmits(['switchFareMediaContext'])

const route = useRoute()
const { t } = useI18n()

const showFareMediaIdModal = ref(false)
const showFareMediaLinkingModal = ref(false)

const getCartFareMedias = computed(() => store.getters.getCartFareMedias)
const fareMedias = computed(() => store.getters.getWalletFareMedias)
const allFareMedias = computed(() => {
    return [...getCartFareMedias.value, ...fareMedias.value]
})
const fareMediaSubtitle = computed(() => {
    return hasSelectedFareMedia.value && !selectedFareMedia.value.isAccount ? selectedFareMedia.value.getSubtitle() : ''
})
const fareMediaTitle = computed(() => {
    return hasSelectedFareMedia.value && !selectedFareMedia.value.isAccount ? selectedFareMedia.value.getTitle() : ''
})
const hasSelectedFareMedia = computed(() => selectedFareMedia.value?.id !== undefined)
const showFareMediaIdModalButton = computed(() => {
    return isEmpty(config?.providers?.fare_media)
        ? []
        : config.providers.filter(provider => provider.fare_media?.manual_input || provider.fare_media?.reader)
})
const providersSupportingLinking = computed(() => {
    return isEmpty(config.providers)
        ? []
        : config.providers.filter(provider =>
            provider?.fare_media?.add || provider?.device?.add || provider?.account?.add
        )
})
const selectedFareMedia = computed(() => store.state.userModule.selectedFareMedia)

const checkAndApplyFareMedia = async (support) => {
    store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { keepShow: true, title: t('default_loading:title'), subtitle: t('default_loading:subtitle'), value: true })
    const params = {
        providerId: support.providerId,
        fareMediaId: support.fareMediaId
    }
    if (!isEmpty(support.providerUserId)) {
        params.accountId = support.providerUserId
    }
    if (support?.providerUserExternalId) {
        params.providerUserExternalId = support.providerUserExternalId
    }
    if (support?.isCadMode) {
        params.isCadMode = true
    }
    const response = await checkAndGetFareMedia(params)
    store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { keepShow: false, resetTexts: true, value: false })

    if (response.isValid && response?.fareMedia?.id) {
        let fareMedia = response?.fareMedia

        if (isAuthenticated.value && fareMedias.value.length > 0) {
            const walletFareMedia = fareMedias.value.find(walletFareMedia => walletFareMedia.id === fareMedia.id)

            if (!isEmpty(walletFareMedia)) {
                fareMedia = walletFareMedia
            }
        }
        store.commit(userMutationTypes.SET_SELECTED_SUPPORT, fareMedia)
        emitter.emit('selectedSupportChanged')
    } else {
        store.commit(userMutationTypes.SET_SELECTED_SUPPORT, null)
        removeSelectedFareMediaCookie()
    }
}

const logIn = () => {
    startLogInProcess(route)
}

const setShowFareMediaChoiceModal = () => {
    emit('switchFareMediaContext')
}

const setShowFareMediaIdModal = () => {
    showFareMediaIdModal.value = true
}

const setShowFareMediaLinkingModal = () => {
    showFareMediaLinkingModal.value = true
}

onMounted(() => {
    if (!selectedFareMedia.value) {
        const selectedFareMediaCookie = getSelectedFareMediaCookie()

        if (!isEmpty(selectedFareMediaCookie) && selectedFareMediaCookie.fareMediaId !== 'ALL') {
            checkAndApplyFareMedia(selectedFareMediaCookie)
        }
    }
})
</script>

<style lang="scss" scoped>
@import 'globalScss';

.FareMediaSelector {
    &-typeIcon {
        margin-right: $s1;
        font-size: 36px;
        color: $color-brandPrimary;
    }

    &-title {
        @include verticalCenter;

        white-space: break-spaces;
    }

    &-description {
        display: block;
    }

    &-cardName {
        display: block;
        font-weight: $fontWeight-defaultBold;
        color: $color-brandPrimary;
        transition: color .3s ease-in-out;

        &:hover,
        &:active {
            color: $color-brandPrimaryDark;
        }
    }

    &-loginMessage {
        margin-top: $s4;
        margin-bottom: 0;
        padding: $s2 $s4;
        border-radius: $borderRadius-large;
        background-color: rgba($button-primary-backgroundColor, .08);
        font-size: 14px;
        color: $color-lightText;
    }

    &-loginMessageButton :deep(.v-btn__content) {
        font-size: 14px;
        font-weight: $fontWeight-defaultBold;
    }
}
</style>
