import * as mutationTypes from './mutation-types'

export default {
    [mutationTypes.SET_ACCOUNT_ASSOCIATION_PENDING_REQUEST] (state, pendingRequest) {
        if (state.accountAssociationPendingRequests[pendingRequest.key] !== undefined) {
            state.accountAssociationPendingRequests[pendingRequest.key] = pendingRequest.value
        }
    },
    [mutationTypes.SET_ACCOUNT_ASSOCIATION_TYPES] (state, accountAssociationTypes) {
        state.accountAssociationTypes = accountAssociationTypes
    },
    [mutationTypes.SET_USER_ACCOUNT_ASSOCIATIONS] (state, userAccountAssociations) {
        state.userAccountAssociations = userAccountAssociations
    }
}
