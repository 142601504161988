<template>
    <div class="Account">
        <div class="Account-block Account-notificationsBlock">
            <div class="Account-blockTitle h3">
                {{ $t('account:notifications:title') }}
            </div>
            <notification-list
                :display-style="'card'"
                :limit="3"
                class="Account-notificationsList Card"
            />
        </div>
    </div>
</template>

<script>
import DefaultView from '@/StoreWeb/views/DefaultView'
import NotificationList from '@/StoreWeb/components/account/notification/NotificationList'
import PageConfigManager from '@/StoreWeb/managers/PageConfigManagerSingleton'
const pageConfigManager = PageConfigManager.getInstance()

export default {
    components: {
        NotificationList
    },
    extends: DefaultView,
    mounted () {
        pageConfigManager.setConfig({
            bodyClasses: 'body--pageAccount',
            title: 'account:account:title',
            currentAccountPage: 'account'
        })
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.Account {
    &-block {
        margin-bottom: 25px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-list.NotificationList {
        border: 1px solid $color-lightgray4;
    }
}
</style>
