<template>
    <div class="DefaultView" />
</template>

<script>
import {
    mapState
} from 'vuex'
import { isEmpty } from 'global-utils'
import PageConfigManager from '@/StoreWeb/managers/PageConfigManagerSingleton'
const pageConfigManager = PageConfigManager.getInstance()

export default {
    name: 'DefaultView',
    computed: {
        ...mapState({
            pageConfig: state => state.pageConfig
        })
    },
    onMounted () {
        this.removePreviousPageClasses()
    },
    mounted () {
        this.$nextTick(() => {
            this.addNewPageClasses()
        })
    },
    methods: {
        addNewPageClasses () {
            if (this.hasBodyClasses()) {
                document.body.classList.add(this.pageConfig.bodyClasses)
            }
        },
        hasBodyClasses () {
            return !isEmpty(this.pageConfig) && !isEmpty(this.pageConfig.bodyClasses)
        },
        removePreviousPageClasses () {
            if (this.hasBodyClasses()) {
                document.body.classList.remove(this.pageConfig.bodyClasses)
            }
            pageConfigManager.setConfig({})
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.DefaultView {
    /* Add css */
}
</style>
