<template>
    <div
        :role="inputRole"
        class="BaseInputField"
    >
        <input
            :id="'js-input-' + inputId"
            v-model="currentValue"
            :aria-label="label"
            :aria-required="isRequired"
            :autocomplete="autocomplete"
            :class="getInputClass"
            :maxlength="getMaxLength"
            :minlength="getMinLength"
            :pattern="getPattern !== '' ? getPattern : ''"
            :readonly="readonly"
            :style="{ borderColor: getBorderColor, 'box-shadow': getBoxShadow }"
            :type="inputType"
            :required="isRequired"
            class="BaseInputField-input"
            tabindex="0"
            @input="fieldValueChanged"
            @keydown="checkPattern"
            @focus="focused=true"
            @blur="focused=false"
        >
        <button
            :aria-label="$t('form:input:clear_field')"
            :tabindex="getTabIndexIcon"
            class="BaseInputField-button Accessibility-reachableByTab"
            type="button"
            @click="clearInputField"
        >
            <span
                :class="{ 'BaseInputField-icon--hoverable': getCurrentValueNotEmpty }"
                tabindex="-1"
                class="BaseInputField-icon material-icons Accessibility-noReachableByClick"
            >
                {{ getIconValue }}
            </span>
        </button>
        <label
            :for="'js-error-' + inputId"
            class="BaseInputField-label"
        >
            {{ label }}
        </label>
        <div
            v-if="description !== ''"
            class="BaseInputField-description"
        >
            {{ description }}
        </div>
        <div
            :id="'js-error-' + inputId"
            :aria-label="errorLabel"
            :tabindex="getErrorTabIndex"
            :class="{ 'BaseInputField-error--displayed': hasError }"
            class="BaseInputField-error"
        >
            {{ errorLabel }}
        </div>
        <div
            :id="'js-success-' + inputId"
            :aria-label="successLabel"
            :tabindex="getSuccessTabIndex"
            :class="{ 'BaseInputField-success--displayed': isValid }"
            class="BaseInputField-success"
        >
            {{ successLabel }}
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { isEmpty } from 'global-utils'

export default {
    name: 'BaseInputField',
    props: {
        autocomplete: {
            type: String,
            default: 'on',
            validator: (val) => ['off', 'on', 'name', 'honorific-prefix', 'given-name', 'additional-name', 'family-name', 'honorific-suffix', 'nickname', 'email', 'username', 'new-password', 'current-password', 'one-time-code', 'organization-title', 'organization', 'street-address', 'address-line1', 'address-line2', 'address-line3', 'address-level4', 'address-level3', 'address-level2', 'address-level1', 'country', 'country-name', 'postal-code', 'cc-name', 'cc-given-name', 'cc-additional-name', 'cc-family-name', 'cc-number', 'cc-exp', 'cc-exp-month', 'cc-exp-year', 'cc-csc', 'cc-type', 'transaction-currency', 'transaction-amount', 'language', 'bday', 'bday-day', 'bday-month', 'bday-year', 'sex', 'tel', 'tel-country-code', 'tel-national', 'tel-area-code', 'tel-local', 'tel-extension', 'impp', 'url', 'photo'].includes(val)
        },
        defaultValue: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        errorLabel: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            default: ''
        },
        inputType: {
            type: String,
            default: 'text',
            validator: (val) => ['email', 'number', 'password', 'search', 'tel', 'text', 'url'].includes(val)
        },
        isRequired: {
            type: Boolean,
            default: true
        },
        isSearchField: {
            type: Boolean,
            default: false
        },
        isStrictPattern: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            required: true
        },
        inputRole: {
            type: String,
            default: '',
            validator: (val) => ['', 'search'].includes(val)
        },
        maxLength: {
            type: Number,
            default: 0
        },
        minLength: {
            type: Number,
            default: 0
        },
        pattern: {
            type: String,
            default: ''
        },
        readonly: {
            type: Boolean,
            default: false
        },
        successLabel: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            currentValue: '',
            focused: false,
            inputId: null,
            previousValue: ''
        }
    },
    computed: {
        ...mapState(['mainColor', 'mainColorLight', 'mainColorDark']),
        getBorderColor () {
            return this.getCurrentValueNotEmpty || this.focused
                ? this.mainColor
                : ''
        },
        getBoxShadow () {
            return this.getCurrentValueNotEmpty || this.focused
                ? ' inset 0 0 3px 0 ' +
                this.mainColorLight
                : 'none'
        },
        getCurrentValueNotEmpty () {
            return this.currentValue !== ''
        },
        getErrorTabIndex () {
            return this.errorLabel !== '' ? '0' : '-1'
        },
        getIconValue () {
            return this.currentValue !== '' ? 'close' : this.isSearchField ? 'search' : ''
        },
        getInputClass () {
            return this.getCurrentValueNotEmpty || this.focused
                ? `${this.$options.name}-input--notEmpty`
                : `${this.$options.name}-input--inactive`
        },
        getMaxLength () {
            return this.maxLength > 0 ? this.maxLength : false
        },
        getMinLength () {
            return this.minLength > 0 ? this.minLength : false
        },
        hasError () {
            return this.errorLabel !== ''
        },
        isValid () {
            return this.successLabel !== ''
        },
        getPattern () {
            if (!isEmpty(this.pattern)) {
                return this.pattern
            } else if (this.inputType === 'email') {
                return this.$config.patterns.email
            }
            return ''
        },
        getSuccessTabIndex () {
            return this.successLabel !== '' ? '0' : '-1'
        },
        getTabIndexIcon () {
            return this.getCurrentValueNotEmpty ? '0' : '-1'
        }
    },
    watch: {
        defaultValue: function (val) {
            this.currentValue = val
            this.previousValue = val
        },
        errorLabel: function () {
            if (this.errorLabel !== '') {
                document.querySelector('#js-error-' + this.inputId).focus()
            }
        },
        successLabel: function () {
            if (this.successLabel !== '') {
                document.querySelector('#js-success-' + this.inputId).focus()
            }
        }
    },
    mounted () {
        this.inputId = this._uid
        this.currentValue = this.defaultValue
        this.previousValue = this.defaultValue
    },
    methods: {
        checkPattern () {
            return !(this.getPattern !== '' && typeof this.currentValue === 'string' && !this.currentValue.match(this.getPattern))
        },
        clearInputField () {
            if (this.getCurrentValueNotEmpty) {
                this.currentValue = ''
                this.previousValue = ''
                document.querySelector('#js-input-' + this.inputId).focus()
                this.fieldValueChanged()
            }
        },
        fieldValueChanged () {
            if (this.isStrictPattern && !this.checkPattern()) {
                this.currentValue = this.previousValue
            } else {
                this.previousValue = this.currentValue
                if (!isEmpty(this.id)) {
                    this.$emit('fieldValueChanged', {
                        id: this.id,
                        value: this.currentValue
                    })
                } else {
                    this.$emit('fieldValueChanged', this.currentValue)
                }
            }
        }
    }
}
</script>

<style lang='scss' scoped>
@import 'globalScss';
</style>
