import HistoryInvoice from '@/StoreWeb/models/user/order/HistoryInvoice'
import { isEmpty } from 'global-utils'

export default class {
    normalize (item) {
        const historyInvoice = new HistoryInvoice(item.id)

        historyInvoice.creationDate = item.creationDate
        historyInvoice.external = item.external ?? true
        historyInvoice.status = item.status
        historyInvoice.providerId = item.providerId
        if (!isEmpty(item.updateDate)) {
            historyInvoice.updateDate = item.updateDate
        }

        return historyInvoice
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
