import FareMedia from '@/StoreWeb/models/user/wallet/FareMedia'
import FareMediaProductNormalizer from '@/StoreWeb/normalizers/fare-media/FareMediaProductNormalizerSingleton'
import IdLabelPatternNormalizer from '@/StoreWeb/normalizers/patterns/IdLabelPatternNormalizerSingleton'
import UserActionNormalizer from '@/StoreWeb/normalizers/common/UserActionNormalizerSingleton'
import { isEmpty } from 'global-utils'

export default class {
    constructor () {
        this._fareMediaProductNormalizer = FareMediaProductNormalizer.getInstance()
        this._idLabelPatternNormalizer = IdLabelPatternNormalizer.getInstance()
        this._userActionNormalizer = UserActionNormalizer.getInstance()
    }

    get fareMediaProductNormalizer () {
        return this._fareMediaProductNormalizer
    }

    get idLabelPatternNormalizer () {
        return this._idLabelPatternNormalizer
    }

    get userActionNormalizer () {
        return this._userActionNormalizer
    }

    normalize (item, params) {
        const fareMedia = new FareMedia(item.id, params.providerId)
        params = Object.assign(params, {
            fareMediaId: item.id,
            providerUserExternalId: item.providerUserExternalId
        })

        fareMedia.actions = this.userActionNormalizer.normalizeArray(item.actions)
        if (!isEmpty(params.accountId)) {
            fareMedia.accountId = params.accountId
            fareMedia.providerUserId = params.accountId
        }

        fareMedia.providerUserExternalId = item.providerUserExternalId
        fareMedia.attributionDate = item.attributionDate
        fareMedia.firstName = item.firstName
        fareMedia.label = item.label
        fareMedia.lastName = item.lastName
        fareMedia.products = []
        if (!isEmpty(item.products)) {
            fareMedia.products = this.fareMediaProductNormalizer.normalizeArray(item.products, params)
            fareMedia.products.forEach(product => {
                if (!isEmpty(product.type) && product.type.id === 'FAREMEDIA_RIGHT') {
                    fareMedia.profile = product.label
                }
            })
        }
        if (!isEmpty(params.recipientUser)) {
            fareMedia.recipientUser = params.recipientUser
        }
        fareMedia.status = this.idLabelPatternNormalizer.normalize(item.status)
        if (!isEmpty(item.type)) {
            fareMedia.type = this.idLabelPatternNormalizer.normalize(item.type)
        }
        fareMedia.validityEndDate = item.validityEndDate

        return fareMedia
    }

    normalizeArray (items, params) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item, params))
            })
        }

        return normalizedItems
    }
}
