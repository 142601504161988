import CatalogProductSaleOptionNormalizer from './CatalogProductSaleOptionNormalizer'

const CatalogProductSaleOptionNormalizerSingleton = (function () {
    let instance

    function createInstance () {
        const classObj = new CatalogProductSaleOptionNormalizer()
        return classObj
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance()
            }
            return instance
        }
    }
})()

export default CatalogProductSaleOptionNormalizerSingleton
