<template>
    <div class="MockSwitchItem">
        <v-switch
            v-model="mockState"
            :label="mockAction"
            class="MockSwitchItem-toggle"
            color="primary"
            @update:model-value="updateMockState"
        />
        <select
            v-show="mockState"
            v-model="mockVersion"
            class="MockSwitchItem-versionSelect"
            @change="updateMockVersion"
        >
            <option
                v-for="(version, index) in mockVersions"
                :key="index"
                :value="index"
            >
                {{ version.label }}
            </option>
        </select>
    </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import DebuggingManager from '@/StoreWeb/managers/DebuggingManagerSingleton'
import * as debuggingMutationTypes from '@/StoreWeb/store/modules/debugging/mutation-types'
import { isEmpty } from 'global-utils'
import { useStore } from 'vuex'

const store = useStore()
const debuggingManager = DebuggingManager.getInstance()

const props = defineProps({
    mockAction: {
        type: String,
        required: true
    }
})

const mockState = ref(false)
const mockVersion = ref(0)
const mockVersions = ref([])

const mockStates = computed(() => store.state.debuggingModule.mockStates)

function getIndexFromVersion () {
    let versionIndex = 0
    if (!isEmpty(mockVersions.value)) {
        versionIndex = mockVersions.value.findIndex(version => version.id === mockStates.value[props.mockAction].version)
    }
    return versionIndex
}

function setMockVersions () {
    mockVersions.value = debuggingManager.getActionConfig(props.mockAction)
}

function updateMockState (value) {
    mockState.value = value
    if (mockState.value) {
        setMockVersions()
    }
    let version = 'default'
    if (!isEmpty(mockVersions.value) && !isEmpty(mockVersions.value[mockVersion.value])) {
        version = mockVersions.value[mockVersion.value].id
    }
    const mock = {
        action: props.mockAction,
        state: mockState.value,
        version
    }
    store.commit(debuggingMutationTypes.SET_MOCK_STATE, mock)
    debuggingManager.updateConfig(mock)
}

function updateMockVersion () {
    let version = 'default'
    if (!isEmpty(mockVersions.value) && !isEmpty(mockVersions.value[mockVersion.value])) {
        version = mockVersions.value[mockVersion.value].id
    }
    const mock = {
        action: props.mockAction,
        state: mockState.value,
        version
    }
    store.commit(debuggingMutationTypes.SET_MOCK_STATE, mock)
    debuggingManager.updateConfig(mock)
}

onMounted(() => {
    mockState.value = mockStates.value[props.mockAction].state
    if (mockState.value) {
        setMockVersions()
    }
    mockVersion.value = getIndexFromVersion()
})
</script>

<style lang="scss" scoped>
@import 'globalScss';

.MockSwitchItem {
    margin-bottom: 10px;

    &-toggle {
        margin: 0;
        background: none !important;
    }

    &-versionSelect {
        max-width: 100%;
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 6px;
    }
}
</style>
