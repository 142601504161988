import { computed } from 'vue'
import { useStore } from 'vuex'
import { isEmpty } from 'global-utils'
import emitter from 'global-emitter'
import * as mainMutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import * as userMutationTypes from '@/StoreWeb/store/modules/user/mutation-types'
import i18n from 'i18n'
import config from 'config'

export function useNavigationMixins () {
    const store = useStore()

    const getAccountNav = computed(() => {
        const accountNav = []

        const profileSection = {
            label: i18n.global.t('account:nav_group:profile'),
            links: []
        }
        profileSection.links.push(buildLink({
            icon: 'AccountInfos--outlined',
            label: 'account:informations:title',
            toName: 'informations'
        }))
        if (
            !isEmpty(config.features.password_update) &&
            config.features.password_update === true
        ) {
            profileSection.links.push(buildLink({
                icon: 'Security--outlined',
                label: 'account:password:title',
                toName: 'password'
            }))
        }
        if (
            config.features.addresses &&
            !isEmpty(config.account_menu_item_enabled.address) &&
            config.account_menu_item_enabled.address === true
        ) {
            profileSection.links.push(buildLink({
                icon: 'OrderHistory--outlined',
                label: 'account:addresses:title',
                toName: 'addresses'
            }))
        }
        if (
            !isEmpty(config.features.my_personal_data) &&
            config.features.my_personal_data.delete_account
        ) {
            profileSection.links.push(buildLink({
                icon: 'Toggle--outlined',
                label: 'account:personal_data:title',
                toName: 'personalData'
            }))
        }
        if (
            !isEmpty(config.features.account_association) &&
            config.features.account_association
        ) {
            profileSection.links.push(buildLink({
                icon: 'Group',
                label: 'account:account_associations:title',
                toName: 'accountAssociations'
            }))
        }

        const storeSection = {
            label: i18n.global.t('account:nav_group:shop_and_purchases'),
            links: []
        }
        storeSection.links.push(buildLink({
            icon: 'Cards--outlined',
            label: 'account:tickets:title',
            toName: 'tickets'
        }))
        if (config.features.order_history) {
            storeSection.links.push(buildLink({
                icon: 'OrderHistory--outlined',
                label: 'account:purchases:title',
                toName: 'purchases'
            }))
        }
        if (config.features.open_payment_trips) {
            storeSection.links.push(buildLink({
                icon: 'CreditCard',
                label: 'account:open_payment_trips:title',
                toName: 'openPaymentTrips'
            }))
        }
        if (config.features.payment_means) {
            storeSection.links.push(buildLink({
                icon: 'Wallet--outlined',
                label: 'account:payment_means:title',
                toName: 'paymentMeans'
            }))
        }
        if (config.features.direct_debits) {
            storeSection.links.push(buildLink({
                icon: 'Bank--outlined',
                label: 'account:direct_debits:title',
                toName: 'directDebits'
            }))
        }
        if (config.features.fine_regularization) {
            storeSection.links.push(buildLink({
                icon: 'Fines--outlined',
                label: 'fine:regularization:title',
                toName: 'fineSelection'
            }))
        }

        const transportSection = {
            label: i18n.global.t('account:nav_group:trips'),
            links: []
        }
        if (!isEmpty(config.features.transport)) {
            if (config.features.transport.favourites?.enabled) {
                transportSection.links.push(buildLink({
                    icon: 'Favourite--outlined',
                    label: 'account:favourites:title',
                    toName: 'favourites'
                }))
            }
            if (config.features.transport.alerts) {
                transportSection.links.push(buildLink({
                    icon: 'Alert--outlined',
                    label: 'account:alerts:title',
                    toName: 'alerts'
                }))
            }
            if (config.features.transport.reduced_mobility) {
                profileSection.links.push(buildLink({
                    icon: 'ReducedMobility',
                    label: 'account:reduced_mobility:title',
                    toName: 'reducedMobility'
                }))
            }
            if (config.features.transport.alerting_preferences) {
                profileSection.links.push(buildLink({
                    icon: 'Notifications--outlined',
                    label: 'account:alerting_preferences:title',
                    toName: 'alertingPreferences'
                }))
            }
        }
        const otherSection = {
            label: null,
            links: []
        }
        otherSection.links.push(buildLink({
            onClick: () => {
                emitter.emit('logOut')
                store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
            },
            icon: 'Logout',
            label: 'button:logout'
        }))

        accountNav.push(profileSection)
        accountNav.push(storeSection)
        if (!isEmpty(transportSection.links)) {
            accountNav.push(transportSection)
        }
        accountNav.push(otherSection)

        return accountNav
    })

    function activeEmitter () {
        emitter.on('accountItemClicked', hideAccountNav)
    }

    function desactiveEmitter () {
        emitter.off('accountItemClicked', hideAccountNav)
    }

    function buildLink (params) {
        const link = {
            icon: params.icon,
            label: i18n.global.t(params.label)
        }
        if (!isEmpty(params.iconClassPrefixe)) {
            link.iconClassPrefixe = params.iconClassPrefixe
        }
        if (!isEmpty(params.iconLayersCount)) {
            link.iconLayersCount = params.iconLayersCount
        }
        if (!isEmpty(params.toName)) {
            link.to = { name: params.toName }
        }
        if (!isEmpty(params.externalLink)) {
            link.externalLink = params.externalLink
        }
        if (typeof params.onClick === 'function') {
            link.onClick = params.onClick
        }
        return link
    }

    function hideAccountNav () {
        document.body.classList.remove('body--withModalOpened')
        store.commit(userMutationTypes.SET_SHOW_ACCOUNT_NAV, false)
    }

    return {
        activeEmitter,
        desactiveEmitter,
        getAccountNav
    }
}
