import UserActionNormalizer from '@/StoreWeb/normalizers/common/UserActionNormalizerSingleton'
import { isEmpty } from 'global-utils'
import DeviceProduct from '@/StoreWeb/models/user/wallet/DeviceProduct'

export default class {
    constructor () {
        this._userActionNormalizer = UserActionNormalizer.getInstance()
    }

    get userActionNormalizer () {
        return this._userActionNormalizer
    }

    normalize (item, params) {
        const deviceProduct = new DeviceProduct(item.id, params.providerId)

        if (!isEmpty(params.accountId)) {
            deviceProduct.accountId = params.accountId
        }
        if (!isEmpty(item.actions)) {
            deviceProduct.actions = this.userActionNormalizer.normalizeArray(item.actions)
        }
        if (!isEmpty(item.description)) {
            deviceProduct.description = item.description
        }
        if (!isEmpty(item.endDate)) {
            deviceProduct.endDate = item.endDate
        }
        deviceProduct.label = item.label
        if (!isEmpty(item.product)) {
            deviceProduct.product = item.product
        }
        if (!isEmpty(item.remainingTickets)) {
            deviceProduct.remainingTickets = item.remainingTickets
        }
        if (!isEmpty(item.serialNumber)) {
            deviceProduct.serialNumber = item.serialNumber
        }
        if (!isEmpty(item.startDate)) {
            deviceProduct.startDate = item.startDate
        }
        deviceProduct.status = item.status
        deviceProduct.type = item.type

        return deviceProduct
    }

    normalizeArray (items, params) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item, params))
            })
        }

        return normalizedItems
    }
}
