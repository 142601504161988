import HistoryProductModalProduct from '@/StoreWeb/models/user/order/HistoryProductModalProduct'
import { isEmpty } from 'global-utils'

export default class {
    normalize (item) {
        const historyProductModalProduct = new HistoryProductModalProduct(item.id)

        historyProductModalProduct.name = item.name
        historyProductModalProduct.version = item.version

        return historyProductModalProduct
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
