<template>
    <a
        :aria-label="getNotificationsToggleLabel"
        :title="getNotificationsToggleLabel"
        :class="{ 'HeaderNotificationListToggleButton--unread': getHasUnreadNotifications || getHasUnreadNotifications2 }"
        class="HeaderNotificationListToggleButton"
        @click="buttonClick"
    >
        <icomoon-icon
            :name="`Notifications--${getIconState}`"
            class="HeaderNotificationListToggleButton-icon"
        />
    </a>
</template>

<script>
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon'
import { mapGetters, mapState } from 'vuex'
import { isEmpty } from 'global-utils'
import i18n from 'i18n'

export default {
    components: {
        IcomoonIcon
    },
    computed: {
        ...mapGetters(['getHasUnreadNotifications']),
        ...mapState({
            notifications: state => state.userModule.notifications,
            showNotifications: state => state.userModule.showNotifications
        }),
        getIconState () {
            return this.showNotifications ? 'filled' : 'outlined'
        },
        getHasUnreadNotifications2 () {
            if (!isEmpty(this.notifications) && !isEmpty(this.notifications.items)) {
                for (const i in this.notifications.items) {
                    if (!this.notifications.items[i].notification.read) {
                        return true
                    }
                }
            }

            return false
        },
        getNotificationsToggleLabel () {
            return this.showNotifications ? i18n.global.t('notifications:hide') : i18n.global.t('notifications:show')
        }
    },
    methods: {
        buttonClick () {
            this.$emit('buttonClicked')
        }
    }
}
</script>
<style lang="scss" scoped>
@import 'globalScss';

.HeaderNotificationListToggleButton {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    color: $color-lighterText;
    transition: all .3s;

    &:hover,
    &:active,
    &:focus {
        color: $color-lightText;
    }

    &-icon {
        font-size: 28px;
    }

    .IconNotifications--filled {
        display: none;
    }

    &--active {
        color: $color-lightText;

        .IconNotifications--filled {
            display: block;
        }

        .IconNotifications--outlined {
            display: none;
        }
    }

    &--unread {
        .HeaderNotificationListToggleButton-icon {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                width: 6px;
                height: 6px;
                border-radius: 6px;
                background: $color-brandSecondary;
            }
        }
    }
}
</style>
