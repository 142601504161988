<template>
    <div class="OfferChoice">
        <div
            v-if="showOffersLoader"
            class="text-center mt-2"
        >
            <v-progress-circular indeterminate />
        </div>
        <div
            v-else-if="getOffers.length > 0"
            class="OfferChoice-listWrapper"
        >
            <p class="OfferChoice-title">
                {{ $t('quotations:available_offers') }}
            </p>
            <ul class="OfferChoice-list">
                <offer-choice-item
                    v-for="offer in getOffers"
                    v-bind="{ isActive: selectedOffer === offer.index, offer: offer }"
                    :key="offer.index"
                    class="OfferChoice-item"
                    @offer-clicked="offerClicked"
                />
            </ul>
        </div>
        <alert-message
            v-else
            :message="$t('quotations:error:no_result')"
            type="error"
            class="OfferChoice-noAvailableOfferError"
        />
    </div>
</template>

<script setup>
import { isEmpty } from 'global-utils'
import OfferChoiceItem from '@/StoreWeb/components/product/OfferChoiceItem'
import AlertMessage from '@/StoreWeb/components/common/AlertMessage'
import { computed, onBeforeUpdate, onMounted, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const emit = defineEmits(['updateOffer'])
const props = defineProps({
    offers: {
        type: Object,
        required: true
    }
})

const selectedOffer = ref(null)
const catalogPendingRequests = computed(() => store.state.catalogModule.catalogPendingRequests.getQuotations)
const getOffers = computed(() => props.offers)
const showOffersLoader = computed(() => catalogPendingRequests.value)

onMounted(() => {
    if (selectedOffer.value === null && !isEmpty(getOffers)) {
        selectedOffer.value = getOffers.value[0].index
        emit('updateOffer', getOffers.value[0])
    }
})

onBeforeUpdate(() => {
    if (!isEmpty(getOffers)) {
        selectedOffer.value = getOffers.value[0].index
        emit('updateOffer', getOffers.value[0])
    }
})

const offerClicked = (offer) => {
    selectedOffer.value = offer.index
    emit('updateOffer', offer, true)
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.OfferChoice {
    position: relative;
    width: 100%;

    &-loader {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -21px;
        margin-left: -21px;
    }

    &-title {
        font-weight: bold;
    }

    &-list {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 12px;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &-item {
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>
