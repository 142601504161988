<template>
    <div class="GridProductItem">
        <div class="GridProductItem-header">
            <h3 class="GridProductItem-name">
                {{ product.name.ucFirst() }}
            </h3>
            <small v-if="debugInformationsDisplay">{{ product.id }}</small>
        </div>
        <div class="GridProductItem-footer">
            <div class="GridProductItem-fareAndButtons">
                <price
                    :display-weee-tax="true"
                    :product="product"
                    class="GridProductItem-price GridProductItem-fareWrapper"
                    size="Small"
                />
                <v-btn
                    v-if="showAddToCart"
                    type="submit"
                    class="bg-primary"
                    size="small"
                    @click.prevent="addCartAction"
                >
                    {{ $t('button:add') }}
                </v-btn>
            </div>
        </div>
        <p
            v-if="debugInformationsDisplay"
            :class="['GridProductItem-type--' + product.modalType ]"
            class="GridProductItem-type"
        >
            {{ product.modalType }}
        </p>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import emitter from 'global-emitter'
import { logIn, useLogin } from '@/StoreWeb/js/composables/login-utils'
import Price from '@/StoreWeb/components/common/Price'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

const route = useRoute()
const store = useStore()
const { isAuthenticated } = useLogin()

const props = defineProps({
    product: {
        type: Object,
        required: true
    },
    index: {
        type: Number,
        required: true
    }
})

const debugInformationsDisplay = computed(() => store.state.debuggingModule.debugInformationsDisplay)
const showAddToCart = computed(() => props.product.saleableUnit.id !== 'NON_SALEABLE')

function addCartAction () {
    if (isAuthenticated.value) {
        emitter.emit('showProductModal', props.product)
    } else {
        logIn(route)
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.GridProductItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding: 10px 15px 15px;
    border-radius: 5px;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, .11);
    background: #fff;

    &-name {
        margin: 0 0 10px;
        font-family: $fontFamily-title;
        font-size: 16px;
        font-weight: $fontWeight-titleBold;
        color: $color-defaultText;

        @media (min-width: $tablet-breakpoint) {
            font-size: 20px;
        }
    }

    &-fareAndButtons {
        display: flex;
        flex-direction: column;
        justify-content: right;
        align-items: flex-start;
    }

    &-fareWrapper {
        flex-grow: 1;
        margin: 0;
        color: $color-lighterText;
    }

    &-fareFrom {
        display: block;
        font-size: 12px;
    }

    &-fare {
        display: block;
    }

    &-weeeTax {
        display: block;
        font-size: 12px;
    }

    &-button {
        align-self: flex-end;
        min-width: 92px;
        max-width: 92px;
        margin-top: 10px;
    }

    &-type {
        position: absolute;
        top: -8px;
        left: 10px;
        height: 16px;
        margin: 0;
        padding: 0 5px;
        background: forestgreen;
        font-size: 12px;
        line-height: 16px;
        color: #fff;

        &--MODALPACKAGE {
            background: #415971;
        }

        &--MODALPRODUCTGROUP {
            background: #ae2a2e;
        }
    }
}
</style>
