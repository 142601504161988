<template>
    <div class="LoggingIn">
        <div class="Container d-flex flex-column justify-center align-center h-100">
            <div class="d-flex flex-column justify-center align-center gap-5">
                <v-progress-circular
                    color="primary"
                    indeterminate
                />
                <v-alert
                    class="text-left"
                    density="compact"
                    text
                    type="info"
                    variant="tonal"
                >
                    {{ $t('logging_in:message') }}
                </v-alert>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, watch } from 'vue'
import { startLogInProcess } from '@/StoreWeb/js/composables/login-utils'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import config from 'config'

const route = useRoute()
const router = useRouter()
const store = useStore()

const isLoggingOut = computed(() => store.state.userModule.isLoggingOut)
const isLoginModuleLoaded = computed(() => store.state.isLoginModuleLoaded)

function logIn () {
    const redirectTo = route?.query?.redirectTo ?? router.resolve({ name: 'home' }).href

    if (
        !isLoggingOut.value &&
        (
            !config.use_web_harmony ||
            (config.use_web_harmony && isLoginModuleLoaded.value)
        )
    ) {
        // This setTimeout is used only to let enough time to the user to read the message
        setTimeout(() => startLogInProcess(redirectTo), 1000)
    }
}

onMounted(() => {
    logIn()
})

watch(() => store.state.isLoginModuleLoaded, (isLoginModuleLoaded) => {
    if (isLoginModuleLoaded) {
        logIn()
    }
})
</script>

<style lang="scss" scoped>
@import 'globalScss';

.LoggingIn {
    background: $color-lightest;
}
</style>
