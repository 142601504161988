export default class {
    constructor () {
        this._label = ''
        this._type = ''
    }

    get label () {
        return this._label
    }

    set label (label) {
        this._label = label
    }

    get type () {
        return this._type
    }

    set type (type) {
        this._type = type
    }

    toJSON () {
        return {
            label: this.label,
            type: this.type
        }
    }
}
