<template>
    <main class="PaymentError">
        <div class="Container PaymentError-container">
            <h1 class="PaymentError-title fadeInAfterIcon">
                {{ $t('payment:error:title') }}
            </h1>
            <svg
                class="PaymentError-illust"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 130.2 130.2"
            >
                <circle
                    class="path circle"
                    fill="none"
                    stroke-width="6"
                    stroke-miterlimit="10"
                    cx="65.1"
                    cy="65.1"
                    r="62.1"
                />
                <line
                    class="path line"
                    fill="none"
                    stroke-width="6"
                    stroke-linecap="round"
                    stroke-miterlimit="10"
                    x1="34.4"
                    y1="37.9"
                    x2="95.8"
                    y2="92.3"
                />
                <line
                    class="path line"
                    fill="none"
                    stroke-width="6"
                    stroke-linecap="round"
                    stroke-miterlimit="10"
                    x1="95.8"
                    y1="38"
                    x2="34.4"
                    y2="92.2"
                />
            </svg>
            <p class="PaymentError-explanations fadeInAfterIcon">
                {{ $t('payment:error:description') }}
            </p>
            <block-loader v-if="showCartLoader" />
            <v-btn
                v-else-if="showRetryPaymentLink"
                color="primary"
                class="PaymentError-retryLink fadeInAfterIcon"
                @click="retryPayment"
            >
                {{ $t('button:retry') }}
            </v-btn>
            <router-link
                v-else
                :to="{ name: 'home' }"
                class="PaymentError-backToHomeLink fadeInAfterIcon"
            >
                {{ $t('button:back_to_home') }}
            </router-link>
        </div>
    </main>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import BlockLoader from '@/StoreWeb/components/common/BlockLoader'
import { isEmpty } from 'global-utils'
import * as cartMutationTypes from '@/StoreWeb/store/modules/cart/mutation-types'
import { loadCart } from '@/StoreWeb/js/composables/cart-utils'
import { useLogin } from '@/StoreWeb/js/composables/login-utils'

const router = useRouter()
const store = useStore()
const { isAuthenticated } = useLogin()

const cart = computed(() => store.state.cartModule.cart)
const cartPendingRequests = computed(() => store.state.cartModule.cartPendingRequests)
const getCartStatus = computed(() => (!isEmpty(cart.value) ? cart.value.status : ''))
const showCartLoader = computed(() => cartPendingRequests.value.getCart)
const showRetryPaymentLink = computed(() => getCartStatus.value === 'PAYMENT_PROCESSING' || getCartStatus.value === 'PAYMENT_DECLINED')

onMounted(async () => {
    if (!isAuthenticated.value) {
        store.commit(cartMutationTypes.SET_IS_GUEST_PURCHASE_FLOW, false)
    }

    localStorage.removeItem('paymentType')

    await loadCart()
})

function retryPayment () {
    router.push({ name: 'cartConfirmation' })
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

.PaymentError {
    background: #fff;
    text-align: center;

    &-container {
        padding-top: 80px;
    }

    &-title {
        @extend %h2;

        color: $color-defaultText;
    }

    &-illust {
        display: block;
        width: 120px;
        margin: 0 auto 20px;
        stroke: $color-error;
    }

    &-explanations {
        margin: 0 0 30px;
        font-size: 14px;
        line-height: 1.8;
        color: $color-lighterText;
    }

    &-goToOrderListButton {
        margin-bottom: 15px;
    }

    &-backToHomeLink,
    &-retryLink {
        display: inline-block;
        padding: 10px;
        font-size: 14px;
        font-weight: bold;
    }
}
</style>
