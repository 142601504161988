<template>
    <div
        v-if="hasProviderOptions"
        class="ProviderSelect"
    >
        <custom-select
            :defaultValue="getSelectedProviderOption"
            :label="$t('provider:select:label')"
            :options="getProviderOptions"
            class="ProviderSelect-select"
            @select-changed="updateProvider"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomSelect from '@/StoreWeb/components/common/CustomSelect'
import { isEmpty } from 'global-utils'

export default {
    components: {
        CustomSelect
    },
    props: {
        selectedProvider: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            provider: ''
        }
    },
    computed: {
        ...mapGetters(['getWalletProvidersAvailableForAdd']),
        getProviderOptions () {
            const providers = []

            this.getWalletProvidersAvailableForAdd.forEach(providerItem => {
                providers.push({
                    id: providerItem.id,
                    name: providerItem.walletName
                })
            })
            return providers
        },
        hasProviderOptions () {
            return !isEmpty(this.getProviderOptions) && !isEmpty(this.getProviderOptions[0]) && !isEmpty(this.getProviderOptions[0].id)
        },
        getSelectedProviderOption () {
            if (!isEmpty(this.getProviderOptions) && !isEmpty(this.selectedProvider)) {
                return this.getProviderOptions.find(provider => provider.id === this.selectedProvider.id) || ''
            }
            return ''
        }
    },
    methods: {
        updateProvider (value) {
            this.$emit('selectedProviderUpdated', value.selected.id)
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.ProviderSelect {
    &-description {
        margin-bottom: 25px;
        font-size: 12px;
        font-style: italic;
        color: $color-lighterText;
    }
}
</style>
