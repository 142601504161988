export default class {
    constructor () {
        this._deliveryMode = ''
        this._fareMediaId = ''
        this._fareMediaLabel = ''
        this._info = ''
        this._line = 0
        this._ownerFirstName = ''
        this._ownerLastName = ''
        this._products = []
        this._providerId = ''
        this._providerUserEmail = ''
        this._providerUserId = ''
        this._providerUserExternalId = ''
        this._providerUserFirstName = ''
        this._providerUserLastName = ''
        this._recipientUser = null
        this._type = ''
    }

    get deliveryMode () {
        return this._deliveryMode
    }

    set deliveryMode (deliveryMode) {
        this._deliveryMode = deliveryMode
    }

    get fareMediaId () {
        return this._fareMediaId
    }

    set fareMediaId (fareMediaId) {
        this._fareMediaId = fareMediaId
    }

    get fareMediaLabel () {
        return this._fareMediaLabel
    }

    set fareMediaLabel (fareMediaLabel) {
        this._fareMediaLabel = fareMediaLabel
    }

    get info () {
        return this._info
    }

    set info (info) {
        this._info = info
    }

    get line () {
        return this._line
    }

    set line (line) {
        this._line = line
    }

    get ownerFirstName () {
        return this._ownerFirstName
    }

    set ownerFirstName (ownerFirstName) {
        this._ownerFirstName = ownerFirstName
    }

    get ownerLastName () {
        return this._ownerLastName
    }

    set ownerLastName (ownerLastName) {
        this._ownerLastName = ownerLastName
    }

    get products () {
        return this._products
    }

    set products (products) {
        this._products = products
    }

    get providerId () {
        return this._providerId
    }

    set providerId (providerId) {
        this._providerId = providerId
    }

    get providerUserEmail () {
        return this._providerUserEmail
    }

    set providerUserEmail (providerUserEmail) {
        this._providerUserEmail = providerUserEmail
    }

    get providerUserId () {
        return this._providerUserId
    }

    set providerUserId (providerUserId) {
        this._providerUserId = providerUserId
    }

    get providerUserExternalId () {
        return this._providerUserExternalId
    }

    set providerUserExternalId (providerUserExternalId) {
        this._providerUserExternalId = providerUserExternalId
    }

    get providerUserFirstName () {
        return this._providerUserFirstName
    }

    set providerUserFirstName (providerUserFirstName) {
        this._providerUserFirstName = providerUserFirstName
    }

    get providerUserLastName () {
        return this._providerUserLastName
    }

    set providerUserLastName (providerUserLastName) {
        this._providerUserLastName = providerUserLastName
    }

    get recipientUser () {
        return this._recipientUser
    }

    set recipientUser (recipientUser) {
        this._recipientUser = recipientUser
    }

    get type () {
        return this._type
    }

    set type (type) {
        this._type = type
    }

    toJSON () {
        return {
            deliveryMode: this.deliveryMode,
            fareMediaId: this.fareMediaId,
            fareMediaLabel: this.fareMediaLabel,
            info: this.info,
            line: this.line,
            ownerFirstName: this.ownerFirstName,
            ownerLastName: this.ownerLastName,
            products: this.products,
            providerId: this.providerId,
            providerUserEmail: this.providerUserEmail,
            providerUserId: this.providerUserId,
            providerUserExternalId: this.providerUserExternalId,
            providerUserFirstName: this.providerUserFirstName,
            providerUserLastName: this.providerUserLastName,
            recipientUser: this.recipientUser,
            type: this.type
        }
    }
}
