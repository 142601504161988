import * as actionTypes from './action-types'
import ApplicationSessionManager from '@/StoreWeb/managers/ApplicationSessionManagerSingleton'
const applicationSessionManager = ApplicationSessionManager.getInstance()

export default {
    /**
     * Create a new application session
     * @param params
     */
    [actionTypes.CREATE_APPLICATION_SESSION] (...args) {
        const [, params] = args
        return applicationSessionManager.createApplicationSessionAction(params)
    },
    /**
     * Get an application session by id
     * @param params
     */
    [actionTypes.READ_APPLICATION_SESSION] (...args) {
        const [, params] = args
        return applicationSessionManager.getApplicationSessionAction(params)
    },
    /**
     * Update an application session
     * @param params
     */
    [actionTypes.UPDATE_APPLICATION_SESSION] (...args) {
        const [, params] = args
        return applicationSessionManager.updateApplicationSessionAction(params)
    }
}
