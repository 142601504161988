<template>
    <div
        v-if="showAlert"
        :class="['AlertMessage--' + type, { 'AlertMessage--dismissible' : dismissible }]"
        class="AlertMessage"
    >
        <p
            v-if="title !== ''"
            class="AlertMessage-title"
            v-text="title"
        />
        <p
            class="AlertMessage-message"
            v-html="$sanitize(message)"
        />
        <a
            v-if="dismissible"
            :title="$t('button:close')"
            class="AlertMessage-closeButton"
            data-testid="AlertMessage-closeButton"
            @click="closeAlert"
        >
            <icomoon-icon
                name="Close"
                class="AlertMessage-closeButtonIcon"
            />
        </a>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon'

defineProps({
    dismissible: {
        type: Boolean,
        default: false
    },
    message: {
        type: String,
        required: true
    },
    title: {
        type: String,
        default: ''
    },
    type: {
        type: String,
        default: 'info'
    }
})

const showAlert = ref(true)

const closeAlert = () => {
    showAlert.value = false
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

.AlertMessage {
    position: relative;
    padding: 10px 15px;
    border: 1px solid $info-border-color;
    border-radius: $border-radius-small;
    background: $info-border-background;
    color: $info-color;

    &--error {
        border-color: $error-border-color;
        background: $error-border-background;
        color: $error-color;
    }

    &--warning {
        border-color: $warning-border-color;
        background: $warning-border-background;
        color: $warning-color;
    }

    &--dismissible {
        padding-right: 30px;
    }

    &-success {
        border-color: $success-border-color;
        background: $success-border-background;
        color: $success-color;
    }

    &-title {
        margin: 0 0 10px;
        font-size: 16px;
        font-weight: $fontWeight-defaultMedium;
    }

    &-message {
        margin: 0 0 10px;
        font-size: 14px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-closeButton {
        position: absolute;
        top: 5px;
        right: 5px;

        &Icon {
            font-size: 20px;
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
