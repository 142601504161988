<template>
    <div class="LinesForm">
        <v-select
            v-model="selectedLines"
            :items="lines"
            :label="props.productParameter.label"
            :loading="showLinesLoader"
            :readonly="selectedLines?.length >= maxNumber"
            chips
            closable-chips
            item-title="label"
            item-value="value"
            multiple
            return-object
            @update:model-value="updateSelectedLines"
        />
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import AddToCartFlowManager from '@/StoreWeb/managers/AddToCartFlowManagerSingleton'
import * as catalogActionTypes from '@/StoreWeb/store/modules/catalog/action-types'
import { isEmpty } from 'global-utils'
import { useStore } from 'vuex'

const store = useStore()
const addToCartFlowManager = AddToCartFlowManager.getInstance()

const props = defineProps({
    product: {
        type: Object,
        required: true
    },
    productParameter: {
        type: Object,
        required: true
    }
})

const emit = defineEmits(['updateLinesForm'])

const lines = ref([])
const maxNumber = ref(1)
const saleOptionStep = ref(null)
const selectedLines = ref(null)
const showLinesLoader = ref(false)

const getLines = async () => {
    showLinesLoader.value = true

    await store.dispatch(catalogActionTypes.GET_LIST_BY_PARAMETER_NAME, {
        product: props.product,
        productParameter: props.productParameter,
        saleOptionStep: saleOptionStep.value
    })
        .then(lineListLoadedHandler)
        .catch(() => {
            showLinesLoader.value = false
        })
}

const lineListLoadedHandler = (data) => {
    showLinesLoader.value = false
    lines.value = data.productParameter.values
    maxNumber.value = !isEmpty(data.productParameter.parameter.maxNumber) ? data.productParameter.parameter.maxNumber : 1
}

const updateProductParameter = () => {
    const productParameterValues = []
    selectedLines.value.forEach(line => {
        productParameterValues.push({
            productParameter: props.productParameter.id,
            code: 'VALUE',
            label: `${line.label}`,
            value: `${line.value}`
        })
    })
    emit('updateLinesForm', {
        id: props.productParameter.id,
        isProductParameterValid: productParameterValues.length > 0,
        productParameterValues,
        isQuotationRequired: true
    })
}

const updateSelectedLines = () => {
    updateProductParameter()
}

onMounted(() => {
    const saleOptionSteps = addToCartFlowManager.findInPreviousSteps({
        type: 'saleOption'
    }); // Adding a semi colon ";" at line.value's end to use decomposition
    [saleOptionStep.value] = saleOptionSteps

    getLines()
})
</script>
