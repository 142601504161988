<template>
    <component
        :is="'li'"
        :class="{ 'VariationItem--active': isActive }"
        class="VariationItem"
        @click.stop="selectVariation"
    >
        <strong class="VariationItem-name">{{ variation.name }}</strong>
        <span class="VariationItem-selectedIconWrapper">
            <i class="VariationItem-selectedIcon IconCheck" />
        </span>
        <formatted-fare
            :fare="variation.fare"
            :size="'XSmall'"
            class="VariationItem-fare"
        />
    </component>
</template>

<script>
import FormattedFare from '@/StoreWeb/components/common/FormattedFare'

export default {
    components: {
        FormattedFare
    },
    props: {
        isActive: {
            type: Boolean,
            default: false
        },
        variation: {
            type: Object,
            required: true
        }
    },
    methods: {
        selectVariation () {
            this.$nextTick(() => {
                this.$emit('selectChanged', { selectedId: this.variation.id })
            })
        }
    }
}
</script>
<style lang="scss" scoped>
@import 'globalScss';

.VariationItem {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    min-width: 133px;
    min-height: 92px;
    padding: $padding-xsmall;
    border-radius: $border-radius-xsmall;
    box-shadow: 0 -2px 15px 0 rgba(0, 0, 0, .12), inset 0 0 0 3px $color-lightgray3;
    background: $color-lightest;
    cursor: pointer;
    color: $color-lightText;
    transition: all .5s;

    &-selectedIconWrapper {
        display: none;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        width: 22px;
        height: 22px;
        border-top-right-radius: $border-radius-xsmall;
        border-bottom-left-radius: $border-radius-xsmall;
        background: $color-success;
    }

    &-selectedIcon {
        font-size: 16px;
        color: $color-lightest;
    }

    &:hover,
    &:focus {
        box-shadow: 0 -2px 15px 0 rgba(0, 0, 0, .12), inset 0 0 0 3px $color-success;
    }

    &--active {
        box-shadow: 0 -2px 15px 0 rgba(0, 0, 0, .12), inset 0 0 0 3px $color-success;

        &:hover,
        &:focus {
            box-shadow: 0 -2px 15px 0 rgba(0, 0, 0, .12), inset 0 0 0 3px $color-success;
        }

        .VariationChoiceItem-selectedIconWrapper {
            display: flex;
        }
    }
}
</style>
