<template>
    <transition
        :name="getTransition"
        mode="out-in"
        @:after-leave="animationFinished"
    >
        <div
            v-show="showMainNav"
            :style="{ zIndex : (headerBarZIndex - 1) }"
            class="MainNav"
        >
            <lang-selector
                v-if="$vuetify.display.smAndDown"
                class="MainNav-langSelector"
                toggleContentHeight="40px"
            />
            <debug-panel v-if="$config.debug_mode" />
        </div>
    </transition>
</template>

<script>
import { mapState } from 'vuex'
import DebugPanel from '@/StoreWeb/components/common/debug/DebugPanel'
import LangSelector from '@/StoreWeb/components/common/LangSelector.vue'

export default {
    components: {
        LangSelector,
        DebugPanel
    },
    computed: {
        ...mapState({
            headerBarZIndex: state => state.headerBarZIndex,
            showMainNav: state => state.showMainNav
        }),
        getTransition () {
            return this.$vuetify.display.smAndDown ? 'slideRight' : 'slideDown'
        }
    },
    methods: {
        animationFinished () {
            this.$emit('showMainNav')
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.MainNav {
    position: absolute;
    top: 60px;
    left: 0;
    max-width: 100vw;
    min-height: 100vh;
    padding: 20px;
    background: $color_lightest;

    @media (min-width: $tablet-breakpoint) {
        right: 0;
        bottom: auto;
        max-height: calc(100vh - 60px);
    }

    &-langSelector {
        margin: 0 0 20px -8px;
    }
}

.slideRight-enter-active,
.slideRight-leave-active {
    transition: left .5s ease-in-out;
}

.slideRight-enter,
.slideRight-leave-to {
    left: -100%;
    opacity: 0;
}

.slideDown-enter-active,
.slideDown-leave-active {
    transition: top .5s ease-in-out;
}

.slideDown-enter,
.slideDown-leave-to {
    top: -100%;
    opacity: 0;
}
</style>
