export default class {
    constructor (id) {
        this._accounts = []
        this._id = id
        this._walletAddAccount = ''
        this._walletDeleteAccountMode = ''
        this._walletName = ''
        this._walletCreateAccount = ''
        this._walletCreateThirdPartyAccount = ''
        this._walletAddFareMedia = ''
        this._walletOptions = []
        this._walletManageFareProfile = []
    }

    get accounts () {
        return this._accounts
    }

    set accounts (accounts) {
        this._accounts = accounts
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get walletAddAccount () {
        return this._walletAddAccount
    }

    set walletAddAccount (walletAddAccount) {
        this._walletAddAccount = walletAddAccount
    }

    get walletDeleteAccountMode () {
        return this._walletDeleteAccountMode
    }

    set walletDeleteAccountMode (walletDeleteAccountMode) {
        this._walletDeleteAccountMode = walletDeleteAccountMode
    }

    get walletName () {
        return this._walletName
    }

    set walletName (walletName) {
        this._walletName = walletName
    }

    get walletCreateAccount () {
        return this._walletCreateAccount
    }

    set walletCreateAccount (walletCreateAccount) {
        this._walletCreateAccount = walletCreateAccount
    }

    get walletCreateThirdPartyAccount () {
        return this._walletCreateThirdPartyAccount
    }

    set walletCreateThirdPartyAccount (walletCreateThirdPartyAccount) {
        this._walletCreateThirdPartyAccount = walletCreateThirdPartyAccount
    }

    get walletAddFareMedia () {
        return this._walletAddFareMedia
    }

    set walletAddFareMedia (walletAddFareMedia) {
        this._walletAddFareMedia = walletAddFareMedia
    }

    get walletOptions () {
        return this._walletOptions
    }

    set walletOptions (walletOptions) {
        this._walletOptions = walletOptions
    }

    get walletManageFareProfile () {
        return this._walletManageFareProfile
    }

    set walletManageFareProfile (walletManageFareProfile) {
        this._walletManageFareProfile = walletManageFareProfile
    }

    getAccountsCount () {
        return this.accounts.length
    }

    hasDevices () {
        return this.accounts.some(account => account.devices.length > 0)
    }

    hasETickets () {
        return this.accounts.some(account => account.eTickets.length > 0)
    }

    hasFareMedias () {
        return this.accounts.some(account => account.fareMedias.length > 0)
    }

    toJSON () {
        return {
            accounts: this.accounts,
            id: this.id,
            walletAddAccount: this.walletAddAccount,
            walletDeleteAccountMode: this.walletDeleteAccountMode,
            walletName: this.walletName,
            walletCreateAccount: this.walletCreateAccount,
            walletCreateThirdPartyAccount: this.walletCreateThirdPartyAccount,
            walletAddFareMedia: this.walletAddFareMedia,
            walletOptions: this.walletOptions,
            walletManageFareProfile: this.walletManageFareProfile
        }
    }
}
