import AddToCartFlowManager from '@/StoreWeb/managers/AddToCartFlowManagerSingleton'
import { isEmpty } from 'global-utils'

const addToCartFlowManager = AddToCartFlowManager.getInstance()

const getPreviousProductParameters = () => {
    const productParametersSteps = addToCartFlowManager.findInPreviousSteps({
        type: 'productParameter'
    })

    return productParametersSteps
}

export const completeBodyWithProductParameters = (body, params) => {
    body.parameters = []
    const previousProductParameters = getPreviousProductParameters()

    if (!isEmpty(previousProductParameters)) {
        previousProductParameters.forEach((productParameter) => {
            if (!isEmpty(productParameter.productParameterValues)) {
                body.parameters = [...body.parameters, ...productParameter.productParameterValues]
            } else {
                body.parameters.push({
                    productParameter: productParameter.id,
                    code: productParameter.code ?? 'VALUE',
                    value: `${productParameter.value}`
                })
            }
        })
    }

    if (!isEmpty(params?.currentProductParameterOptions?.subParameters)) {
        body.parameters = [...body.parameters, ...params.currentProductParameterOptions.subParameters]
    }

    return body
}
