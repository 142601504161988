export const formatAction = (action, onClickFunction) => {
    const formattedAction = {
        externalUrl: false,
        href: null,
        label: action.label,
        onClick: null,
        type: action.type
    }

    onClickFunction(formattedAction, action)

    return formattedAction
}
