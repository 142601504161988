<template>
    <div class="AccountAssociation">
        <account-association-request-list class="AccountAssociation-accountAssociationRequestList" />
    </div>
</template>

<script>
import DefaultView from '@/StoreWeb/views/DefaultView'
import AccountAssociationRequestList from '@/StoreWeb/components/account/association/AccountAssociationRequestList'
import PageConfigManager from '@/StoreWeb/managers/PageConfigManagerSingleton'
const pageConfigManager = PageConfigManager.getInstance()

export default {
    components: {
        AccountAssociationRequestList
    },
    extends: DefaultView,
    mounted () {
        pageConfigManager.setConfig({
            bodyClasses: 'body--pageAccount',
            title: 'account:account_associations:title',
            currentAccountPage: 'accountAssociations'
        })
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.AccountAssociation {
    &-block {
        margin-bottom: 25px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-list.NotificationList {
        border: 1px solid $color-lightgray4;
    }
}
</style>
