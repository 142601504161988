<template>
    <div class="Informations">
        <sytral-informations
            v-if="getInformationComponentVersion === 'sytral'"
        />
        <default-informations
            v-else
        />
    </div>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import PageConfigManager from '@/StoreWeb/managers/PageConfigManagerSingleton'
import DefaultInformations from '@/StoreWeb/components/account/informations/DefaultInformations.vue'
import SytralInformations from '@/StoreWeb/components/account/informations/SytralInformations.vue'
import { isEmpty } from 'global-utils'
import config from 'config'

const pageConfigManager = PageConfigManager.getInstance()

const getInformationComponentVersion = computed(() => {
    if (!isEmpty(config.userweb.kas_user_infos_update)) {
        return config.userweb.kas_user_infos_update
    }
    return 'default'
})

onMounted(() => {
    pageConfigManager.setConfig({
        title: 'account:informations:title',
        currentAccountPage: 'informations'
    })
})
</script>
