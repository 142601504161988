export function lightenDarkenColor (colorCode, amount) {
    let usePound = false

    if (colorCode[0] === '#') {
        colorCode = colorCode.slice(1)
        usePound = true
    }

    const num = parseInt(colorCode, 16)

    let r = (num >> 16) + amount

    if (r > 255) {
        r = 255
    } else if (r < 0) {
        r = 0
    }

    let b = ((num >> 8) & 0x00FF) + amount

    if (b > 255) {
        b = 255
    } else if (b < 0) {
        b = 0
    }

    let g = (num & 0x0000FF) + amount

    if (g > 255) {
        g = 255
    } else if (g < 0) {
        g = 0
    }

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
}

export function hexToRgbA (hex, alpha) {
    let c

    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('')
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]]
        }
        c = '0x' + c.join('')
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')'
    }

    throw new Error('Bad Hex')
}

export function isColorDark (color) {
    // Variables for red, green, blue values
    let r, g, b

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)

        r = color[1]
        g = color[2]
        b = color[3]
    } else {
        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'))

        r = color >> 16
        g = color >> 8 & 255
        b = color & 255
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    const hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
    )

    // Using the HSP value, determine whether the color is light or dark
    return hsp <= 127.5
}
