import WalletNormalizer from './WalletNormalizer'

const WalletNormalizerSingleton = (function () {
    let instance

    function createInstance () {
        const classObj = new WalletNormalizer()
        return classObj
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance()
            }
            return instance
        }
    }
})()

export default WalletNormalizerSingleton
