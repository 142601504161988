<template>
    <the-footer
        :menu-items="menuItems"
        :configuration="configuration"
    />
</template>

<script setup>
import { TheFooter, useCmsMenuApi, INJECT_MAIN_APPLICATION_CONTEXT } from '@instant-system/web-harmony-widget'
import configuration from '@/../config/wh-config.json'
import { onMounted, ref, provide } from 'vue'
import { useI18n } from 'vue-i18n'
import axios from 'axios'

const { locale } = useI18n()
provide(INJECT_MAIN_APPLICATION_CONTEXT, 'VAD')

const { loadFooterMenuItems } = useCmsMenuApi(fetchFn, configuration)

const menuItems = ref([])

onMounted(async () => {
    menuItems.value = await loadFooterMenuItems(locale.value)
})

async function fetchFn (url) {
    return axios.get(url)
        .then((response) => response.data)
}
</script>
