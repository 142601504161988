<template>
    <div
        v-if="basket.products.length > 0"
        class="HeaderCartBasket"
    >
        <p
            v-if="basket.info === 'PROVIDERACCOUNTANDDEVICE'"
            class="HeaderCartBasket-number text-left"
        >
            <v-icon icon="mdi mdi-cellphone" />
            <span v-if="basket.deviceLabel"> {{ basket.deviceLabel }} </span>
        </p>
        <p
            v-if="basket.info === 'PROVIDERACCOUNTANDFAREMEDIA' || getLabel !== '' || getSubLabel !== ''"
            class="HeaderCartBasket-label"
        >
            <span v-if="basket.info === 'PROVIDERACCOUNTANDFAREMEDIA'"><v-icon icon="mdi mdi-card-bulleted-outline" /></span>
            <span v-else-if="getLabel !== ''">{{ getLabel }}</span>
            <span
                v-if="getSubLabel !== ''"
                class="HeaderCartBasket-number"
            >
                {{ getSubLabel }}
            </span>
        </p>
        <ul class="HeaderCartBasket-list">
            <header-cart-item
                v-for="cartItem in getBasketProducts"
                v-bind="cartItem"
                :key="cartItem.id"
                class="HeaderCartBasket-item"
            />
        </ul>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { isEmpty } from 'global-utils'
import HeaderCartItem from './HeaderCartItem'
import i18n from 'i18n'
const props = defineProps({
    basket: {
        type: Object,
        required: true
    }
})

const getBasketProducts = computed(() => {
    const products = props.basket.products
    if (
        !isEmpty(props.basket) &&
        (props.basket.basketType === 'FAREMEDIA' || props.basket.type === 'FAREMEDIA') &&
        props.basket.providerId &&
        props.basket.providerUserId &&
        props.basket.providerUserExternalId &&
        props.basket.fareMediaId
    ) {
        const updatedProduct = {
            ...products[0],
            actions: [{
                label: i18n.global.t('cart:fare_media_loading:title'),
                type: 'ADD_NEW_PRODUCT_TO_BASKET',
                route: {
                    name: 'catalog',
                    params: {
                        isTemporary: true,
                        providerId: props.basket.providerId,
                        providerUserId: props.basket.providerUserId,
                        providerUserExternalId: props.basket.providerUserExternalId,
                        fareMediaId: props.basket.fareMediaId
                    }
                }
            }]
        }
        return [updatedProduct, ...products.slice(1)]
    }
    return products
})

const getLabel = computed(() => {
    if (!isEmpty(props.basket.ownerFirstName) && !isEmpty(props.basket.ownerLastName) && props.basket.info !== 'PROVIDERACCOUNT' && props.basket.info !== 'ACCOUNT') {
        if (props.basket.ownerFirstName !== 'anonymous' && props.basket.ownerLastName !== 'anonymous') {
            return i18n.global.t('fare_media:card_of').replace('%cardOwner%', props.basket.ownerFirstName + ' ' + props.basket.ownerLastName)
        }
        return i18n.global.t('fare_media:card_number').replace('%cardNumber%', props.basket.fareMediaId)
    }
    return ''
})

const getSubLabel = computed(() => {
    if (isEmpty(props.basket.ownerFirstName) && isEmpty(props.basket.ownerLastName)) {
        if (props.basket.type === 'FAREMEDIA') {
            return ''
        } else if (props.basket.info === 'PROVIDERACCOUNTANDFAREMEDIA' && !isEmpty(props.basket.fareMediaId)) {
            return `n°${props.basket.fareMediaId}`
        }
    } else if (props.basket.ownerFirstName !== 'anonymous' && props.basket.ownerLastName !== 'anonymous') {
        return `n°${props.basket.fareMediaId}`
    }
    return ''
})
</script>

<style lang="scss" scoped>
@import 'globalScss';

.HeaderCartBasket {
    white-space: normal;

    &:first-child {
        border-top: 0;
    }

    &-user {
        @extend %h3;

        margin: 0 0 20px;
        padding: 8px 12px;
        border-radius: 10px;
        background: $color-lightest;
        font-weight: bold;
        text-align: left;
        color: $color-lighterText;
    }

    &-label {
        @extend %h4;

        margin: 0 0 15px;
        font-size: 16px;
        font-weight: bold;
        text-align: left;
        color: $color-lightText;

        :deep(& + .HeaderCartBasket-list) {
            padding-left: $s7;
        }

        :deep(& + .HeaderCartBasket-list .HeaderCartItem:first-child) {
            padding-top: 0;
            border-top: 0;
        }
    }

    &-number {
        font-size: 12px;
        color: $color-lighterText;
    }

    &-list {
        margin: 0;
        padding: 0 0 0 10px;
        list-style: none;
    }
}
</style>
