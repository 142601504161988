import unidecode from 'unidecode-plus'

export const indexOfSubstrings = function * (str, searchValue) {
    const regex = new RegExp(searchValue, 'gi')
    let match
    while ((match = regex.exec(str)) !== null) {
        yield match.index
    }
}

/**
 * Check if the normalized item text matches with the normalized query text
 * @param item The suggestion item (with at least a text key)
 * @param query The query string
 * @returns {boolean} True if the item's text matches with the query text, else false
 */
export const filterSuggestion = (label, query, item) => {
    const normalizedLowerQueryText = normalizeText(query)

    if (!normalizedLowerQueryText) {
        item.matches = []
        return true
    }

    const positions = Array.from(indexOfSubstrings(normalizeText(label), normalizedLowerQueryText))

    if (positions.length > 0) {
        item.matches = positions.map(position => ({
            start: position,
            end: position + normalizedLowerQueryText.length,
            substring: label.substr(position, normalizedLowerQueryText.length)
        }))

        return true
    }

    item.matches = [] // Reset matches if no positions found

    return false
}

export const stripTags = (htmlString) => {
    const element = document.createElement('div')
    element.innerHTML = htmlString

    return element.textContent || element.innerText || ''
}

export const highlightMatch = (item) => {
    if (item.matches && item.matches.length > 0) {
        const matches = item.matches.flatMap(match => match.substring)
        const regExp = new RegExp(matches.join('|'), 'gi')

        return item.title.replace(regExp, '<span class="v-autocomplete__mask">$&</span>')
    }

    return stripTags(item.title)
}

export const normalizeText = (text) => {
    if (typeof text !== 'string') {
        return text
    }

    return unidecode(text).toLowerCase().replaceAll(/['‘’ʼ— ]/gi, '-')
}

export const resetMatches = (items) => {
    const count = items?.length

    for (let i = 0; i < count; i++) {
        delete items[i].matches
    }
}
