<template>
    <div class="ProductLoginRequiredToAskFareProfiles">
        <p class="ProductLoginRequiredToAskFareProfiles-description">
            {{ getRequiredLoginFareProfilesDescription }}
        </p>
        <div class="ProductLoginRequiredToAskFareProfiles-customButtonWrapper d-flex justify-space-between">
            <v-btn
                class="mt-2 pa-0"
                color="primary"
                variant="text"
                @click="signIn"
            >
                {{ $t('sign_in') }}
            </v-btn>
            <v-btn
                class="bg-primary mt-2"
                @click="logIn"
            >
                {{ $t('account:nav:offline_toggle') }}
            </v-btn>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import i18n from 'i18n'
import { startLogInProcess, useLogin } from '@/StoreWeb/js/composables/login-utils'
import { isEmpty } from 'global-utils'
import { useRoute } from 'vue-router'

const route = useRoute()
const { kasConfigurationSettings } = useLogin()

const props = defineProps({
    requiredFareProfiles: {
        type: Array,
        required: true
    }
})

const getRequiredLoginFareProfilesDescription = computed(() => {
    const profileLabels = props.requiredFareProfiles.map(item => item.label.toLowerCase()).join(', ')
    return i18n.global.t('required_fare_profiles:description') + ' ' + profileLabels + '. ' +
        i18n.global.t('login_required_to_ask_fare_profiles')
})

const logIn = () => {
    startLogInProcess(route)
}

const signIn = () => {
    if (!isEmpty(kasConfigurationSettings.value?.pages?.registration)) {
        window.location.href = kasConfigurationSettings.value.pages.registration.setUrlParameter('kc_locale', i18n.locale)
        return
    }

    logIn()
}
</script>
