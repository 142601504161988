import BankAccountNormalizer from './BankAccountNormalizer'

const BankAccountNormalizerSingleton = (function () {
    let instance

    function createInstance () {
        const classObj = new BankAccountNormalizer()
        return classObj
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance()
            }
            return instance
        }
    }
})()

export default BankAccountNormalizerSingleton
