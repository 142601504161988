export default class {
    constructor () {
        this._amount = 0
        this._modalProduct = null
        this._fareExcludingTaxes = 0
        this._fareIncludingTaxes = 0
        this._id = ''
        this._modalCounter = null
        this._modalCounterTotalValue = 0
        this._modalCounterValue = 0
        this._name = ''
        this._productParameters = []
        this._providerFinePaymentId = 0
        this._providerItemDescription = ''
        this._providerNetworkLabel = ''
        this._quantity = 0
        this._storedDocuments = []
        this._technicalProductExternalId = ''
        this._totalFareExcludingTaxes = 0
        this._totalFareIncludingTaxes = 0
        this._totalVatAmount = 0
        this._vatAmout = 0
        this._vatRate = 0
        this._version = ''
    }

    get address () {
        return this._address
    }

    set address (address) {
        this._address = address
    }

    get amount () {
        return this._amount
    }

    set amount (amount) {
        this._amount = amount
    }

    get fareExcludingTaxes () {
        return this._fareExcludingTaxes
    }

    set fareExcludingTaxes (fareExcludingTaxes) {
        this._fareExcludingTaxes = fareExcludingTaxes
    }

    get fareIncludingTaxes () {
        return this._fareIncludingTaxes
    }

    set fareIncludingTaxes (fareIncludingTaxes) {
        this._fareIncludingTaxes = fareIncludingTaxes
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get modalCounter () {
        return this._modalCounter
    }

    set modalCounter (modalCounter) {
        this._modalCounter = modalCounter
    }

    get modalCounterTotalValue () {
        return this._modalCounterTotalValue
    }

    set modalCounterTotalValue (modalCounterTotalValue) {
        this._modalCounterTotalValue = modalCounterTotalValue
    }

    get modalCounterValue () {
        return this._modalCounterValue
    }

    set modalCounterValue (modalCounterValue) {
        this._modalCounterValue = modalCounterValue
    }

    get modalProduct () {
        return this._modalProduct
    }

    set modalProduct (modalProduct) {
        this._modalProduct = modalProduct
    }

    get name () {
        return this._name
    }

    set name (name) {
        this._name = name
    }

    get productParameters () {
        return this._productParameters
    }

    set productParameters (productParameters) {
        this._productParameters = productParameters
    }

    get providerFinePaymentId () {
        return this._providerFinePaymentId
    }

    set providerFinePaymentId (providerFinePaymentId) {
        this._providerFinePaymentId = providerFinePaymentId
    }

    get providerItemDescription () {
        return this._providerItemDescription
    }

    set providerNetworkLabel (providerNetworkLabel) {
        this._providerNetworkLabel = providerNetworkLabel
    }

    get providerNetworkLabel () {
        return this._providerNetworkLabel
    }

    set providerItemDescription (providerItemDescription) {
        this._providerItemDescription = providerItemDescription
    }

    get quantity () {
        return this._quantity
    }

    set quantity (quantity) {
        this._quantity = quantity
    }

    get storedDocuments () {
        return this._storedDocuments
    }

    set storedDocuments (storedDocuments) {
        this._storedDocuments = storedDocuments
    }

    get technicalProductExternalId () {
        return this._technicalProductExternalId
    }

    set technicalProductExternalId (technicalProductExternalId) {
        this._technicalProductExternalId = technicalProductExternalId
    }

    get totalFareExcludingTaxes () {
        return this._totalFareExcludingTaxes
    }

    set totalFareExcludingTaxes (totalFareExcludingTaxes) {
        this._totalFareExcludingTaxes = totalFareExcludingTaxes
    }

    get totalFareIncludingTaxes () {
        return this._totalFareIncludingTaxes
    }

    set totalFareIncludingTaxes (totalFareIncludingTaxes) {
        this._totalFareIncludingTaxes = totalFareIncludingTaxes
    }

    get totalVatAmount () {
        return this._totalVatAmount
    }

    set totalVatAmount (totalVatAmount) {
        this._totalVatAmount = totalVatAmount
    }

    get vatAmout () {
        return this._vatAmout
    }

    set vatAmout (vatAmout) {
        this._vatAmout = vatAmout
    }

    get vatRate () {
        return this._vatRate
    }

    set vatRate (vatRate) {
        this._vatRate = vatRate
    }

    get version () {
        return this._version
    }

    set version (version) {
        this._version = version
    }

    toJSON () {
        return {
            address: this.address,
            amount: this.amount,
            fareExcludingTaxes: this.fareExcludingTaxes,
            fareIncludingTaxes: this.fareIncludingTaxes,
            id: this.id,
            modalCounter: this.modalCounter,
            modalCounterTotalValue: this.modalCounterTotalValue,
            modalCounterValue: this.modalCounterValue,
            modalProduct: this.modalProduct,
            name: this.name,
            productParameters: this.productParameters,
            providerFinePaymentId: this.providerFinePaymentId,
            providerItemDescription: this.providerItemDescription,
            providerNetworkLabel: this.providerNetworkLabel,
            quantity: this.quantity,
            storedDocuments: this.storedDocuments,
            technicalProductExternalId: this.technicalProductExternalId,
            totalFareExcludingTaxes: this.totalFareExcludingTaxes,
            totalFareIncludingTaxes: this.totalFareIncludingTaxes,
            totalVatAmount: this.totalVatAmount,
            vatAmout: this.vatAmout,
            vatRate: this.vatRate,
            version: this.version
        }
    }
}
