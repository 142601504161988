import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
    namespaced: false,
    actions,
    getters,
    mutations,
    state: {
        addresses: [],
        addressAuthorizations: {
            add: true,
            edit: true,
            maxNb: 0,
            minNb: 1,
            remove: true
        },
        addressPendingRequests: {
            createAddress: false,
            deleteAddress: false,
            getAddress: false,
            getAddresses: false,
            getShopAddresses: false,
            getAddressTemplates: false,
            setAddressAsDefault: false,
            updateAddress: false
        },
        addressTemplates: [],
        shops: []
    }
}
