<template>
    <div
        :style="{ zIndex : (headerBarZIndex - 1) }"
        class="FareMediaContextBar"
    >
        <div
            :class="[(getHasSelectedFareMedia ? 'FareMediaContextBar-content--withSelectedFareMedia' : 'FareMediaContextBar-content--withoutSelectedFareMedia')]"
            class="FareMediaContextBar-content"
        >
            <v-btn
                v-if="isAuthenticated"
                class="bg-secondary"
                @click.prevent="showFareMediaChoiceModal"
            >
                <icomoon-icon
                    name="Change--outlined"
                    class="mr-1"
                />
                {{ $t('button:choose_card') }}
            </v-btn>
            <v-btn
                v-else
                type="submit"
                class="bg-primary"
                size="small"
                @click.prevent="logIn"
            >
                {{ $t('button:login') }}
            </v-btn>
            <div class="FareMediaContextBar-state">
                <p class="FareMediaContextBar-title">
                    {{ getTitle }}
                </p>
                <p
                    v-if="getSubtitle !== ''"
                    class="FareMediaContextBar-subtitle"
                >
                    <span
                        :class="'Icon' + selectedFareMedia.getIcon()"
                        class="FareMediaContextBar-subtitleIcon"
                    />
                    <span class="FareMediaContextBar-subtitleLabel">{{ getSubtitle }}</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { isEmpty } from 'global-utils'
import i18n from 'i18n'
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon.vue'
import { startLogInProcess, useLogin } from '@/StoreWeb/js/composables/login-utils'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

const route = useRoute()
const store = useStore()
const { isAuthenticated } = useLogin()

const emit = defineEmits(['switchFareMediaContext'])

const headerBarZIndex = computed(() => store.state.headerBarZIndex)
const selectedFareMedia = computed(() => store.state.userModule.selectedFareMedia)

const getHasSelectedFareMedia = computed(() => {
    return selectedFareMedia.value && !isEmpty(selectedFareMedia.value) && selectedFareMedia.value.id !== undefined
})
const getTitle = computed(() => {
    return getHasSelectedFareMedia.value && !selectedFareMedia.value.isAccount ? selectedFareMedia.value.getTitle() : i18n.global.t('fare_media:context_bar:all_products:title')
})
const getSubtitle = computed(() => {
    return getHasSelectedFareMedia.value && !selectedFareMedia.value.isAccount ? selectedFareMedia.value.getSubtitle() : ''
})

function logIn () {
    startLogInProcess(route)
}

function showFareMediaChoiceModal () {
    emit('switchFareMediaContext', this)
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.FareMediaContextBar {
    display: flex;
    justify-content: center;
    position: relative;
    padding: 10px 15px 8px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, .11);
    background: $color-lightgray3;

    &-button {
        width: auto;
    }

    &-title,
    &-subtitle {
        display: inline;
        margin: 0;
    }

    &-title {
        font-family: $fontFamily-title;
        font-size: 18px;
        font-weight: $fontWeight-titleBold;
    }

    &-subtitle {
        display: inline-flex;
        align-items: center;
        margin-bottom: -2px;

        &Icon {
            margin-right: 2px;
            font-size: 24px;
        }

        &Label {
            font-size: 12px;
            font-weight: $fontWeight-defaultMedium;
            color: $color-lighterText;
        }
    }

    &-content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        width: 100%;
        max-width: 1065px;

        &--withoutSelectedFareMedia {
            .FareMediaContextBar-subtitleIcon {
                margin-left: -5px;
                font-size: 20px;
            }
        }

        &--withSelectedFareMedia {
            .FareMediaContextBar-subtitleIcon {
                color: $color-brandPrimary;
            }
        }
    }

    &-state {
        display: inline-flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex-grow: 1;

        @media (min-width: $tablet-breakpoint) {
            flex-direction: row;
            align-items: center;
            gap: 10px;
        }
    }
}
</style>
