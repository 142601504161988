<template>
    <div class="SupportContent">
        <div class="Container">
            <h1 class="h1">
                {{ $t('support_content:title') }}
            </h1>
            <div class="SupportContent-form">
                <v-text-field
                    v-model="supportId"
                    :label="$t('form:support_content:support_id:label')"
                    :pattern="getPattern"
                    :required="true"
                    variant="underlined"
                    class="SupportContent-field"
                />
                <v-btn
                    :disabled="!getIsSupportIdValid"
                    color="primary"
                    type="submit"
                    size="large"
                    @click="supportContentLoadedHandler"
                >
                    {{ $t('button:read') }}
                </v-btn>
                <v-btn
                    v-if="getIsCardReaderAvailable"
                    color="primary"
                    type="submit"
                    variant="text"
                    class="SupportContent-button"
                    @click="useCardReader"
                >
                    {{ $t('card_reader:use_button_text') }}
                </v-btn>
            </div>
            <alert-message
                v-if="errorMessage"
                :message="errorMessage"
                type="error"
                class="SupportContent-alert"
            />
            <support-reader-modal
                v-model="showSupportReaderModal"
                :headerTitle="$t('card_reader:scan_modal:title')"
                :operationType="'READ'"
                @operation-finished="onOperationFinished"
            />
        </div>
    </div>
</template>

<script setup>
import { isEmpty } from 'global-utils'
import { useRoute, useRouter } from 'vue-router'
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import config from 'config'
import AlertMessage from '@/StoreWeb/components/common/AlertMessage'
import SupportReaderModal from '@/StoreWeb/components/miscellaneous/conduent-support-reader/SupportReaderModal'
import { setSelectedFareMediaCookie } from '@/StoreWeb/js/mixins/wallet-utils'
import * as anonymousActionTypes from '@/StoreWeb/store/modules/anonymous/action-types'
import * as anonymousMutationTypes from '@/StoreWeb/store/modules/anonymous/mutation-types'

const store = useStore()
const route = useRoute()
const router = useRouter()

const errorMessage = ref(undefined)
const providerId = ref(undefined)
const showSupportReaderModal = ref(false)
const supportId = ref(undefined)

const isMobileApp = computed(() => store.state.isMobileApp)
const isMobileOrTablet = computed(() => store.state.isMobileOrTablet)
const getPattern = computed(() => !isEmpty(config.patterns.support_id.conduent) ? config.patterns.support_id.conduent : '')
const getIsSupportIdValid = computed(() => !isEmpty(supportId.value) ? !isEmpty(supportId.value.match(config.patterns.support_id.conduent)) : false)
const getIsCardReaderAvailable = computed(() => !isMobileApp.value && !isMobileOrTablet.value)
const supportContent = computed(() => store.state.anonymousModule.supportContent)

onMounted(() => {
    const fareMediaId = route.params.fareMediaId

    if (!isEmpty(fareMediaId)) {
        supportId.value = fareMediaId
    }

    if (route.params?.providerId) {
        providerId.value = route.params.providerId
    }
})

function useCardReader () {
    showSupportReaderModal.value = true
}

async function supportContentLoadedHandler () {
    errorMessage.value = undefined
    const params = {
        providerId: providerId.value,
        fareMediaId: supportId.value
    }
    if (isEmpty(supportContent.value)) {
        const content = await store.dispatch(anonymousActionTypes.GET_SUPPORT_CONTENT, params)
        if (content?.id) {
            store.commit(anonymousMutationTypes.SET_SUPPORT_CONTENT, content)
        }
    }
    if (!isEmpty(supportContent.value)) {
        router.push({ name: 'walletLight', params })
    }
}

async function onOperationFinished (data) {
    if (!isEmpty(data.support)) {
        supportId.value = data.support.fareMediaId
    }

    showSupportReaderModal.value = false
    const params = {
        providerId: 'conduent',
        fareMediaId: supportId.value,
        isTemporary: false
    }
    setSelectedFareMediaCookie(params, true)
    if (isEmpty(supportContent.value)) {
        const content = await store.dispatch(anonymousActionTypes.GET_SUPPORT_CONTENT, params)
        if (content?.id) {
            store.commit(anonymousMutationTypes.SET_SUPPORT_CONTENT, content)
        }
    }
    if (!isEmpty(supportContent.value)) {
        router.push(
            {
                name: 'walletLight',
                params: { providerId: 'conduent', fareMediaId: supportId.value }
            }
        )
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.SupportContent {
    &-form {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: $s5;
        width: 100%;
        margin-top: $s8;

        @media (min-width: $tablet-breakpoint) {
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: $s4;
        }
    }

    &-field {
        width: 100%;

        @media (min-width: $tablet-breakpoint) {
            width: 250px;
        }
    }

    &-button {
        font-weight: $fontWeight-defaultBold;
    }

    &-alert {
        margin-top: $s5;
    }
}
</style>
