<template>
    <div class="EditCivility">
        <v-form @submit.prevent="submit">
            <v-select
                v-model="civility"
                :error-messages="v$.civility.$errors.map(e => e.$message)"
                :label="$t('form:personal_info:civility:label')"
                :items="civilities"
                item-value="id"
                item-title="name"
                disabled
                @update:model-value="v$.civility.$touch()"
            >
                <template #append>
                    <v-icon
                        v-if="submitted && submitSucceed"
                        icon="mdi-check"
                        color="success"
                    />
                    <v-icon
                        v-else-if="submitted && !submitSucceed"
                        icon="mdi-close"
                        color="error"
                    />
                </template>
            </v-select>
            <v-text-field
                v-model="lastName"
                :error-messages="v$.lastName.$errors.map(e => e.$message)"
                :label="$t('form:user_infos:lastname:label')"
                disabled
                variant="underlined"
                @update:model-value="v$.lastName.$touch()"
            >
                <template #append>
                    <v-icon
                        v-if="submitted && submitSucceed"
                        icon="mdi-check"
                        color="success"
                    />
                    <v-icon
                        v-else-if="submitted && !submitSucceed"
                        icon="mdi-close"
                        color="error"
                    />
                </template>
            </v-text-field>
            <v-text-field
                v-model="firstName"
                :error-messages="v$.firstName.$errors.map(e => e.$message)"
                :label="$t('form:user_infos:firstname:label')"
                disabled
                variant="underlined"
                @update:model-value="v$.firstName.$touch()"
            >
                <template #append>
                    <v-icon
                        v-if="submitted && submitSucceed"
                        icon="mdi-check"
                        color="success"
                    />
                    <v-icon
                        v-else-if="submitted && !submitSucceed"
                        icon="mdi-close"
                        color="error"
                    />
                </template>
            </v-text-field>
            <v-text-field
                v-model="birthdate"
                type="date"
                :label="$t('form:user_infos:birthdate:label')"
                variant="underlined"
                disabled
            />
        </v-form>
    </div>
</template>
<script setup>
import { computed, reactive, ref, watch } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@/StoreWeb/utils/i18n-validators'
import * as userActionTypes from '@/StoreWeb/store/modules/user/action-types'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

const store = useStore()
const { t } = useI18n()

const props = defineProps({
    firstName: { type: String, default: '' },
    lastName: { type: String, default: '' },
    civility: { type: String, default: '' },
    birthdate: { type: String, default: '' }
})

watch(
    [() => props.firstName, () => props.lastName, () => props.civility, () => props.birthdate],
    ([newFirstName, newLastName, newCivilityId, newBirthdate]) => {
        firstName.value = newFirstName
        originalValues.value.firstName = newFirstName

        lastName.value = newLastName
        originalValues.value.lastName = newLastName

        civility.value = newCivilityId
        originalValues.value.civility = newCivilityId

        birthdate.value = newBirthdate
        originalValues.value.birthdate = newBirthdate
    }
)

const originalValues = ref({
    firstName: props.firstName,
    lastName: props.lastName,
    civility: props.civility,
    birthdate: props.birthdate
})

const emit = defineEmits(['validated', 'submitted'])

const firstName = ref(props.firstName)
const lastName = ref(props.lastName)
const civility = ref(props.civility)
const birthdate = ref(props.birthdate)
const submitSucceed = ref(false)
const submitted = ref(false)
const hasChanged = ref(false)

const civilities = reactive([
    {
        id: 'UNDEFINED',
        name: computed(() => t('form:personal_info:civility:choices:undefined'))
    },
    {
        id: 'MR',
        name: computed(() => t('form:personal_info:civility:choices:mr'))
    },
    {
        id: 'MS',
        name: computed(() => t('form:personal_info:civility:choices:ms'))
    }
])

const hasFieldChanged = (originalValue, currentValue) => originalValue !== currentValue

watch(
    [firstName, lastName, civility, birthdate],
    ([newFirstName, newLastName, newCivility, newBirthdate]) => {
        hasChanged.value =
            hasFieldChanged(originalValues.value.firstName, newFirstName) ||
            hasFieldChanged(originalValues.value.lastName, newLastName) ||
            hasFieldChanged(originalValues.value.civility, newCivility)
    }
)

const rules = {
    firstName: { required },
    lastName: { required },
    civility: { required }
}
const v$ = useVuelidate(rules, { firstName, lastName, civility, birthdate })

async function validate () {
    submitSucceed.value = false
    submitted.value = false

    emit('validated', await v$.value.$validate())
}

async function submit () {
    await validate()

    if (!v$.value.$pending && v$.value.$error) {
        return
    }
    let result = { success: true }
    if (hasChanged.value) {
        result = await store.dispatch(userActionTypes.SET_PERSONAL_INFO, {
            firstName: firstName.value,
            lastName: lastName.value,
            civility: civility.value
        })
    }
    submitSucceed.value = result.success
    submitted.value = true
    hasChanged.value = false

    emit('submitted', result)
}

defineExpose({ validate, submit, hasChanged })
</script>
