import { isEmpty } from 'global-utils'
import i18n from 'i18n'
import localeConversionTable from '@/StoreWeb/js/locales-conversion-table'

export default class {
    constructor () {
        this._accessDate = ''
        this._application = 'STORE'
        this._creationDate = ''
        this._data = {}
        this._id = null
        this._sessionContext = null
        this._updateDate = ''

        this.setLanguage()
    }

    get accessDate () {
        return this._accessDate
    }

    set accessDate (accessDate) {
        this._accessDate = accessDate
    }

    get application () {
        return this._application
    }

    set application (application) {
        this._application = application
    }

    get creationDate () {
        return this._creationDate
    }

    set creationDate (creationDate) {
        this._creationDate = creationDate
    }

    get data () {
        return this._data
    }

    set data (data) {
        this._data = data
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get sessionContext () {
        return this._sessionContext
    }

    set sessionContext (sessionContext) {
        this._sessionContext = sessionContext
    }

    get updateDate () {
        return this._updateDate
    }

    set updateDate (updateDate) {
        this._updateDate = updateDate
    }

    setLanguage (language) {
        if (typeof language !== 'undefined') {
            this.data.language = language
        } else {
            this.data.language = localeConversionTable[i18n.global.locale]
        }
    }

    setUserToken (userToken) {
        if (typeof userToken === 'object') {
            this.data.userToken = JSON.stringify(userToken)
        } else {
            this.data.userToken = userToken
        }
    }

    toJSON () {
        const applicationSessionObject = {
            application: this.application
        }
        if (this.accessDate !== '') {
            applicationSessionObject.accessDate = this.accessDate
        }
        if (this.creationDate !== '') {
            applicationSessionObject.creationDate = this.creationDate
        }
        if (!isEmpty(this.data)) {
            applicationSessionObject.data = this.data
        }
        if (!isEmpty(this.id)) {
            applicationSessionObject.id = this.id
        }
        if (!isEmpty(this.sessionContext)) {
            applicationSessionObject.sessionContext = this.sessionContext
        }
        if (this.updateDate !== '') {
            applicationSessionObject.updateDate = this.updateDate
        }
        return applicationSessionObject
    }
}
