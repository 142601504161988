<template>
    <div
        v-if="isDisplayed"
        class="UserAccountSubscriptionButton"
    >
        <div class="UserAccountSubscriptionButton-subscription">
            <v-btn
                color="primary"
                variant="tonal"
                class="UserAccountSubscriptionButton-subscriptionLink ma-2"
                @click="showSubscriptionModal"
            >
                <v-icon class="mdi mdi-plus UserAccountSubscriptionButton-icon" />
                {{ getLabel }}
            </v-btn>
        </div>
    </div>
</template>

<script>
import emitter from 'global-emitter'
import { mapGetters } from 'vuex'
import config from 'config'

export default {
    props: {
        provider: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters(['getWalletProviders']),
        isDisplayed () {
            const acceptedWalletAddAccountModes = ['FAREMEDIA_BIRTHDATE', 'FIRSTNAME_LASTNAME_BIRTHDATE', 'EMAIL_PASSWORD']
            const selectedProvider = config.providers.find(provider => provider.id === this.getProvider.id)

            return this.getProvider &&
                (
                    (this.getProvider.walletCreateAccount && this.getProvider.walletCreateAccount.mode === 'SYTRALSOUSCRIPTION') ||
                    (this.getProvider.walletCreateThirdPartyAccount && this.getProvider.walletCreateThirdPartyAccount.mode === 'SYTRALTHIRDPARTYSOUSCRIPTION') ||
                    (
                        selectedProvider?.fare_media?.add &&
                        this.getProvider.walletAddAccount &&
                        acceptedWalletAddAccountModes.includes(this.getProvider.walletAddAccount.mode)
                    )
                )
        },
        getLabel () {
            if (this.getProvider?.walletCreateAccount?.mode === 'SYTRALSOUSCRIPTION') {
                return this.getProvider.walletCreateAccount.label
            } else if (this.getProvider?.walletCreateThirdPartyAccount?.mode === 'SYTRALTHIRDPARTYSOUSCRIPTION') {
                return this.getProvider.walletCreateThirdPartyAccount.label
            }
            return this.getProvider.walletAddAccount.label
        },
        getProvider () {
            const walletProvider = this.getWalletProviders.find(provider => provider.id === this.provider.id)
            if (walletProvider) {
                return walletProvider
            }
            return this.provider
        }
    },
    methods: {
        showSubscriptionModal () {
            if (this.getProvider?.walletCreateAccount?.mode === 'SYTRALSOUSCRIPTION') {
                this.$emit('showUserAccountSubscriptionModal', this.provider)
            } else if (this.getProvider?.walletCreateThirdPartyAccount?.mode === 'SYTRALTHIRDPARTYSOUSCRIPTION') {
                this.$emit('showUserSubscriptionThirdPartyModal', this.getProvider.accounts)
            } else {
                emitter.emit('showAddFareMediaModal', { walletId: this.getProvider.id, formType: this.getProvider.walletAddAccount.mode })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.UserAccountSubscriptionButton {
    &-subscription {
        margin: 0;
        font-size: 14px;
        font-weight: $fontWeight-defaultBold;
        text-align: center;

        &Link {
            display: inline-flex;
            align-items: center;
            border: none;
            cursor: pointer;
            font-weight: bold;
            color: $color-brandPrimary;
            transition: color .3s;

            &:hover,
            &:active {
                color: $color-brandPrimaryDark;
            }
        }
    }

    &-icon {
        font-size: 24px;
    }
}
</style>
