<template>
    <component
        :is="'li'"
        class="AssociatedAccountListItem"
    >
        <div class="h3">
            {{ associatedAccount.getFullName() }}
        </div>
        <div
            v-for="provider in associatedAccount.providers"
            :key="provider.id"
        >
            <div v-if="!isProviderHasDevices(provider.accounts)">
                <fare-profile
                    v-if="!isEmpty(provider.walletManageFareProfile)"
                    :provider="provider"
                    :providerAccount="associatedAccount"
                    class="AssociatedAccountListItem-fareProfile"
                />
                <ul
                    v-if="getFareMedias(provider.accounts).length > 0"
                    class="AssociatedAccountListItem-list AssociatedAccountListItem-fareMediaList"
                >
                    <fare-media-item
                        v-for="fareMedia in getFareMedias(provider.accounts)"
                        :key="fareMedia.id"
                        :fareMedia="fareMedia"
                        class="AssociatedAccountListItem-fareMediaItem"
                        @download-fare-media-certificate-button-clicked="downloadFareMediaCertificateButtonClicked"
                        @suspend-subscription-button-clicked="suspendSubscriptionButtonClicked"
                    />
                </ul>
                <div v-else>
                    <empty-cards />
                </div>
            </div>
            <div
                v-if="provider.walletAddFareMedia"
                class="AssociatedAccountListItem-addCard"
            >
                <v-btn
                    class="my-2 font-weight-bold"
                    color="primary"
                    variant="text"
                    @click="onAddFareMediaButtonClick"
                >
                    <v-icon class="IconCard" />
                    {{ provider.walletAddFareMedia.label }}
                </v-btn>
            </div>
        </div>
        <fare-media-add-modal
            v-model="showFareMediaAddModal"
            :is-coming-from-fare-media-choice-modal="false"
            @go-next-action="fareMediaAddModalCloseAction"
            @close-modal="closeAllFareMediaChoiceOrAddModals"
        />
        <fare-media-add-new-modal
            v-model="showFareMediaAddNewModal"
            :is-coming-from-fare-media-choice-modal="false"
            @go-next-action="fareMediaAddNewModalCloseAction"
            @close-modal="closeAllFareMediaChoiceOrAddModals"
        />
        <fare-media-add-existant-modal
            v-model="showFareMediaAddExistantModal"
            :is-coming-from-fare-media-choice-modal="false"
            @go-next-action="fareMediaAddExistantModalCloseAction"
            @close-modal="closeAllFareMediaChoiceOrAddModals"
        />
    </component>
</template>

<script setup>
import FareMediaItem from '@/StoreWeb/components/fare-media/FareMediaItem.vue'
import FareProfile from '@/StoreWeb/components/fare-profile/FareProfile.vue'
import FareMediaAddExistantModal from '@/StoreWeb/components/fare-media/FareMediaAddExistantModal'
import FareMediaAddModal from '@/StoreWeb/components/fare-media/FareMediaAddModal'
import FareMediaAddNewModal from '@/StoreWeb/components/fare-media/FareMediaAddNewModal'
import { useModalActions } from '@/StoreWeb/js/composables/add-faremedia-modals-utils'
import { isEmpty } from 'global-utils'

const {
    closeAllFareMediaChoiceOrAddModals,
    fareMediaAddExistantModalCloseAction,
    fareMediaAddModalCloseAction,
    fareMediaAddNewModalCloseAction,
    getFareMediaAddActionModal,
    setShowFareMediaAddModal,
    setShowFareMediaAddExistantModal,
    setShowFareMediaAddNewModal,
    showFareMediaAddExistantModal,
    showFareMediaAddModal,
    showFareMediaAddNewModal
} = useModalActions()

const emit = defineEmits(['hideFareMediaChoiceModal', 'downloadFareMediaCertificateButtonClicked', 'suspendSubscriptionButtonClicked'])

defineProps({
    associatedAccount: {
        type: Object,
        required: true
    },
    displayedListType: {
        type: String,
        default: 'ALL',
        validator: val => ['ALL', 'COUNTERS', 'FAREMEDIAS', 'MTICKETS', 'PLANS', 'SUPPORTS'].includes(val)
    }
})

function isProviderHasDevices (accounts) {
    return accounts.some(account => account.devices.length > 0)
}

function getFareMedias (accounts) {
    const fareMedias = []
    accounts.forEach(account => {
        if (account.fareMedias.length > 0) {
            fareMedias.push(...account.fareMedias)
        }
    })
    return fareMedias
}

function downloadFareMediaCertificateButtonClicked (data) {
    emit('downloadFareMediaCertificateButtonClicked', data)
}

function suspendSubscriptionButtonClicked (data) {
    emit('suspendSubscriptionButtonClicked', data)
}

function onAddFareMediaButtonClick () {
    switch (getFareMediaAddActionModal('conduent')) {
        case 'shouldShowFareMediaAddModal':
            setShowFareMediaAddModal()
            break
        case 'shouldShowFareMediaAddExistantModal':
            setShowFareMediaAddExistantModal()
            break
        case 'shouldShowFareMediaAddNewModal':
            setShowFareMediaAddNewModal()
            break
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.AssociatedAccountListItem {
    margin-bottom: 30px;
    list-style: none;

    &:last-child {
        margin-bottom: 0;
    }

    &-walletName {
        font-family: $fontFamily-title;
        font-size: $font-xmedium;
        font-weight: $fontWeight-titleRegular;
        color: $color-defaultText;
    }

    &-list {
        margin: 0 0 25px;
        padding: 0;
        list-style: none;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-addCard {
        margin: 0;
        font-size: 14px;
        font-weight: $fontWeight-defaultBold;
        text-align: center;

        &Link {
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            color: $color-brandPrimary;
            transition: color .3s;

            &:hover,
            &:active {
                color: $color-brandPrimaryDark;
            }
        }

        &Icon {
            font-size: 36px;
        }
    }
}
</style>
