import config from 'config'

export default {
    default: {
        home: {
            authenticated: false
        },
        loggingIn: {
            authenticated: false
        },
        catalog: {
            authenticated: false
        },
        product: {
            authenticated: false
        },
        cart: {
            authenticated: false,
            emptyCart: false
        },
        cartConfirmation: {
            authenticated: false,
            emptyCart: false,
            paymentProcessingFlow: true
        },
        paymentForm: {
            authenticated: false,
            emptyCart: false,
            paymentProcessingFlow: true
        },
        paymentSuccess: {
            authenticated: false,
            paymentProcessingFlow: true
        },
        paymentError: {
            authenticated: false,
            paymentProcessingFlow: true
        },
        paymentCancelled: {
            authenticated: false,
            paymentProcessingFlow: true
        },
        orderSuccess: {
            authenticated: false,
            paymentProcessingFlow: true
        },
        signedIn: {
            authenticated: false
        },
        externalLinks: {
            authenticated: false
        },
        internalLinks: {
            authenticated: false
        },
        fareMediaReloading: {
            authenticated: false
        },
        fareMediaLoading: {
            authenticated: false
        },
        fineRegularization: {
            authenticated: false,
            isAuthorized: config.features.fine_regularization
        },
        fineSelection: {
            authenticated: false,
            isAuthorized: config.features.fine_regularization
        },
        contact: {
            authenticated: false
        },
        account: {
            authenticated: true
        },
        accountAssociations: {
            authenticated: true
        },
        alerts: {
            authenticated: true
        },
        directDebits: {
            authenticated: true
        },
        favourites: {
            authenticated: true
        },
        informations: {
            authenticated: true
        },
        notifications: {
            authenticated: true
        },
        openPaymentTrips: {
            authenticated: true
        },
        orderDetails: {
            authenticated: true
        },
        password: {
            authenticated: true
        },
        paymentMeans: {
            authenticated: true,
            paymentProcessingFlow: true,
            isAuthorized: config.features.payment_means
        },
        purchases: {
            authenticated: true
        },
        tickets: {
            authenticated: true
        },
        supportContent: {
            authenticated: false
        },
        walletLight: {
            authenticated: false
        },
        error404: {
            authenticated: false,
            paymentProcessingFlow: false
        },
        renewalProducts: {
            authenticated: true
        },
        homePage: {
            authenticated: false
        }
    }
}
