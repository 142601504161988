<template>
    <fieldset
        class="RadioButtonList"
    >
        <legend class="RadioButtonList-legend">
            {{ ariaLabel }}
        </legend>
        <div
            v-for="(item, index) in items"
            :key="'radio-button-list_'+index"
            class="RadioButtonList-item"
            @keyup.space="validateValue()"
            @click="updateValue(item.id)"
        >
            <input
                :id="item.id"
                v-model="currentValue"
                :value="item.id"
                :aria-checked="currentValue === item.id"
                class="RadioButtonList-input u-clientColor"
                role="radio"
                tabindex="0"
                type="radio"
            >
            <span class="RadioButtonList-radioButtonContainer">
                <span
                    :style="{ backgroundColor: currentValue === item.id ? mainColor : ''}"
                    class="RadioButtonList-radioButton"
                />
            </span>
            <label
                :for="item.id"
                class="RadioButtonList-label"
            >
                {{ item.label }}
            </label>
        </div>
    </fieldset>
</template>

<script>
import { mapState } from 'vuex'

export default {
    props: {
        ariaLabel: {
            type: String,
            required: true
        },
        items: {
            type: Array,
            required: true
        },
        value: {
            type: [Number, String],
            required: true
        }
    },
    data () {
        return {
            currentValue: this.value
        }
    },
    computed: {
        ...mapState(['mainColor'])
    },
    watch: {
        value (val) {
            this.currentValue = val
        }
    },
    methods: {
        updateValue (newValue) {
            if (newValue !== this.currentValue) {
                this.currentValue = newValue
                this.$emit('change', this.currentValue)
            }
        },
        validateValue () {
            this.$emit('validate', this.currentValue)
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.RadioButtonList {
    margin: 0;
    padding: 0;
    border: none;

    &-legend {
        display: block;
        height: 0;
        color: transparent;
    }

    &-radioButtonContainer {
        @include verticalCenter;

        justify-content: center;
        width: 20px;
        min-width: 20px;
        height: 20px;
        min-height: 20px;
        padding: 2px;
        border: 1px solid $color-grey;
        border-radius: $borderRadius-rounded;
    }

    &-radioButton {
        width: 100%;
        height: 100%;
        border-radius: $borderRadius-rounded;
        transition: background-color .3s ease-in-out;
    }

    &-item {
        @include verticalCenter;

        position: relative;
        padding: $padding-xsmall 0;
        border: none;
        background-color: transparent;
        cursor: pointer;
        white-space: normal;

        &:hover {
            .RadioButtonList-radioButton {
                background-color: $color-grey;
            }
        }
    }

    &-input {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        appearance: none;
    }

    &-label {
        @include handleOverflowTextContent;

        margin-left: $margin-small;
        cursor: pointer;
        font-family: $fontLato;
        font-size: $font-small;
        font-weight: $fontLato-regular;
        color: $color-black-light;
    }
}
</style>
