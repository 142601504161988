<template>
    <div class="d-flex justify-center align-center flex-column">
        <v-progress-circular
            v-if="showLoader"
            indeterminate
            color="primary"
        />
        <v-form
            class="w-100"
            @submit.prevent="submitForm"
        >
            <v-card elevation="0">
                <v-card-title v-if="getLabel">
                    <v-icon icon="mdi mdi-bank-plus" />&nbsp;{{ $t(getLabel) }}
                </v-card-title>
                <v-card-subtitle
                    v-if="getSubtitle"
                    class="mt-2"
                >
                    {{ $t(getSubtitle) }}
                </v-card-subtitle>
                <v-card-text>
                    <alert-message
                        v-if="showErrorMessage"
                        :message="errorMessage"
                        class="BankAccountAddModal-errorMessage mb-3"
                        type="error"
                    />
                    <v-form @submit.prevent="submitForm">
                        <template
                            v-for="field in selectedTemplate"
                        >
                            <v-text-field
                                v-if="field.type === 'text'"
                                :key="field.id"
                                v-model="formValues[field.id]"
                                :error-messages="v$[field.id].$error ? v$[field.id].$errors.map(e => e.$message) : ''"
                                :label="$t(field.label)"
                                :disabled="field.readOnly"
                                variant="underlined"
                                @update:model-value="v$[field.id].$touch()"
                            />
                            <v-text-field
                                v-if="field.type === 'date'"
                                :key="field.id"
                                v-model="formValues[field.id]"
                                type="date"
                                :error-messages="v$[field.id].$error ? $t(v$[field.id].$errors.map(e => e.$message)) : ''"
                                :label="$t(field.label)"
                                :disabled="field.readOnly"
                                variant="underlined"
                                @update:model-value="v$[field.id].$touch()"
                            />
                            <v-autocomplete
                                v-if="field.type === 'choice'"
                                :key="field.id"
                                v-model="formValues[field.id]"
                                variant="underlined"
                                :error-messages="v$[field.id].$error ? $t(v$[field.id].$errors.map(e => e.$message)) : ''"
                                :items="field.params.list"
                                :label="$t(field.label)"
                                item-title="label"
                                item-value="value"
                                :disabled="field.readOnly"
                                @update:model-value="v$[field.id].$touch()"
                            />
                        </template>
                        <v-card-actions class="pr-0 pb-0 pl-0 pt-5">
                            <v-spacer />
                            <v-btn
                                variant="text"
                                @click="closeModal()"
                            >
                                {{ $t('button:cancel') }}
                            </v-btn>
                            <v-btn
                                class="AddAccountModal-submitButton"
                                color="primary"
                                type="submit"
                            >
                                <v-icon icon="mdi mdi-bank-plus" />&nbsp;{{ submitButtonLabel }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-form>
    </div>
</template>

<script setup>
import { computed, onMounted, reactive, ref } from 'vue'
import { regex, required } from '@/StoreWeb/utils/i18n-validators'
import { useVuelidate } from '@vuelidate/core'
import { isEmpty } from 'global-utils'
import { useStore } from 'vuex'
import * as addressActionTypes from '@/StoreWeb/store/modules/address/action-types'
import ErrorManager from '@/StoreWeb/managers/ErrorManager'
import { helpers } from '@vuelidate/validators'
import i18n from 'i18n'
import AlertMessage from '@/StoreWeb/components/common/AlertMessage'
import { useLogin } from '@/StoreWeb/js/composables/login-utils'

const emit = defineEmits(['closeBankAccountModal', 'submitBankAccountForm'])

const props = defineProps({
    actionLabel: {
        type: String,
        default: i18n.global.t('add')
    },
    bankAccount: {
        type: Object,
        default: null
    },
    errorMessage: {
        type: String,
        default: ''
    },
    provider: {
        type: Object,
        required: true
    },
    submitButtonLabel: {
        type: String,
        default: i18n.global.t('iban:submit_button:label')
    },
    type: {
        type: String,
        required: true,
        validator: function (value) {
            return ['add', 'edit'].includes(value)
        }
    }
})

const store = useStore()
const { userInfos } = useLogin()

const addBankAccountForm = ref()
const selectedTemplate = ref()
const showLoader = ref(true)

const addresses = computed(() => store.getters.getAddresses)
const getLabel = computed(() => addBankAccountForm.value?.label ? i18n.global.t(addBankAccountForm.value?.label).replace('%action%', props.actionLabel) : '')
const getSubtitle = computed(() => addBankAccountForm.value?.subtitle)
const showErrorMessage = computed(() => props.errorMessage !== '')

const formValues = computed(() => {
    const address = getAddress() || {}
    const fields = {}

    selectedTemplate.value?.forEach(field => {
        switch (field.id) {
            case 'firstName':
                fields[field.id] = address.receiverFirstname ?? userInfos.value.firstName
                break
            case 'lastName':
                fields[field.id] = address.receiverLastname ?? userInfos.value.lastName
                break
            case 'streetNumber':
            case 'streetNumberSuffix':
            case 'streetName':
            case 'streetLine2':
            case 'zipCode':
            case 'city':
            case 'country':
                fields[field.id] = address[field.id] || ''
                break
            case 'bankName':
            case 'iban':
            case 'bic':
            case 'phone':
                fields[field.id] = isEmpty(fields[field.id]) && !isEmpty(props.bankAccount) && !isEmpty(props.bankAccount[field.id]) ? props.bankAccount[field.id] : fields[field.id]
                break
            default:
                fields[field.id] = field.value ?? ''
        }
    })

    return reactive(fields)
})

const rules = computed(() => {
    const rules = {}
    selectedTemplate.value?.forEach(field => {
        rules[field.id] = {}
        if (field.required) {
            rules[field.id].required = required
        }
        if (field.params?.regex) {
            const errorMsg = i18n.global.t(field.params?.regexValidationMessage) ?? i18n.global.t('validations:regex')
            rules[field.id].regex = helpers.withMessage(errorMsg, regex(new RegExp(field.params.regex)))
        }
    })
    return rules
})

onMounted(async () => {
    await store.dispatch(addressActionTypes.GET_ADDRESS_TEMPLATES)
    await store.dispatch(addressActionTypes.GET_ADDRESSES)
    await bankAccountForm()
    v$.value.$reset()
})

const v$ = useVuelidate(rules, formValues)

function getAddress () {
    if (!isEmpty(addresses)) {
        const defaultAddress = addresses.value.find(address => address.defaultAddress === true)
        return defaultAddress || addresses.value[0]
    }
    return null
}

async function bankAccountForm () {
    const templateUrl = `${process.env.VUE_APP_CDN_URL}${process.env.VUE_APP_NETWORK_ID}/template/${props.type}_template_${props.provider.id}_bankAccount.json`
    try {
        const response = await fetch(templateUrl)
        if (!response.ok) {
            ErrorManager.displayErrorModal('HTTP error', response.status)
            return
        }
        addBankAccountForm.value = await response.json()
    } catch (error) {
        ErrorManager.displayErrorModal('Error fetching and parsing JSON', error)
    }
    showLoader.value = false
    selectedTemplate.value = addBankAccountForm.value.fields
}

async function submitForm () {
    const isValid = await v$.value.$validate()
    if (isValid) {
        emit('submitBankAccountForm', formValues.value)
    }
}

function closeModal () {
    v$.value.$reset()
    emit('closeBankAccountModal', this)
}

</script>
