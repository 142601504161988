<template>
    <div class="SaleOptionProviderFareMediaChoice">
        <sale-option-anonymous-fare-media-choice
            :provider="provider"
            class="SaleOptionProviderFareMediaChoice-anonymousFareMediaForm"
            @anonymous-fare-media-updated="anonymousFareMediaUpdated"
        />
    </div>
</template>

<script>
import SaleOptionAnonymousFareMediaChoice from '@/StoreWeb/components/product/SaleOptionAnonymousFareMediaChoice'
import * as cartMutationTypes from '@/StoreWeb/store/modules/cart/mutation-types'
import { normalizeProviderFareMedia } from '@/StoreWeb/js/mixins/support-utils'
import i18n from 'i18n'

export default {
    components: {
        SaleOptionAnonymousFareMediaChoice
    },
    props: {
        provider: {
            type: String,
            default: ''
        }
    },
    mounted () {
        this.$store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, true)
    },
    methods: {
        anonymousFareMediaUpdated (data) {
            this.$store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, false)
            this.$emit('updateFareMedia', {
                basketInfo: 'PROVIDERFAREMEDIA',
                goToNext: data.goToNext ?? true,
                value: normalizeProviderFareMedia(data.value, this.provider)
            })
        },
        getCardTitle (cardNumber) {
            return i18n.global.t('sale_option:basket_info:provider_account_and_faremedia:anonymous_faremedia_title').replace('%cardNumber%', cardNumber)
        }
    }
}
</script>
<style lang="scss" scoped>
@import 'globalScss';

.SaleOptionProviderFareMediaChoice {
    width: 100%;
}
</style>
