<template>
    <main class="PaymentSuccess">
        <div class="Container PaymentSuccess-container">
            <finalized-order
                class="fadeInAfterIcon"
                :title="$t('payment:success:title')"
            />
        </div>
    </main>
</template>

<script setup>
import { onMounted } from 'vue'
import FinalizedOrder from '@/StoreWeb/components/checkout/FinalizedOrder'
import { loadCart } from '@/StoreWeb/js/composables/cart-utils'

onMounted(async () => {
    localStorage.removeItem('paymentType')

    await loadCart()
})

</script>

<style lang='scss' scoped>
@import 'globalScss';

.PaymentSuccess {
    background: $color-white;
    text-align: center;

    &-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 40px 0;
    }
}
</style>
