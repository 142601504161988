import Plan from '@/StoreWeb/models/user/wallet/Plan'
import { isEmpty } from 'global-utils'

export default class {
    normalize (item) {
        const plan = new Plan(item.planId)

        plan.endDate = item.planEndDate
        plan.name = item.planName
        plan.recondition = item.recondition
        plan.startDate = item.planStartDate
        plan.status = item.status
        plan.version = item.planVersion

        return plan
    }

    normalizeArray (items, params) {
        const normalizedItems = []

        if (typeof params === 'undefined') {
            params = {}
        }

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item, params))
            })
        }

        return normalizedItems
    }
}
