<template>
    <v-dialog
        v-model="showMobileMenu"
        width="auto"
        fullscreen
        attach="#HeaderBarSytral"
        transition="slide-x-reverse-transition"
        open-delay="0"
        :opacity="0"
        class="HeaderBarSytralMobileSubmenu"
    >
        <template #activator="{ props: activatorProps }">
            <a
                role="button"
                v-bind="activatorProps"
                class="HeaderBarSytralMobileSubmenu-link"
            >
                {{ $t(item.title) }}
                <v-icon
                    size="large"
                    icon="mdi-arrow-right-thin"
                    color="#d1d7d7"
                />
            </a>
        </template>
        <div
            class="HeaderBarSytralMobileSubmenu-mobileDrawerSubMenuContent"
        >
            <div
                class="HeaderBarSytralMobileSubmenu-mobileDrawerSubMenuHeader"
            >
                <button @click="showMobileMenu = false">
                    <v-icon
                        size="large"
                        icon="mdi-chevron-left"
                    />
                </button>
                <h2
                    style="font-size: 20px; font-weight: 700;color: #58585a;"
                >
                    {{ $t(item.title) }}
                </h2>
                <button
                    style="position: absolute; right: 20px;"
                    @click="showMobileMenu = false; emits('close')"
                >
                    <v-icon
                        size="large"
                        icon="mdi-close"
                    />
                </button>
            </div>

            <nav role="navigation">
                <ul>
                    <li
                        v-for="(subItem) in item.subMenu"
                        :key="subItem.id"
                    >
                        <a
                            :href="subItem.link ? sanitizeUrl(subItem.link) : ''"
                            :to="subItem.link"
                            class="HeaderBarSytralMobileSubmenu-link"
                        >
                            {{ $t(subItem.title) }}
                            <v-icon
                                size="large"
                                icon="mdi-arrow-right-thin"
                                color="#d1d7d7"
                            />
                        </a>
                    </li>
                </ul>
            </nav>

            <div
                v-if="item.illustratedColumn"
                class="mapCard"
                :style="{backgroundImage: `url(${mapCardImg})`}"
            >
                <div
                    class="mapCard_gradient"
                    style="z-index: 1"
                />
                <v-row
                    class="d-inline mb-2 text-center"
                    style="z-index: 2"
                >
                    <v-col
                        cols="12"
                        class="ma-auto"
                        style="z-index: 2"
                    >
                        <a
                            v-if="item.illustratedColumn.firstButton"
                            class="HeaderBarSytralMobileSubmenu-firstButton"
                            :href="item.illustratedColumn.firstButton.link ? sanitizeUrl(item.illustratedColumn.firstButton.link) : ''"
                            :to="item.illustratedColumn.firstButton.route ?? null"
                        >
                            {{ $t(item.illustratedColumn.firstButton.title) }}
                        </a>
                    </v-col>
                    <v-col
                        cols="12"
                        class="ma-auto justify-center"
                        style="z-index: 2"
                    >
                        <ul class="HeaderBarSytralMobileSubmenu-list text--white pa-0">
                            <template v-for="(itemIllustratedColumn, indexItemIllustratedColumn) in item.illustratedColumn">
                                <li
                                    v-if="indexItemIllustratedColumn !== 'firstButton'"
                                    :key="indexItemIllustratedColumn"
                                    class="d-inline pa-1"
                                >
                                    <a
                                        class="HeaderBarSytralMobileSubmenu-itemIllustratedColumn"
                                        :href="itemIllustratedColumn.link ? sanitizeUrl(itemIllustratedColumn.link) : ''"
                                        :to="itemIllustratedColumn.route ?? null"
                                    >
                                        {{ $t(itemIllustratedColumn.title) }}
                                    </a>
                                </li>
                            </template>
                        </ul>
                    </v-col>
                </v-row>
            </div>

            <div
                v-if="item.link"
                class="HeaderBarSytralMobileSubmenu-moreButton"
            >
                <a
                    :href="item.link ? sanitizeUrl(item.link) : ''"
                    :to="item.link"
                    class="HeaderBarSytralMobileSubmenu-moreButtonLink"
                    v-text="$t('mobile_menu:button:more')"
                />
            </div>
        </div>
    </v-dialog>
</template>

<script setup>
import { sanitizeUrl } from '@braintree/sanitize-url'
import { ref, toRefs } from 'vue'

const props = defineProps({
    item: {
        type: Object,
        required: true
    }
})

const { item } = toRefs(props)

const showMobileMenu = ref(false)

const emits = defineEmits(['close'])

const mapCardImg = process.env.VUE_APP_CDN_URL + 'img/' + process.env.VUE_APP_NETWORK_ID + '/map-card.png'
</script>

<style scoped lang="scss">
@import 'globalScss';

.HeaderBarSytralMobileSubmenu {
    &-mobileDrawerSubMenuHeader {
        display: flex;
        align-items: center;
        position: relative;
        padding: 20px 80px 20px 22px;
        border-bottom: 1px solid #e3e4e4;
    }

    &-mobileDrawerSubMenuContent {
        display: flex;
        flex-direction: column;
        overflow: auto;
        width: 100vw;
        height: 100vh;
        background: white;
    }

    &-link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 20px 32px;
        border-bottom: 1px solid #e3e4e4;
        font-size: 18px;
        font-weight: 500;
        color: #58585a;
    }

    &-moreButton {
        padding: 20px;
    }

    &-moreButtonLink {
        display: block;
        padding: 11px 34px;
        border: 2px solid #e2001a;
        border-radius: 5px;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.35em;
        text-align: center;
    }

    .mapCard {
        display: flex;
        align-items: flex-end;
        flex: 1 0 auto;
        position: relative;
        overflow: hidden;
        width: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        &_gradient {
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background:
                linear-gradient(
                    to top,
                    rgba(0, 0, 0, .9) 0,
                    rgba(0, 0, 0, .738) 19%,
                    rgba(0, 0, 0, .541) 34%,
                    rgba(0, 0, 0, .382) 47%,
                    rgba(0, 0, 0, .278) 56.5%,
                    rgba(0, 0, 0, .194) 65%,
                    rgba(0, 0, 0, .126) 73%,
                    rgba(0, 0, 0, .075) 80.2%,
                    rgba(0, 0, 0, .042) 86.1%,
                    rgba(0, 0, 0, .021) 90%,
                    rgba(0, 0, 0, .008) 90%,
                    rgba(0, 0, 0, .002) 90%,
                    rgba(0, 0, 0, 0) 90%
                ) no-repeat bottom center;
            background-size: 100% 90%;
            opacity: .8;
        }
    }

    &-firstButton {
        padding: 7px 25px;
        border-radius: 35px;
        background-color: white;
        font-size: 1rem;
        font-weight: 500;
        text-align: center;
        color: $primary_color !important;
    }

    &-firstButton:hover {
        border: 1px solid $primary_color;
    }

    &-itemIllustratedColumn {
        font-size: 15px !important;
        font-weight: normal !important;
        color: white;
    }

    &-itemIllustratedColumn:hover {
        border-bottom: 1px solid $primary_color;
    }

    &-firstButton::before {
        background-color: white;
    }

    &-illustratedColumnItem {
        left: 50% !important;
        transform: translateX(-50%);
        width: 1250px;
    }
}
</style>
