<template>
    <nav
        :style="style"
        class="HeaderAccountNavContent"
    >
        <account-nav
            :accountNav="navigationMixins.getAccountNav.value"
            class="HeaderAccountNavContent-list"
        />
    </nav>
</template>

<script setup>
import { onMounted, onUnmounted } from 'vue'
import AccountNav from '@/StoreWeb/components/account/AccountNav'
import { useNavigationMixins } from '@/StoreWeb/js/navigation-mixins'

const navigationMixins = useNavigationMixins()

defineProps({
    style: {
        type: String,
        default: ''
    }
})

onMounted(() => {
    navigationMixins.activeEmitter()
})

onUnmounted(() => {
    navigationMixins.desactiveEmitter()
})
</script>

<style lang="scss" scoped>
@import 'globalScss';

.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.HeaderAccountNavContent {
    width: 325px;
    max-width: 80vw;
    border-radius: 5px;
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, .2);
    background: $color-lightest;

    &::before {
        content: '';
        position: absolute;
        top: -9px;
        right: 10px;
        width: 0;
        height: 0;
        border-width: 0 9px 9px;
        border-style: solid;
        border-color: transparent transparent $color-lightgray2 transparent;

        @media #{map-get($display-breakpoints, 'sm-only')} {
            right: auto;
            left: 18px;
        }
    }
}
</style>
