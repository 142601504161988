import Action from '@/StoreWeb/models/common/Action'
import { isEmpty } from 'global-utils'

export default class {
    normalize (item) {
        const action = new Action()

        if (!isEmpty(item.label)) {
            action.label = item.label
        }
        action.type = item.type

        return action
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
