import i18n from 'i18n'
import { isEmpty } from 'global-utils'

export default class {
    constructor () {
        this._city = ''
        this._country = ''
        this._creationDate = ''
        this._defaultAddress = false
        this._id = 0
        this._networkId = 0
        this._postalBox = ''
        this._depositName = ''
        this._receiverFirstname = ''
        this._receiverLastname = ''
        this._state = ''
        this._streetLine2 = ''
        this._streetLine3 = ''
        this._streetName = ''
        this._streetNumber = ''
        this._streetNumberSuffix = ''
        this._streetType = ''
        this._template = null
        this._type = ''
        this._updateDate = ''
        this._userId = ''
        this._zipCode = ''
    }

    get city () {
        return this._city
    }

    set city (city) {
        this._city = city
    }

    get country () {
        return this._country
    }

    set country (country) {
        this._country = country
    }

    get creationDate () {
        return this._creationDate
    }

    set creationDate (creationDate) {
        this._creationDate = creationDate
    }

    get defaultAddress () {
        return this._defaultAddress
    }

    set defaultAddress (defaultAddress) {
        this._defaultAddress = defaultAddress
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get networkId () {
        return this._networkId
    }

    set networkId (networkId) {
        this._networkId = networkId
    }

    get postalBox () {
        return this._postalBox
    }

    set postalBox (postalBox) {
        this._postalBox = postalBox
    }

    get depositName () {
        return this._depositName
    }

    set depositName (depositName) {
        this._depositName = depositName
    }

    get receiverFirstname () {
        return this._receiverFirstname
    }

    set receiverFirstname (receiverFirstname) {
        this._receiverFirstname = receiverFirstname
    }

    get receiverLastname () {
        return this._receiverLastname
    }

    set receiverLastname (receiverLastname) {
        this._receiverLastname = receiverLastname
    }

    get state () {
        return this._state
    }

    set state (state) {
        this._state = state
    }

    get streetLine2 () {
        return this._streetLine2
    }

    set streetLine2 (streetLine2) {
        this._streetLine2 = streetLine2
    }

    get streetLine3 () {
        return this._streetLine3
    }

    set streetLine3 (streetLine3) {
        this._streetLine3 = streetLine3
    }

    get streetName () {
        return this._streetName
    }

    set streetName (streetName) {
        this._streetName = streetName
    }

    get streetNumber () {
        return this._streetNumber
    }

    set streetNumber (streetNumber) {
        this._streetNumber = streetNumber
    }

    get streetNumberSuffix () {
        return this._streetNumberSuffix
    }

    set streetNumberSuffix (streetNumberSuffix) {
        this._streetNumberSuffix = streetNumberSuffix
    }

    get streetType () {
        return this._streetType
    }

    set streetType (streetType) {
        this._streetType = streetType
    }

    get template () {
        return this._template
    }

    set template (template) {
        this._template = template
    }

    get type () {
        return this._type
    }

    set type (type) {
        this._type = type
    }

    get updateDate () {
        return this._updateDate
    }

    set updateDate (updateDate) {
        this._updateDate = updateDate
    }

    get userId () {
        return this._userId
    }

    set userId (userId) {
        this._userId = userId
    }

    get zipCode () {
        return this._zipCode
    }

    set zipCode (zipCode) {
        this._zipCode = zipCode
    }

    getTypeLabel () {
        return !isEmpty(this.type) ? i18n.global.t(`address:type:${this.type}`) : ''
    }

    getFullName () {
        let fullName = this.receiverFirstname ?? ''

        fullName += (fullName === '' ? '' : ' ') + (this.receiverLastname ?? '')

        return fullName
    }

    getFullStreetName () {
        let fullStreetName = !isEmpty(this.streetNumber) ? this.streetNumber : ''
        fullStreetName = fullStreetName + ((!isEmpty(this.streetNumberSuffix) && this.streetNumberSuffix.toLowerCase() !== 'none') ? ' ' + this.streetNumberSuffix : '')

        if (!isEmpty(this.streetType)) {
            fullStreetName += (fullStreetName === '' ? '' : ' ') + this.streetType
        }
        fullStreetName += (fullStreetName === '' ? '' : ' ') + this.streetName

        return fullStreetName
    }

    getCountry () {
        if (this.template) {
            return this.getListItemLabel(this.template, 'country')
        }
        return this.country
    }

    getListItemLabel (template, fieldId) {
        const correspondingField = template.fields.find(field => field.id === fieldId)

        if (!correspondingField || correspondingField.type !== 'choice') {
            return ''
        }

        const list = correspondingField.params?.list || []
        const correspondingItem = list.find(item => item.id === this[fieldId])

        return correspondingItem || this[fieldId]
    }

    getTemplateWithValues (template) {
        if (typeof template === 'undefined') {
            template = this.template
        }
        if (!isEmpty(template.fields)) {
            template.fields.forEach(field => {
                if (typeof this[field.id] !== 'undefined') {
                    field.value = this[field.id]
                }
            })
        }

        return template
    }

    getValues () {
        const values = {}
        values.receiverFirstname = this?.receiverFirstname ?? null
        values.receiverLastname = this?.receiverLastname ?? null
        this.template.fields.forEach(field => {
            if (typeof this[field.id] !== 'undefined') {
                values[field.id] = this[field.id]
            }
        })

        return values
    }

    getZipCodeAndCity () {
        let zipCodeAndCity = ''

        zipCodeAndCity += this.zipCode
        zipCodeAndCity += (zipCodeAndCity === '' ? '' : ' ') + this.city

        return zipCodeAndCity
    }

    toJSON () {
        return {
            city: this.city,
            country: this.country,
            creationDate: this.creationDate,
            defaultAddress: this.defaultAddress,
            id: this.id,
            networkId: this.networkId,
            postalBox: this.postalBox,
            depositName: this.depositName,
            receiverFirstname: this.receiverFirstname,
            receiverLastname: this.receiverLastname,
            state: this.state,
            streetLine2: this.streetLine2,
            streetLine3: this.streetLine3,
            streetName: this.streetName,
            streetNumber: this.streetNumber,
            streetNumberSuffix: this.streetNumberSuffix,
            streetType: this.streetType,
            template: this.template,
            type: this.type,
            updateDate: this.updateDate,
            userId: this.userId,
            zipCode: this.zipCode
        }
    }
}
