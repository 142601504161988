import { isEmpty } from 'global-utils'
import i18n from 'i18n'

export default class {
    constructor () {
        this._basketInfo = null
        this._basketInfoSelectMode = null
        this._deliveryMode = null
        this._index = 1
        this._provider = null
        this._target = null
        this._userAuthMode = null
    }

    get basketInfo () {
        return this._basketInfo
    }

    set basketInfo (basketInfo) {
        this._basketInfo = basketInfo
    }

    get basketInfoSelectMode () {
        return this._basketInfoSelectMode
    }

    set basketInfoSelectMode (basketInfoSelectMode) {
        this._basketInfoSelectMode = basketInfoSelectMode
    }

    get deliveryMode () {
        return this._deliveryMode
    }

    set deliveryMode (deliveryMode) {
        this._deliveryMode = deliveryMode
    }

    get index () {
        return this._index
    }

    set index (index) {
        this._index = index
    }

    get provider () {
        return this._provider
    }

    set provider (provider) {
        this._provider = provider
    }

    get target () {
        return this._target
    }

    set target (target) {
        this._target = target
    }

    get userAuthMode () {
        return this._userAuthMode
    }

    set userAuthMode (userAuthMode) {
        this._userAuthMode = userAuthMode
    }

    getBasketInfoValue () {
        if (!isEmpty(this.basketInfo)) {
            return this.basketInfo.id
        }
        return null
    }

    getBasketInfoLabel () {
        if (this.basketInfo && this.basketInfo.id) {
            return i18n.global.t(`sale_option:basket_info:${this.basketInfo.id.toLowerCase()}:label`)
        }
        return ''
    }

    getDeliveryModeValue () {
        if (!isEmpty(this.deliveryMode)) {
            return this.deliveryMode.id
        }
        return null
    }

    getProviderValue () {
        if (!isEmpty(this.provider)) {
            return this.provider.id
        }
        return null
    }

    getTargetValue () {
        if (!isEmpty(this.target)) {
            return this.target.id
        }
        return null
    }

    getUserAuthModeValue () {
        if (!isEmpty(this.userAuthMode)) {
            return this.userAuthMode.id
        }
        return null
    }

    isValid () {
        switch (true) {
            // Cas Produit à charger sur une carte transport >>> Télédistribution carte classique
            case (
                this.getUserAuthModeValue() === 'USERACCOUNT' &&
                this.getTargetValue() === 'CARD' &&
                this.getDeliveryModeValue() === 'DISTRIBUTION' &&
                this.getBasketInfoValue() === 'PROVIDERACCOUNTANDFAREMEDIA' &&
                !isEmpty(this.getProviderValue())
            ):
                return true
            // Cas Produit à charger sur une carte transport >>> Télédistribution carte classique en mode invité
            case (
                this.getUserAuthModeValue() === 'EMAILMANDATORY' &&
                this.getTargetValue() === 'CARD' &&
                this.getDeliveryModeValue() === 'DISTRIBUTION' &&
                // && this.getBasketInfoValue() === 'PROVIDERFAREMEDIA'
                (this.getBasketInfoValue() === 'PROVIDERFAREMEDIA' || this.getBasketInfoValue() === 'PROVIDERACCOUNTANDFAREMEDIA') &&
                !isEmpty(this.getProviderValue())
            ):
                return true
            // Cas E-Ticket et M-Ticket >>> Achat M-ticket
            case (
                this.getUserAuthModeValue() === 'USERACCOUNT' &&
                this.getTargetValue() === 'ACCOUNT' &&
                this.getDeliveryModeValue() === 'ACCOUNT' &&
                this.getBasketInfoValue() === 'PROVIDERACCOUNT'
                // && !isEmpty(this.getProviderValue())
            ):
                return true
            // Cas Commande de carte >>> Nouveau Support
            case (
                this.getUserAuthModeValue() === 'USERACCOUNT' &&
                this.getTargetValue() === 'CARD' &&
                this.getDeliveryModeValue() === 'POSTAL' &&
                this.getBasketInfoValue() === 'POSTAL_ADDRESS'
                // && !isEmpty(this.getProviderValue())
            ):
                return true
            // Cas Paiement d’amende >>> Paiement d’une amende
            case (
                this.getUserAuthModeValue() === 'USERACCOUNT' &&
                this.getTargetValue() === 'FINE' &&
                this.getDeliveryModeValue() === 'EMAIL' &&
                this.getBasketInfoValue() === 'EMAIL'
                // && !isEmpty(this.getProviderValue())
            ):
                return true
            // Cas Compteurs modaux >>> 1 H de trottinette
            case (
                this.getUserAuthModeValue() === 'USERACCOUNT' &&
                this.getTargetValue() === 'ACCOUNT' &&
                this.getDeliveryModeValue() === 'ACCOUNT' &&
                this.getBasketInfoValue() === 'ACCOUNT'
                // && !isEmpty(this.getProviderValue())
            ):
                return true
            // Cas Commande d'objets physiques >>> Produit Annexe (ex: Lecteur de carte)
            case (
                this.getUserAuthModeValue() === 'USERACCOUNT' &&
                this.getTargetValue() === 'POSTAL' &&
                this.getDeliveryModeValue() === 'POSTAL' &&
                this.getBasketInfoValue() === 'POSTAL_ADDRESS'
                // && !isEmpty(this.getProviderValue())
            ):
                return true
        }
        return false
    }

    toJSON () {
        return {
            basketInfo: this.basketInfo,
            basketInfoSelectMode: this.basketInfoSelectMode,
            deliveryMode: this.deliveryMode,
            index: this.index,
            provider: this.provider,
            target: this.target,
            userAuthMode: this.userAuthMode
        }
    }
}
