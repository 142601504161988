<template>
    <div
        v-if="isCardReaderAvailable"
        class="SupportReaderModal"
    >
        <modal
            v-model="showModal"
            :title="$t(headerTitle)"
        >
            <support-reader
                :headerTitle="$t('card_reader:scan_modal:title')"
                :operationType="operationType"
                @operation-finished="onOperationFinished"
                @plugged-support-id-updated="pluggedSupportIdUpdated"
            />
        </modal>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import SupportReader from '@/StoreWeb/components/miscellaneous/conduent-support-reader/SupportReader'

const store = useStore()

const props = defineProps({
    headerTitle: {
        type: String,
        default: 'card_reader:read:title'
    },
    modelValue: {
        type: Boolean,
        required: true
    },
    operationType: {
        type: String,
        default: 'READ',
        validator: (val) => ['READ', 'WRITE'].includes(val)
    },
    shouldAutoCloseModalAfterOperation: {
        type: Boolean,
        default: true
    }
})

const emit = defineEmits(['update:modelValue', 'operationFinished'])

const support = ref(null)

const showModal = computed({
    get: () => props.modelValue,
    set: (newValue) => {
        emit('update:modelValue', newValue)
    }
})

const isCardReaderAvailable = computed(() => !store.state.isMobileApp && !store.state.isMobileOrTablet)

function onOperationFinished (data) {
    emit('operationFinished', {
        operationReport: data,
        support: support.value
    })
    showModal.value = false
}

function pluggedSupportIdUpdated (newSupport) {
    support.value = newSupport
}
</script>
