export default class {
    constructor (
        id, value
    ) {
        this._id = id
        this._value = value
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get value () {
        return this._value
    }

    set value (value) {
        this._value = value
    }

    toString () {
        return this.value
    }

    toJSON () {
        return {
            id: this.id,
            value: this.value
        }
    }
}
