import * as mainMutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import * as actionTypes from '@/StoreWeb/store/modules/payment/action-types'
import * as mutationTypes from '@/StoreWeb/store/modules/payment/mutation-types'
import * as userMutationTypes from '@/StoreWeb/store/modules/user/mutation-types'
import cachios from 'cachios'
import emitter from 'global-emitter'
import { isEmpty } from 'global-utils'
import DebuggingManager from '@/StoreWeb/managers/DebuggingManagerSingleton'
import UserManager from '@/StoreWeb/managers/UserManagerSingleton'
import BankAccountNormalizer from '@/StoreWeb/normalizers/payment/BankAccountNormalizerSingleton'
import i18n from 'i18n'
import ErrorManager from '@/StoreWeb/managers/ErrorManager'
import {
    loadCart
} from '@/StoreWeb/js/composables/cart-utils'

export default class {
    constructor () {
        this._bankAccountNormalizer = BankAccountNormalizer.getInstance()
        this._debuggingManager = DebuggingManager.getInstance()
        this._userManager = UserManager.getInstance()

        this._store = null
    }

    get bankAccountNormalizer () {
        return this._bankAccountNormalizer
    }

    get debuggingManager () {
        return this._debuggingManager
    }

    get store () {
        return this._store
    }

    set store (store) {
        this._store = store
    }

    get userManager () {
        return this._userManager
    }

    addQueryParams (url, params) {
        const queryParams = new URLSearchParams()

        for (const i in params) {
            queryParams.append(i, params[i])
        }

        if (queryParams.size > 0) {
            url += `?${queryParams}`
        }

        return url
    }

    /* =============================================================================================================
    =================================================== ACTIONS ====================================================
    ============================================================================================================= */
    setPaymentInitState (state) {
        this.store.commit(mutationTypes.SET_PAYMENT_PENDING_REQUEST, { key: 'paymentInit', value: state })
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: state })
    }

    async handlePaymentResponse (response) {
        this.store.commit(mutationTypes.SET_PAYMENT, response.data)
        const payment = this.store.state.paymentModule.payment
        if (payment.codeType === 'TOKEN') {
            emitter.emit('paymentInitTokenLoaded')
            await loadCart()
        } else if (payment.codeType === 'URL') {
            emitter.emit('paymentInitUrlLoaded')
        } else if (
            payment.paymentVersion === null &&
            payment.codeType === null &&
            payment.code === null &&
            payment.paymentRedirectUrls === null
        ) {
            if (payment.initStatus === 'SUCCESS') {
                emitter.emit('paymentInitSuccess')
            } else {
                emitter.emit('paymentInitFailure')
            }
        }
        this.setPaymentInitState(false)
    }

    paymentInitAction (params) {
        this.setPaymentInitState(true)

        const body = params ?? {}
        if (this.store.state.cartModule.cart !== undefined && this.store.state.cartModule.cart.id) {
            body.orderId = this.store.state.cartModule.cart.id
        }

        if (this.debuggingManager.getMockActionState('postPaymentInit')) {
            this.debuggingManager.getResponse('postPaymentInit', this.handlePaymentResponse.bind(this))
        } else {
            return cachios.post(`${process.env.VUE_APP_API_URL}/payment/init`, body, { force: true })
                .then(this.handlePaymentResponse.bind(this))
                .catch(error => {
                    ErrorManager.displayErrorModal('payment:payment_init', error)
                    this.store.commit(mutationTypes.SET_PAYMENT_PENDING_REQUEST, { key: 'paymentInit', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                    this.setPaymentInitState(false)
                })
        }
    }

    getBankAccountsAction (params) {
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
        this.store.commit(mutationTypes.SET_PAYMENT_PENDING_REQUEST, { key: 'getBankAccounts', value: true })

        if (this.debuggingManager.getMockActionState('getBankAccounts')) {
            this.debuggingManager.getResponse('getBankAccounts', (response) => {
                this.userManager.updateBankAccounts(params.providerId, params.providerUserId, this.bankAccountNormalizer.normalizeArray(response.data))
                this.store.commit(userMutationTypes.SET_BANK_ACCOUNTS, this.userManager.bankAccounts)
                this.store.commit(mutationTypes.SET_PAYMENT_PENDING_REQUEST, { key: 'getBankAccounts', value: false })
                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                emitter.emit('getBankAccountsSuccess')
            })
        } else {
            let url = process.env.VUE_APP_API_URL + '/payment/bankAccounts'

            url = this.addQueryParams(url, {
                providerId: params.providerId,
                providerUserExternalId: params.providerUserExternalId,
                providerUserId: params.providerUserId,
                eligibleForPayment: params.eligibleForPayment ?? false
            })

            cachios
                .get(url, !isEmpty(params) && typeof params.cachiosOptions !== 'undefined' ? params.cachiosOptions : {})
                .then(response => {
                    this.userManager.updateBankAccounts(params.providerId, params.providerUserId, this.bankAccountNormalizer.normalizeArray(response.data))
                    this.store.commit(userMutationTypes.SET_BANK_ACCOUNTS, this.userManager.bankAccounts)
                    this.store.commit(mutationTypes.SET_PAYMENT_PENDING_REQUEST, { key: 'getBankAccounts', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                    emitter.emit('getBankAccountsSuccess')
                    if (!isEmpty(params) && typeof params.callback === 'function') {
                        params.callback()
                    }
                })
                .catch(error => {
                    // emitter.emit('getBankAccountsError')
                    ErrorManager.displayErrorModal('payment:get_bank_accounts', error)
                    this.store.commit(mutationTypes.SET_PAYMENT_PENDING_REQUEST, { key: 'getBankAccounts', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                })
        }
    }

    createBankAccountAction (params) {
        const setLoaderAndPendingState = (isLoading, isPending) => {
            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: isLoading })
            this.store.commit(mutationTypes.SET_PAYMENT_PENDING_REQUEST, { key: 'createBankAccount', value: isPending })
        }

        return new Promise((resolve, reject) => {
            setLoaderAndPendingState(true, true)

            let url = `${process.env.VUE_APP_API_URL}/payment/add/bankAccounts`
            const body = {
                ...params.data,
                providerBankAccountIdentifier: params.data?.providerBankAccountIdentifier
            }

            url = this.addQueryParams(url, {
                providerId: params.providerId
            })

            if (params.providerUserExternalId) {
                body.providerUserExternalId = params.providerUserExternalId
            }

            if (params.providerUserId) {
                body.providerUserId = params.providerUserId
            }

            cachios.post(url, body)
                .then(response => {
                    setLoaderAndPendingState(false, false)

                    if (typeof params.callback === 'function') {
                        params.callback(response.data)
                        resolve(response.data)
                    } else if (params.getBankAccount) {
                        this.store.commit(mainMutationTypes.SET_FEEDBACK_SUCCESS, i18n.global.t('bank_account:create:success'))
                        this.store.dispatch(actionTypes.GET_BANK_ACCOUNTS, {
                            cachiosOptions: { force: true },
                            providerId: params.providerId,
                            providerUserExternalId: params.providerUserExternalId,
                            providerUserId: params.providerUserId
                        })
                        resolve(response.data)
                    } else {
                        resolve(response.data)
                    }
                })
                .catch(error => {
                    setLoaderAndPendingState(false, false)

                    if (error.response?.data?.status === 409 && error.response?.data?.debugMessage === 'ERR_FUNDINGMETHOD_DUPLICATE') {
                        let message = ''
                        if (error.response.data.message) {
                            message += error.response.data.message + ' '
                        }
                        if (error.response.data.externalMessage) {
                            message += error.response.data.externalMessage
                        }
                        reject(message)
                    } else {
                        ErrorManager.displayErrorModal('payment:create_bank_account', error.errorMessage ?? error, error)
                    }
                })
        })
    }

    updateBankAccountAction (params) {
        const setLoaderAndPendingState = (isLoading, isPending) => {
            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: isLoading })
            this.store.commit(mutationTypes.SET_PAYMENT_PENDING_REQUEST, { key: 'updateBankAccount', value: isPending })
        }

        return new Promise((resolve, reject) => {
            setLoaderAndPendingState(true, true)

            let url = `${process.env.VUE_APP_API_URL}/payment/update/bankAccounts`
            const body = {
                ...params.data,
                providerBankAccountIdentifier: params.providerBankAccountIdentifier
            }

            url = this.addQueryParams(url, {
                providerId: params.providerId
            })

            if (params.providerUserExternalId) {
                body.providerUserExternalId = params.providerUserExternalId
            }

            cachios.put(url, body)
                .then(response => {
                    setLoaderAndPendingState(false, false)

                    if (typeof params.callback === 'function') {
                        params.callback(response.data)
                    } else if (params.getBankAccount) {
                        this.store.commit(mainMutationTypes.SET_FEEDBACK_SUCCESS, i18n.global.t('bank_account:update:success'))
                        this.store.dispatch(actionTypes.GET_BANK_ACCOUNTS, {
                            cachiosOptions: { force: true },
                            providerId: params.providerId,
                            providerUserId: params.providerUserId,
                            providerUserExternalId: params.providerUserExternalId
                        })
                    }
                    resolve(response.data)
                })
                .catch(error => {
                    setLoaderAndPendingState(false, false)

                    if (error.response?.data?.status === 409 && error.response?.data?.debugMessage === 'ERR_FUNDINGMETHOD_DUPLICATE') {
                        let message = ''
                        if (error.response.data.message) {
                            message += error.response.data.message + ' '
                        }
                        if (error.response.data.externalMessage) {
                            message += error.response.data.externalMessage
                        }
                        reject(message)
                    } else {
                        ErrorManager.displayErrorModal('payment:update_bank_account', error.errorMessage ?? error, error)
                    }
                })
        })
    }

    getMandateBinaryAction (params) {
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
        this.store.commit(mutationTypes.SET_PAYMENT_PENDING_REQUEST, { key: 'getMandateBinary', value: true })
        const url = `${process.env.VUE_APP_API_URL}/payment/mandate/${params.rum}?providerId=${params.providerId}`
        cachios
            .get(url, { responseType: 'arraybuffer', force: true })
            .then(response => {
                this.store.commit(mutationTypes.SET_PAYMENT_PENDING_REQUEST, { key: 'getMandateBinary', value: false })
                const blob = new Blob([response.data], { type: 'application/pdf' })
                const url = window.URL.createObjectURL(blob)

                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                window.open(url)
            })
            .catch(error => {
                ErrorManager.displayErrorModal('payment:get_mandate_binary', error)
                this.store.commit(mutationTypes.SET_PAYMENT_PENDING_REQUEST, { key: 'getMandateBinary', value: false })
                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
            })
    }

    signMandateAction (params) {
        const setLoaderAndPendingState = (isLoading, isPending) => {
            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: isLoading })
            this.store.commit(mutationTypes.SET_PAYMENT_PENDING_REQUEST, { key: 'signMandate', value: isPending })
        }

        return new Promise((resolve, reject) => {
            setLoaderAndPendingState(true, true)

            const body = params.data

            cachios.post(`${process.env.VUE_APP_API_URL}/payment/mandate/signature`, body)
                .then(response => {
                    setLoaderAndPendingState(false, false)
                    if (typeof params.callback === 'function') {
                        params.callback(response.data)
                    }
                    resolve(response.data)
                })
                .catch(error => {
                    setLoaderAndPendingState(false, false)
                    ErrorManager.displayErrorModal('payment:sign_mandate', error.errorMessage ?? error, error)
                    reject(error)
                })
        })
    }

    postBankAccounts (params) {
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
        this.store.commit(mutationTypes.SET_PAYMENT_PENDING_REQUEST, { key: 'postBankAccounts', value: true })
        const url = `${process.env.VUE_APP_API_URL}/payment/bankAccounts/${params.rum}?providerId=${params.providerId}&${params.providerUserId}`
        cachios
            .post(url, { responseType: 'arraybuffer', force: true })
            .then(response => {
                this.store.commit(mutationTypes.SET_PAYMENT_PENDING_REQUEST, { key: 'getMandateBinary', value: false })
                const blob = new Blob([response.data], { type: 'application/pdf' })
                const url = window.URL.createObjectURL(blob)

                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                window.open(url)
            })
            .catch(error => {
                ErrorManager.displayErrorModal('payment:get_mandate_binary', error)
                this.store.commit(mutationTypes.SET_PAYMENT_PENDING_REQUEST, { key: 'getMandateBinary', value: false })
                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
            })
    }
}
