<template>
    <div class="Price">
        <p
            v-if="product.fareRule === 'FROM' || displayMainPrice"
            class="mb-0"
        >
            <span
                v-if="product.fareRule === 'FROM'"
                class="Price-from d-block"
            >
                {{ $t('catalog:fare_from') }}
            </span>
            <span
                v-if="displayMainPrice"
                class="d-block mb-1"
            >
                <formatted-fare
                    :currency="product.currency"
                    :fare="getMainPrice"
                    :size="size"
                    tag="span"
                />
                <span v-if="displayRecurrencePerMonth">
                    / {{ $t('month') }}
                </span>
                <span v-if="displayNumberOfMonths">
                    {{ ' ' + $t('during') }}
                    <strong>
                        <span class="Price-number">
                            {{ product.fareRecurringPeriodCount }}
                        </span>
                        {{ $t('month') }}
                    </strong>
                </span>
            </span>
        </p>
        <p
            v-if="displayNumberOfPayments"
            class="mb-0"
        >
            {{ $t('payment_in') }}
            <strong class="Price-number">
                {{ product.fareRecurringPeriodCount }}
            </strong>
            {{ $t('times') }}
        </p>
        <p
            v-if="displayNumberAndAmountOfPayments"
            class="mb-0"
        >
            {{ $t('payment_of') }}
            <formatted-fare
                :currency="product.currency"
                :fare="product.fareTotalRecurringIncludingTaxes"
                size="XXSmall"
                tag="span"
            />
            {{ $t('in') }}
            <strong class="Price-number">
                {{ product.fareRecurringPeriodCount }}
            </strong>
            {{ $t('times') }}
        </p>
        <p v-if="displayEasyPayment">
            {{ $t('catalog:easy_payment') }}
        </p>
        <p
            v-if="displayTotalAmount || displayImmediateFare"
            class="mb-0"
        >
            <span
                v-if="displayTotalAmount"
                class="d-block"
            >
                {{ $t('total_amount') }}
                <formatted-fare
                    :currency="product.currency"
                    :fare="product.fareTotalIncludingTaxes"
                    size="XXSmall"
                    tag="span"
                />
            </span>
            <span
                v-if="displayImmediateFare"
                class="Price-recurringImmediate d-block"
            >
                <span v-if="product.fareRule === 'RECURRING_IMMEDIATE' || product.fareRule === 'EASY_PAYMENT_IMMEDIATE'">
                    {{ $t('and') + ' ' }}
                </span>
                <span v-if="product.fareRule === 'RECURRING_TOTAL_IMMEDIATE'">
                    {{ $t('including') + ' ' }}
                </span>
                <formatted-fare
                    :currency="product.currency"
                    :fare="product.fareIncludingTaxes"
                    size="XXSmall"
                    tag="span"
                />
                <span>&nbsp;{{ $t('catalog:paid:immediately') }}</span>
            </span>
        </p>
        <p
            v-if="product.fareRule === 'FREE'"
            class="mb-0"
        >
            <strong class="Price-free">
                {{ $t('catalog:free') }}
            </strong>
        </p>
        <p
            v-if="product.fareRule === 'CONDITIONS'"
            class="mb-0"
        >
            <strong class="Price-conditions">
                {{ $t('catalog:price:with_conditions') }}
            </strong>
        </p>
        <p
            v-if="displayWeeeTax && product.weeeTaxApplicable"
            class="mb-0 Price-weeeTax"
        >
            {{ $t('catalog:include_weee_tax') }}
        </p>
        <p
            v-if="isNonSaleable"
            class="mb-0"
        >
            <strong class="Price-nonSaleable d-block">
                {{ $t('catalog:non_saleable') }}
            </strong>
        </p>
    </div>
</template>

<script setup>
import FormattedFare from '@/StoreWeb/components/common/FormattedFare'
import { computed } from 'vue'
import { isEmpty } from 'global-utils'

const props = defineProps({
    product: {
        type: Object,
        required: true
    },
    size: {
        type: String,
        default: 'Small'
    },
    displayWeeeTax: {
        type: Boolean,
        default: false
    },
    detailLevel: {
        type: String,
        default: 'FULL',
        validator: (val) => ['REDUCED', 'FULL'].includes(val)
    }
})

const displayMainPrice = computed(() => {
    return !['FREE', 'CONDITIONS'].includes(props.product.fareRule) &&
        props.product.saleableUnit.id !== 'NON_SALEABLE' &&
        getMainPrice.value !== ''
})

const getMainPrice = computed(() => {
    switch (props.product.fareRule) {
        case 'IMMEDIATE':
        case 'FROM':
            return props.product.fareIncludingTaxes ?? ''
        case 'RECURRING':
        case 'RECURRING_IMMEDIATE':
        case 'RECURRING_TOTAL':
        case 'RECURRING_TOTAL_IMMEDIATE':
            return props.product.fareRecurringIncludingTaxes ?? ''
        case 'EASY_PAYMENT_COUNT':
        case 'EASY_PAYMENT':
        case 'EASY_PAYMENT_IMMEDIATE':
            return props.product.fareTotalIncludingTaxes ?? ''
        default:
            return props.product.fareIncludingTaxes ?? ''
    }
})

const displayRecurrencePerMonth = computed(() => {
    return ['RECURRING', 'RECURRING_IMMEDIATE', 'RECURRING_TOTAL', 'RECURRING_TOTAL_IMMEDIATE'].includes(props.product.fareRule) &&
        props.product.fareRecurringPeriod === 'MONTH'
})

const displayNumberOfMonths = computed(() => {
    return props.detailLevel === 'FULL' &&
        ['RECURRING', 'RECURRING_IMMEDIATE', 'RECURRING_TOTAL', 'RECURRING_TOTAL_IMMEDIATE'].includes(props.product.fareRule) &&
        props.product.fareRecurringPeriod === 'MONTH' &&
        !isEmpty(props.product.fareRecurringPeriodCount) && props.product.fareRecurringPeriodCount > 0
})

const displayImmediateFare = computed(() => {
    return props.detailLevel === 'FULL' &&
        ['RECURRING_IMMEDIATE', 'RECURRING_TOTAL_IMMEDIATE', 'EASY_PAYMENT_IMMEDIATE'].includes(props.product.fareRule) &&
        !isEmpty(props.product.fareIncludingTaxes)
})

const displayTotalAmount = computed(() => {
    return props.detailLevel === 'FULL' &&
        ['RECURRING_TOTAL', 'RECURRING_TOTAL_IMMEDIATE'].includes(props.product.fareRule) &&
        !isEmpty(props.product.fareTotalIncludingTaxes)
})

const displayNumberOfPayments = computed(() => {
    return props.detailLevel === 'FULL' &&
        props.product.fareRule === 'EASY_PAYMENT_COUNT' &&
        !isEmpty(props.product.fareRecurringPeriodCount)
})

const displayNumberAndAmountOfPayments = computed(() => {
    return props.detailLevel === 'FULL' &&
        ['EASY_PAYMENT', 'EASY_PAYMENT_IMMEDIATE'].includes(props.product.fareRule) &&
        !isEmpty(props.product.fareRecurringPeriodCount) &&
        !isEmpty(props.product.fareTotalRecurringIncludingTaxes)
})

const displayEasyPayment = computed(() => {
    return props.detailLevel === 'REDUCED' &&
        ['EASY_PAYMENT', 'EASY_PAYMENT_IMMEDIATE', 'EASY_PAYMENT_COUNT'].includes(props.product.fareRule)
})

const isNonSaleable = computed(() => {
    return props.product.saleableUnit.id === 'NON_SALEABLE' && !isEmpty(props.product.fareIncludingTaxes)
})

</script>

<style lang="scss" scoped>
@import 'globalScss';

.Price {
    font-size: 12px;
    text-align: left;
    color: $fare-color;

    &-number {
        font-size: 13px;
    }

    &-nonSaleable {
        font-size: 14px;
        color: $fare-explanationsColor;
    }

    &-conditions,
    &-free {
        font-size: 16px;
    }
}
</style>
