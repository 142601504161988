import CatalogManager from '@/StoreWeb/managers/CatalogManagerSingleton'

const catalogManager = CatalogManager.getInstance()

export default {
    getFareMediaAvailableProducts (state) {
        return catalogManager.getFareMediaAvailableProducts(state)
    },
    getFineProducts (state) {
        return catalogManager.getFineProducts(state)
    },
    getProductsPerSection (state) {
        return catalogManager.getProductsPerSection(state)
    },
    getCatalogFareMedias (state) {
        return catalogManager.getFareMediasAction(state)
    }
}
