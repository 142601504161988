<template>
    <div class="Purchases">
        <div v-if="getOrdersByYear.length > 0">
            <ul class="Purchases-list">
                <li
                    v-for="group in getOrdersByYear"
                    :key="group.id"
                    class="Purchases-yearItem"
                >
                    <div class="Purchases-itemGroupLabel">
                        {{ group.label }}
                    </div>
                    <ul class="Purchases-list">
                        <order-item
                            v-for="order in group.orders"
                            :key="order.id"
                            :order="order"
                            :orderPage="currentPage"
                            class="Purchases-item"
                        />
                    </ul>
                </li>
            </ul>
            <v-pagination
                v-if="hasPagination"
                v-model="currentPage"
                :length="getNumberOfPages"
                :total-visible="10"
                @update:model-value="updateCurrentPage"
            />
        </div>
        <block-loader v-else-if="getShowPurchasesLoader" />
        <p
            v-else
            class="Purchases-emptyList"
        >
            {{ $t('account:purchases:empty_list') }}
        </p>
    </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { isEmpty } from 'global-utils'
import * as userActionTypes from '@/StoreWeb/store/modules/user/action-types'
import BlockLoader from '@/StoreWeb/components/common/BlockLoader'
import OrderItem from '@/StoreWeb/components/account/order/OrderItem'
import PageConfigManager from '@/StoreWeb/managers/PageConfigManagerSingleton'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

const route = useRoute()
const store = useStore()

const currentPage = ref(!isEmpty(route.params.page) ? parseInt(route.params.page) : 1)

const pageConfigManager = PageConfigManager.getInstance()

const getOrdersByYear = computed(() => store.getters.getOrdersByYear)
const pagination = computed(() => store.state.userModule.orders)
const userPendingRequests = computed(() => store.state.userModule.userPendingRequests)

const getNumberOfPages = computed(() => {
    if (!isEmpty(pagination.value)) {
        return pagination.value.totalPages
    }
    return 1
})

const hasPagination = computed(() => {
    return !isEmpty(pagination.value) && pagination.value.totalPages > 1
})

const getShowPurchasesLoader = computed(() => {
    return userPendingRequests.value.getOrders
})

onMounted(() => {
    store.dispatch(userActionTypes.GET_ORDERS, {
        page: currentPage.value - 1
    })

    pageConfigManager.setConfig({
        title: 'account:purchases:title',
        currentAccountPage: 'purchases'
    })
})

function updateCurrentPage (page) {
    store.dispatch(userActionTypes.GET_ORDERS, {
        page: currentPage.value - 1
    })
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.Purchases {
    &-list {
        margin: 0 0 20px;
        padding: 0;
        list-style: none;
    }

    &-itemGroupLabel {
        margin-bottom: $s5;
        font-family: $fontFamily-title;
        font-size: 24px;
        font-weight: bold;
    }
}
</style>
