<template>
    <div class="ProductKeywords d-flex flex-wrap align-center gap-3">
        <v-chip
            v-for="keyword in props.keywords"
            :key="keyword"
            :disabled="true"
            class="ProductKeywords-keyword rounded-lg text-body-1 font-weight-bold"
        >
            {{ keyword }}
        </v-chip>
    </div>
</template>

<script setup>
const props = defineProps({
    keywords: {
        type: Array,
        required: true
    }
})
</script>

<style lang='scss' scoped>
@import 'globalScss';

.ProductKeywords {
    &-keyword {
        color: $color-brandSecondary !important;

        :deep(.v-chip__underlay) {
            background: $color-brandSecondaryLightBackground !important;
            opacity: 1;
        }

        &.v-chip--disabled {
            opacity: 1;
            user-select: auto;
        }
    }
}
</style>
