import ETicketProduct from '@/StoreWeb/models/user/wallet/ETicketProduct'
import IdLabelPatternNormalizer from '@/StoreWeb/normalizers/patterns/IdLabelPatternNormalizerSingleton'
import { isEmpty } from 'global-utils'

export default class {
    constructor () {
        this._idLabelPatternNormalizer = IdLabelPatternNormalizer.getInstance()
    }

    get idLabelPatternNormalizer () {
        return this._idLabelPatternNormalizer
    }

    normalize (item) {
        const product = new ETicketProduct()

        product.label = item.label
        product.status = this.idLabelPatternNormalizer.normalize(item.status)

        return product
    }

    normalizeArray (items, params) {
        const normalizedItems = []

        if (typeof params === 'undefined') {
            params = {}
        }

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item, params))
            })
        }

        return normalizedItems
    }
}
