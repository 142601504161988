<template>
    <v-card
        :class="[{ 'FareMediaItem--withProfile': fareMedia.profile !== null }]"
        class="FareMediaItem pa-3"
    >
        <div class="FareMediaItem-header justify-space-between mb-4">
            <div class="FareMediaItem-label">
                <div class="FareMediaItem-title">
                    {{ getSubTitle }}
                </div>
                <div
                    v-if="getFullName"
                    class="FareMediaItem-fullName"
                >
                    {{ getFullName }}
                </div>
                <div
                    class="FareMediaItem-expiration"
                    v-html="$sanitize(getValidityEndDate)"
                />
                <div
                    v-if="fareMedia.profile !== null"
                    class="FareMediaItem-profile"
                >
                    <span>{{ fareMedia.profile }}</span>
                </div>
            </div>
            <div
                :class="[`FareMediaItem-infos--${fareMedia.status?.id}`, 'FareMediaItem-status']"
                class="FareMediaItem-infos d-flex align-center my-1"
            >
                <div class="FareMediaItem-status mx-2">
                    <v-icon
                        v-if="FareMedia.activeStatuses.includes(fareMedia.status.id)"
                        :class="{
                            'mdi-check-circle': fareMedia.status.id === 'ACTIVE',
                            'mdi-exclamation': fareMedia.status.id === 'TO_UPDATE'
                        }"
                        class="FareMediaItem-statusIcon mdi mdi-check-circle"
                    />
                    <span class="FareMediaItem-statusText">{{ fareMedia.status.label }}</span>
                </div>
            </div>
            <v-menu
                v-if="isActionMenuAvailable"
                v-model="showActionsMenu"
            >
                <template #activator="{ props: activatorProps }">
                    <v-btn
                        class="FareMediaItem-showActionsButton opacity-100"
                        icon="mdi-dots-vertical"
                        variant="plain"
                        v-bind="activatorProps"
                    />
                </template>
                <v-list
                    class="FareMediaItem-actionList pa-1"
                    density="compact"
                    color="success"
                >
                    <template
                        v-for="(action, index) in actions"
                        :key="index"
                    >
                        <v-list-item
                            v-if="action.href"
                            :href="sanitizeUrl(action.href)"
                            :value="index"
                            target="_blank"
                            base-color="primary"
                            class="FareMediaItem-actionItem pa-2"
                        >
                            <v-list-item-title class="FareMediaItem-actionTitle text-caption font-weight-bold">
                                {{ action.label }}
                                <v-icon
                                    v-if="action.externalUrl"
                                    :aria-label="$t('open_new_tab')"
                                    :aria-hidden="false"
                                    class="pl-1"
                                    color="primary"
                                    size="medium"
                                >
                                    mdi-open-in-new
                                </v-icon>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-else-if="typeof action.onClick === 'function'"
                            :value="index"
                            base-color="primary"
                            class="FareMediaItem-actionItem pa-2"
                            @click.prevent="action.onClick"
                        >
                            <v-list-item-title class="FareMediaItem-actionTitle text-caption font-weight-bold">
                                {{ action.label }}
                            </v-list-item-title>
                        </v-list-item>
                    </template>
                </v-list>
            </v-menu>
        </div>
        <ul
            v-if="getFareMediaProducts.length"
            class="FareMediaItem-productList mb-4"
        >
            <li
                v-for="(fareMediaProduct, index) in getFareMediaProducts"
                :key="index"
            >
                <v-card
                    class="FareMediaItem-productContainer position-static"
                    elevation="0"
                >
                    <fare-media-product-item
                        :fareMediaProduct="fareMediaProduct"
                        :fareMedia="fareMedia"
                        :showActions="showActions"
                        class="FareMediaItem-productItem"
                        @suspend-subscription-button-clicked="suspendSubscriptionButtonClicked"
                    />
                </v-card>
            </li>
        </ul>
        <div
            v-if="isActionExists('FARE_MEDIA_RELOAD')"
            class="FareMediaItem-actions"
        >
            <v-btn
                color="primary"
                size="small"
                class="FareMediaItem-footer-reloadButton"
                @click="loadFareMedia"
            >
                {{ $t('fare_media:reload') }}
            </v-btn>
        </div>
        <v-btn
            v-if="FareMedia.activeStatuses.includes(fareMedia.status?.id) && !fareMedia.getIsExpired()"
            class="FareMediaItem-reloadButton font-weight-bold"
            color="primary"
            size="large"
            @click="setSupportAndGoCatalog(fareMedia)"
        >
            <v-icon
                icon="mdi-cart-arrow-down"
                class="mr-1"
            />
            {{ $t('action_button:reload_card') }}
        </v-btn>
    </v-card>
</template>

<script setup>
import { sanitizeUrl } from '@braintree/sanitize-url'
import FareMediaProductItem from '@/StoreWeb/components/fare-media/FareMediaProductItem'
import * as userMutationTypes from '@/StoreWeb/store/modules/user/mutation-types'
import { isEmpty } from 'global-utils'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { getSelectedFareMediaCookie, setSelectedFareMediaCookie } from '@/StoreWeb/js/mixins/wallet-utils'
import emitter from 'global-emitter'
import { useI18n } from 'vue-i18n'
import moment from 'moment'
import config from 'config'
import { formatAction } from '@/StoreWeb/js/composables/user-action-utils'
import FareMedia from '@/StoreWeb/models/user/wallet/FareMedia'

const emit = defineEmits(['downloadFareMediaCertificateButtonClicked', 'suspendSubscriptionButtonClicked'])

const props = defineProps({
    fareMedia: {
        type: Object,
        required: true
    },
    showActions: {
        type: Boolean,
        default: true
    },
    tag: {
        type: String,
        default: 'li'
    }
})

const router = useRouter()
const store = useStore()
const { t, locale } = useI18n()

const showActionsMenu = ref(false)

const actions = computed(() => {
    if (!isEmpty(props.fareMedia.actions)) {
        return props.fareMedia.actions.map(action => formatAction(action, setActionClick))
    }

    return []
})
const isActionMenuAvailable = computed(() => {
    return props.showActions && actions.value.length > 0
})

const isActionExists = (actionType) => {
    return actions.value.some(action => action.type === actionType)
}

const getValidityEndDate = computed(() => {
    return t('date:expire_on')
        .replace(
            '%date%',
            `<strong>${moment(props.fareMedia.validityEndDate).format(t('date_format:default'))}</strong>`
        )
})

const getFareMediaProducts = computed(() => !isEmpty(props.fareMedia.products) ? props.fareMedia.products : [])

const getSubTitle = computed(() => {
    if (props.fareMedia.type === null) {
        return t('fare_media:card_number').replace('%cardNumber%', props.fareMedia.id)
    } else {
        return `${props.fareMedia.type} ${props.fareMedia.getSubtitle()}`
    }
})

const getFullName = computed(() => {
    const recipientUser = props.fareMedia.recipientUser
    const fullName = (props.fareMedia.firstName || recipientUser?.firstName) && (props.fareMedia.lastName || recipientUser?.lastName)
        ? `${(props.fareMedia.firstName || recipientUser?.firstName).toLowerCase().ucFirst()} ${(props.fareMedia.lastName || recipientUser?.lastName).toLowerCase().ucFirst()}`
        : ''

    return fullName
})

function setActionClick (formattedAction, action) {
    switch (action.type) {
        case 'FARE_MEDIA_DOWNLOAD_CERTIFICATE':
            formattedAction.onClick = () => downloadFareMediaCertificateButtonClicked(action)
            break
        case 'FARE_MEDIA_SUSPEND_SUBSCRIPTION':
            formattedAction.onClick = () => suspendSubscriptionButtonClicked(action)
            break
        case 'FARE_MEDIA_RENEW':
            formattedAction.onClick = () => renewFareMedia(action)
            break
        case 'FARE_MEDIA_REBUILD':
            formattedAction.onClick = () => rebuildFareMedia(action)
            break
        default:
            if (action?.parameters?.URI) {
                formattedAction.href = action.parameters.URI

                if (!isEmpty(action.external)) {
                    formattedAction.externalUrl = action.external
                }
            }
            break
    }
}

function loadFareMedia () {
    router
        .push(
            {
                name: 'fareMediaLoading',
                params: {
                    providerId: props.fareMedia.providerId,
                    providerUserId: props.fareMedia.accountId,
                    providerUserExternalId: props.basket.providerUserExternalId,
                    id: props.fareMedia.id
                }
            }
        )
}

function downloadFareMediaCertificateButtonClicked (action) {
    emit('downloadFareMediaCertificateButtonClicked', {
        actionType: action.type,
        fareMedia: props.fareMedia
    })
}

function rebuildFareMedia (action) {
    if (action?.parameters?.TEXT) {
        router.push({
            name: 'product',
            params: {
                id: action.parameters.TEXT
            }
        })
    }
}

function renewFareMedia (action) {
    const providerConfig = config.providers.find(provider => provider.id === props.fareMedia.providerId)

    if (
        providerConfig?.fare_media?.actions &&
        action.type in providerConfig.fare_media.actions &&
        providerConfig.fare_media.actions[action.type]?.link
    ) {
        window.location.href = providerConfig.fare_media.actions[action.type].link[locale.value]
    }
}

function suspendSubscriptionButtonClicked (action) {
    emit('suspendSubscriptionButtonClicked', action)
}

function setSupportAndGoCatalog (fareMedia) {
    store.commit(userMutationTypes.SET_SELECTED_SUPPORT, fareMedia)

    const selectedFareMediaCookie = getSelectedFareMediaCookie()

    const supportContentCookie = {
        fareMediaId: fareMedia.id,
        providerId: fareMedia.providerId,
        providerUserExternalId: fareMedia.providerUserExternalId,
        providerUserId: fareMedia.providerUserId
    }

    if (fareMedia?.isTemporary) {
        supportContentCookie.isTemporary = fareMedia.isTemporary
    }

    const isCadMode = selectedFareMediaCookie?.fareMediaId === fareMedia.id && selectedFareMediaCookie?.isCadMode

    setSelectedFareMediaCookie(supportContentCookie, isCadMode)
    emitter.emit('selectedSupportChanged')
    router.push({ name: 'catalog' })
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.FareMediaItem {
    position: relative;
    margin-bottom: $s5;
    border-radius: 15px !important;
    background: $color-lightest;
    color: $color-lightText;

    &-productContainer {
        height: 100%;
    }

    &-productItem {
        height: 100%;
        border-radius: 15px !important;
        background: linear-gradient(348.76deg, rgba(219, 191, 255, .1354) 13.95%, rgba(196, 208, 255, .16) 88.25%);
    }

    &-header {
        display: flex;
        justify-content: space-between;
        gap: 5px;
    }

    &-label {
        flex-grow: 1;
    }

    &-title {
        display: block;
        font-family: $fontFamily-title;
        font-size: 16px;
        font-weight: $fontWeight-titleBold;
        color: $color-defaultText;
    }

    &-subtitle {
        font-size: 12px;
        font-weight: $fontWeight-defaultMedium;
        color: $color-lighterText;
    }

    &-profile {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-height: 26px;
        font-size: 12px;
        font-weight: $fontWeight-defaultMedium;
        color: $color-lighterText;

        &ChangeButton {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            padding: 4px;
            cursor: pointer;
            color: $color-lighterText;
            transition: color .5s;

            &:hover,
            &:focus {
                color: $color-lightText;
            }
        }

        &ChangeButtonIcon {
            font-size: 18px;
        }
    }

    &-infos {
        height: 30px;
        border-radius: 15px !important;
        background: rgba(red($primary_color), green($primary_color), blue($primary_color), .15);
        text-align: right;
        color: $primary_color;
    }

    &-status {
        display: inline-flex;
        align-items: center;
        font-size: 14px;
        font-weight: $fontWeight-defaultMedium;
        text-transform: lowercase;
    }

    &-statusIcon {
        margin-right: $s1;
    }

    &-infos--TO_UPDATE {
        background: $color-danger;
        color: $button-danger-foregroundColor;

        .FareMediaItem-statusIcon {
            margin-right: 0;
        }
    }

    &-statusText {
        @include handleOverflowTextContent;

        max-width: 100px;

        @media (min-width: $tablet-breakpoint) {
            max-width: none;
        }
    }

    &-expiration {
        font-size: 12px;
        color: $color-lighterText;
    }

    &-showActionsButton {
        margin-top: $ns1;
        margin-right: $ns2;

        &:hover,
        &:active {
            color: $primary_color;
        }
    }

    &-actionList {
        max-width: 80vw;
    }

    &-actionItem {
        min-height: 0 !important;
    }

    &-actionTitle {
        overflow: hidden;
        cursor: pointer;
        text-overflow: unset;
        white-space: unset;
        hyphens: none;
    }

    &-body {
        border-top: 1px solid $color-lightgray3;
    }

    &-actions {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 0 15px 15px;

        @media (min-width: $tablet-breakpoint) {
            flex-flow: row wrap;
            justify-content: flex-end;
            padding-right: 65px;
            padding-left: 25px;
        }
    }

    &-reloadButton {
        width: max-content;
    }

    &-footer {
        padding: 15px;
        border-top: 1px solid $color-lightgray3;

        @media (min-width: $tablet-breakpoint) {
            padding-right: 25px;
            padding-left: 25px;
        }
    }

    &-productList {
        display: grid;
        grid-template-columns: 1fr;
        gap: $s3;
        margin: 0;
        padding: 0;
        list-style: none;

        @media (min-width: $tablet-breakpoint) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (min-width: $desktop-breakpoint) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &--activeStatus {
        .FareMediaItem-status {
            color: $primary_color;
        }
    }

    &--withProfile {
        .FareMediaItem-header {
            align-items: flex-start;
        }
    }
}
</style>
