<template>
    <component
        :is="'li'"
        class="OrderPlan"
    >
        <p class="OrderPlan-title">
            {{ getTitle }}
        </p>
        <formatted-fare
            v-if="plan.fareIncludingTaxes || plan.fareIncludingTaxes > 0"
            :fare="plan.fareIncludingTaxes"
            :size="'XSmall'"
            class="OrderPlan-fare"
        />
    </component>
</template>

<script>
import FormattedFare from '@/StoreWeb/components/common/FormattedFare'

export default {
    components: {
        FormattedFare
    },
    props: {
        plan: {
            type: Object,
            required: true
        }
    },
    computed: {
        getTitle () {
            return this.plan.name
        }
    }
}
</script>
<style lang="scss" scoped>
@import 'globalScss';

.OrderPlan {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-title {
        flex-grow: 1;
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        color: $color-lightText;
    }

    &-fare {
        color: $color-lightText;
    }
}
</style>
