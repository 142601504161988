import * as mainMutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import * as mutationTypes from '@/StoreWeb/store/modules/cart/mutation-types'
import * as paymentMutationTypes from '@/StoreWeb/store/modules/payment/mutation-types'
import cachios from 'cachios'
import emitter from 'global-emitter'
import { isEmpty } from 'global-utils'
import Cart from '@/StoreWeb/models/cart/Cart'
import CartSerializer from '@/StoreWeb/serializers/CartSerializerSingleton'
import DebuggingManager from '@/StoreWeb/managers/DebuggingManagerSingleton'
import ErrorManager from '@/StoreWeb/managers/ErrorManager'
import * as userMutationTypes from '@/StoreWeb/store/modules/user/mutation-types'
import router from '@/router/router'
import i18n from 'i18n'
import {
    constructCartUrl,
    cartDeletedHandler,
    emitEventCart,
    handleDefectiveCart,
    handleValidCart,
    isCartDefective,
    isValidGetCartResponse,
    loadCart,
    showIllogicalCartStructureAlert,
    checkNeedsAddProductOnFareMedia
} from '@/StoreWeb/js/composables/cart-utils'

export default class {
    constructor () {
        this._cartSerializer = CartSerializer.getInstance()
        this._debuggingManager = DebuggingManager.getInstance()
        this._retryCounter = {
            getCart: 0
        }
        this._retryCounterMaxValue = {
            getCart: 7
        }

        this._store = null
    }

    get cartSerializer () {
        return this._cartSerializer
    }

    get debuggingManager () {
        return this._debuggingManager
    }

    get retryCounter () {
        return this._retryCounter
    }

    set retryCounter (retryCounter) {
        this._retryCounter = retryCounter
    }

    get retryCounterMaxValue () {
        return this._retryCounterMaxValue
    }

    set retryCounterMaxValue (retryCounterMaxValue) {
        this._retryCounterMaxValue = retryCounterMaxValue
    }

    get store () {
        return this._store
    }

    set store (store) {
        this._store = store
    }

    async addToCart (params) {
        // TODO : Checker si le produit ajouté au panier est un FAREMEDIA et si oui, montrer le catalogue des produits
        //  ajoutables sur ce FAREMEDIA une fois ajouté au panier
        const addToCartParams = {
            data: params.data,
            localErrorCallback: params?.localErrorCallback || null,
            isFareMedia: params.product.isFareMedia()
        }

        if (this.store.state.cartModule.cart === null || this.store.state.cartModule.cart?.id === null) {
            await loadCart(params)
        }

        return this.addToCartAction(addToCartParams)
    }

    clearCart () {
        this.store.commit(mutationTypes.SET_CART, new Cart())
        this.store.commit(userMutationTypes.SET_SELECTED_SUPPORT, null)
        this.store.commit(userMutationTypes.SET_WAITING_FOR_ACTION_FARE_MEDIA, null)
        emitter.emit('selectedSupportChanged')
    }

    getCartSuccessHandler (params) {
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
        this.store.commit(mutationTypes.SET_CART_PENDING_REQUEST, { key: 'getCart', value: false })

        if (!params.data) return

        if (!isValidGetCartResponse(params.data)) {
            showIllogicalCartStructureAlert(params.data)
            return
        }

        if (params.data.empty) {
            this.store.commit(mutationTypes.SET_IS_GUEST_PURCHASE_FLOW, false)
        }

        if (isCartDefective(params.data.status)) {
            handleDefectiveCart(params.data.id)
            return
        }

        return handleValidCart(params.data)
    }

    getCartErrorHandler (error, params) {
        const errorMessage = 'cart:get_cart'
        ErrorManager.displayErrorModal(errorMessage, error)

        this.store.commit(mutationTypes.SET_CART_PENDING_REQUEST, { key: 'getCart', value: false })
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
    }

    getIsPaymentProcessingFlow () {
        const cartStatus = this.store.state.cartModule.cart?.status
        let isPaymentProcessingFlow = false
        const paymentProcessingStatus = [
            'PAYMENT_PROCESSING',
            'PROVIDERS_TOTAL_CANCELED',
            'PROVIDERS_TOTAL_CONFIRMED',
            'PAYMENT_DECLINED'
        ]
        if (
            !isEmpty(cartStatus) &&
            paymentProcessingStatus.indexOf(cartStatus) !== -1
        ) {
            isPaymentProcessingFlow = true
        }

        return isPaymentProcessingFlow
    }

    getIsEmptyCart () {
        if (this.store.state.cartModule.cart?.id) {
            return this.store.state.cartModule.cart?.empty
        }

        return null
    }

    handleMockCartResponse (params) {
        return this.debuggingManager.getResponse('getCart', (response) => {
            return this.getCartSuccessHandler({ ...params, data: response.data })
        })
    }

    async getCart (url, params) {
        try {
            const response = await cachios.get(url, { force: true })
            const isPaymentProcessing = params?.isPaymentSuccessPage && response.data.status === 'PAYMENT_PROCESSING'

            if (isPaymentProcessing) {
                if (this.retryCounter.getCart < this.retryCounterMaxValue.getCart) {
                    return this.retryGetCart(params)
                } else {
                    this.store.commit(mutationTypes.SET_CART_PENDING_REQUEST, { key: 'getCart', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                }
            } else {
                return this.getCartSuccessHandler({ ...params, data: response.data })
            }
        } catch (error) {
            return this.getCartErrorHandler(error, params)
        }
    }

    retryGetCart (params) {
        const timeout = 2 ** this.retryCounter.getCart * 1000
        this.retryCounter.getCart++

        setTimeout(() => {
            loadCart(params)
        }, timeout)
    }

    /* =============================================================================================================
    =================================================== ACTIONS ====================================================
    ============================================================================================================= */
    addToCartAction (params) {
        return new Promise((resolve, reject) => {
            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
            this.store.commit(mutationTypes.SET_CART_PENDING_REQUEST, { key: 'addToCart', value: true })

            let body = null
            if (params.addToCartBody) {
                body = params.addToCartBody
            } else {
                body = this.cartSerializer.getAddToCartBody({
                    orderId: this.store.state.cartModule.cart.id,
                    data: params.data
                })
            }

            if (!isEmpty(params?.data?.address)) {
                body.items.forEach((item) => {
                    item.address = params.data.address
                })
            }
            const deactivateAddToCart = false
            if (deactivateAddToCart) {
                /* eslint-disable */
                console.log('SUPPOSED TO ADD TO CART >>> UNCOMMENT FOR ADDING TO CART')
                console.log(body)
                /* eslint-enable */
                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
            } else {
                cachios
                    .post(`${process.env.VUE_APP_API_URL}/cart`, body, {
                        force: true
                    })
                    .then(response => {
                        this.store.commit(mutationTypes.SET_CART, response.data)
                        this.store.commit(paymentMutationTypes.SET_PAYMENT_MODES, response.data.paymentModes ?? [])
                        this.store.commit(mutationTypes.SET_CART_PENDING_REQUEST, { key: 'addToCart', value: false })
                        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                        if (!this.store.state.userModule.isAuthenticated) {
                            emitter.emit('enteringGuestPurchaseMode')
                        } else if (!isEmpty(params.itemType) && params.itemType === 'FINE') {
                            router.push({ name: i18n.locale + '.cart' })
                        } else {
                            this.store.commit(mutationTypes.SET_SHOW_CART, true)
                        }
                        emitter.emit('addToCartSuccess')
                        emitter.emit('hideProductModal')
                        emitEventCart('add')
                        resolve(response.data)

                        // Wait until the end of the Modal closing animation
                        setTimeout(() => {
                            checkNeedsAddProductOnFareMedia(response.data)
                        }, 500)
                    })
                    .catch(error => {
                        this.store.commit(mutationTypes.SET_CART_PENDING_REQUEST, { key: 'addToCart', value: false })
                        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                        this.getCartAction()
                        reject(error)
                    })
            }
        })
    }

    getCartAction (params = {}) {
        this.store.commit(mutationTypes.SET_CART_PENDING_REQUEST, { key: 'getCart', value: true })
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })

        return new Promise((resolve, reject) => {
            const url = constructCartUrl(params)
            if (this.debuggingManager.getMockActionState('getCart')) {
                try {
                    const cart = this.handleMockCartResponse(params)
                    emitEventCart('update')
                    resolve(cart)
                } catch (error) {
                    reject(error)
                }
            } else {
                this.getCart(url, params)
                    .then((response) => {
                        emitEventCart('update')
                        resolve(response)
                    })
                    .catch((error) => reject(error))
            }
        })
    }

    getFinalizedCartAction (params) {
        return new Promise((resolve, reject) => {
            this.store.commit(mutationTypes.SET_CART_PENDING_REQUEST, { key: 'getFinalizedCart', value: true })
            let url = `${process.env.VUE_APP_API_URL}/cart/${params.id}`

            if (params.authenticated === false && params.email) {
                url += `?email=${params.email}`
            } else if (!this.store.state.userModule.isAuthenticated && params.authenticated) {
                const errorMessage = i18n.global.t('cart:get_last_confirmed_cart:error:has_to_authenticate:title')
                ErrorManager.displayErrorModal('cart:get_last_confirmed_cart:error:has_to_authenticate:title', new Error(errorMessage))
                reject(errorMessage)
            }

            if (this.debuggingManager.getMockActionState('getFinalizedCart')) {
                this.debuggingManager.getResponse('getFinalizedCart', (response) => {
                    this.store.commit(mutationTypes.SET_CART_PENDING_REQUEST, { key: 'getFinalizedCart', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                    resolve(response.data)
                })
            } else {
                cachios
                    .get(url, params.cachiosOptions ?? {})
                    .then(response => {
                        this.store.commit(mutationTypes.SET_CART_PENDING_REQUEST, {
                            key: 'getFinalizedCart',
                            value: false
                        })
                        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })

                        if (response.data.status === 'VALIDATED') {
                            emitEventCart('update')
                        }

                        resolve(response.data)
                    })
                    .catch(error => {
                        ErrorManager.displayErrorModal('cart:get_last_confirmed_cart', error)
                        this.store.commit(mutationTypes.SET_CART_PENDING_REQUEST, {
                            key: 'getFinalizedCart',
                            value: false
                        })
                        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                        reject(error.message)
                    })
            }
        })
    }

    clearCartAction (params) {
        return new Promise((resolve, reject) => {
            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
            cachios
                .get(`${process.env.VUE_APP_API_URL}/cart/${params.id}/cleanup`, {
                    force: true
                })
                .then(() => {
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                    cartDeletedHandler()
                    resolve(true)
                })
                .catch(error => {
                    ErrorManager.displayErrorModal('cart:clear_cart', error)
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })

                    reject(error)
                })
        })
    }

    deleteCartAction (params) {
        return new Promise((resolve, reject) => {
            this.store.commit(mutationTypes.SET_CART_PENDING_REQUEST, { key: 'deleteCart', value: true })
            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })

            cachios
                .delete(`${process.env.VUE_APP_API_URL}/cart/${params.id}`, {
                    force: true
                })
                .then(async () => {
                    if (
                        !isEmpty(this.store.state.userModule.selectedFareMedia) &&
                        this.store.state.userModule.selectedFareMedia.isTemporary
                    ) {
                        await this.clearCart()
                    }

                    cartDeletedHandler()
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                    this.store.commit(mutationTypes.SET_CART_PENDING_REQUEST, { key: 'deleteCart', value: false })

                    resolve(true)
                })
                .catch(error => {
                    ErrorManager.displayErrorModal('cart:delete_cart', error)
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                    this.store.commit(mutationTypes.SET_CART_PENDING_REQUEST, { key: 'deleteCart', value: false })

                    reject(error)
                })
        })
    }

    deleteCartLineAction (params) {
        return new Promise((resolve, reject) => {
            this.store.commit(mutationTypes.SET_CART_PENDING_REQUEST, { key: 'deleteCartLine', value: true })
            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })

            cachios
                .delete(`${process.env.VUE_APP_API_URL}/cart/${params.orderId}/items?line=${params.line}&type=${params.type}`)
                .then(async response => {
                    await this.store.commit(mutationTypes.SET_CART_PENDING_REQUEST, { key: 'deleteCartLine', value: false })
                    await this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                    emitEventCart('update')
                    resolve(true)
                })
                .catch(error => {
                    ErrorManager.displayErrorModal('cart:delete_cart_line', error)
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                    this.store.commit(mutationTypes.SET_CART_PENDING_REQUEST, { key: 'deleteCartLine', value: false })

                    reject(error)
                })
        })
    }

    getCartConfirmationAction (params) {
        return new Promise((resolve, reject) => {
            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
            this.store.commit(mutationTypes.SET_CART_PENDING_REQUEST, { key: 'cartConfirmation', value: true })

            const body = this.cartSerializer.getCartConfirmBody({
                orderId: this.store.state.cartModule.cart.id,
                data: params
            })

            if (this.debuggingManager.getMockActionState('getCartConfirmation')) {
                this.debuggingManager.getResponse('getCartConfirmation', (response) => {
                    this.store.commit(mutationTypes.SET_CART_PENDING_REQUEST, { key: 'cartConfirmation', value: false })
                    if (response.data.status === 'PROVIDERS_TOTAL_CONFIRMED') {
                        emitter.emit('cartConfirmationSuccess', response.data)
                    } else {
                        emitter.emit('showAlertModal', {
                            message: i18n.global.t('cart:confirmation:error:message').replace('%status%', params.status),
                            status: response.data.status,
                            title: i18n.global.t('cart:confirmation:error:title')
                        })
                    }
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                })
            } else {
                cachios
                    .post(`${process.env.VUE_APP_API_URL}/cart/confirm`, body, { force: true })
                    .then(response => {
                        this.store.commit(mutationTypes.SET_CART_PENDING_REQUEST, { key: 'cartConfirmation', value: false })

                        if (response.data.status === 'PROVIDERS_TOTAL_CONFIRMED') {
                            emitter.emit('cartConfirmationSuccess', response.data)
                            resolve(response.data)
                        } else {
                            emitter.emit('showAlertModal', {
                                message: i18n.global.t('cart:confirmation:error:message').replace('%status%', params.status),
                                status: response.data.status,
                                title: i18n.global.t('cart:confirmation:error:title')
                            })
                        }
                        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                    })
                    .then(() => {
                        return loadCart()
                    })
                    .catch(error => {
                        ErrorManager.displayErrorModal('cart:get_cart_confirmation', error)
                        this.store.commit(mutationTypes.SET_CART_PENDING_REQUEST, { key: 'cartConfirmation', value: false })
                        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                        reject(error)
                    })
            }
        })
    }

    reopenCartAction (params) {
        return new Promise((resolve, reject) => {
            this.store.commit(mutationTypes.SET_CART_PENDING_REQUEST, { key: 'reopenCart', value: true })
            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
            cachios
                .post(`${process.env.VUE_APP_API_URL}/cart/${params.id}/reopen`, {}, params.cachiosOptions)
                .then(async response => {
                    if (response.status === 200) {
                        await loadCart()
                    }
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.message)
                })
                .finally(() => {
                    this.store.commit(mutationTypes.SET_CART_PENDING_REQUEST, { key: 'reopenCart', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                })
        })
    }
}
