<template>
    <div class="CustomSelect">
        <v-select
            :id="id"
            :ref="'select-' + _uid"
            v-model="select"
            :items="getItems"
            :label="label"
            :clearable="clearable"
            hide-details
            @update:model-value="updateValue"
            @click:clear="unfocus"
        />
    </div>
</template>

<script>
export default {
    props: {
        defaultValue: {
            type: [Object, String],
            default: null
        },
        clearable: {
            type: Boolean,
            default: false
        },
        id: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        options: {
            type: Array,
            required: true
        }
    },
    data () {
        return {
            select: ''
        }
    },
    computed: {
        getRules () {
            if (this.isRequired) {
                return [
                    (value) => value !== ''
                ]
            }
            return []
        },
        getItems () {
            return this.options.map((item) => {
                return {
                    text: item.name,
                    value: item.id
                }
            }).filter((item) => item.value !== '')
        }
    },
    watch: {
        defaultValue: function () {
            if (this.defaultValue.id !== this.select) {
                this.resetSelect()
            }
        },
        options: function () {
            this.resetSelect()
        }
    },
    mounted () {
        this.resetSelect()
    },
    methods: {
        resetSelect () {
            this.select = null

            if (this.defaultValue) {
                this.select = this.defaultValue.id
            }

            this.updateValue()
        },
        updateValue () {
            this.$nextTick(() => {
                const selectedOption = this.options.find(option => option.id === this.select)
                this.$emit('selectChanged', { dropdownId: this.id, selected: selectedOption || { id: '' } })
            })
        },
        unfocus (event) {
            this.$refs['select-' + this._uid].blur()
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.CustomSelect {
    position: relative;
    font-family: $fontLato;
}
</style>
