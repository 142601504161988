<template>
    <div class="OriginDestinationZoneRouteForm">
        <v-autocomplete
            v-if="true"
            ref="originAutocomplete"
            v-model="selectedOrigin"
            :custom-filter="filterSuggestion"
            :items="origins"
            :label="$t('add_to_cart_flow:origin_destination_zone_route_selector:origin_zone')"
            :loading="showOriginsLoader"
            :no-data-text="$t('autocomplete:no_result')"
            item-title="label"
            item-value="value"
            return-object
            variant="underlined"
            @update:model-value="updateSelectedOrigin"
            @update:search="resetMatches(originAutocomplete.filteredItems)"
        >
            <template #item="{ props: activatorProps, item }">
                <v-list-item
                    v-bind="activatorProps"
                    :title="item.name"
                >
                    <template #title>
                        <div
                            v-html="$sanitize(highlightMatch(item))"
                        />
                    </template>
                </v-list-item>
            </template>
        </v-autocomplete>
        <v-autocomplete
            v-if="selectedOrigin !== null"
            ref="destinationAutocomplete"
            v-model="selectedDestination"
            :custom-filter="filterSuggestion"
            :items="destinations"
            :label="$t('add_to_cart_flow:origin_destination_zone_route_selector:destination_zone')"
            :loading="showDestinationsLoader"
            :no-data-text="$t('autocomplete:no_result')"
            item-title="label"
            item-value="value"
            return-object
            variant="underlined"
            @update:model-value="updateSelectedDestination"
            @update:search="resetMatches(destinationAutocomplete.filteredItems)"
        >
            <template #item="{ props: activatorProps, item }">
                <v-list-item
                    v-bind="activatorProps"
                    :title="item.name"
                >
                    <template #title>
                        <div
                            v-html="$sanitize(highlightMatch(item))"
                        />
                    </template>
                </v-list-item>
            </template>
        </v-autocomplete>
        <v-select
            v-if="selectedOrigin !== null && selectedDestination !== null"
            v-model="selectedRoute"
            :items="routes"
            :label="$t('add_to_cart_flow:origin_destination_zone_route_selector:route')"
            :loading="showRoutesLoader"
            class="OriginDestinationZoneRouteForm-select"
            item-title="label"
            item-value="value"
            @update:model-value="updateSelectedRoute"
        />
    </div>
</template>

<script setup>
import {
    onMounted,
    ref
} from 'vue'
import { useStore } from 'vuex'
import AddToCartFlowManager from '@/StoreWeb/managers/AddToCartFlowManagerSingleton'
import * as catalogActionTypes from '@/StoreWeb/store/modules/catalog/action-types'
import * as cartMutationTypes from '@/StoreWeb/store/modules/cart/mutation-types'
import { isEmpty } from 'global-utils'
import { filterSuggestion, highlightMatch, resetMatches } from '@/StoreWeb/js/mixins/autocomplete-utils'

const store = useStore()

const props = defineProps({
    product: {
        type: Object,
        required: true
    },
    productParameter: {
        type: Object,
        required: true
    }
})

const emit = defineEmits(['updateOriginDestinationZoneRouteForm'])

const addToCartFlowManager = AddToCartFlowManager.getInstance()

const destinationAutocomplete = ref(null)
const destinations = ref([])
const originAutocomplete = ref(null)
const origins = ref([])
const routes = ref([])
const saleOptionStep = ref(null)
const selectedDestination = ref(null)
const selectedOrigin = ref(null)
const selectedRoute = ref(null)
const showDestinationsLoader = ref(false)
const showOriginsLoader = ref(false)
const showRoutesLoader = ref(false)

const getOrigins = async () => {
    showOriginsLoader.value = true

    await store.dispatch(catalogActionTypes.GET_LIST_BY_PARAMETER_NAME, {
        product: props.product,
        productParameter: props.productParameter,
        saleOptionStep: saleOptionStep.value,
        currentProductParameterOptions: {
            subParameter: 'origin_zone'
        }
    })
        .then(originListLoadedHandler)
        .catch(() => {
            showOriginsLoader.value = false
        })
}

const getDestinations = async () => {
    showDestinationsLoader.value = true

    await store.dispatch(catalogActionTypes.GET_LIST_BY_PARAMETER_NAME, {
        product: props.product,
        productParameter: props.productParameter,
        saleOptionStep: saleOptionStep.value,
        currentProductParameterOptions: {
            subParameters: [
                {
                    productParameter: props.productParameter.id,
                    code: 'ORIGIN_ZONE',
                    value: selectedOrigin.value.value
                }
            ],
            subParameter: 'destination_zone'
        }
    })
        .then(destinationListLoadedHandler)
        .catch(() => {
            showDestinationsLoader.value = false
        })
}

const getRoutes = async () => {
    showRoutesLoader.value = true

    await store.dispatch(catalogActionTypes.GET_LIST_BY_PARAMETER_NAME, {
        product: props.product,
        productParameter: props.productParameter,
        saleOptionStep: saleOptionStep.value,
        currentProductParameterOptions: {
            subParameters: [
                {
                    productParameter: props.productParameter.id,
                    code: 'ORIGIN_ZONE',
                    value: selectedOrigin.value.value
                },
                {
                    productParameter: props.productParameter.id,
                    code: 'DESTINATION_ZONE',
                    value: selectedDestination.value.value
                }
            ],
            subParameter: 'route'
        }
    })
        .then(routeListLoadedHandler)
        .catch(() => {
            showRoutesLoader.value = false
        })
}

const destinationListLoadedHandler = (data) => {
    destinations.value = data.productParameter.values

    if (!isEmpty(props.productParameter.preselection)) {
        const destinationDefaultValue = props.productParameter.preselection.find(preselection => preselection.code === 'DESTINATION')

        if (destinationDefaultValue) {
            selectedDestination.value = destinations.value.find(destination => destination.value === destinationDefaultValue.value)
            if (selectedDestination.value) {
                updateSelectedDestination(selectedDestination.value)
            }
        }
    }

    showDestinationsLoader.value = false
}

const originListLoadedHandler = (data) => {
    origins.value = data.productParameter.values

    if (!isEmpty(props.productParameter.preselection)) {
        const originDefaultValue = props.productParameter.preselection.find(preselection => preselection.code === 'ORIGIN')

        if (originDefaultValue) {
            selectedOrigin.value = origins.value.find(origin => origin.value === originDefaultValue.value)
            if (selectedOrigin.value) {
                updateSelectedOrigin(selectedOrigin.value)
            }
        }
    }

    showOriginsLoader.value = false
}

const routeListLoadedHandler = (data) => {
    routes.value = data.productParameter.values

    if (!isEmpty(props.productParameter.preselection)) {
        const routeDefaultValue = props.productParameter.preselection.find(preselection => preselection.code === 'ROUTE')

        if (routeDefaultValue) {
            selectedRoute.value = routes.value.find(route => route.value === routeDefaultValue.value)
            if (selectedRoute.value) {
                updateSelectedRoute(selectedRoute.value)
            }
        }
    }

    showRoutesLoader.value = false
}

const updateProductParameter = () => {
    const productParameterValues = []
    productParameterValues.push({
        productParameter: props.productParameter.id,
        code: 'DESTINATION_ZONE',
        value: `${selectedDestination.value.value}`
    })
    productParameterValues.push({
        productParameter: props.productParameter.id,
        code: 'ORIGIN_ZONE',
        value: `${selectedOrigin.value.value}`
    })
    productParameterValues.push({
        productParameter: props.productParameter.id,
        code: 'ROUTE',
        value: `${selectedRoute.value.value}`
    })
    emit('updateOriginDestinationZoneRouteForm', {
        id: props.productParameter.id,
        isProductParameterValid: true,
        productParameterValues,
        isQuotationRequired: true
    })
}

const updateSelectedOrigin = (origin) => {
    store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, true)

    selectedOrigin.value = origin

    if (selectedOrigin.value !== null) {
        getDestinations()
    }
}

const updateSelectedDestination = (destination) => {
    store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, true)

    selectedDestination.value = destination

    if (selectedDestination.value !== null) {
        getRoutes()
    }
}

const updateSelectedRoute = async (route) => {
    if (route === '') {
        selectedRoute.value = null
    } else {
        selectedRoute.value = routes.value.find(item => item.value === route)
    }

    if (selectedOrigin.value !== null && selectedDestination.value !== null && selectedRoute.value !== null) {
        store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, false)
        updateProductParameter()
    }
}

const setDefaultValues = () => {
    if (!isEmpty(addToCartFlowManager?.currentStep?.productParameterValues)) {
        const origin = addToCartFlowManager.currentStep.productParameterValues.find(productParameter => productParameter.code === 'ORIGIN_ZONE')
        const destination = addToCartFlowManager.currentStep.productParameterValues.find(productParameter => productParameter.code === 'DESTINATION_ZONE')

        if (origin?.value) {
            updateSelectedOrigin({ value: origin.value })
        }

        if (destination?.value) {
            updateSelectedDestination({ value: destination.value })
        }
    }
}

onMounted(() => {
    store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, true)

    setDefaultValues()

    const saleOptionSteps = addToCartFlowManager.findInPreviousSteps({
        type: 'saleOption'
    }); // Adding a semi colon ";" at this line's end to use decomposition
    [saleOptionStep.value] = saleOptionSteps

    getOrigins()
})
</script>
