import { createI18n } from 'vue-i18n'
import translations from 'translations'
import config from 'config'

const supportedLocales = config.i18n.supported_locales

function isSupportedLocale (code) {
    return typeof supportedLocales[code] !== 'undefined'
}

/**
 * Extracts the locale from the given URL.
 * @param {string} url The URL to extract the locale from.
 * @returns {string|null} The extracted locale from the URL or null if it could not be extracted.
 */
function extractLocale (url) {
    const foundLocale = url.match(/^https?:\/\/[^/]+\/([^/?#]+)/)?.[1] ?? null

    if (foundLocale && isSupportedLocale(foundLocale)) {
        return foundLocale
    }

    return undefined
}

/**
 * Simplifies the language code to its short form (e.g., 'en-US' to 'en').
 * @param {string} lang The full language code.
 * @returns {string} The simplified language code.
 */
function simplifyLangCode (lang) {
    return lang.split(/[-_]/)[0]
}

// Attempt to extract the locale from the URL, then the browser's language settings, and finally default to 'fr'.
const finalLang = extractLocale(window.location.href) ??
    simplifyLangCode(navigator.languages?.[0] ?? navigator.language) ??
    'fr'

const i18n = createI18n({
    allowComposition: true,
    locale: finalLang,
    fallbackLocale: 'fr',
    messages: translations
})

document.documentElement.setAttribute('lang', finalLang)

export default i18n
