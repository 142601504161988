<template>
    <component
        :is="'li'"
        class="OrderPackage"
    >
        <p class="OrderPackage-title">
            {{ orderPackage.name }}
        </p>
        <p class="OrderPackage-productListTitle">
            Baskets
        </p>
        <ul
            v-if="orderPackage.baskets.length > 0"
            class="OrderPackage-productList"
        >
            <order-basket
                v-for="basket in orderPackage.baskets"
                :key="basket.providerBasketId"
                :basket="basket"
                class="OrderPackage-basketItem"
            />
        </ul>
    </component>
</template>

<script>
import OrderBasket from '@/StoreWeb/components/account/order/OrderBasket'

export default {
    components: {
        OrderBasket
    },
    props: {
        orderPackage: {
            type: Object,
            required: true
        }
    }
}
</script>
<style lang="scss" scoped>
@import 'globalScss';

.OrderPackage {
    &-title {
        margin: 0 0 6px;
        font-size: 16px;
        font-weight: bold;
        color: $color-lightText;
    }

    &-status {
        margin: 0 0 30px;
        font-size: 12px;
        color: $color-brandSecondary;
    }

    &-fareMedia {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 355px;
        padding: 12px 16px;
        border-radius: 6px;
        background: $color-lightgray1;

        &Icon {
            margin-right: 10px;
            font-size: 32px;
            color: $color-brandPrimary;
            transform: rotate(-7deg);
        }

        &Label {
            flex-grow: 1;
            color: $color-lighterText;
        }

        &Title {
            display: block;
            font-family: $fontFamily-title;
            font-size: 18px;
            font-weight: bold;
        }

        &Subtitle {
            display: block;
            font-size: 14px;
        }
    }

    &-productListTitle {
        margin-bottom: 10px;
        font-family: $fontFamily-title;
    }

    &-productList {
        margin: 0;
        padding: 0 0 0 10px;
        list-style: none;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-productItem {
        border-bottom: 1px solid $color-lightgray3;

        &:last-child {
            margin-bottom: 0;
            border-bottom: none;
        }
    }
}
</style>
