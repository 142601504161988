export default class {
    constructor () {
        this._creationDate = ''
        this._product = null
    }

    get creationDate () {
        return this._creationDate
    }

    set creationDate (creationDate) {
        this._creationDate = creationDate
    }

    get product () {
        return this._product
    }

    set product (product) {
        this._product = product
    }

    getLabel () {
        return this.product.label
    }

    toJSON () {
        return {
            creationDate: this.creationDate,
            product: this.product
        }
    }
}
