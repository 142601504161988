import { isEmpty } from 'global-utils'
import routesSecurity from '@/router/routes-security'

export default class {
    constructor () {
        this._store = null
    }

    get store () {
        return this._store
    }

    set store (store) {
        this._store = store
    }

    isAuthorisedInGuestModeRoute (routeName) {
        if (this.store !== null) {
            if (this.store.state.userModule.isAuthenticated) {
                return true
            }
            if (!isEmpty(routeName)) {
                const routeConfig = this.getRouteConfiguration(routeName)
                if (!isEmpty(routeConfig.authenticated)) {
                    return routeConfig.authenticated === this.store.state.userModule.isAuthenticated
                }
            }
            return false
        }
        return true
    }

    isAuthorizedRoute (routeName) {
        const { isAuthorized = true } = this.getRouteConfiguration(routeName)
        return isAuthorized
    }

    isAuthorizedInPaymentProcessingRoute (routeName) {
        if (
            this.store !== null &&
            this.store.getters.getIsPaymentProcessingFlow &&
            !isEmpty(routeName)
        ) {
            const routeConfig = this.getRouteConfiguration(routeName)

            return !isEmpty(routeConfig.paymentProcessingFlow) && routeConfig.paymentProcessingFlow
        }

        return true
    }

    isAuthorizedWithEmptyCartRoute (routeName) {
        if (
            this.store !== null &&
            this.store.getters.getIsEmptyCart &&
            !isEmpty(routeName)
        ) {
            const routeConfig = this.getRouteConfiguration(routeName)

            return isEmpty(routeConfig.emptyCart) || routeConfig.emptyCart
        }

        return true
    }

    getRouteConfiguration (routeName) {
        let routeConfig = {}

        if (!isEmpty(routesSecurity.default[routeName])) {
            routeConfig = Object.assign(routeConfig, routesSecurity.default[routeName])
        }

        if (!isEmpty(routesSecurity[process.env.VUE_APP_NETWORK_ID]) && !isEmpty(routesSecurity[process.env.VUE_APP_NETWORK_ID][routeName])) {
            routeConfig = Object.assign(routeConfig, routesSecurity[process.env.VUE_APP_NETWORK_ID][routeName])
        }

        return routeConfig
    }
}
