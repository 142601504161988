import Form from '@/StoreWeb/models/form/Form'
import FormFieldNormalizer from '@/StoreWeb/normalizers/form/FormFieldNormalizerSingleton'
import { isEmpty } from 'global-utils'

export default class {
    constructor () {
        this._formFieldNormalizer = FormFieldNormalizer.getInstance()
    }

    get formFieldNormalizer () {
        return this._formFieldNormalizer
    }

    normalize (item) {
        if (typeof item === 'string') {
            item = JSON.parse(item)
        }
        const form = new Form(item.code)

        if (!isEmpty(item.fields)) {
            form.fields = this.formFieldNormalizer.normalizeArray(item.fields)
        }
        if (!isEmpty(item.label)) {
            form.label = item.label
        }
        if (!isEmpty(item.maxNb)) {
            form.maxNb = item.maxNb
        }
        if (!isEmpty(item.minNb)) {
            form.minNb = item.minNb
        }

        return form
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }

    normalizeFromAltFormat (fields, values) {
        const form = new Form('')

        if (!isEmpty(fields)) {
            const convertedFields = []
            if (!isEmpty(fields)) {
                fields.forEach(field => {
                    convertedFields.push(this.formFieldNormalizer.normalizeFromAltFormat(
                        field,
                        values[field.name] ?? undefined
                    ))
                })
            }
            form.fields = convertedFields
        }

        return form
    }
}
