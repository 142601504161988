<template>
    <div
        :class="{ 'SelectDropdown--inline': inline }"
        class="SelectDropdown"
    >
        <label
            :for="'select_dropdown_' + inputId"
            :class="{ 'SelectDropdown-label--inline': inline }"
            class="SelectDropdown-label"
        >
            {{ label }}
            <span
                v-if="isRequired"
                class="SelectDropdown-required"
            >*
            </span>
        </label>
        <div
            :class="{ 'SelectDropdown-input--inline': inline }"
            class="SelectDropdown-input"
        >
            <select
                :id="'select_dropdown_' + inputId"
                v-model="selected"
                :class="{ 'SelectDropdown-select--isThin': isThin }"
                :style="{ borderColor: getBorderColor, 'box-shadow': getBoxShadow }"
                class="SelectDropdown-select"
                @blur="focused = false"
                @click="checkIsOpened"
                @focus="focused = true"
                @keyup.enter="checkIsOpened"
                @keyup.space="checkIsOpened"
                @change="updateValue"
            >
                <option
                    v-for="(option, index) in options"
                    :key="index"
                    :value="option.id"
                    :class="{ 'SelectDropdown-option--default': option.id === -1 }"
                    class="SelectDropdown-option"
                >
                    {{ option.name }}
                </option>
            </select>
            <span
                :class="getSelectClass"
                class="SelectDropdown-icon vuecommon-icon_chevron_down"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    props: {
        defaultValue: {
            type: [Number, String],
            default: -1
        },
        id: {
            type: String,
            default: ''
        },
        inline: {
            type: Boolean,
            default: false
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        isThin: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        options: {
            type: Array,
            required: true
        }
    },
    data () {
        return {
            focused: false,
            inputId: null,
            opened: false,
            selected: ''
        }
    },
    computed: {
        ...mapState(['mainColor', 'mainColorLight', 'mainColorDark']),
        getBorderColor () {
            return this.focused ? this.mainColor : ''
        },
        getBoxShadow () {
            return this.focused
                ? '0 0 0 1px' +
                      this.mainColor +
                      ', inset 0 0 3px 0 ' +
                      this.mainColorLight
                : 'none'
        },
        getSelectClass () {
            let selectClass = ''

            if (this.opened) {
                selectClass += 'SelectDropdown-icon--rotated'
            }

            return selectClass
        }
    },
    watch: {
        defaultValue: function (val) {
            this.resetSelect()
        }
    },
    mounted () {
        this.inputId = this._uid
        this.resetSelect()
        document.addEventListener('click', this.handleClickOutside)
    },
    unmounted () {
        document.removeEventListener('click', this.handleClickOutside)
    },
    methods: {
        checkIsOpened () {
            this.opened = !this.opened
        },
        handleClickOutside (event) {
            event.stopPropagation()
            if ((event.target.classList.value.indexOf('SelectDropdown') === -1 || event.currentTarget.activeElement.classList.value.indexOf('SelectDropdown') === -1) && this.opened) {
                setTimeout(() => {
                    this.opened = false
                }, 0)
            }
        },
        resetSelect () {
            if (this.defaultValue === '' || this.defaultValue === -1) {
                this.selected = this.options[0].id
            } else {
                this.selected = this.defaultValue
            }
        },
        updateValue () {
            this.$nextTick(() => {
                this.$emit('selectChanged', { dropdownId: this.id, selectedId: this.selected })
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

select,
option {
    appearance: none;
    border: none;
}

.SelectDropdown {
    font-family: $fontLato;

    &--inline {
        @include verticalCenter;
    }

    &-label {
        font-size: $font-small;
        line-height: $font-small;
        color: $color-grey-dark;

        &--inline {
            margin-right: $margin-xsmall;
        }
    }

    &-input {
        position: relative;
        width: 100%;
        margin-top: $margin-xsmall;

        &--inline {
            width: max-content;
            margin-top: 0;
        }
    }

    &-select {
        @include outline0;
        @include handleOverflowTextContent;

        width: 100%;
        padding: $padding-small;
        padding-right: $padding-xlarge;
        border-width: 1px;
        border-style: solid;
        border-color: $color-grey-light;
        border-radius: $borderRadius-medium;
        cursor: pointer;
        font-size: $font-small;
        line-height: 18px;
        color: $color-black-light;
        transition: border-color .3s ease-in-out, box-shadow .3s ease-in-out;

        &--isThin {
            padding: $padding-xsmall;
            padding-right: $padding-xlarge;
        }
    }

    &-icon {
        position: absolute;
        top: calc(50% - #{$font-small} / 2);
        right: $margin-small;
        pointer-events: none;
        font-size: $font-small;
        color: $color-grey-dark;
        transition: transform .3s ease-in-out;
    }

    &-icon--rotated {
        transform: rotate(180deg);
    }

    &-option {
        padding: 22px;
    }

    &-required {
        color: $color-error;
    }
}
</style>
