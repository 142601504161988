<template>
    <div class="CardCatalog">
        <catalog-card-page-banner />
        <div class="Container">
            <v-progress-circular
                v-if="showLoader"
                indeterminate
                color="primary"
            />
            <product-list
                v-if="fareMedias.length > 0"
                :name="$t('account:tickets:tabs:cards')"
                :link-to-product-page="true"
                :products="fareMedias"
            />
            <v-alert
                v-else
                class="CardCatalog-empty mb-5"
                color="secondary"
                variant="tonal"
            >
                {{ $t('fare_media:add_new_modal:empty_list') }}
            </v-alert>
            <product-modal />
        </div>
    </div>
</template>

<script setup>
import ProductList from '@/StoreWeb/components/product/ProductList'
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import * as catalogActionTypes from '@/StoreWeb/store/modules/catalog/action-types'
import ProductModal from '@/StoreWeb/components/product/ProductModal.vue'

const store = useStore()

const catalogPendingRequests = computed(() => store.state.catalogModule.catalogPendingRequests)
const cartPendingRequests = computed(() => store.state.cartModule.cartPendingRequests)
const fareMedias = computed(() => store.state.catalogModule.fareMedias)
const showLoader = computed(() => catalogPendingRequests.value.getFareMedias || cartPendingRequests.value.addToCart)

onMounted(async () => {
    await store.dispatch(catalogActionTypes.GET_FARE_MEDIAS)
})
</script>
