<template>
    <div class="AddressSelector">
        <h3 class="h2">
            {{ $t('address:billing_address') }}
        </h3>
        <div
            v-if="showAddressListLoader"
            class="text-center mt-4"
        >
            <v-progress-circular indeterminate />
        </div>
        <div
            v-else
            class="AddressSelector-listWrapper"
        >
            <address-selector-recap-item
                v-if="!getShowBillingAddressList && getBillingAddress !== null"
                :address="getBillingAddress"
                :showChangeButton="true"
                class="AddressSelector-recap"
                @choose-another-address-clicked="chooseAnotherBillingAddress"
                @edit-address-clicked="showEditAddressForm"
            />
            <ul
                v-if="getShowBillingAddressList && getAddresses.length > 0"
                v-click-outside="hideBillingAddressList"
                class="AddressSelector-list"
            >
                <address-selector-item
                    v-for="address in getAddresses"
                    :key="address.id"
                    :address="address"
                    :isSelected="getBillingAddress !== null && address.id === getBillingAddress.id"
                    :showEditButton="true"
                    class="AddressSelector-item"
                    @address-selected="changeBillingAddress"
                    @show-edit-address-form="showEditAddressForm"
                />
            </ul>
            <alert-message
                v-else-if="getAddresses.length === 0"
                :message="$t('address:missing_billing_address')"
                type="info"
                class="AddressSelector-emptyAddressList"
            />
            <p
                v-if="getAddAddressEnabled"
                class="AddressSelector-addAddress"
            >
                <a
                    class="AddressSelector-addAddressLink"
                    @click="showCreateAddressForm"
                >
                    <span class="AddressList-addAddressIcon IconMore" />
                    {{ $t('address:add_button') }}
                </a>
            </p>
            <div>
                <div
                    v-if="hasDeliveryAddressSelector"
                    class="AddressSelector-deliveryAddressArea"
                >
                    <v-card elevation="0">
                        <v-tabs v-model="selectedTab">
                            <v-tab
                                value="0"
                                @click="setDeliveryMode('POSTAL')"
                            >
                                <v-icon icon="mdi-home" />
                                {{ $t('address:delivery:postal') }}
                            </v-tab>
                            <v-tab
                                v-if="hasClickAndCollectDeliveryMode"
                                value="1"
                                @click="setDeliveryMode('POSTAL_CLICKANDCOLLECT')"
                            >
                                <v-icon icon="mdi-store" />
                                {{ $t('address:delivery:clickandcollect') }}
                            </v-tab>
                        </v-tabs>

                        <v-card-text>
                            <v-tabs-window v-model="selectedTab">
                                <v-tabs-window-item value="0">
                                    <div v-if="isAddressChangeEnabled">
                                        <address-selector-recap-item
                                            v-if="getDeliveryAddress !== null"
                                            :address="getDeliveryAddress"
                                            :showChangeButton="true"
                                            class="AddressSelector-recap"
                                            @choose-another-address-clicked="chooseAnotherDeliveryAddress"
                                            @edit-address-clicked="showEditAddressForm"
                                        />
                                        <ul
                                            v-if="getShowDeliveryAddressList && getAddresses.length > 1"
                                            class="AddressSelector-list"
                                        >
                                            <address-selector-item
                                                v-for="address in getAddresses"
                                                :key="address.id"
                                                :address="address"
                                                :isSelected="getDeliveryAddress !== null && address.id === getDeliveryAddress.id"
                                                :showEditButton="false"
                                                class="AddressSelector-item"
                                                @address-selected="changeDeliveryAddress"
                                                @show-edit-address-form="showEditAddressForm"
                                            />
                                        </ul>
                                    </div>
                                    <div v-else-if="showDeliveryAddressForm">
                                        <address-template-selector
                                            :defaultTemplateCode="getDeliveryTemplateCode"
                                            :templateList="getDeliveryTemplateList"
                                            @template-changed="updateDeliverySelectedTemplate"
                                        />
                                        <vuetify-dynamic-form
                                            v-if="getDeliveryTemplateWithValues.fields.some(field => field.value)"
                                            :is-part-of-form="true"
                                            :template="getDeliveryTemplateWithValues"
                                            :is-pre-filled="true"
                                            class="mt-5"
                                            form-title-class="h4"
                                            @form-updated="changeFieldValue($event)"
                                        />
                                    </div>
                                </v-tabs-window-item>
                                <v-tabs-window-item
                                    v-if="hasClickAndCollectDeliveryMode"
                                    value="1"
                                >
                                    <v-container>
                                        <v-radio-group v-model="selectedItem">
                                            <v-radio
                                                v-for="(shop, index) in getShopAddresses"
                                                :key="index"
                                                :label="null"
                                                :value="shop"
                                                class="pt-3 pb-3 pl-2 pr-2 d-flex align-center"
                                                @click="setClickAndCollect(shop.id)"
                                            >
                                                <template #label>
                                                    <div class="ml-2">
                                                        <div class="font-weight-bold">
                                                            {{ shop.label }}
                                                        </div>
                                                        {{ shop.address.streetName }} {{ shop.address.zipCode }} {{ shop.address.city }}
                                                        <div
                                                            class="font-weight-thin"
                                                            v-html="makeLinkClickable($sanitize(shop.description))"
                                                        />
                                                    </div>
                                                </template>
                                            </v-radio>
                                        </v-radio-group>
                                    </v-container>
                                </v-tabs-window-item>
                            </v-tabs-window>
                        </v-card-text>
                    </v-card>
                </div>
            </div>
        </div>
        <address-create-modal
            v-if="showAddressCreateModal"
            v-model="showAddressCreateModal"
        />
        <address-edit-modal
            v-if="selectedAddressId && showAddressEditModal"
            v-model="showAddressEditModal"
            :address="getSelectedAddress"
            @close-modal="resetSelectedAddressId"
        />
    </div>
</template>

<script setup>
import AddressCreateModal from '@/StoreWeb/components/address/AddressCreateModal'
import AddressEditModal from '@/StoreWeb/components/address/AddressEditModal'
import AddressSelectorItem from '@/StoreWeb/components/address/AddressSelectorItem'
import AddressSelectorRecapItem from '@/StoreWeb/components/address/AddressSelectorRecapItem'
import AddressTemplateSelector from '@/StoreWeb/components/address/AddressTemplateSelector'
import AlertMessage from '@/StoreWeb/components/common/AlertMessage'
import AddressNormalizer from '@/StoreWeb/normalizers/address/AddressNormalizerSingleton'
import AddressSerializer from '@/StoreWeb/serializers/AddressSerializerSingleton'
import { computed, onBeforeMount, onUnmounted, reactive, ref, watch, provide } from 'vue'
import { useStore } from 'vuex'
import { isEmpty } from 'global-utils'
import * as addressActionTypes from '@/StoreWeb/store/modules/address/action-types'
import { useI18n } from 'vue-i18n'
import emitter from 'global-emitter'
import VuetifyDynamicForm from '@/StoreWeb/components/form/VuetifyDynamicForm.vue'

const store = useStore()
const { t } = useI18n()

const selectedTab = ref(0)
const selectedItem = ref(null)
const addressAuthorizations = computed(() => store.state.addressModule.addressAuthorizations)

const isAddressChangeEnabled = computed(() => {
    return addressAuthorizations.value.minNb > 1
})

const props = defineProps({
    showSelectButton: {
        type: Boolean,
        default: false
    },
    isCartConfirmed: {
        type: Boolean,
        default: false
    }
})

provide('handleFormSubmitted', changeFieldValue)

const addressNormalizer = reactive(AddressNormalizer.getInstance())
const addressSerializer = reactive(AddressSerializer.getInstance())
const billingAddressId = ref(null)
const deliveryAddressId = ref(null)
const defaultTemplateCode = ref('USER')
const deliveryTemplateCode = ref('DELIVERY')
const deliveryTemplateWithValues = ref(null)
const deliveryMode = ref('POSTAL')
const deliveryAddressClickAndCollect = ref(null)
const ephemeralDeliveryAddress = ref(null)
const selectedAddressId = ref(null)
const showBillingAddressList = ref(false)
const showDeliveryAddressList = ref(false)
const showAddressCreateModal = ref(false)
const showAddressEditModal = ref(false)
const shopAddresses = ref(null)
const formIsValid = ref(false)

const getAddAddressEnabled = computed(() => store.getters.getAddAddressEnabled)
const getAddresses = computed(() => store.getters.getAddresses)
const getAddressTemplates = computed(() => store.getters.getAddressTemplates)
const getCart = computed(() => store.getters.getCart)
const addressPendingRequests = computed(() => store.state.addressModule.addressPendingRequests)

const emit = defineEmits(['addressChanged', 'addressFormsError'])

const getBillingAddress = computed(() => {
    let billingAddress = null
    if (billingAddressId.value !== null) {
        billingAddress = getAddresses.value.find(address => address.id === billingAddressId.value)
    }
    if (isEmpty(billingAddress) && !isEmpty(getDefaultAddress.value)) {
        billingAddress = getDefaultAddress.value
    }
    if (isEmpty(billingAddress) && !isEmpty(getAddresses.value)) {
        billingAddress = getAddresses.value[0]
    }
    return billingAddress
})

const getDefaultAddress = computed(() => {
    return getAddresses.value.find(address => address.defaultAddress)
})

const getDeliveryAddress = computed(() => {
    if (!isEmpty(ephemeralDeliveryAddress.value)) {
        return ephemeralDeliveryAddress.value
    }
    let deliveryAddress = null
    if (deliveryAddressId.value !== null) {
        deliveryAddress = getAddresses.value.find(address => address.id === deliveryAddressId.value)
    }
    if (isEmpty(deliveryAddress)) {
        if (!isEmpty(getDefaultAddress.value)) {
            deliveryAddress = getDefaultAddress.value
        }
        if (!isEmpty(getAddresses.value)) {
            deliveryAddress = getAddresses.value[0]
        }
    }
    return deliveryAddress
})

const getSelectedAddress = computed(() => {
    let selectedAddress
    if (!isEmpty(getAddresses.value)) {
        selectedAddress = getAddresses.value.find(item => item.id === selectedAddressId.value)
        if (typeof selectedAddress !== 'undefined') {
            return selectedAddress
        }
    }
    return null
})

const getShowBillingAddressList = computed(() => showBillingAddressList.value)
const getShowDeliveryAddressList = computed(() => showDeliveryAddressList.value)
const getDeliveryTemplate = computed(() => {
    let templateToUse = getAddressTemplates.value.find(item => item.code === deliveryTemplateCode.value)
    if (typeof templateToUse === 'undefined') {
        templateToUse = getAddressTemplates.value.find(item => item.code === defaultTemplateCode.value)
    }
    if (typeof templateToUse === 'undefined') {
        templateToUse = getAddressTemplates.value[0]
    }
    return templateToUse
})

const getDeliveryTemplateWithValues = computed(() => {
    if (!isEmpty(deliveryTemplateWithValues.value)) {
        return deliveryTemplateWithValues.value
    }
    return getDeliveryTemplate.value
})

const getDeliveryTemplateCode = computed(() => {
    return deliveryTemplateCode.value.id
})

const getDeliveryTemplateList = computed(() => {
    const templates = []
    getAddressTemplates.value.forEach(template => {
        if (template.minNb === 0 && template.maxNb === 0) {
            templates.push({
                id: template.code,
                label: template.label
            })
        }
    })
    return templates
})

const getShopAddresses = computed(() => {
    return shopAddresses.value
})

const hasDeliveryAddressSelector = computed(() => {
    if (!isEmpty(getCart.value)) {
        return getCart.value.hasPostalDeliveryMode()
    }
    return false
})

const hasClickAndCollectDeliveryMode = computed(() => {
    return getCart.value.hasClickAndCollectDeliveryMode()
})

const showAddressListLoader = computed(() => addressPendingRequests.value.getAddresses)
const showDeliveryAddressForm = computed(() => {
    return !isEmpty(getDeliveryTemplateList.value)
})

watch(() => props.isCartConfirmed, (isCartConfirmed) => {
    if (isCartConfirmed === true) {
        setDeliveryAddress()
    }
})

const deliveryFormFields = computed(() => {
    const fields = {}
    if (!isEmpty(getDeliveryTemplateWithValues.value) && !isEmpty(getDeliveryTemplateWithValues.value.fields)) {
        getDeliveryTemplateWithValues.value.fields.forEach(field => {
            fields[field.id] = field.value
        })
    }
    return reactive(fields)
})

onBeforeMount(async () => {
    emitter.on('addressesLoaded', checkIsEmptyAddresses)
    await store.dispatch(addressActionTypes.GET_ADDRESS_TEMPLATES)
    await store.dispatch(addressActionTypes.GET_ADDRESSES)
    if (hasClickAndCollectDeliveryMode.value) {
        const response = await store.dispatch(addressActionTypes.GET_SHOP_ADDRESSES, {
            id: getCart.value.id
        })
        if (!isEmpty(response.shops)) {
            shopAddresses.value = response.shops
        }
    }
    updateDeliveryAddressArea()
})

onUnmounted(() => {
    emitter.off('addressesLoaded', checkIsEmptyAddresses)
})

function checkIsEmptyAddresses () {
    if (getBillingAddress.value === null || getDeliveryAddress.value === null) {
        emit('addressFormsError', true)
    } else {
        emit('addressFormsError', false)
    }
}

function setDeliveryMode (param) {
    deliveryMode.value = param
    checkFormError()
}

function setClickAndCollect (param) {
    shopAddresses.value.forEach(shopAdress => {
        if (shopAdress.id === param) {
            const shopData = shopAdress
            const addressData = shopData.address
            deliveryAddressClickAndCollect.value = {
                type: 'DELIVERY',
                subtype: 'CLICKANDCOLLECT',
                clickAndCollect: {
                    id: shopData.id,
                    label: shopData.label,
                    description: shopData.description
                },
                streetNumber: '',
                streetType: '',
                streetName: addressData.streetName,
                zipCode: addressData.zipCode,
                city: addressData.city,
                state: '',
                country: ''
            }
        }
    })
    checkFormError()
}

function changeFieldValue ($event) {
    formIsValid.value = $event.isValid
    for (const key in $event.values) {
        if (Object.prototype.hasOwnProperty.call(deliveryFormFields.value, key) && Object.prototype.hasOwnProperty.call($event.values, key)) {
            if (deliveryFormFields.value[key] !== $event.values[key]) {
                deliveryFormFields.value[key] = $event.values[key]
            }
        }
    }
    checkFormError()
}

async function checkFormError () {
    let addressFormsError = !!(hasDeliveryAddressSelector.value)

    switch (deliveryMode.value) {
        case 'POSTAL_CLICKANDCOLLECT':
            if (getBillingAddress.value !== null && deliveryAddressClickAndCollect.value !== null) {
                addressFormsError = false
            }
            break

        case 'POSTAL':
            if (getBillingAddress.value !== null) {
                if (getDeliveryAddress.value !== null && formIsValid.value) {
                    addressFormsError = false
                }
            }
            break
    }

    emit('addressFormsError', addressFormsError)
}

function changeBillingAddress (id) {
    showBillingAddressList.value = false
    billingAddressId.value = id
}

function changeDeliveryAddress (id) {
    showDeliveryAddressList.value = false
    deliveryAddressId.value = id
}

function chooseAnotherBillingAddress () {
    showBillingAddressList.value = true
}

function chooseAnotherDeliveryAddress () {
    showDeliveryAddressList.value = true
}

async function showCreateAddressForm () {
    if (!isEmpty(deliveryTemplateWithValues.value)) {
        await store.dispatch(addressActionTypes.GET_ADDRESS_TEMPLATES)
    }
    showAddressCreateModal.value = true
}

function showEditAddressForm (id) {
    selectedAddressId.value = id
    showAddressEditModal.value = true
}

function hideBillingAddressList (event) {
    if (!event.target.classList.contains('js-AddressSelectorRecapItem-changeButton')) {
        showBillingAddressList.value = false
    }
}

async function setDeliveryAddress () {
    if (hasDeliveryAddressSelector.value) {
        ephemeralDeliveryAddress.value = addressSerializer.serialize(deliveryFormFields.value, getDeliveryTemplate.value)
        ephemeralDeliveryAddress.value = addressNormalizer.normalize(ephemeralDeliveryAddress.value)
        ephemeralDeliveryAddress.value.template = getDeliveryTemplate.value
    }
    if (!hasDeliveryAddressSelector.value || (deliveryMode.value === 'POSTAL' && formIsValid.value) || deliveryMode.value === 'POSTAL_CLICKANDCOLLECT') {
        updateAddress()
    }
}

function updateAddress () {
    emit('addressChanged', {
        billingAddress: getBillingAddress.value,
        deliveryAddress: !getCart.value.hasOnlyCardDistributionDeliveryMode() ? getDeliveryAddress.value : null,
        deliveryClickAndCollectAddress: deliveryMode.value === 'POSTAL_CLICKANDCOLLECT' ? deliveryAddressClickAndCollect.value : null
    })
}

function updateDeliverySelectedTemplate (value) {
    deliveryTemplateCode.value = value
    if (!isEmpty(ephemeralDeliveryAddress.value)) {
        const templateToUse = getDeliveryTemplate.value
        ephemeralDeliveryAddress.value.template = templateToUse
        deliveryTemplateWithValues.value = ephemeralDeliveryAddress.value.getTemplateWithValues(templateToUse)
    }
}

function updateDeliveryAddressArea () {
    if (!isEmpty(getBillingAddress.value)) {
        const templateToUse = getDeliveryTemplate.value
        deliveryTemplateWithValues.value = getBillingAddress.value.getTemplateWithValues(templateToUse)
        checkFormError()
    }
}

function makeLinkClickable (text) {
    const urlRegex = /(https?:\/\/\S+)/g
    return text.replace(urlRegex, function (url) {
        return `${url}
            <i aria-label="${t('open_new_tab')}" aria-hidden="false" class="mdi mdi-open-in-new theme--light primary--text" style="font-size: medium;"/>`
    })
}

function resetSelectedAddressId () {
    selectedAddressId.value = null
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.AddressSelector {
    &-recap {
        margin: 0 0 25px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-list {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &-item {
        margin-bottom: 10px;
    }

    &-emptyAddressList {
        margin-bottom: 20px;
    }

    &-addAddress {
        margin: 0 0 15px;
        font-size: 14px;
        font-weight: $fontWeight-defaultBold;

        &Link {
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            color: $color-brandPrimary;
            transition: color .3s;

            &:hover,
            &:active {
                color: $color-brandPrimaryDark;
            }
        }

        &Icon {
            font-size: 24px;
        }
    }

    &-sameAddressesToggle {
        margin-left: 10px;
    }

    &-deliveryAddressArea {
        padding-top: 20px;
    }
}
</style>
