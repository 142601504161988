<template>
    <ul
        :class="[displayStyle !== '' ? 'NotificationList--' + displayStyle : '']"
        class="NotificationList"
    >
        <notification-item
            v-for="notificationItem in getNotifications"
            v-bind="notificationItem"
            :key="notificationItem.id"
            class="NotificationList-item"
        />
        <li
            v-if="getIsSeeAll"
            class="NotificationList-item NotificationList-item--seeAll"
        >
            <router-link
                :to="{ name: 'notifications' }"
                class="NotificationList-seeAllLink"
            >
                {{ $t('account:notifications:see_all') }}
            </router-link>
        </li>
    </ul>
</template>

<script>
import { mapState } from 'vuex'
import { isEmpty } from 'global-utils'
import NotificationItem from './NotificationItem'

export default {
    components: {
        NotificationItem
    },
    props: {
        limit: {
            type: Number,
            default: 0
        },
        displayStyle: {
            type: String,
            default: '',
            validator: (val) => ['', 'card'].includes(val)
        }
    },
    computed: {
        ...mapState({
            notifications: state => state.userModule.notifications
        }),
        getNotifications () {
            if (!isEmpty(this.notifications.items) && this.limit > 0) {
                return this.notifications.items.slice(0, this.limit)
            }
            return this.notifications.items
        },
        getIsSeeAll () {
            return this.limit !== 0 && !isEmpty(this.notifications.items) && this.notifications.items.length > this.limit
        }
    }
}
</script>
<style lang="scss" scoped>
@import 'globalScss';

.NotificationList {
    margin: 0;
    padding: 0;
    list-style: none;

    &-item {
        &:first-child {
            border: none;
        }

        &--seeAll {
            border-top: 1px solid $color-lightgray4;
            background: $color-lightest;
        }
    }

    &-seeAllLink {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
        font-size: 14px;
        font-weight: $fontWeight-defaultBold;
    }

    &--card {
        @extend %Card;

        .NotificationList-item {
            &:first-child {
                border-radius: $border-radius-small $border-radius-small 0 0;
            }

            &:last-child {
                border-radius: 0 0 $border-radius-small $border-radius-small;
            }
        }
    }
}
</style>
