import i18n from 'i18n'

export default class {
    constructor (id) {
        this._endDate = ''
        this._id = id
        this._name = ''
        this._recondition = false
        this._startDate = ''
        this._status = ''
        this._version = 1
    }

    get endDate () {
        return this._endDate
    }

    set endDate (endDate) {
        this._endDate = endDate
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get name () {
        return this._name
    }

    set name (name) {
        this._name = name
    }

    get recondition () {
        return this._recondition
    }

    set recondition (recondition) {
        this._recondition = recondition
    }

    get startDate () {
        return this._startDate
    }

    set startDate (startDate) {
        this._startDate = startDate
    }

    get status () {
        return this._status
    }

    set status (status) {
        this._status = status
    }

    get version () {
        return this._version
    }

    set version (version) {
        this._version = version
    }

    getStatusLabel () {
        const statusKey = 'fare_media:status:' + this.status.toLowerCase()
        const statusMessage = i18n.global.t('fare_media:status:' + this.status.toLowerCase())
        if (statusKey === statusMessage) {
            return ''
        }
        return statusMessage
    }

    toJSON () {
        return {
            endDate: this.endDate,
            id: this.id,
            name: this.name,
            recondition: this.recondition,
            startDate: this.startDate,
            status: this.status,
            version: this.version
        }
    }
}
