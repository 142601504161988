export default class {
    constructor () {
        this._id = ''
        this._amount = null
        this._bankDebitId = 'XXXXXXX'
        this._date = ''
        this._effectiveDate = ''
        this._productLabel = ''
        this._providerFareMediaId = ''
        this._providerId = ''
        this._status = null
        this._supportOwnerName = ''
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get amount () {
        return this._amount
    }

    set amount (amount) {
        this._amount = amount
    }

    get bankDebitId () {
        return this._bankDebitId
    }

    set bankDebitId (bankDebitId) {
        this._bankDebitId = bankDebitId
    }

    get date () {
        return this._date
    }

    set date (date) {
        this._date = date
    }

    get effectiveDate () {
        return this._effectiveDate
    }

    set effectiveDate (effectiveDate) {
        this._effectiveDate = effectiveDate
    }

    get productLabel () {
        return this._productLabel
    }

    set productLabel (productLabel) {
        this._productLabel = productLabel
    }

    get providerFareMediaId () {
        return this._providerFareMediaId
    }

    set providerFareMediaId (providerFareMediaId) {
        this._providerFareMediaId = providerFareMediaId
    }

    get providerId () {
        return this._providerId
    }

    set providerId (providerId) {
        this._providerId = providerId
    }

    get status () {
        return this._status
    }

    set status (status) {
        this._status = status
    }

    get supportOwnerName () {
        return this._supportOwnerName
    }

    set supportOwnerName (supportOwnerName) {
        this._supportOwnerName = supportOwnerName
    }

    toJSON () {
        return {
            id: this.id,
            amount: this.amount,
            bankDebitId: this.bankDebitId,
            date: this.date,
            effectiveDate: this.effectiveDate,
            productLabel: this.productLabel,
            providerFareMediaId: this.providerFareMediaId,
            providerId: this.providerId,
            status: this.status,
            supportOwnerName: this.supportOwnerName
        }
    }
}
