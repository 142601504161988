import CatalogManager from './CatalogManager'

const CatalogManagerSingleton = (function () {
    let instance

    function createInstance () {
        const classObj = new CatalogManager()
        return classObj
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance()
            }
            return instance
        }
    }
})()

export default CatalogManagerSingleton
