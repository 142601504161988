import { isEmpty } from 'global-utils'

export const defectiveStatuses = [
    'PROVIDERS_TOTAL_CREATION_FAILED',
    'PROVIDERS_TOTAL_CANCELED'
]

export default class {
    constructor () {
        this._actions = []
        this._baskets = []
        this._empty = true
        this._id = null
        this._packages = []
        this._plans = []
        this._status = null
        this._immediateAmountExcludingTaxes = null
        this._immediateAmountIncludingTaxes = null
        this._immediateVatAmount = null
        this._totalAmountExcludingTaxes = 0
        this._totalAmountIncludingTaxes = 0
        this._totalVatAmount = 0
        this._userId = null
    }

    static notEmptyAuthorizedCartStatuses = [
        'PROVIDERS_TOTAL_CREATED',
        'PROVIDERS_TOTAL_CONFIRMED',
        'PROVIDERS_TOTAL_CANCELED',
        'PAYMENT_PROCESSING',
        'PAYMENT_CONFIRMED',
        'PAYMENT_DECLINED',
        'PAYMENT_CONFIRMED',
        'VALIDATED',
        'FINALIZED'
    ]

    static emptyAuthorizedCartStatuses = [
        'INITIALIZED'
    ]

    static get defectiveStatuses () {
        return defectiveStatuses
    }

    get actions () {
        return this._actions
    }

    set actions (actions) {
        this._actions = actions
    }

    get baskets () {
        return this._baskets
    }

    set baskets (baskets) {
        this._baskets = baskets
    }

    get empty () {
        return this._empty
    }

    set empty (empty) {
        this._empty = empty
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get packages () {
        return this._packages
    }

    set packages (packages) {
        this._packages = packages
    }

    get plans () {
        return this._plans
    }

    set plans (plans) {
        this._plans = plans
    }

    get status () {
        return this._status
    }

    set status (status) {
        this._status = status
    }

    get totalAmountExcludingTaxes () {
        return this._totalAmountExcludingTaxes
    }

    set totalAmountExcludingTaxes (totalAmountExcludingTaxes) {
        this._totalAmountExcludingTaxes = totalAmountExcludingTaxes
    }

    get totalAmountIncludingTaxes () {
        return this._totalAmountIncludingTaxes
    }

    set totalAmountIncludingTaxes (totalAmountIncludingTaxes) {
        this._totalAmountIncludingTaxes = totalAmountIncludingTaxes
    }

    get totalVatAmount () {
        return this._totalVatAmount
    }

    set totalVatAmount (totalVatAmount) {
        this._totalVatAmount = totalVatAmount
    }

    get immediateAmountExcludingTaxes () {
        return this._immediateAmountExcludingTaxes
    }

    set immediateAmountExcludingTaxes (immediateAmountExcludingTaxes) {
        this._immediateAmountExcludingTaxes = immediateAmountExcludingTaxes
    }

    get immediateAmountIncludingTaxes () {
        return this._immediateAmountIncludingTaxes
    }

    set immediateAmountIncludingTaxes (immediateAmountIncludingTaxes) {
        this._immediateAmountIncludingTaxes = immediateAmountIncludingTaxes
    }

    get immediateVatAmount () {
        return this._immediateVatAmount
    }

    set immediateVatAmount (immediateVatAmount) {
        this._immediateVatAmount = immediateVatAmount
    }

    get userId () {
        return this._userId
    }

    set userId (userId) {
        this._userId = userId
    }

    hasPostalDeliveryMode () {
        let hasPostalDeliveryMode = false
        this.baskets.forEach(basket => {
            if (basket.basket.deliveryMode === 'POSTAL' || basket.basket.deliveryMode === 'POSTAL_CLICKANDCOLLECT') {
                hasPostalDeliveryMode = true
            }
        })

        return hasPostalDeliveryMode
    }

    hasClickAndCollectDeliveryMode () {
        return this.getDeliveryModes().includes('POSTAL_CLICKANDCOLLECT')
    }

    hasOnlyCardDistributionDeliveryMode () {
        return this.getDeliveryModes().every(deliveryMode => deliveryMode === 'DISTRIBUTION')
    }

    getDeliveryModes () {
        return this.baskets.map(basket => basket.basket.deliveryMode)
    }

    hasDistributionCardReader () {
        if (!isEmpty(this.baskets)) {
            for (const basket of this.baskets) {
                if (basket.basket.deliveryMode === 'DISTRIBUTION_CARDREADER') {
                    return true
                }
            }
        }
        return false
    }

    toJSON () {
        return {
            actions: this.actions,
            baskets: this.baskets,
            empty: this.empty,
            id: this.id,
            packages: this.packages,
            plans: this.plans,
            status: this.status,
            totalAmountExcludingTaxes: this.totalAmountExcludingTaxes,
            totalAmountIncludingTaxes: this.totalAmountIncludingTaxes,
            totalVatAmount: this.totalVatAmount,
            userId: this.userId
        }
    }
}
