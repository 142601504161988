export default class {
    constructor () {
        this._productParameterId = ''
        this._code = ''
        this._value = ''
    }

    get productParameterId () {
        return this._productParameterId
    }

    set productParameterId (productParameterId) {
        this._productParameterId = productParameterId
    }

    get code () {
        return this._code
    }

    set code (code) {
        this._code = code
    }

    get value () {
        return this._value
    }

    set value (value) {
        this._value = value
    }

    toJSON () {
        return {
            productParameterId: this.productParameterId,
            code: this.code,
            value: this.value
        }
    }
}
