export default class {
    constructor (id) {
        this._creationDate = ''
        this._external = true
        this._id = id
        this._status = ''
        this._updateDate = ''
        this._providerId = ''
    }

    get creationDate () {
        return this._creationDate
    }

    set creationDate (creationDate) {
        this._creationDate = creationDate
    }

    get external () {
        return this._external
    }

    set external (external) {
        this._external = external
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get status () {
        return this._status
    }

    set status (status) {
        this._status = status
    }

    get updateDate () {
        return this._updateDate
    }

    set updateDate (updateDate) {
        this._updateDate = updateDate
    }

    get providerId () {
        return this._providerId
    }

    set providerId (providerId) {
        this._providerId = providerId
    }

    toJSON () {
        return {
            creationDate: this.creationDate,
            external: this.external,
            id: this.id,
            status: this.status,
            updateDate: this.updateDate
        }
    }
}
