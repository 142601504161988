import i18n from 'i18n'
import emitter from 'global-emitter'

export default class ErrorModalHandler {
    /**
     * @param {string} key - The localization key prefix for error messages.
     * @param {Object} error - The error object to display.
     * @param {boolean} onlyMessageFromApi - If true, does not include external messages.
     * @param {boolean} withCrossIcon - If true, includes a cross icon on the modal.
     */
    static displayErrorModal (key, error, onlyMessageFromApi = false, withCrossIcon = true) {
        this.normalizeErrorData(error)

        const { data = {} } = error.response || {}
        const params = {
            debugObject: data,
            title: i18n.global.t(`${key}:error:default:title`),
            messageHTML: data.message || '',
            crossIcon: withCrossIcon,
            status: this.getStatus(data, error),
            externalMessage: onlyMessageFromApi ? undefined : data.externalMessage
        }

        emitter.emit('showAlertModal', params)
    }

    /**
     * @param {Object} error - The error object to normalize.
     */
    static normalizeErrorData (error) {
        if (error.response && error.response.data instanceof ArrayBuffer) {
            const decoder = new TextDecoder('utf-8')
            try {
                error.response.data = JSON.parse(decoder.decode(new Uint8Array(error.response.data)))
            } catch (e) {
                error.response.data = { message: i18n.global.t('error:default:message') }
            }
        }
    }

    /**
     * @param {Object} data - The error data object.
     * @param {Object} error - The full error object.
     * @return {string|number} The status code or a default status identifier.
     */
    static getStatus (data, error) {
        return !isNaN(data.status)
            ? data.status
            : !isNaN(error.response?.status)
                ? error.response.status
                : !isNaN(error.error)
                    ? error.error
                    : 'default'
    }
}
