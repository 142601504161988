<template>
    <component
        :is="type"
        class="AuthenticationModeItem"
    >
        <a
            :class="isCurrent ? 'AuthenticationModeItem-link--current' : ''"
            class="AuthenticationModeItem-link"
            @click="selectAuthenticationMode"
        >
            <span class="AuthenticationModeItem-iconWrapper">
                <span
                    :class="'Icon' + icon"
                    class="AuthenticationModeItem-icon"
                >
                    <span class="path1" />
                    <span class="path2" />
                    <span class="path3" />
                </span>
            </span>
            <span class="AuthenticationModeItem-label">
                <strong class="AuthenticationModeItem-title">{{ title }}</strong>
                <span class="AuthenticationModeItem-subtitle">{{ subtitle }}</span>
            </span>
        </a>
    </component>
</template>

<script>
export default {
    props: {
        isCurrent: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: true
        },
        subtitle: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: 'li'
        }
    },
    methods: {
        selectAuthenticationMode () {
            this.$emit('authenticationModeClicked', this.id)
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.AuthenticationModeItem {
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 0;
    }

    &-link {
        display: flex;
        padding: 10px 10px 14px;
        border: 2px solid $color-lightgray4;
        border-radius: 6px;
        box-shadow: $box-shadow-light;
        background: $color-lightest;
        cursor: pointer;
        color: $color-lighterText;
        transition: background .3s;

        &:hover,
        &:active,
        &--current {
            background: $color-lightgray3;
        }
    }

    &:first-child {
        padding-top: 0;
        border-top: none;
    }

    &-iconWrapper {
        position: relative;
        width: 32px;
    }

    &-icon {
        position: absolute;
        font-size: 30px;
    }

    .Icon {
        &FareMediaIllust {
            top: 0;
            left: -2px;

            .path3 {
                color: $color-brandPrimary;
            }
        }

        &Card {
            top: -3px;
            left: -2px;
            color: $color-brandPrimary;
        }

        &Phone {
            top: 0;
            left: -2px;
        }
    }

    &-label {
        flex-grow: 1;
    }

    &-title {
        display: block;
        font-family: $fontFamily-title;
        font-size: 18px;
        font-weight: $fontWeight-titleBold;
        color: $color-defaultText;
    }

    &-subtitle {
        font-size: 12px;
        font-weight: $fontWeight-defaultMedium;
    }
}
</style>
