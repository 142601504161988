import store from '@/StoreWeb/store/store'
import * as mutationTypes from '@/StoreWeb/store/modules/main/mutation-types'

export const getApplicationSessionIdFromLocalStorage = () => {
    return localStorage.getItem('applicationSessionId') || false
}

export const checkAndUpdateIsMobileApp = () => {
    const isMobileAppContext = localStorage.getItem('isMobileAppContext')

    if (isMobileAppContext === '1') {
        store.commit(mutationTypes.SET_IS_MOBILE_APP, true)

        return true
    }

    return false
}
