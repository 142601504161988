import FareMediaProduct from '@/StoreWeb/models/user/wallet/FareMediaProduct'
import UserActionNormalizer from '@/StoreWeb/normalizers/common/UserActionNormalizerSingleton'
import { isEmpty } from 'global-utils'

export default class {
    constructor () {
        this._userActionNormalizer = UserActionNormalizer.getInstance()
    }

    get userActionNormalizer () {
        return this._userActionNormalizer
    }

    normalize (item, params) {
        const fareMediaProduct = new FareMediaProduct(item.id, params.providerId)

        if (!isEmpty(params.accountId)) {
            fareMediaProduct.accountId = params.accountId
        }
        if (!isEmpty(params.providerUserExternalId)) {
            fareMediaProduct.providerUserExternalId = params.providerUserExternalId
        }
        if (!isEmpty(params.fareMediaId)) {
            fareMediaProduct.fareMediaId = params.fareMediaId
        }
        if (!isEmpty(item.actions)) {
            fareMediaProduct.actions = this.userActionNormalizer.normalizeArray(item.actions)
        }
        if (!isEmpty(item.description)) {
            fareMediaProduct.description = item.description
        }
        if (!isEmpty(item.endDate)) {
            fareMediaProduct.endDate = item.endDate
        }
        fareMediaProduct.label = item.label
        if (!isEmpty(item.product)) {
            fareMediaProduct.product = item.product
        }
        if (!isEmpty(item.providerNetworkLabel)) {
            fareMediaProduct.providerNetworkLabel = item.providerNetworkLabel
        }
        if (!isEmpty(item.remainingTickets)) {
            fareMediaProduct.remainingTickets = item.remainingTickets
        }
        if (!isEmpty(item.serialNumber)) {
            fareMediaProduct.serialNumber = item.serialNumber
        }
        if (!isEmpty(item.startDate)) {
            fareMediaProduct.startDate = item.startDate
        }
        if (!isEmpty(item.renewableProducts)) {
            fareMediaProduct.renewableProducts = item.renewableProducts
        }
        fareMediaProduct.status = item.status
        fareMediaProduct.type = item.type

        return fareMediaProduct
    }

    normalizeArray (items, params) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item, params))
            })
        }

        return normalizedItems
    }
}
