<template>
    <form class="CardIdForm">
        <h1
            v-if="false"
            class="h1"
        >
            {{ $t('form:card_holder_infos:title') }}
        </h1>
        <p v-if="false">
            {{ $t('form:card_holder_infos:subtitle') }}
        </p>
        <labelled-input
            :defaultValue="getCardId"
            :inputType="'text'"
            :label="$t('form:user_infos:cardId:label')"
            class="CardIdForm-field CardIdForm-cardIdField"
            @field-value-changed="updateCardId"
        />
    </form>
</template>

<script>
import LabelledInput from '@/StoreWeb/components/common/LabelledInput'
import { isEmpty } from 'global-utils'

export default {
    components: {
        LabelledInput
    },
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            cardId: ''
        }
    },
    computed: {
        getCardId () {
            return this.cardId
        }
    },
    mounted () {
        if (
            !isEmpty(this.addToCartFlowManager.currentStep) &&
            !isEmpty(this.addToCartFlowManager.currentStep.productParameterValues)
        ) {
            const cardId = this.addToCartFlowManager.currentStep.productParameterValues.find(productParameter => productParameter.productParameter === 'CARDID')
            if (!isEmpty(cardId) && !isEmpty(cardId.value)) {
                this.updateCardId(cardId.value)
            }
        }
    },
    methods: {
        checkFormError () {
            return (
                this.cardId !== ''
            )
        },
        updateCardId (value) {
            this.cardId = value
            this.updateProductParameter()
        },
        updateProductParameter () {
            this.$emit('updateCardIdForm', {
                id: 'CARDID',
                isProductParameterValid: this.checkFormError(),
                productParameterValues: [
                    {
                        productParameter: 'CARDID',
                        code: 'VALUE',
                        value: this.cardId
                    }
                ],
                isQuotationRequired: false
            })
        }
    }
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

.CardIdForm {
    &-field {
        margin-bottom: 15px;
    }

    &-zipCodeField {
        flex-basis: 45%;
    }

    &-streetNumberSuffixField {
        flex-grow: 1;
    }
}
</style>
