import RecipientUser from '@/StoreWeb/models/user/RecipientUser'
import { isEmpty } from 'global-utils'
import AssociationType from '@/StoreWeb/models/user/AssociationType'

export default class {
    normalize (item) {
        const recipientUser = new RecipientUser()

        if (!isEmpty(item.associationId)) {
            recipientUser.associationId = item.associationId
        } else if (!isEmpty(item.id)) {
            recipientUser.associationId = item.id
        }

        if (item?.type?.id) {
            recipientUser.associationType = new AssociationType(item.type.id)

            recipientUser.associationType.fromName = item.type?.fromName ?? ''
            recipientUser.associationType.interfaceName = item.type?.interfaceName ?? ''
            recipientUser.associationType.isExternal = item.type?.isExternal ?? false
            recipientUser.associationType.modes = item.type?.modes ?? []
            recipientUser.associationType.name = item.type?.name ?? ''
            recipientUser.associationType.toName = item.type?.toName ?? ''
        }

        recipientUser.email = item.email
        recipientUser.firstName = item.firstName
        recipientUser.isMaster = item.isMaster ?? true
        recipientUser.lastName = item.lastName
        recipientUser.userId = item.userId

        return recipientUser
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
