<template>
    <component
        :is="'li'"
        class="BankAccountItem"
    >
        <div class="BankAccountItem-Icon">
            <span class="IconBank--outlined" />
        </div>
        <div class="BankAccountItem-container">
            <span class="BankAccountItem-name">{{ bankAccount.bankName }}</span>
            <v-row
                class="BankAccountItem-content"
                align="center"
                no-gutters
            >
                <v-col
                    v-if="debugInformationsDisplay > 1 && bankAccount.providerBankAccountIdentifier !== ''"
                    cols="4"
                    class="BankAccountItem-description"
                >
                    <dl>
                        <dt>Id</dt>
                        <dd>{{ bankAccount.providerBankAccountIdentifier }}</dd>
                    </dl>
                </v-col>
                <v-col
                    v-if="getIBAN !== ''"
                    cols="4"
                    class="BankAccountItem-description"
                >
                    <dl>
                        <dt>{{ $t('bank_account:iban') }}</dt>
                        <dd>{{ getIBAN }}</dd>
                    </dl>
                </v-col>
                <v-col
                    v-if="getBIC !== ''"
                    cols="4"
                    class="BankAccountItem-description"
                >
                    <dl>
                        <dt>{{ $t('bank_account:bic') }}</dt>
                        <dd>{{ getBIC }}</dd>
                    </dl>
                </v-col>
                <v-col
                    v-if="getMandates.length"
                    cols="4"
                    class="BankAccountItem-mandates"
                >
                    <template
                        v-for="sepaMandate in getMandates"
                        :key="sepaMandate.uniqueMandateReference"
                    >
                        <div
                            v-if="sepaMandate.uniqueMandateReference !== null && sepaMandate.status === 'A'"
                            class="d-flex justify-end"
                        >
                            <v-btn
                                :aria-label="$t('sepa_mandate:download_button:label') + ' ' + $t('open_new_tab')"
                                :title="$t('sepa_mandate:download_button:label') + ' ' + $t('open_new_tab')"
                                class="BankAccountItem-downloadMandateButton"
                                variant="text"
                                @click="downloadMandate(sepaMandate.uniqueMandateReference)"
                            >
                                <v-icon
                                    :aria-label="$t('Download')"
                                    :aria-hidden="false"
                                    color="primary"
                                    icon="mdi-download"
                                />
                            </v-btn>
                        </div>
                    </template>
                </v-col>
            </v-row>
        </div>
        <dropdown-menu
            v-if="getActions.length > 0"
            class="BankAccountItem-dropdownMenu"
            padding-top="0"
            padding-bottom="0"
        >
            <ul class="DropdownMenu-list">
                <li
                    v-for="action in getActions"
                    :key="action.type"
                    class="DropdownMenu-item"
                >
                    <v-btn
                        variant="text"
                        class="DropdownMenu-listItemButton justify-start w-100 rounded-0"
                        color="primary"
                        size="small"
                        @click.prevent="executeAction(action)"
                    >
                        {{ action.label }}
                    </v-btn>
                </li>
            </ul>
        </dropdown-menu>
    </component>
</template>

<script setup>
import { isEmpty } from 'global-utils'
import * as paymentActionTypes from '@/StoreWeb/store/modules/payment/action-types'
import { computed } from 'vue'
import { useStore } from 'vuex'
import DropdownMenu from '@/StoreWeb/components/common/dropdown-menu/DropdownMenu'

const store = useStore()

const emit = defineEmits(['editBankAccount', 'signMandate'])

const props = defineProps({
    bankAccount: {
        type: Object,
        required: true
    },
    provider: {
        type: String,
        required: true
    }
})

const debugInformationsDisplay = computed(() => store.state.debuggingModule.debugInformationsDisplay)
const getBIC = computed(() => props.bankAccount.bic)
const getIBAN = computed(() => props.bankAccount.iban)
const getMandates = computed(() => !isEmpty(props.bankAccount.mandates) ? props.bankAccount.mandates : [])
const getActions = computed(() => {
    const actions = props.bankAccount.actions
    return !isEmpty(actions) ? actions : []
})

const downloadMandate = (uniqueMandateReference) => {
    store.dispatch(paymentActionTypes.GET_MANDATE_BINARY, {
        providerId: props.provider,
        rum: uniqueMandateReference
    })
}

function executeAction (action) {
    if (action.type === 'UPDATE_BANK_INFORMATION') {
        editBankAccount()
    }
    if (action.type === 'SIGN_MANDATE') {
        signMandate()
    }
}

function editBankAccount () {
    emit('editBankAccount', props.bankAccount)
}

function signMandate () {
    emit('signMandate', props.bankAccount)
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.BankAccountItem {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 15px;
    padding: 0 0 10px 10px;
    border-radius: $border-radius-xsmall;
    box-shadow: $box-shadow-light;
    background: $color-lightest;
    color: $color-lighterText;

    &-content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @media (max-width: $tablet-breakpoint) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &-container {
        flex: 1;
    }

    &-name {
        display: block;
        margin: 0 0 5px 10px;
        padding-top: 10px;
        font-size: .8em;
        font-weight: 500;
        color: $color-lightText;

        @media (max-width: $tablet-breakpoint) {
            margin-bottom: 10px;
        }
    }

    @media (max-width: $tablet-breakpoint) {
        &-Icon {
            display: none;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }

    &-Icon {
        margin-right: 0;
        font-size: 2.2rem;
        line-height: 0;
    }

    dl {
        flex: 1;
        margin-left: 10px;

        @media (max-width: $tablet-breakpoint) {
            margin-bottom: 10px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    dt {
        margin-bottom: 2px;
        font-size: 12px;
        color: $color-lighterText;

        &.BankAccountItem-name {
            font-weight: bold;
        }
    }

    dd {
        margin: 0;
        padding: 0;
        font-size: .75rem;
        font-weight: 500;
        color: $color-lightText;
    }

    &-mandatesTitle {
        font-size: 14px;
        font-weight: $fontWeight-defaultMedium;
    }

    &-downloadMandateButton {
        display: block;
        padding-right: 15px;
        font-size: 1.5rem;
        color: $primary_color;

        @media (max-width: $tablet-breakpoint) {
            margin-left: 5px;
        }
    }

    .BankAccountItem-downloadMandateButtonIcon {
        vertical-align: text-top;
    }

    &-dropdownMenu {
        align-self: flex-start;
        padding: 5px 5px 0 0;
    }
}
</style>
