import { isEmpty } from 'global-utils'

export default class {
    serialize (session) {
        const serializedSession = { ...session }

        if (!isEmpty(session.data)) {
            serializedSession.data = {}

            for (const [key, value] of Object.entries(session.data)) {
                serializedSession.data[key] = this.serializeValue(value)
            }
        }

        if (!isEmpty(session.id)) {
            serializedSession.id = session.id
        }

        if (!isEmpty(session.sessionContext)) {
            serializedSession.sessionContext = {}

            for (const [key, value] of Object.entries(session.sessionContext)) {
                serializedSession.sessionContext[key] = encodeURI(value)
            }
        }

        return serializedSession
    }

    serializeValue (value) {
        return JSON.stringify(value)
    }
}
