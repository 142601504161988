<template>
    <div class="FinalizedOrder">
        <template v-if="isLoadingFinalizedCart">
            <v-progress-circular
                class="mb-5"
                color="primary"
                indeterminate
            />
            <v-alert
                class="text-left"
                density="compact"
                text
                type="info"
                variant="tonal"
            >
                {{ $t('payment:success:waiting_for_finalization') }}
            </v-alert>
        </template>
        <template v-else-if="isFinalizedCartUnfinalized">
            <h1 class="FinalizedOrder-title fadeInAfterIcon">
                {{ $t('payment:success:can_not_retrieve_finalized_cart:title') }}
            </h1>
            <svg
                class="FinalizedOrder-illust FinalizedOrder-illust--error"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 130.2 130.2"
            >
                <circle
                    class="path circle"
                    fill="none"
                    stroke-width="6"
                    stroke-miterlimit="10"
                    cx="65.1"
                    cy="65.1"
                    r="62.1"
                />
                <line
                    class="path line"
                    fill="none"
                    stroke-width="6"
                    stroke-linecap="round"
                    stroke-miterlimit="10"
                    x1="34.4"
                    y1="37.9"
                    x2="95.8"
                    y2="92.3"
                />
                <line
                    class="path line"
                    fill="none"
                    stroke-width="6"
                    stroke-linecap="round"
                    stroke-miterlimit="10"
                    x1="95.8"
                    y1="38"
                    x2="34.4"
                    y2="92.2"
                />
            </svg>
            <v-alert
                class="text-left"
                density="compact"
                text
                type="error"
                variant="tonal"
            >
                <div
                    v-html="$sanitize($t('cart:get_last_confirmed_cart:error:unable_to_confirm:message'))"
                />
            </v-alert>
        </template>
        <payment-success-with-delivery-modes
            v-else
            :id="lastConfirmedCart?.id"
            :baskets="!isEmpty(lastConfirmedCart) ? lastConfirmedCart.baskets : []"
            :title
        />
        <div
            v-if="debugInformationsDisplay > 1 && isEmpty(lastConfirmedCart) && !isEmpty(lastConfirmedCartDebugData)"
            class="mt-8 text-left"
        >
            <p>Dernière réponse du panier finalisé (visible uniquement en debugInformationsDisplay "Avancé" et supérieur) :</p>
            <pre
                style="overflow: auto; padding: 8px; background-color: #f0f0f0; font-size: 12px;"
                v-html="JSON.stringify(lastConfirmedCartDebugData, null, 4)"
            />
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useStore } from 'vuex'
import * as applicationSessionActionTypes from '@/StoreWeb/store/modules/application-session/action-types'
import * as userActionTypes from '@/StoreWeb/store/modules/user/action-types'
import * as cartActionTypes from '@/StoreWeb/store/modules/cart/action-types'
import * as cartMutationTypes from '@/StoreWeb/store/modules/cart/mutation-types'
import CartNormalizer from '@/StoreWeb/normalizers/cart/CartNormalizer'
import PaymentSuccessWithDeliveryModes from '@/StoreWeb/components/payment/PaymentSuccessWithDeliveryModes'
import { isEmpty } from 'global-utils'
import { getApplicationSessionIdFromLocalStorage } from '@/StoreWeb/js/mixins/application-session-utils'
import { useMatomoEventTracker } from '@/StoreWeb/js/composables/matomo'
import { useLogin } from '@/StoreWeb/js/composables/login-utils'
import { getSelectedFareMediaCookie } from '@/StoreWeb/js/mixins/wallet-utils'
import { loadCart, removeTemporarySelectedFareMedia } from '@/StoreWeb/js/composables/cart-utils'

const store = useStore()
const { isAuthenticated } = useLogin()
const { trackMatomoEvents, clearMatomoEventTracker, mapOrderToMatomoEvent, mapProductToMatomoEvent } = useMatomoEventTracker()

defineProps({
    title: {
        type: String,
        required: true
    }
})

const lastConfirmedCartData = ref(null)
const lastConfirmedCartDebugData = ref(null)
const shouldShowUseSupportReader = ref(false)
const hasSentTrackerEvent = ref(false)
const getFinalizedCartRetryCounter = ref(0)
const getFinalizedCartRetryCounterMax = ref(7)
const isFinalizedCartUnfinalized = ref(false)
const isLoadingFinalizedCart = ref(true)

const applicationSession = computed(() => store.state.applicationSessionModule.applicationSession)
const debugInformationsDisplay = computed(() => store.state.debuggingModule.debugInformationsDisplay)
const lastConfirmedCart = computed(() => lastConfirmedCartData.value)

async function getLastConfirmedCart (lastConfirmedCartInfos) {
    const lastConfirmedCartResponse = await store.dispatch(cartActionTypes.GET_FINALIZED_CART, lastConfirmedCartInfos)

    if (lastConfirmedCartResponse?.id) {
        if (checkIfCartIsFinalized(lastConfirmedCartResponse)) {
            isLoadingFinalizedCart.value = false
            lastConfirmedCartData.value = CartNormalizer.normalizeCart(lastConfirmedCartResponse)

            if (lastConfirmedCartData.value.hasDistributionCardReader()) {
                shouldShowUseSupportReader.value = true
            }

            const selectedFareMedia = getSelectedFareMediaCookie()

            if (selectedFareMedia && selectedFareMedia.isTemporary) {
                removeTemporarySelectedFareMedia()
            }

            await loadCart()

            emitPaymentSuccessEvent()
        } else if (getFinalizedCartRetryCounter.value < getFinalizedCartRetryCounterMax.value) {
            const duration = 2 ** getFinalizedCartRetryCounter.value * 1000

            setTimeout(() => {
                getFinalizedCartRetryCounter.value++
                getLastConfirmedCart(lastConfirmedCartInfos)
            }, duration)

            if (debugInformationsDisplay.value > 1) {
                lastConfirmedCartDebugData.value = lastConfirmedCartResponse
            }
        } else {
            isLoadingFinalizedCart.value = false
            isFinalizedCartUnfinalized.value = true
        }
    }
}

function checkIfCartIsFinalized (cart) {
    return ['PAYMENT_CONFIRMED', 'VALIDATED', 'FINALIZED'].includes(cart?.status)
}

const emitPaymentSuccessEvent = () => {
    if (hasSentTrackerEvent.value) {
        return
    }

    const productsTrackingEvents = lastConfirmedCartData.value.baskets.reduce((acc, { basket }) => {
        basket.products.forEach((product) => {
            acc.push(mapProductToMatomoEvent(product))
        })
        return acc
    }, [])

    const orderTrackingEvent = mapOrderToMatomoEvent(lastConfirmedCart.value)

    trackMatomoEvents([
        ...productsTrackingEvents,
        orderTrackingEvent
    ])

    hasSentTrackerEvent.value = true
}

onMounted(async () => {
    const applicationSessionId = getApplicationSessionIdFromLocalStorage()

    if (applicationSessionId && isEmpty(applicationSession.value)) {
        await store.dispatch(applicationSessionActionTypes.READ_APPLICATION_SESSION, {
            id: applicationSessionId
        })
    }

    if (isAuthenticated.value) {
        store.dispatch(userActionTypes.GET_WALLET, {
            wsOptions: {
                cacheAsync: false,
                cacheValidity: 0
            },
            cachiosOptions: {
                force: true
            }
        })
    } else {
        store.commit(cartMutationTypes.SET_IS_GUEST_PURCHASE_FLOW, false)
    }

    let lastConfirmedCartInfos = localStorage.getItem('lastConfirmedCartInfos')
    if (!isEmpty(lastConfirmedCartInfos)) {
        lastConfirmedCartInfos = JSON.parse(lastConfirmedCartInfos)
        await getLastConfirmedCart(Object.assign({}, lastConfirmedCartInfos, { cachiosOptions: { force: true } }))
    }
})

onUnmounted(() => {
    clearMatomoEventTracker()
})
</script>

<style lang="scss" scoped>
@import 'globalScss';

.FinalizedOrder {
    width: 100%;
    max-width: 846px;

    &-title {
        @extend %h2;

        color: $color-defaultText;
    }

    &-illust {
        display: block;
        width: 120px;
        margin: 0 auto 20px;

        &--success {
            stroke: $color-success;
        }

        &--error {
            stroke: $color-error;
        }
    }

    &-explanations {
        margin: 0 0 30px;
        font-size: 14px;
        line-height: 1.8;
        color: $color-lighterText;
    }

    &-goToOrderListButton {
        display: inline-flex;
        margin-bottom: 15px;
    }

    &-content {
        width: 100%;
        text-align: left;
    }
}
</style>
