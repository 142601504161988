<template>
    <main class="FareMediaLoading">
        <product-filter-bar
            v-if="filters.length && !isDefaultTemplate && $vuetify.display.mdAndUp"
            class="FareMediaLoading-productFilterBar"
        />
        <v-container
            :class="['FareMediaLoading-container--' + getTemplateClassSuffixe, !isDefaultTemplate ? 'pt-0 px-0 pb-2' : 'px-0 pt-10 pt-3 pb-sm-3 pt-md-16 px-md-7']"
            class="FareMediaLoading-container my-0 mx-auto mr-auto "
        >
            <v-row>
                <v-col
                    v-if="isDefaultTemplate && $vuetify.display.lgAndUp"
                    class="FareMediaLoading-sidebar pr-4"
                    lg="3"
                >
                    <product-filter-list
                        v-if="filters.length"
                        :shouldShowFiltersSidebar="true"
                        class="FareMediaLoading-sidebarFilters"
                    />
                </v-col>
                <v-col
                    :class="!isDefaultTemplate ? 'pt-16' : ''"
                    class="FareMediaLoading-main px-7 pl-lg-10 pl-xl-14"
                    :lg="!isDefaultTemplate ? 12 : $vuetify.display.lgAndUp ? 9 : 12"
                >
                    <div
                        v-if="filters.length"
                        class="d-flex flex-column"
                    >
                        <h1 class="h1 FareMediaLoading-title">
                            {{ $t('fare_media_loading:title') }}
                        </h1>
                        <v-btn
                            v-if="showStickFilterButton"
                            class="FareMediaLoading-stickyFilterButton"
                            color="primary"
                            @click="showCatalogFiltersModal"
                        >
                            <icomoon-icon
                                :layersCount="0"
                                :name="'Filter'"
                                class="Home-filtersButtonIcon"
                            />
                            {{ $t('button:filter') }}
                        </v-btn>
                        <p class="FareMediaLoading-skipStep">
                            <router-link
                                :to="{ name: 'cart' }"
                                class="FareMediaLoading-skipStepButton"
                            >
                                <v-btn
                                    color="primary"
                                    variant="outlined"
                                >
                                    {{ $t('button:skip_step') }}
                                </v-btn>
                            </router-link>
                        </p>
                        <block-loader v-if="catalogPendingRequests.getCatalog" />
                        <grid-product-list
                            v-else-if="getFareMediaAvailableProducts.length > 0"
                            :products="getFareMediaAvailableProducts"
                        />
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <product-modal :context="getContext" />
        <v-btn
            v-if="showBottomFixedFilterButton"
            class="FareMediaLoading-filtersButton"
            color="primary"
            @click="showCatalogFiltersModal"
        >
            <icomoon-icon
                :layersCount="0"
                :name="'Filter'"
                class="FareMediaLoading-filtersButtonIcon"
            />
            {{ $t('button:filter') }}
        </v-btn>
        <modal
            v-if="mdAndDown"
            v-model="showFiltersModal"
            :width="'full'"
        >
            <v-container
                class="pa-8 pt-0"
                fluid
            >
                <template v-if="!isDefaultTemplate">
                    <p class="h3">
                        <strong class="ProductFilterBar-title">{{ $t('catalog:filter:modal_title') }} :</strong>
                    </p>
                    <product-filter-select-list
                        class="ProductFilterBar-filters"
                    />
                </template>
                <product-filter-list
                    v-else-if="filters.length"
                    :shouldShowFiltersSidebar="true"
                    class="FareMediaLoading-sidebarFilters"
                />
            </v-container>
        </modal>
    </main>
</template>

<script setup>
import { computed, ref, onMounted, onBeforeUpdate, onBeforeUnmount, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import emitter from 'global-emitter'
import * as catalogActionTypes from '@/StoreWeb/store/modules/catalog/action-types'
import FareMedia from '@/StoreWeb/models/user/wallet/FareMedia'
import GridProductList from '@/StoreWeb/components/product/GridProductList'
import ProductModal from '@/StoreWeb/components/product/ProductModal'
import { isEmpty } from 'global-utils'
import * as userMutationTypes from '@/StoreWeb/store/modules/user/mutation-types'
import AddToCartFlowManager from '@/StoreWeb/managers/AddToCartFlowManagerSingleton'
import BlockLoader from '@/StoreWeb/components/common/BlockLoader'
import ProductFilterBar from '@/StoreWeb/components/product/ProductFilterBar'
import ProductFilterSelectList from '@/StoreWeb/components/product/ProductFilterSelectList'
import ProductFilterList from '@/StoreWeb/components/product/ProductFilterList'
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import { useDisplay } from 'vuetify'
import config from 'config'

const store = useStore()
const route = useRoute()
const router = useRouter()
const { smAndDown, mdAndDown, xs } = useDisplay()

const addToCartFlowManager = AddToCartFlowManager.getInstance()

const fareMedia = ref({
    fareMediaId: route.params.id,
    providerId: route.params.providerId,
    providerUserId: route.params.providerUserId,
    providerUserExternalId: route.params.providerUserExternalId
})
const showFiltersModal = ref(false)

const cart = computed(() => store.state.cartModule.cart)
const catalogPendingRequests = computed(() => store.state.catalogModule.catalogPendingRequests)
const filters = computed(() => store.state.catalogModule.filters)
const getFareMediaAvailableProducts = computed(() => store.getters.getFareMediaAvailableProducts)
const getWalletFareMedias = computed(() => store.getters.getWalletFareMedias)
const getTemplateClassSuffixe = computed(() => `--${config.header.template}`)
const isDefaultTemplate = computed(() => config.header.template === 'default')

const getCompleteFareMedia = computed(() => {
    let addedFareMedia = findAddedFareMedia()
    if (!isEmpty(addedFareMedia)) {
        addedFareMedia = convertBasketToFareMedia(addedFareMedia)
        addedFareMedia.isTemporary = true
        return addedFareMedia
    }
    const completeFareMedia = new FareMedia(fareMedia.value.id)
    completeFareMedia.providerId = fareMedia.value.providerId
    completeFareMedia.providerUserId = fareMedia.value.providerUserId
    completeFareMedia.providerUserExternalId = fareMedia.value?.providerUserExternalId

    return completeFareMedia
})

const getContext = computed(() => {
    return {
        chosenFareMedia: getCompleteFareMedia.value,
        flowType: 'FARE_MEDIA_LOADING'
    }
})

const showBottomFixedFilterButton = computed(() => {
    return filters.value.length &&
        (
            (
                config.header.template === 'default' && xs.value
            ) ||
            (
                config.header.template === 'sytral' && smAndDown.value
            )
        )
})

const showStickFilterButton = computed(() => {
    return config.header.template === 'default' && mdAndDown
})

onMounted(() => {
    const fareMediaItem = findAddedFareMedia()
    if (!isEmpty(fareMediaItem)) {
        fareMedia.value.providerId = fareMediaItem.providerId
        if (!isEmpty(fareMediaItem.providerUserId)) {
            fareMedia.value.providerUserId = fareMediaItem.providerUserId
        }
        if (!isEmpty(fareMediaItem.providerUserExternalId)) {
            fareMedia.value.providerUserExternalId = fareMediaItem.providerUserExternalId
        }
        store.dispatch(catalogActionTypes.GET_FARE_MEDIA_CATALOG, fareMedia.value)
    } else if (isEmpty(getFareMediaAvailableProducts.value) && isEmpty(getWalletFareMedias.value)) {
        store.dispatch(catalogActionTypes.GET_FARE_MEDIA_CATALOG, fareMedia.value)
    } else if (!isEmpty(getWalletFareMedias.value)) {
        const selectedFareMedia = getWalletFareMedias.value.find(walletFareMedia => walletFareMedia.id === fareMedia.value.id)
        store.dispatch(catalogActionTypes.GET_FARE_MEDIA_CATALOG, selectedFareMedia)
    }
    emitter.on('cartDeleted', onCartDeletedHandler)
    emitter.on('addToCartSuccess', onAddToCartHandler)
})

onBeforeUpdate(() => {
    store.commit(userMutationTypes.SET_SELECTED_SUPPORT, getCompleteFareMedia.value)
})

onUnmounted(() => {
    emitter.off('cartDeleted', onCartDeletedHandler)
    emitter.off('addToCartSuccess', onAddToCartHandler)
})

onBeforeUnmount(() => {
    clearSelectedFareMedia()
})

onBeforeRouteLeave((to, from, next) => {
    addToCartFlowManager.addToCartFlowContext = null
    next()
})

function clearSelectedFareMedia () {
    store.commit(userMutationTypes.SET_SELECTED_SUPPORT, null)
    store.commit(userMutationTypes.SET_WAITING_FOR_ACTION_FARE_MEDIA, null)
    emitter.emit('selectedSupportChanged')
}

function findAddedFareMedia () {
    let fareMediaItem = null
    if (!isEmpty(cart.value?.baskets)) {
        fareMediaItem = cart.value.baskets.find(basket => basket.basketType === 'FAREMEDIA' && basket.fareMediaId === fareMedia.value.id)
    }

    return fareMediaItem
}

function convertBasketToFareMedia (basket) {
    const basketFareMedia = new FareMedia(basket.fareMediaId, basket.providerId)
    basketFareMedia.providerUserId = basket.providerUserId
    basketFareMedia.providerUserExternalId = basket.providerUserExternalId
    basketFareMedia.isTemporary = true

    return basketFareMedia
}

function showCatalogFiltersModal () {
    showFiltersModal.value = true
}

function onCartDeletedHandler () {
    if (findAddedFareMedia() === null) {
        clearSelectedFareMedia()
        router.push({ name: 'home' })
    }
}

function onAddToCartHandler () {
    const params = {}
    const fareMediaItem = findAddedFareMedia()
    if (!isEmpty(fareMediaItem.providerId)) {
        params.providerId = fareMediaItem.providerId
    }
    if (!isEmpty(fareMediaItem.providerUserId)) {
        params.providerUserId = fareMediaItem.providerUserId
    }
    if (!isEmpty(fareMediaItem.providerUserExternalId)) {
        params.providerUserExternalId = fareMediaItem.providerUserExternalId
    }
    if (!isEmpty(fareMediaItem.fareMediaId)) {
        params.id = fareMediaItem.fareMediaId
    }
    store.dispatch(catalogActionTypes.GET_FARE_MEDIA_CATALOG, params)
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

.FareMediaLoading {
    background: $color-lightest;

    &-container {
        @media #{map-get($display-breakpoints, 'md-only')} {
            max-width: 946px;
        }

        @media #{map-get($display-breakpoints, 'lg-and-up')} {
            max-width: 1614px;
        }

        &--sytral {
            max-width: 1095px;
        }
    }

    &-sidebar {
        border-right: 1px solid $color-lightgray4;
    }

    &-title {
        margin-bottom: 30px;
    }

    &-skipStep {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 0 40px;

        &Button {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 100%;

            @media (min-width: $tablet-breakpoint) {
                width: auto;
            }
        }
    }

    &-stickyFilterButton {
        align-self: flex-end;
        position: sticky;
        z-index: 1;
        top: 105px;
        margin-top: -105px;
        margin-bottom: 65px;

        @media #{map-get($display-breakpoints, 'md-only')} {
            margin-top: -75px;
            margin-bottom: 35px;
        }
    }

    &-filtersButton {
        position: fixed;
        bottom: 30px;
        left: 50%;
        width: 110px;
        margin-left: -55px;
    }

    &-filtersButtonIcon {
        margin-right: 4px;
        font-size: 20px;
    }
}
</style>
