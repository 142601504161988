<template>
    <div class="DebugModal">
        <modal
            v-model="showModal"
            :title="title"
        >
            <div class="DebugModal-body">
                <pre class="DebugModal-objectWrapper"><!--
                    -->{{ JSON.stringify(objectToDump, null, 4) }}
                </pre>
                <v-btn
                    type="submit"
                    class="bg-primary"
                    elevation="0"
                    size="large"
                    @click.prevent="closeModal"
                >
                    {{ $t('button:close') }}
                </v-btn>
            </div>
        </modal>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import emitter from 'global-emitter'
import { isEmpty } from 'global-utils'

const objectToDump = ref({})
const showModal = ref(false)
const title = ref('Debugging modal')

onMounted(() => {
    emitter.on('showDebugModal', (params) => {
        if (!isEmpty(params)) {
            if (!isEmpty(params.title)) {
                title.value = params.title
            }
            if (!isEmpty(params.objectToDump)) {
                objectToDump.value = params.objectToDump
            }
        }
        openModal()
    })
})

function closeModal () {
    showModal.value = false
}

function openModal () {
    showModal.value = true
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.DebugModal {
    &-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &-objectWrapper {
        overflow: auto;
        padding: 10px;
        background: #f4f4f4;
    }

    &-button {
        width: 100%;
    }
}
</style>
