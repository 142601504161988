<template>
    <div class="DefaultInformations">
        <v-card class="DefaultInformations-description">
            <v-card-text>
                <dynamic-form
                    :buttonLabel="$t('button:edit')"
                    :params="getFormParams"
                    :showFormTitle="false"
                    :template="getTemplate"
                    @form-submitted="updatePersonalInfo"
                />
            </v-card-text>
        </v-card>
    </div>
</template>

<script setup>
import { computed, reactive } from 'vue'
import { useStore } from 'vuex'
import DynamicForm from '@/StoreWeb/components/form/DynamicForm'
import FormNormalizer from '@/StoreWeb/normalizers/form/FormNormalizerSingleton'
import * as userActionTypes from '@/StoreWeb/store/modules/user/action-types'
import * as mainMutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import i18n from 'i18n'
import { useLogin } from '@/StoreWeb/js/composables/login-utils'

const store = useStore()
const { kasConfigurationSettings, rawUserInfos } = useLogin()

const state = reactive({
    formNormalizer: FormNormalizer.getInstance()
})

const getTemplate = computed(() => {
    const fields = kasConfigurationSettings.value?.forms?.account?.fields ?? []
    const attributes = rawUserInfos.value?.attributes ?? []
    return state.formNormalizer.normalizeFromAltFormat(fields, attributes)
})

const getFormParams = computed(() => ({
    template: getTemplate.value
}))

function updatePersonalInfo (data) {
    if (data.isValid) {
        store.dispatch(userActionTypes.UPDATE_PERSONAL_INFO, {
            attributes: data.values
        })
    } else {
        store.commit(mainMutationTypes.SET_FEEDBACK_ERROR, i18n.global.t('personal_info:edit:invalid'))
    }
}

</script>

<style lang="scss" scoped>
@import 'globalScss';

.DefaultInformations {
    &-description {
        border-radius: 6px;
        box-shadow: $box-shadow-light;
        background: $color-lightest;
    }
}
</style>
