<template>
    <component
        :is="'li'"
        class="ProviderAccountItem"
    >
        <strong class="ProviderAccountItem-title">{{ providerAccount.getTitle() }}</strong>
        <div
            v-if="getProviderAccountProducts.length > 0"
            class="ProviderAccountItem-body"
        >
            <ul class="ProviderAccountItem-productList">
                <provider-account-product-item
                    v-for="(providerAccountProduct, index) in getProviderAccountProducts"
                    :key="index"
                    :providerAccountProduct="providerAccountProduct"
                    class="ProviderAccountItem-productItem"
                />
            </ul>
        </div>
    </component>
</template>

<script>
import ProviderAccountProductItem from '@/StoreWeb/components/fare-media/ProviderAccountProductItem'

export default {
    components: {
        ProviderAccountProductItem
    },
    props: {
        providerAccount: {
            type: Object,
            required: true
        }
    },
    computed: {
        getProviderAccountProducts () {
            return this.providerAccount.products
        }
    }
}
</script>
<style lang="scss" scoped>
@import 'globalScss';

.ProviderAccountItem {
    margin-bottom: 25px;
    color: $color-lightText;

    &:last-child {
        margin-bottom: 0;
    }

    &-label {
        flex-grow: 1;
    }

    &-title {
        display: block;
        margin-bottom: 10px;
        font-family: $fontFamily-title;
        font-size: 18px;
        font-weight: $fontWeight-titleBold;
        color: $color-defaultText;
    }

    &-productList {
        margin: 0;
        padding: 0;
        list-style: none;
    }
}
</style>
