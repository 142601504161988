<template>
    <div class="Contact">
        <div class="Container">
            <h1 class="h1">
                {{ $t('contact:title') }}
            </h1>
            <debug-application-session v-if="debugMode && debugInformationsDisplay > 2" />
            <iframe
                v-if="false"
                class="Contact-iframe"
                title="Inline Frame Example"
                width="100%"
                height="500px"
                src="http://localhost:8080/auth/realms/master/account/password?referrer_uri=http%3A%2F%2Flocalhost%3A8080%2Fauth%2Fadmin%2Fmaster%2Fconsole%2F%23%2Frealms%2Fmaster%2Ftheme-settings&referrer=security-admin-console"
            />
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import DebugApplicationSession from '@/StoreWeb/components/common/debug/DebugApplicationSession'

const store = useStore()

const debugInformationsDisplay = computed(() => store.state.debuggingModule.debugInformationsDisplay)
const debugMode = computed(() => store.state.debuggingModule.debugMode)
</script>

<style lang="scss" scoped>
@import 'globalScss';

.Contact {
    background: $color-lightest;

    &-internalLinksList {
        li {
            margin-bottom: 15px;
        }
    }

    &-iframe {
        border: none;
    }
}
</style>
