<template>
    <div class="Notifications">
        <notification-list
            :display-style="'card'"
            class="Notifications-list"
        />
    </div>
</template>

<script>
import DefaultView from '@/StoreWeb/views/DefaultView'
import NotificationList from '@/StoreWeb/components/account/notification/NotificationList'
import PageConfigManager from '@/StoreWeb/managers/PageConfigManagerSingleton'
const pageConfigManager = PageConfigManager.getInstance()

export default {
    components: {
        NotificationList
    },
    extends: DefaultView,
    mounted () {
        pageConfigManager.setConfig({
            title: 'account:notifications:title',
            currentAccountPage: 'notifications'
        })
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.Notifications {
    &-list {
        // Add css
    }
}
</style>
