export default class {
    constructor () {
        this._empty = true
        this._sorted = true
        this._unsorted = !this._sorted
    }

    get empty () {
        return this._empty
    }

    set empty (empty) {
        this._empty = empty
    }

    get sorted () {
        return this._sorted
    }

    set sorted (sorted) {
        this._sorted = sorted
    }

    get unsorted () {
        return this._unsorted
    }

    set unsorted (unsorted) {
        this._unsorted = unsorted
    }

    toJSON () {
        return {
            empty: this.empty,
            sorted: this.sorted,
            unsorted: this.unsorted
        }
    }
}
