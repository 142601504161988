import { createVuetify } from 'vuetify'
import * as vuetifyComponent from 'vuetify/components'
import 'vuetify/styles'
import '@/StoreWeb/assets/scss/includes/_variables.scss'
import config from 'config'
import * as labsComponents from 'vuetify/labs/components'

export default createVuetify({
    theme: {
        defaultTheme: 'storeweb',
        themes: {
            storeweb: {
                colors: {
                    error: config.theme.color_danger,
                    primary: config.theme.primary_color,
                    secondary: config.theme.color_brand_secondary,
                    info: config.theme.color_info,
                    success: config.theme.color_success,
                    warning: config.theme.color_warning,
                    lightGray1: config.theme.color_lightgray1,
                    lightGray3: config.theme.color_lightgray3
                }
            }
        }
    },
    components: {
        ...vuetifyComponent,
        ...labsComponents
    }
})
