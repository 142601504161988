<template>
    <div class="OpenPaymentTrips" />
</template>

<script>
import DefaultView from '@/StoreWeb/views/DefaultView'
import PageConfigManager from '@/StoreWeb/managers/PageConfigManagerSingleton'
const pageConfigManager = PageConfigManager.getInstance()

export default {
    extends: DefaultView,
    mounted () {
        pageConfigManager.setConfig({
            title: 'account:open_payment_trips:title',
            currentAccountPage: 'openPaymentTrips'
        })
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.OpenPaymentTrips {
    &-list {
        // Add css
    }
}
</style>
