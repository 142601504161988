export const SET_BANK_ACCOUNTS = 'setBankAccounts'
export const SET_FARE_MEDIA_PRODUCT = 'setFareMediaProduct'
export const SET_FUTURE_DIRECT_DEBITS = 'setFutureDirectDebits'
export const SET_PAST_DIRECT_DEBITS = 'setPastDirectDebits'
export const SET_INVOICES = 'setInvoices'
export const SET_IS_AUTHENTICATED = 'setIsAuthenticated'
export const SET_IS_LOGGING_OUT = 'setIsLoggingOut'
export const SET_KAS_CONFIGURATION_SETTINGS = 'setKasConfigurationSettings'
export const SET_SHOW_ACCOUNT_NAV = 'setShowAccountNav'
export const SET_NOTIFICATIONS = 'setNotifications'
export const SET_ORDERS = 'setOrders'
export const SET_PERSONAL_INFO = 'setPersonalInfo'
export const SET_RAW_USER_INFOS = 'setRawUserInfos'
export const SET_SELECTED_SUPPORT = 'setSelectedSupport'
export const SET_SELECTED_ORDER = 'setSelectedOrder'
export const SET_SHOW_NOTIFICATIONS = 'setShowNotifications'
export const SET_USER_INFOS = 'setUserInfos'
export const SET_USER_PENDING_REQUEST = 'setUserPendingRequest'
export const SET_WAITING_FOR_ACTION_FARE_MEDIA = 'setWaitingForActionFareMedia'
export const SET_WALLET = 'setWallet'
export const SET_CERTIFICATE_DOWNLOAD_ERROR = 'setCertificateDownloadError'
