<template>
    <div class="FareMediaLinkingModal">
        <modal v-model="showModal">
            <v-card-text
                class="d-flex flex-column align-center gap-4 w-100 py-6"
            >
                <template v-if="showFareMediaList">
                    <v-progress-circular
                        v-if="showGetFareMediasLoader"
                        indeterminate
                        color="primary"
                    />
                    <div
                        v-else-if="fareMedias.length > 0"
                        class="w-100"
                    >
                        <p class="h3">
                            {{ t('fare_media:order:title') }}
                        </p>
                        <ul class="FareMediaLinkingModal-list d-flex flex-column align-stretch gap-4 pa-0">
                            <fare-media-product
                                v-for="fareMediaProduct in fareMedias"
                                :key="fareMediaProduct.id"
                                :product="fareMediaProduct"
                                class="ma-0"
                                @start-order-process="startOrderProcess"
                            />
                        </ul>
                    </div>
                    <v-alert
                        v-else
                        type="warning"
                    >
                        {{ t('fare_media:empty_product_list') }}
                    </v-alert>
                </template>
                <template v-else-if="showFareMediaActions">
                    <p class="h3">
                        {{ t('fare_media:add_modal:subtitle') }}
                    </p>
                    <fare-media-add-actions
                        :provider="selectedProvider"
                        @link-fare-media="linkFareMedia(selectedProvider.id)"
                        @order-fare-media="orderFareMedia"
                    />
                </template>
                <fare-media-linking
                    v-else
                    class="w-100"
                    @link-fare-media="linkFareMedia"
                    @order-fare-media="orderFareMedia"
                />
            </v-card-text>
        </modal>
    </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { isEmpty } from 'global-utils'
import config from 'config'
import { useStore } from 'vuex'
import emitter from 'global-emitter'
import FareMediaAddActions from '@/StoreWeb/components/fare-media/FareMediaAddActions'
import FareMediaProduct from '@/StoreWeb/components/fare-media/FareMediaProduct'
import { useI18n } from 'vue-i18n'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true
    }
})

const store = useStore()
const { t } = useI18n()

const selectedProvider = ref(null)
const showFareMediaActions = ref(false)
const showFareMediaAddModal = ref(false)
const showFareMediaList = ref(false)

const showModal = computed({
    get: () => props.modelValue,
    set: (newValue) => {
        if (!newValue) {
            showFareMediaList.value = false
        }

        emit('update:modelValue', newValue)
    }
})

const catalogPendingRequests = computed(() => store.state.catalogModule.catalogPendingRequests)
const fareMedias = computed(() => store.state.catalogModule.fareMedias)
const providersSupportingFareMedias = computed(() => {
    if (!isEmpty(config.providers)) {
        const providers = config.providers.filter(provider => {
            return provider?.fare_media?.enabled
        })

        return providers
    }

    return []
})
const showGetFareMediasLoader = computed(() => catalogPendingRequests.value.getFareMedias)
const walletProviders = computed(() => store.getters.getWalletProviders)

const closeModal = () => {
    showFareMediaList.value = false
    showModal.value = false
}

const linkFareMedia = (providerId) => {
    const correspondingWalletProvider = walletProviders.value.find(provider => provider.id === providerId)

    if (correspondingWalletProvider?.walletAddFareMedia?.mode) {
        closeModal()
        emitter.emit('showAddFareMediaModal', { walletId: providerId, formType: correspondingWalletProvider.walletAddFareMedia.mode })
    } else if (correspondingWalletProvider?.walletAddAccount?.mode) {
        closeModal()
        emitter.emit('showAddFareMediaModal', { walletId: providerId, formType: correspondingWalletProvider.walletAddAccount.mode })
    }
}

const orderFareMedia = () => {
    showFareMediaList.value = true
}

const startOrderProcess = (product) => {
    closeModal()
    emitter.emit('showProductModal', product)
}

const initActions = () => {
    if (providersSupportingFareMedias.value.length === 1) {
        [selectedProvider.value] = providersSupportingFareMedias.value

        if ((selectedProvider.value?.account?.add || selectedProvider.value?.fare_media?.add) && selectedProvider.value?.fare_media?.order) {
            showFareMediaActions.value = true
        } else if (selectedProvider.value?.account?.add || selectedProvider.value?.fare_media?.add) {
            showFareMediaAddModal.value = true
        } else if (selectedProvider.value.fare_media.order) {
            showFareMediaList.value = true
        }
    }
}

onMounted(() => {
    initActions()
})

watch(() => walletProviders.value, () => {
    initActions()
})

watch(() => props.showFareMediaLinkingModal, () => {
    if (showFareMediaAddModal.value && selectedProvider.value?.id) {
        linkFareMedia(selectedProvider.value.id)
    } else if (showFareMediaAddModal.value && selectedProvider.value?.id) {
        orderFareMedia()
    }
})
</script>

<style scoped lang="scss">
.FareMediaLinkingModal {
    &-list {
        list-style: none;
    }
}
</style>
