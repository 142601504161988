import Sort from '@/StoreWeb/models/common/Sort'
import { isEmpty } from 'global-utils'

export default class {
    normalize (item) {
        const sort = new Sort()

        sort.empty = item.empty
        sort.sorted = item.sorted
        sort.unsorted = !sort._sorted

        return sort
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
