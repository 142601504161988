import ProviderAccount from '@/StoreWeb/models/user/wallet/ProviderAccount'
import FareMediaNormalizer from '@/StoreWeb/normalizers/fare-media/FareMediaNormalizerSingleton'
import FareMediaProductNormalizer from '@/StoreWeb/normalizers/fare-media/FareMediaProductNormalizerSingleton'
import FareProfileNormalizer from '@/StoreWeb/normalizers/fare-profile/FareProfileNormalizerSingleton'
import DeviceNormalizer from '@/StoreWeb/normalizers/Devices/DeviceNormalizerSingleton'
import ETicketNormalizer from '@/StoreWeb/normalizers/eticket/ETicketNormalizerSingleton'
import { isEmpty } from 'global-utils'

export default class {
    constructor () {
        this._deviceNormalizer = DeviceNormalizer.getInstance()
        this._eTicketNormalizer = ETicketNormalizer.getInstance()
        this._fareMediaNormalizer = FareMediaNormalizer.getInstance()
        this._fareMediaProductNormalizer = FareMediaProductNormalizer.getInstance()
        this._fareProfileNormalizer = FareProfileNormalizer.getInstance()
    }

    get deviceNormalizer () {
        return this._deviceNormalizer
    }

    get eTicketNormalizer () {
        return this._eTicketNormalizer
    }

    get fareMediaNormalizer () {
        return this._fareMediaNormalizer
    }

    get fareMediaProductNormalizer () {
        return this._fareMediaProductNormalizer
    }

    get fareProfileNormalizer () {
        return this._fareProfileNormalizer
    }

    normalize (item, params) {
        const providerAccount = new ProviderAccount(item.id)
        params = Object.assign(params, {
            accountId: providerAccount.id,
            providerId: params.providerId
        })

        providerAccount.devices = []
        if (!isEmpty(item.devices)) {
            providerAccount.devices = this.deviceNormalizer.normalizeArray(item.devices, params)
        }
        providerAccount.eTickets = []
        if (!isEmpty(item.etickets)) {
            providerAccount.eTickets = this.eTicketNormalizer.normalizeArray(item.etickets, params)
        }
        providerAccount.fareMedias = []
        if (!isEmpty(item.fareMedias)) {
            providerAccount.fareMedias = this.fareMediaNormalizer.normalizeArray(item.fareMedias, params)
        }
        providerAccount.fareProfiles = []
        if (!isEmpty(item.fareProfiles)) {
            providerAccount.fareProfiles = this.fareProfileNormalizer.normalizeArray(item.fareProfiles)
        }
        if (!isEmpty(item.firstName)) {
            providerAccount.firstName = item.firstName
        }
        if (!isEmpty(item.lastName)) {
            providerAccount.lastName = item.lastName
        }
        providerAccount.products = []
        if (!isEmpty(item.products)) {
            providerAccount.products = this.fareMediaProductNormalizer.normalizeArray(item.products, params)
        }
        providerAccount.provider = params.providerId
        if (!isEmpty(item.externalId)) {
            providerAccount.providerUserExternalId = item.externalId
        } else if (!isEmpty(item.providerUserExternalId)) {
            providerAccount.providerUserExternalId = item.providerUserExternalId
        }
        if (!isEmpty(params.recipientUser)) {
            providerAccount.recipientUser = params.recipientUser
        }
        if (!isEmpty(params.walletCreateAccount)) {
            providerAccount.walletCreateAccount = params.walletCreateAccount
        }
        if (!isEmpty(params.walletCreateThirdPartyAccount)) {
            providerAccount.walletCreateThirdPartyAccount = params.walletCreateThirdPartyAccount
        }
        if (!isEmpty(params.walletName)) {
            providerAccount.walletName = params.walletName
        }

        return providerAccount
    }

    normalizeArray (items, params) {
        const normalizedItems = []

        if (typeof params === 'undefined') {
            params = {}
        }

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item, params))
            })
        }

        return normalizedItems
    }
}
