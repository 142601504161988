import Pagination from '@/StoreWeb/models/common/Pagination'
import PageableNormalizer from '@/StoreWeb/normalizers/common/PageableNormalizerSingleton'
import SortNormalizer from '@/StoreWeb/normalizers/common/SortNormalizerSingleton'
import { isEmpty } from 'global-utils'

const pageableNormalizer = PageableNormalizer.getInstance()
const sortNormalizer = SortNormalizer.getInstance()

export default class {
    normalize (item, normalizer) {
        const pagination = new Pagination()

        pagination.content = normalizer.normalizeArray(item.content)
        pagination.empty = item.empty
        pagination.first = item.first
        pagination.last = item.last
        pagination.number = item.number
        pagination.numberOfElements = item.numberOfElements
        pagination.pageable = pageableNormalizer.normalize(item.pageable)
        pagination.size = item.size
        pagination.sort = sortNormalizer.normalize(item.sort)
        pagination.totalElements = item.totalElements
        pagination.totalPages = item.totalPages

        return pagination
    }

    normalizeArray (items, normalizer) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item, normalizer))
            })
        }

        return normalizedItems
    }
}
