import CounterTransaction from '@/StoreWeb/models/user/wallet/CounterTransaction'
import { isEmpty } from 'global-utils'

export default class {
    normalize (item) {
        const counterTransaction = new CounterTransaction()

        counterTransaction.basketLine = item.basketLine
        counterTransaction.comment = item.comment
        counterTransaction.headerId = item.headerId
        counterTransaction.productId = item.modalProductId
        counterTransaction.productLine = item.productLine
        counterTransaction.productName = item.modalProductName
        counterTransaction.productVersion = item.productVersion
        counterTransaction.realm = item.realm
        counterTransaction.validFrom = item.validFrom
        counterTransaction.validTo = item.validTo
        counterTransaction.value = item.value

        return counterTransaction
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
