<template>
    <div class="RenewalProducts Container">
        <product-list
            v-if="products.length > 0"
            :name="$t('products')"
            :link-to-product-page="false"
            :products="products"
        />
        <product-modal :context="getContext" />
    </div>
</template>

<script setup>
import ProductList from '@/StoreWeb/components/product/ProductList'
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import * as catalogActionTypes from '@/StoreWeb/store/modules/catalog/action-types'
import CatalogProductNormalizerSingleton from '@/StoreWeb/normalizers/catalog/CatalogProductNormalizerSingleton'
import ProductModal from '@/StoreWeb/components/product/ProductModal'
import * as userMutationTypes from '@/StoreWeb/store/modules/user/mutation-types'
import { isEmpty } from 'global-utils'
import userManagerSingleton from '@/StoreWeb/managers/UserManagerSingleton'
import { setSelectedFareMediaCookie } from '@/StoreWeb/js/mixins/wallet-utils'
import router from '@/router/router'

const store = useStore()

const userManager = userManagerSingleton.getInstance()

const fareMediaId = ref(null)
const providerId = ref(null)
const products = ref([])
const selectedFareMedia = ref(null)

const fareMediaProduct = computed(() => store.state.userModule.fareMediaProduct)

onMounted(async () => {
    providerId.value = fareMediaProduct.value?.provider
    fareMediaId.value = fareMediaProduct.value?.fareMediaId

    if (!fareMediaProduct.value?.renewableProducts || !providerId.value || !fareMediaId.value) {
        await router.push({ name: 'tickets' })
    }

    selectedFareMedia.value = userManager.getWalletFareMedias(store.state.userModule).find(walletFareMedia => walletFareMedia.id === fareMediaId.value)

    setSelectedFareMediaCookie({
        providerId: providerId.value,
        fareMediaId: fareMediaId.value,
        isTemporary: false
    })

    if (!isEmpty(selectedFareMedia.value)) {
        await store.commit(userMutationTypes.SET_SELECTED_SUPPORT, selectedFareMedia.value)
    }
    const renewableProductsIds = fareMediaProduct.value?.renewableProducts.map(renewableProduct => renewableProduct.id)
    if (renewableProductsIds) {
        store.dispatch(catalogActionTypes.GET_CATALOG_BY_PRODUCTS, {
            providerFareMediaId: fareMediaId.value,
            productIds: renewableProductsIds,
            providerId: providerId.value,
            providerUserId: fareMediaProduct.value.accountId,
            providerUserExternalId: fareMediaProduct.value.providerUserExternalId
        })
            .then((response) => {
                const normalizer = CatalogProductNormalizerSingleton.getInstance()
                products.value = normalizer.normalizeArray(response.data, {
                    renewalData: fareMediaProduct.value.renewableProducts
                })
                store.commit(userMutationTypes.SET_FARE_MEDIA_PRODUCT, null)
            })
    }
})

const getContext = computed(() => ({
    chosenFareMedia: selectedFareMedia.value,
    flowType: 'FARE_MEDIA_LOADING'
}))

</script>
