import actions from './actions'
import mutations from './mutations'

export default {
    namespaced: false,
    actions,
    mutations,
    state: {
        paymentPendingRequests: {
            createBankAccount: false,
            updateBankAccount: false,
            getBankAccounts: false,
            paymentInit: false,
            getMandateBinary: false,
            signMandate: false,
            postBankAccounts: false
        },
        payment: null,
        paymentModes: []
    }
}
