<template>
    <div class="DocumentsSelector">
        <h2
            v-if="title !== ''"
            class="DocumentsSelector-title"
        >
            {{ title }}
        </h2>
        <photo-file-input-field-with-label
            v-for="document in getPhotoTypeDocuments"
            :key="document.id"
            :formatRatio="'3:4'"
            :fileId="document.id"
            :isFileInstructionsDisplayed="true"
            :mimeTypes="document.mimeTypes"
            :maxSize="document.maxSize"
            v-bind="document"
            class="DocumentsSelector-field DocumentsSelector-photoField"
            @file-input-error="setUploadedFileError"
            @field-value-changed="documentChanged"
            @image-cropped="imageCropped"
        />
        <template v-if="getNotPhotoTypeDocuments.length">
            <p
                v-if="otherFilesTitle"
                class="DocumentsSelector-otherFilesTitle"
            >
                {{ otherFilesTitle }}
            </p>
            <file-input-field-with-label
                v-for="document in getNotPhotoTypeDocuments"
                :key="document.id"
                :fileId="document.id"
                :isFileInstructionsDisplayed="true"
                :mimeTypes="document.mimeTypes"
                :maxSize="document.maxSize"
                v-bind="document"
                class="DocumentsSelector-field DocumentsSelector-fileField"
                @file-input-error="setUploadedFileError"
                @field-value-changed="documentChanged"
            />
        </template>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { isEmpty } from 'global-utils'
import FileInputFieldWithLabel from '@/StoreWeb/components/common/FileInputFieldWithLabel'
import DocumentManager from '@/StoreWeb/managers/DocumentManagerSingleton'
import PhotoFileInputFieldWithLabel from '@/StoreWeb/components/common/PhotoFileInputFieldWithLabel'
import * as actionTypes from '@/StoreWeb/store/modules/main/action-types'
import * as cartMutationTypes from '@/StoreWeb/store/modules/cart/mutation-types'
import { useStore } from 'vuex'
import config from 'config'

const emit = defineEmits(['updateDocuments'])

const props = defineProps({
    documents: {
        type: Array,
        required: true
    },
    otherFilesTitle: {
        type: String,
        default: ''
    },
    providedDocuments: {
        type: Array,
        default: () => []
    },
    title: {
        type: String,
        default: ''
    }
})

const store = useStore()

const selectedDocuments = ref([])
const uploadedFileError = ref(false)

const documentManager = DocumentManager.getInstance()

const getNotPhotoTypeDocuments = computed(() => selectedDocuments.value.filter(document => !isPhotoDocument(document)))
const getPhotoTypeDocuments = computed(() => selectedDocuments.value.filter(document => isPhotoDocument(document)))

onMounted(() => {
    if (config.features.catalog.file_upload) {
        store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, true)
    }

    props.documents.forEach(document => {
        let providedDocument = null
        if (!isEmpty(props.providedDocuments)) {
            providedDocument = props.providedDocuments.find(item => item.id === document.id)
        }

        const formattedDocument = {
            base64String: '',
            cropEnabled: document.cropEnabled,
            description: document.description ? document.description : '',
            fileName: '',
            fileObject: null,
            id: document.id,
            isCropAvailable: document.isCropAvailable,
            label: document.name,
            maxSize: document.maxSize,
            mimeTypes: document.mimeTypes
        }

        if (!isEmpty(providedDocument)) {
            formattedDocument.fileObject = providedDocument.fileObject || null
            formattedDocument.fileName = providedDocument.fileName || ''
            formattedDocument.base64String = providedDocument.base64String || ''
        }

        selectedDocuments.value.push(formattedDocument)
    })

    if (!isEmpty(props.providedDocuments)) {
        emit('updateDocuments', selectedDocuments.value)
    }
})

function imageCropped (params) {
    documentManager.cropImage(params, props.documents)

    if (config.features.catalog.file_upload && !isEmpty(params.base64String)) {
        const updatedDocument = getDocumentById(params.id)
        const uploadConfig = getUploadConfig(params.id, documentManager.dataURLToFile(params.base64String, updatedDocument.fileName))
        uploadConfig.id = params.id
        store.dispatch(actionTypes.UPLOAD_DOCUMENT, uploadConfig)
    }
}

function documentChanged (event) {
    if (!uploadedFileError.value && event?.target?.id && event?.target?.files?.[0]) {
        selectedDocuments.value = documentManager.updateDocuments(event, selectedDocuments.value)
        const idParsed = event.target.id.split('js-file-')[1]

        if (config.features.catalog.file_upload) {
            const uploadConfig = getUploadConfig(idParsed, event.target.files[0])
            uploadConfig.id = idParsed
            store.dispatch(actionTypes.UPLOAD_DOCUMENT, uploadConfig)
        } else {
            emit('updateDocuments', selectedDocuments.value)
        }
    }

    store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, true)
    uploadedFileError.value = false
}

function getDocumentById (id) {
    return selectedDocuments.value.find(document => document.id === id)
}

function getUploadConfig (documentId, file, fileName) {
    const formData = new FormData()
    if (!isEmpty(fileName)) {
        formData.append('file', file, fileName)
    } else {
        formData.append('file', file)
    }

    return {
        formData,
        callback: (data) => {
            const updatedDocument = getDocumentById(documentId)
            updatedDocument.uploadedFileId = data.documentId
            emit('updateDocuments', selectedDocuments.value)
            if (isAllDocumentsLoaded()) {
                store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, false)
            }
        }
    }
}

function isAllDocumentsLoaded () {
    return selectedDocuments.value.every(document => !isEmpty(document.uploadedFileId))
}

function isPhotoDocument (document) {
    return document.isCropAvailable && document.cropEnabled
}

function setUploadedFileError (error) {
    uploadedFileError.value = error !== false
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.DocumentsSelector {
    &-title {
        margin: 0 0 10px;
        font-size: 18px;
    }

    &-otherFilesTitle {
        margin: 0;
        font-size: 14px;
        font-weight: bold;
    }

    &-field {
        margin-bottom: 15px;

        & + .DocumentsSelector-otherFilesTitle {
            margin-top: 30px;
        }
    }
}
</style>
