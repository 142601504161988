export default class {
    constructor (
        id
    ) {
        this._id = id
        this._label = ''
        this._maxValue = 0
        this._minValue = 1
        this._online = false
        this._pagination = false
        this._preselection = null
        this._required = false
        this._search = false
        this._type = 'STRING'
        this._index = 0
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get label () {
        return this._label
    }

    set label (label) {
        this._label = label
    }

    get maxValue () {
        return this._maxValue
    }

    set maxValue (maxValue) {
        this._maxValue = maxValue
    }

    get minValue () {
        return this._minValue
    }

    set minValue (minValue) {
        this._minValue = minValue
    }

    get online () {
        return this._online
    }

    set online (online) {
        this._online = online
    }

    get pagination () {
        return this._pagination
    }

    set pagination (pagination) {
        this._pagination = pagination
    }

    get preselection () {
        return this._preselection
    }

    set preselection (preselection) {
        this._preselection = preselection
    }

    get required () {
        return this._required
    }

    set required (required) {
        this._required = required
    }

    get search () {
        return this._search
    }

    set search (search) {
        this._search = search
    }

    get type () {
        return this._type
    }

    set type (type) {
        this._type = type
    }

    get index () {
        return this._index
    }

    set index (index) {
        this._index = index
    }

    toJSON () {
        return {
            id: this.id,
            label: this.label,
            maxValue: this.maxValue,
            minValue: this.minValue,
            online: this.online,
            pagination: this.pagination,
            preselection: this.preselection,
            required: this.required,
            search: this.search,
            type: this.type,
            index: this.index
        }
    }
}
