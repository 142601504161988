<template>
    <the-header
        :menu-items="menuItems"
        :configuration="configuration"
    />
</template>

<script setup>
import { useCmsMenuApi, TheHeader } from '@instant-system/web-harmony-widget'
import configuration from '@/../config/wh-config.json'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import axios from 'axios'

const { locale } = useI18n()

const { loadHeaderMenuItems } = useCmsMenuApi(fetchFn, configuration)

const menuItems = ref([])

onMounted(async () => {
    menuItems.value = await loadHeaderMenuItems(locale.value)
})

async function fetchFn (url) {
    return axios.get(url)
        .then((response) => response.data)
}
</script>
