import * as validators from '@vuelidate/validators'
import i18n from 'i18n'
import { createI18nMessage, helpers } from '@vuelidate/validators'

const { t } = i18n.global
const withI18nMessage = createI18nMessage({ t })

export const required = withI18nMessage(validators.required)
export const minLength = withI18nMessage(validators.minLength, { withArguments: true })
export const maxLength = withI18nMessage(validators.maxLength, { withArguments: true })
export const email = withI18nMessage(validators.email, { withArguments: true })
export const numeric = withI18nMessage(validators.numeric)
export const regex = withI18nMessage(helpers.regex, { withArguments: true })
