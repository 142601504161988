<template>
    <div class="PaymentMeans">
        <block-loader v-if="showWalletLoader" />
        <div v-if="getWalletProviders.length > 0">
            <div
                v-for="provider in getWalletProviders"
                :key="provider.id"
            >
                <p
                    class="h3 PaymentMeans-title"
                >
                    {{ $t('account:payment_means') }}
                    <small
                        v-if="getWalletProviders.length > 1 && config.features.iban_list.display_wallet_name"
                        class="ml-1 PaymentMeans-titleProvider"
                    >
                        (<span v-if="provider.walletName">{{ provider.walletName }}:</span> {{ provider.id }})
                    </small>
                </p>
                <div
                    v-if="provider.accounts.length > 0"
                    class="PaymentMeans-list"
                >
                    <provider-bank-account-list
                        v-for="providerAccount in provider.accounts"
                        :key="providerAccount.id"
                        :hasToDisplayLabel="false"
                        :label="providerAccount.getFullName()"
                        :provider="provider"
                        :providerAccount="providerAccount"
                    />
                </div>
                <div v-else>
                    <v-alert
                        color="info"
                        density="compact"
                        prominent
                        text
                        variant="tonal"
                    >
                        {{ $t('account:bank_account:empty_list') }}
                    </v-alert>
                    <user-account-subscription-button
                        :provider="provider"
                        @show-user-account-subscription-modal="setShowUserAccountSubscriptionModal"
                    />
                </div>
            </div>
        </div>
        <user-account-subscription-modal v-model="showUserSubscriptionModal" />
    </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import blockLoader from '@/StoreWeb/components/common/BlockLoader.vue'
import ProviderBankAccountList from '@/StoreWeb/components/account/bank-account/ProviderBankAccountList.vue'
import UserAccountSubscriptionButton from '@/StoreWeb/components/account/UserAccountSubscriptionButton.vue'
import UserAccountSubscriptionModal from '@/StoreWeb/components/account/UserAccountSubscriptionModal.vue'
import config from 'config'
import PageConfigManager from '@/StoreWeb/managers/PageConfigManagerSingleton'

const store = useStore()

const userPendingRequests = computed(() => store.state.userModule.userPendingRequests)
const showWalletLoader = computed(() => userPendingRequests.value.getWallet)
const getWalletProviders = computed(() => store.getters.getWalletProviders)
const pageConfigManager = PageConfigManager.getInstance()
const showUserSubscriptionModal = ref(false)

function setShowUserAccountSubscriptionModal () {
    showUserSubscriptionModal.value = true
}

onMounted(() => {
    pageConfigManager.setConfig({
        title: 'account:payment_means:title',
        currentAccountPage: 'paymentMeans'
    })
})

</script>

<style lang="scss" scoped>
@import 'globalScss';

.PaymentMeans {
    &-list {
        display: flex;
        flex-direction: column;
        gap: $s6;
    }
}
</style>
