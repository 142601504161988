import { isEmpty } from 'global-utils'

export default class {
    serialize (values, template) {
        if (typeof values.defaultAddress === 'undefined') {
            values.defaultAddress = false
        }
        if (values.streetNumberSuffix === '-') {
            values.streetNumberSuffix = ''
        }
        if (!isEmpty(template)) {
            values.templateModel = template.code
        }
        return values
    }
}
