export const SET_APPLIED_FILTERS = 'setAppliedFilters'
export const SET_CURRENT_CATALOG_PARAMS = 'setCurrentCatalogParams'
export const SET_FARE_MEDIA_AVAILABLE_PRODUCTS = 'setFareMediaAvailableProducts'
export const SET_FARE_MEDIAS = 'setFareMedias'
export const SET_FILTERS = 'setFilters'
export const SET_FINES = 'setFines'
export const SET_CATALOG_PENDING_REQUEST = 'setCatalogPendingRequest'
export const SET_PRODUCT_DETAILS = 'setProductDetails'
export const SET_PRODUCT_TO_PUT_ON_CARD = 'setProductToPutOnCard'
export const SET_PRODUCTS = 'setProducts'
export const SET_SECTIONS = 'setSections'
export const SET_WAITING_FOR_FILTERS = 'setWaitingForFilters'
