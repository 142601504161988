<template>
    <div class="UserWithAvatar">
        <user-avatar class="UserWithAvatar-avatar" />{{ getFullName }}
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { isEmpty } from 'global-utils'
import UserAvatar from '@/StoreWeb/components/account/UserAvatar'
import { useLogin } from '@/StoreWeb/js/composables/login-utils'

const { userInfos } = useLogin()

const getFullName = computed(() => !isEmpty(userInfos.value) ? userInfos.value.getFullName() : '')
</script>

<style lang="scss" scoped>
@import 'globalScss';

.UserWithAvatar {
    @extend %h2;

    display: flex;
    align-items: center;
    margin: 0 -25px 25px;
    padding: 0 25px 15px;
    background: $color-lightest;

    @media (min-width: $tablet-breakpoint) {
        margin: 0 0 25px;
        padding: 0;
        background: none;
    }

    &-avatar {
        margin-right: 10px;
    }
}
</style>
