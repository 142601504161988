import { isEmpty } from 'global-utils'

export default class {
    constructor (
        id
    ) {
        this._actions = []
        this._creationDate = ''
        this._fareMedias = []
        this._id = id
        this._isMaster = false
        this._isRequester = false
        this._isAssociatedTo = null
        this._key = '' // TODO : To remove ?
        this._masterAccountId = ''
        this._masterEmail = ''
        this._masterFirstName = ''
        this._masterLastName = ''
        this._mode = ''
        this._networkId = 0
        this._slaveAccountId = ''
        this._slaveEmail = ''
        this._slaveFirstName = ''
        this._slaveLastName = ''
        this._status = null
        this._type = null
        this._updateDate = ''
    }

    get actions () {
        return this._actions
    }

    set actions (actions) {
        this._actions = actions
    }

    get creationDate () {
        return this._creationDate
    }

    set creationDate (creationDate) {
        this._creationDate = creationDate
    }

    get fareMedias () {
        return this._fareMedias
    }

    set fareMedias (fareMedias) {
        this._fareMedias = fareMedias
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get isAssociatedTo () {
        return this._isAssociatedTo
    }

    set isAssociatedTo (isAssociatedTo) {
        this._isAssociatedTo = isAssociatedTo
    }

    get isMaster () {
        return this._isMaster
    }

    set isMaster (isMaster) {
        this._isMaster = isMaster
    }

    get isRequester () {
        return this._isRequester
    }

    set isRequester (isRequester) {
        this._isRequester = isRequester
    }

    get key () {
        return this._key
    }

    set key (key) {
        this._key = key
    }

    get masterAccountId () {
        return this._masterAccountId
    }

    set masterAccountId (masterAccountId) {
        this._masterAccountId = masterAccountId
    }

    get masterEmail () {
        return this._masterEmail
    }

    set masterEmail (masterEmail) {
        this._masterEmail = masterEmail
    }

    get masterFirstName () {
        return this._masterFirstName
    }

    set masterFirstName (masterFirstName) {
        this._masterFirstName = masterFirstName
    }

    get masterLastName () {
        return this._masterLastName
    }

    set masterLastName (masterLastName) {
        this._masterLastName = masterLastName
    }

    get mode () {
        return this._mode
    }

    set mode (mode) {
        this._mode = mode
    }

    get networkId () {
        return this._networkId
    }

    set networkId (networkId) {
        this._networkId = networkId
    }

    get slaveAccountId () {
        return this._slaveAccountId
    }

    set slaveAccountId (slaveAccountId) {
        this._slaveAccountId = slaveAccountId
    }

    get slaveFirstName () {
        return this._slaveFirstName
    }

    set slaveFirstName (slaveFirstName) {
        this._slaveFirstName = slaveFirstName
    }

    get slaveEmail () {
        return this._slaveEmail
    }

    set slaveEmail (slaveEmail) {
        this._slaveEmail = slaveEmail
    }

    get slaveLastName () {
        return this._slaveLastName
    }

    set slaveLastName (slaveLastName) {
        this._slaveLastName = slaveLastName
    }

    get status () {
        return this._status
    }

    set status (status) {
        this._status = status
    }

    get type () {
        return this._type
    }

    set type (type) {
        this._type = type
    }

    get updateDate () {
        return this._updateDate
    }

    set updateDate (updateDate) {
        this._updateDate = updateDate
    }

    getFullName (account) {
        return (!isEmpty(account.lastName) ? account.lastName + ' ' : '') + (!isEmpty(account.firstName) ? account.firstName : '')
    }

    getMasterFullName () {
        return (!isEmpty(this.masterLastName) ? this.masterLastName + ' ' : '') + (!isEmpty(this.masterFirstName) ? this.masterFirstName : '')
    }

    getSlaveFullName () {
        return (!isEmpty(this.slaveLastName) ? this.slaveLastName + ' ' : '') + (!isEmpty(this.slaveFirstName) ? this.slaveFirstName : '')
    }

    replaceAccountLabel (message) {
        let accountLabel
        if (this.isMaster) {
            accountLabel = this.getSlaveFullName()
        } else {
            accountLabel = this.getMasterFullName()
        }
        return message.replace('%accountLabel%', `<strong>${accountLabel}</strong>`)
    }

    getTargetAccountLabel () {
        return this.isMaster ? this.getSlaveFullName() : this.getMasterFullName()
    }

    getTypeString () {
        if (this.isMaster && this.type.fromName) {
            return this.type.fromName
        } else if (!this.isMaster && this.type.toName) {
            return this.type.toName
        } else if (this.type.name) {
            return this.type.name
        }

        return ''
    }

    toJSON () {
        return {
            actions: this.actions,
            creationDate: this.creationDate,
            fareMedias: this.fareMedias,
            id: this.id,
            isAssociatedTo: this.isAssociatedTo,
            isMaster: this.isMaster,
            isRequester: this.isRequester,
            key: this.key,
            masterAccountId: this.masterAccountId,
            masterEmail: this.masterEmail,
            masterFirstName: this.masterFirstName,
            masterLastName: this.masterLastName,
            mode: this.mode,
            networkId: this.networkId,
            slaveAccountId: this.slaveAccountId,
            slaveEmail: this.slaveEmail,
            slaveFirstName: this.slaveFirstName,
            slaveLastName: this.slaveLastName,
            status: this.status,
            type: this.type,
            updateDate: this.updateDate
        }
    }
}
