export default class {
    /**
     * Notification constructor
     */
    constructor (id) {
        this._id = id
    }

    get creationDate () {
        return this._creationDate
    }

    set creationDate (creationDate) {
        this._creationDate = creationDate
    }

    get data () {
        return this._data
    }

    set data (data) {
        this._data = data
    }

    get deleted () {
        return this._deleted
    }

    set deleted (deleted) {
        this._deleted = deleted
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get key () {
        return this._key
    }

    set key (key) {
        this._key = key
    }

    get read () {
        return this._read
    }

    set read (read) {
        this._read = read
    }

    get sendDate () {
        return this._sendDate
    }

    set sendDate (sendDate) {
        this._sendDate = sendDate
    }

    getAction () {
        return null
    }

    toJSON () {
        return {
            creationDate: this.creationDate,
            data: this.data,
            deleted: this.deleted,
            id: this.id,
            key: this.key,
            read: this.read,
            sendDate: this.sendDate
        }
    }
}
