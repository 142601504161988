<template>
    <div class="PaymentModeList">
        <ul
            :aria-labelledby="ariaLabel"
            class="PaymentModeList-container"
            role="radiogroup"
        >
            <li
                v-for="(item, index) in paymentModesAvailable"
                :key="'radio-button-list_' + index"
                :class="currentValue === item.id ? 'PaymentModeList-item--checked' : ''"
                class="PaymentModeList-item"
            >
                <label
                    :for="item.id"
                    class="PaymentModeList-itemLabel"
                >
                    <span class="PaymentModeList-itemInputWrapper">
                        <input
                            :id="item.id"
                            v-model="currentValue"
                            alt="radio"
                            :aria-checked="currentValue === item.id"
                            :value="item.id"
                            class="PaymentModeList-itemInput"
                            role="radio"
                            tabindex="0"
                            type="radio"
                        >
                    </span>
                    <span class="PaymentModeList-itemText">
                        {{ item.label }}
                    </span>
                    <span
                        :class="'Icon-' + item.id"
                        class="PaymentModeList-itemIcon"
                    />
                </label>
            </li>
        </ul>
        <payment-mode-list-content
            :payment-modes-available="paymentModesAvailable"
            :selected-payment-mode="currentValue"
        />
    </div>
</template>

<script setup>
import PaymentModeListContent from '@/StoreWeb/components/payment/PaymentModeListContent'
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'

const { state } = useStore()

defineProps({
    ariaLabel: {
        type: String,
        required: true
    }
})

const currentValue = ref('')

const paymentModesAvailable = computed(() => state.paymentModule.paymentModes)

onMounted(() => {
    currentValue.value = paymentModesAvailable.value?.[0].id ?? ''
})

</script>

<style lang="scss" scoped>
@import 'globalScss';

.PaymentModeList {
    &-container {
        width: 100%;
        max-width: 1095px;
        margin: 0 auto;
        padding: 25px 0 0;
        list-style: none;
        text-align: center;

        @media (max-width: $desktop-breakpoint) {
            padding: 0;
            text-align: left;
        }
    }

    &-item {
        display: inline-block;
        margin-bottom: 15px;
        padding: 0 15px;
        border-right: 1px solid $color-lightgray3;

        @media (max-width: $desktop-breakpoint) {
            display: block;
            border: 0;
        }

        &:last-child {
            border-right: 0;
        }
    }

    &-itemInputWrapper {
        position: relative;
        overflow: hidden;
        width: 17px;
        height: 17px;
        margin-right: 5px;
        border: solid 1px $color-lightgray3;
        border-radius: 17px;
        transition: border-color .2s;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 6px;
            height: 6px;
            margin-top: -3px;
            margin-left: -3px;
            border-radius: 5px;
            background: $color-brandPrimary;
            opacity: 0;
            transition: opacity .2s;
        }
    }

    &-item--checked {
        .PaymentModeList-itemInputWrapper {
            border-color: $color-brandPrimary;

            &::after {
                opacity: 1;
            }
        }
    }

    &-itemLabel {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: $color-lighterText;

        @media (max-width: $desktop-breakpoint) {
            justify-content: flex-start;
        }
    }

    &-itemInput {
        position: absolute;
        top: -9999px;
        left: -9999px;
        width: 0;
        height: 0;
    }

    &-itemText {
        margin-right: 4px;
        font-size: 14px;
    }

    &-itemIcon {
        font-size: 22px;
    }

    .IconSepaRecurringPayment--filled {
        font-size: 17px;
    }
}
</style>
