import InvoiceNormalizer from '@/StoreWeb/normalizers/user/order/InvoiceNormalizer'
import NotificationNormalizer from '@/StoreWeb/normalizers/user/notification/NotificationNormalizer'
import UserManager from '@/StoreWeb/managers/UserManagerSingleton'
const userManager = UserManager.getInstance()

export default {
    getAllBankAccounts (state) {
        return userManager.getAllBankAccounts(state)
    },
    getBankAccountsProvidersIdsCount (state) {
        return userManager.getBankAccountsProvidersIdsCount(state)
    },
    getPastDirectDebitsByYear (state) {
        return userManager.getPastDirectDebitsByYear(state)
    },
    getFutureDirectDebitsByYear (state) {
        return userManager.getFutureDirectDebitsByYear(state)
    },
    getInvoices (state) {
        return InvoiceNormalizer.getInvoices(state)
    },
    getNotifications (state) {
        return NotificationNormalizer.getNotifications(state.notifications)
    },
    getHasUnreadNotifications (state) {
        return NotificationNormalizer.getHasUnreadNotifications(state.notifications)
    },
    getOrders (state) {
        return userManager.getOrders(state)
    },
    getOrdersByYear (state) {
        return userManager.getOrdersByYear(state)
    },
    getUserStatus (state) {
        return userManager.getUserStatus(state)
    },
    getWallet (state) {
        return userManager.getWallet(state)
    },
    getWalletProviders (state) {
        return userManager.getWalletProviders(state)
    },
    getWalletProvidersWithFareMedia (state) {
        return userManager.getWalletProvidersWithFareMedia(state)
    },
    getWalletProviderAccounts (state) {
        return userManager.getWalletProviderAccounts(state)
    },
    getNonEmptyWalletProviderAccounts (state) {
        return userManager.getNonEmptyWalletProviderAccounts(state)
    },
    getWalletProvidersAvailableForAdd (state) {
        return userManager.getWalletProvidersAvailableForAdd(state)
    },
    getWalletFareMedias (state) {
        return userManager.getWalletFareMedias(state)
    },
    getWalletAllSupports (state) {
        return userManager.getWalletAllSupports(state)
    },
    getWalletFareProfiles (state) {
        return userManager.getWalletFareProfiles(state)
    },
    getWalletAssociatedFareMedias (state) {
        return userManager.getWalletAssociatedFareMedias(state)
    },
    getWalletAssociatedProviderAccounts (state) {
        return userManager.getWalletAssociatedProviderAccounts(state)
    },
    getAllWalletFareMedias (state) {
        return userManager.getWalletFareMedias(state).concat(userManager.getWalletAssociatedFareMedias(state))
    },
    getWalletAssociatedAccounts (state) {
        return userManager.getWalletAssociatedAccounts(state)
    },
    getWalletPlans (state) {
        return userManager.getWalletPlans(state)
    },
    getWalletCounters (state) {
        return userManager.getWalletCounters(state)
    },
    getWalletSupports (state) {
        return userManager.getWalletSupports(state)
    }
}
