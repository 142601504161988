import ProductParameterField from '@/StoreWeb/models/common/ProductParameterField'
import { isEmpty } from 'global-utils'

export default class {
    normalize (item) {
        const productParameterField = new ProductParameterField()

        productParameterField.productParameterId = item.productParameterId
        productParameterField.code = item.code
        productParameterField.value = item.value

        return productParameterField
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
