<template>
    <div class="DebugConfigModal">
        <modal
            v-model="showModal"
            :title="title"
        >
            <div class="DebugConfigModal-body">
                <debug-panel
                    @close-panel="showModal = false"
                />
            </div>
        </modal>
    </div>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue'
import emitter from 'global-emitter'
import DebugPanel from '@/StoreWeb/components/common/debug/DebugPanel'

const showModal = ref(false)
const title = ref('Debug config modal')

onBeforeMount(() => {
    emitter.on('showDebugConfigModal', () => {
        openModal()
    })
})

function openModal () {
    showModal.value = true
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.DebugConfigModal {
    &-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
</style>
