<template>
    <div class="PaymentModeForm">
        <div v-if="getPaymentRequired">
            <h2 class="PaymentModeForm-title h2">
                {{ $t('payment_mode:title') }}
            </h2>
            <payment-mode-list
                :ariaLabel="$t('payment:choose_method')"
                class="PaymentModeForm-list"
            />
        </div>
        <div
            v-else
            class="text-center"
        >
            <v-btn
                class="justify-center text-center"
                color="primary"
                @click.prevent="orderValidation"
            >
                {{ $t('button:validate') }}
            </v-btn>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import PaymentModeList from './PaymentModeList'
import * as cartActionTypes from '@/StoreWeb/store/modules/cart/action-types'
import * as cartMutationTypes from '@/StoreWeb/store/modules/cart/mutation-types'
import * as paymentActionTypes from '@/StoreWeb/store/modules/payment/action-types'
import * as paymentMutationTypes from '@/StoreWeb/store/modules/payment/mutation-types'
import Cart from '@/StoreWeb/models/cart/Cart'
import emitter from 'global-emitter'
import AddressNormalizer from '@/StoreWeb/normalizers/address/AddressNormalizerSingleton'
import ErrorManager from '@/StoreWeb/managers/ErrorManager'
import { getCartConfirmPayload } from '@/StoreWeb/js/composables/cart-utils'

const store = useStore()
const router = useRouter()

const addressNormalizer = reactive(AddressNormalizer.getInstance())

const cart = computed(() => store.state.cartModule.cart)
const paymentModesAvailable = computed(() => store.state.paymentModule.paymentModes)
const getPaymentRequired = computed(() => paymentModesAvailable.value?.length > 0)

onMounted(() => {
    emitter.on('paymentInitSuccess', paymentInitSuccess)
    emitter.on('paymentInitFailure', paymentInitFailure)
})

onUnmounted(() => {
    emitter.off('paymentInitSuccess', paymentInitSuccess)
    emitter.off('paymentInitFailure', paymentInitFailure)
})

const normalizeAddressFields = (payload) => {
    const fields = ['billingAddress', 'deliveryAddress', 'deliveryClickAndCollectAddress']
    fields.forEach(field => {
        if (!payload[field]) {
            return
        }
        payload[field] = addressNormalizer.normalize(payload[field])
    })
}

const orderValidation = async () => {
    try {
        const paymentInitPayload = {
            paymentInfos: {
                paymentMode: 'CREDITCARD',
                amount: cart.value.totalAmountIncludingTaxes
            }
        }

        if (cart.value.status !== 'PAYMENT_PROCESSING') {
            const cartConfirmPayload = getCartConfirmPayload(normalizeAddressFields)

            if (!cartConfirmPayload) {
                return
            }

            if (['PROVIDERS_TOTAL_CONFIRMED', 'PROVIDERS_TOTAL_CREATED'].includes(cart.value.status)) {
                await store.dispatch(cartActionTypes.CART_CONFIRMATION, { ...cartConfirmPayload, ...paymentInitPayload })
            }
        }

        await store.dispatch(paymentActionTypes.PAYMENT_INIT, paymentInitPayload).then(() => {
            paymentInitSuccess()
        })
    } catch (error) {
        ErrorManager.displayErrorModal('cart:get_cart_confirmation', error)
    }
}

const paymentInitSuccess = () => {
    localStorage.removeItem('cartEmail')
    store.commit(cartMutationTypes.SET_CART, new Cart())
    store.commit(paymentMutationTypes.SET_PAYMENT, null)
    router.push({ name: 'orderSuccess' })
}

const paymentInitFailure = () => {
    localStorage.removeItem('cartEmail')
    router.push({ name: 'paymentError' })
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

.PaymentModeForm {
    &-list.PaymentModeList {
        margin-bottom: 20px;
    }

    &-title {
        width: 100%;
        max-width: 1095px;
        margin: 0 auto;
        padding: 0 25px 25px;
        font-size: $font-xlarge;
    }
}
</style>
