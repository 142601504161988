import * as mutationTypes from './mutation-types'

export default {
    [mutationTypes.SET_ADDRESSES] (state, addresses) {
        state.addresses = addresses
    },
    [mutationTypes.SET_ADDRESS_AUTHORIZATIONS] (state, addressAuthorizations) {
        state.addressAuthorizations = addressAuthorizations
    },
    [mutationTypes.SET_ADDRESS_TEMPLATES] (state, addressTemplates) {
        state.addressTemplates = addressTemplates
    },
    [mutationTypes.SET_ADDRESS_PENDING_REQUEST] (state, pendingRequest) {
        if (state.addressPendingRequests[pendingRequest.key] !== undefined) {
            state.addressPendingRequests[pendingRequest.key] = pendingRequest.value
        }
    }
}
