<template>
    <footer
        class="FooterBar"
        role="contentinfo"
    >
        <div class="FooterBar-container hidden-sm-and-down">
            <div class="d-flex justify-space-between align-center gap-5">
                <div class="flex-grow-0 pa-0">
                    <router-link
                        :to="{ name: 'home' }"
                        :title="getAppTitle"
                        class="FooterBar-branding"
                        rel="home"
                    >
                        <img
                            class="footerBar-logo"
                            :src="getFooterLogo"
                            alt="footer-logo"
                        >
                    </router-link>
                </div>

                <nav
                    class="FooterBar-nav"
                    role="navigation"
                >
                    <v-list
                        class="FooterBar-navList d-flex flex-wrap"
                    >
                        <v-list-item
                            class="FooterBar-copyright"
                        >
                            &copy; {{ getCurrentYear() }} Sytral
                        </v-list-item>
                        <v-list-item
                            v-for="(link, index) in getFooterMenuLinks"
                            :key="index"
                            class="pa-2"
                        >
                            <a
                                class="FooterBar-link"
                                :class="{ 'custom-tac-opener': link.action && link.action === 'actionCookiesPanel' }"
                                :href="link.action && link.action === 'actionCookiesPanel' ? null : sanitizeUrl(link.to)"
                                :aria-label="link.label"
                                @click="handleClick($event, link.action)"
                            >
                                {{ $t(link.label) }}
                            </a>
                        </v-list-item>
                    </v-list>
                </nav>

                <div class="FooterBar-socialNetwork d-inline-flex justify-center">
                    <template
                        v-for="link in getFooterMenuSocialNetworks"
                        :key="link.alt"
                    >
                        <a
                            :aria-label="link.alt + ' ' + $t('open_new_tab')"
                            :title="link.alt + ' ' + $t('open_new_tab')"
                            :href="sanitizeUrl(link.href)"
                            target="_blank"
                            data-testid="SocialNetworks"
                        >
                            <v-img
                                :key="link.alt"
                                :src="getSocialNetworksImg(link.src)"
                                :alt="link.alt"
                                :title="link.title"
                                width="28"
                                height="28"
                            />
                        </a>
                    </template>
                </div>
            </div>
        </div>
        <!--      MOBILE VIEW       -->
        <v-row class="FooterBar-container-mobile FooterBar-Mobile justify-space-between hidden-md-and-up ma-auto">
            <v-col class="FooterBar-socialNetwork d-flex justify-center">
                <a
                    v-for="link in getFooterMenuSocialNetworks"
                    :key="link.alt"
                    :href="sanitizeUrl(link.href)"
                    target="_blank"
                    :aria-label="link.alt + ' ' + $t('open_new_tab')"
                    :title="link.alt + ' ' + $t('open_new_tab')"
                    class="mx-1 mt-2"
                >
                    <v-img
                        :src="getSocialNetworksImg(link.src)"
                        :alt="link.alt"
                        :title="link.title"
                        width="28"
                        height="28"
                    />
                </a>
            </v-col>

            <v-row class="flex-grow-1 ma-4">
                <v-col class="d-flex align-center pa-0">
                    <router-link
                        :to="{ name: 'home' }"
                        :title="getAppTitle"
                        class="FooterBar-branding"
                        rel="home"
                    >
                        <img
                            class="footerBar-logo"
                            :src="getFooterLogo"
                            alt="footer-logo"
                        >
                    </router-link>
                </v-col>
                <v-col class="pa-0">
                    <nav class="d-flex justify-end">
                        <v-list class="FooterBar-navList pa-0 text-right">
                            <div>
                                <v-list-item
                                    v-for="(link, index) in getFooterMenuLinks"
                                    :key="index"
                                    class="FooterBar-linkContainer"
                                >
                                    <div class="pa-0">
                                        <a
                                            class="FooterBar-link"
                                            :class="{ 'custom-tac-opener': link.action && link.action === 'actionCookiesPanel' }"
                                            :href="link.action && link.action === 'actionCookiesPanel' ? null : sanitizeUrl(link.to)"
                                            :aria-label="link.label"
                                            @click="handleClick($event, link.action)"
                                        >
                                            {{ $t(link.label) }}
                                        </a>
                                    </div>
                                </v-list-item>
                            </div>
                            <v-list-item class="FooterBar-linkContainer pa-0">
                                <div
                                    class="FooterBar-copyright"
                                >
                                    <span class="FooterBar-copyright text-end">
                                        &copy; {{ getCurrentYear() }} Sytral
                                    </span>
                                </div>
                            </v-list-item>
                        </v-list>
                    </nav>
                </v-col>
            </v-row>
        </v-row>
    </footer>
</template>

<script setup>
import { sanitizeUrl } from '@braintree/sanitize-url'
import { isEmpty } from 'global-utils'
import config from 'config'
import { computed } from 'vue'

const getFooterMenuLinks = config?.footer_menu?.links ?? []

const getFooterMenuSocialNetworks = config?.footer_menu?.socialNetworks ?? []

const getAppTitle = computed(() => {
    return !isEmpty(config.app_title) ? config.app_title : ''
})

const getFooterLogo = computed(() => {
    return process.env.VUE_APP_NETWORK_ID
        ? process.env.VUE_APP_CDN_URL + 'img/' + process.env.VUE_APP_NETWORK_ID + '/footer-logo.svg'
        : process.env.VUE_APP_CDN_URL + 'img/default/footer-logo.svg'
})

function handleClick (event, action) {
    if (action === 'actionCookiesPanel') {
        event.preventDefault()
        actionCookiesPanel()
    }
}
function actionCookiesPanel () {
    if (window.tarteaucitron) {
        window.tarteaucitron.userInterface.openPanel()
    }
}

function getSocialNetworksImg (src) {
    return process.env.VUE_APP_CDN_URL + process.env.VUE_APP_NETWORK_ID + '/' + src
}
const getCurrentYear = () => {
    return new Date().getFullYear()
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.v-list {
    background: none !important;
}

.FooterBar {
    border-top: rem-calc(1) solid #e8e8e8;
    background: $footer_background_color;
    color: $footer_color;

    &-branding {
        img {
            width: 91px;
            height: 70px;
        }
    }

    &-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 1300px;
        margin: auto;
        padding-top: 24px;
        padding-bottom: 24px;

        @media (min-width: $tablet-breakpoint) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }

    &-linkContainer {
        min-height: 0 !important;
        margin-bottom: 12px;
        padding-right: 0;
    }

    &-link {
        display: block;
        cursor: pointer;
        font-size: 13px;
        font-weight: normal;
        color: $footer_color !important;
        transition: all .3s;

        &:hover,
        &:active {
            text-decoration: underline;
            color: $footer_color;
        }
    }

    &-nav {
        margin-bottom: 20px;

        @media (min-width: $tablet-breakpoint) {
            flex-grow: 1;
            margin-bottom: 0;
        }
    }

    &-navList {
        min-height: 20px !important;
        margin: 0;
        overflow-x: auto;
        background: $footer_background_color;
        list-style: none;
        white-space: nowrap;

        @media (min-width: $tablet-breakpoint) {
            display: flex;
            flex-direction: row;
        }
    }

    &-navItem {
        margin: 0;

        &:last-child {
            margin-right: 0;
        }

        @media (min-width: $tablet-breakpoint) {
            margin-right: 30px;
        }
    }

    &-copyright {
        padding: 0 16px 0 0;
        font-size: 13px !important;
        color: $footer_color !important;
    }

    &-socialNetwork {
        gap: 30px;
        position: relative;
    }

    &-socialNetwork::before,
    &-socialNetwork::after {
        content: '';
        position: relative;
        top: 50%;
        width: 100%;
        height: 1px;
        background-color: #707173;
    }

    &-socialNetwork::before {
        left: 0;
        margin-left: -20px;
    }

    &-socialNetwork::after {
        right: 0;
        margin-right: -20px;
    }
}
</style>
