<template>
    <component
        :is="'li'"
        class="SaleOptionProviderAccountAndFareMediaChoiceItem mt-2"
    >
        <button
            :class="{ 'SaleOptionProviderAccountAndFareMediaChoiceItem-link--current': isActive }"
            :disabled="disabled"
            class="SaleOptionProviderAccountAndFareMediaChoiceItem-link"
            @click="selectSaleOptionFareMedia"
        >
            <span
                :class="{ 'SaleOptionProviderAccountAndFareMediaChoiceItem-iconWrapper--active': isActive }"
                class="SaleOptionProviderAccountAndFareMediaChoiceItem-iconWrapper"
            >
                <v-icon
                    :class="{ 'SaleOptionProviderAccountAndFareMediaChoiceItem-icon--active': isActive }"
                    class="SaleOptionProviderAccountAndFareMediaChoiceItem-icon"
                >
                    {{ isActive ? `mdi-${mdiIconOnHover}` : `mdi-${mdiIcon}` }}
                </v-icon>
            </span>
            <span class="SaleOptionProviderAccountAndFareMediaChoiceItem-label">
                <strong
                    v-if="fareMedia?.title"
                    class="SaleOptionProviderAccountAndFareMediaChoiceItem-title"
                >
                    {{ fareMedia.title }}
                </strong>
                <span
                    v-if="fareMedia?.subtitle"
                    class="SaleOptionProviderAccountAndFareMediaChoiceItem-subtitle"
                >
                    {{ fareMedia?.subtitle }}
                </span>
            </span>
        </button>
    </component>
</template>

<script setup>
const props = defineProps({
    disabled: {
        type: Boolean,
        default: false
    },
    isActive: {
        type: Boolean,
        default: false
    },
    fareMedia: {
        type: Object,
        required: true
    },
    mdiIcon: {
        type: String,
        default: 'card-account-details-outline'
    },
    mdiIconOnHover: {
        type: String,
        default: 'check'
    },
    description: {
        type: String,
        default: ''
    }
})

const emit = defineEmits(['fareMediaClicked'])

function selectSaleOptionFareMedia () {
    emit('fareMediaClicked', props.fareMedia)
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.SaleOptionProviderAccountAndFareMediaChoiceItem {
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 0;
    }

    &-icon {
        font-size: $font-medium;
        color: $color-brandSecondary;
        transition: color .3s ease-in-out;

        &--active {
            color: $color-white;
        }
    }

    &-link {
        display: flex;
        width: 100%;
        padding: 10px 10px 14px;
        border-radius: $border_radius_medium;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, .2);
        background-color: $color-lightest;
        cursor: pointer;
        text-align: left;
        color: $color-defaultText;
        transition: background-color .3s ease-in-out;

        &:disabled {
            cursor: not-allowed;
        }

        &:hover:not([disabled]),
        &:active:not([disabled]),
        &--current {
            background-color: $color-brandSecondary;
            color: $color-white;

            /* stylelint-disable */
            .SaleOptionProviderAccountAndFareMediaChoiceItem-icon {
                color: $color-white;
            }
            /* stylelint-enable */
        }
    }

    &-iconWrapper {
        @include verticalCenter;

        justify-content: center;
        width: 40px;
        height: 40px;
        margin-right: $margin-xsmall;
        border-radius: $borderRadius-rounded;
        background: rgba(red($color-brandSecondary), green($color-brandSecondary), blue($color-brandSecondary), .1);
        text-align: center;
        transition: background .3s ease-in-out;

        &--active {
            background: rgba(255, 255, 255, .1);
        }
    }

    &:first-child {
        padding-top: 0;
        border-top: none;
    }

    &-label {
        flex-grow: 1;
        line-height: 1.3rem;
    }

    &-title {
        display: block;
        font-family: $fontFamily-title;
        font-size: $font-small;
        font-weight: $fontWeight-titleBold;
    }

    &-subtitle {
        font-size: 12px;
        font-weight: $fontWeight-defaultMedium;
        line-height: 0;
    }
}
</style>
