import i18n from 'i18n'
import { isEmpty } from 'global-utils'

export default class {
    constructor (id, providerId) {
        this._devices = []
        this._eTickets = []
        this._fareMedias = []
        this._fareProfiles = []
        this._firstName = ''
        this._id = id
        this._lastName = ''
        this._products = []
        this._provider = providerId
        this._providerUserExternalId = ''
        this._recipientUser = null
        this._walletName = ''
        this._walletCreateAccount = []
        this._walletCreateThirdPartyAccount = []
    }

    get devices () {
        return this._devices
    }

    set devices (devices) {
        this._devices = devices
    }

    get eTickets () {
        return this._eTickets
    }

    set eTickets (eTickets) {
        this._eTickets = eTickets
    }

    get fareMedias () {
        return this._fareMedias
    }

    set fareMedias (fareMedias) {
        this._fareMedias = fareMedias
    }

    get fareProfiles () {
        return this._fareProfiles
    }

    set fareProfiles (fareProfiles) {
        this._fareProfiles = fareProfiles
    }

    get firstName () {
        return this._firstName
    }

    set firstName (firstName) {
        this._firstName = firstName
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get lastName () {
        return this._lastName
    }

    set lastName (lastName) {
        this._lastName = lastName
    }

    get products () {
        return this._products
    }

    set products (products) {
        this._products = products
    }

    get provider () {
        return this._provider
    }

    set provider (provider) {
        this._provider = provider
    }

    get providerUserExternalId () {
        return this._providerUserExternalId
    }

    set providerUserExternalId (providerUserExternalId) {
        this._providerUserExternalId = providerUserExternalId
    }

    get recipientUser () {
        return this._recipientUser
    }

    set recipientUser (recipientUser) {
        this._recipientUser = recipientUser
    }

    get walletCreateAccount () {
        return this._walletCreateAccount
    }

    set walletCreateAccount (walletCreateAccount) {
        this._walletCreateAccount = walletCreateAccount
    }

    get walletCreateThirdPartyAccount () {
        return this._walletCreateThirdPartyAccount
    }

    set walletCreateThirdPartyAccount (walletCreateThirdPartyAccount) {
        this._walletCreateThirdPartyAccount = walletCreateThirdPartyAccount
    }

    get walletName () {
        return this._walletName
    }

    set walletName (walletName) {
        this._walletName = walletName
    }

    get walletOptions () {
        return this._walletOptions
    }

    set walletOptions (walletOptions) {
        this._walletOptions = walletOptions
    }

    getTitle () {
        return (!isEmpty(this.walletName) ? this.walletName : i18n.global.t('sale_option:basket_info:provider_account')) + ' n°' + this.id
    }

    getFirstName () {
        if (!isEmpty(this.firstName)) {
            return this.firstName
        } else if (!isEmpty(this.recipientUser)) {
            if (!isEmpty(this.recipientUser.firstName)) {
                return this.recipientUser.firstName
            }
        }
        return ''
    }

    getLastName () {
        if (!isEmpty(this.lastName)) {
            return this.lastName
        } else if (!isEmpty(this.recipientUser)) {
            if (!isEmpty(this.recipientUser.lastName)) {
                return this.recipientUser.lastName
            }
        }
        return ''
    }

    getFullName () {
        const firstName = this.getFirstName()
        const lastName = this.getLastName()
        return (firstName !== '' ? firstName + ' ' : '') + (lastName !== '' ? lastName : '')
    }

    toJSON () {
        return {
            devices: this.devices,
            eTickets: this.eTickets,
            fareMedias: this.fareMedias,
            firstName: this.firstName,
            id: this.id,
            lastName: this.lastName,
            products: this.products,
            provider: this.provider,
            providerUserExternalId: this.providerUserExternalId,
            recipientUser: this.recipientUser,
            walletCreateAccount: this.walletCreateAccount,
            walletCreateThirdPartyAccount: this.walletCreateThirdPartyAccount,
            walletName: this.walletName,
            walletOptions: this.walletOptions
        }
    }
}
