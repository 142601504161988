export const SET_ADD_TO_CART_EMAIL = 'setAddToCartEmail'
export const SET_ADD_TO_CART_FLOW = 'setAddToCartFlow'
export const SET_ADD_TO_CART_FLOW_CONTEXT = 'setAddToCartFlowContext'
export const SET_ADD_TO_CART_FLOW_INDEX = 'setAddToCartFlowIndex'
export const SET_ADD_TO_CART_FLOW_STEP = 'setAddToCartFlowStep'
export const SET_IS_ADD_TO_CART_FLOW_FIRST_STEP = 'setIsAddToCartFlowFirstStep'
export const SET_IS_ADD_TO_CART_FLOW_LAST_STEP = 'setIsAddToCartFlowLastStep'
export const SET_IS_GO_TO_NEXT_STEP_DISABLED = 'setGoToNextStepDisabled'
export const SET_IS_GUEST_PURCHASE_FLOW = 'setIsGuestPurchaseFlow'
export const SET_CART = 'setCart'
export const SET_CART_PENDING_REQUEST = 'setCartPendingRequest'
export const SET_SHOW_CART = 'setShowCart'
export const SET_TEMP_ADD_CART_FLOW = 'setTempAddCartFlow'
