<template>
    <component
        :is="tag"
        :class="['FormattedFare--size' + size ]"
        class="FormattedFare"
    >
        <span class="FormattedFare-units">{{ getFareUnits() }}</span>,<span class="FormattedFare-decimals">{{ getFareDecimals() }} </span><span class="FormattedFare-currency">{{ getCurrency() }}</span>
    </component>
</template>

<script setup>
const props = defineProps({
    currency: {
        type: String,
        default: 'EUR'
    },
    fare: {
        type: Number,
        default: 0
    },
    size: {
        type: String,
        default: 'Medium',
        validator: (val) => ['XLarge', 'Large', 'Medium', 'SMedium', 'Small', 'XSmall', 'XXSmall'].includes(val)
    },
    tag: {
        type: String,
        default: 'p'
    }
})

function getCurrency () {
    switch (props.currency) {
        case 'EUR':
            return '€'
        case 'USD':
            return '$'
        case 'GBP':
            return '£'
        default:
            return '€'
    }
}

function getFareDecimals () {
    if (props.fare < 0) {
        return '00'
    }
    const decimals = Math.round((props.fare - getFareUnits()) * 100)
    return decimals === 0 ? '00' : (decimals < 10 ? '0' + decimals : decimals)
}

function getFareUnits () {
    if (props.fare < 0) {
        return 0
    }
    return Math.floor(props.fare)
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

.FormattedFare {
    margin: 0;
    font-size: $font-large;
    font-weight: $fontWeight-defaultBold;
    white-space: nowrap;

    &--sizeXLarge {
        font-size: 40px;
    }

    &--sizeLarge {
        font-size: $font-xlarge;
    }

    &--sizeMedium {
        font-size: $font-large;
    }

    &--sizeSMedium {
        font-size: $font-smedium;
    }

    &--sizeSmall {
        font-size: 20px;
    }

    &--sizeXSmall {
        font-size: 16px;
    }

    &--sizeXXSmall {
        font-size: 14px;
    }
}
</style>
