import * as actionTypes from './action-types'
import * as colorManager from '@/StoreWeb/utils/colorManager'
import * as mutationTypes from './mutation-types'
import { customizeMobileTheme } from '@/StoreWeb/js/head-customization'
import DocumentManager from '@/StoreWeb/managers/DocumentManagerSingleton'
import config from 'config'
const documentManager = DocumentManager.getInstance()

export default {
    /**
     * Init
     * @param {Object} context.commit
     */
    [actionTypes.INIT] ({ commit }) {
        // TODO : Use values from instant-config yaml files to import these colors (cf. import/configuration/import-environment-variables-from-config.js)
        const primaryColor = config.theme.primary_color
        customizeMobileTheme(primaryColor)

        const primaryColorLighten = colorManager.hexToRgbA(primaryColor, 0.5)
        const primaryColorDarken = colorManager.lightenDarkenColor(primaryColor, -30)

        commit(mutationTypes.SET_MAIN_COLOR, primaryColor)
        commit(mutationTypes.SET_MAIN_COLOR_LIGHT, primaryColorLighten)
        commit(mutationTypes.SET_MAIN_COLOR_DARK, primaryColorDarken)
    },
    /**
     * Get catalog
     * @param params
     */
    [actionTypes.UPLOAD_DOCUMENT] (...args) {
        const [, params] = args
        return documentManager.uploadDocumentAction(params)
    }
}
