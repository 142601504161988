import Address from '@/StoreWeb/models/address/Address'
import FormNormalizer from '@/StoreWeb/normalizers/form/FormNormalizerSingleton'
import { isEmpty } from 'global-utils'

export default class {
    constructor () {
        this._formNormalizer = FormNormalizer.getInstance()
    }

    get formNormalizer () {
        return this._formNormalizer
    }

    normalize (item) {
        const address = new Address()

        address.city = item.city
        address.country = item.country
        address.creationDate = item.creationDate
        address.defaultAddress = item.defaultAddress
        address.id = item.id
        address.networkId = item.networkId
        address.postalBox = item.postalBox
        address.depositName = item.depositName
        address.receiverFirstname = item.receiverFirstname
        address.receiverLastname = item.receiverLastname
        address.state = item.state
        address.streetLine2 = item.streetLine2
        address.streetLine3 = item.streetLine3
        address.streetName = item.streetName
        address.streetNumber = item.streetNumber
        address.streetNumberSuffix = item.streetNumberSuffix
        address.streetType = item.streetType
        if (!isEmpty(item.template)) {
            address.template = this.formNormalizer.normalize(item.template)
        }
        if (!isEmpty(item.type)) {
            address.type = item.type
        }
        address.updateDate = item.updateDate
        address.userId = item.userId
        address.zipCode = item.zipCode

        return address
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
