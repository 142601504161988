import PersonalInfo from '@/StoreWeb/models/user/personal-info/PersonalInfo'
import IdValuePatternNormalizer from '@/StoreWeb/normalizers/patterns/IdValuePatternNormalizerSingleton'
import { isEmpty } from 'global-utils'

export default class {
    constructor () {
        this._idValuePatternNormalizer = IdValuePatternNormalizer.getInstance()
    }

    get idValuePatternNormalizer () {
        return this._idValuePatternNormalizer
    }

    normalize (item) {
        const personalInfo = new PersonalInfo(item.id)

        personalInfo.birthDate = item.birthDate.split('T')[0]
        personalInfo.civility = item.civility
        personalInfo.email = item.email
        personalInfo.firstName = item.firstName
        if (!isEmpty(item.landlinePhoneNumber)) {
            personalInfo.landlinePhoneNumber = this.idValuePatternNormalizer.normalize(item.landlinePhoneNumber)
        }
        if (!isEmpty(item.mobilePhoneNumber)) {
            personalInfo.mobilePhoneNumber = this.idValuePatternNormalizer.normalize(item.mobilePhoneNumber)
        }
        personalInfo.lastName = item.lastName

        return personalInfo
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
