<template>
    <div class="DirectDebits">
        <v-tabs
            v-model="selectedTab"
            align-tabs="center"
            color="primary"
            @update:model-value="onTabChanged"
        >
            <v-tab value="past">
                {{ $t('history') }}
            </v-tab>
            <v-tab value="future">
                {{ $t('coming_soon') }}
            </v-tab>
        </v-tabs>

        <v-tabs-window v-model="selectedTab">
            <v-tabs-window-item
                class="pt-5"
                value="past"
            >
                <div
                    v-if="showPastDirectDebits"
                    class="DirectDebit-loader"
                >
                    <v-progress-circular
                        indeterminate
                        color="primary"
                    />
                </div>
                <div v-else-if="!isEmpty(getPastDirectDebitsByYear)">
                    <ul class="DirectDebit-yearList">
                        <li
                            v-for="(year, yearIndex) in getPastDirectDebitsByYear"
                            :key="yearIndex"
                            class="DirectDebit-yearItem mt-8"
                        >
                            <span class="DirectDebit-yearItemLabel">{{ year.label }}</span>
                            <ul
                                v-if="providerAccount"
                                class="DirectDebit-list"
                            >
                                <direct-debit-item
                                    v-for="(directDebit, index) in year.directDebits"
                                    :key="index"
                                    :directDebit="directDebit"
                                    :providerUserId="providerAccount.id"
                                    :providerUserExternalId="providerAccount.providerUserExternalId"
                                    class="DirectDebit-item"
                                />
                            </ul>
                        </li>
                    </ul>
                    <pagination
                        v-if="pastDirectDebitsHasPagination"
                        :items="[]"
                        :currentPage="pastDirectDebitsCurrentPage"
                        :elementsByPage="getPastDirectDebitsElementsByPage"
                        :showItemsPerPageSelector="false"
                        :totalPages="getPastDirectDebitsNumberOfPages"
                        @update-current-page="updatePastDirectDebitsCurrentPage"
                    />
                </div>
                <v-alert
                    v-else
                    color="info"
                    density="compact"
                    prominent
                    text
                    variant="tonal"
                >
                    {{ $t('account:direct_debits:past_empty_list') }}
                </v-alert>
            </v-tabs-window-item>
            <v-tabs-window-item
                class="pt-5"
                value="future"
            >
                <div
                    v-if="showFutureDirectDebits"
                    class="DirectDebit-loader"
                >
                    <v-progress-circular
                        indeterminate
                        color="primary"
                    />
                </div>
                <div v-else-if="!isEmpty(getFutureDirectDebitsByYear)">
                    <v-alert
                        v-if="t('future_direct_debit:description') !== 'future_direct_debit:description'"
                        type="info"
                        variant="tonal"
                    >
                        {{ $t('future_direct_debit:description') }}
                    </v-alert>
                    <ul class="DirectDebit-yearList">
                        <li
                            v-for="(year, yearIndex) in getFutureDirectDebitsByYear"
                            :key="yearIndex"
                            class="DirectDebit-yearItem mt-6"
                        >
                            <span class="DirectDebit-yearItemLabel">{{ year.label }}</span>
                            <ul class="DirectDebit-list">
                                <direct-debit-item
                                    v-for="(directDebit, index) in year.directDebits"
                                    :key="index"
                                    :directDebit="directDebit"
                                    :providerUserId="providerAccount.id"
                                    :providerUserExternalId="providerAccount.providerUserExternalId"
                                    class="DirectDebit-item"
                                />
                            </ul>
                        </li>
                    </ul>
                    <pagination
                        v-if="futureDirectDebitsHasPagination"
                        :items="[]"
                        :currentPage="futureDirectDebitsCurrentPage"
                        :elementsByPage="getFutureDirectDebitsElementsByPage"
                        :showItemsPerPageSelector="false"
                        :totalPages="getFutureDirectDebitsNumberOfPages"
                        @update-current-page="updateFutureDirectDebitsCurrentPage"
                    />
                </div>
                <v-alert
                    v-else
                    color="info"
                    density="compact"
                    prominent
                    text
                    variant="tonal"
                >
                    {{ $t('account:direct_debits:future_empty_list') }}
                </v-alert>
            </v-tabs-window-item>
        </v-tabs-window>
    </div>
</template>

<script setup>
import { isEmpty } from 'global-utils'
import Pagination from '@/StoreWeb/components/common/Pagination'
import DirectDebitItem from '@/StoreWeb/components/account/direct-debit/DirectDebitItem'
import PageConfigManager from '@/StoreWeb/managers/PageConfigManagerSingleton'
import * as userActionTypes from '@/StoreWeb/store/modules/user/action-types'
import emitter from 'global-emitter'
import { useStore } from 'vuex'
import { computed, onMounted, ref } from 'vue'
import router from '@/router/router'
import { useI18n } from 'vue-i18n'
import ErrorManager from '@/StoreWeb/managers/ErrorManager'
import moment from 'moment'
import { useRoute } from 'vue-router'

const store = useStore()
const route = useRoute()
const { t } = useI18n()
const pageConfigManager = PageConfigManager.getInstance()

const pastDirectDebitsCurrentPage = ref(1)
const futureDirectDebitsCurrentPage = ref(1)
const selectedTab = ref('past')

const getWalletProviderAccounts = computed(() => store.getters.getWalletProviderAccounts)
const getPastDirectDebitsByYear = computed(() => store.getters.getPastDirectDebitsByYear)
const getFutureDirectDebitsByYear = computed(() => store.getters.getFutureDirectDebitsByYear)
const pastDirectDebitsPagination = computed(() => store.state.userModule.pastDirectDebits)
const futureDirectDebitsPagination = computed(() => store.state.userModule.futureDirectDebits)
const userPendingRequests = computed(() => store.state.userModule.userPendingRequests)
const providerAccount = ref()

const getPastDirectDebitsElementsByPage = computed(() => {
    if (!isEmpty(pastDirectDebitsPagination.value)) {
        return pastDirectDebitsPagination.value.size
    }
    return 20
})

const getFutureDirectDebitsElementsByPage = computed(() => {
    if (!isEmpty(futureDirectDebitsPagination.value)) {
        return futureDirectDebitsPagination.value.size
    }
    return 20
})

const getPastDirectDebitsNumberOfPages = computed(() => {
    if (!isEmpty(pastDirectDebitsPagination.value)) {
        return pastDirectDebitsPagination.value.totalPages
    }
    return 1
})

const getFutureDirectDebitsNumberOfPages = computed(() => {
    if (!isEmpty(futureDirectDebitsPagination.value)) {
        return futureDirectDebitsPagination.value.totalPages
    }
    return 1
})

const pastDirectDebitsHasPagination = computed(() => {
    return !isEmpty(pastDirectDebitsPagination.value) && pastDirectDebitsPagination.value.totalPages > 1
})

const futureDirectDebitsHasPagination = computed(() => {
    return !isEmpty(futureDirectDebitsPagination.value) && futureDirectDebitsPagination.value.totalPages > 1
})

const showPastDirectDebits = computed(() => {
    return selectedTab.value === 'past' &&
        (userPendingRequests.value.getWallet || userPendingRequests.value.getPastDirectDebits)
})

const showFutureDirectDebits = computed(() => {
    return selectedTab.value === 'future' &&
        (userPendingRequests.value.getWallet || userPendingRequests.value.getFutureDirectDebits)
})

onMounted(() => {
    getWalletProviderAccounts.value.forEach(account => {
        if (isEmpty(providerAccount) && account.provider === 'conduent') {
            providerAccount.value = account
        }
    })

    if (!isEmpty(getWalletProviderAccounts.value)) {
        getDirectDebits(selectedTab.value)
    }

    pageConfigManager.setConfig({
        title: 'account:direct_debits:title',
        currentAccountPage: 'directDebits'
    })

    const tab = route.params.tab
    if (!isEmpty(tab) && ['past', 'future'].includes(tab)) {
        selectedTab.value = tab
    }

    emitter.on('walletLoaded', () => {
        getDirectDebits(selectedTab.value)
    })

    emitter.on('futureDirectDebitsLoaded', () => {
        router.push({
            name: 'directDebits',
            params: {
                tab: 'future',
                page: futureDirectDebitsCurrentPage.value
            }
        }).catch((error) => {
            if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
                ErrorManager.displayErrorModal(error.message, error)
            }
        })
    })

    emitter.on('pastDirectDebitsLoaded', () => {
        router.push({
            name: 'directDebits',
            params: {
                tab: 'past',
                page: pastDirectDebitsCurrentPage.value
            }
        }).catch((error) => {
            if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
                ErrorManager.displayErrorModal(error.message, error)
            }
        })
    })
})
const getDirectDebits = async (tab) => {
    providerAccount.value = selectConduentProviderAccount()
    if (isEmpty(providerAccount.value)) return

    const payload = {
        providerId: providerAccount.value.provider,
        providerUserId: providerAccount.value.id,
        providerUserExternalId: providerAccount.value.providerUserExternalId,
        page: tab === 'past' ? pastDirectDebitsCurrentPage.value : futureDirectDebitsCurrentPage.value
    }

    if (tab === 'past') {
        payload.endDate = moment().subtract(1, 'days').format('YYYY-MM-DD')
    } else {
        payload.startDate = moment().format('YYYY-MM-DD')
    }

    await store.dispatch(userActionTypes.GET_DIRECT_DEBITS, payload)
}

const selectConduentProviderAccount = () => {
    let providerAccount
    getWalletProviderAccounts.value.forEach(account => {
        if (isEmpty(providerAccount) && account.provider === 'conduent') {
            providerAccount = account
        }
    })
    return providerAccount
}

const updatePastDirectDebitsCurrentPage = (page) => {
    if (page !== pastDirectDebitsCurrentPage.value) {
        pastDirectDebitsCurrentPage.value = page
        getDirectDebits('past')
    }
}

const updateFutureDirectDebitsCurrentPage = (page) => {
    if (page !== futureDirectDebitsCurrentPage.value) {
        futureDirectDebitsCurrentPage.value = page
        getDirectDebits('future')
    }
}

const onTabChanged = (tab) => {
    getDirectDebits(tab)
}

</script>

<style lang="scss" scoped>
@import 'globalScss';

.DirectDebit {
    &-yearList,
    &-list {
        margin: 0 0 20px;
        padding: 0;
        list-style: none;
    }

    &-yearItemLabel {
        display: block;
        margin-bottom: 24px;
        font-family: $font_family_title;
        font-size: $font-xlarge;
        font-weight: 700;
    }

    &-loader {
        width: 100%;
        margin-bottom: $s4;
        text-align: center;
    }
}
</style>
