import { isEmpty } from 'global-utils'
import i18n from 'i18n'
import FareMedia from '@/StoreWeb/models/user/wallet/FareMedia'

export default class {
    constructor () {
        this._associatedAccounts = []
        this._counters = []
        this._lastSelectedFareMediaId = ''
        this._plans = []
        this._providers = []
        this._userStatus = 'ACTIVE'
    }

    get associatedAccounts () {
        return this._associatedAccounts
    }

    set associatedAccounts (associatedAccounts) {
        this._associatedAccounts = associatedAccounts
    }

    get counters () {
        return this._counters
    }

    set counters (counters) {
        this._counters = counters
    }

    get lastSelectedFareMediaId () {
        return this._lastSelectedFareMediaId
    }

    set lastSelectedFareMediaId (lastSelectedFareMediaId) {
        this._lastSelectedFareMediaId = lastSelectedFareMediaId
    }

    get plans () {
        return this._plans
    }

    set plans (plans) {
        this._plans = plans
    }

    get providers () {
        return this._providers
    }

    set providers (providers) {
        this._providers = providers
    }

    get userStatus () {
        return this._userStatus
    }

    set userStatus (userStatus) {
        this._userStatus = userStatus
    }

    filterSupports (supports, filterConditions) {
        let filteredSupports = []
        if (!isEmpty(filterConditions)) {
            if (!isEmpty(filterConditions.provider)) {
                filteredSupports = []
                supports.forEach(support => {
                    if (!isEmpty(support.provider) && support.provider.id === filterConditions.provider) {
                        filteredSupports.push(support)
                    }
                })
                supports = filteredSupports
            }
            if (!isEmpty(filterConditions.statuses)) {
                filteredSupports = []
                supports.forEach(support => {
                    if (isEmpty(support.status) || filterConditions.statuses.includes(support.status.id)) {
                        filteredSupports.push(support)
                    }
                })
                supports = filteredSupports
            } else if (!isEmpty(filterConditions.status)) {
                filteredSupports = []
                supports.forEach(support => {
                    if (isEmpty(support.status) || support.status.id === filterConditions.status) {
                        filteredSupports.push(support)
                    }
                })
                supports = filteredSupports
            }
            if (!isEmpty(filterConditions.correspondingBasketInfos)) {
                filteredSupports = []
                if (Array.isArray(filterConditions.correspondingBasketInfos)) {
                    let isMatching
                    supports.forEach(support => {
                        isMatching = false
                        filterConditions.correspondingBasketInfos.forEach(correspondingBasketInfo => {
                            if (support.correspondingBasketInfos.includes(correspondingBasketInfo)) {
                                isMatching = true
                            }
                        })
                        if (isMatching) {
                            filteredSupports.push(support)
                        }
                    })
                } else {
                    supports.forEach(support => {
                        if (support.correspondingBasketInfos.includes(filterConditions.correspondingBasketInfos)) {
                            filteredSupports.push(support)
                        }
                    })
                }

                supports = filteredSupports
            }
        }

        return supports
    }

    getSupports (filterConditions) {
        let walletSupports = []

        if (!isEmpty(this.providers)) {
            let item
            let i = 0
            this.providers.forEach(walletProvider => {
                if (!isEmpty(walletProvider.accounts)) {
                    walletProvider.accounts.forEach(providerAccount => {
                        item = {
                            account: providerAccount,
                            correspondingBasketInfos: ['PROVIDERACCOUNT'],
                            correspondingTargets: ['ACCOUNT'],
                            provider: walletProvider,
                            icon: 'Phone',
                            index: i,
                            recipientUser: providerAccount.recipientUser,
                            title: this.getProviderAccountTitle(providerAccount),
                            subtitle: ''
                        }

                        walletSupports.push(item)
                        i++
                        if (!isEmpty(providerAccount.fareMedias)) {
                            providerAccount.fareMedias.forEach(fareMedia => {
                                if (FareMedia.activeStatuses.includes(fareMedia.status.id)) {
                                    item = {
                                        account: providerAccount,
                                        correspondingBasketInfos: ['PROVIDERACCOUNTANDFAREMEDIA', 'PROVIDERFAREMEDIA'],
                                        correspondingTargets: ['CARD'],
                                        fareMedia,
                                        icon: 'Card',
                                        index: i,
                                        provider: walletProvider,
                                        recipientUser: providerAccount.recipientUser,
                                        title: fareMedia.getTitle(),
                                        subtitle: fareMedia.getSubtitle()
                                    }

                                    walletSupports.push(item)
                                    i++
                                }
                            })
                        }
                    })
                }
            })
        }

        if (!isEmpty(filterConditions)) {
            walletSupports = this.filterSupports(walletSupports, filterConditions)
        }

        return walletSupports
    }

    getProviderAccountTitle (providerAccount) {
        if (!isEmpty(providerAccount.firstName) || !isEmpty(providerAccount.lastName)) {
            return i18n.global.t('sale_option:basket_info:provider_account:title_with_name').replace('%walletName%', providerAccount.walletName).replace('%fullName%', this.getFullName(providerAccount.firstName, providerAccount.lastName)) + ' - n°' + providerAccount.id
        } else if (!isEmpty(providerAccount.recipientUser) && (!isEmpty(providerAccount.recipientUser.firstName) || !isEmpty(providerAccount.recipientUser.lastName))) {
            return i18n.global.t('sale_option:basket_info:provider_account:title_with_name').replace('%walletName%', providerAccount.walletName).replace('%fullName%', this.getFullName(providerAccount.recipientUser.firstName, providerAccount.recipientUser.lastName)) + ' - n°' + providerAccount.id
        }
        return i18n.global.t('sale_option:basket_info:provider_account:title').replace('%walletName%', providerAccount.walletName).replace('%accountId%', providerAccount.id)
    }

    getFullName (firstName, lastName) {
        return (typeof firstName === 'string' ? firstName.ucFirst() + ' ' : '') + (typeof lastName === 'string' ? lastName.ucFirst() : '')
    }

    getAssociatedSupports (filterConditions) {
        let walletSupports = []

        if (!isEmpty(this.associatedAccounts)) {
            this.associatedAccounts.forEach(associatedAccount => {
                if (!isEmpty(associatedAccount.providers)) {
                    let item
                    let i = 1
                    associatedAccount.providers.forEach(walletProvider => {
                        if (!isEmpty(walletProvider.accounts)) {
                            walletProvider.accounts.forEach(providerAccount => {
                                item = {
                                    account: providerAccount,
                                    correspondingBasketInfos: ['PROVIDERACCOUNT'],
                                    correspondingTargets: ['ACCOUNT'],
                                    provider: walletProvider,
                                    recipientUser: providerAccount.recipientUser,
                                    icon: 'Phone',
                                    index: i,
                                    title: this.getProviderAccountTitle(providerAccount),
                                    subtitle: ''
                                }

                                walletSupports.push(item)
                                i++
                                if (!isEmpty(providerAccount.fareMedias)) {
                                    providerAccount.fareMedias.forEach(fareMedia => {
                                        if (FareMedia.activeStatuses.includes(fareMedia.status.id)) {
                                            item = {
                                                account: providerAccount,
                                                correspondingBasketInfos: ['PROVIDERACCOUNTANDFAREMEDIA', 'PROVIDERFAREMEDIA'],
                                                correspondingTargets: ['CARD'],
                                                fareMedia,
                                                icon: 'Card',
                                                index: i,
                                                provider: walletProvider,
                                                recipientUser: providerAccount.recipientUser,
                                                title: fareMedia.getTitle(),
                                                subtitle: fareMedia.getSubtitle()
                                            }

                                            walletSupports.push(item)
                                            i++
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })
        }

        if (!isEmpty(filterConditions)) {
            walletSupports = this.filterSupports(walletSupports, filterConditions)
        }

        return walletSupports
    }

    getAllSupports (filterConditions) {
        let walletSupports = this.getSupports().concat(this.getAssociatedSupports())

        if (!isEmpty(filterConditions)) {
            walletSupports = this.filterSupports(walletSupports, filterConditions)
        }

        return walletSupports
    }

    hasDevices () {
        return this.providers.some(provider => {
            return provider.hasDevices()
        }) ||
        this.associatedAccounts.some(associatedAccount => {
            return associatedAccount.hasDevices()
        })
    }

    hasETickets () {
        return this.providers.some(provider => {
            return provider.hasETickets()
        }) ||
        this.associatedAccounts.some(associatedAccount => {
            return associatedAccount.hasETickets()
        })
    }

    hasFareMedias () {
        return this.providers.some(provider => {
            return provider.hasFareMedias()
        }) ||
        this.associatedAccounts.some(associatedAccount => {
            return associatedAccount.hasFareMedias()
        })
    }

    toJSON () {
        return {
            associatedAccounts: this.associatedAccounts,
            counters: this.counters,
            lastSelectedFareMediaId: this.lastSelectedFareMediaId,
            plans: this.plans,
            providers: this.providers,
            userStatus: this.userStatus
        }
    }
}
