import FormField from '@/StoreWeb/models/form/FormField'
import FormFieldParamsNormalizer from '@/StoreWeb/normalizers/form/FormFieldParamsNormalizerSingleton'
import { isEmpty } from 'global-utils'
import FormFieldParams from '@/StoreWeb/models/form/FormFieldParams'
import i18n from 'i18n'
import moment from 'moment'

export default class {
    constructor () {
        this._formFieldParamsNormalizer = FormFieldParamsNormalizer.getInstance()
    }

    get formFieldParamsNormalizer () {
        return this._formFieldParamsNormalizer
    }

    normalize (item) {
        const field = new FormField(item.id, item.type)

        field.afterAssetPlaceholder = item.afterAssetPlaceholder ?? ''
        field.beforeAssetPlaceholder = item.beforeAssetPlaceholder ?? ''
        if (!isEmpty(item.description)) {
            field.description = item.description
        }
        if (!isEmpty(item.label)) {
            field.label = item.label
        }
        if (!isEmpty(item.params)) {
            field.params = this.formFieldParamsNormalizer.normalize(item.params)
        }
        if (!isEmpty(item.placeholder)) {
            field.placeholder = item.placeholder
        }
        if (!isEmpty(item.readonly)) {
            field.readonly = item.readonly
        }
        if (!isEmpty(item.required)) {
            field.required = item.required
        }
        if (!isEmpty(item.requiredConditions)) {
            // TODO : Use a normalizer once everything will be specified
            field.requiredConditions = item.requiredConditions
        }
        if (!isEmpty(item.tooltip)) {
            field.tooltip = item.tooltip
        }
        if (!isEmpty(item.value)) {
            field.value = item.value
        } else if (field.type === 'boolean') {
            field.value = false
        }

        return field
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }

    normalizeFromAltFormat (item, value) {
        const supportedTypes = [
            'birthdate',
            'date',
            'email',
            'tel',
            'text',
            'textarea'
        ]
        const currentDate = moment().format('YYYY-MM-DD')
        let type = 'text'
        let subType = 'text'
        let maxDate = null
        let id

        if (!isEmpty(item.name)) {
            id = item.name
        } else {
            return false
        }
        if (!isEmpty(item.type) && supportedTypes.indexOf(item.type) !== -1) {
            switch (item.type) {
                case 'birthdate':
                    type = 'date'
                    subType = 'birthdate'
                    maxDate = currentDate
                    break
                case 'date':
                    type = 'date'
                    subType = 'date'
                    maxDate = (item.name === 'birthDate') ? currentDate : null
                    break
                case 'email':
                    type = 'text'
                    subType = 'email'
                    break
                case 'tel':
                    type = 'text'
                    subType = 'tel'
                    break
                case 'text':
                    type = 'text'
                    subType = 'text'
                    break
                case 'textarea':
                    type = 'text'
                    subType = 'textarea'
                    break
                default:
                    return false
            }
        }
        const formattedField = new FormField(id, type)
        formattedField.params = new FormFieldParams()
        formattedField.params.subType = subType

        const labelKey = `kas_personal_info:edit:${item.name.toLowerCase()}`
        formattedField.label = i18n.global.t(labelKey) !== labelKey ? i18n.global.t(labelKey) : item.name

        if (!isEmpty(item.validations)) {
            formattedField.params.maxDate = !isEmpty(item.validations.maxDate) ? item.validations.maxDate : (!isEmpty(maxDate) ? maxDate : null)
            formattedField.params.maxLength = item.validations.maxLength ?? null
            formattedField.params.minDate = item.validations.minDate ?? null
            formattedField.params.minLength = item.validations.minLength ?? null
            formattedField.params.pattern = item.validations.pattern ?? null

            if (!isEmpty(item.validations.readOnly)) {
                formattedField.readonly = item.validations.readOnly
            }
            if (!isEmpty(item.validations.required)) {
                formattedField.required = item.validations.required
            }
        }

        if (typeof value !== 'undefined') {
            formattedField.value = value
        }

        return formattedField
    }

    normalizeArrayFromAltFormat (items, values) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalizeFromAltFormat(item, values[item.name] ?? undefined))
            })
        }

        return normalizedItems
    }
}
