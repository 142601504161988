import ApplicationSession from '@/StoreWeb/models/application-session/ApplicationSession'
import { isEmpty } from 'global-utils'

export default class {
    normalize (item) {
        const applicationSession = new ApplicationSession()

        applicationSession.accessDate = item.accessDate ?? ''
        applicationSession.application = item.application ?? 'STORE'
        applicationSession.creationDate = item.creationDate ?? ''
        applicationSession.updateDate = item.updateDate ?? ''

        if (!isEmpty(item.data)) {
            applicationSession.data = {}

            for (const [key, value] of Object.entries(item.data)) {
                applicationSession.data[key] = this.parseValue(value)
            }
        }

        if (!isEmpty(item.id)) {
            applicationSession.id = item.id
        }

        if (!isEmpty(item.sessionContext)) {
            applicationSession.sessionContext = {}

            for (const [key, value] of Object.entries(item.sessionContext)) {
                applicationSession.sessionContext[key] = decodeURI(value)
            }
        }

        applicationSession.updateDate = item.updateDate ?? ''

        return applicationSession
    }

    parseValue (value) {
        return JSON.parse(value)
    }
}
