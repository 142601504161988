<template>
    <main class="MandateSignatureCancelled">
        <div class="Container MandateSignatureCancelled-container">
            <h1 class="MandateSignatureCancelled-title fadeInAfterIcon">
                {{ $t('mandate_signature:cancelled:title') }}
            </h1>
            <svg
                class="MandateSignatureCancelled-illust"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 130.2 130.2"
            >
                <circle
                    class="path circle"
                    fill="none"
                    stroke-width="6"
                    stroke-miterlimit="10"
                    cx="65.1"
                    cy="65.1"
                    r="62.1"
                />
                <line
                    class="path line"
                    fill="none"
                    stroke-width="6"
                    stroke-linecap="round"
                    stroke-miterlimit="10"
                    x1="34.4"
                    y1="37.9"
                    x2="95.8"
                    y2="92.3"
                />
                <line
                    class="path line"
                    fill="none"
                    stroke-width="6"
                    stroke-linecap="round"
                    stroke-miterlimit="10"
                    x1="95.8"
                    y1="38"
                    x2="34.4"
                    y2="92.2"
                />
            </svg>
            <p class="MandateSignatureCancelled-explanations fadeInAfterIcon">
                {{ $t('mandate_signature:redirection_message') }}
            </p>
            <router-link
                :to="{ name: 'paymentMeans' }"
                class="MandateSignatureCancelled-backToPaymentMeansLink fadeInAfterIcon"
            >
                {{ $t('mandate_signature:back_to_list') }}
            </router-link>
        </div>
    </main>
</template>

<script>
export default {
    mounted () {
        setTimeout(() => {
            this.$router.push({ name: 'paymentMeans' })
        }, 7000)
    }
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

.MandateSignatureCancelled {
    background: #fff;
    text-align: center;

    &-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: 80px;
    }

    &-title {
        @extend %h2;

        color: $color-defaultText;
    }

    &-illust {
        display: block;
        width: 120px;
        margin: 0 auto 20px;
        stroke: $color-gray;
    }

    &-explanations {
        margin: 0 0 30px;
        font-size: 14px;
        color: $color-lighterText;
    }

    &-backToPaymentMeansLink {
        display: inline-flex;
        padding: 10px;
        font-size: 14px;
        font-weight: bold;
    }
}
</style>
