<template>
    <div
        :class="{ 'QuantitySelector--noTitle': title === '' }"
        class="QuantitySelector"
    >
        <h2
            v-if="title !== ''"
            class="QuantitySelector-title"
        >
            {{ title }}
        </h2>
        <div class="QuantitySelector-quantityEditorWrapper">
            <div class="QuantitySelector-quantityEditor">
                <a
                    class="QuantitySelector-button QuantitySelector-less"
                    @click="decrement"
                >
                    <span class="QuantitySelector-buttonIcon IconLess" />
                </a>
                <input
                    v-model="quantity"
                    :min="min"
                    :max="max"
                    class="QuantitySelector-value"
                    type="text"
                    @change="onChangeQuantity"
                >
                <a
                    class="QuantitySelector-button QuantitySelector-more"
                    @click="increment"
                >
                    <span class="QuantitySelector-buttonIcon IconMore" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { isEmpty } from 'global-utils'
import AddToCartFlowManager from '@/StoreWeb/managers/AddToCartFlowManagerSingleton'

export default {
    components: {},
    props: {
        max: {
            type: Number,
            default: 0
        },
        min: {
            type: Number,
            default: 1
        },
        title: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            addToCartFlowManager: AddToCartFlowManager.getInstance(),
            quantity: 0
        }
    },
    mounted () {
        if (
            !isEmpty(this.addToCartFlowManager.currentStep) &&
            !isEmpty(this.addToCartFlowManager.currentStep.selectedQuantity)
        ) {
            this.quantity = this.addToCartFlowManager.currentStep.selectedQuantity
        } else {
            this.quantity = this.min
        }
    },
    methods: {
        decrement () {
            if (this.quantity > this.min) {
                this.quantity--
                this.$emit('updateQuantity', this.quantity)
            }
        },
        increment () {
            if (this.quantity < this.max || this.max === 0) {
                this.quantity++
                this.$emit('updateQuantity', this.quantity)
            }
        },
        onChangeQuantity () {
            const quantity = parseInt(this.quantity.trim())
            if (isNaN(quantity)) {
                this.quantity = this.min
            } else if (this.min <= quantity && (this.max === 0 || this.max >= quantity)) {
                this.quantity = quantity
            } else {
                this.quantity = this.min
            }
            this.$emit('updateQuantity', this.quantity)
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.QuantitySelector {
    &-title {
        margin: 0 0 10px;
        font-size: 18px;
    }

    &-quantityEditor {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 12px 30px;
        border-radius: $border-radius-small;
        background: $color-lightgray1;

        &Wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 20px;
        }
    }

    &-button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 24px;
        background: $color-brandPrimary;
        cursor: pointer;
        color: $color-lightest;
        transition: all .3s;

        &:hover,
        &:active {
            background: $color-brandPrimaryDark;
        }
    }

    &-buttonIcon {
        font-size: 24px;
    }

    &-value {
        width: 30px;
        height: 30px;
        margin: 0 12px;
        padding: 0 2px;
        border: none;
        border-radius: $border-radius-xsmall;
        background: none;
        font-size: 24px;
        font-weight: $fontWeight-defaultBold;
        text-align: center;
        color: $color-lightText;
        transition: all .3s;

        &:hover,
        &:active {
            background: $color-lightest;
        }
    }

    &--noTitle {
        .QuantitySelector-quantityEditorWrapper {
            padding-top: 0;
        }
    }
}

.CartPackageItem-quantity,
.CartItem-quantity {
    &.QuantitySelector {
        padding: 0;
        background: none;
    }

    .QuantitySelector {
        &-button {
            width: 20px;
            height: 20px;
            border-radius: 20px;
        }

        &-buttonIcon {
            font-size: 20px;
        }

        &-value {
            width: 24px;
            height: 20px;
            margin: 0 4px;
            font-size: 16px;
        }
    }
}
</style>
