<template>
    <div
        :class="{ 'FineFormButtons-navigation--withBackButton' : displayBackButton }"
        class="FineFormButtons-navigation"
    >
        <v-btn
            v-if="displayBackButton"
            :size="$vuetify.display.mdAndUp ? 'large' : 'Small'"
            type="submit"
            color="primary"
            elevation="0"
            @click.prevent="clickOnBackButton"
        >
            {{ $t('button:back') }}
        </v-btn>
        <v-btn
            :size="$vuetify.display.mdAndUp ? 'large' : 'Small'"
            type="submit"
            elevation="0"
            class="bg-primary"
        >
            {{ $t('button:validate') }}
        </v-btn>
    </div>
</template>

<script>
export default {
    props: {
        displayBackButton: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        clickOnBackButton () {
            this.$emit('clickOnBackButton')
        }
    }
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

.FineFormButtons {
    &-navigation {
        padding-top: 20px;
        text-align: center;

        &Button {
            margin-bottom: 20px;
        }
    }
}

@media (min-width: $tablet-breakpoint) {
    .FineFormButtons {
        &-navigation {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-top: 20px;

            &Button {
                min-width: 45%;
                margin: 0;
            }

            &--withBackButton {
                justify-content: space-between;
            }
        }
    }
}
</style>
