import * as mutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import { isOrContainsTarget } from 'global-utils'

export default class {
    constructor () {
        this._store = null
    }

    get store () {
        return this._store
    }

    set store (store) {
        this._store = store
    }

    onMainNavClickOutside (event, element) {
        if (!isOrContainsTarget(event.target, element.querySelector('.ToggleNavButton'))) {
            this.hideMainNav()
        }
    }

    hideMainNav () {
        this.store.commit(mutationTypes.SET_SHOW_MAIN_NAV, false)
    }
}
