<template>
    <div class="UserAccountSubscriptionModal">
        <alert-message
            v-if="subscriptionAccountErrorMessage !== ''"
            :message="subscriptionAccountErrorMessage"
            class="UserAccountSubscriptionModal-errorMessage"
            type="error"
        />
        <modal
            v-model="showModal"
            :title="$t('account:new_subscription:title')"
        >
            <div class="UserAccountSubscriptionModal-body">
                <div class="UserAccountSubscriptionModal-conditions">
                    {{ $t('account:new_subscription:conditions_terms') }}
                </div>
            </div>
            <label
                class="SubscriptionTermsCheckbox"
                for="UserAccountSubscriptionModalTerms"
            >
                <span class="SubscriptionTermsCheckbox-container">
                    <input
                        id="UserAccountSubscriptionModalTerms"
                        v-model="isSubscriptionTermsAccepted"
                        alt="checkbox"
                        class="SubscriptionTermsCheckbox-input"
                        type="checkbox"
                    >
                    <span class="SubscriptionTermsCheckbox-icon IconCheck" />
                </span>
                <span class="SubscriptionTermsCheckbox-label">
                    {{ $t('account:new_subscription:conditions_terms:acceptation') }}
                    <a
                        class="SubscriptionTermsCheckbox-link"
                        href="https://www.tcl.fr/cgu"
                        target="_blank"
                    >
                        {{ $t('terms_conditions:title') }}
                        <v-icon
                            :aria-label="$t('open_new_tab')"
                            :aria-hidden="false"
                            icon="mdi-open-in-new"
                            color="primary"
                            size="medium"
                        />
                    </a>
                </span>
            </label>
            <template #actions>
                <v-btn
                    :disabled="!isSubscriptionTermsAccepted"
                    class="bg-primary"
                    size="large"
                    @click.prevent="userSubscription"
                >
                    {{ $t('next') }}
                </v-btn>
            </template>
        </modal>
    </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useStore } from 'vuex'
import * as actionTypes from '@/StoreWeb/store/modules/user/action-types'
import AlertMessage from '@/StoreWeb/components/common/AlertMessage.vue'
import emitter from 'global-emitter'
import * as mainMutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import { useI18n } from 'vue-i18n'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true
    }
})

const store = useStore()
const { t } = useI18n()

const isSubscriptionTermsAccepted = ref(false)
const subscriptionAccountErrorMessage = ref('')

const showModal = computed({
    get: () => props.modelValue,
    set: (newValue) => {
        if (!newValue) {
            isSubscriptionTermsAccepted.value = false
        }

        emit('update:modelValue', newValue)
    }
})

onMounted(() => {
    emitter.on('getSubscriptionAccountSuccess', getSubscriptionAccountSuccessHandler)
    emitter.on('getSubscriptionAccountError', getSubscriptionAccountErrorHandler)
})

onUnmounted(() => {
    emitter.off('getSubscriptionAccountSuccess', getSubscriptionAccountSuccessHandler)
    emitter.off('getSubscriptionAccountError', getSubscriptionAccountErrorHandler)
})

function userSubscription () {
    if (isSubscriptionTermsAccepted.value) {
        store.dispatch(actionTypes.SET_NEW_ACCOUNT)
    }
}

function getSubscriptionAccountSuccessHandler () {
    store.commit(mainMutationTypes.SET_FEEDBACK_SUCCESS, t('account:new_subscription:success'))
    subscriptionAccountErrorMessage.value = ''
    showModal.value = false
    location.reload()
}

function getSubscriptionAccountErrorHandler () {
    subscriptionAccountErrorMessage.value = t('account:new_subscription:error')
    showModal.value = false
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.UserAccountSubscriptionModal {
    &-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &-conditions {
        margin-bottom: 15px;
    }

    &-label {
        flex-grow: 1;
        font-size: 14px;
    }
}

.SubscriptionTermsCheckbox {
    display: flex;
    justify-content: flex-start;
    cursor: pointer;

    &-container {
        position: relative;
        overflow: hidden;
        min-width: 19px;
        max-width: 19px;
        min-height: 19px;
        max-height: 19px;
        margin-right: 10px;
        border: solid 1px $color-lightgray3;
        border-radius: 2px;
    }

    &-icon {
        font-size: 18px;
        color: $color-brandPrimary;
        opacity: 0;
        transition: opacity .2s;
    }

    &-input {
        position: absolute;
        top: -9999px;
        left: -9999px;

        &:checked + .SubscriptionTermsCheckbox-icon {
            opacity: 1;
        }
    }

    &-label {
        flex-grow: 1;
        font-size: 14px;
    }

    &-link {
        white-space: break-spaces;
    }
}
</style>
