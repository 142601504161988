import { isEmpty } from 'global-utils'

export default class {
    constructor () {
        this._associationId = 0
        this._associationType = null
        this._email = ''
        this._firstName = ''
        this._isMaster = true
        this._lastName = ''
        this._userId = ''
    }

    get associationId () {
        return this._associationId
    }

    set associationId (associationId) {
        this._associationId = associationId
    }

    get associationType () {
        return this._associationType
    }

    set associationType (associationType) {
        this._associationType = associationType
    }

    get email () {
        return this._email
    }

    set email (email) {
        this._email = email
    }

    get firstName () {
        return this._firstName
    }

    set firstName (firstName) {
        this._firstName = firstName
    }

    get isMaster () {
        return this._isMaster
    }

    set isMaster (isMaster) {
        this._isMaster = isMaster
    }

    get lastName () {
        return this._lastName
    }

    set lastName (lastName) {
        this._lastName = lastName
    }

    get userId () {
        return this._userId
    }

    set userId (userId) {
        this._userId = userId
    }

    getFullName () {
        return (!isEmpty(this.firstName) ? this.firstName + ' ' : '') + (!isEmpty(this.lastName) ? this.lastName : '')
    }

    getAddToCartBody () {
        const body = {
            associationType: this.associationType.id,
            email: this.email,
            firstName: this.firstName,
            isMaster: this.isMaster,
            lastName: this.lastName,
            userId: this.userId
        }

        if (this.associationId) {
            body.associationId = this.associationId
        }

        return body
    }

    toJSON () {
        return {
            associationId: this.associationId,
            associationType: this.associationType,
            email: this.email,
            firstName: this.firstName,
            isMaster: this.isMaster,
            lastName: this.lastName,
            userId: this.userId
        }
    }
}
