export default {
    methods: {
        getField (key) {
            return this[key]
        },
        isMatchingPattern (pattern, value) {
            return pattern !== '' && typeof value === 'string' && value.match(pattern)
        },
        updateField (key, value) {
            this[key] = value
            if (typeof this[`update${key.ucFirst()}Callback`] === 'function') {
                this[`update${key.ucFirst()}Callback`](value)
            } else if (typeof this.updateFieldCallback === 'function') {
                this.updateFieldCallback(value)
            }
        },
        updateSelectField (key, value) {
            this[key] = value.selected
            if (typeof this[`update${key.ucFirst()}Callback`] === 'function') {
                this[`update${key.ucFirst()}Callback`](value.selected)
            } else if (typeof this.updateFieldCallback === 'function') {
                this.updateFieldCallback(value.selected)
            }
        }
    }
}
