<template>
    <div class="AlertingPreferences">
        <alerting-preferences :configuration="configuration" />
    </div>
</template>

<script setup>
import PageConfigManager from '@/StoreWeb/managers/PageConfigManagerSingleton'
import cachios from 'cachios'
import { computed, onMounted } from 'vue'
import { getUserTokenAccessToken } from '@/StoreWeb/js/composables/login-utils'
const pageConfigManager = PageConfigManager.getInstance()

const configuration = computed(() => {
    return {
        cachiosBearerToken: cachiosBearerToken.value,
        cachiosApiKey: cachios.axiosInstance.defaults.headers.common['X-API-Key'],
        coreApiUrl: process.env.VUE_APP_CORE_URL
    }
})
const cachiosBearerToken = computed(() => {
    return getUserTokenAccessToken()
})

onMounted(() => {
    pageConfigManager.setConfig({
        title: 'account:alerting-preferences:title',
        currentAccountPage: 'alertingPreferences'
    })
})
</script>

<style lang="scss" scoped>
@import 'globalScss';
</style>
