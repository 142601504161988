<template>
    <form
        :novalidate="true"
        class="FineEmailForm"
        @submit="submitEmail"
    >
        <labelled-input
            :default-value="email"
            :error-label="emailError"
            :is-required="true"
            :label="$t('form:fine:email:label')"
            class="FineRegularization-field FineRegularization-emailField"
            inputType="email"
            @field-value-changed="updateEmail"
        />
        <fine-form-buttons @click-on-back-button="clickOnBackButton" />
    </form>
</template>

<script>
import LabelledInput from '@/StoreWeb/components/common/LabelledInput'
import FineFormButtons from '@/StoreWeb/components/fine/subcomponent/FineFormButtons'
import formMixins from '@/StoreWeb/js/mixins/form-mixins'
import i18n from 'i18n'

export default {
    components: {
        FineFormButtons,
        LabelledInput
    },
    mixins: [
        formMixins
    ],
    props: {
        defaultEmail: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            email: '',
            emailError: ''
        }
    },
    mounted () {
        this.updateField('email', this.defaultEmail)
    },
    methods: {
        updateEmail (value) {
            this.updateField('email', value)
        },
        submitEmail (e) {
            e.preventDefault()
            this.clearError()
            let error = false
            if (this.email === '') {
                this.updateField('emailError', i18n.global.t('form:error:required_field'))
                error = true
            } else if (!this.isMatchingPattern(this.$config.patterns.email, this.email)) {
                this.updateField('emailError', i18n.global.t('form:user_infos:email:error:invalid'))
                error = true
            }

            if (error !== true) {
                this.$emit('fineEmail', this.email)
            }
        },
        clearError () {
            this.updateField('emailError', '')
        },
        clickOnBackButton () {
            this.$emit('clickOnBackButtonFromFineEmailForm')
        }
    }
}
</script>
