<template>
    <div class="SaleOptionProviderAccountChoice">
        <ul
            v-if="getProviderAccounts.length > 0"
            class="SaleOptionProviderAccountChoice-list"
        >
            <sale-option-provider-account-choice-item
                v-for="providerAccount in getProviderAccounts"
                :key="providerAccount.id"
                :isActive="isActive(providerAccount)"
                :providerAccount="providerAccount"
                class="SaleOptionProviderAccountChoice-item"
                @provider-account-clicked="providerAccountClicked"
            />
        </ul>
        <sale-option-no-provider-account-available
            v-else-if="showNoProviderAccountMessage"
            :provider="provider"
            @provider-accounts-available="providerAccountsAvailable"
        />
        <alert-message
            v-else
            :message="$t('sale_option:basket_info:provider_account:no_available_item')"
            type="error"
            class="SaleOptionProviderAccountAndFareMediaChoice-noAvailableTargetError"
        />
    </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import SaleOptionProviderAccountChoiceItem from '@/StoreWeb/components/product/SaleOptionProviderAccountChoiceItem'
import AlertMessage from '@/StoreWeb/components/common/AlertMessage'
import { useStore } from 'vuex'
import { normalizeProviderAccount } from '@/StoreWeb/js/mixins/support-utils'
import config from 'config'
import SaleOptionNoProviderAccountAvailable from '@/StoreWeb/components/product/SaleOptionNoProviderAccountAvailable'

const store = useStore()

const emit = defineEmits(['updateProviderAccount', 'providerAccountsAvailable'])

const props = defineProps({
    provider: {
        type: String,
        default: ''
    }
})

const selectedProviderAccount = ref(null)

const getProviderAccounts = computed(() => {
    return store.getters.getWallet.getAllSupports({
        correspondingBasketInfos: 'PROVIDERACCOUNT',
        provider: props.provider
    })
})

const showNoProviderAccountMessage = computed(() => {
    const provider = store.getters.getWalletProviders.find(provider => provider.id === props.provider)
    return config.purchase_flow.type === 'default' && provider.accounts.length <= 0
})

function providerAccountClicked (providerAccount) {
    selectedProviderAccount.value = providerAccount

    emit('updateProviderAccount', {
        basketInfo: 'PROVIDERACCOUNT',
        value: normalizeProviderAccount(providerAccount, props.provider)
    })
}

function isActive (providerAccount) {
    return selectedProviderAccount.value?.index === providerAccount.index
}

function providerAccountsAvailable (data) {
    emit('providerAccountsAvailable', data)
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.SaleOptionProviderAccountChoice {
    width: 100%;

    &-list {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &-item {
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-loader {
        width: 100%;
        text-align: center;
    }
}
</style>
