import AccountAssociationUser from '@/StoreWeb/models/account-association/AccountAssociationUser'
import { isEmpty } from 'global-utils'

export default class {
    normalize (item) {
        const accountAssociationUser = new AccountAssociationUser(item.externalUserId)

        accountAssociationUser.email = item.email
        accountAssociationUser.firstName = item.firstName
        accountAssociationUser.lastName = item.lastName

        return accountAssociationUser
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
