<template>
    <main class="FareMediaReloading Container">
        <h1 class="h1 FareMediaReloading-title">
            Rechargement de carte
        </h1>
    </main>
</template>

<script>
export default {
}
</script>
