export const updateUrlParams = (url, paramsToUpdate) => {
    const urlObj = new URL(url)

    for (const [key, value] of Object.entries(paramsToUpdate)) {
        if (urlObj.searchParams.has(key)) {
            urlObj.searchParams.set(key, value)
        } else {
            urlObj.searchParams.append(key, value)
        }
    }

    return urlObj.toString()
}
