<template>
    <div class="ProviderBankAccountList">
        <alert-message
            v-if="getBankAccountsErrorMessage !== ''"
            :message="getBankAccountsErrorMessage"
            class="ProviderBankAccountList-errorMessage"
            type="error"
        />
        <p
            v-if="hasToDisplayLabel"
            class="h3"
        >
            {{ getTitle }}
        </p>
        <v-alert
            v-if="isEmpty(getBankAccounts)"
            color="info"
            density="compact"
            prominent
            text
            variant="tonal"
        >
            {{ $t('account:bank_account:empty_list') }}
        </v-alert>
        <v-card
            v-if="config.features.iban_list.description"
            class="ProviderBankAccountList-description"
            elevation="0"
        >
            <v-card-text>
                <div :ref="el => setHtmlContent(el, $t('account:bank_account:description'))" />
            </v-card-text>
        </v-card>
        <template v-if="getBankAccounts.length > 0">
            <ul class="ProviderBankAccountList-list pb-3">
                <bank-account-item
                    v-for="(bankAccount, index) in getBankAccounts"
                    :key="index"
                    :bankAccount="bankAccount"
                    :provider="provider.id"
                    class="ProviderBankAccountList-item"
                    @edit-bank-account="showBankAccountEditForm"
                    @sign-mandate="showMandateForm"
                />
            </ul>
            <div
                v-if="showEditBankAccountButton"
                class="text-right"
            >
                <v-btn
                    color="primary"
                    @click.prevent="showMandateGenerationForm = !showMandateGenerationForm"
                >
                    {{ $t('iban:modify_button:label') }}
                </v-btn>
            </div>
            <modal
                v-if="isEditBankAccountEnabled"
                v-model="showBankAccountEditModal"
            >
                <bank-account-form
                    :provider="provider"
                    :bankAccount="bankAccountValues"
                    :errorMessage="errorMessage"
                    :actionLabel="$t('edit')"
                    :submitButtonLabel="$t('iban:edit:submit_button:label')"
                    :type="'edit'"
                    @close-bank-account-modal="hideBankAccountEditModal"
                    @submit-bank-account-form="updateBankAccount"
                />
            </modal>
        </template>
        <generate-mandate-form
            v-if="showInlineAddBankAccountForm"
            :provider="provider"
        />
        <v-btn
            v-if="showAddBankAccountButton"
            color="primary"
            variant="text"
            class="ProviderBankAccountList-addBankAccount"
            @click="showBankAccountAddForm"
        >
            <v-icon
                icon="mdi-bank-plus"
                class="mr-1"
            />
            {{ $t('button:create_bank_account') }}
        </v-btn>
        <modal
            v-if="showAddBankAccountButton"
            v-model="showBankAccountAddModal"
        >
            <bank-account-form
                :provider="provider"
                :errorMessage="errorMessage"
                :type="'add'"
                @close-bank-account-modal="hideBankAccountAddModal"
                @submit-bank-account-form="createBankAccount"
            />
        </modal>
        <modal
            v-model="showMandateGenerationForm"
            :title="$t('mandate:additional_information:title')"
        >
            <generate-mandate-form
                :provider="provider"
            />
        </modal>
        <modal
            v-model="showMandateFormModal"
            :title="$t('mandate:additional_information:title')"
        >
            <v-form @submit.prevent="submitSignMandate">
                <v-text-field
                    v-model="signMandateFormData.phone"
                    :error-messages="v$.phone.$error ? v$.phone.$errors.map(e => e.$message) : ''"
                    :label="$t('mobile_phone')"
                    @blur="v$.phone.$touch()"
                />
            </v-form>
            <template #actions>
                <v-btn
                    variant="text"
                    @click="hideMandateFormModal"
                >
                    {{ $t('button:cancel') }}
                </v-btn>
                <v-btn
                    class="bg-primary ml-2"
                    @click="submitFormSignMandate"
                >
                    {{ $t('button:validate') }}
                </v-btn>
            </template>
        </modal>
    </div>
</template>

<script setup>
import AlertMessage from '@/StoreWeb/components/common/AlertMessage'
import BankAccountForm from '@/StoreWeb/components/account/bank-account/BankAccountForm'
import BankAccountItem from '@/StoreWeb/components/account/bank-account/BankAccountItem'
import { isEmpty } from 'global-utils'
import * as paymentActionTypes from '@/StoreWeb/store/modules/payment/action-types'
import emitter from 'global-emitter'
import { useStore } from 'vuex'
import { computed, ref, onBeforeMount, onMounted, onUnmounted, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import { required } from '@/StoreWeb/utils/i18n-validators'
import { helpers } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import GenerateMandateForm from '@/StoreWeb/components/account/bank-account/GenerateMandateForm.vue'
import config from 'config'
import DOMPurify from 'dompurify'

const store = useStore()
const { t } = useI18n()

const props = defineProps({
    hasToDisplayLabel: {
        type: Boolean,
        default: false
    },
    label: {
        type: String,
        required: true
    },
    provider: {
        type: Object,
        required: true
    },
    providerAccount: {
        type: Object,
        required: true
    }
})

const bankAccountsErrorMessage = ref('')
const showBankAccountAddModal = ref(false)
const showBankAccountEditModal = ref(false)
const showMandateFormModal = ref(false)
const errorMessage = ref('')
const bankAccountValues = ref(null)
const providerBankAccountIdentifier = ref(null)
const accountAssociationErrorMessage = ref('')
const showMandateGenerationForm = ref(false)
const signMandateFormData = reactive({
    phone: ''
})
const signMandateFormRules = {
    phone: {
        required,
        regex: helpers.withMessage(
            t('validations:regex'),
            helpers.regex(/^(\+\d{1,4}|0)?[1-9]\d{8,14}$/)
        )
    }
}
const v$ = useVuelidate(signMandateFormRules, signMandateFormData, { $scope: 'sign_mandate' })

const bankAccounts = computed(() => store.state.userModule.bankAccounts)
const getBankAccounts = computed(() => {
    if (
        !isEmpty(bankAccounts.value) &&
        !isEmpty(bankAccounts.value[props.provider.id]) &&
        !isEmpty(bankAccounts.value[props.provider.id][props.providerAccount.id])
    ) {
        return bankAccounts.value[props.provider.id][props.providerAccount.id]
    }
    return []
})
const getBankAccountsErrorMessage = computed(() => bankAccountsErrorMessage.value)
const getProvider = computed(() => props.provider.id.ucFirst())
const getTitle = computed(() => t('account:payment_means:bank_account_list_title').replace('%label%', props.label).replace('%provider%', getProvider.value))
const showAddBankAccountButton = computed(() => config.features.iban_list.add && (config.features.iban_list.max_number === 0 || (config.features.iban_list.max_number !== 1 && getBankAccounts.value.length < config.features.iban_list.max_number)))
const showInlineAddBankAccountForm = computed(() => config.features.iban_list.add && config.features.iban_list.max_number === 1 && getBankAccounts.value.length === 0)
const showEditBankAccountButton = computed(() => config.features.iban_list.update && config.features.iban_list.max_number === 1 && getBankAccounts.value.length > 0)
const isEditBankAccountEnabled = computed(() => config.features.iban_list.update && getBankAccounts.value.length > 0)

onBeforeMount(() => {
    emitter.on('walletLoaded', () => {
        getUserBankAccounts()
    })
})

onMounted(() => {
    if (isEmpty(getBankAccounts.value)) {
        getUserBankAccounts()
    }

    emitter.on('getBankAccountsSuccess', getBankAccountsSuccessHandler)
    emitter.on('getBankAccountsError', getBankAccountsErrorHandler)
})

onUnmounted(() => {
    emitter.off('getBankAccountsSuccess', getBankAccountsSuccessHandler)
    emitter.off('getBankAccountsError', getBankAccountsErrorHandler)
})

const getUserBankAccounts = () => {
    store.dispatch(paymentActionTypes.GET_BANK_ACCOUNTS, {
        providerId: props.provider.id,
        providerUserId: props.providerAccount.id,
        providerUserExternalId: props.providerAccount.providerUserExternalId
    })
}
const getBankAccountsSuccessHandler = () => {
    accountAssociationErrorMessage.value = ''
}
const getBankAccountsErrorHandler = () => {
    accountAssociationErrorMessage.value = "Une erreur s'est produite lors de la récupération de la liste des comptes bancaires.<br>Veuillez réessayer plus tard et si le problème persiste, merci de nous contacter via le formulaire de contact."
}
const hideBankAccountAddModal = () => {
    showBankAccountAddModal.value = false
    errorMessage.value = ''
}
const hideBankAccountEditModal = () => {
    showBankAccountEditModal.value = false
    providerBankAccountIdentifier.value = null
    bankAccountValues.value = null
    errorMessage.value = ''
}
const hideMandateFormModal = () => {
    v$.value.$reset()
    showMandateFormModal.value = false
    providerBankAccountIdentifier.value = null
    signMandateFormData.phone = ''
}
const showBankAccountAddForm = () => {
    showBankAccountAddModal.value = true
}
const showBankAccountEditForm = (bankAccount) => {
    bankAccountValues.value = bankAccount
    showBankAccountEditModal.value = true
    providerBankAccountIdentifier.value = bankAccount.providerBankAccountIdentifier
}
const showMandateForm = (bankAccount) => {
    showMandateFormModal.value = true
    providerBankAccountIdentifier.value = bankAccount.providerBankAccountIdentifier
}
const createBankAccount = async (formValues) => {
    formValues.providerUserId = props.providerAccount.id
    formValues.providerUserExternalId = props.providerAccount.providerUserExternalId
    await store.dispatch(paymentActionTypes.CREATE_BANK_ACCOUNT, {
        data: formValues,
        providerId: props.provider.id,
        callback: (data) => {
            if (!isEmpty(data.signUrl)) {
                window.location.href = data.signUrl
            }
        }
    }).then(() => {
        errorMessage.value = ''
        hideBankAccountAddModal()
    }).catch((error) => {
        errorMessage.value = error
    })
}
const updateBankAccount = async (formValues) => {
    await store.dispatch(paymentActionTypes.UPDATE_BANK_ACCOUNT, {
        data: formValues,
        providerId: props.provider.id,
        providerUserExternalId: props.providerAccount.providerUserExternalId,
        providerUserId: props.providerAccount.id,
        providerBankAccountIdentifier: providerBankAccountIdentifier.value,
        getBankAccount: true
    }).then(() => {
        errorMessage.value = ''
        hideBankAccountEditModal()
    }).catch((error) => {
        errorMessage.value = error
    })
}

async function submitFormSignMandate () {
    v$.value.$touch()
    const isValid = await v$.value.$validate()
    if (!v$.value.$invalid && isValid) {
        submitSignMandate()
    }
}

const submitSignMandate = async () => {
    await store.dispatch(paymentActionTypes.SIGN_MANDATE, {
        data: {
            phone: signMandateFormData.phone,
            providerBankAccountIdentifier: providerBankAccountIdentifier.value,
            providerId: props.provider.id,
            providerUserExternalId: props.providerAccount.providerUserExternalId,
            providerUserId: props.providerAccount.id
        },
        callback: (data) => {
            if (!isEmpty(data.signUrl)) {
                window.location.href = data.signUrl
            }
        }
    }).then(() => {
        hideMandateFormModal()
    }).catch(() => {
        hideMandateFormModal()
    })
}

const setHtmlContent = (el, htmlContent) => {
    if (el) {
        el.innerHTML = DOMPurify.sanitize(htmlContent, { ALLOWED_TAGS: ['u', 'p', 'a', 'b', 'br'], ALLOWED_ATTR: ['href', 'target'] })
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.ProviderBankAccountList {
    display: flex;
    flex-direction: column;
    gap: $s4;

    &-description {
        background-color: rgba($color-brandSecondary, .2) !important;
    }

    &-list {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &-addBankAccount {
        width: max-content;
        font-weight: $fontWeight-defaultBold;
    }
}
</style>
