<template>
    <div class="ExternalLinks">
        <v-progress-circular
            indeterminate
            color="primary"
        />
        <p>
            {{ $t('external_links:pending_redirection') }}
        </p>
        <template v-if="debugMode && debugInformationsDisplay > 2">
            <v-btn
                color="primary"
                style="margin-bottom: 15px;"
                @click="simulateResumeAfterLogin"
            >
                Simulate RESUME after LogIn
            </v-btn>
            <v-btn
                color="primary"
                style="margin-bottom: 15px;"
                @click="simulateResumeAfterNfc"
            >
                Simulate RESUME after NFC
            </v-btn>
        </template>
    </div>
</template>

<script setup>
import {
    computed,
    onBeforeMount
} from 'vue'
import { useStore } from 'vuex'
import * as mainMutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import { isEmpty } from 'global-utils'
import { useRoute } from 'vue-router'

const store = useStore()
const route = useRoute()

onBeforeMount(() => {
    store.commit(mainMutationTypes.SET_IS_MOBILE_APP, true)
    // if (!isEmpty(route.query) && !isEmpty(route.query.action)) {
    //     console.log('ExternalLinks >>> CREATED >>> query', route.query)
    // } else if (!isEmpty(route.params) && !isEmpty(route.params.action)) {
    //     console.log('ExternalLinks >>> CREATED >>> action', route.params.action)
    // }
    // console.log('ExternalLinks >>> CREATED >>> route', route)
    // console.log('ExternalLinks >>> CREATED >>> window.location', window.location)
    // window.location.reload()
    // window.location.href
})

const debugInformationsDisplay = computed(() => store.state.debuggingModule.debugInformationsDisplay)
const debugMode = computed(() => store.state.debuggingModule.debugMode)

const simulateResumeAfterLogin = async () => {
    if (!isEmpty(route.query) && !isEmpty(route.query.sessionId)) {
        window.location.href = `http://store.is:5906/internalLinks/catalog?sessionId=${route.query.sessionId}&initialRequest=logIn&status=success`
    }
}

const simulateResumeAfterNfc = async () => {
    if (!isEmpty(route.query) && !isEmpty(route.query.sessionId)) {
        window.location.href = `http://store.is:5906/internalLinks/catalog?sessionId=${route.query.sessionId}&initialRequest=getFareMediaIdThroughNFC&status=success`
    }
}

</script>

<style lang='scss' scoped>
@import 'globalScss';

.ExternalLinks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: $s5;
    padding: $s10 $s5;
}
</style>
