import { createStore } from 'vuex'
import actions from './modules/main/actions'
import mutations from './modules/main/mutations'
import loginModule from 'user/Login/store/loginModule'
import accountAssociationModule from './modules/account-association/module'
import addressModule from './modules/address/module'
import anonymousModule from './modules/anonymous/module'
import applicationSessionModule from './modules/application-session/module'
import cardReaderModule from './modules/card-reader/module'
import cartModule from './modules/cart/module'
import catalogModule from './modules/catalog/module'
import paymentModule from './modules/payment/module'
import debuggingModule from './modules/debugging/module'
import userModule from './modules/user/module'

const store = createStore({
    modules: {
        accountAssociationModule,
        addressModule,
        anonymousModule,
        applicationSessionModule,
        cardReaderModule,
        cartModule,
        catalogModule,
        paymentModule,
        debuggingModule,
        userModule,
        login: loginModule
    },
    actions,
    mutations,
    state: {
        currentRoute: null,
        currentLanguage: '',
        feedbackError: '',
        feedbackSuccess: '',
        showSuccessToast: false,
        showErrorToast: false,
        headerBarZIndex: 4,
        isCheckAccountEnabled: false,
        isLoginModuleLoaded: false,
        isMobileApp: false,
        isMobileOrTablet: false,
        mainColor: '',
        mainColorDark: '',
        mainColorLight: '',
        showMainNav: false,
        pageConfig: {},
        pendingRequests: {
            uploadDocument: false
        },
        showGlobalLoader: {
            value: false
        },
        globalLoaderCounter: 0,
        showModal: false,
        walletPendingRequest: false
    }
})

export default store
