import { isEmpty } from 'global-utils'
import * as debuggingMutationTypes from '@/StoreWeb/store/modules/debugging/mutation-types'
import emitter from 'global-emitter'
import LoginManager from 'user/Login/managers/LoginManager'
import * as loginMutationTypes from 'user/Login/store/mutation-types'
/*
For adding new mocks, add it to :
    - DebuggingManager.getAvailableMockedActions
    - src/StoreWeb/store/modules/debugging/module.js file in mockStates state
    - debug-config.json file on Google CDN in is-cdn-dev/store/mocks
For using local debug-config, uncomment the following import (import LocalDebugConfig from '@/StoreWeb/mocks/debug-config.json')
and comment the next one (const LocalDebugConfig = null)
 */
// import LocalDebugConfig from '@/StoreWeb/mocks/debug-config.json'
const LocalDebugConfig = null

export default class {
    constructor () {
        this._debugConfig = null
        this._debugInformationsDisplay = 0
        this._debugMode = false
        this._store = null
    }

    get debugConfig () {
        return this._debugConfig
    }

    set debugConfig (debugConfig) {
        this._debugConfig = debugConfig
        this.store.commit(debuggingMutationTypes.SET_DEBUG_CONFIG, debugConfig)
    }

    get debugInformationsDisplay () {
        return this._debugInformationsDisplay
    }

    set debugInformationsDisplay (debugInformationsDisplay) {
        this._debugInformationsDisplay = debugInformationsDisplay
        this.store.commit(debuggingMutationTypes.SET_DEBUG_INFORMATIONS_DISPLAY, debugInformationsDisplay)
        if (debugInformationsDisplay) {
            localStorage.setItem('debugInformationsDisplay', debugInformationsDisplay)
        } else {
            localStorage.removeItem('debugInformationsDisplay')
        }
    }

    get debugMode () {
        return this._debugMode
    }

    set debugMode (debugMode) {
        this._debugMode = debugMode
        this.store.commit(debuggingMutationTypes.SET_DEBUG_MODE, debugMode)
        if (debugMode) {
            localStorage.setItem('debugMode', true)
            emitter.emit('debugModeActivated')
        } else {
            localStorage.removeItem('debugMode')
            this.debugInformationsDisplay = 0
            emitter.emit('debugModeDeactivated')
        }
    }

    get store () {
        return this._store
    }

    set store (store) {
        this._store = store
    }

    init () {
        emitter.on('debugModeActivated', () => {
            if (this.debugConfig === null && !this.store.state.debuggingModule.debugPendingRequests.getConfig) {
                this.getConfig()
            }
        })
        emitter.on('updateMockState_getCart', (data) => {
            if (data.state) {
                const cartId = localStorage.getItem('cartId')
                if (isEmpty(cartId)) {
                    localStorage.setItem('cartId', 1)
                    localStorage.setItem('cartIdMocked', 1)
                }
            } else {
                const cartIdMocked = localStorage.getItem('cartIdMocked')
                if (!isEmpty(cartIdMocked)) {
                    localStorage.removeItem('cartId')
                    localStorage.removeItem('cartIdMocked')
                }
            }
        })
        this.applyLocalStorageConfig()
    }

    updateDebugModeValue (value) {
        this.debugMode = value
    }

    getAvailableMockedActions () {
        return [
            'getAccountAssociationTypes',
            'getAddresses',
            'getAddressTemplates',
            'getApplicationSession',
            'getBankAccounts',
            'getCatalog',
            'getCart',
            'getCartConfirmation',
            'getConfiguration',
            'getConfigurationSettings',
            'getFareMediaCatalog',
            'getFareMedias',
            'getFines',
            'getFutureDirectDebits',
            'getFinalizedCart',
            'getListByParameterName',
            'getMe',
            'getNotifications',
            'getOrder',
            'getOrders',
            'getPastDirectDebits',
            'getPersonalInfo',
            'getProductDetails',
            'getQuotations',
            'getSupportContent',
            'getToken',
            'getUserAccountAssociations',
            'getWallet',
            'postPaymentInit',
            'terminatePlan'
        ]
    }

    async getConfig () {
        const url = `${process.env.VUE_APP_CDN_URL}mocks/debug-config.json`
        if (!isEmpty(LocalDebugConfig)) {
            this.debugConfig = LocalDebugConfig
            emitter.emit('debugConfigLoaded')
        } else {
            this.store.commit(debuggingMutationTypes.SET_DEBUG_PENDING_REQUEST, { key: 'getConfig', value: true })
            await fetch(url)
                .then(response => response.json())
                .then(data => {
                    this.debugConfig = data
                    this.store.commit(debuggingMutationTypes.SET_DEBUG_PENDING_REQUEST, { key: 'getConfig', value: false })
                    emitter.emit('debugConfigLoaded')
                })
                .catch(e => {
                    // eslint-disable-next-line
                    console.log(`Error while trying to get mocks configuration from ${url}`, e)
                    this.store.commit(debuggingMutationTypes.SET_DEBUG_PENDING_REQUEST, { key: 'getConfig', value: false })
                })
        }
    }

    getActionConfig (action) {
        if (!isEmpty(action)) {
            if (this.debugConfig !== null && !isEmpty(this.debugConfig[action])) {
                return this.debugConfig[action]
            }
        }
        return []
    }

    applyLocalStorageConfig () {
        if (localStorage.getItem('debugMode')) {
            this.debugMode = true
            if (this.debugConfig === null && !this.store.state.debuggingModule.debugPendingRequests.getConfig) {
                this.getConfig()
            }
        }
        const isForceUseWalletCacheEnabled = localStorage.getItem('isForceUseWalletCacheEnabled')
        if (isForceUseWalletCacheEnabled) {
            this.store.commit(debuggingMutationTypes.SET_FORCE_USE_WALLET_CACHE, !!parseInt(isForceUseWalletCacheEnabled))
        }
        if (this.debugMode) {
            const debugInformationsDisplay = localStorage.getItem('debugInformationsDisplay')
            this.debugInformationsDisplay = parseInt(debugInformationsDisplay)
        }

        let debugConfig = localStorage.getItem('debugConfig')
        if (!isEmpty(debugConfig)) {
            debugConfig = JSON.parse(debugConfig)
            debugConfig.actions.forEach(action => {
                if (this.getAvailableMockedActions().find(item => item === action.action)) {
                    this.store.commit(debuggingMutationTypes.SET_MOCK_STATE, {
                        action: action.action,
                        state: action.state,
                        version: action.version
                    })
                }
            })
        }
    }

    getMockActionState (action) {
        if (!this.debugMode) {
            return false
        }
        const mockConfig = this.getMockActionConfig(action)
        if (!isEmpty(mockConfig)) {
            return mockConfig.state
        }
        return false
    }

    getMockActionConfig (action) {
        const mockStates = this.store.state.debuggingModule.mockStates
        if (!isEmpty(mockStates[action])) {
            return mockStates[action]
        }
        return null
    }

    async getResponse (action, callback) {
        if (this.debugConfig !== null) {
            return await this.handleResponse(action, callback)
        } else {
            await emitter.on('debugConfigLoaded', () => {
                return this.handleResponse(action, callback)
            })
        }
    }

    async handleResponse (action, callback) {
        const actionConfig = this.getActionConfig(action)
        const mockActionConfig = this.getMockActionConfig(action)
        if (!isEmpty(actionConfig) && !isEmpty(mockActionConfig)) {
            const selectedVersion = actionConfig.find(item => item.id === mockActionConfig.version)
            if (!isEmpty(selectedVersion)) {
                const mockPath = `${process.env.VUE_APP_CDN_URL}mocks${selectedVersion.file}`
                return fetch(mockPath)
                    .then(response => {
                        return response.json()
                    })
                    .then(data => {
                        return callback({
                            data
                        })
                    })
                    .catch(e => {
                        // eslint-disable-next-line
                        console.log(`Error while trying to get mock from ${mockPath} or during its treatment in the provided callback (in that case, you have to investigate in every treatment done after getting response)`, e)
                    })
            }
        }
        return false
    }

    updateConfig (data) {
        let debugConfig = JSON.parse(localStorage.getItem('debugConfig'))

        if (isEmpty(debugConfig)) {
            debugConfig = {
                actions: []
            }
        } else if (typeof debugConfig.actions === 'undefined') {
            debugConfig.actions = []
        }
        const storedAction = debugConfig.actions.find(item => item.action === data.action)
        if (!isEmpty(storedAction)) {
            storedAction.action = data.action
            storedAction.state = data.state
            storedAction.version = data.version
        } else {
            debugConfig.actions.push(data)
        }
        localStorage.setItem('debugConfig', JSON.stringify(debugConfig))
    }

    getActionMockPath (action, version = 'default') {
        const actionConfig = this.getActionConfig(action)
        const mockActionConfig = this.getMockActionConfig(action)
        // debugger
        if (!isEmpty(actionConfig) && !isEmpty(mockActionConfig)) {
            const selectedVersion = actionConfig.find(item => item.id === mockActionConfig.version)
            if (!isEmpty(selectedVersion)) {
                return `${process.env.VUE_APP_CDN_URL}mocks${selectedVersion.file}`
            }
        }
        return ''
    }

    async simulateAuthenticatedUser () {
        if (isEmpty(this.debugConfig)) {
            await this.getConfig()
        }
        const kasConfigurationResponse = await fetch(this.getActionMockPath('getConfiguration'))
        const kasConfiguration = await kasConfigurationResponse.json()
        const kasConfigurationSettingsResponse = await fetch(this.getActionMockPath('getConfigurationSettings'))
        const kasConfigurationSettings = await kasConfigurationSettingsResponse.json()
        const userTokenResponse = await fetch(this.getActionMockPath('getToken'))
        const userToken = await userTokenResponse.json()
        const userInfosResponse = await fetch(this.getActionMockPath('getMe'))
        const userInfos = await userInfosResponse.json()
        if (
            !isEmpty(kasConfiguration) &&
            !isEmpty(kasConfigurationSettings) &&
            !isEmpty(userToken) &&
            !isEmpty(userInfos)
        ) {
            // KAS Configuration
            LoginManager.setLocalStorageKasConfiguration(kasConfiguration)
            // KAS Configuration Settings
            LoginManager.setLocalStorageKasConfigurationSettings(kasConfigurationSettings)
            this.store.commit(`login/${loginMutationTypes.SET_KAS_CONFIGURATION_SETTINGS}`, kasConfigurationSettings)
            // userToken
            userToken.expirationDate = Math.floor(new Date().getTime() / 1000) + 24 * 60 * 60
            LoginManager.setUserTokenCookie(userToken)
            this.store.commit(`login/${loginMutationTypes.SET_USER_TOKEN}`, LoginManager.userToken)
            // userInfos
            LoginManager.setUserInfosCookie(userInfos)
            this.store.commit(`login/${loginMutationTypes.SET_USER_INFOS}`, LoginManager.userInfos)
            this.store.commit(`login/${loginMutationTypes.SET_RAW_USER_INFOS}`, LoginManager.rawUserInfos)
            this.store.commit(`login/${loginMutationTypes.SET_AUTHENTICATED}`, true)
            localStorage.setItem('usingFakeUserToken', 1)
        }
    }

    async enableAllMocks () {
        if (isEmpty(this.debugConfig)) {
            await this.getConfig()
        }
        const mockStates = this.store.state.debuggingModule.mockStates
        for (const i in mockStates) {
            if (!mockStates[i].state) {
                const data = {
                    action: i,
                    state: true,
                    version: mockStates[i].version ?? 'default'
                }
                this.store.commit(debuggingMutationTypes.SET_MOCK_STATE, data)
                this.updateConfig(data)
            }
        }
    }
}
