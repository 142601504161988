import AccountAssociation from '@/StoreWeb/models/account-association/AccountAssociation'
import AccountAssociationTypeNormalizer from '@/StoreWeb/normalizers/account-association/AccountAssociationTypeNormalizerSingleton'
import AccountAssociationUserNormalizer from '@/StoreWeb/normalizers/account-association/AccountAssociationUserNormalizerSingleton'
import AssociatedFareMediaNormalizer from '@/StoreWeb/normalizers/account-association/AssociatedFareMediaNormalizerSingleton'
import IdLabelPatternNormalizer from '@/StoreWeb/normalizers/patterns/IdLabelPatternNormalizerSingleton'
import UserActionNormalizer from '@/StoreWeb/normalizers/common/UserActionNormalizerSingleton'
import { isEmpty } from 'global-utils'

export default class {
    constructor () {
        this._accountAssociationTypeNormalizer = AccountAssociationTypeNormalizer.getInstance()
        this._accountAssociationUserNormalizer = AccountAssociationUserNormalizer.getInstance()
        this._associatedFareMediaNormalizer = AssociatedFareMediaNormalizer.getInstance()
        this._idLabelPatternNormalizer = IdLabelPatternNormalizer.getInstance()
        this._userActionNormalizer = UserActionNormalizer.getInstance()
    }

    get accountAssociationTypeNormalizer () {
        return this._accountAssociationTypeNormalizer
    }

    get accountAssociationUserNormalizer () {
        return this._accountAssociationUserNormalizer
    }

    get associatedFareMediaNormalizer () {
        return this._associatedFareMediaNormalizer
    }

    get idLabelPatternNormalizer () {
        return this._idLabelPatternNormalizer
    }

    get userActionNormalizer () {
        return this._userActionNormalizer
    }

    normalize (item) {
        const accountAssociation = new AccountAssociation(item.id)

        accountAssociation.actions = this.userActionNormalizer.normalizeArray(item.actions)
        accountAssociation.creationDate = item.creationDate
        accountAssociation.fareMedias = this.associatedFareMediaNormalizer.normalizeArray(item.fareMedias)
        if (!isEmpty(item.isAssociatedTo)) {
            accountAssociation.isAssociatedTo = this.accountAssociationUserNormalizer.normalize(item.isAssociatedTo)
        }
        accountAssociation.isMaster = item.isMaster
        accountAssociation.isRequester = item.isRequester
        if (!isEmpty(item.key)) {
            accountAssociation.key = item.key
        }
        accountAssociation.masterAccountId = item.masterAccountId ?? ''
        accountAssociation.masterEmail = item.masterEmail ?? ''
        accountAssociation.masterFirstName = item.masterFirstName ?? ''
        accountAssociation.masterLastName = item.masterLastName ?? ''
        accountAssociation.mode = item.mode
        accountAssociation.networkId = item.networkId
        accountAssociation.slaveAccountId = item.slaveAccountId ?? ''
        accountAssociation.slaveEmail = item.slaveEmail ?? ''
        accountAssociation.slaveFirstName = item.slaveFirstName ?? ''
        accountAssociation.slaveLastName = item.slaveLastName ?? ''
        if (!isEmpty(item.status)) {
            accountAssociation.status = this.idLabelPatternNormalizer.normalize(item.status)
        }
        if (!isEmpty(item.type)) {
            accountAssociation.type = this.accountAssociationTypeNormalizer.normalize(item.type)
        }
        accountAssociation.updateDate = item.updateDate

        return accountAssociation
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
