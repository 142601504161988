export default class {
    constructor (
        id, label
    ) {
        this._id = id
        this._label = label
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get label () {
        return this._label
    }

    set label (label) {
        this._label = label
    }

    toString () {
        return this.label
    }

    toJSON () {
        return {
            id: this.id,
            label: this.label
        }
    }
}
