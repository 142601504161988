<template>
    <component
        :is="'li'"
        class="OrderBasket"
    >
        <div
            v-if="displayBasketStatus"
            class="OrderBasket-header"
        >
            <p class="OrderBasket-label">
                <strong class="OrderBasket-title">
                    {{ $t('order:basket:status') }}
                </strong>
                <span class="OrderBasket-status">
                    {{ getStatus }}
                </span>
            </p>
        </div>
        <div
            v-if="getFareMediaTitle !== ''"
            class="OrderBasket-fareMedia d-flex justify-space-between align-center mb-6 py-3 px-4 w-100 w-auto-md rounded-xl"
        >
            <icomoon-icon
                :name="'Card'"
                class="OrderBasket-fareMediaIcon"
            />
            <div class="OrderBasket-fareMediaLabel">
                <strong class="OrderBasket-fareMediaTitle">{{ getFareMediaTitle }} {{ getFareMediaUser }}</strong>
                <span class="OrderBasket-fareMediaSubtitle">{{ getFareMediaSubtitle }}</span>
            </div>
        </div>
        <ul
            v-if="basket.products.length > 0"
            class="OrderBasket-productList"
        >
            <order-basket-product
                v-for="product in basket.products"
                :key="product.id"
                :product="product"
                class="OrderBasket-productItem"
            />
        </ul>
    </component>
</template>

<script setup>
import { isEmpty } from 'global-utils'
import { computed } from 'vue'
import OrderBasketProduct from '@/StoreWeb/components/account/order/OrderBasketProduct'
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon'

const props = defineProps({
    basket: {
        type: Object,
        required: true
    },
    displayBasketStatus: {
        type: Boolean,
        default: false
    },
    orderId: {
        type: [String, Number],
        required: true
    }
})

const getFareMediaSubtitle = computed(() => props.basket.getFareMediaSubtitle())

const getFareMediaTitle = computed(() => {
    if (props.basket.providerFareMediaId) {
        return props.basket.getFareMediaTitle()
    }
    return ''
})

const getFareMediaUser = computed(() => {
    if (props.basket.recipientUser) {
        return props.basket.recipientUser.getFullName()
    }
    return (props.basket?.providerUserFirstName ? props.basket.providerUserFirstName + ' ' : '') + (props.basket?.providerUserLastName ?? '')
})

const getStatus = computed(() => {
    if (!isEmpty(props.basket.externalStatus)) {
        return props.basket.externalStatus.getStatusLabel()
    }
    return props.basket.getStatusLabel()
})
</script>

<style lang="scss" scoped>
@import 'globalScss';

.OrderBasket {
    &-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 30px;
    }

    &-label {
        flex-grow: 1;
        margin: 0;
    }

    &-title {
        display: block;
        font-size: 16px;
        font-weight: bold;
        line-height: 16px;
        color: $color-lightText;
    }

    &-status {
        margin: 0 0 30px;
        font-size: 12px;
        color: $color-brandSecondary;
    }

    &-downloadInvoice {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        border: none;
        background: none;
        cursor: pointer;
        font-size: 12px;
        color: $color-brandPrimary;
        transition: color .3s;

        &:hover,
        &:active,
        &:focus {
            color: $color-brandPrimaryDark;
        }

        &Icon {
            font-size: 20px;
        }

        &Label {
            display: none;
        }
    }

    &-fareMedia {
        background: $color-brandPrimaryLightBackground;

        @media #{map-get($display-breakpoints, 'md-and-up')} {
            max-width: 355px;
        }

        &Icon {
            margin-right: 10px;
            font-size: 32px;
            color: $color-brandPrimary;
            transform: rotate(-7deg);
        }

        &Label {
            flex-grow: 1;
            color: $color-lighterText;
        }

        &Title {
            display: block;
            font-family: $fontFamily-title;
            font-size: 18px;
            font-weight: bold;
            color: $color-brandPrimary;
        }

        &Subtitle {
            display: block;
            font-size: 14px;
        }
    }

    &-productListTitle {
        margin-bottom: 10px;
        font-family: $fontFamily-title;
    }

    &-productList {
        margin: 0;
        padding: 0 0 0 10px;
        list-style: none;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-productItem {
        border-bottom: 1px solid $color-lightgray3;

        &:last-child {
            margin-bottom: 0;
            border-bottom: none;
        }
    }
}
</style>
