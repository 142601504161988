export default class {
    constructor (id) {
        this._baskets = []
        this._fareExcludingTaxes = 0
        this._fareIncludingTaxes = 0
        this._id = id
        this._name = ''
        this._quantity = 0
        this._totalFareExcludingTaxes = 0
        this._totalFareIncludingTaxes = 0
        this._totalVatAmount = 0
        this._unit = ''
        this._vatAmout = 0
        this._vatRate = 0
        this._version = 0
    }

    get baskets () {
        return this._baskets
    }

    set baskets (baskets) {
        this._baskets = baskets
    }

    get fareExcludingTaxes () {
        return this._fareExcludingTaxes
    }

    set fareExcludingTaxes (fareExcludingTaxes) {
        this._fareExcludingTaxes = fareExcludingTaxes
    }

    get fareIncludingTaxes () {
        return this._fareIncludingTaxes
    }

    set fareIncludingTaxes (fareIncludingTaxes) {
        this._fareIncludingTaxes = fareIncludingTaxes
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get name () {
        return this._name
    }

    set name (name) {
        this._name = name
    }

    get quantity () {
        return this._quantity
    }

    set quantity (quantity) {
        this._quantity = quantity
    }

    get totalFareExcludingTaxes () {
        return this._totalFareExcludingTaxes
    }

    set totalFareExcludingTaxes (totalFareExcludingTaxes) {
        this._totalFareExcludingTaxes = totalFareExcludingTaxes
    }

    get totalFareIncludingTaxes () {
        return this._totalFareIncludingTaxes
    }

    set totalFareIncludingTaxes (totalFareIncludingTaxes) {
        this._totalFareIncludingTaxes = totalFareIncludingTaxes
    }

    get totalVatAmount () {
        return this._totalVatAmount
    }

    set totalVatAmount (totalVatAmount) {
        this._totalVatAmount = totalVatAmount
    }

    get unit () {
        return this._unit
    }

    set unit (unit) {
        this._unit = unit
    }

    get vatAmout () {
        return this._vatAmout
    }

    set vatAmout (vatAmout) {
        this._vatAmout = vatAmout
    }

    get vatRate () {
        return this._vatRate
    }

    set vatRate (vatRate) {
        this._vatRate = vatRate
    }

    get version () {
        return this._version
    }

    set version (version) {
        this._version = version
    }

    toJSON () {
        return {
            baskets: this.baskets,
            fareExcludingTaxes: this.fareExcludingTaxes,
            fareIncludingTaxes: this.fareIncludingTaxes,
            id: this.id,
            name: this.name,
            quantity: this.quantity,
            totalFareExcludingTaxes: this.totalFareExcludingTaxes,
            totalFareIncludingTaxes: this.totalFareIncludingTaxes,
            totalVatAmount: this.totalVatAmount,
            unit: this.unit,
            vatAmout: this.vatAmout,
            vatRate: this.vatRate,
            version: this.version
        }
    }
}
