import mutations from './mutations'

export default {
    namespaced: false,
    mutations,
    state: {
        debugAutofillCardOrderForms: true,
        debugMode: false,
        debugInformationsDisplay: false,
        debugPendingRequests: {
            getConfig: false
        },
        debugConfig: null,
        forceUseWalletCache: false,
        mockStates: {
            getAccountAssociationTypes: {
                state: false,
                version: 'default'
            },
            getAddresses: {
                state: false,
                version: 'default'
            },
            getAddressTemplates: {
                state: false,
                version: 'default'
            },
            getApplicationSession: {
                state: false,
                version: 'default'
            },
            getBankAccounts: {
                state: false,
                version: 'default'
            },
            getCatalog: {
                state: false,
                version: 'default'
            },
            getCatalogByProducts: {
                state: false,
                version: 'default'
            },
            getCart: {
                state: false,
                version: 'default'
            },
            getCartConfirmation: {
                state: false,
                version: 'default'
            },
            getConfiguration: {
                state: false,
                version: 'default'
            },
            getConfigurationSettings: {
                state: false,
                version: 'default'
            },
            getFareMediaCatalog: {
                state: false,
                version: 'default'
            },
            getFareMedias: {
                state: false,
                version: 'default'
            },
            getFines: {
                state: false,
                version: 'default'
            },
            getFutureDirectDebits: {
                state: false,
                version: 'default'
            },
            getFinalizedCart: {
                state: false,
                version: 'default'
            },
            getListByParameterName: {
                state: false,
                version: 'default'
            },
            getMe: {
                state: false,
                version: 'default'
            },
            getNotifications: {
                state: false,
                version: 'default'
            },
            getOrder: {
                state: false,
                version: 'default'
            },
            getOrders: {
                state: false,
                version: 'default'
            },
            getPastDirectDebits: {
                state: false,
                version: 'default'
            },
            getPersonalInfo: {
                state: false,
                version: 'default'
            },
            getProductDetails: {
                state: false,
                version: 'default'
            },
            getQuotations: {
                state: false,
                version: 'default'
            },
            getSupportContent: {
                state: false,
                version: 'default'
            },
            getToken: {
                state: false,
                version: 'default'
            },
            getUserAccountAssociations: {
                state: false,
                version: 'default'
            },
            getWallet: {
                state: false,
                version: 'default'
            },
            postPaymentInit: {
                state: false,
                version: 'default'
            },
            terminatePlan: {
                state: false,
                version: 'default'
            }
        }
    }
}
