import * as mutationTypes from './mutation-types'

export default {
    [mutationTypes.SET_ADD_TO_CART_EMAIL] (state, addToCartEmail) {
        state.addToCartEmail = addToCartEmail
    },
    [mutationTypes.SET_ADD_TO_CART_FLOW] (state, addToCartFlow) {
        state.addToCartFlow = addToCartFlow
    },
    [mutationTypes.SET_ADD_TO_CART_FLOW_CONTEXT] (state, addToCartFlowContext) {
        state.addToCartFlowContext = addToCartFlowContext
    },
    [mutationTypes.SET_ADD_TO_CART_FLOW_INDEX] (state, addToCartFlowIndex) {
        state.addToCartFlowIndex = addToCartFlowIndex
    },
    [mutationTypes.SET_ADD_TO_CART_FLOW_STEP] (state, addToCartFlowStep) {
        state.addToCartFlowStep = addToCartFlowStep
    },
    [mutationTypes.SET_IS_ADD_TO_CART_FLOW_FIRST_STEP] (state, isAddToCartFlowFirstStep) {
        state.isAddToCartFlowFirstStep = isAddToCartFlowFirstStep
    },
    [mutationTypes.SET_IS_ADD_TO_CART_FLOW_LAST_STEP] (state, isAddToCartFlowLastStep) {
        state.isAddToCartFlowLastStep = isAddToCartFlowLastStep
    },
    [mutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED] (state, isGoToNextStepDisabled) {
        state.isGoToNextStepDisabled = isGoToNextStepDisabled
    },
    [mutationTypes.SET_IS_GUEST_PURCHASE_FLOW] (state, isGuestPurchaseFlow) {
        state.isGuestPurchaseFlow = isGuestPurchaseFlow
    },
    [mutationTypes.SET_CART] (state, cart) {
        state.cart = cart
    },
    [mutationTypes.SET_CART_PENDING_REQUEST] (state, pendingRequest) {
        if (state.cartPendingRequests[pendingRequest.key] !== undefined) {
            state.cartPendingRequests[pendingRequest.key] = pendingRequest.value
        }
    },
    [mutationTypes.SET_SHOW_CART] (state, showCart) {
        state.showCart = showCart
    },
    [mutationTypes.SET_TEMP_ADD_CART_FLOW] (state, tempAddCartFlow) {
        state.tempAddCartFlow = tempAddCartFlow
    }
}
