<template>
    <form class="AccountAssociationAddLastNameFirstNameBirthdateModeForm">
        <labelled-input
            :inputType="'text'"
            :label="$t('form:user_infos:lastname:label')"
            autocomplete="off"
            class="AccountAssociationAddLastNameFirstNameBirthdateModeForm-field"
            @field-value-changed="updateField('lastName', $event)"
        />
        <labelled-input
            :inputType="'text'"
            :label="$t('form:user_infos:firstname:label')"
            autocomplete="off"
            class="AccountAssociationAddLastNameFirstNameBirthdateModeForm-field"
            @field-value-changed="updateField('firstName', $event)"
        />
        <date-input-field-with-label
            :inputType="'date'"
            :label="$t('form:user_infos:birthdate:label')"
            class="AccountAssociationAddLastNameFirstNameBirthdateModeForm-field"
            @field-value-changed="updateField('birthdate', $event)"
        />
    </form>
</template>

<script>
import DateInputFieldWithLabel from '@/StoreWeb/components/common/DateInputFieldWithLabel'
import LabelledInput from '@/StoreWeb/components/common/LabelledInput'

export default {
    components: {
        DateInputFieldWithLabel,
        LabelledInput
    },
    data () {
        return {
            birthdate: '',
            firstName: '',
            keys: ['lastName', 'firstName', 'birthdate'],
            lastName: ''
        }
    },
    methods: {
        getField (key) {
            return this[key]
        },
        updateField (key, value) {
            this[key] = value
            if (typeof this[`update${key.ucFirst()}Callback`] === 'function') {
                this[`update${key.ucFirst()}Callback`](value)
            } else if (typeof this.updateFieldCallback === 'function') {
                this.updateFieldCallback(value)
            }
        },
        updateBirthdateCallback (value) {
            this.birthdate = value + 'T00:00:00'
            this.updateKey()
        },
        checkFormError () {
            let isValid = true
            this.keys.forEach(key => {
                isValid = isValid && this.getField(key) !== ''
            })
            return isValid
        },
        updateFieldCallback () {
            this.updateKey()
        },
        updateKey () {
            let value = ''
            this.keys.forEach(key => {
                value += (value !== '' ? '|' : '') + this.getField(key)
            })
            this.$emit('lastNameFirstNameBirthdateFormUpdated', value)
        }
    }
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

.AccountAssociationAddLastNameFirstNameBirthdateModeForm {
    &-field {
        margin-bottom: 15px;
    }
}
</style>
