<template>
    <div class="AddressList">
        <div
            v-if="showHeader"
            class="h2"
        >
            <template v-if="areSeveralAddresses">
                {{ $t('address:my_addresses') }}
            </template>
            <template v-else>
                {{ $t('address:my_address') }}
            </template>
        </div>
        <div
            v-if="addressPendingRequests.getAddresses || addressPendingRequests.getAddressTemplates"
            class="AddressList-loader"
        >
            <v-progress-circular
                indeterminate
                color="primary"
            />
        </div>
        <ul
            v-if="getAddresses"
            class="AddressList-list"
        >
            <address-item
                v-for="address in getAddresses"
                :key="address.id"
                :address="address"
                :showSelectButton="getShowSelectButton"
                :showEditButton="!!getTemplate"
                @show-edit-address-form="setShowEditAddressModal"
            />
        </ul>
        <v-btn
            v-if="getAddAddressEnabled && !addressPendingRequests.getAddresses"
            color="primary"
            variant="text"
            class="AddressList-addAddress"
            @click="setShowCreateAddressModal"
        >
            <v-icon
                icon="mdi-plus"
                class="mr-1"
            />
            {{ $t('address:add_button') }}
        </v-btn>
        <address-create-modal
            v-if="showCreateAddressModal"
            v-model="showCreateAddressModal"
        />
        <address-edit-modal
            v-if="selectedAddressId"
            v-model="showEditAddressModal"
            :address="getSelectedAddress"
        />
    </div>
</template>

<script setup>
import AddressCreateModal from '@/StoreWeb/components/address/AddressCreateModal'
import AddressEditModal from '@/StoreWeb/components/address/AddressEditModal'
import AddressItem from '@/StoreWeb/components/address/AddressItem.vue'
import * as addressActionTypes from '@/StoreWeb/store/modules/address/action-types'
import emitter from 'global-emitter'
import { useStore } from 'vuex'
import { computed, ref, onBeforeMount, watch } from 'vue'
import { isEmpty } from 'global-utils'

const store = useStore()

onBeforeMount(async () => {
    emitter.on('addressTemplatesLoaded', addressTemplatesLoadedHandler)
    await store.dispatch(addressActionTypes.GET_ADDRESS_TEMPLATES)
    await store.dispatch(addressActionTypes.GET_ADDRESSES, {
        cachiosOptions: {
            force: true
        }
    })
})

const props = defineProps({
    showHeader: {
        type: Boolean,
        default: true
    },
    showSelectButton: {
        type: Boolean,
        default: false
    }
})

const showCreateAddressModal = ref(false)
const showEditAddressModal = ref(false)

const selectedAddressId = ref(null)
const templateCode = ref('USER')

const getAddresses = computed(() => store.getters.getAddresses)
const getAddressesCount = computed(() => store.getters.getAddressesCount)
const areSeveralAddresses = computed(() => {
    return getAddressesCount.value > 1 || (getAddressesCount.value === 1 && getAddAddressEnabled.value)
})
const getAddressTemplates = computed(() => store.getters.getAddressTemplates)
const getTemplate = computed(() => getAddressTemplates.value.find(item => item.code === templateCode.value))
const template = ref(getTemplate.value)
const getAddAddressEnabled = computed(() => store.getters.getAddAddressEnabled)
const addressPendingRequests = computed(() => store.state.addressModule.addressPendingRequests)
const getShowSelectButton = computed(() => props.showSelectButton)

const getSelectedAddress = computed(() => {
    let selectedAddress

    if (!isEmpty(getAddresses.value)) {
        selectedAddress = getAddresses.value.find(item => item.id === selectedAddressId.value)
        if (typeof selectedAddress !== 'undefined') {
            return selectedAddress
        }
    }
    return null
})

watch(() => showEditAddressModal.value, (value) => {
    if (!value) {
        selectedAddressId.value = null
    }
})

function setShowCreateAddressModal () {
    showCreateAddressModal.value = true
}

function setShowEditAddressModal (id) {
    selectedAddressId.value = id
    showEditAddressModal.value = true
}

function addressTemplatesLoadedHandler () {
    if (getAddressTemplates.value) {
        template.value = getAddressTemplates.value.find(item => item.code === templateCode.value)
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.AddressList {
    display: flex;
    flex-direction: column;
    gap: $s4;

    &-loader {
        width: 100%;
        margin-bottom: $s4;
        text-align: center;
    }

    &-list {
        display: flex;
        flex-direction: column;
        gap: $s5;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &-addAddress {
        width: max-content;
        font-weight: $fontWeight-defaultBold;
    }
}
</style>
