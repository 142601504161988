<template>
    <div class="FareMediaAddExistantModal">
        <modal
            v-model="showModal"
            :title="$t('fare_media:add_existant_modal:title')"
            @close-modal="resetNextActionAndClose"
        >
            <div class="FareMediaAddExistantModal-body">
                <provider-select
                    v-if="getShowProviderSelect"
                    v-bind="provider"
                    class="FareMediaAddExistantModal-providerSelect"
                    @selected-provider-updated="updateProvider"
                />
                <p v-if="!getShowSelectedProvider && provider !== null">
                    {{ !getIsEmptyWalletName ? provider.walletName : $t('fare_media:add_existant_modal:empty_provider_name') }}
                    <a
                        v-if="getShowSelectedProviderUpdate"
                        class="FareMediaAddExistantModal-showProviderSelect"
                        @click="updateShowProviderSelect(true)"
                    >{{ $t('button:edit') }}</a>
                </p>
                <p v-else-if="!getShowSelectedProvider && provider === null">
                    {{ $t('fare_media:add_existant_modal:no_provider') }}
                </p>
                <p
                    v-if="getShowForm"
                    class="FareMediaAddExistantModal-instructions"
                >
                    {{ $t('fare_media:add_existant_modal:subtitle') }}
                </p>
                <fare-media-linking-id-birthdate-form
                    v-if="getShowIdAndBirthdateForm"
                    :providerId="getProviderId"
                    class="FareMediaAddExistantModal-form"
                />
                <div class="FareMediaAddExistantModal-back">
                    <v-btn
                        class="FareMediaAddExistantModal-backButton"
                        color="primary"
                        variant="text"
                        @click="onBackButtonClick"
                    >
                        {{ $t('button:back') }}
                    </v-btn>
                </div>
            </div>
        </modal>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import FareMediaLinkingIdBirthdateForm from '@/StoreWeb/components/form/FareMediaLinkingIdBirthdateForm'
import ProviderSelect from '@/StoreWeb/components/fare-media/ProviderSelect'
import { isEmpty } from 'global-utils'
import emitter from 'global-emitter'
import { useModalActions } from '@/StoreWeb/js/composables/add-faremedia-modals-utils'

const emit = defineEmits(['closeModal', 'goNextAction', 'update:modelValue'])

const props = defineProps({
    isComingFromFareMediaChoiceModal: {
        type: Boolean,
        default: true
    },
    modelValue: {
        type: Boolean,
        required: true
    }
})

const store = useStore()
const { afterCloseAction, getBackAction } = useModalActions()

const provider = ref(null)
const showProviderSelect = ref(!isEmpty(store.getters.getWalletProvidersAvailableForAdd) && store.getters.getWalletProvidersAvailableForAdd.length > 1)

const showModal = computed({
    get: () => props.modelValue,
    set: (newValue) => {
        if (newValue) {
            init()
        } else {
            emit('goNextAction', afterCloseAction.value)
        }

        emit('update:modelValue', newValue)
    }
})

const getWalletProvidersAvailableForAdd = computed(() => store.getters.getWalletProvidersAvailableForAdd)

const getProviderId = computed(() => {
    if (getWalletProvidersAvailableForAdd.value.length === 1) {
        return getWalletProvidersAvailableForAdd.value[0].id
    } else if (!isEmpty(provider.value)) {
        return provider.value.id
    }
    return ''
})

const getIsEmptyWalletName = computed(() => {
    return isEmpty(provider.value) || isEmpty(provider.value.walletName)
})

const getShowProviderSelect = computed(() => {
    return !isEmpty(getWalletProvidersAvailableForAdd.value) &&
    getWalletProvidersAvailableForAdd.value.length > 1 &&
    (isEmpty(provider.value) || showProviderSelect.value)
})

const getShowSelectedProvider = computed(() => {
    return !isEmpty(getWalletProvidersAvailableForAdd.value) &&
    getWalletProvidersAvailableForAdd.value.length > 1 &&
    !isEmpty(provider.value) &&
    !showProviderSelect.value
})

const getShowSelectedProviderUpdate = computed(() => {
    return getWalletProvidersAvailableForAdd.value.length > 1 && !isEmpty(provider.value)
})

const getShowForm = computed(() => {
    return !isEmpty(provider.value) && !isEmpty(provider.value.walletAddAccount.mode)
})

const getShowIdAndBirthdateForm = computed(() => {
    return !isEmpty(provider.value) && provider.value.walletAddAccount.mode === 'FAREMEDIAIDBIRTHDATE'
})

onMounted(() => {
    emitter.on('walletLoaded', init)
})

function closeModal () {
    showModal.value = false
}

function init () {
    if (!isEmpty(getWalletProvidersAvailableForAdd.value)) {
        showProviderSelect.value = getWalletProvidersAvailableForAdd.value.length > 1
        if (getWalletProvidersAvailableForAdd.value.length === 1) {
            provider.value = getWalletProvidersAvailableForAdd.value[0]
            updateShowProviderSelect(false)
        }
    }
}

function onBackButtonClick () {
    afterCloseAction.value = getBackAction({
        currentModal: 'FareMediaAddExistantModal',
        isComingFromFareMediaChoiceModal: props.isComingFromFareMediaChoiceModal
    })

    closeModal()
}

function updateProvider (providerId) {
    provider.value = getWalletProvidersAvailableForAdd.value.find(provider => provider.id === providerId)

    if (!isEmpty(provider.value)) {
        updateShowProviderSelect(false)
    }
}

function updateShowProviderSelect (value) {
    showProviderSelect.value = value
}

function resetNextActionAndClose () {
    afterCloseAction.value = null
    closeModal()
    emit('closeModal')
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.FareMediaAddExistantModal {
    &-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &-instructions {
        margin-bottom: 25px;
        font-size: 16px;
        color: $color-lighterText;
    }

    &-providerSelect {
        margin: 0 0 25px;
    }

    &-form {
        margin-bottom: 15px;
    }

    &-back {
        margin: 0;
        text-align: center;
    }

    &-backButton {
        font-weight: $fontWeight-defaultBold;
    }
}
</style>
