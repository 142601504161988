<template>
    <div class="OrderDetails d-flex flex-column flex-md-row justify-md-space-between justify-space-between align-stretch align-md-start">
        <div class="OrderDetails-header align-self-stretch align-self-md-start order-md-2 rounded-xl">
            <v-card class="rounded-xl">
                <v-card-text class="d-flex flex-wrap justify-space-between align-center gap-5">
                    <p class="OrderDetails-date ma-0 text-body-2 font-weight-bold">
                        {{ getOrderDateLabel }}
                    </p>
                    <formatted-fare
                        :fare="order.totalAmountIncludingTaxes"
                        :size="'Small'"
                        class="OrderDetails-total"
                    />
                    <div
                        v-if="getInvoices.length > 0"
                        class="OrderDetails-invoiceList w-100"
                    >
                        <button
                            v-for="invoice in getInvoices"
                            :key="invoice.id"
                            class="OrderDetails-downloadInvoice font-weight-bold"
                            @click="downloadInvoice(invoice)"
                        >
                            <icomoon-icon
                                :name="'Download'"
                                class="OrderDetails-downloadInvoiceIcon"
                            />
                            <span class="OrderDetails-downloadInvoiceLabel">{{ getDownloadInvoiceButtonLabel() }}</span>
                            <v-icon
                                :aria-label="$t('open_new_tab')"
                                :aria-hidden="false"
                                class="pl-1"
                                color="primary"
                                icon="mdi-open-in-new"
                                size="medium"
                            />
                        </button>
                    </div>
                </v-card-text>
            </v-card>
        </div>
        <div class="OrderDetails-body order-md-1 flex-grow-1 pt-8 pt-md-0 pr-md-10">
            <ul
                v-if="order.baskets.length > 0"
                class="OrderDetails-basketList"
            >
                <order-basket
                    v-for="orderBasket in order.baskets"
                    :key="orderBasket.providerBasketId"
                    :basket="orderBasket"
                    :orderId="order.id"
                    class="OrderDetails-basketItem OrderDetails-bloc"
                />
            </ul>
            <ul
                v-if="false && order.packages.length > 0"
                class="OrderDetails-packageList"
            >
                <order-package
                    v-for="orderPackage in order.packages"
                    :key="orderPackage.id"
                    :orderPackage="orderPackage"
                    class="OrderDetails-packageItem OrderDetails-bloc"
                />
            </ul>
            <ul
                v-if="order.plans.length > 0"
                class="OrderDetails-planList"
            >
                <order-plan
                    v-for="orderPlan in order.plans"
                    :key="orderPlan.id"
                    :plan="orderPlan"
                    class="OrderDetails-planItem OrderDetails-bloc"
                />
            </ul>
            <template v-if="order.addresses.length > 0">
                <p class="h2">
                    {{ $t('customer_address') }}
                </p>
                <div class="OrderDetails-addressList">
                    <address-selector-recap-item
                        v-for="(orderAddress, orderIndex) in order.addresses"
                        :key="orderIndex"
                        :address="orderAddress"
                        :showChangeButton="false"
                        :showEditButton="false"
                        :showSelectButton="false"
                        :showType="true"
                        class="OrderDetails-addressItem"
                    />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import * as userActionTypes from '@/StoreWeb/store/modules/user/action-types'
import AddressSelectorRecapItem from '@/StoreWeb/components/address/AddressSelectorRecapItem'
import FormattedFare from '@/StoreWeb/components/common/FormattedFare'
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon'
import OrderBasket from '@/StoreWeb/components/account/order/OrderBasket'
import OrderPackage from '@/StoreWeb/components/account/order/OrderPackage'
import OrderPlan from '@/StoreWeb/components/account/order/OrderPlan'
import { isEmpty } from 'global-utils'
import i18n from 'i18n'

export default {
    components: {
        AddressSelectorRecapItem,
        FormattedFare,
        IcomoonIcon,
        OrderBasket,
        OrderPackage,
        OrderPlan
    },
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    computed: {
        getOrderDate () {
            const date = !isEmpty(this.order.purchaseDate) ? this.order.purchaseDate : this.order.creationDate
            return this.$moment(date).format(i18n.global.t('date_format:litteral_day_month_year'))
        },
        getOrderDateLabel () {
            return i18n.global.t('order:date').replace('%date%', this.getOrderDate)
        },
        getInvoiceId () {
            let invoiceId = null
            if (!isEmpty(this.order.invoices)) {
                const globalInvoice = this.order.invoices.find(invoice => invoice.external === false)
                if (!isEmpty(globalInvoice)) {
                    invoiceId = globalInvoice.id
                }
            } else if (!isEmpty(this.order.invoice)) {
                invoiceId = this.order.invoice.id
            }
            return invoiceId
        },
        getInvoices () {
            return this.order.invoices
        }
    },
    methods: {
        downloadInvoice (invoice) {
            const params = {
                invoiceId: invoice.id,
                orderId: this.order.id
            }
            this.$store.dispatch(userActionTypes.GET_INVOICE, params)
        },
        getDownloadInvoiceButtonLabel () {
            return i18n.global.t('order:download_invoice:button_text')
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.OrderDetails {
    background: $color-lightest;
    color: $color-lighterText;

    &-header {
        @media #{map-get($display-breakpoints, 'md-and-up')} {
            max-width: 416px;
        }
    }

    &-bloc {
        width: 100%;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-downloadInvoice {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0;
        border: none;
        background: none;
        cursor: pointer;
        font-size: 14px;
        color: $color-brandPrimary;
        transition: color .3s;

        &:hover,
        &:active,
        &:focus {
            color: $color-brandPrimaryDark;
        }

        &Icon {
            font-size: 28px;
        }
    }

    &-free,
    &-total {
        font-weight: bold;
        color: $color-defaultText;
    }

    &-free {
        line-height: 28px;
    }

    &-basketList,
    &-planList {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &-addressList {
        @media #{map-get($display-breakpoints, 'md-and-up')} {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 30px;
        }
    }
}
</style>
