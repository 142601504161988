import HistoryProductModalCounter from '@/StoreWeb/models/user/order/HistoryProductModalCounter'
import { isEmpty } from 'global-utils'

export default class {
    normalize (item) {
        const historyProductModalCounter = new HistoryProductModalCounter(item.id)

        historyProductModalCounter.name = item.name
        historyProductModalCounter.unit = item.unit
        historyProductModalCounter.version = item.version

        return historyProductModalCounter
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
