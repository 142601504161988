import AccountAssociationUserNormalizer from './AccountAssociationUserNormalizer'

const AccountAssociationUserNormalizerSingleton = (function () {
    let instance

    function createInstance () {
        const classObj = new AccountAssociationUserNormalizer()
        return classObj
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance()
            }
            return instance
        }
    }
})()

export default AccountAssociationUserNormalizerSingleton
