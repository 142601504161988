import { isEmpty } from 'global-utils'
import * as mainMutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import * as mutationTypes from '@/StoreWeb/store/modules/card-reader/mutation-types'
import cachios from 'cachios'
import DebuggingManager from '@/StoreWeb/managers/DebuggingManagerSingleton'
import ErrorManager from '@/StoreWeb/managers/ErrorManager'

export default class {
    constructor () {
        this._debuggingManager = DebuggingManager.getInstance()
        this._store = null
    }

    get debuggingManager () {
        return this._debuggingManager
    }

    get store () {
        return this._store
    }

    set store (store) {
        this._store = store
    }

    /* =============================================================================================================
    =================================================== ACTIONS ====================================================
    ============================================================================================================= */
    getOperationAction (params) {
        return new Promise((resolve, reject) => {
            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
            this.store.commit(mutationTypes.SET_CARD_READER_PENDING_REQUEST, { key: 'getOperation', value: true })

            const body = {
                operationType: params.operationType ?? '',
                providerId: !isEmpty(params.providerId) ? params.providerId : 'conduent'
            }
            if (!isEmpty(params.sessionId)) {
                body.sessionId = params.sessionId
            }
            if (!isEmpty(params.mediaSerialNumber)) {
                body.mediaSerialNumber = params.mediaSerialNumber
            }
            if (!isEmpty(params.mediaUnicity)) {
                body.mediaUnicity = params.mediaUnicity
            }

            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
            return cachios
                .post(`${process.env.VUE_APP_API_URL}/card-reader/operation`, body, { force: true })
                .then(response => {
                    this.store.commit(mutationTypes.SET_CARD_READER_PENDING_REQUEST, { key: 'getOperation', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                    resolve(response)
                })
                .catch(error => {
                    if (!params.silent) {
                        ErrorManager.displayErrorModal('card_reader:get_operation', error)
                    }
                    this.store.commit(mutationTypes.SET_CARD_READER_PENDING_REQUEST, { key: 'getOperation', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                    reject(error)
                })
        })
    }

    getOperationReportAction (params) {
        return new Promise((resolve, reject) => {
            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
            this.store.commit(mutationTypes.SET_CARD_READER_PENDING_REQUEST, { key: 'getOperationReport', value: true })

            const body = {
                operationType: !isEmpty(params.operationType) ? params.operationType : 'READ',
                providerId: !isEmpty(params.operation?.providerId) ? params.operation?.providerId : 'conduent'
            }

            if (!isEmpty(params.operation?.sessionId)) {
                body.sessionId = params.operation?.sessionId
            }
            if (!isEmpty(params.operation?.operationId)) {
                body.operationId = params.operation?.operationId
            }
            if (!isEmpty(params.operation?.servicesURL)) {
                body.servicesURL = params.operation?.servicesURL
            }
            if (!isEmpty(params.operation?.serverHostname)) {
                body.serverHostname = params.operation?.serverHostname
            }
            if (!isEmpty(params.operation?.serverPort)) {
                body.serverPort = params.operation?.serverPort
            }

            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })

            return cachios
                .post(`${process.env.VUE_APP_API_URL}/card-reader/report`, body, { force: true })
                .then(response => {
                    this.store.commit(mutationTypes.SET_CARD_READER_PENDING_REQUEST, { key: 'getOperationReport', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                    resolve(response)
                })
                .catch(error => {
                    if (!params.silent) {
                        ErrorManager.displayErrorModal('card_reader:get_operation_report', error)
                    }
                    this.store.commit(mutationTypes.SET_CARD_READER_PENDING_REQUEST, { key: 'getOperationReport', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                    reject(error)
                })
        })
    }
}
