import StoredDocument from '@/StoreWeb/models/common/StoredDocument'
import { isEmpty } from 'global-utils'

export default class {
    normalize (item) {
        const storedDocument = new StoredDocument(item.documentId)

        storedDocument.version = item.version
        storedDocument.name = item.name
        storedDocument.type = item.type
        storedDocument.extension = item.extension
        storedDocument.path = item.path
        storedDocument.size = item.size

        return storedDocument
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
