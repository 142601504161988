<template>
    <div>
        <h3 class="mb-3">
            {{ $t('address:delivery_mode:postal_on_product') }}
        </h3>

        <div v-if="addressPendingRequests.getAddresses || addressPendingRequests.getAddressTemplates">
            <v-progress-circular
                indeterminate
                color="primary"
            />
        </div>
        <div
            v-else-if="!isEditing"
        >
            <p class="ma-0">
                <strong
                    v-if="formValues.receiverFirstname"
                    class="AddressSelectorRecapItem-line"
                >
                    {{ formValues.receiverFirstname + ' ' }}
                </strong>
                <strong v-if="formValues.receiverLastname">
                    {{ formValues.receiverLastname }}
                </strong>
            </p>
            <p class="ma-0">
                <span
                    v-if="formValues.streetNumber"
                    class="AddressSelectorRecapItem-line"
                >
                    {{ formValues.streetNumber + ' ' }}
                </span>
                <span v-if="formValues.streetNumberSuffix">
                    {{ formValues.streetNumberSuffix + ' ' }}
                </span>
                <span v-if="formValues.streetName">
                    {{ formValues.streetName }}
                </span>
            </p>
            <p class="ma-0">
                <span
                    v-if="formValues.streetLine2"
                    class="AddressSelectorRecapItem-line"
                >
                    {{ formValues.streetLine2 }}
                </span>
            </p>
            <p class="ma-0">
                <span
                    v-if="formValues.zipCode !== ''"
                    class="AddressSelectorRecapItem-line"
                >
                    {{ formValues.zipCode + ' ' }}
                </span>
                <span
                    v-if="formValues.city !== ''"
                    class="AddressSelectorRecapItem-line"
                >
                    {{ formValues.city }}
                </span>
            </p>
            <p class="ma-0">
                <span
                    v-if="formValues.country !== ''"
                    class="AddressSelectorRecapItem-line"
                >
                    {{ getCountryName(formValues.country) }}
                </span>
            </p>
            <v-btn
                class="mt-3"
                color="primary"
                @click="editAddress"
            >
                {{ $t('button:edit') }}
            </v-btn>
        </div>
        <v-form
            v-if="isEditing"
            @submit.prevent="addressSubmit"
        >
            <template v-for="field in addressTemplate">
                <v-text-field
                    v-if="field.type === 'text'"
                    :key="field.id"
                    v-model="formValues[field.id]"
                    :label="field.label"
                    :readonly="field.readonly"
                    :required="field.required"
                    :error-messages="v$[field.id].$errors.map(e => e.$message)"
                    variant="underlined"
                    class="mb-2"
                    @update:model-value="updateModelValue(field)"
                />
                <v-autocomplete
                    v-if="field.type === 'choice'"
                    :key="field.id"
                    v-model="formValues[field.id]"
                    variant="underlined"
                    :items="field.params.list"
                    :label="field.label"
                    :readonly="field.readonly"
                    :required="field.required"
                    :error-messages="v$[field.id].$errors.map(e => e.$message)"
                    item-title="name"
                    item-value="id"
                    @update:model-value="updateModelValue(field)"
                />
            </template>
            <div class="mt-4 text-right">
                <v-btn
                    color="primary"
                    type="submit"
                >
                    {{ $t('address:delivery:button:edit') }}
                </v-btn>
            </div>
        </v-form>
        <div class="mt-4 text-right">
            <v-btn
                v-if="!isEditing"
                color="primary"
                @click="submit"
            >
                {{ $t('button:validate') }}
            </v-btn>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import CartManagerSingleton from '@/StoreWeb/managers/CartManagerSingleton'
import {
    initFieldsDependenciesFromTemplate,
    browseAndApplyDependenciesSchemas
} from '@/StoreWeb/js/composables/fields-dependencies-from-template-utils'
import { numeric, regex, required } from '@/StoreWeb/utils/i18n-validators'
import { helpers } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'
import { useVuelidate } from '@vuelidate/core'
import { isEmpty } from 'global-utils'
import { useLogin } from '@/StoreWeb/js/composables/login-utils'

const props = defineProps({
    addresses: {
        required: false,
        type: Object,
        default: () => {}
    },
    addToCartFlowManager: {
        required: true,
        type: Object
    },
    deliveryMode: {
        required: true,
        type: Object
    }
})

const store = useStore()
const { t } = useI18n()
const { userInfos } = useLogin()

const addressPendingRequests = computed(() => store.state.addressModule.addressPendingRequests)
const CartManager = CartManagerSingleton.getInstance()
const isEditing = ref(false)

const addressTemplate = computed(() => store.getters.getAddressTemplates.find((template) => template.code === 'DELIVERY').fields)
const template = addressTemplate.value

const formValues = computed(() => {
    const fields = {}
    addressTemplate.value?.forEach(field => {
        if (field.id === 'receiverFirstname') {
            fields[field.id] = userInfos.value.firstName
        } else if (field.id === 'receiverLastname') {
            fields[field.id] = userInfos.value.lastName
        } else {
            fields[field.id] = props.addresses?.[field.id] ?? ''
        }
    })
    return reactive(fields)
})

const rules = computed(() => {
    const rules = {}
    addressTemplate.value.forEach(field => {
        rules[field.id] = {}
        if (field.required) {
            rules[field.id].required = required
        }
        if (field.params.pattern) {
            rules[field.id].regexCustom = helpers.withMessage(
                () => {
                    const transKey = field.params.patternValidationMessage
                    let errorMsg = transKey ? t(transKey) : ''
                    if (isEmpty(errorMsg)) {
                        errorMsg = t('validations:regex')
                    }
                    return errorMsg
                },
                regex(new RegExp(field.params.pattern))
            )
        }
        if (field.params.subType === 'number') {
            rules[field.id].numeric = numeric
        }
        if (!isEmpty(field.params.list) && !isEmpty(field.value)) {
            const list = field.params.list.map(element => element.id)
            const contains = (value) => list.includes(value)
            const errorMessage = t('validations.mustBeInList')
            rules[field.id].mustBeInList = helpers.withMessage(errorMessage, contains)
        }
    })
    return rules
})

const v$ = useVuelidate(rules, formValues)

onMounted(async () => {
    const isValidForm = await isValidAddress()
    isEditing.value = isEmpty(formValues.value) || !isValidForm
    initFieldsDependenciesFromTemplate(formValues, addressTemplate.value, v$)
})

async function isValidAddress () {
    await v$.value.$validate()
    return !v$.value.$invalid
}

function editAddress () {
    isEditing.value = true
}

function getCountryName (countryCode) {
    const countryField = template.find(field => field.id === 'country')
    if (!countryField || !countryField.params || !countryField.params.list) {
        return ''
    }
    const countryObj = countryField.params.list.find(country => country.id === countryCode)
    return countryObj ? countryObj.name : ''
}

async function addressSubmit () {
    const isValid = await isValidAddress()
    if (isValid) {
        const dataFlowManager = props.addToCartFlowManager
        dataFlowManager.addToCartFlowSteps.address = formValues.value
        isEditing.value = false
    }
}

function submit () {
    const dataFlowManager = props.addToCartFlowManager
    dataFlowManager.addToCartFlowSteps.address = formValues.value
    CartManager.addToCart({
        data: dataFlowManager.addToCartFlowSteps,
        product: dataFlowManager.product
    })
}

function updateModelValue (field) {
    v$.value[field.id].$touch()
    browseAndApplyDependenciesSchemas(field)
}

</script>
