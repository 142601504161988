<template>
    <div
        v-show="showTemplateSelector"
        class="AddressTemplateSelector"
    >
        <p v-if="showLabel">
            {{ $t('address:choose_template') }}
        </p>
        <radio-button-list
            :ariaLabel="$t('address:choose_template')"
            :items="templateList"
            :value="selectedTemplate"
            class="AddressTemplateSelector-list"
            @change="updateSelectedTemplate"
        />
    </div>
</template>

<script>
import RadioButtonList from '@/StoreWeb/components/common/RadioButtonList'
import { isEmpty } from 'global-utils'

export default {
    components: {
        RadioButtonList
    },
    props: {
        defaultTemplateCode: {
            type: String,
            default: ''
        },
        showLabel: {
            type: Boolean,
            default: true
        },
        templateList: {
            type: Array,
            required: true
        }
    },
    data () {
        return {
            selectedTemplate: ''
        }
    },
    computed: {
        showTemplateSelector () {
            return this.templateList.length > 1
        }
    },
    mounted () {
        if (this.templateList.length > 0) {
            if (!isEmpty(this.defaultTemplateCode)) {
                const selectedTemplate = this.templateList.find(template => template.id === this.defaultTemplateCode)
                if (!isEmpty(selectedTemplate)) {
                    this.selectedTemplate = selectedTemplate.id
                }
            }
            if (isEmpty(this.selectedTemplate)) {
                this.selectedTemplate = this.templateList[0].id
            }
            if (!isEmpty(this.selectedTemplate)) {
                this.updateSelectedTemplate(this.selectedTemplate)
            }
        }
    },
    methods: {
        updateSelectedTemplate (value) {
            this.$emit('templateChanged', value)
        }
    }
}
</script>
