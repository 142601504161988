import UserModalCounterTransactionNormalizer from './UserModalCounterTransactionNormalizer'

const UserModalCounterTransactionNormalizerSingleton = (function () {
    let instance

    function createInstance () {
        const classObj = new UserModalCounterTransactionNormalizer()
        return classObj
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance()
            }
            return instance
        }
    }
})()

export default UserModalCounterTransactionNormalizerSingleton
