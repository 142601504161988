<template>
    <div class="Alerts">
        <h1 class="Alerts-title h1">
            {{ $t('account:alerts:title') }}
        </h1>
    </div>
</template>

<script>
import DefaultView from '@/StoreWeb/views/DefaultView'
import PageConfigManager from '@/StoreWeb/managers/PageConfigManagerSingleton'
const pageConfigManager = PageConfigManager.getInstance()

export default {
    extends: DefaultView,
    mounted () {
        pageConfigManager.setConfig({
            title: 'account:alerts:title',
            currentAccountPage: 'alerts'
        })
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.Alerts {
    /* Add css */
}
</style>
