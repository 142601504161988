import AccountAssociationType from '@/StoreWeb/models/account-association/AccountAssociationType'
import { isEmpty } from 'global-utils'

export default class {
    normalize (item) {
        const accountAssociationType = new AccountAssociationType(item.id)

        accountAssociationType.fromName = item.fromName
        accountAssociationType.interfaceName = item.interfaceName ?? ''
        accountAssociationType.isExternal = item.isExternal ?? true
        accountAssociationType.modes = item.modes ?? []
        accountAssociationType.name = item.name
        accountAssociationType.toName = item.toName

        return accountAssociationType
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
