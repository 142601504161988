import config from 'config'
import { customizeAppName } from '@/StoreWeb/js/head-customization'

export const updateSeoTags = ({ title, description, imageUrl, imageAlt, url, locale, twitterSite }) => {
    customizeAppName(`${config.app_title} - ${title}`)

    updateMetaTag('name', 'description', description)

    updateMetaTag('property', 'og:title', title)
    updateMetaTag('property', 'og:description', description)
    updateMetaTag('property', 'og:image', imageUrl)
    updateMetaTag('property', 'og:image:alt', imageAlt)
    updateMetaTag('property', 'og:url', url)
    updateMetaTag('property', 'og:locale', locale)

    updateMetaTag('name', 'twitter:title', title)
    updateMetaTag('name', 'twitter:description', description)
    updateMetaTag('name', 'twitter:image', imageUrl)
    updateMetaTag('name', 'twitter:image:alt', imageAlt)
    updateMetaTag('name', 'twitter:site', twitterSite)
}

const updateMetaTag = (attribute, nameOrProperty, content) => {
    const existingTag = document.head.querySelector(`meta[${attribute}="${nameOrProperty}"]`)
    if (existingTag) {
        existingTag.setAttribute('content', content)
    } else {
        const newTag = document.createElement('meta')
        newTag.setAttribute(attribute, nameOrProperty)
        newTag.setAttribute('content', content)
        document.head.appendChild(newTag)
    }
}

export const removeSeoMetaTags = () => {
    const metaTagsToRemove = [
        'description',
        'og:title',
        'og:description',
        'og:image',
        'og:image:alt',
        'og:url',
        'og:locale',
        'twitter:card',
        'twitter:title',
        'twitter:description',
        'twitter:image',
        'twitter:image:alt',
        'twitter:site'
    ]

    metaTagsToRemove.forEach(tagName => {
        const metaTag = document.querySelector(`meta[property="${tagName}"], meta[name="${tagName}"]`)
        if (metaTag) {
            metaTag.remove()
        }
    })
}
