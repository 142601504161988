import * as mutationTypes from './mutation-types'

export default {
    [mutationTypes.SET_PAYMENT_PENDING_REQUEST] (state, pendingRequest) {
        if (state.paymentPendingRequests[pendingRequest.key] !== undefined) {
            state.paymentPendingRequests[pendingRequest.key] = pendingRequest.value
        }
    },
    [mutationTypes.SET_PAYMENT] (state, payment) {
        state.payment = payment
    },
    [mutationTypes.SET_PAYMENT_MODES] (state, paymentModes) {
        state.paymentModes = paymentModes
    }
}
