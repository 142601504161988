import store from '@/StoreWeb/store/store'
import * as anonymousActionTypes from '@/StoreWeb/store/modules/anonymous/action-types'
import { computed } from 'vue'
import config from 'config'
import { isEmpty } from '@/StoreWeb/utils/global-utils'

export const checkAndGetFareMedia = async ({ providerId, fareMediaId, accountId }, silentError = false, isCadMode = false) => {
    const correspondingCartFareMedia = checkIfFareMediaComesFromCart(providerId, accountId, fareMediaId)

    if (correspondingCartFareMedia) {
        return {
            isValid: true,
            fareMedia: correspondingCartFareMedia
        }
    }

    const fareMediaStatus = await store.dispatch(anonymousActionTypes.GET_SUPPORT_INFOS, {
        providerId,
        fareMediaId,
        isCadMode,
        silentError
    })

    if (fareMediaStatus.isValid) {
        const data = { providerId, fareMediaId, isCadMode }
        if (typeof accountId !== 'undefined') {
            data.accountId = accountId
        }
        const fareMedia = await store.dispatch(anonymousActionTypes.GET_SUPPORT_CONTENT, data)

        if (fareMedia) {
            return {
                isValid: fareMediaStatus.isValid,
                fareMedia
            }
        }
    }

    return {
        isValid: fareMediaStatus.isValid,
        error: fareMediaStatus.error
    }
}

export function checkIfFareMediaComesFromCart (providerId, accountId, fareMediaId) {
    if (!isEmpty(store.getters.getCartFareMedias)) {
        return store.getters.getCartFareMedias.find(cartFareMedia => cartFareMedia.providerId === providerId && cartFareMedia.id === fareMediaId)
    }

    return null
}

export function isValidFareMediaId (fareMediaId, providerId) {
    const providerConfig = config.providers.find(provider => provider.id === providerId)

    if (providerConfig?.pattern) {
        return providerConfig.pattern.test(fareMediaId)
    }

    return true
}

export const addToCartContext = () => {
    const selectedFareMedia = computed(() => store.state.userModule.selectedFareMedia)

    const context = computed(() => {
        if (selectedFareMedia.value?.id) {
            return {
                chosenFareMedia: selectedFareMedia.value,
                flowType: 'FARE_MEDIA_LOADING'
            }
        }
        return null
    })

    return context.value
}
