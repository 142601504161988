import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import {
    applyLocale,
    checkAuthorizationsAndGetFinalRoute,
    deduceLocale,
    getError404Route,
    isLocale,
    isSegmentBelongsToNonLocalizedRoute,
    resetStates,
    resolveLocalizedRouteSegmentsAndGetResolvedRoute,
    resolveUnlocalizedLocalizedRouteSegmentsAndGetResolvedRoute,
    updateLocalizedRouteSegmentsLocaleAndGetNewPath
} from '@/StoreWeb/js/composables/routing-utils'
import { getSessionLocale, loadSession } from '@/StoreWeb/js/composables/application-session-utils'
import { loadCart } from '@/StoreWeb/js/composables/cart-utils'
import { isEmpty } from 'global-utils'
import LoginManager from 'user/Login/managers/LoginManager'
import config from 'config'
import store from '@/StoreWeb/store/store'
import * as mainMutationTypes from '@/StoreWeb/store/modules/main/mutation-types'

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach(async (to, from, next) => {
    if (to.name) {
        store.commit(mainMutationTypes.SET_CURRENT_ROUTE, to)
    }

    let sessionLocale

    if (to.name === 'internalLinks' && to?.query?.sessionId && to?.params?.action) {
        const session = await loadSession(to)
        sessionLocale = getSessionLocale(session)
    }

    const pathSegments = to.fullPath.split('/')
    const [, firstSegment, secondSegment] = pathSegments
    const isSecondSegmentIsQueryParams = secondSegment ? secondSegment.slice(0, 1) === '?' : false

    if (isSegmentBelongsToNonLocalizedRoute(firstSegment)) {
        next()
        return
    }

    const isLocalizedRoute = isLocale(firstSegment)
    const selectedLocale = deduceLocale(firstSegment, isLocalizedRoute)

    if (isLocalizedRoute && firstSegment !== selectedLocale) {
        const newPath = updateLocalizedRouteSegmentsLocaleAndGetNewPath(pathSegments, selectedLocale)
        next(newPath)
        return
    } else if (!isLocalizedRoute && to.name) {
        let resolvedRoute = resolveUnlocalizedLocalizedRouteSegmentsAndGetResolvedRoute(pathSegments, selectedLocale)

        if (resolvedRoute) {
            if (typeof resolvedRoute === 'string') {
                resolvedRoute = router.resolve(resolvedRoute)
            }

            next(resolvedRoute)
            return
        }

        next(getError404Route(selectedLocale))
        return
    }

    if (
        to.name === 'homeDefault' ||
        firstSegment === '' ||
        (
            isLocalizedRoute &&
            (
                !secondSegment ||
                isSecondSegmentIsQueryParams
            )
        )
    ) {
        next({ name: config.landing_page, params: { lang: selectedLocale } })
        return
    } else if (typeof to.name === 'undefined') {
        if (!isLocalizedRoute) {
            const resolvedRoute = resolveUnlocalizedLocalizedRouteSegmentsAndGetResolvedRoute(pathSegments, selectedLocale)

            if (resolvedRoute) {
                next(resolvedRoute)
                return
            }
        } else {
            const resolvedRoute = resolveLocalizedRouteSegmentsAndGetResolvedRoute(to.href)

            // We have to check if the resolved route has a name in this special case to ensure that it is a real match and avoid infinite loops
            if (resolvedRoute?.name) {
                next(resolvedRoute)
                return
            }
        }

        next(getError404Route(selectedLocale))
        return
    }

    applyLocale(sessionLocale ?? selectedLocale)

    if (store.state.cartModule.cart === null) {
        await loadCart()
    }

    resetStates()

    const finalAction = checkAuthorizationsAndGetFinalRoute(to)

    if (finalAction.allChecksPassed) {
        next()
    } else if (finalAction.hasToLogIn) {
        if (!config.use_web_harmony) {
            LoginManager.startLogInProcess(to)
        }

        next({ name: 'loggingIn', params: { lang: selectedLocale }, query: { redirectTo: to.fullPath } })
    } else if (!isEmpty(finalAction.changeForRoute)) {
        next(finalAction.changeForRoute)
    } else if (finalAction.hasToRefresh) {
        window.location.href = to.fullPath
    } else {
        next(getError404Route(selectedLocale))
    }
})

export default router
