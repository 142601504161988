<template>
    <div
        ref="container"
        class="LangSelector"
    >
        <button
            class="LangSelector-toggle"
            @click="toggleLangListDisplay"
        >
            <span
                :style="`height: ${toggleContentHeight};`"
                class="LangSelector-toggleContent"
            >
                {{ getCurrentLangLocale }}
                <icomoon-icon
                    name="ChevronDown"
                    class="LangSelector-toggleIcon"
                />
            </span>
        </button>
        <transition
            name="fade"
        >
            <ul
                v-show="showLangList"
                v-click-outside="clickOutside"
                :style="`z-index: ${zIndex};`"
                class="LangSelector-list"
            >
                <li
                    v-for="lang in getSelectableLangList"
                    :key="lang.locale"
                    class="LangSelector-item"
                >
                    <a
                        class="LangSelector-itemLink"
                        :title="lang.label"
                        @click="changeLanguage(lang)"
                    >
                        {{ lang.locale.toUpperCase() }}
                    </a>
                </li>
            </ul>
        </transition>
    </div>
</template>

<script>
import { isEmpty } from 'global-utils'
import * as mutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon'
import { mapState } from 'vuex'
import i18n from 'i18n'
import store from '@/StoreWeb/store/store'

export default {
    components: {
        IcomoonIcon
    },
    props: {
        toggleContentHeight: {
            type: String,
            default: '50px'
        },
        zIndex: {
            type: Number,
            default: 4
        }
    },
    data () {
        return {
            langs: [
                {
                    locale: 'fr',
                    label: 'Français'
                },
                {
                    locale: 'en',
                    label: 'English'
                }
            ],
            selectedLocale: i18n.locale,
            showLangList: false
        }
    },
    computed: {
        ...mapState({
            currentLang: state => state.currentLanguage
        }),
        getLangLocale () {
            return i18n.locale
        },
        getCurrentLang () {
            const currentLang = this.getLangList.find(lang => lang.locale === this.getLangLocale)

            return !isEmpty(currentLang)
                ? currentLang
                : {
                    locale: 'fr',
                    label: 'Français'
                }
        },
        getCurrentLangLocale () {
            return this.getCurrentLang.locale.toUpperCase()
        },
        getLangList () {
            const langs = []
            let i
            for (i in this.$config.i18n.supported_locales) {
                langs.push({
                    locale: i,
                    label: this.$config.i18n.supported_locales[i]
                })
            }
            return langs
        },
        getSelectableLangList () {
            return this.getLangList.filter(lang => lang.locale !== this.getLangLocale)
        }
    },
    mounted () {
        window.addEventListener('resize', this.hideLangListContent)
        window.addEventListener('scroll', this.hideLangListContent, true)
    },
    unmounted () {
        window.removeEventListener('resize', this.hideLangListContent)
        window.removeEventListener('scroll', this.hideLangListContent, true)
    },
    methods: {
        changeLanguage (lang) {
            i18n.locale = lang.locale
            i18n.global.locale = lang.locale
            store.commit(mutationTypes.SET_CURRENT_LANGUAGE, lang.locale)
            localStorage.setItem('locale', lang.locale)
            document.documentElement.setAttribute('lang', lang.locale)
            const newPath = `/${lang.locale}${this.$route.path.replace(/^\/[^/]+/, '')}`

            window.location.href = `${window.location.origin}${newPath}`
        },
        clickOutside (event) {
            if (this.$refs.container.contains(event.target)) {
                return
            }

            this.hideLangListContent()
        },
        hideLangListContent () {
            this.showLangList = false
        },
        toggleLangListDisplay () {
            this.showLangList = !this.showLangList
            if (this.showLangList) {
                this.showLangList = true
                const langList = this.$el.querySelector('.LangSelector-list')
                const button = this.$el.querySelector('.LangSelector-toggle')
                const buttonHeight = button.getBoundingClientRect().height
                langList.style.display = ''
                langList.style.left = '8px'
                langList.style.top = buttonHeight + 'px'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.LangSelector {
    position: relative;

    &-toggle {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 10px 8px;
        cursor: pointer;
        font-weight: $fontWeight-defaultMedium;
        color: $color-lighterText;
        transition: all .3s;

        &Content {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            padding: 10px 15px;
            border-radius: 4px;
            background: $color_lightgray1;
        }

        &:hover,
        &:active,
        &:focus {
            color: $color-lightText;
        }

        &Icon {
            margin-left: 4px;
            font-size: 16px;
        }
    }

    &-list {
        position: absolute;
        z-index: 3;
        top: 30px;
        left: 8px;
        overflow: auto;
        margin: 0;
        padding: 0;
        border-radius: 6px;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .3);
        background: $color-lightest;
        list-style: none;
    }

    &-itemLink {
        display: block;
        padding: 10px 15px;
        cursor: pointer;
        font-weight: $fontWeight-defaultMedium;
        text-align: center;
        color: $color-lighterText;
        transition: all .3s;

        &:hover,
        &:active {
            background: $color-lightgray1;
            color: $color-lightText;
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .3s ease-in-out;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
    transition: bottom .6s ease-in-out, opacity .6s ease-in-out;
}

.slide-enter,
.slide-leave-to {
    bottom: -100%;
    opacity: 0;
}
</style>
