import * as actionTypes from './action-types'
import AnonymousManager from '@/StoreWeb/managers/AnonymousManagerSingleton'
const anonymousManager = AnonymousManager.getInstance()

export default {
    /**
     * Get support content
     * @param params
     */
    [actionTypes.GET_SUPPORT_CONTENT] (...args) {
        const [, params] = args
        return anonymousManager.getSupportContentAction(params)
    },
    /**
     * Get support infos
     * @param params
     */
    [actionTypes.GET_SUPPORT_INFOS] (...args) {
        const [, params] = args
        return anonymousManager.getSupportInfosAction(params)
    }
}
