import IdNamePattern from '@/StoreWeb/models/patterns/IdNamePattern'
import { isEmpty } from 'global-utils'

export default class {
    normalize (item) {
        let normalizedItem

        if (typeof item === 'string') {
            normalizedItem = new IdNamePattern(item, '')
        } else {
            if (isEmpty(item.name)) {
                normalizedItem = new IdNamePattern(item.id, '')
            } else {
                normalizedItem = new IdNamePattern(item.id, item.name)
            }
        }

        return normalizedItem
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
