export const GO_TO_ACCOUNT_ASSOCIATION = 'accountLinks'
export const GO_TO_ADDRESSES = 'addresses'
export const GO_TO_ALERTING_PREFERENCES = 'alertingPreferences'
export const GO_TO_BANK_ACCOUNTS = 'bankAccounts'
export const GO_TO_CATALOG = 'catalog'
export const GO_TO_CONTACT = 'contact'
export const GO_TO_DIRECT_DEBITS = 'directDebits'
export const GO_TO_FINE_REGULARIZATION = 'fineRegularization'
export const GO_TO_USER_INFO = 'informations'
export const GO_TO_OPEN_PAYMENT_TRIPS = 'openPaymentTrips'
export const GO_TO_ORDERS = 'orders'
export const GO_TO_PERSONAL_DATA = 'personalData'
export const GO_TO_PRODUCT_DETAILS = 'productDetails'
export const GO_TO_REDUCED_MOBILITY = 'reducedMobility'
export const GO_TO_WALLET = 'wallet'
