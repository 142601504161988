import { isEmpty } from 'global-utils'
import * as actionTypes from '@/StoreWeb/store/modules/address/action-types'
import * as mainMutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import * as mutationTypes from '@/StoreWeb/store/modules/address/mutation-types'
import cachios from 'cachios'
import emitter from 'global-emitter'
import AddressNormalizer from '@/StoreWeb/normalizers/address/AddressNormalizerSingleton'
import FormNormalizer from '@/StoreWeb/normalizers/form/FormNormalizerSingleton'
import AddressSerializer from '@/StoreWeb/serializers/AddressSerializerSingleton'
import DebuggingManager from '@/StoreWeb/managers/DebuggingManagerSingleton'
import config from 'config'
import ErrorManager from '@/StoreWeb/managers/ErrorManager'
// import AddressTemplatesMock from '@/StoreWeb/mocks/address/get-address-templates/get-address-templates-57-2022-12-27.json'
// import UserAddressesMock from '@/StoreWeb/mocks/address/get-addresses-default.json'

export default class {
    constructor () {
        this._addresses = []
        this._addressNormalizer = AddressNormalizer.getInstance()
        this._addressSerializer = AddressSerializer.getInstance()
        this._debuggingManager = DebuggingManager.getInstance()
        this._formNormalizer = FormNormalizer.getInstance()
        this._templates = []
        this._store = null
    }

    get addresses () {
        return this._addresses
    }

    set addresses (addresses) {
        this._addresses = addresses
    }

    get addressNormalizer () {
        return this._addressNormalizer
    }

    get addressSerializer () {
        return this._addressSerializer
    }

    get debuggingManager () {
        return this._debuggingManager
    }

    get formNormalizer () {
        return this._formNormalizer
    }

    get templates () {
        return this._templates
    }

    set templates (templates) {
        this._templates = templates
    }

    get store () {
        return this._store
    }

    set store (store) {
        this._store = store
    }

    getAddresses (state) {
        let addresses = []
        const orderedAddresses = []

        if (!isEmpty(state.addresses)) {
            addresses = this.addressNormalizer.normalizeArray(state.addresses)

            addresses.forEach(address => {
                if (address.defaultAddress) {
                    orderedAddresses.push(address)
                }
            })
            addresses.forEach(address => {
                if (!address.defaultAddress) {
                    orderedAddresses.push(address)
                }
            })
        }

        return orderedAddresses
    }

    getAddressesCount (state) {
        return state.addresses?.length
    }

    getTemplates (state) {
        let templates = []

        if (!isEmpty(state.addressTemplates.templates)) {
            templates = this.formNormalizer.normalizeArray(state.addressTemplates.templates)
        }

        return templates
    }

    setTemplateWithInitialValues (template) {
        if (!isEmpty(template) && !isEmpty(template.fields)) {
            template.fields.forEach((field, index) => {
                if (field.id === 'country' && !isEmpty(field.params.list) && isEmpty(field.value)) {
                    field.value = field.params.list[0].id
                }
            })
        }

        return template
    }

    getAuthorizations () {
        const authorizations = {
            add: config.address.add,
            edit: config.address.edit,
            maxNb: config.address.max_number,
            minNb: config.address.min_number,
            remove: config.address.remove
        }

        return authorizations
    }

    getAddAddressEnabled (state) {
        if (config.address.add) {
            return config.address.max_number === 0 || state.addresses.length < config.address.max_number
        }
        return false
    }

    getRemoveAddressEnabled (state) {
        if (config.address.remove) {
            return state.addresses.length > config.address.min_number
        }
        return false
    }

    /* =============================================================================================================
    =================================================== ACTIONS ====================================================
    ============================================================================================================= */
    createAddressAction (params) {
        return new Promise((resolve, reject) => {
            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
            this.store.commit(mutationTypes.SET_ADDRESS_PENDING_REQUEST, { key: 'createAddress', value: true })
            const body = this.addressSerializer.serialize(params.data, params.template)

            cachios.post(`${process.env.VUE_APP_API_URL}/address/create`, body)
                .then(response => {
                    if (!isEmpty(params) && typeof params.callback === 'function') {
                        params.callback()
                        resolve(response)
                    } else {
                        this.store.dispatch(actionTypes.GET_ADDRESSES, {
                            cachiosOptions: {
                                force: true
                            }
                        })
                            .then((response) => resolve(response))
                            .catch(error => {
                                ErrorManager.displayErrorModal('address:get_address', error)
                                reject(error)
                            })
                    }
                })
                .catch(error => {
                    ErrorManager.displayErrorModal('address:create_address', error)
                    reject(error)
                })
                .finally(() => {
                    this.store.commit(mutationTypes.SET_ADDRESS_PENDING_REQUEST, { key: 'createAddress', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                })
        })
    }

    deleteAddressAction (params) {
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
        this.store.commit(mutationTypes.SET_ADDRESS_PENDING_REQUEST, { key: 'deleteAddress', value: true })
        if (this.store.state.useMocks?.all || this.store.state.useMocks?.deleteAddress) {
            setTimeout(() => {
                this.store.commit(mutationTypes.SET_ADDRESS_PENDING_REQUEST, { key: 'deleteAddress', value: false })
                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
            }, 250)
        } else {
            cachios
                .delete(`${process.env.VUE_APP_API_URL}/address/delete/${params.id}`)
                .then(async (response) => {
                    const deletedAddress = this.store.state.addressModule.addresses.find(address => address.id === params.id)

                    if (deletedAddress.defaultAddress) {
                        const addresses = await this.store.dispatch(actionTypes.GET_ADDRESSES, {
                            cachiosOptions: {
                                force: true
                            }
                        })

                        const [firstAddress] = addresses

                        if (!isEmpty(firstAddress)) {
                            await this.store.dispatch(actionTypes.SET_ADDRESS_AS_DEFAULT, {
                                id: firstAddress.id
                            })
                        }
                    } else {
                        this.store.commit(mutationTypes.SET_ADDRESSES, this.store.state.addressModule.addresses.filter(address => address.id !== params.id))
                    }
                    this.store.commit(mutationTypes.SET_ADDRESS_PENDING_REQUEST, { key: 'deleteAddress', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                })
                .catch(error => {
                    ErrorManager.displayErrorModal('address:delete_address', error)
                    this.store.commit(mutationTypes.SET_ADDRESS_PENDING_REQUEST, { key: 'deleteAddress', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                })
        }
    }

    getAddressAction (params) {
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
        this.store.commit(mutationTypes.SET_ADDRESS_PENDING_REQUEST, { key: 'getAddress', value: true })
        if (this.store.state.useMocks.all || this.store.state.useMocks.getAddresses) {
            setTimeout(() => {
                this.store.commit(mutationTypes.SET_ADDRESS_PENDING_REQUEST, { key: 'getAddress', value: false })
                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
            }, 250)
        } else {
            cachios
                .get(`${process.env.VUE_APP_API_URL}/address/${params.id}`, params?.cachiosOptions)
                .then(response => {
                    this.store.commit(mutationTypes.SET_ADDRESSES, response.data)
                    this.store.commit(mutationTypes.SET_ADDRESS_PENDING_REQUEST, { key: 'getAddress', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                })
                .catch(error => {
                    ErrorManager.displayErrorModal('address:get_address', error)
                    this.store.commit(mutationTypes.SET_ADDRESS_PENDING_REQUEST, { key: 'getAddress', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                })
        }
    }

    getShopAddressesAction (params) {
        return new Promise((resolve, reject) => {
            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
            return cachios
                .get(`${process.env.VUE_APP_API_URL}/cart/${params.id}/shops`)
                .then(response => {
                    resolve(response.data)
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                })
                .catch(error => {
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                    reject(error)
                })
        })
    }

    getAddressesAction (params) {
        return new Promise((resolve, reject) => {
            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
            this.store.commit(mutationTypes.SET_ADDRESS_PENDING_REQUEST, { key: 'getAddresses', value: true })

            if (this.debuggingManager.getMockActionState('getAddresses')) {
                this.debuggingManager.getResponse('getAddresses', (response) => {
                    this.store.commit(mutationTypes.SET_ADDRESSES, response.data.addresses)
                    emitter.emit('addressesLoaded')
                    this.store.commit(mutationTypes.SET_ADDRESS_PENDING_REQUEST, { key: 'getAddresses', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                })
            } else {
                cachios
                    .get(`${process.env.VUE_APP_API_URL}/address/list`, !isEmpty(params) && typeof params.cachiosOptions !== 'undefined' ? params.cachiosOptions : {})
                    .then(response => {
                        this.store.commit(mutationTypes.SET_ADDRESSES, response.data.addresses)
                        this.store.commit(mutationTypes.SET_ADDRESS_PENDING_REQUEST, { key: 'getAddresses', value: false })
                        emitter.emit('addressesLoaded')
                        resolve(response.data.addresses)
                    })
                    .catch(error => {
                        ErrorManager.displayErrorModal('address:get_addresses', error)
                        this.store.commit(mutationTypes.SET_ADDRESS_PENDING_REQUEST, { key: 'getAddresses', value: false })
                        reject(error)
                    })
                    .finally(() => {
                        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                    })
            }
        })
    }

    async fetchAddressTemplates () {
        const response = await cachios.get(`${process.env.VUE_APP_API_URL}/address/templates`, {
            force: true
        })
        return response.data
    }

    getAddressTemplatesAction (params) {
        this.store.commit(mutationTypes.SET_ADDRESS_PENDING_REQUEST, { key: 'getAddressTemplates', value: true })
        return cachios
            .get(`${process.env.VUE_APP_API_URL}/address/templates`, params?.cachiosOptions)
            .then(response => {
                this.store.commit(mutationTypes.SET_ADDRESS_TEMPLATES, response.data)
                this.store.commit(mutationTypes.SET_ADDRESS_PENDING_REQUEST, { key: 'getAddressTemplates', value: false })
            })
            .catch(error => {
                ErrorManager.displayErrorModal('address:get_address_templates', error)
                this.store.commit(mutationTypes.SET_ADDRESS_PENDING_REQUEST, { key: 'getAddressTemplates', value: false })
            })
    }

    setAddressAsDefaultAction (params) {
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
        this.store.commit(mutationTypes.SET_ADDRESS_PENDING_REQUEST, { key: 'setAddressAsDefault', value: true })

        cachios
            .post(`${process.env.VUE_APP_API_URL}/address/${params.id}/default`, '', { force: true })
            .then(response => {
                this.store.commit(mutationTypes.SET_ADDRESS_PENDING_REQUEST, { key: 'setAddressAsDefault', value: false })
                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                this.store.dispatch(actionTypes.GET_ADDRESSES, {
                    cachiosOptions: {
                        force: true
                    }
                })
            })
            .catch(error => {
                ErrorManager.displayErrorModal('address:set_default_address', error)
                this.store.commit(mutationTypes.SET_ADDRESS_PENDING_REQUEST, { key: 'setAddressAsDefault', value: false })
                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
            })
    }

    updateAddressAction (params) {
        return new Promise((resolve, reject) => {
            const body = this.addressSerializer.serialize(params.data, params.template)
            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
            this.store.commit(mutationTypes.SET_ADDRESS_PENDING_REQUEST, { key: 'updateAddress', value: true })
            cachios
                .post(`${process.env.VUE_APP_API_URL}/address/update/${params.id}`, body, params.cachiosOptions)
                .then(response => {
                    this.store.commit(mutationTypes.SET_ADDRESS_PENDING_REQUEST, { key: 'updateAddress', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                    resolve(response.data)
                })
                .catch(error => {
                    ErrorManager.displayErrorModal('address:update_address', error)
                    reject(error.message)
                    this.store.commit(mutationTypes.SET_ADDRESS_PENDING_REQUEST, { key: 'updateAddress', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                })
        })
    }
}
