<template>
    <v-container
        ma-0
        pa-0
    >
        <v-row>
            <v-col>
                <div class="h2">
                    {{ $t('account:informations:title') }}
                </div>
                <sytral-form />
            </v-col>
        </v-row>
        <v-row>
            <v-col no-gutters>
                <address-list class="Addresses-addressList" />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import SytralForm from '@/StoreWeb/components/account/informations/Sytral/SytralForm.vue'
import AddressList from '@/StoreWeb/components/address/AddressList.vue'
export default {
    components: {
        SytralForm,
        AddressList
    }
}
</script>
