export const useMatomoEventTracker = () => {
    let matomoCheckingTimeoutId = null

    const checkMatomoLoaded = (callback) => {
        if (typeof window._paq !== 'undefined' && window._paq.push) {
            clearTimeout(matomoCheckingTimeoutId)
            callback()
            return
        }

        matomoCheckingTimeoutId = setTimeout(function () {
            checkMatomoLoaded(callback)
        }, 100)
    }

    const clearMatomoEventTracker = () => {
        clearTimeout(matomoCheckingTimeoutId)
    }

    const trackMatomoEvent = (eventToTrack) => {
        checkMatomoLoaded(() => {
            window._paq.push(eventToTrack)
        })
    }

    const trackMatomoEvents = (eventsToTrack) => {
        checkMatomoLoaded(() => {
            eventsToTrack.forEach(event => {
                window._paq.push(event)
            })
        })
    }

    const mapProductToMatomoEvent = (product) => {
        return ['addEcommerceItem',
            product.item.id,
            product.item.name,
            '',
            product.item.fareIncludingTaxes,
            product.item.quantity
        ]
    }

    const mapOrderToMatomoEvent = (order) => {
        return [
            'trackEcommerceOrder',
            order.id,
            order.totalAmountIncludingTaxes,
            order.totalAmountExcludingTaxes,
            order.totalVatAmount,
            0,
            false
        ]
    }

    return {
        trackMatomoEvent,
        trackMatomoEvents,
        clearMatomoEventTracker,
        mapProductToMatomoEvent,
        mapOrderToMatomoEvent
    }
}
