import { isEmpty } from 'global-utils'
import config from 'config'
import i18n from 'i18n'
import store from '@/StoreWeb/store/store'
import * as catalogMutationTypes from '@/StoreWeb/store/modules/catalog/mutation-types'
import * as userMutationTypes from '@/StoreWeb/store/modules/user/mutation-types'
import emitter from 'global-emitter'
import { setSelectedFareMediaCookie } from '@/StoreWeb/js/mixins/wallet-utils'

export const getFiltersToApply = (appliedFilters) => {
    let isFirst = true
    const result = appliedFilters.value.reduce((accumulator, filter) => {
        if (!isEmpty(filter.value) && filter.value !== '' && filter.value !== false) {
            accumulator.filtersToApply.push(filter)
            accumulator.filtersQueryParamsValue += `${isFirst ? '' : '|'}${filter.id}=${filter.value}`
            accumulator.filtersTitle += `${isFirst ? '' : ', '}${filter.id} ${filter.value}`
            isFirst = false
        }
        return accumulator
    }, {
        filtersToApply: [],
        filtersQueryParamsValue: '',
        filtersTitle: ''
    })

    if (result.filtersTitle !== '') {
        result.filtersTitle = i18n.global.t('catalog:filter:current_title').replace('%filters%', result.filtersTitle)
    }

    return result
}

export const updateFilters = (appliedFilters) => {
    const { filtersToApply, filtersQueryParamsValue, filtersTitle } = getFiltersToApply(appliedFilters)
    let nextURL = window.location.href

    if (nextURL.indexOf('?') !== -1) {
        [nextURL] = nextURL.split('?')
    }

    const params = new URLSearchParams(window.location.search)
    params.delete('catalogFilters')

    if (filtersQueryParamsValue) {
        params.set('catalogFilters', filtersQueryParamsValue)
    }

    nextURL = `${nextURL}${params.size ? '?' : ''}${params}`
    const nextTitle = `${filtersTitle} - ${config.app_title}`
    const nextState = { additionalInformation: nextTitle }
    window.history.replaceState(nextState, nextTitle, nextURL)
    store.commit(catalogMutationTypes.SET_APPLIED_FILTERS, filtersToApply)
}

export const applyFiltersFromQueryParams = (filters, appliedFilters) => {
    const params = new URLSearchParams(window.location.search)
    const catalogFilters = params.get('catalogFilters')
    const resetSelectedSupportFilter = params.get('resetSelectedSupportFilter') === 'true'
    const resetAppliedFilters = params.get('resetFilters') === 'true'

    appliedFilters.value = filters.value

    if (resetAppliedFilters) {
        resetFilters(appliedFilters, ['catalogFilters'])
    }

    const updatedFilters = []
    let settledFilters = []

    if (!isEmpty(catalogFilters)) {
        settledFilters = catalogFilters.split('|')

        for (const settledFilter of settledFilters) {
            const [filterId, filterValue] = settledFilter.split('=')
            const currentFilter = appliedFilters.value.find(filter => filter.id === filterId)

            if (currentFilter && currentFilter.filters.some(filter => filter.id === filterValue)) {
                updatedFilters.push(settledFilter)
                currentFilter.value = filterValue
            }
        }
    }

    if (resetSelectedSupportFilter) {
        setSelectedFareMediaCookie({
            fareMediaId: 'ALL'
        })

        store.commit(userMutationTypes.SET_SELECTED_SUPPORT, null)
        emitter.emit('selectedSupportChanged')
    }

    if (updatedFilters.length !== settledFilters.length) {
        if (updatedFilters.length === 0) {
            params.set('catalogFilters', updatedFilters.join('|'))
            const newUrl = `${window.location.pathname}`
            window.history.pushState({}, '', newUrl)
        } else {
            params.set('catalogFilters', updatedFilters.join('|'))
            const newUrl = `${window.location.pathname}?${params.toString()}`
            window.history.pushState({}, '', newUrl)
        }
    }

    const { filtersToApply } = getFiltersToApply(appliedFilters)
    store.commit(catalogMutationTypes.SET_APPLIED_FILTERS, filtersToApply)
}

export const resetFilters = (appliedFilters, filtersToKeep = []) => {
    let nextURL = window.location.href

    if (nextURL.indexOf('?') !== -1) {
        [nextURL] = nextURL.split('?')
    }

    const params = new URLSearchParams(window.location.search)
    const paramsToRemove = ['catalogFilters', 'resetFilters', 'resetSelectedSupportFilter'].filter(param => !filtersToKeep.includes(param))

    paramsToRemove.forEach(param => params.delete(param))

    nextURL = `${nextURL}${params.size ? '?' : ''}${params}`
    const nextTitle = `${config.app_title}`
    const nextState = { additionalInformation: nextTitle }
    window.history.replaceState(nextState, nextTitle, nextURL)

    for (const filter of appliedFilters.value) {
        filter.value = ''
    }

    store.commit(catalogMutationTypes.SET_APPLIED_FILTERS, [])
}
