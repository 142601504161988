<template>
    <div class="EditEmail">
        <v-form @submit.prevent="submit">
            <v-text-field
                v-model="email"
                :error-messages="v$.email.$errors.map(e => e.$message)"
                :label="$t('form:user_infos:email:label')"
                variant="underlined"
                @update:model-value="v$.email.$touch()"
            >
                <template #append>
                    <v-icon
                        v-if="submitted && submitSucceed"
                        icon="mdi-check"
                        color="success"
                    />
                    <v-icon
                        v-else-if="submitted && !submitSucceed"
                        icon="mdi-close"
                        color="error"
                    />
                </template>
            </v-text-field>
        </v-form>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email as emailValidator } from '@/StoreWeb/utils/i18n-validators'
import * as userActionTypes from '@/StoreWeb/store/modules/user/action-types'
import { useStore } from 'vuex'

const store = useStore()

const props = defineProps({
    email: {
        type: String,
        default: '',
        required: true
    }
})

watch(
    () => props.email,
    (newEmail) => {
        email.value = newEmail
        originalEmail.value = newEmail
    }
)
const email = ref(props.email)
const originalEmail = ref(props.email)
const submitSucceed = ref(false)
const submitted = ref(false)

const hasChanged = ref(false)

watch(email, (newValue) => {
    hasChanged.value = newValue !== originalEmail.value
})

const emit = defineEmits(['validated', 'submitted'])

const rules = {
    email: { required, email: emailValidator }
}
const v$ = useVuelidate(rules, { email })

async function validate () {
    submitSucceed.value = false
    submitted.value = false

    emit('validated', await v$.value.$validate())
}

async function submit () {
    await validate()

    if (!v$.value.$pending && v$.value.$error) {
        return
    }

    let result = { success: true }
    if (hasChanged.value) {
        result = await store.dispatch(userActionTypes.SET_EMAIL, {
            newEmail: email.value,
            oldEmail: originalEmail.value
        })
    }

    submitSucceed.value = true
    submitted.value = true
    hasChanged.value = false

    emit('submitted', result)
}

defineExpose({ validate, submit, hasChanged })
</script>
