<template>
    <div class="UserAvatar">
        <div
            v-if="userInitials !== ''"
            class="UserAvatar-initials"
        >
            {{ userInitials }}
        </div>
        <div
            v-else
            class="UserAvatar-iconWrapper"
        >
            <span class="UserAvatar-icon IconAccount--outlined" />
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useLogin } from '@/StoreWeb/js/composables/login-utils'
import { isEmpty } from 'global-utils'

const { userInfos } = useLogin()

const userInitials = computed(() => {
    if (!isEmpty(userInfos.value)) {
        return userInfos.value.firstName.slice(0, 1).ucFirst() + userInfos.value.lastName.slice(0, 1).ucFirst()
    }
    return ''
})
</script>

<style lang="scss" scoped>
@import 'globalScss';

.UserAvatar {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background: $color-lightgray1;

    &-initials {
        display: block;
        padding: 10px 5px 0;
        font-size: 16px;
        font-weight: $fontWeight-defaultBold;
        line-height: 20px;
        text-align: center;
        color: $color-lighterText;
    }

    &-iconWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
    }

    &-icon {
        font-size: 28px;
        color: $color-lighterText;
    }
}
</style>
