/**
 * Capitalize the first letter of the string
 * @type {*|(function(): string)}
 */
// eslint-disable-next-line no-extend-native
String.prototype.ucFirst = String.prototype.ucFirst || function () {
    return this.length ? this.charAt(0).toUpperCase() + this.slice(1) : ''
}

/**
 * Replace respectively multiple segments of the string
 * @type {*|(function(): string)}
 */
// eslint-disable-next-line no-extend-native
String.prototype.multiReplace = String.prototype.multiReplace || function (originalSegments, newSegments) {
    if (Array.isArray(originalSegments) && Array.isArray(newSegments)) {
        const count = Math.min(originalSegments.length, newSegments.length)
        let result = this
        let i
        for (i = 0; i < count; i++) {
            result = result.replace(originalSegments[i], newSegments[i])
        }
        return result
    }
    // eslint-disable-next-line no-throw-literal
    throw 'multiReplace exception: The originalSegments and newSegments arrays does not have the same length'
}

/**
 * Add or update an url a parameter paramName with the value paramValue
 * @type {*|(function(*=, *=): string)}
 */
// eslint-disable-next-line no-extend-native
String.prototype.setUrlParameter = String.prototype.setUrlParameter || function (paramName, paramValue) {
    const url = new URL(this)
    const params = new URLSearchParams(url.search.slice(1))
    params.set(paramName, paramValue)

    return `${url.origin}${url.pathname}?${params}`
}

/**
 * Remove from an url the parameter paramName
 * @type {*|(function(*=): string)}
 */
// eslint-disable-next-line no-extend-native
String.prototype.removeUrlParameter = String.prototype.removeUrlParameter || function (paramName) {
    const url = new URL(this)
    const params = new URLSearchParams(url.search.slice(1))
    params.delete(paramName)

    return `${url.origin}${url.pathname}?${params}`
}

// Warn if overriding existing method
// eslint-disable-next-line
if (Array.prototype.equals) { console.warn("Overriding existing Array.prototype.equals. Possible causes: New API defines the method, there's a framework conflict or you've got double inclusions in your code.") }
// attach the .equals method to Array's prototype to call it on any array
// eslint-disable-next-line no-extend-native
Array.prototype.equals = function (array) {
    // if the other array is a falsy value, return
    if (!array) { return false }

    // compare lengths - can save a lot of time
    if (this.length !== array.length) { return false }

    for (let i = 0, l = this.length; i < l; i++) {
        // Check if we have nested arrays
        if (this[i] instanceof Array && array[i] instanceof Array) {
            // recurse into the nested arrays
            if (!this[i].equals(array[i])) { return false }
        } else if (this[i] !== array[i]) {
            // Warning - two different object instances will never be equal: {x:20} != {x:20}
            return false
        }
    }
    return true
}
// Hide method from for-in loops
// eslint-disable-next-line no-extend-native
Object.defineProperty(Array.prototype, 'equals', { enumerable: false })

// Add an URL canParse method if not already defined for old browsers as the node module @braintree/sanitize-url uses it
URL.canParse = URL.canParse || function (url) {
    try {
        // eslint-disable-next-line no-unused-vars
        const parsedUrl = new URL(url)
        return true
    } catch {
        return false
    }
}
