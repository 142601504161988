<template>
    <div class="TermsOfServiceSelector">
        <h2
            v-if="title !== ''"
            class="TermsOfServiceSelector-title"
        >
            {{ title }}
        </h2>
        <label
            for="TermsOfServiceSelector-input"
            class="TermsOfServiceSelector-labelWrapper"
        >
            <span class="TermsOfServiceSelector-checkboxWrapper">
                <input
                    id="TermsOfServiceSelector-input"
                    :checked="isChecked"
                    class="TermsOfServiceSelector-input"
                    required="true"
                    type="checkbox"
                    @change="checkboxChange"
                >
                <span class="TermsOfServiceSelector-icon IconCheck" />
            </span>
            <span class="TermsOfServiceSelector-label">
                {{ getAcceptTermsOfServiceSelectorPre }}
                <a
                    :href="sanitizeUrl(termsOfServiceUrl)"
                    class="TermsOfServiceSelector-link"
                    target="_blank"
                >
                    {{ $t('add_cart_flow:terms_of_service:acronym') }}
                    <v-icon
                        :aria-label="$t('open_new_tab')"
                        :aria-hidden="false"
                        class="pl-1"
                        color="primary"
                        icon="mdi-open-in-new"
                        size="medium"
                    />
                </a>
                {{ getAcceptTermsOfServiceSelectorPost }}
            </span>
        </label>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { sanitizeUrl } from '@braintree/sanitize-url'

const emit = defineEmits(['updateTermsOfServiceApproval'])

const props = defineProps({
    isChecked: {
        type: Boolean,
        default: true
    },
    termsOfServiceUrl: {
        type: String,
        required: true
    },
    title: {
        type: String,
        default: ''
    }
})

const { t } = useI18n()

const isChecked = ref(props.isChecked)

const getAcceptTermsOfServiceSelectorPre = computed(() => {
    const index = t('add_cart_flow:terms_of_service:accept_sentence').lastIndexOf('%termsOfServiceLink%')
    return t('add_cart_flow:terms_of_service:accept_sentence').substr(0, index)
})

const getAcceptTermsOfServiceSelectorPost = computed(() => {
    const index = t('add_cart_flow:terms_of_service:accept_sentence').lastIndexOf('%termsOfServiceLink%') + '%termsOfServiceLink%'.length
    return t('add_cart_flow:terms_of_service:accept_sentence').substr(index, t('sales_terms:approve_message').length)
})

function checkboxChange (event) {
    emit('updateTermsOfServiceApproval', event.target.checked)
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

.TermsOfServiceSelector {
    &-title {
        margin: 0 0 10px;
        font-size: 18px;
    }

    &-labelWrapper {
        display: flex;
        justify-content: flex-start;
        cursor: pointer;
    }

    &-checkboxWrapper {
        position: relative;
        overflow: hidden;
        min-width: 19px;
        max-width: 19px;
        min-height: 19px;
        max-height: 19px;
        margin-right: 10px;
        border: solid 1px $color-lightgray3;
        border-radius: 2px;
        background-color: $color-lightest;
    }

    &-icon {
        font-size: 18px;
        color: $color-brandPrimary;
        opacity: 0;
        transition: opacity .2s;
    }

    &-input {
        position: absolute;
        top: -9999px;
        left: -9999px;

        &:checked ~ .TermsOfServiceSelector-icon {
            opacity: 1;
        }
    }

    &-label {
        flex-grow: 1;
        font-size: 14px;
    }

    &-link {
        cursor: pointer;
        font-weight: bold;
        color: $color-brandPrimary;
        transition: all .3s;

        &:hover,
        &:focus {
            color: $color-brandPrimaryDark;
        }
    }
}
</style>
