export default class {
    constructor () {
        this._basketLine = 1
        this._comment = ''
        this._headerId = ''
        this._productId = ''
        this._productLine = 1
        this._productName = ''
        this._productVersion = 1
        this._realm = ''
        this._validFrom = ''
        this._validTo = ''
        this._value = 0
    }

    get basketLine () {
        return this._basketLine
    }

    set basketLine (basketLine) {
        this._basketLine = basketLine
    }

    get comment () {
        return this._comment
    }

    set comment (comment) {
        this._comment = comment
    }

    get headerId () {
        return this._headerId
    }

    set headerId (headerId) {
        this._headerId = headerId
    }

    get productId () {
        return this._productId
    }

    set productId (productId) {
        this._productId = productId
    }

    get productLine () {
        return this._productLine
    }

    set productLine (productLine) {
        this._productLine = productLine
    }

    get productName () {
        return this._productName
    }

    set productName (productName) {
        this._productName = productName
    }

    get productVersion () {
        return this._productVersion
    }

    set productVersion (productVersion) {
        this._productVersion = productVersion
    }

    get realm () {
        return this._realm
    }

    set realm (realm) {
        this._realm = realm
    }

    get validFrom () {
        return this._validFrom
    }

    set validFrom (validFrom) {
        this._validFrom = validFrom
    }

    get validTo () {
        return this._validTo
    }

    set validTo (validTo) {
        this._validTo = validTo
    }

    get value () {
        return this._value
    }

    set value (value) {
        this._value = value
    }

    toJSON () {
        return {
            basketLine: this.basketLine,
            comment: this.comment,
            headerId: this.headerId,
            productId: this.productId,
            productLine: this.productLine,
            productName: this.productName,
            productVersion: this.productVersion,
            realm: this.realm,
            validFrom: this.validFrom,
            validTo: this.validTo,
            value: this.value
        }
    }
}
