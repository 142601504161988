<template>
    <component
        :is="type"
        class="AuthenticationModeItem"
    >
        <v-btn
            class="AuthenticationModeItem-button"
            :color="color"
            size="large"
            elevation="0"
            @click.prevent="selectAuthenticationMode"
        >
            {{ label }}
        </v-btn>
    </component>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: 'li'
        }
    },
    methods: {
        selectAuthenticationMode () {
            this.$emit('authenticationModeClicked', this.id)
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.AuthenticationModeItem {
    list-style: none;

    &-link {
        display: flex;
        padding: 10px 10px 14px;
        border: 2px solid $color-lightgray4;
        border-radius: 6px;
        box-shadow: $box-shadow-light;
        background: $color-lightest;
        cursor: pointer;
        color: $color-lighterText;
        transition: background .3s;

        &:hover,
        &:active {
            background: $color-lightgray3;
        }
    }

    &:first-child {
        padding-top: 0;
        border-top: none;
    }

    &-label {
        flex-grow: 1;
    }

    &-title {
        display: block;
        font-family: $fontFamily-title;
        font-size: 18px;
        font-weight: $fontWeight-titleBold;
        color: $color-defaultText;
    }

    &-subtitle {
        font-size: 12px;
        font-weight: $fontWeight-defaultMedium;
    }
}
</style>
