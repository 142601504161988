import HistoryModalCounter from '@/StoreWeb/models/user/order/HistoryModalCounter'
import { isEmpty } from 'global-utils'

export default class {
    normalize (item) {
        const historyModalCounter = new HistoryModalCounter(item.id)

        historyModalCounter.credit = item.modalCounterCredit
        historyModalCounter.debit = item.modalCounterDebit
        historyModalCounter.name = item.modalCounterName
        historyModalCounter.newValue = item.modalCounterNewValue
        historyModalCounter.oldValue = item.modalCounterOldValue
        historyModalCounter.unit = item.modalCounterUnit
        historyModalCounter.validityDate = item.modalCounterValidityDate
        historyModalCounter.version = item.modalCounterVersion

        return historyModalCounter
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
