export default class {
    constructor () {
        this._label = ''
        this._status = null
    }

    get label () {
        return this._label
    }

    set label (label) {
        this._label = label
    }

    get status () {
        return this._status
    }

    set status (status) {
        this._status = status
    }

    toJSON () {
        return {
            label: this.label,
            status: this.status
        }
    }
}
