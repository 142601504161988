export default class {
    constructor (id) {
        this._fareExcludingTaxes = 0
        this._fareIncludingTaxes = 0
        this._id = id
        this._name = ''
        this._operationType = ''
        this._unit = ''
        this._vatAmout = 0
        this._vatRate = 0
        this._userId = ''
        this._version = 0
    }

    get fareExcludingTaxes () {
        return this._fareExcludingTaxes
    }

    set fareExcludingTaxes (fareExcludingTaxes) {
        this._fareExcludingTaxes = fareExcludingTaxes
    }

    get fareIncludingTaxes () {
        return this._fareIncludingTaxes
    }

    set fareIncludingTaxes (fareIncludingTaxes) {
        this._fareIncludingTaxes = fareIncludingTaxes
    }

    get id () {
        return this._modalPlanId
    }

    set id (id) {
        this._id = id
    }

    get name () {
        return this._name
    }

    set name (name) {
        this._name = name
    }

    get operationType () {
        return this._operationType
    }

    set operationType (operationType) {
        this._operationType = operationType
    }

    get unit () {
        return this._unit
    }

    set unit (unit) {
        this._unit = unit
    }

    get userId () {
        return this._userId
    }

    set userId (userId) {
        this._userId = userId
    }

    get vatAmout () {
        return this._vatAmout
    }

    set vatAmout (vatAmout) {
        this._vatAmout = vatAmout
    }

    get vatRate () {
        return this._vatRate
    }

    set vatRate (vatRate) {
        this._vatRate = vatRate
    }

    get version () {
        return this._version
    }

    set version (version) {
        this._version = version
    }

    toJSON () {
        return {
            fareExcludingTaxes: this.fareExcludingTaxes,
            fareIncludingTaxes: this.fareIncludingTaxes,
            id: this.id,
            name: this.name,
            operationType: this.operationType,
            unit: this.unit,
            userId: this.userId,
            vatAmout: this.vatAmout,
            vatRate: this.vatRate,
            version: this.version
        }
    }
}
