<template>
    <a
        :aria-label="getCartToggleLabel"
        :title="getCartToggleLabel"
        :class="[{ 'HeaderCartToggleButton--notEmpty': isNotEmpty() }]"
        class="HeaderCartToggleButton"
        @click="buttonClick"
    >
        <span class="HeaderCartToggleButton-icon IconBag--outlined" />
        <span class="HeaderCartToggleButton-icon IconBag--filled" />
    </a>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import { useStore } from 'vuex'

const emit = defineEmits(['buttonClicked'])

const { state } = useStore()
const { t } = useI18n()

const cart = computed(() => state.cartModule.cart)
const showCart = computed(() => state.cartModule.showCart)
const getCartToggleLabel = computed(() => showCart.value ? t('cart:hide') : t('cart:show'))

function isNotEmpty () {
    return cart.value && typeof cart.value.empty !== 'undefined' && !cart.value.empty
}

function buttonClick () {
    emit('buttonClicked')
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.HeaderCartToggleButton {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    vertical-align: top;
    width: 100%;
    height: 100%;
    cursor: pointer;
    color: $color-lighterText;
    transition: all .3s;

    &:hover,
    &:active,
    &:focus {
        color: $color-lightText;
    }

    &-icon {
        position: relative;
        font-size: 28px;
    }

    .IconBag--filled {
        display: none;
    }

    &--active {
        color: $color-lightText;

        .IconBag--filled {
            display: block;
        }

        .IconBag--outlined {
            display: none;
        }
    }

    &--notEmpty {
        .HeaderCartToggleButton-icon::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: 6px;
            height: 6px;
            border-radius: 6px;
            background: $color-brandSecondary;
        }
    }
}
</style>
