import packagesEn from '@/locales/default/packages.en.json'
import packagesFr from '@/locales/default/packages.fr.json'
import defaultEn from '@/locales/default/messages.en.json'
import defaultFr from '@/locales/default/messages.fr.json'
import defaultEs from '@/locales/default/messages.es.json'
import defaultDe from '@/locales/default/messages.de.json'
import defaultIt from '@/locales/default/messages.it.json'

const requireNetworkCustomLocaleFiles = (filepath) => {
    try {
        return require('@/locales/network/' + filepath)
    } catch (e) {
        return {}
    }
}

const requireWebHarmony = () => {
    try {
        return require('@/locales/web-harmony.json')
    } catch (e) {
        return null
    }
}

const webHarmony = requireWebHarmony()

export default {
    en: Object.assign({}, packagesEn, defaultEn, requireNetworkCustomLocaleFiles('messages.en.json'), webHarmony ? webHarmony.en : {}),
    fr: Object.assign({}, packagesFr, defaultFr, requireNetworkCustomLocaleFiles('messages.fr.json'), webHarmony ? webHarmony.fr : {}),
    es: Object.assign({}, defaultEs, requireNetworkCustomLocaleFiles('messages.es.json'), webHarmony ? webHarmony.es : {}),
    de: Object.assign({}, defaultDe, requireNetworkCustomLocaleFiles('messages.de.json'), webHarmony ? webHarmony.de : {}),
    it: Object.assign({}, defaultIt, requireNetworkCustomLocaleFiles('messages.it.json'), webHarmony ? webHarmony.it : {})
}
