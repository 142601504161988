import config from 'config'

export const customizeAppName = (customTitle = '') => {
    document.getElementById('js-headMetaAppName').content = customTitle || config.app_title
    document.getElementById('js-headTitle').innerHTML = customTitle || config.app_title
}

export const customizeFaviconTags = () => {
    const imgFolderPath = process.env.VUE_APP_CDN_URL + 'img/' + process.env.VUE_APP_NETWORK_ID
    document.getElementById('js-faviconAppleTouch').href = imgFolderPath + '/favicon/apple-touch-icon.png'
    document.getElementById('js-favicon32x32').href = imgFolderPath + '/favicon/favicon-32x32.png'
    document.getElementById('js-favicon16x16').href = imgFolderPath + '/favicon/favicon-16x16.png'
    document.getElementById('js-faviconWebManifest').href = imgFolderPath + '/favicon/site.webmanifest'
    document.getElementById('js-faviconSafari').href = imgFolderPath + '/favicon/safari-pinned-tab.svg'
    document.getElementById('js-faviconIco').href = imgFolderPath + '/favicon/favicon.ico'
    document.getElementById('js-headMetaBrowserConfig').content = imgFolderPath + '/favicon/browserconfig.xml'
}

export const customizeMobileTheme = (color) => {
    document.getElementById('js-headMetaTileColor').content = color
    document.getElementById('js-headMetaThemeColor').content = color
}

export const addTarteAuCitronScript = (domain, uuid) => {
    const tarteAuCitronScript = document.createElement('script')
    tarteAuCitronScript.type = 'text/javascript'
    tarteAuCitronScript.src = `https://tarteaucitron.io/load.js?domain=${domain}&uuid=${uuid}`
    document.head.appendChild(tarteAuCitronScript)
}
