<template>
    <form class="FineForm1">
        <labelled-input
            :defaultValue="getFineReference"
            :inputType="'text'"
            :label="$t('form:fine_form1:reference:label')"
            class="FineForm1-field FineForm1-fineReferenceField"
            @field-value-changed="updateFineReference"
        />
        <date-input-field-with-label
            :defaultValue="getFineToken"
            :inputType="'datetime-local'"
            :label="$t('form:fine_form1:token:label')"
            class="FineForm1-field FineForm1-fineTokenField"
            @field-value-changed="updateFineToken"
        />
    </form>
</template>

<script>
import DateInputFieldWithLabel from '@/StoreWeb/components/common/DateInputFieldWithLabel'
import LabelledInput from '@/StoreWeb/components/common/LabelledInput'
import { isEmpty } from 'global-utils'
import AddToCartFlowManager from '@/StoreWeb/managers/AddToCartFlowManagerSingleton'

export default {
    components: {
        DateInputFieldWithLabel,
        LabelledInput
    },
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            addToCartFlowManager: AddToCartFlowManager.getInstance(),
            fineToken: '',
            fineReference: '',
            id: this.$route.params.id
        }
    },
    computed: {
        getFineToken () {
            return !isEmpty(this.fineToken) ? this.fineToken : ''
        },
        getFineReference () {
            return !isEmpty(this.fineReference) ? this.fineReference : ''
        }
    },
    mounted () {
        if (
            !isEmpty(this.addToCartFlowManager.currentStep) &&
            !isEmpty(this.addToCartFlowManager.currentStep.productParameterValues)
        ) {
            const fineToken = this.addToCartFlowManager.currentStep.productParameterValues.find(productParameter => productParameter.code === 'FINE_TOKEN')
            if (!isEmpty(fineToken) && !isEmpty(fineToken.value)) {
                this.updateFineToken(fineToken.value)
            }
            const fineId = this.addToCartFlowManager.currentStep.productParameterValues.find(productParameter => productParameter.code === 'FINE_ID')
            if (!isEmpty(fineId) && !isEmpty(fineId.value)) {
                this.updateFineReference(fineId.value)
            }
        } else if (this.$store.state.debugAutofillCardOrderForms) {
            this.fineReference = '14000438'
            this.fineToken = '1983-01-02'
            this.updateProductParameter()
        }
    },
    methods: {
        checkFormError () {
            return (
                this.fineReference !== '' &&
                this.fineToken !== ''
            )
        },
        updateFineToken (value) {
            this.fineToken = value
            this.updateProductParameter()
        },
        updateFineReference (value) {
            this.fineReference = value
            this.updateProductParameter()
        },
        updateProductParameter () {
            let fineToken = this.fineToken
            if (!/(.*)T([0-9]{2,2}):([0-9]{2,2}):/.test(fineToken)) {
                fineToken += ':00'
            }

            this.$emit('updateFineForm1', {
                id: 'FINEFORM1',
                isProductParameterValid: this.checkFormError(),
                productParameterValues: [
                    {
                        productParameter: 'FINEFORM1',
                        code: 'FINE_ID',
                        value: this.getFineReference
                    },
                    {
                        productParameter: 'FINEFORM1',
                        code: 'FINE_TOKEN',
                        value: fineToken
                    }
                ],
                isQuotationRequired: false
            })
        }
    }
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

.FineForm1 {
    &-field {
        margin-bottom: 15px;
    }
}
</style>
