<template>
    <div class="NbTravellersForm">
        <div class="NbTravellersForm-form">
            <strong
                v-if="productParameter.label !== ''"
                class="NbTravellersForm-label"
            >
                {{ productParameter.label }}
            </strong>
            <quantity-selector
                v-if="productParameter.minValue !== productParameter.maxValue"
                :max="productParameter.maxValue"
                :min="productParameter.minValue"
                class="NbTravellersForm-quantity"
                @update-quantity="updateNbTravellers"
            />
        </div>
    </div>
</template>

<script>
import AddToCartFlowManager from '@/StoreWeb/managers/AddToCartFlowManagerSingleton'
import CatalogManager from '@/StoreWeb/managers/CatalogManagerSingleton'
import QuantitySelector from '@/StoreWeb/components/common/QuantitySelector'
import { isEmpty } from 'global-utils'

export default {
    components: {
        QuantitySelector
    },
    props: {
        product: {
            type: Object,
            required: true
        },
        productParameter: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            addToCartFlowManager: AddToCartFlowManager.getInstance(),
            catalogManager: CatalogManager.getInstance(),
            nbTravellers: this.productParameter.minValue
        }
    },
    mounted () {
        if (
            !isEmpty(this.addToCartFlowManager.currentStep) &&
            !isEmpty(this.addToCartFlowManager.currentStep.productParameterValues)
        ) {
            const nbTravellers = this.addToCartFlowManager.currentStep.productParameterValues.find(productParameter => productParameter.productParameter === 'NB_TRAVELLERS')
            if (!isEmpty(nbTravellers) && !isEmpty(nbTravellers.value)) {
                this.updateNbTravellers(nbTravellers.value)
            }
        } else {
            this.updateNbTravellers(this.nbTravellers)
        }
    },
    methods: {
        checkFormError () {
            return this.nbTravellers >= this.productParameter.minValue && (this.nbTravellers <= this.productParameter.maxValue || this.productParameter.maxValue === 0)
        },
        updateNbTravellers (quantity) {
            this.nbTravellers = quantity
            this.updateProductParameter()
        },
        updateProductParameter () {
            this.$emit('updateNbTravellersForm', {
                id: this.productParameter.id,
                isProductParameterValid: this.checkFormError(),
                productParameterValues: [
                    {
                        productParameter: this.productParameter.id,
                        code: 'VALUE',
                        value: `${this.nbTravellers}`
                    }
                ],
                isQuotationRequired: true
            })
        }
    }
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

.NbTravellersForm {
    &-form {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;

        @media (min-width: $tablet-breakpoint) {
            justify-content: flex-start;
        }
    }
}
</style>
