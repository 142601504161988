<template>
    <div
        :role="inputRole"
        class="LabelledInput"
    >
        <input
            :id="'js-input-' + inputId"
            v-model="currentValue"
            :aria-label="label"
            :aria-required="isRequired"
            :autocomplete="autocomplete"
            :class="getInputClass"
            :maxlength="getMaxLength"
            :minlength="getMinLength"
            :pattern="getPattern !== '' ? getPattern : ''"
            :readonly="readonly"
            :required="isRequired"
            :style="{ borderColor: getBorderColor, 'box-shadow': getBoxShadow }"
            :type="inputType"
            class="LabelledInput-input"
            tabindex="0"
            @input="fieldValueChanged"
            @keydown="checkPattern"
            @focus="focused=true"
            @blur="focused=false"
        >
        <button
            :aria-label="$t('form:input:clear_field')"
            :tabindex="getTabIndexIcon"
            class="LabelledInput-button Accessibility-reachableByTab"
            type="button"
            @click="clearInputField"
        >
            <span
                :class="{ 'LabelledInput-icon--hoverable': getCurrentValueNotEmpty }"
                tabindex="-1"
                class="LabelledInput-icon material-icons Accessibility-noReachableByClick"
            >
                {{ getIconValue }}
            </span>
        </button>
        <label
            :for="'js-error-' + inputId"
            class="LabelledInput-label"
        >
            {{ label }}
        </label>
        <div
            v-if="description !== ''"
            class="BaseInputField-description"
        >
            {{ description }}
        </div>
        <div
            :id="'js-error-' + inputId"
            :aria-label="errorLabel"
            :tabindex="getErrorTabIndex"
            :class="{ 'LabelledInput-error--displayed': hasError }"
            class="LabelledInput-error"
        >
            {{ errorLabel }}
        </div>
        <div
            :id="'js-success-' + inputId"
            :aria-label="successLabel"
            :tabindex="getSuccessTabIndex"
            :class="{ 'LabelledInput-success--displayed': isValid }"
            class="LabelledInput-success"
        >
            {{ successLabel }}
        </div>
    </div>
</template>

<script>
import BaseInputField from './BaseInputField'

export default {
    name: 'LabelledInput',
    extends: BaseInputField
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    appearance: none;
}

/* stylelint-disable */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
/* stylelint-enable */

.LabelledInput {
    position: relative;
    font-family: $fontLato;

    &-button {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: calc($input-medium-minHeight / 4);
        right: calc(#{$font-large} / 2);
        width: calc($input-medium-minHeight / 2);
        height: calc($input-medium-minHeight / 2);
        padding: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;
    }

    &-label {
        display: block;
        position: absolute;
        top: $margin-small;
        left: $input-medium-horizontalPadding + $input-borderWidth;
        font-size: 14px;
        line-height: calc(#{$padding-medium + $font-small} / 2);
        color: $color-black-light;
        pointer-events: none;
        opacity: .7;
        transition: top .3s ease-in-out, font-size .3s ease-in-out, line-height .3s ease-in-out;
    }

    &-input {
        @include outline0;

        width: 100%;
        min-height: $input-medium-minHeight;
        padding: 20px $padding-small 10px $padding-small;
        border-width: 1px;
        border-style: solid;
        border-radius: $borderRadius-medium;
        background: $color_white;
        font-size: 14px;
        line-height: 14px;
        color: $color-black-light;
        transition: border-color .3s ease-in-out, box-shadow .3s ease-in-out;

        &--inactive {
            border-color: $color-grey-light;
        }

        &:read-only {
            border-color: $color-grey-light !important;
            box-shadow: none !important;
            background: $color-lightgray1;
            cursor: not-allowed;

            & + .LabelledInput-button {
                display: none;
            }
        }

        &--notEmpty,
        &:focus {
            & ~ .LabelledInput-label {
                top: 6px;
                font-size: 12px;
                line-height: 12px;
            }
        }
    }

    &-icon.material-icons {
        font-size: $font-large;
        color: $color-grey-dark;
        transition: color .3s ease-in-out;
    }

    &-icon--hoverable:hover {
        cursor: pointer;
        color: $color-black;
    }

    &-error,
    &-success {
        margin-bottom: 0;
        opacity: 0;
        transition: all .3s ease-in-out;

        &--displayed {
            margin-top: 10px;
            padding: 10px 15px;
            border: 1px solid;
            border-radius: $border-radius-small;
            opacity: 1;
        }
    }

    &-error--displayed {
        border-color: $error-border-color;
        background: $error-border-background;
        color: $error-color;
    }

    &-success--displayed {
        border-color: $success-border-color;
        background: $success-border-background;
        color: $success-color;
    }
}
</style>
