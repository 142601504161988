<template>
    <div
        v-if="getProviderAccounts.length > 0"
        class="SaleOptionProviderDeviceChoice"
    >
        <ul
            v-for="providerAccount in getProviderAccounts"
            :key="providerAccount.id"
            class="SaleOptionProviderDeviceChoice-list"
        >
            <sale-option-provider-device-choice-item
                v-for="device in providerAccount.account.devices"
                :key="device.id"
                :isActive="isActive(device)"
                :device="device"
                class="SaleOptionProviderDeviceChoice-item"
                @device-clicked="deviceClicked"
            />
        </ul>
    </div>
    <sale-option-no-provider-account-available
        v-else-if="showNoProviderAccountMessage"
        :provider="provider"
        @provider-accounts-available="providerAccountsAvailable"
    />
    <alert-message
        v-else
        :message="$t('sale_option:basket_info:provider_account:no_available_item')"
        type="error"
        class="SaleOptionProviderAccountAndFareMediaChoice-noAvailableTargetError"
    />
</template>

<script>
import { mapGetters } from 'vuex'
import { normalizeProviderDevice } from '@/StoreWeb/js/mixins/support-utils'
import AlertMessage from '@/StoreWeb/components/common/AlertMessage'
import SaleOptionProviderDeviceChoiceItem from '@/StoreWeb/components/product/SaleOptionProviderDeviceChoiceItem.vue'
import SaleOptionNoProviderAccountAvailable from '@/StoreWeb/components/product/SaleOptionNoProviderAccountAvailable'
import config from 'config'

export default {
    components: {
        AlertMessage,
        SaleOptionProviderDeviceChoiceItem,
        SaleOptionNoProviderAccountAvailable
    },
    props: {
        provider: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            selectedDevice: null
        }
    },
    computed: {
        ...mapGetters(['getWallet', 'getWalletProviders']),
        getProviderAccounts () {
            return this.getWallet.getAllSupports({
                correspondingBasketInfos: 'PROVIDERACCOUNT',
                provider: this.provider
            })
        },
        showNoProviderAccountMessage () {
            const provider = this.getWalletProviders.find(provider => provider.id === this.provider)
            return config.purchase_flow.type === 'default' && provider.accounts.length <= 0
        }
    },
    methods: {
        deviceClicked (device) {
            this.selectedDevice = device
            this.$emit('updateDevice', {
                basketInfo: 'PROVIDERACCOUNTANDDEVICE',
                value: normalizeProviderDevice(device, this.provider)
            })
        },
        isActive (device) {
            return this.selectedDevice === device
        },
        providerAccountsAvailable (data) {
            this.$emit('providerAccountsAvailable', data)
        }
    }
}
</script>
<style lang="scss" scoped>
@import 'globalScss';

.SaleOptionProviderDeviceChoice {
    width: 100%;

    &-list {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &-item {
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>
