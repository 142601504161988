import HistoryPlan from '@/StoreWeb/models/user/order/HistoryPlan'
import { isEmpty } from 'global-utils'

export default class {
    normalize (item) {
        const historyPlan = new HistoryPlan(item.modalPlanId)

        historyPlan.fareExcludingTaxes = item.fareExcludingTaxes
        historyPlan.fareIncludingTaxes = item.fareIncludingTaxes
        historyPlan.name = item.name
        historyPlan.operationType = item.operationType
        historyPlan.unit = item.unit
        historyPlan.userId = item.userId
        historyPlan.vatAmout = item.vatAmout
        historyPlan.vatRate = item.vatRate
        historyPlan.version = item.version

        return historyPlan
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
