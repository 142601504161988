<template>
    <div class="ProductParameterSelector">
        <div v-if="productParameter">
            <h2
                v-if="title !== ''"
                class="ProductParameterSelector-title"
            >
                {{ title }}
            </h2>
            <address-form
                v-if="productParameter?.id === 'ADDRESS'"
                class="ProductParameterSelector-addressForm"
                @update-address-form="updateProductParameter"
            />
            <adjacent-zones-form
                v-if="productParameter?.id === 'ZONE_ADJACENT'"
                :product="product"
                :productParameter="productParameter"
                class="ProductParameterSelector-adjacentZonesForm"
                @update-adjacent-zones-form="updateProductParameter"
            />
            <personal-card-form
                v-if="productParameter?.id === 'PERSONALCARDFORM'"
                :product="product"
                class="ProductParameterSelector-personalCardForm"
                @update-personal-card-form="updateProductParameter"
            />
            <nb-travellers-form
                v-if="productParameter?.id === 'NB_TRAVELLERS'"
                :product="product"
                :productParameter="productParameter"
                class="ProductParameterSelector-nbTravellersForm"
                @update-nb-travellers-form="updateProductParameter"
            />
            <lines-form
                v-if="productParameter?.id === 'LINES'"
                :product="product"
                :productParameter="productParameter"
                class="ProductParameterSelector-linesForm"
                @update-lines-form="updateProductParameter"
            />
            <origin-destination-form
                v-if="productParameter?.id === 'ORIGIN_DESTINATION'"
                :product="product"
                :productParameter="productParameter"
                class="ProductParameterSelector-originDestinationForm"
                @update-origin-destination-form="updateProductParameter"
            />
            <origin-destination-zone-route-form
                v-if="productParameter?.id === 'ODZONE_ROUTE'"
                :product="product"
                :productParameter="productParameter"
                class="ProductParameterSelector-originDestinationZoneRouteForm"
                @update-origin-destination-zone-route-form="updateProductParameter"
            />
            <transport-class-form
                v-if="productParameter?.id === 'TRANSPORT_CLASS'"
                :product="product"
                :productParameter="productParameter"
                class="ProductParameterSelector-transportClassForm"
                @update-transport-class-form="updateProductParameter"
            />
            <validity-dates-form
                v-if="productParameter?.id === 'START_VALIDITY_DATE' || productParameter?.id === 'END_VALIDITY_DATE'"
                :product="product"
                :productParameter="productParameter"
                class="ProductParameterSelector-validityDatesForm"
                @update-validity-dates-form="updateProductParameter"
            />
            <fine-form-1
                v-if="productParameter?.id === 'FINEFORM1'"
                :product="product"
                class="ProductParameterSelector-fineForm1"
                @update-fine-form1="updateProductParameter"
            />
            <card-id-form
                v-if="productParameter?.id === 'CARDID'"
                :product="product"
                class="ProductParameterSelector-cardIdForm"
                @update-card-id-form="updateProductParameter"
            />
            <div class="ProductParameterSelector-offerChoiceWrapper mb-3">
                <offer-choice
                    v-if="showOffersList"
                    :offers="getOffers"
                    class="ValidityDatesForm-offerChoice"
                    @update-offer="updateOffer"
                />
            </div>
        </div>
    </div>
</template>

<script>
import AddressForm from '@/StoreWeb/components/form/AddressForm'
import AdjacentZonesForm from '@/StoreWeb/components/form/AdjacentZonesForm'
import PersonalCardForm from '@/StoreWeb/components/form/PersonalCardForm'
import FineForm1 from '@/StoreWeb/components/form/FineForm1'
import LinesForm from '@/StoreWeb/components/form/LinesForm'
import NbTravellersForm from '@/StoreWeb/components/form/NbTravellersForm'
import OriginDestinationForm from '@/StoreWeb/components/form/OriginDestinationForm'
import OriginDestinationZoneRouteForm from '@/StoreWeb/components/form/OriginDestinationZoneRouteForm'
import TransportClassForm from '@/StoreWeb/components/form/TransportClassForm'
import CardIdForm from '../form/CardIdForm'
import ValidityDatesForm from '../form/ValidityDatesForm'
import * as catalogActionTypes from '@/StoreWeb/store/modules/catalog/action-types'
import AddToCartFlowManager from '@/StoreWeb/managers/AddToCartFlowManagerSingleton'
import OfferChoice from '@/StoreWeb/components/product/OfferChoice'
import { isEmpty } from 'global-utils'
import emitter from 'global-emitter'

export default {
    components: {
        OfferChoice,
        AddressForm,
        AdjacentZonesForm,
        ValidityDatesForm,
        CardIdForm,
        FineForm1,
        LinesForm,
        NbTravellersForm,
        OriginDestinationForm,
        OriginDestinationZoneRouteForm,
        PersonalCardForm,
        TransportClassForm
    },
    props: {
        hasError: {
            type: Boolean,
            default: false
        },
        product: {
            type: Object,
            required: true
        },
        productParameter: {
            type: Object,
            required: true,
            validator: (val) => {
                return ['ADDRESS', 'CARDID', 'FINEFORM1', 'LINES', 'NAMEDCARDFORM', 'NB_TRAVELLERS', 'ODZONE_ROUTE', 'ORIGIN_DESTINATION', 'PERSONALCARDFORM', 'TRANSPORT_CLASS', 'START_VALIDITY_DATE', 'END_VALIDITY_DATE', 'ZONE', 'ZONE_ADJACENT'].includes(val.id)
            }
        },
        title: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            addToCartFlowManager: AddToCartFlowManager.getInstance(),
            target: '',
            allParameters: {},
            showOffers: false,
            quotations: null,
            selectedOffer: null,
            currentProductParameterUpdated: null
        }
    },
    computed: {
        getOffers () {
            const offers = []
            if (!isEmpty(this.quotations)) {
                this.quotations.forEach(quotation => {
                    if (!isEmpty(quotation.offers)) {
                        quotation.offers.forEach(offer => {
                            offers.push({ item: quotation.item, ...offer })
                        })
                    }
                })
            }
            offers.sort((a, b) => {
                return a.orderForDisplay - b.orderForDisplay
            })
            return offers
        },
        showOffersList () {
            return this.productParameter?.id === this.currentProductParameterUpdated?.id && this.showOffers
        }
    },
    mounted () {
        emitter.on('quotationsLoaded', this.quotationLoadedHandler)
        emitter.on('quotationsError', this.quotationErrorHandler)
    },
    unmounted () {
        emitter.off('quotationsLoaded', this.quotationLoadedHandler)
        emitter.off('quotationsError', this.quotationErrorHandler)
    },
    methods: {
        quotationLoadedHandler (data) {
            if (data.quotations) { this.quotations = data.quotations }
            if (isEmpty(this.quotations)) {
                this.currentProductParameterUpdated.isProductParameterValid = false
            }
            this.$emit('updateProductParameter', this.currentProductParameterUpdated)
        },
        quotationErrorHandler () {
            this.quotations = null
            this.currentProductParameterUpdated.isProductParameterValid = false
            this.$emit('updateProductParameter', this.currentProductParameterUpdated)
        },
        updateProductParameter (productParameter) {
            this.currentProductParameterUpdated = productParameter
            this.allParameters[productParameter?.id] = []
            productParameter.productParameterValues.forEach(param => {
                this.allParameters[productParameter?.id].push(param)
            })
            if (this.addToCartFlowManager.currentStep.isLastProductParameter && productParameter.isQuotationRequired) {
                this.getQuotations()
            } else {
                this.showOffers = false
                this.$emit('updateProductParameter', productParameter)
            }
        },
        async getQuotations () {
            if (this.allParameters) {
                const productParameters = Object.values(this.allParameters).flat()
                const saleOptionSteps = this.addToCartFlowManager.findInPreviousSteps({
                    type: 'saleOption'
                })
                const params = {
                    product: this.product,
                    productParameters,
                    saleOptionStep: saleOptionSteps && saleOptionSteps.length > 0 ? saleOptionSteps[0] : null
                }

                await this.$store.dispatch(catalogActionTypes.GET_QUOTATIONS, params)
                    .then((response) => {
                        this.showOffers = true
                        this.quotationLoadedHandler(response)
                    })
                    .catch((error) => this.quotationErrorHandler(error))
            }
        },
        updateOffer (offer) {
            this.selectedOffer = offer
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.ProductParameterSelector {
    &-title {
        margin: 0 0 10px;
        font-size: 18px;
    }

    &-label {
        margin: 0;
        font-size: 14px;
    }
}
</style>
