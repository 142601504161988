import CatalogSection from '@/StoreWeb/models/catalog/CatalogSection'
import CatalogProductNormalizer from '@/StoreWeb/normalizers/catalog/CatalogProductNormalizerSingleton'
import { isEmpty } from 'global-utils'

export default class {
    constructor () {
        this._catalogProductNormalizer = CatalogProductNormalizer.getInstance()
    }

    get catalogProductNormalizer () {
        return this._catalogProductNormalizer
    }

    normalize (item) {
        const section = new CatalogSection(item.id)

        if (!isEmpty(item.name)) {
            section.name = item.name
        }
        if (!isEmpty(item.products)) {
            section.products = this.catalogProductNormalizer.normalizeArray(item.products)
        }
        if (!isEmpty(item.size)) {
            section.size = item.size
        }
        if (!isEmpty(item.style)) {
            section.sectionStyle = item.style
        }
        if (!isEmpty(item.sections)) {
            section.sections = this.normalizeArray(item.sections)
        }

        return section
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
