import actions from './actions'
import mutations from './mutations'

export default {
    namespaced: false,
    actions,
    mutations,
    state: {
        cardReaderPendingRequests: {
            getOperation: false,
            getOperationReport: false
        }
    }
}
