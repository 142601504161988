import FareProfile from '@/StoreWeb/models/user/wallet/FareProfile'
import { isEmpty } from 'global-utils'

export default class {
    normalize (item) {
        const fareProfile = new FareProfile(item.id)
        fareProfile.label = item.label
        if (!isEmpty(item.startValidityDate)) {
            fareProfile.startValidityDate = item.startValidityDate
        }
        if (!isEmpty(item.endValidityDate)) {
            fareProfile.endValidityDate = item.endValidityDate
        }
        if (!isEmpty(item.status)) {
            fareProfile.status = item.status
        }

        return fareProfile
    }

    normalizeArray (items, params) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item, params))
            })
        }

        return normalizedItems
    }
}
