<template>
    <component
        :is="'li'"
        class="AccountAssociationRequestItem"
    >
        <v-menu
            v-if="actions.length > 0"
            v-model="showActionsMenu"
            class="AccountAssociationRequestItem-actionsMenu"
        >
            <template #activator="{ props: activatorProps }">
                <v-btn
                    class="AccountAssociationRequestItem-showActionsButton opacity-100"
                    icon="mdi-dots-vertical"
                    variant="plain"
                    v-bind="activatorProps"
                />
            </template>
            <v-list
                class="AccountAssociationRequestItem-actionList pa-1"
                density="compact"
                color="success"
            >
                <template
                    v-for="(action, index) in actions"
                    :key="index"
                >
                    <v-list-item
                        v-if="action.href"
                        :href="sanitizeUrl(action.href)"
                        :value="index"
                        base-color="primary"
                        class="AccountAssociationRequestItem-actionItem pa-2"
                    >
                        <v-list-item-title class="AccountAssociationRequestItem-actionTitle text-caption font-weight-bold">
                            {{ action.label }}
                            <v-icon
                                v-if="action.externalUrl"
                                :aria-label="$t('open_new_tab')"
                                :aria-hidden="false"
                                class="pl-1"
                                color="primary"
                                size="medium"
                            >
                                mdi-open-in-new
                            </v-icon>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        v-else-if="typeof action.onClick === 'function'"
                        :value="index"
                        base-color="primary"
                        class="AccountAssociationRequestItem-actionItem pa-2"
                        @click.prevent="action.onClick"
                    >
                        <v-list-item-title class="AccountAssociationRequestItem-actionTitle text-caption font-weight-bold">
                            {{ action.label }}
                        </v-list-item-title>
                    </v-list-item>
                </template>
            </v-list>
        </v-menu>
        <div class="AccountAssociationRequestItem-header">
            <div class="AccountAssociationRequestItem-type">
                <strong class="d-block">
                    {{ t('account_association:type') }}
                </strong>
                {{ accountAssociationRequest.getTypeString() }}
            </div>
            <div class="AccountAssociationRequestItem-label">
                <strong class="d-block">
                    {{ t('account_association:account_label_title') }}
                </strong>
                {{ accountAssociationRequest.getTargetAccountLabel() }}
            </div>
        </div>
        <div class="AccountAssociationRequestItem-debugInformations d-flex justify-start align-start gap-5">
            <div
                v-if="debugInformationsDisplay > 1 && accountAssociationRequest.id"
                class="AccountAssociationRequestItem-id"
            >
                <strong class="d-block">
                    Id :
                </strong>
                #{{ accountAssociationRequest.id }}
            </div>
            <div
                v-if="debugInformationsDisplay > 1"
                class="AccountAssociationRequestItem-type"
            >
                <strong class="d-block">
                    Type :
                </strong>
                {{ accountAssociationRequest.getTypeString() }}
            </div>
            <div
                v-if="debugInformationsDisplay > 1"
                class="AccountAssociationRequestItem-status"
            >
                <strong class="d-block">
                    Etat :
                </strong>
                {{ accountAssociationRequest.status.label }}
            </div>
            <div
                v-if="debugInformationsDisplay > 1 && accountAssociationRequest.key !== ''"
                class="AccountAssociationRequestItem-status"
            >
                <strong class="d-block">
                    Key :
                </strong>
                {{ accountAssociationRequest.key }}
            </div>
            <div
                v-if="debugInformationsDisplay > 1 && accountAssociationRequest.isAssociatedTo !== null"
                class="AccountAssociationRequestItem-isAssociatedTo"
            >
                <strong class="d-block">
                    isAssociatedTo :
                </strong>
                {{ accountAssociationRequest.isAssociatedTo.firstName }} {{ accountAssociationRequest.isAssociatedTo.lastName }}<br>
                {{ accountAssociationRequest.isAssociatedTo.email }}
            </div>
        </div>
        <modal
            v-model="showModal"
            :subtitle="getDeleteModalText"
            :title="getDeleteModalTitle"
            width="small"
        >
            <template #actions>
                <v-btn
                    class="bg-primary ml-2"
                    @click="deleteAccountAssociation"
                >
                    {{ getDeleteLabel }}
                </v-btn>
            </template>
        </modal>
    </component>
</template>

<script setup>
import { sanitizeUrl } from '@braintree/sanitize-url'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { isEmpty } from 'global-utils'
import { formatAction } from '@/StoreWeb/js/composables/user-action-utils'
import * as actionTypes from '@/StoreWeb/store/modules/account-association/action-types'
import * as accountAssociationMutationTypes from '@/StoreWeb/store/modules/account-association/mutation-types'
import * as mainMutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import * as userActionTypes from '@/StoreWeb/store/modules/user/action-types'
import AccountAssociationNormalizer from '@/StoreWeb/normalizers/account-association/AccountAssociationNormalizerSingleton'

const props = defineProps({
    accountAssociationRequest: {
        type: Object,
        required: true
    }
})

const store = useStore()
const { t } = useI18n()
const accountAssociationNormalizer = AccountAssociationNormalizer.getInstance()

const selectedProvider = ref('conduent')
const showActionsMenu = ref(false)
const showModal = ref(false)

const actions = computed(() => {
    if (!isEmpty(props.accountAssociationRequest.actions)) {
        const formattedActions = props.accountAssociationRequest.actions.map(action => formatAction(action, setActionClick))

        return formattedActions
    }

    return []
})

const debugInformationsDisplay = computed(() => store.state.debuggingModule.debugInformationsDisplay)

const getDeleteModalText = computed(() => {
    if (props.accountAssociationRequest.status.id === 'ACTIVE') {
        return t('account_association:revoke:confirm_modal_text')
    } else {
        return t('account_association:cancel_request:confirm_modal_text')
    }
})

const getDeleteModalTitle = computed(() => {
    if (props.accountAssociationRequest.status.id === 'ACTIVE') {
        return t('account_association:revoke:confirm_modal_title')
    } else {
        return t('account_association:cancel_request:confirm_modal_title')
    }
})

const getDeleteLabel = computed(() => {
    if (props.accountAssociationRequest.status.id === 'ACTIVE') {
        return t('account_association:revoke:button_text')
    } else {
        return t('account_association:cancel_request:button_text')
    }
})

const walletAccount = computed(() => walletAccounts.value.find(account => account.provider === selectedProvider.value))
const walletAccounts = computed(() => store.getters.getWalletProviderAccounts)

function closeModal () {
    showModal.value = false
}

function setActionClick (formattedAction, action) {
    switch (action.type) {
        case 'ACCOUNT_LINK_DELETE':
            formattedAction.onClick = () => setShowModal()
            break
        default:
            if (action?.parameters?.URI) {
                formattedAction.href = action.parameters.URI

                if (!isEmpty(action.external)) {
                    formattedAction.externalUrl = action.external
                }
            }
            break
    }
}

function setShowModal () {
    showModal.value = true
}

async function deleteAccountAssociation () {
    const updatedAccountAssociationList = await store.dispatch(actionTypes.DELETE_ACCOUNT_ASSOCIATION, {
        associationId: props.accountAssociationRequest.id,
        providerId: selectedProvider.value,
        providerUserId: walletAccount.value.id,
        providerUserExternalId: walletAccount.value.providerUserExternalId,
        cachiosOptions: {
            force: true
        }
    })

    store.commit(mainMutationTypes.SET_FEEDBACK_SUCCESS, t('account_association:revoke:success'))

    if (updatedAccountAssociationList) {
        const normalizedAccountAssociations = accountAssociationNormalizer.normalizeArray(updatedAccountAssociationList.associations)

        store.commit(accountAssociationMutationTypes.SET_USER_ACCOUNT_ASSOCIATIONS, normalizedAccountAssociations)

        store.dispatch(userActionTypes.GET_WALLET, {
            wsOptions: {
                cacheAsync: false,
                cacheValidity: 0
            },
            cachiosOptions: {
                force: true
            }
        })
    }

    closeModal()
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.AccountAssociationRequestItem {
    position: relative;
    margin-bottom: $s4;
    padding: $s3;
    border-radius: 6px;
    box-shadow: $box-shadow-light;
    background: $color-lightest;
    font-size: $font-xsmall;
    color: $color-lightText;

    &-header {
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 10px;
        padding-right: $s16;
    }

    &-pendingAssociationActions {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        margin-top: 20px;
    }

    &-message {
        margin-top: 20px;
        padding-right: 20px;
        color: $color-lightText;

        &:first-child {
            margin-top: 0;
        }
    }

    &-showActionsButton {
        position: absolute;
        top: $s2;
        right: $s2;

        &:hover,
        &:active {
            color: $primary_color;
        }
    }

    &-actionList {
        max-width: 80vw;
    }

    &-actionItem {
        min-height: 0 !important;
    }

    &-actionTitle {
        overflow: hidden;
        cursor: pointer;
        text-overflow: unset;
        white-space: unset;
        hyphens: none;
    }

    &:last-child {
        margin-bottom: 0;
    }
}
</style>
