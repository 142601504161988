export default class {
    constructor (id) {
        this._id = id
        this._name = ''
        this._version = ''
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get name () {
        return this._name
    }

    set name (name) {
        this._name = name
    }

    get version () {
        return this._version
    }

    set version (version) {
        this._version = version
    }

    toJSON () {
        return {
            id: this.id,
            name: this.name,
            version: this.version
        }
    }
}
