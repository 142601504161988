<template>
    <div
        v-if="hasFareProfile"
        class="FareProfile"
    >
        <h3 class="FareProfile-title">
            {{ $t('customer_status_title') }}
        </h3>
        <v-btn
            :label="$t('add:profile')"
            class="FareProfile-addProfileButton ma-2"
            color="primary"
            variant="text"
            @click="setAddNewProfileModal(provider)"
        >
            {{ $t('add:profile') }}
        </v-btn>
        <ul
            v-for="account in provider.accounts"
            :key="account.id"
            class="FareProfile-list pb-4"
        >
            <li
                v-for="(fareProfile, index) in getAccountProfilesSorted(account.fareProfiles)"
                :key="'fareProfile_' + index"
                class="FareProfile-listItem"
            >
                <span class="FareProfile-listItemLabel">
                    {{ fareProfile.label }}
                    <span
                        v-if="fareProfile.status.id"
                        class="FareProfile-status"
                    >
                        {{ fareProfile.status.label }}
                    </span>
                </span>
                <span
                    v-if="!endValidityDateIsEmpty(fareProfile.endValidityDate)"
                    class="FareProfile-listItemDate"
                >
                    {{ $t('expired_on') }}
                    <strong>{{ getValidityEndDate(fareProfile.endValidityDate) }}</strong>
                </span>
            </li>
            <add-new-profile-modal
                v-if="fareProfiles.length > 0"
                v-model="showModal"
                :account="account"
                :providerAccount="providerAccount"
                :fareProfiles="fareProfiles"
                :getProfileErrorMessage="getProfileErrorMessage"
            />
        </ul>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import AddNewProfileModal from '@/StoreWeb/components/account/AddNewProfileModal.vue'
import moment from 'moment'
import { useI18n } from 'vue-i18n'
import * as userActionTypes from '@/StoreWeb/store/modules/user/action-types'
import { useRoute } from 'vue-router'
import { isEmpty } from 'global-utils'

const props = defineProps({
    provider: {
        type: Object,
        required: true
    },
    providerAccount: {
        type: Object,
        default: null
    }
})

const store = useStore()
const route = useRoute()
const { t } = useI18n()

const fareProfiles = ref([])
const getProfileErrorMessage = ref(null)
const showModal = ref(false)

const getRecipientUser = computed(() => {
    if (props.providerAccount) {
        return {
            associationId: props.providerAccount.id,
            firstName: props.providerAccount.firstName,
            lastName: props.providerAccount.lastName,
            userId: props.providerAccount.userId,
            type: props.providerAccount?.type?.id
        }
    }
    return null
})

const hasFareProfile = computed(() => props.provider.accounts.some(account => account.fareProfiles))
const getWalletProviderAccounts = computed(() => store.getters.getWalletProviderAccounts)
const getWalletAssociatedAccounts = computed(() => store.getters.getWalletAssociatedAccounts)

onMounted(() => {
    const account = props.provider.accounts.filter(item => item.id === route.params.accountId)
    if (route.params.accountId && account.length > 0) {
        setAddNewProfileModal(props.provider)
    }
})

function getAccountProfilesSorted (profiles) {
    const activeProfiles = profiles.filter(profile => !profile.endValidityDate || moment(profile.endValidityDate).unix() > moment().unix())
    const inactiveProfiles = profiles.filter(profile => moment(profile.endValidityDate).unix() < moment().unix())
    return activeProfiles.concat(inactiveProfiles)
}

function getValidityEndDate (date) {
    return moment(date).format(t('date_format:default'))
}

function endValidityDateIsEmpty (date) {
    return date === ''
}

function setAddNewProfileModal (provider) {
    const params = {
        providerId: provider.id,
        providerUserExternalId: props.providerAccount?.providerUserExternalId,
        providerUserId: props.providerAccount.id
    }

    const associatedProvider = getWalletAssociatedAccounts.value.find(obj => obj.id === props.providerAccount.id)
    const fatherAccount = getWalletProviderAccounts.value[0]

    // Manage associated provider
    if (!isEmpty(associatedProvider)) {
        params.recipientUser = getRecipientUser.value
        params.providerUserId = fatherAccount.id
        params.providerUserExternalId = fatherAccount.providerUserExternalId
    }

    store.dispatch(userActionTypes.GET_NEW_PROFILE_ACCOUNT, params)
        .then(data => {
            fareProfiles.value = data.fareProfiles.map(({ label, ...rest }) => ({ name: label, ...rest }))
            showModal.value = true
        })
        .catch(() => {
            getProfileErrorMessage.value = t('profiles:get:error')
        })
}

</script>

<style lang="scss" scoped>
@import 'globalScss';

.FareProfile {
    &-title {
        display: inline-block;
        vertical-align: middle;
        font-family: $fontFamily-title;
        font-size: $font-xmedium;
        font-weight: $fontWeight-titleRegular;
        color: $color-defaultText;
    }

    &-list {
        margin: 0;
        padding: 0;
    }

    &-listItem {
        display: flex;
        justify-content: space-between;
        margin-bottom: $margin-xsmall;
        padding: $padding-small $padding-medium;
        border-radius: $borderRadius-medium;
        box-shadow: $boxShadow-ultraLight;
        background-color: $color-white;
        list-style: none;
        font-size: $font-xsmall;
        color: $color-lightText;

        @media (max-width: $phoneMedium-breakpoint) {
            flex-direction: column;
        }

        &Label {
            margin-right: 5px;
        }
    }

    &-status {
        font-style: italic;
        font-size: 12px;
        font-weight: lighter;
        color: $color-brandPrimary;
    }

    &-listItemDate {
        font-size: $font-xxsmall;
        color: $color-lighterText;

        @media (max-width: $phoneMedium-breakpoint) {
            padding-top: $padding-xxsmall;
        }
    }

    &-addProfileButton {
        font-weight: bold;
    }
}
</style>
