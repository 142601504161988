import { isEmpty } from 'global-utils'

export const normalizeProviderAccountAndFareMedia = (fareMedia, provider) => {
    let fareMediaFirstName = ''
    let fareMediaLastName = ''
    let providerUserFirstName = ''
    let providerUserLastName = ''
    if (!isEmpty(fareMedia)) {
        if (!isEmpty(fareMedia.firstName)) {
            fareMediaFirstName = fareMedia.firstName
        } else if (!isEmpty(fareMedia.recipientUser) && !isEmpty(fareMedia.recipientUser.firstName)) {
            fareMediaFirstName = fareMedia.recipientUser.firstName
            providerUserFirstName = fareMediaFirstName
        }
        if (!isEmpty(fareMedia.lastName)) {
            fareMediaLastName = fareMedia.lastName
        } else if (!isEmpty(fareMedia.recipientUser) && !isEmpty(fareMedia.recipientUser.lastName)) {
            fareMediaLastName = fareMedia.recipientUser.lastName
            providerUserLastName = fareMediaLastName
        }
        return {
            fareMediaId: fareMedia.id,
            fareMediaOwnerFirstName: fareMediaFirstName,
            fareMediaOwnerLastName: fareMediaLastName,
            providerId: provider,
            providerUserExternalId: fareMedia.providerUserExternalId ?? fareMedia.account?.providerUserExternalId ?? '',
            providerUserId: fareMedia.providerUserId ?? fareMedia.account?.id ?? fareMedia.accountId,
            providerUserFirstName: fareMedia.account?.firstName ?? providerUserFirstName,
            providerUserLastName: fareMedia.account?.lastName ?? providerUserLastName,
            recipientUser: fareMedia.recipientUser,
            fareMediaType: fareMedia.type
        }
    }
    return {
        fareMediaId: fareMedia,
        fareMediaOwnerFirstName: '',
        fareMediaOwnerLastName: '',
        providerId: provider
    }
}

export const normalizeProviderAccount = (providerAccount, provider) => {
    let providerUserFirstName = ''
    let providerUserLastName = ''
    if (!isEmpty(providerAccount.account?.firstName)) {
        providerUserFirstName = providerAccount.account.firstName
    } else if (!isEmpty(providerAccount.recipientUser?.firstName)) {
        providerUserFirstName = providerAccount.recipientUser.firstName
    }
    if (!isEmpty(providerAccount.account?.lastName)) {
        providerUserLastName = providerAccount.account.lastName
    } else if (!isEmpty(providerAccount.recipientUser?.lastName)) {
        providerUserLastName = providerAccount.recipientUser.lastName
    }
    return {
        providerId: provider,
        providerUserExternalId: providerAccount.providerUserExternalId ?? providerAccount.account.providerUserExternalId ?? '',
        providerUserId: providerAccount.providerUserId ?? providerAccount?.id ?? providerAccount.account?.id ?? providerAccount.accountId,
        providerUserFirstName,
        providerUserLastName,
        recipientUser: providerAccount.recipientUser,
        walletName: providerAccount.account?.walletName ?? ''
    }
}

export const normalizeProviderFareMedia = (fareMedia, provider) => {
    return {
        fareMediaId: fareMedia.id ?? fareMedia.fareMediaId,
        fareMediaOwnerFirstName: '',
        fareMediaOwnerLastName: '',
        providerId: provider,
        providerUserExternalId: fareMedia.providerUserExternalId
    }
}

export const normalizeProviderDevice = (device, provider) => {
    let deviceFirstName = ''
    let deviceLastName = ''
    let providerUserFirstName = ''
    let providerUserLastName = ''
    if (!isEmpty(device)) {
        if (!isEmpty(device.firstName)) {
            deviceFirstName = device.firstName
        } else if (!isEmpty(device.recipientUser) && !isEmpty(device.recipientUser.firstName)) {
            deviceFirstName = device.recipientUser.firstName
            providerUserFirstName = deviceFirstName
        }
        if (!isEmpty(device.lastName)) {
            deviceLastName = device.firstName
        } else if (!isEmpty(device.recipientUser) && !isEmpty(device.recipientUser.lastName)) {
            deviceLastName = device.recipientUser.lastName
            providerUserLastName = deviceLastName
        }
        return {
            deviceId: device.id,
            deviceLabel: device.label,
            fareMediaOwnerFirstName: deviceFirstName,
            fareMediaOwnerLastName: deviceLastName,
            providerId: provider,
            providerUserId: device.accountId,
            providerUserFirstName,
            providerUserLastName,
            recipientUser: device.recipientUser,
            supportType: device.type
        }
    }
    return {
        deviceId: device.id,
        deviceLabel: device.label,
        fareMediaOwnerFirstName: '',
        fareMediaOwnerLastName: '',
        providerId: provider
    }
}
