import { isEmpty, isIterable } from 'global-utils'
import CatalogProductSerializer from '@/StoreWeb/serializers/catalog/CatalogProductSerializerSingleton'
import CatalogProductDocumentNormalizer from '@/StoreWeb/normalizers/catalog/CatalogProductDocumentNormalizerSingleton'
import CatalogProductParameterNormalizer from '@/StoreWeb/normalizers/catalog/CatalogProductParameterNormalizerSingleton'
import CatalogProductSaleOptionNormalizer from '@/StoreWeb/normalizers/catalog/CatalogProductSaleOptionNormalizerSingleton'
import IdLabelPatternNormalizer from '@/StoreWeb/normalizers/patterns/IdLabelPatternNormalizerSingleton'
import IdNamePatternNormalizer from '@/StoreWeb/normalizers/patterns/IdNamePatternNormalizerSingleton'
import IdPatternNormalizer from '@/StoreWeb/normalizers/patterns/IdPatternNormalizerSingleton'

export default class {
    constructor () {
        this._catalogProductDocumentNormalizer = CatalogProductDocumentNormalizer.getInstance()
        this._catalogProductParameterNormalizer = CatalogProductParameterNormalizer.getInstance()
        this._catalogProductSaleOptionNormalizer = CatalogProductSaleOptionNormalizer.getInstance()
        this._catalogProductSerializer = CatalogProductSerializer.getInstance()
        this._idLabelPatternNormalizer = IdLabelPatternNormalizer.getInstance()
        this._idNamePatternNormalizer = IdNamePatternNormalizer.getInstance()
        this._idPatternNormalizer = IdPatternNormalizer.getInstance()
        this._store = null
        // this._counter = 0
    }

    get catalogProductDocumentNormalizer () {
        return this._catalogProductDocumentNormalizer
    }

    get catalogProductParameterNormalizer () {
        return this._catalogProductParameterNormalizer
    }

    get catalogProductSaleOptionNormalizer () {
        return this._catalogProductSaleOptionNormalizer
    }

    get catalogProductSerializer () {
        return this._catalogProductSerializer
    }

    get idLabelPatternNormalizer () {
        return this._idLabelPatternNormalizer
    }

    get idNamePatternNormalizer () {
        return this._idNamePatternNormalizer
    }

    get idPatternNormalizer () {
        return this._idPatternNormalizer
    }

    get store () {
        return this._store
    }

    set store (store) {
        this._store = store
    }

    deserialize (flow) {
        if (isIterable(flow)) {
            let i
            for (i in flow) {
                switch (i) {
                    case 'product':
                        if (!isEmpty(flow[i])) {
                            flow[i] = this.catalogProductSerializer.deserialize(flow[i])
                        }
                        break
                    case 'selectedSaleOption':
                        this.deserializeSelectedSaleOption(flow[i])
                        break
                    default:
                        if (isIterable(flow[i])) {
                            flow[i] = this.deserialize(flow[i])
                        }
                        break
                }
            }
        }
        return flow
    }

    deserializeSelectedSaleOption (selectedSaleOption) {
        if (!isEmpty(selectedSaleOption)) {
            selectedSaleOption = this.catalogProductSaleOptionNormalizer.normalize(selectedSaleOption)
        }
    }
}
