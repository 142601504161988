import MandateNormalizer from './MandateNormalizer'

const MandateNormalizerSingleton = (function () {
    let instance

    function createInstance () {
        const classObj = new MandateNormalizer()
        return classObj
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance()
            }
            return instance
        }
    }
})()

export default MandateNormalizerSingleton
