import * as actionTypes from './action-types'
import CartManager from '@/StoreWeb/managers/CartManagerSingleton'
const cartManager = CartManager.getInstance()

export default {
    /**
     * Load product details
     * @param args
     */
    [actionTypes.ADD_TO_CART] (...args) {
        const [, params] = args
        return cartManager.addToCartAction(params)
    },
    /**
     * Confirm cart
     * @param args
     */
    [actionTypes.CART_CONFIRMATION] (...args) {
        const [, params] = args
        return cartManager.getCartConfirmationAction(params)
    },
    /**
     * Clear cart
     * @param args
     */
    [actionTypes.CLEAR_CART] (...args) {
        const [, params] = args
        return cartManager.clearCartAction(params)
    },
    /**
     * Delete cart
     * @param args
     */
    [actionTypes.DELETE_CART] (...args) {
        const [, params] = args
        return cartManager.deleteCartAction(params)
    },
    /**
     * Delete cart line
     * @param args
     */
    [actionTypes.DELETE_CART_LINE] (...args) {
        const [, params] = args
        return cartManager.deleteCartLineAction(params)
    },
    /**
     * Get cart
     * @param args
     */
    [actionTypes.GET_CART] (...args) {
        const [, params] = args
        return cartManager.getCartAction(params)
    },
    /**
     * Get finalized cart
     * @param args
     */
    [actionTypes.GET_FINALIZED_CART] (...args) {
        const [, params] = args
        return cartManager.getFinalizedCartAction(params)
    },
    /**
     * Reopen Cart
     * @param args
     */
    [actionTypes.REOPEN_CART] (...args) {
        const [, params] = args
        return cartManager.reopenCartAction(params)
    }
}
