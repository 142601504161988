<template>
    <div class="Error404">
        <div class="Container">
            <h1 class="h1">
                {{ $t('error:404:title') }}
            </h1>
            <p
                class="mb-10"
                v-html="$sanitize(description)"
            />
            <v-btn
                :to="{ name: 'home' }"
                class="w-100 w-auto-sm"
                color="primary"
                elevation="0"
                size="x-large"
            >
                {{ $t('button:back_to_home') }}
            </v-btn>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const store = useStore()
const route = useRoute()
const { t } = useI18n()

const description = computed(() => {
    return t('error:404:description').replace('%link%', `<strong style="color: ${mainColor.value}">${route?.query?.path}</strong>`)
})
const mainColor = computed(() => store.state.mainColor)
</script>

<style lang="scss" scoped>
@import 'globalScss';
</style>
