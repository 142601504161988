<template>
    <div class="Favourites">
        <favorites :configuration="favoritesConfiguration" />
    </div>
</template>

<script>
import DefaultView from '@/StoreWeb/views/DefaultView'
import PageConfigManager from '@/StoreWeb/managers/PageConfigManagerSingleton'
import cachios from 'cachios'
import $store from '@/StoreWeb/store/store'
import config from 'config'
import { getUserTokenAccessToken } from '@/StoreWeb/js/composables/login-utils'

const pageConfigManager = PageConfigManager.getInstance()

export default {
    extends: DefaultView,
    provide: {
        $store
    },
    computed: {
        favoritesConfiguration () {
            return {
                cachiosBearerToken: this.cachiosBearerToken,
                cachiosApiKey: cachios.axiosInstance.defaults.headers.common['X-API-Key'],
                favoritesApiUrl: process.env.VUE_APP_FAVORITES_URL,
                disruptionsApiUrl: process.env.VUE_APP_DISRUPTIONS_URL,
                coreApiUrl: process.env.VUE_APP_CORE_URL,
                alertingPreferencesRouteName: 'alertingPreferences',
                networkId: process.env.VUE_APP_NETWORK_ID,
                assetsCDNUrl: process.env.VUE_APP_CDN_URL,
                IVWebsiteUrl: config.features.transport.favourites.iv_web_url
            }
        },
        cachiosBearerToken () {
            return getUserTokenAccessToken()
        }
    },
    mounted () {
        pageConfigManager.setConfig({
            title: 'account:favourites:title',
            currentAccountPage: 'favourites'
        })
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.Favourites {
    &-list {
        // Add css
    }
}
</style>
