<template>
    <component
        :is="tag"
        :class="{ 'AddressItem--selectedStatus' : isSelected }"
        class="AddressItem"
    >
        <strong
            v-if="getAddAddressEnabled && isDefaultAddress"
            class="AddressItem-defaultAddressStatus"
        >
            {{ $t('address:default_address') }}
        </strong>
        <strong
            v-if="address.getFullName() !== ''"
            class="AddressItem-line"
        >
            {{ address.getFullName() }}
        </strong>
        <span class="AddressItem-line">
            {{ address.getFullStreetName() }}
        </span>
        <span
            v-if="address.streetLine2"
            class="AddressItem-line"
        >
            {{ address.streetLine2 }}
        </span>
        <span
            v-if="address.getZipCodeAndCity() !== ''"
            class="AddressItem-line"
        >
            {{ address.getZipCodeAndCity() }}
        </span>
        <span
            v-if="address.getCountry() !== ''"
            class="AddressItem-line"
        >
            {{ address.getCountry() }}
        </span>
        <div class="AddressItem-buttonsArea">
            <v-btn
                v-if="getAddAddressEnabled && showSetAsDefaultButton && !isDefaultAddress"
                size="small"
                variant="outlined"
                @click.prevent="setAsDefault"
            >
                {{ $t('address:define_as_default') }}
            </v-btn>
            <v-btn
                v-if="getRemoveAddressEnabled"
                size="small"
                variant="outlined"
                @click.prevent="displayModal"
            >
                {{ $t('address:delete:button') }}
            </v-btn>
            <v-btn
                v-if="showChangeButton"
                class="bg-primary"
                color="white"
                size="small"
                @click.prevent="chooseAnotherAddress"
            >
                {{ $t('address:choose_another') }}
            </v-btn>
            <v-btn
                v-if="showEditButton"
                class="bg-primary"
                color="white"
                size="small"
                @click.prevent="editAddress"
            >
                {{ $t('button:edit') }}
            </v-btn>
            <v-btn
                v-if="!isSelected && showSelectButton"
                class="bg-primary"
                color="white"
                size="small"
                @click.prevent="selectAddress"
            >
                {{ $t('button:choose') }}
            </v-btn>
        </div>
        <modal
            v-model="showConfirmModal"
            :subtitle="$t('address:delete:confirm_modal_text')"
            :title="$t('address:delete:confirm_modal_title')"
        >
            <template #actions>
                <v-btn
                    variant="text"
                    @click="closeModal"
                >
                    {{ $t('button:cancel') }}
                </v-btn>
                <v-btn
                    class="bg-primary ml-2"
                    @click="deleteAddress"
                >
                    {{ $t('address:delete:button') }}
                </v-btn>
            </template>
        </modal>
    </component>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import AddressManager from '@/StoreWeb/managers/AddressManagerSingleton'

const emit = defineEmits(['chooseAnotherAddress', 'showEditAddressForm', 'addressSelected'])

const props = defineProps({
    address: {
        type: Object,
        required: true
    },
    isSelected: {
        type: Boolean,
        default: false
    },
    showChangeButton: {
        type: Boolean,
        default: false
    },
    showEditButton: {
        type: Boolean,
        default: true
    },
    showSelectButton: {
        type: Boolean,
        default: false
    },
    showSetAsDefaultButton: {
        type: Boolean,
        default: true
    },
    tag: {
        type: String,
        default: 'li'
    }
})

const store = useStore()

const addressManager = AddressManager.getInstance()
const showConfirmModal = ref(false)

const getAddAddressEnabled = computed(() => store.getters.getAddAddressEnabled)
const getRemoveAddressEnabled = computed(() => store.getters.getRemoveAddressEnabled)
const isDefaultAddress = computed(() => props.address.defaultAddress)

function chooseAnotherAddress () {
    emit('chooseAnotherAddress')
}

function closeModal () {
    showConfirmModal.value = false
}

function deleteAddress () {
    closeModal()
    addressManager.deleteAddressAction({ id: props.address.id })
}

function displayModal () {
    showConfirmModal.value = true
}

function editAddress () {
    emit('showEditAddressForm', props.address.id)
}

function setAsDefault () {
    addressManager.setAddressAsDefaultAction({ id: props.address.id })
}

function selectAddress () {
    emit('addressSelected', props.address.id)
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.AddressItem {
    padding: 15px;
    border-radius: 6px;
    box-shadow: $box-shadow-light;
    background: $color-lightest;
    font-size: 14px;
    color: $color-lightText;

    &--selectedStatus {
        box-shadow: 0 3px 8px 0 rgba(65, 89, 113, .09), inset 0 0 0 3px $color-brandPrimary;
    }

    &-defaultAddressStatus {
        display: block;
        margin: -15px -15px 10px;
        padding: 8px 15px;
        border-radius: 6px 6px 0 0;
        background: $color-lightgray3;
        color: $color-defaultText;
    }

    &-line {
        display: block;
        line-height: 1.5;
    }

    &-buttonsArea {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        padding-top: 10px;
    }

    &-button {
        width: auto;
    }
}
</style>
