export default class {
    constructor (id) {
        this._extension = ''
        this._id = id
        this._name = ''
        this._path = ''
        this._size = ''
        this._type = ''
        this._version = ''
    }

    get extension () {
        return this._extension
    }

    set extension (extension) {
        this._extension = extension
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get name () {
        return this._name
    }

    set name (name) {
        this._name = name
    }

    get path () {
        return this._path
    }

    set path (path) {
        this._path = path
    }

    get size () {
        return this._size
    }

    set size (size) {
        this._size = size
    }

    get type () {
        return this._type
    }

    set type (type) {
        this._type = type
    }

    get version () {
        return this._version
    }

    set version (version) {
        this._version = version
    }

    toJSON () {
        return {
            extension: this.extension,
            id: this.id,
            name: this.name,
            path: this.path,
            size: this.size,
            type: this.type,
            version: this.version
        }
    }
}
