const streetNumberSuffixes = [
    'Bis',
    'Ter',
    'Quarter'
]

const streetTypes = [
    'Allée',
    'Avenue',
    'Boulevard',
    'Centre',
    'Centre commercial',
    'Chaussée',
    'Chemin',
    'Cité',
    'Clos',
    'Corniche',
    'Côte',
    'Cours',
    'Digue',
    'Domaine',
    'Esplanade',
    'Faubourg',
    'Foyer',
    'Hameau',
    'Immeuble',
    'Impasse',
    'Lieu dit',
    'Lotissement',
    'Mail',
    'Montée',
    'Parc',
    'Parvis',
    'Passage',
    'Place',
    'Port',
    'Promenade',
    'Quai',
    'Quartier',
    'Résidence',
    'Rocade',
    'Rond point',
    'Rotonde',
    'Route',
    'Rue',
    'Ruelle',
    'Sente / Sentier',
    'Square',
    'Terrasse',
    'Tour',
    'Traversée',
    'Villa',
    'Village',
    'Voie',
    'Zone d\'activité',
    'Zone d\'aménagement concerté',
    'Zone d\'aménagement différé',
    'Zone industrielle'
]

const countries = [
    {
        id: 'FR',
        name: 'France'
    },
    {
        id: 'DE',
        name: 'Allemagne'
    },
    {
        id: 'IT',
        name: 'Italie'
    }
]

export default class {
    static getStreetNumberSuffixeOptions () {
        const streetNumberSuffixeOptions = [{
            id: '-',
            name: ''
        }]
        streetNumberSuffixes.forEach(suffix => {
            streetNumberSuffixeOptions.push({
                id: suffix,
                name: suffix
            })
        })

        return streetNumberSuffixeOptions
    }

    static getStreetTypeOptions () {
        const streetTypeOptions = [{
            id: '-',
            name: ''
        }]
        streetTypes.forEach(streetType => {
            streetTypeOptions.push({
                id: streetType,
                name: streetType
            })
        })

        return streetTypeOptions
    }

    static getCountryOptions () {
        const countryOptions = [{
            id: '-',
            name: ''
        }]
        countries.forEach(country => {
            countryOptions.push({
                id: country.id,
                name: country.name
            })
        })

        return countryOptions
    }
}
