<template>
    <component
        :is="'li'"
        class="SaleOptionProviderAccountChoiceItem mt-2"
    >
        <a
            :class="{ 'SaleOptionProviderAccountChoiceItem-link--current': isActive }"
            class="SaleOptionProviderAccountChoiceItem-link"
            @click.stop="selectSaleOptionProviderAccount"
        >
            <span class="SaleOptionProviderAccountChoiceItem-iconWrapper">
                <span
                    :class="getIcon"
                    class="SaleOptionProviderAccountChoiceItem-icon"
                >
                    <span class="path1" />
                    <span class="path2" />
                    <span class="path3" />
                </span>
                <span
                    v-if="mdiIcon"
                    class="SaleOptionProviderAccountChoiceItem-icon"
                    :class="mdiIcon"
                />
            </span>
            <span class="SaleOptionProviderAccountChoiceItem-label">
                <strong class="SaleOptionProviderAccountChoiceItem-title">{{ getLabel }}</strong>
                <span class="SaleOptionProviderAccountChoiceItem-subtitle">{{ getDescription }}</span>
            </span>
        </a>
    </component>
</template>

<script setup>
import { computed } from 'vue'
import i18n from 'i18n'

const emit = defineEmits(['providerAccountClicked'])

const props = defineProps({
    isActive: {
        type: Boolean,
        default: false
    },
    providerAccount: {
        type: Object,
        required: true
    },
    icon: {
        type: String,
        default: 'Account--outlined'
    },
    mdiIcon: {
        type: String,
        default: ''
    },
    description: {
        type: String,
        default: ''
    }
})

const getLabel = computed(() => {
    const fullName = props.providerAccount.account.getFullName()
    if (fullName) {
        return i18n.global.t('sale_option:basket_info:provider_account:fullname_label')
            .replace('%walletName%', props.providerAccount.account.walletName)
            .replace('%fullName%', fullName)
    }
    return props.providerAccount.account.walletName
})

const getIcon = computed(() => {
    return `Icon${props.icon}${props.mdiIcon}`
})

const getDescription = computed(() => {
    return `n° ${props.providerAccount.account.providerUserExternalId || props.providerAccount.account.id}`
})

function selectSaleOptionProviderAccount () {
    emit('providerAccountClicked', props.providerAccount)
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.SaleOptionProviderAccountChoiceItem {
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 0;
    }

    &-link {
        display: flex;
        padding: 10px 10px 14px;
        border: 2px solid transparent;
        border-radius: 6px;
        box-shadow: $box-shadow-light;
        background-color: $color-lightest;
        cursor: pointer;
        color: $color-lighterText;
        transition: border-color .3s ease-in-out, background-color .3s ease-in-out;

        &:hover,
        &:active {
            background-color: $color-lightgray3;
        }

        &--current {
            border-color: $color-brandPrimary;
        }
    }

    &:first-child {
        padding-top: 0;
        border-top: none;
    }

    &-iconWrapper {
        position: relative;
        width: 32px;
    }

    &-icon {
        position: absolute;
        font-size: 30px;
        color: $color-brandPrimary;
    }

    .Icon {
        &FareMediaIllust {
            top: 0;
            left: -2px;

            .path3 {
                color: $color-brandPrimary;
            }
        }

        &Card {
            top: -3px;
            left: -2px;
            color: $color-brandPrimary;
        }

        &Phone {
            top: 0;
            left: -2px;
        }
    }

    &-label {
        flex-grow: 1;
    }

    &-title {
        display: block;
        font-family: $fontFamily-title;
        font-size: 18px;
        font-weight: $fontWeight-titleBold;
        color: $color-defaultText;
    }

    &-subtitle {
        font-size: 12px;
        font-weight: $fontWeight-defaultMedium;
    }
}
</style>
