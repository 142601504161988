<template>
    <link
        rel="stylesheet"
        href="https://static.payzen.eu/static/js/krypton-client/V4.0/ext/neon-reset.css"
    >
    <div class="PaymentForm">
        <div class="PaymentForm-errorMessage">
            {{ promiseError }}
        </div>
        <div class="d-flex align-center justify-center">
            <div id="PayZenV4Form">
                <div class="kr-smart-form" />
            </div>
        </div>
    </div>
</template>

<script setup>
import KRGlue from '@lyracom/embedded-form-glue'
import * as cartMutationTypes from '@/StoreWeb/store/modules/cart/mutation-types'
import * as paymentMutationTypes from '@/StoreWeb/store/modules/payment/mutation-types'
import Cart from '@/StoreWeb/models/cart/Cart'
import { useStore } from 'vuex'
import { onMounted, ref } from 'vue'
import config from 'config'
import { useRouter } from 'vue-router'
import i18n from 'i18n'

const { commit } = useStore()
const router = useRouter()

const props = defineProps({
    token: {
        type: String,
        required: true
    }

})

const promiseError = ref('')

onMounted(async () => {
    const endpoint = 'https://static.payzen.eu'
    const publicKey = config?.payment?.payzen?.key ?? ''

    try {
        const { KR } = await KRGlue.loadLibrary(
            endpoint,
            publicKey
        )

        await KR.setFormConfig({
            formToken: props.token,
            'kr-language': i18n.locale ?? 'fr-FR'
        })
        await KR.onSubmit(validatePayment)

        await KR.renderElements('#PayZenV4Form')
    } catch (error) {
        promiseError.value = error + ' (see console for more details)'
    }
})
const validatePayment = async (paymentData) => {
    localStorage.removeItem('cartEmail')
    commit(cartMutationTypes.SET_CART, new Cart())
    commit(paymentMutationTypes.SET_PAYMENT, null)

    if (paymentData.clientAnswer.orderStatus === 'PAID') {
        await router.push({ name: 'paymentSuccess' })
    } else {
        await router.push({ name: 'paymentError' })
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.PaymentForm {
    &-form {
        max-width: 400px;
    }
}
</style>
