import actions from './actions'
import mutations from './mutations'

export default {
    namespaced: false,
    actions,
    mutations,
    state: {
        anonymousPendingRequests: {
            getConduentSessionId: false,
            getSupportContent: false,
            getSupportInfos: false
        },
        pluggedSupportId: '',
        supportContent: null
    }
}
