export const SET_DEBUG_CONFIG = 'setDebugConfig'
export const SET_DEBUG_INFORMATIONS_DISPLAY = 'setDebugInformationsDisplay'
export const SET_DEBUG_MODE = 'setDebugMode'
export const SET_DEBUG_PENDING_REQUEST = 'setDebugPendingRequest'
export const SET_FORCE_USE_WALLET_CACHE = 'setForceUseWalletCache'
export const SET_MOCK_STATE = 'setMockState'
// export const SET_MOCK_STATE_GET_CATALOG = 'setGetCatalogMockState'
// export const SET_MOCK_STATE_GET_CART = 'setGetCartMockState'
// export const SET_MOCK_STATE_GET_CART_CONFIRMATION = 'setGetCartConfirmationMockState'
// export const SET_MOCK_STATE_GET_FARE_MEDIAS = 'setGetFareMediasMockState'
// export const SET_MOCK_STATE_GET_ORDERS = 'setGetOrdersMockState'
// export const SET_MOCK_STATE_GET_NOTIFICATIONS = 'setGetNotificationsMockState'
// export const SET_MOCK_STATE_GET_WALLET = 'setGetWalletMockState'
// export const SET_MOCK_STATE_POST_PAYMENT_INIT = 'setPostPaymentInitMockState'
// export const SET_MOCK_STATE_TERMINATE_PLAN = 'setTerminatePlanMockState'
// export const SET_MOCK_STATE_UNLINK_FARE_MEDIA = 'setUnlinkFareMediaState'
