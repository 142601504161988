import * as actionTypes from './action-types'
import CardReaderManager from '@/StoreWeb/managers/CardReaderManagerSingleton'
const cardReaderManager = CardReaderManager.getInstance()

export default {
    /**
     * Get a new operation
     * @param params
     */
    [actionTypes.GET_OPERATION] (...args) {
        const [, params] = args
        return cardReaderManager.getOperationAction(params)
    },
    /**
     * Get a new operation report
     * @param params
     */
    [actionTypes.GET_OPERATION_REPORT] (...args) {
        const [, params] = args
        return cardReaderManager.getOperationReportAction(params)
    }
}
