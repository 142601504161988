export default class {
    constructor (code) {
        this._code = code
        this._fields = []
        this._label = ''
        this._maxNb = 1
        this._minNb = 1
    }

    get code () {
        return this._code
    }

    get fields () {
        return this._fields
    }

    set fields (fields) {
        this._fields = fields
    }

    get label () {
        return this._label
    }

    set label (label) {
        this._label = label
    }

    get maxNb () {
        return this._maxNb
    }

    set maxNb (maxNb) {
        this._maxNb = maxNb
    }

    get minNb () {
        return this._minNb
    }

    set minNb (minNb) {
        this._minNb = minNb
    }

    toJSON () {
        const fields = []
        this.fields.forEach(field => {
            fields.push(field.toJSON())
        })
        return {
            code: this.code,
            fields,
            label: this.label,
            maxNb: this.maxNb,
            minNb: this.minNb
        }
    }
}
