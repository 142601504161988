import HistoryPayment from '@/StoreWeb/models/user/order/HistoryPayment'
import { isEmpty } from 'global-utils'

export default class {
    normalize (item) {
        const historyPayment = new HistoryPayment()

        historyPayment.credit = item.credit
        historyPayment.debit = item.debit
        historyPayment.externalId = item.externalId
        historyPayment.operation = item.operation
        historyPayment.paymentStatus = item.paymentStatus
        historyPayment.paymentUpdateDate = item.paymentUpdateDate
        historyPayment.providerId = item.providerId

        return historyPayment
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
