export default class {
    // /**
    //  * NotificationList constructor
    //  */
    constructor () {
        this._lastLoadedPage = null
    }

    get items () {
        return this._items
    }

    set items (items) {
        this._items = items
    }

    get lastLoadedPage () {
        return this._lastLoadedPage
    }

    set lastLoadedPage (lastLoadedPage) {
        this._lastLoadedPage = lastLoadedPage
    }

    get totalElements () {
        return this._totalElements
    }

    set totalElements (totalElements) {
        this._totalElements = totalElements
    }

    get totalPages () {
        return this._totalPages
    }

    set totalPages (totalPages) {
        this._totalPages = totalPages
    }

    toJSON () {
        return {
            items: this.items,
            lastLoadedPage: this.lastLoadedPage,
            totalElements: this.totalElements,
            totalPages: this.totalPages
        }
    }
}
