import BankAccount from '@/StoreWeb/models/payment/BankAccount'
import MandateNormalizer from '@/StoreWeb/normalizers/payment/MandateNormalizerSingleton'
import { isEmpty } from 'global-utils'
import ActionNormalizer from '@/StoreWeb/normalizers/common/ActionNormalizerSingleton'

export default class {
    constructor () {
        this._actionNormalizer = ActionNormalizer.getInstance()
        this._mandateNormalizer = MandateNormalizer.getInstance()
    }

    get actionNormalizer () {
        return this._actionNormalizer
    }

    get mandateNormalizer () {
        return this._mandateNormalizer
    }

    normalize (item, params) {
        const bankAccount = new BankAccount()

        if (typeof params === 'undefined') {
            params = {}
        }

        if (!isEmpty(item.bankAccount)) {
            bankAccount.bankName = item.bankAccount.bankName ?? ''
            bankAccount.bic = item.bankAccount.bic ?? ''
            bankAccount.iban = item.bankAccount.iban ?? ''
            bankAccount.providerBankAccountIdentifier = item.bankAccount.providerBankAccountIdentifier ?? ''
        }
        if (!isEmpty(item.actions)) {
            bankAccount.actions = this.actionNormalizer.normalizeArray(item.actions)
        }
        if (!isEmpty(item.mandates)) {
            bankAccount.mandates = this.mandateNormalizer.normalizeArray(item.mandates)
        }
        bankAccount.providerId = params.providerId ?? ''
        bankAccount.providerUserId = params.providerUserId ?? ''
        bankAccount.signUrl = params.signUrl ?? ''

        return bankAccount
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items) && !isEmpty(items.bankInformations)) {
            items.bankInformations.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
