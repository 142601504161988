<template>
    <component
        :is="'li'"
        class="FareMediaProduct"
    >
        <span class="FareMediaProduct-name">{{ product.name }}</span>
        <p
            v-if="getDescription !== ''"
            class="FareMediaProduct-description"
            v-html="$sanitize(getDescription)"
        />
        <p>
            <formatted-fare
                tag="span"
                :fare="product.fareIncludingTaxes"
            />
        </p>
        <div class="FareMediaProduct-buttonsArea">
            <v-btn
                color="primary"
                variant="flat"
                @click.prevent="startOrderProcess"
            >
                {{ $t('button:choose') }}
            </v-btn>
        </div>
    </component>
</template>

<script>
import FormattedFare from '@/StoreWeb/components/common/FormattedFare.vue'

export default {
    components: {
        FormattedFare
    },
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    computed: {
        getDescription () {
            if (this.product.description !== this.product.name) {
                return this.product.description.replace('\n', '<br>')
            }
            return ''
        }
    },
    methods: {
        startOrderProcess () {
            this.$emit('startOrderProcess', this.product)
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.FareMediaProduct {
    margin-bottom: 16px;
    padding: 8px 16px 16px;
    border-radius: 5px;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, .11);
    background-color: $color-lightest;

    &-name {
        font-family: $fontFamily-title;
        font-size: 18px;
        font-weight: $fontWeight-titleBold;
        color: $color-defaultText;
    }

    &-description {
        margin: 8px 0 16px;
        color: $color-lighterText;
    }

    &-buttonsArea {
        display: flex;
        justify-content: flex-end;
    }

    &-button {
        width: auto;
        min-width: 92px;
    }
}
</style>
