export default class {
    constructor (id) {
        this._credit = 0
        this._debit = 0
        this._id = id
        this._name = ''
        this._newValue = 0
        this._oldValue = 0
        this._unit = ''
        this._validityDate = ''
        this._version = ''
    }

    get credit () {
        return this._credit
    }

    set credit (credit) {
        this._credit = credit
    }

    get debit () {
        return this._debit
    }

    set debit (debit) {
        this._debit = debit
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get name () {
        return this._name
    }

    set name (name) {
        this._name = name
    }

    get newValue () {
        return this._newValue
    }

    set newValue (newValue) {
        this._newValue = newValue
    }

    get oldValue () {
        return this._oldValue
    }

    set oldValue (oldValue) {
        this._oldValue = oldValue
    }

    get unit () {
        return this._unit
    }

    set unit (unit) {
        this._unit = unit
    }

    get validityDate () {
        return this._validityDate
    }

    set validityDate (validityDate) {
        this._validityDate = validityDate
    }

    get version () {
        return this._version
    }

    set version (version) {
        this._version = version
    }

    toJSON () {
        return {
            credit: this.credit,
            debit: this.debit,
            id: this.id,
            name: this.name,
            newValue: this.newValue,
            oldValue: this.oldValue,
            unit: this.unit,
            validityDate: this.validityDate,
            version: this.version
        }
    }
}
