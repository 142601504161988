export default class {
    constructor () {
        this._offset = 0
        this._paged = false
        this._pageNumber = 0
        this._pageSize = 20
        this._sort = null
        this._unpaged = !this._paged
    }

    get offset () {
        return this._offset
    }

    set offset (offset) {
        this._offset = offset
    }

    get paged () {
        return this._paged
    }

    set paged (paged) {
        this._paged = paged
    }

    get pageNumber () {
        return this._pageNumber
    }

    set pageNumber (pageNumber) {
        this._pageNumber = pageNumber
    }

    get pageSize () {
        return this._pageSize
    }

    set pageSize (pageSize) {
        this._pageSize = pageSize
    }

    get sort () {
        return this._sort
    }

    set sort (sort) {
        this._sort = sort
    }

    get unpaged () {
        return this._unpaged
    }

    set unpaged (unpaged) {
        this._unpaged = unpaged
    }

    toJSON () {
        return {
            offset: this.offset,
            paged: this.paged,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            sort: this.sort,
            unpaged: this.unpaged
        }
    }
}
