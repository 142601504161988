import i18n from 'i18n'
import { isEmpty } from 'global-utils'

export default class {
    constructor () {
        this._accountId = ''
        this._accountUserFirstName = ''
        this._accountUserLastName = ''
        this._basketType = ''
        this._basketInfo = ''
        this._externalStatus = null
        this._orderId = ''
        this._products = []
        this._providerBasketId = ''
        this._providerFareMediaId = ''
        this._providerId = ''
        this._providerUserId = ''
        this._recipientUser = null
        this._status = ''
        this._updateDate = ''
    }

    get accountId () {
        return this._accountId
    }

    set accountId (accountId) {
        this._accountId = accountId
    }

    get accountUserFirstName () {
        return this._accountUserFirstName
    }

    set accountUserFirstName (accountUserFirstName) {
        this._accountUserFirstName = accountUserFirstName
    }

    get accountUserLastName () {
        return this._accountUserLastName
    }

    set accountUserLastName (accountUserLastName) {
        this._accountUserLastName = accountUserLastName
    }

    get basketInfo () {
        return this._basketInfo
    }

    set basketInfo (basketInfo) {
        this._basketInfo = basketInfo
    }

    get basketType () {
        return this._basketType
    }

    set basketType (basketType) {
        this._basketType = basketType
    }

    get externalStatus () {
        return this._externalStatus
    }

    set externalStatus (externalStatus) {
        this._externalStatus = externalStatus
    }

    get fareMediaId () {
        return this._providerFareMediaId
    }

    get products () {
        return this._products
    }

    set products (products) {
        this._products = products
    }

    get providerBasketId () {
        return this._providerBasketId
    }

    set providerBasketId (providerBasketId) {
        this._providerBasketId = providerBasketId
    }

    get providerFareMediaId () {
        return this._providerFareMediaId
    }

    set providerFareMediaId (providerFareMediaId) {
        this._providerFareMediaId = providerFareMediaId
    }

    get providerId () {
        return this._providerId
    }

    set providerId (providerId) {
        this._providerId = providerId
    }

    get orderId () {
        return this._orderId
    }

    set orderId (orderId) {
        this._orderId = orderId
    }

    get providerUserId () {
        return this._providerUserId
    }

    set providerUserId (providerUserId) {
        this._providerUserId = providerUserId
    }

    get recipientUser () {
        return this._recipientUser
    }

    set recipientUser (recipientUser) {
        this._recipientUser = recipientUser
    }

    get status () {
        return this._status
    }

    set status (status) {
        this._status = status
    }

    get updateDate () {
        return this._updateDate
    }

    set updateDate (updateDate) {
        this._updateDate = updateDate
    }

    getFareMediaTitle () {
        if (!isEmpty(this.providerFareMediaId)) {
            return i18n.global.t('sale_option:basket_info:provider_account_and_faremedia')
        } else if (!isEmpty(this.recipientUser)) {
            return i18n.global.t('fare_media:card_of').replace('%cardOwner%', this.recipientUser.getFullName())
        }
        return ''
    }

    getFareMediaSubtitle () {
        if (!isEmpty(this.providerFareMediaId)) {
            return `n°${this.providerFareMediaId}`
        }
        return ''
    }

    getStatusLabel () {
        return i18n.global.t('basket:status:' + this.status.toLowerCase())
    }

    getTitle () {
        if (this.basketType === 'FAREMEDIA') {
            return i18n.global.t('basket:card_order')
        }
        return `Panier ${this.providerId}`
    }

    toJSON () {
        return {
            accountId: this.accountId,
            accountUserFirstName: this.accountUserFirstName,
            accountUserLastName: this.accountUserLastName,
            basketInfo: this.basketInfo,
            basketType: this.basketType,
            externalStatus: this.externalStatus,
            orderId: this.orderId,
            products: this.products,
            providerBasketId: this.providerBasketId,
            providerFareMediaId: this.providerFareMediaId,
            providerId: this.providerId,
            providerUserId: this.providerUserId,
            recipientUser: this.recipientUser,
            status: this.status,
            updateDate: this.updateDate
        }
    }
}
