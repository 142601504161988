import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
    namespaced: false,
    actions,
    getters,
    mutations,
    state: {
        addToCartEmail: '',
        addToCartFlow: null,
        addToCartFlowContext: null,
        addToCartFlowStep: null,
        addToCartFlowIndex: null,
        isAddToCartFlowFirstStep: true,
        isAddToCartFlowLastStep: false,
        isGoToNextStepDisabled: false,
        isGuestPurchaseFlow: false,
        cart: null,
        cartPendingRequests: {
            addFareMediaToCart: false,
            addFineToCart: false,
            addToCart: false,
            cartConfirmation: false,
            deleteCart: false,
            deleteCartLine: false,
            getCart: false,
            getFinalizedCart: false,
            reopenCart: false
        },
        showCart: false,
        tempAddCartFlow: null
    }
}
