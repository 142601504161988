<template>
    <div class="FineAuthenticationChoice">
        <h2 class="FineAuthenticationChoice-title">
            {{ $t('add_to_cart_flow:authentication_mode_selector:title') }}
        </h2>
        <ul class="FineAuthenticationChoice-list">
            <li class="FineAuthenticationChoice-listItem">
                <a
                    class="FineAuthenticationChoice-listItemLink"
                    @click="selectConnectionMode(anonymousMode)"
                >
                    <span class="FineAuthenticationChoice-iconWrapper">
                        <span class="FineAuthenticationChoice-icon IconWallet--outlined">
                            <span class="path1" />
                            <span class="path2" />
                            <span class="path3" />
                        </span>
                    </span>
                    <span class="FineAuthenticationChoice-label">
                        <strong class="FineAuthenticationChoice-subtitle">
                            {{ $t('user_auth_mode:email_mandatory:title') }}
                        </strong>
                        <span class="FineAuthenticationChoice-description">
                            {{ $t('user_auth_mode:email_mandatory:subtitle') }}
                        </span>
                    </span>
                </a>
            </li>
            <li class="FineAuthenticationChoice-listItem">
                <a
                    class="FineAuthenticationChoice-listItemLink"
                    @click="selectConnectionMode(loginMode)"
                >
                    <span class="FineAuthenticationChoice-iconWrapper">
                        <span class="FineAuthenticationChoice-icon IconAccount--outlined">
                            <span class="path1" />
                            <span class="path2" />
                            <span class="path3" />
                        </span>
                    </span>
                    <span class="FineAuthenticationChoice-label">
                        <strong class="FineAuthenticationChoice-subtitle">
                            {{ $t('user_auth_mode:user_account:title') }}
                        </strong>
                        <span class="FineAuthenticationChoice-description">
                            {{ $t('user_auth_mode:user_account:subtitle') }}
                        </span>
                    </span>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    data () {
        return {
            anonymousMode: 'anonymous',
            loginMode: 'loginMode'
        }
    },
    methods: {
        selectConnectionMode (modeSelected) {
            this.$emit('selectedMode', modeSelected)
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.FineAuthenticationChoice {
    &-title {
        margin: 0 0 10px;
        font-size: 18px;
    }

    &-list {
        padding: 0;
    }

    &-listItem {
        margin-bottom: 15px;
        padding: 0;
        list-style: none;

        &:last-child {
            margin-bottom: 0;
        }

        &Link {
            display: flex;
            padding: 10px 10px 14px;
            border: 2px solid $color-lightgray4;
            border-radius: 6px;
            box-shadow: $box-shadow-light;
            background: $color-lightest;
            cursor: pointer;
            color: $color-lighterText;
            transition: background .3s;

            &:hover,
            &:active {
                background: $color-lightgray3;
            }
        }

        &:first-child {
            padding-top: 0;
            border-top: none;
        }
    }

    &-iconWrapper {
        position: relative;
        width: 32px;
    }

    &-icon {
        position: absolute;
        font-size: 30px;
    }

    &-label {
        flex-grow: 1;
    }

    &-subtitle {
        display: block;
        font-family: $fontFamily-title;
        font-size: 18px;
        font-weight: $fontWeight-titleBold;
        color: $color-defaultText;
    }

    &-description {
        font-size: 12px;
        font-weight: $fontWeight-defaultMedium;
    }
}
</style>
