import DeviceProductNormalizer from './DeviceProductNormalizer'

const DeviceProductNormalizerSingleton = (function () {
    let instance

    function createInstance () {
        return new DeviceProductNormalizer()
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance()
            }
            return instance
        }
    }
})()

export default DeviceProductNormalizerSingleton
