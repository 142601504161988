import CatalogProduct from '@/StoreWeb/models/catalog/CatalogProduct'
import CatalogProductDocumentNormalizer from '@/StoreWeb/normalizers/catalog/CatalogProductDocumentNormalizerSingleton'
import CatalogProductParameterNormalizer from '@/StoreWeb/normalizers/catalog/CatalogProductParameterNormalizerSingleton'
import CatalogProductSaleOptionNormalizer from '@/StoreWeb/normalizers/catalog/CatalogProductSaleOptionNormalizerSingleton'
import IdLabelPatternNormalizer from '@/StoreWeb/normalizers/patterns/IdLabelPatternNormalizerSingleton'
import IdNamePatternNormalizer from '@/StoreWeb/normalizers/patterns/IdNamePatternNormalizerSingleton'
import IdPatternNormalizer from '@/StoreWeb/normalizers/patterns/IdPatternNormalizerSingleton'
import { isEmpty } from 'global-utils'

export default class {
    constructor () {
        this._catalogProductDocumentNormalizer = CatalogProductDocumentNormalizer.getInstance()
        this._catalogProductParameterNormalizer = CatalogProductParameterNormalizer.getInstance()
        this._catalogProductSaleOptionNormalizer = CatalogProductSaleOptionNormalizer.getInstance()
        this._idLabelPatternNormalizer = IdLabelPatternNormalizer.getInstance()
        this._idNamePatternNormalizer = IdNamePatternNormalizer.getInstance()
        this._idPatternNormalizer = IdPatternNormalizer.getInstance()
    }

    get catalogProductDocumentNormalizer () {
        return this._catalogProductDocumentNormalizer
    }

    get catalogProductParameterNormalizer () {
        return this._catalogProductParameterNormalizer
    }

    get catalogProductSaleOptionNormalizer () {
        return this._catalogProductSaleOptionNormalizer
    }

    get idLabelPatternNormalizer () {
        return this._idLabelPatternNormalizer
    }

    get idNamePatternNormalizer () {
        return this._idNamePatternNormalizer
    }

    get idPatternNormalizer () {
        return this._idPatternNormalizer
    }

    normalize (item, params) {
        let modalKey = ''
        let preselectedProductParameters
        let product

        if (typeof params === 'undefined') {
            params = {}
        }

        if (isEmpty(params.type) && !isEmpty(item.type)) {
            modalKey = this.getModalKeyFromModalType(item.type)
        }

        if (!isEmpty(params.itemRenewalData) && !isEmpty(params.itemRenewalData.productParameters)) {
            preselectedProductParameters = params.itemRenewalData.productParameters
        }

        if (!isEmpty(item.id)) {
            product = new CatalogProduct(item.id)
        } else if (!isEmpty(item[modalKey]?.id)) {
            product = new CatalogProduct(item[modalKey].id)
        }

        if (!isEmpty(product)) {
            if (!isEmpty(item.index)) {
                product.index = item.index
            }
            if (!isEmpty(item.quantity)) {
                product.quantity = item.quantity
            }
            if (typeof params.type === 'string') {
                product.modalType = params.type
            } else if (!isEmpty(item.type)) {
                product.modalType = item.type
            }
            if (!isEmpty(modalKey) && !isEmpty(item[modalKey])) {
                item = item[modalKey]
            }

            if (!isEmpty(item)) {
                if (!isEmpty(item.currency)) {
                    product.currency = item.currency
                }
                if (!isEmpty(item.description)) {
                    product.description = item.description
                }
                if (!isEmpty(item.documents)) {
                    product.documents = this.catalogProductDocumentNormalizer.normalizeArray(item.documents)
                }
                if (!isEmpty(item.endDate)) {
                    product.endDate = item.endDate
                }
                product.expressPurchase = item.expressPurchase ?? false
                if (!isEmpty(item.externalId)) {
                    product.externalId = item.externalId
                }
                if (!isEmpty(item.fareRule)) {
                    product.fareRule = item.fareRule
                }
                if (!isEmpty(item.fareExcludingTaxes)) {
                    product.fareExcludingTaxes = item.fareExcludingTaxes
                }
                if (!isEmpty(item.vatRate)) {
                    product.vatRate = item.vatRate
                }
                if (!isEmpty(item.vatAmount)) {
                    product.vatAmount = item.vatAmount
                }
                if (!isEmpty(item.fareIncludingTaxes)) {
                    product.fareIncludingTaxes = item.fareIncludingTaxes
                }
                if (!isEmpty(item.fareRecurringIncludingTaxes)) {
                    product.fareRecurringIncludingTaxes = item.fareRecurringIncludingTaxes
                }
                if (!isEmpty(item.fareTotalRecurringIncludingTaxes)) {
                    product.fareTotalRecurringIncludingTaxes = item.fareTotalRecurringIncludingTaxes
                }
                if (!isEmpty(item.fareTotalIncludingTaxes)) {
                    product.fareTotalIncludingTaxes = item.fareTotalIncludingTaxes
                }
                if (!isEmpty(item.fareRecurringPeriod)) {
                    product.fareRecurringPeriod = item.fareRecurringPeriod
                }
                if (!isEmpty(item.fareRecurringPeriodCount)) {
                    product.fareRecurringPeriodCount = item.fareRecurringPeriodCount
                }
                if (!isEmpty(item.image)) {
                    product.image = item.image
                }
                if (!isEmpty(item.imageAlt)) {
                    product.imageAlt = item.imageAlt
                }
                product.keywords = item.keywords ?? []
                if (!isEmpty(params.level)) {
                    product.level = params.level
                }
                if (!isEmpty(item.maxNumber)) {
                    product.maxNumber = item.maxNumber
                }
                if (!isEmpty(item.minNumber)) {
                    product.minNumber = item.minNumber
                }
                if (params.isParentIsModalPackage) {
                    product.minNumber = product.quantity ?? 1
                    product.maxNumber = product.quantity ?? 1
                }
                if (!isEmpty(item.name)) {
                    product.name = item.name
                }
                if (!isEmpty(item.paymentModes)) {
                    product.paymentModes = this.idLabelPatternNormalizer.normalizeArray(item.paymentModes)
                }
                if (!isEmpty(item.preauthorizedAmount)) {
                    product.preauthorizedAmount = item.preauthorizedAmount
                }
                if (!isEmpty(item.productParameters)) {
                    product.productParameters = this.catalogProductParameterNormalizer.normalizeArray(item.productParameters, {
                        preselectedProductParameters
                    })
                }
                if (!isEmpty(item.preSetProductParameters)) {
                    product.preSetProductParameters = item.preSetProductParameters
                }
                if (!isEmpty(item.productType)) {
                    product.productType = this.idLabelPatternNormalizer.normalize(item.productType)
                }
                if (!isEmpty(item.providerNetworkLabel)) {
                    product.providerNetworkLabel = item.providerNetworkLabel
                }
                if (!isEmpty(item.saleableUnit)) {
                    product.saleableUnit = this.idLabelPatternNormalizer.normalize(item.saleableUnit)
                    // TODO : remove once NON_SALEABLE product feature is fully developed
                    // product.saleableUnit = this.idLabelPatternNormalizer.normalize({
                    //     id: 'NON_SALEABLE',
                    //     label: 'Non vendable'
                    // })
                }
                if (!isEmpty(item.saleOptions)) {
                    product.saleOptions = this.catalogProductSaleOptionNormalizer.normalizeArray(item.saleOptions)
                    // TODO : Remove the line below once POINTOFSALE saleOption deliveryMode will be enabled again
                    product.saleOptions = product.saleOptions.filter(saleOption => saleOption.deliveryMode.id !== 'POINTOFSALE')
                    product.saleOptions.sort((a, b) => {
                        return a.index - b.index
                    })
                }
                if (!isEmpty(item.startDate)) {
                    product.startDate = item.startDate
                }
                product.shortDescription = item.shortDescription ? item.shortDescription.replace(/\n/g, '<br>') : ''
                if (!isEmpty(item.status)) {
                    product.status = item.status
                }
                if (!isEmpty(item.products)) {
                    product.subProducts = this.normalizeArray(item.products, {
                        level: product.level + 1,
                        isParentIsModalPackage: product.modalType === 'MODALPACKAGE'
                    })
                    product.subProducts.sort((a, b) => {
                        return a.index - b.index
                    })
                }
                if (!isEmpty(item.termsOfServiceUrl)) {
                    product.termsOfServiceUrl = item.termsOfServiceUrl
                }
                if (!isEmpty(item.userAuthModes)) {
                    product.userAuthModes = this.idLabelPatternNormalizer.normalizeArray(item.userAuthModes)
                }
                if (!isEmpty(item.modalProducts)) {
                    product.variations = this.normalizeArray(item.modalProducts, {
                        level: product.level + 1,
                        type: 'MODALPRODUCT'
                    })
                }
                if (!isEmpty(item.version)) {
                    product.version = item.version
                }
                product.weeeTaxApplicable = item.weeeTaxApplicable ?? false
                if (!isEmpty(item.requiredFareProfiles)) {
                    product.requiredFareProfiles = this.idLabelPatternNormalizer.normalizeArray(item.requiredFareProfiles)
                }
                if (!isEmpty(item.address)) {
                    product.requiredFareProfiles = this.idLabelPatternNormalizer.normalizeArray(item.requiredFareProfiles)
                }
                if (!isEmpty(item.seoTitle)) {
                    product.seoTitle = item.seoTitle
                }
                if (!isEmpty(item.seoMetaDescription)) {
                    product.seoMetaDescription = item.seoMetaDescription
                }
                if (!isEmpty(item.seoImage)) {
                    product.seoImage = item.seoImage
                }
                if (!isEmpty(item.seoImageAlt)) {
                    product.seoImageAlt = item.seoImageAlt
                }
            }
        }

        return product
    }

    normalizeArray (items, params) {
        const normalizedItems = []

        if (typeof params === 'undefined') {
            params = {}
        }

        if (!isEmpty(items)) {
            items.forEach(item => {
                const itemParams = Object.assign({}, params)
                if (typeof params.renewalData !== 'undefined') {
                    const itemRenewalData = params.renewalData.find(renewalDataItem => renewalDataItem.id === item.id)
                    delete itemParams.renewalData
                    if (!isEmpty(itemRenewalData)) {
                        itemParams.itemRenewalData = itemRenewalData
                    }
                }
                normalizedItems.push(this.normalize(item, itemParams))
            })
        }

        return normalizedItems
    }

    getModalKeyFromModalType (type) {
        switch (type) {
            case 'MODALPACKAGE':
                return 'modalPackage'
            case 'MODALPLAN':
                return 'modalPlan'
            case 'MODALPRODUCT':
                return 'modalProduct'
            case 'MODALPRODUCTGROUP':
                return 'modalProductGroup'
        }

        return null
    }
}
