<template>
    <div class="VariationSelector">
        <h2
            v-if="title !== ''"
            class="VariationSelector-title"
        >
            {{ title }}
        </h2>
        <div class="VariationSelector-list">
            <variation-item
                v-for="variation in getVariationsOptions"
                :key="variation.id"
                :isActive="selectedVariation.id === variation.id"
                :variation="variation"
                @select-changed="updateVariation"
            />
        </div>
    </div>
</template>

<script>
import { isEmpty } from 'global-utils'
import AddToCartFlowManager from '@/StoreWeb/managers/AddToCartFlowManagerSingleton'
import VariationItem from '@/StoreWeb/components/common/VariationItem.vue'

export default {
    components: {
        VariationItem
    },
    props: {
        product: {
            type: Object,
            required: true
        },
        title: {
            type: String,
            default: ''
        },
        variations: {
            type: Array,
            required: true
        }
    },
    data () {
        return {
            addToCartFlowManager: AddToCartFlowManager.getInstance(),
            isVariationEmpty: true,
            selectedVariation: null
        }
    },
    computed: {
        getVariationsOptions () {
            const variations = []
            let item

            this.variations.forEach(variationItem => {
                item = {
                    id: variationItem.id,
                    name: variationItem.name,
                    fare: variationItem.fareIncludingTaxes,
                    isCurrent: false
                }
                variations.push(item)
            })

            if (!isEmpty(variations)) {
                variations[0].isCurrent = true
                this.updateVariation({ selectedId: variations[0].id })
            }
            return variations
        },
        hasVariationsOptions () {
            return !isEmpty(this.getVariationsOptions) && !isEmpty(this.getVariationsOptions[0]) && !isEmpty(this.getVariationsOptions[0].id)
        },
        getSelectedVariationOption () {
            if (!isEmpty(this.variations) && !isEmpty(this.selectedVariation)) {
                const selectedVariationOption = this.variations.find(variation => variation.id === this.selectedVariation.id) || ''
                return selectedVariationOption.id
            }
            return ''
        }
    },
    mounted () {
        if (!isEmpty(this.addToCartFlowManager.currentStep.selectedVariation)) {
            this.selectedVariation = this.addToCartFlowManager.currentStep.selectedVariation
        }
        this.isVariationEmpty = isEmpty(this.selectedVariation)
    },
    methods: {
        updateVariation (value) {
            this.variation = this.variations.find(variation => variation.id === value.selectedId)
            this.selectedVariation = this.variation

            if (!isEmpty(this.variation)) {
                this.$emit('selectedVariationUpdated', this.variation)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.VariationSelector {
    &-list {
        display: inline-flex;
        flex-wrap: wrap;
        gap: 10px;
        overflow: auto;
    }

    &-title {
        margin: 0 0 10px;
        font-size: 18px;
    }
}
</style>
