<template>
    <div class="DropdownMenu">
        <a
            class="DropdownMenu-toggle"
            @click="toggleMenuDisplay"
        >
            <span class="DropdownMenu-toggleIcon" />
            <span class="DropdownMenu-toggleIcon" />
            <span class="DropdownMenu-toggleIcon" />
        </a>
        <div
            v-show="showDropdownMenu"
            v-click-outside="clickOutside"
            :style="`z-index: ${zIndex}; padding-top: ${paddingTop}; padding-bottom: ${paddingBottom};`"
            class="DropdownMenu-content"
        >
            <slot />
        </div>
    </div>
</template>

<script>
import { isOrContainsTarget, setElementPosition } from 'global-utils'

export default {
    props: {
        paddingBottom: {
            type: String,
            default: '10px'
        },
        paddingTop: {
            type: String,
            default: '10px'
        },
        zIndex: {
            type: Number,
            default: 4
        }
    },
    data () {
        return {
            showDropdownMenu: false
        }
    },
    mounted () {
        window.addEventListener('resize', this.hideDropdownMenuContent)
        window.addEventListener('scroll', this.hideDropdownMenuContent, true)
    },
    unmounted () {
        window.removeEventListener('resize', this.hideDropdownMenuContent)
        window.removeEventListener('scroll', this.hideDropdownMenuContent, true)
    },
    methods: {
        clickOutside (event) {
            if (!isOrContainsTarget(event.target, this.$el.querySelector('.DropdownMenu-toggle'))) {
                this.hideDropdownMenuContent()
            }
        },
        hideDropdownMenuContent () {
            this.showDropdownMenu = false
        },
        toggleMenuDisplay () {
            this.showDropdownMenu = !this.showDropdownMenu
            if (this.showDropdownMenu) {
                this.showDropdownMenu = true
                let $content = this.$el.getElementsByClassName('DropdownMenu-content')
                $content = $content[0]
                $content.style.display = ''
                $content.style.position = 'fixed'
                setElementPosition($content, this.$el)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.DropdownMenu {
    position: relative;

    &-toggle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 30px;
        cursor: pointer;

        &Icon {
            width: 3px;
            height: 3px;
            margin: 1px 0;
            border-radius: 3px;
            background: $color-lightText;
        }
    }

    &-content {
        position: fixed;
        z-index: 3;
        top: 30px;
        left: 0;
        overflow: auto;
        border-radius: 6px;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .3);
        background: $color-lightest;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .3s ease-in-out;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
    transition: bottom .6s ease-in-out, opacity .6s ease-in-out;
}

.slide-enter,
.slide-leave-to {
    bottom: -100%;
    opacity: 0;
}
</style>
