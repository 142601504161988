<template>
    <div class="ReducedMobilityProfile">
        <reduced-mobility :configuration="componentConfiguration" />
    </div>
</template>

<script>
import DefaultView from '@/StoreWeb/views/DefaultView'
import PageConfigManager from '@/StoreWeb/managers/PageConfigManagerSingleton'
import cachios from 'cachios'
import { getUserTokenAccessToken } from '@/StoreWeb/js/composables/login-utils'
const pageConfigManager = PageConfigManager.getInstance()

export default {
    extends: DefaultView,
    computed: {
        componentConfiguration () {
            return {
                cachiosBearerToken: this.cachiosBearerToken,
                cachiosApiKey: cachios.axiosInstance.defaults.headers.common['X-API-Key'],
                coreApiUrl: process.env.VUE_APP_CORE_URL
            }
        },
        cachiosBearerToken () {
            return getUserTokenAccessToken()
        }
    },
    mounted () {
        pageConfigManager.setConfig({
            title: 'account:reduced_mobility:title',
            currentAccountPage: 'reducedMobility'
        })
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';
</style>
