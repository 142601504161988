import actions from './actions'
import mutations from './mutations'

export default {
    namespaced: false,
    actions,
    mutations,
    state: {
        accountAssociationPendingRequests: {
            createAccountAssociation: false,
            getAssociation: false,
            getUserAssociations: false,
            getAssociationTypes: false,
            deleteAccountAssociation: false
        },
        accountAssociationTypes: [],
        userAccountAssociations: []
    }
}
