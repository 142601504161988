import Pageable from '@/StoreWeb/models/common/Pageable'
import { isEmpty } from 'global-utils'

export default class {
    normalize (item) {
        const pageable = new Pageable()

        if (!isEmpty(item.offset)) {
            pageable.offset = item.offset
        }
        pageable.paged = item.paged
        pageable.pageNumber = item.pageNumber
        pageable.pageSize = item.pageSize
        pageable.sort = item.sort
        pageable.unpaged = item.unpaged

        return pageable
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
