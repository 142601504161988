export const subTypes = {
    choice: [
        'expanded_list',
        'select'
    ],
    date: [
        'birthdate',
        'date',
        'datetime',
        'time'
    ],
    text: [
        'email',
        'number',
        'password',
        'search',
        'tel',
        'text',
        'textarea',
        'url'
    ],
    boolean: [
        'boolean'
    ]
}

export const displayModes = {
    boolean: [
        'checkbox',
        'switch'
    ],
    choice: [
        'horizontal_list',
        'select',
        'vertical_list'
    ],
    file: [
        'default',
        'illustrated'
    ]
}

export default class {
    constructor () {
        this._autocomplete = ''
        this._displayMode = null
        this._fileMaxSize = 0
        this._fileMimeTypes = null
        this._list = []
        this._dependenciesSchemas = null
        this._maxDate = null
        this._maxLength = null
        this._minDate = null
        this._minLength = null
        this._multiple = false
        this._pattern = null
        this._patternValidationMessage = null
        this._subType = null
        this._validationConditions = null
    }

    static get subTypes () {
        return subTypes
    }

    static get displayModes () {
        return displayModes
    }

    get autocomplete () {
        return this._autocomplete
    }

    set autocomplete (autocomplete) {
        this._autocomplete = autocomplete
    }

    get displayMode () {
        return this._displayMode
    }

    set displayMode (displayMode) {
        this._displayMode = displayMode
    }

    get fileMaxSize () {
        return this._fileMaxSize
    }

    set fileMaxSize (fileMaxSize) {
        this._fileMaxSize = fileMaxSize
    }

    get fileMimeTypes () {
        return this._fileMimeTypes
    }

    set fileMimeTypes (fileMimeTypes) {
        this._fileMimeTypes = fileMimeTypes
    }

    get list () {
        return this._list
    }

    set list (list) {
        this._list = list
    }

    get dependenciesSchemas () {
        return this._dependenciesSchemas
    }

    set dependenciesSchemas (dependenciesSchemas) {
        this._dependenciesSchemas = dependenciesSchemas
    }

    get maxDate () {
        return this._maxDate
    }

    set maxDate (maxDate) {
        this._maxDate = maxDate
    }

    get maxLength () {
        return this._maxLength
    }

    set maxLength (maxLength) {
        this._maxLength = maxLength
    }

    get minDate () {
        return this._minDate
    }

    set minDate (minDate) {
        this._minDate = minDate
    }

    get minLength () {
        return this._minLength
    }

    set minLength (minLength) {
        this._minLength = minLength
    }

    get multiple () {
        return this._multiple
    }

    set multiple (multiple) {
        this._multiple = multiple
    }

    get pattern () {
        return this._pattern
    }

    set pattern (pattern) {
        this._pattern = pattern
    }

    get patternValidationMessage () {
        return this._patternValidationMessage
    }

    set patternValidationMessage (patternValidationMessage) {
        this._patternValidationMessage = patternValidationMessage
    }

    get subType () {
        return this._subType
    }

    set subType (subType) {
        this._subType = subType
    }

    get validationConditions () {
        return this._validationConditions
    }

    set validationConditions (validationConditions) {
        this._validationConditions = validationConditions
    }

    toJSON () {
        return {
            autocomplete: this.autocomplete,
            displayMode: this.displayMode,
            fileMaxSize: this.fileMaxSize,
            fileMimeTypes: this.fileMimeTypes,
            list: this.list,
            listAPI: this.listAPI,
            maxDate: this.maxDate,
            maxLength: this.maxLength,
            minDate: this.minDate,
            minLength: this.minLength,
            multiple: this.multiple,
            pattern: this.pattern,
            patternValidationMessage: this.patternValidationMessage,
            subType: this.subType,
            validationConditions: this.validationConditions
        }
    }
}
