<template>
    <v-switch
        v-model="value"
        inset
        :label="label"
        @update:model-value="change"
    />
</template>

<script>
export default {
    props: {
        defaultValue: {
            type: Boolean,
            default: false
        },
        id: {
            type: String,
            required: true
        },
        isEditable: {
            type: Boolean,
            default: true
        },
        isSmall: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            value: this.defaultValue
        }
    },
    methods: {
        change () {
            this.$emit('toggleSwitchChanged', {
                id: this.id,
                value: this.value
            })
        }
    }
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

.ToggleSwitchWithLabel {
    @include verticalCenter;

    width: auto;

    &-title {
        margin: 0;
        margin-left: $margin-small;
        padding: 0;
        font-family: $fontLato;
        font-size: $font-small;
        font-weight: $fontLato-regular;
        color: $color-black-light;
    }
}
</style>
