<template>
    <form class="AddressForm">
        <p>{{ $t('form:card_holder_address:subtitle') }}</p>
        <labelled-input
            :defaultValue="getField('receiverFirstname')"
            :inputType="'text'"
            :label="$t('form:address:receiver_firstname:label')"
            autocomplete="given-name"
            class="AddressForm-field AddressForm-receiverFirstnameField"
            @field-value-changed="updateField('receiverFirstname', $event)"
        />
        <labelled-input
            :defaultValue="getField('receiverLastname')"
            :inputType="'text'"
            :label="$t('form:address:receiver_lastname:label')"
            autocomplete="family-name"
            class="AddressForm-field AddressForm-receiverLastnameField"
            @field-value-changed="updateField('receiverLastname', $event)"
        />
        <div class="AddressForm-street">
            <labelled-input
                :defaultValue="getField('streetNumber')"
                :inputType="'text'"
                :label="$t('form:address:street_number:label')"
                class="AddressForm-field AddressForm-streetNumberField"
                @field-value-changed="updateField('streetNumber', $event)"
            />
            <labelled-input
                :defaultValue="getField('streetName')"
                :inputType="'text'"
                :label="$t('form:address:street_name:label')"
                autocomplete="address-line1"
                class="AddressForm-field AddressForm-streetNameField"
                @field-value-changed="updateField('streetName', $event)"
            />
        </div>
        <labelled-input
            :defaultValue="getField('streetLine2')"
            :inputType="'text'"
            :label="$t('form:address:details1:label')"
            autocomplete="address-line2"
            class="AddressForm-field AddressForm-stressLine2Field"
            @field-value-changed="updateField('streetLine2', $event)"
        />
        <div class="AddressForm-cityAndZipCode">
            <labelled-input
                :defaultValue="getField('zipCode')"
                :inputType="'text'"
                :label="$t('form:address:zip_code:label')"
                autocomplete="postal-code"
                class="AddressForm-field AddressForm-zipCodeField"
                @field-value-changed="updateField('zipCode', $event)"
            />
            <labelled-input
                :defaultValue="getField('city')"
                :inputType="'text'"
                :label="$t('form:address:city:label')"
                autocomplete="address-level2"
                class="AddressForm-field AddressForm-cityField"
                @field-value-changed="updateField('city', $event)"
            />
        </div>
    </form>
</template>

<script>
import LabelledInput from '@/StoreWeb/components/common/LabelledInput'
import formMixins from '@/StoreWeb/js/mixins/form-mixins'

export default {
    components: {
        LabelledInput
    },
    mixins: [formMixins],
    data () {
        return {
            city: '',
            receiverFirstname: '',
            receiverLastname: '',
            streetLine2: '',
            streetName: '',
            streetNumber: '',
            zipCode: ''
        }
    },
    mounted () {
        if (this.$store.state.debugAutofillCardOrderForms) {
            this.updateStreetName('rue Evariste Galois')
            this.updateStreetNumber('240')
            this.updateStreetLine2('Garden Space B2, 3ème étage, Sophia-Antipolis')
            this.updateReceiverFirstname('Julien')
            this.updateReceiverLastname('Belluco')
            this.updateZipCode('06410')
            this.updateCity('Biot')
        }
    },
    methods: {
        getField (key) {
            return this[key]
        },
        updateField (key, value) {
            this[key] = value
            if (typeof this[`update${key.ucFirst()}Callback`] === 'function') {
                this[`update${key.ucFirst()}Callback`](value)
            } else if (typeof this.updateFieldCallback === 'function') {
                this.updateFieldCallback(value)
            }
        },
        checkFormError () {
            return (
                this.city !== '' &&
                this.receiverFirstname !== '' &&
                this.receiverLastname !== '' &&
                this.streetName !== '' &&
                this.streetNumber !== '' &&
                this.zipCode !== ''
            )
        },
        updateFieldCallback () {
            this.updateProductParameter()
        },
        updateProductParameter () {
            const keys = ['city', 'receiverFirstname', 'receiverLastname', 'streetLine2', 'streetNumber', 'zipCode']
            const values = []
            keys.forEach(key => {
                values.push({
                    productParameter: 'ADDRESS',
                    code: key,
                    value: this.getField(key)
                })
            })
            this.$emit('updateAddressForm', {
                id: 'ADDRESS',
                isProductParameterValid: this.checkFormError(),
                productParameterValues: values,
                isQuotationRequired: false
            })
        }
    }
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

.AddressForm {
    &-field {
        margin-bottom: 15px;
    }

    &-street,
    &-cityAndZipCode {
        display: flex;
    }

    &-street {
        align-items: flex-end;
    }

    &-streetNumberField,
    &-zipCodeField {
        margin-right: 15px;
    }

    &-streetNumberField {
        flex-basis: 30%;
    }

    &-streetNameField {
        flex-grow: 1;
    }

    &-zipCodeField {
        flex-basis: 45%;
    }
}
</style>
