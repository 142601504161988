import HistoryBasketExternalStatus from '@/StoreWeb/models/user/order/HistoryBasketExternalStatus'
import { isEmpty } from 'global-utils'

export default class {
    normalize (item) {
        const historyBasketExternalStatus = new HistoryBasketExternalStatus()

        historyBasketExternalStatus.externalStatus = item.externalStatus
        historyBasketExternalStatus.invoiceId = item.invoiceId
        historyBasketExternalStatus.lastUpdate = item.lastUpdate
        historyBasketExternalStatus.providerOrderId = item.providerOrderId

        return historyBasketExternalStatus
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
