<template>
    <div class="ProductVanishModal">
        <modal
            v-model="showModal"
            :title="$t('product_vanish_modal:title')"
        >
            <div class="ProductVanishModal-body">
                <p class="ProductVanishModal-explanations mb-2">
                    {{ $t('product_vanish_modal:explanations') }}
                </p>
                <v-btn
                    v-if="getAllWalletFareMedias.length > 1"
                    type="submit"
                    size="large"
                    class="bg-primary"
                    @click.prevent="chooseAnotherSupport"
                >
                    {{ $t('product_vanish_modal:choose_another_support_button') }}
                </v-btn>
                <p
                    v-if="getAllWalletFareMedias.length > 1"
                    class="ProductVanishModal-or"
                >
                    {{ $t('miscellaneous:or') }}
                </p>
                <v-btn
                    type="submit"
                    size="large"
                    class="bg-primary"
                    @click.prevent="chooseAnotherProduct"
                >
                    {{ $t('product_vanish_modal:continue_with_compatible_product_button') }}
                </v-btn>
            </div>
        </modal>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import emitter from 'global-emitter'
import { isEmpty } from 'global-utils'
import * as userMutationTypes from '@/StoreWeb/store/modules/user/mutation-types'
import * as cartMutationTypes from '@/StoreWeb/store/modules/cart/mutation-types'

const store = useStore()

const context = ref(null)
const showModal = ref(false)

const getAllWalletFareMedias = computed(() => store.getters.getAllWalletFareMedias)

onMounted(() => {
    emitter.on('showProductVanishModal', showProductVanishModalHandler)
})

onUnmounted(() => {
    emitter.off('showProductVanishModal', showProductVanishModalHandler)
})

function closeModal () {
    document.body.classList.remove('body--withModalOpened')
    showModal.value = false
}

function chooseAnotherProduct () {
    store.commit(cartMutationTypes.SET_TEMP_ADD_CART_FLOW, null)
    const fareMediaToSelect = getAllWalletFareMedias.value.find(fareMedia => (
        fareMedia.id === context.value.supportToExclude.fareMediaId &&
    fareMedia.providerId === context.value.supportToExclude.providerId
    ))

    if (!isEmpty(fareMediaToSelect)) {
        store.commit(userMutationTypes.SET_SELECTED_SUPPORT, fareMediaToSelect)
        emitter.emit('selectedSupportChanged')
    }

    closeModal()
}

function chooseAnotherSupport () {
    emitter.emit('continueAddToCartFlowWithAnotherSupport', context.value)
    closeModal()
}

function showProductVanishModalHandler (ctx) {
    showModal.value = true
    context.value = ctx
    emitter.emit('hideProductModal')
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.ProductVanishModal {
    &-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &-or {
        margin: 0;
        text-align: center;
        text-transform: uppercase;
    }

    &-or,
    &-chooseAnotherSupportButton {
        margin-bottom: 15px;
    }
}
</style>
