export default class {
    constructor (
        id
    ) {
        this._id = id
        this._label = ''
        this._startValidityDate = ''
        this._endValidityDate = ''
        this._status = []
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get label () {
        return this._label
    }

    set label (label) {
        this._label = label
    }

    get startValidityDate () {
        return this._startValidityDate
    }

    set startValidityDate (startValidityDate) {
        this._startValidityDate = startValidityDate
    }

    get endValidityDate () {
        return this._endValidityDate
    }

    set endValidityDate (endValidityDate) {
        this._endValidityDate = endValidityDate
    }

    get status () {
        return this._status
    }

    set status (status) {
        this._status = status
    }

    toJSON () {
        return {
            id: this.id,
            label: this.label,
            startValidityDate: this.startValidityDate,
            endValidityDate: this.endValidityDate,
            status: this.status
        }
    }
}
