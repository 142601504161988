import * as actionTypes from './action-types'
import AddressManager from '@/StoreWeb/managers/AddressManagerSingleton'
const addressManager = AddressManager.getInstance()

export default {
    /**
     * Create an address
     * @param params
     */
    [actionTypes.CREATE_ADDRESS] (...args) {
        const [, params] = args
        return addressManager.createAddressAction(params)
    },
    /**
     * Delete an address
     * @param params
     */
    [actionTypes.DELETE_ADDRESS] (...args) {
        const [, params] = args
        addressManager.deleteAddressAction(params)
    },
    /**
     * Get an address by id
     * @param params
     */
    [actionTypes.GET_ADDRESS] (...args) {
        const [, params] = args
        addressManager.getAddressAction(params)
    },
    /**
     * Get user's addresses
     * @param params
     */
    [actionTypes.GET_ADDRESSES] (...args) {
        const [, params] = args
        return addressManager.getAddressesAction(params)
    },
    /**
     * Get shop addresses
     * @param params
     */
    [actionTypes.GET_SHOP_ADDRESSES] (...args) {
        const [, params] = args
        return addressManager.getShopAddressesAction(params)
    },
    /**
     * Get address templates
     * @param params
     */
    [actionTypes.GET_ADDRESS_TEMPLATES] (...args) {
        const [, params] = args
        return addressManager.getAddressTemplatesAction(params)
    },
    /**
     * Set an address as default
     * @param params
     */
    [actionTypes.SET_ADDRESS_AS_DEFAULT] (...args) {
        const [, params] = args
        addressManager.setAddressAsDefaultAction(params)
    },
    /**
     * Update an address
     * @param params
     */
    [actionTypes.UPDATE_ADDRESS] (...args) {
        const [, params] = args
        return addressManager.updateAddressAction(params)
    }
}
