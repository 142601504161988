<template>
    <ul
        :class="navClasses"
        class="AccountNav"
    >
        <li
            v-for="(group, groupIndex) in accountNav"
            :key="groupIndex"
            :class="[group.label === null ? 'AccountNav-group--withoutLabel' : '']"
            class="AccountNav-group"
        >
            {{ group.label !== null ? group.label : '' }}
            <ul class="AccountNav-list">
                <account-nav-item
                    v-for="(link, index) in group.links"
                    v-bind="link"
                    :key="index"
                    class="AccountNav-navItem"
                />
            </ul>
        </li>
    </ul>
</template>

<script setup>
import { computed } from 'vue'
import AccountNavItem from './AccountNavItem'
import config from 'config'

const props = defineProps({
    accountNav: {
        type: Array,
        required: true
    },
    inNavBar: {
        type: Boolean,
        default: true
    }
})

const navClasses = computed(() => {
    return {
        'AccountNav-nav': props.inNavBar,
        'AccountNav-nav--sytral': props.inNavBar && config.features.modal_auth === 'sytral'
    }
})

</script>

<style lang="scss" scoped>
@import 'globalScss';
@import 'commonCustomizationScss';

.AccountNav {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: left;

    &-nav {
        max-height: calc(100vh - rem-calc(60) - $s5);
        overflow-y: auto;

        &--sytral {
            max-height: calc(100vh - 68px - $s5);

            @media (min-width: $desktop-breakpoint) {
                max-height: calc(100vh - 86px - $s5);
            }
        }
    }

    &-groupList,
    &-list {
        margin: 0;
        padding: 6px 0 0;
        list-style: none;
    }

    &-group {
        padding: 25px 0 15px;
        border-top: 1px solid $color-lightgray3;
        font-size: 14px;
        font-weight: $fontWeight-defaultLight;
        color: $color-lightText;

        &--withoutLabel {
            padding-top: 10px;
        }

        &:first-child {
            padding-top: 0;
            border-top: none;

            .AccountNav-list {
                padding-top: 0;
            }
        }
    }

    &-item {
        margin: 0;
    }
}
</style>
