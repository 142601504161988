<template>
    <div>
        <label
            :for="labelFor"
            class="SalesTerms"
        >
            <span class="SalesTerms-container">
                <input
                    :id="labelFor"
                    alt="checkbox"
                    :checked="isChecked"
                    :name="name"
                    :readonly="isReadonly"
                    :required="isRequired"
                    class="SalesTerms-input"
                    type="checkbox"
                    @change="checkboxChange"
                >
                <span class="SalesTerms-icon IconCheck" />
            </span>
            <span class="SalesTerms-label">
                {{ $t('sales_terms:approve_message') }}
            </span>
        </label>
        <div class="SalesTerms-link">
            <a
                :href="sanitizeUrl(getLink)"
                class="d-flex align-center"
                target="_blank"
            >
                {{ $t('sales_terms:consult_message') }}
                <v-icon
                    :aria-label="$t('open_new_tab')"
                    :aria-hidden="false"
                    class="pl-1"
                    color="primary"
                    size="medium"
                >
                    mdi-open-in-new
                </v-icon>
            </a>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, defineProps, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { sanitizeUrl } from '@braintree/sanitize-url'

const emit = defineEmits(['salesTermsApprovalChanged'])

const props = defineProps({
    isChecked: {
        type: Boolean,
        default: false
    },
    labelFor: {
        type: String,
        required: true
    },
    name: {
        type: String,
        required: true
    },
    isReadonly: {
        type: Boolean,
        default: false
    },
    isRequired: {
        type: Boolean,
        default: false
    }
})

const { t } = useI18n()

const isChecked = ref(props.isChecked)

const getLink = computed(() => {
    /* TODO : Sales terms link has to be managed differently as in store, it may have several ticketing providers,
    each one with different sales terms, so we will possibly have several links, depending on cart content.
    Moreover, depending on network, the link could be an internal or external link, so it will be either a <a>
    tag or a <router-link> tag */
    return t('sales_terms:link')
})

onMounted(() => {
    emit('salesTermsApprovalChanged', isChecked.value)
})

function checkboxChange (event) {
    isChecked.value = event.target.checked
    emit('salesTermsApprovalChanged', event.target.checked)
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

.SalesTerms {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 5px;
    cursor: pointer;

    &-container {
        position: relative;
        overflow: hidden;
        min-width: 19px;
        max-width: 19px;
        min-height: 19px;
        max-height: 19px;
        margin-right: 10px;
        border: solid 1px $color-lightgray3;
        border-radius: 2px;
        background-color: $color-lightest;
    }

    &-icon {
        font-size: 18px;
        color: $color-brandPrimary;
        opacity: 0;
        transition: opacity .2s;
    }

    &-input {
        position: absolute;
        top: -9999px;
        left: -9999px;

        &:checked ~ .SalesTerms-icon {
            opacity: 1;
        }
    }

    &-label {
        flex-grow: 1;
        font-size: 14px;
    }

    &-link {
        padding-left: 29px;
        cursor: pointer;
        font-size: 14px;
        color: $color-brandPrimary;
        transition: all .3s;

        &:hover,
        &:focus {
            color: $color-brandPrimaryDark;
        }
    }
}
</style>
