<template>
    <div class="PersonalData">
        <alert-message
            v-if="getAlertMessage"
            :message="getAlertMessage"
            class="PersonalData-errorMessage mb-2"
            type="error"
        />
        <v-card class="mb-5">
            <v-card-title>
                {{ $t('account:consents') }}
            </v-card-title>
            <v-card-text>
                {{ $t('account:consents:modify') }}
            </v-card-text>
            <v-card-actions class="justify-center px-4 pb-4">
                <v-btn
                    color="primary"
                    variant="flat"
                    @click="displayConsents"
                >
                    {{ $t('button:edit') }}
                </v-btn>
            </v-card-actions>
            <iframe
                v-if="consentsIframeUrl"
                :src="consentsIframeUrl"
                frameborder="0"
                allowfullscreen
            />
        </v-card>

        <v-card class="mb-5">
            <v-card-title>
                {{ $t('account:personal_data:title') }}
            </v-card-title>
            <v-card-text>
                {{ $t('account:personal_data:description') }}
            </v-card-text>
            <v-card-actions class="justify-center px-4 pb-4">
                <v-btn
                    color="primary"
                    variant="flat"
                    @click="setShowUserDataClaimModal"
                >
                    {{ $t('account:personal_datas:retreive_my_infos') }}
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-card class="mb-5">
            <v-card-title>
                {{ $t('account:deactivate:confirm_modal_title') }}
            </v-card-title>
            <v-card-text>
                {{ $t('account:deactivate:confirm_modal_description2') }}
            </v-card-text>
            <v-card-actions class="justify-center px-4 pb-4">
                <v-btn
                    color="primary"
                    variant="flat"
                    @click="confirmDeletion(false)"
                >
                    {{ $t('account:deactivate:confirm_modal_delete_button') }}
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-card class="mb-5">
            <v-card-title>
                {{ $t('account:deactivate_with_data:confirm_modal_title') }}
            </v-card-title>
            <v-card-text>
                {{ $t('account:deactivate_with_data:confirm_modal_description') }}
            </v-card-text>
            <v-card-actions class="justify-center px-4 pb-4">
                <v-btn
                    color="primary"
                    variant="flat"
                    @click="confirmDeletion(true)"
                >
                    {{ $t('account:deactivate:confirm_modal_delete_withdatas_button') }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <modal
            v-model="showConfirmDeletionModal"
            :title="$t('account:deactivate:confirm_modal_title')"
        >
            <v-card class="pa-6">
                <p
                    class="text-justify"
                    v-html="$sanitize(getFormattedDescription($t('account:deactivate:re_confirm_modal_description')))"
                />
                <div class="PersonalData-deleteModalButtonAreaWrapper">
                    <div class="PersonalData-deleteModalButtonArea">
                        <v-btn
                            color="primary"
                            class="PersonalData-deleteModalConfirmWithDatasButton"
                            @click="deleteAccount"
                        >
                            {{ $t('account:deactivate:confirm_modal_delete_button') }}
                        </v-btn>
                        <v-btn
                            variant="text"
                            @click="closeConfirmDeletionModal"
                        >
                            {{ $t('account:deactivate:confirm_modal_cancel') }}
                        </v-btn>
                    </div>
                </div>
            </v-card>
        </modal>
        <modal
            v-model="showConfirmDeletionModalWithDatas"
            :title="$t('account:deactivate_with_data:confirm_modal_title')"
        >
            <v-card class="pa-6">
                <p
                    class="text-justify"
                    v-html="$sanitize(getFormattedDescription($t('account:deactivate:re_confirm_modal_description_with_datas')))"
                />
                <div class="PersonalData-deleteModalButtonAreaWrapper">
                    <div class="PersonalData-deleteModalButtonArea">
                        <v-btn
                            color="primary"
                            class="PersonalData-deleteModalConfirmWithDatasButton"
                            @click="deleteAccount"
                        >
                            {{ $t('account:deactivate:re_confirm_modal_delete_withdatas_button') }}
                        </v-btn>
                        <v-btn
                            variant="text"
                            @click="closeConfirmDeletionModalWithDatas"
                        >
                            {{ $t('account:deactivate:confirm_modal_cancel') }}
                        </v-btn>
                    </div>
                </div>
            </v-card>
        </modal>
        <modal
            v-model="showUserDataClaimModal"
            :subtitle="$t('account:personal_datas:modal_content')"
            :title="$t('account:personal_datas:retreive_my_infos')"
        >
            <template #actions>
                <v-btn
                    variant="text"
                    @click="closeUserDataClaimModal"
                >
                    {{ $t('button:cancel') }}
                </v-btn>
                <v-btn
                    class="bg-primary ml-2"
                    @click="userDataClaim"
                >
                    {{ $t('account:personal_datas:modal_confirm_button') }}
                </v-btn>
            </template>
        </modal>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import PageConfigManager from '@/StoreWeb/managers/PageConfigManagerSingleton'
import AlertMessage from '@/StoreWeb/components/common/AlertMessage.vue'
import * as userActionTypes from '@/StoreWeb/store/modules/user/action-types'
import * as loginActionTypes from 'user/Login/store/action-types'
import config from 'config'

const store = useStore()
const { t } = useI18n()
const pageConfigManager = PageConfigManager.getInstance()

const showConfirmDeletionModal = ref(false)
const showConfirmDeletionModalWithDatas = ref(false)
const isPersonalDataToDelete = ref(false)
const showDeleteAccountModal = ref(false)
const showUserDataClaimModal = ref(false)
const errorMessage = ref(null)
const consentsIframeUrl = ref('')

const getAlertMessage = computed(() => errorMessage.value)

onMounted(() => {
    pageConfigManager.setConfig({
        title: 'account:personal_data:title',
        currentAccountPage: 'personalData'
    })
})

function userDataClaim () {
    closeUserDataClaimModal()
    store.dispatch(userActionTypes.USER_DATA_CLAIM)
}

function confirmDeletion (isPersonalDataToDeleteVal) {
    isPersonalDataToDelete.value = isPersonalDataToDeleteVal
    if (isPersonalDataToDeleteVal === false) {
        setShowConfirmDeletionModal()
    } else {
        setShowConfirmDeletionModalWithDatas()
    }
}

async function deleteAccount () {
    closeDeleteAccountModal()
    const params = {
        isPersonalDataToDelete: isPersonalDataToDelete.value
    }
    await store.dispatch(userActionTypes.DELETE_ACCOUNT, params).then(async () => {
        if (config.use_web_harmony) {
            document.body.dispatchEvent(new CustomEvent('kas-logout'))
        } else {
            await store.dispatch(`login/${loginActionTypes.LOGOUT}`)
            closeConfirmDeletionModalWithDatas()
            closeConfirmDeletionModal()
        }
    })
}

function displayConsents () {
    store.dispatch(userActionTypes.GET_CONSENTS).then((response) => {
        errorMessage.value = null
        consentsIframeUrl.value = response.url
    }).catch(() => {
        errorMessage.value = t('account:consents:get_consents:error')
    })
}

function getFormattedDescription (description) {
    return description.replace(/\\n/g, '<br>')
}

function setShowUserDataClaimModal () {
    showUserDataClaimModal.value = true
}

function setShowConfirmDeletionModal () {
    showConfirmDeletionModal.value = true
}

function setShowConfirmDeletionModalWithDatas () {
    showConfirmDeletionModalWithDatas.value = true
}

function closeConfirmDeletionModal () {
    showConfirmDeletionModal.value = false
}

function closeConfirmDeletionModalWithDatas () {
    showConfirmDeletionModalWithDatas.value = false
}

function closeDeleteAccountModal () {
    showDeleteAccountModal.value = false
}

function closeUserDataClaimModal () {
    showUserDataClaimModal.value = false
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.PersonalData {
    max-width: 750px;

    &-sectionActionButton {
        position: absolute;
        top: 4px;
        right: 4px;
    }

    iframe {
        width: 100%;
        height: 500px;
        margin-top: 20px;
    }

    &-deactivateAccountLink {
        cursor: pointer;
        font-weight: 500;
        color: $color-lighterText;
        transition: all .3s;

        &:hover,
        &:active {
            color: $color-lightText;
        }
    }

    &-deleteModalButtonAreaWrapper {
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }

    &-deleteModalButtonArea {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        gap: 15px;

        .v-btn {
            margin: 0;
        }
    }
}
</style>
