export default class {
    constructor (id) {
        this._fromName = ''
        this._id = id
        this._interfaceName = ''
        this._isExternal = false
        this._modes = []
        this._name = ''
        this._toName = ''
    }

    get fromName () {
        return this._fromName
    }

    set fromName (fromName) {
        this._fromName = fromName
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get interfaceName () {
        return this._interfaceName
    }

    set interfaceName (interfaceName) {
        this._interfaceName = interfaceName
    }

    get isExternal () {
        return this._isExternal
    }

    set isExternal (isExternal) {
        this._isExternal = isExternal
    }

    get modes () {
        return this._modes
    }

    set modes (modes) {
        this._modes = modes
    }

    get name () {
        return this._name
    }

    set name (name) {
        this._name = name
    }

    get toName () {
        return this._toName
    }

    set toName (toName) {
        this._toName = toName
    }

    toJSON () {
        return {
            fromName: this.fromName,
            id: this.id,
            interfaceName: this.interfaceName,
            isExternal: this.isExternal,
            modes: this.modes,
            name: this.name,
            toName: this.toName
        }
    }
}
