import CatalogProductDocument from '@/StoreWeb/models/catalog/CatalogProductDocument'
import IdPatternNormalizer from '@/StoreWeb/normalizers/patterns/IdPatternNormalizerSingleton'
import { isEmpty } from 'global-utils'

export default class {
    constructor () {
        this._idPatternNormalizer = IdPatternNormalizer.getInstance()
    }

    get idPatternNormalizer () {
        return this._idPatternNormalizer
    }

    normalize (item) {
        const document = new CatalogProductDocument(item.id)

        document.cropEnabled = item.cropEnabled ?? false
        document.description = item.description ?? ''
        document.externalId = item.externalId ?? ''
        if (!isEmpty(item.size)) {
            document.maxSize = item.size
        }
        if (!isEmpty(item.supportedTypes)) {
            document.mimeTypes = this.setMimeTypes(item.supportedTypes)
        }
        document.name = item.name
        if (!isEmpty(item.provider)) {
            document.provider = this.idPatternNormalizer.normalize(item.provider)
        }
        document.supportedTypes = item.supportedTypes ?? []

        return document
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }

    setMimeTypes (supportedTypes) {
        const mimeTypes = []
        supportedTypes.forEach(supportedType => {
            switch (supportedType) {
                case 'BMP':
                    mimeTypes.push('image/bmp')
                    break
                case 'GIF':
                    mimeTypes.push('image/gif')
                    break
                case 'JPEG':
                case 'JPG':
                    mimeTypes.push('image/jpeg')
                    break
                case 'PDF':
                    mimeTypes.push('application/pdf')
                    break
                case 'PNG':
                    mimeTypes.push('image/png')
                    break
                case 'TIF':
                case 'TIFF':
                    mimeTypes.push('image/tiff')
                    break
                case 'WEBP':
                    mimeTypes.push('image/webp')
                    break
            }
        })
        return mimeTypes
    }
}
