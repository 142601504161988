import * as actionTypes from './action-types'
import UserManager from '@/StoreWeb/managers/UserManagerSingleton'
const userManager = UserManager.getInstance()

export default {
    /**
     * Activate account
     * @param args
     */
    [actionTypes.ACTIVATE_ACCOUNT] (...args) {
        const [, params] = args
        userManager.activateAccountAction(params)
    },
    /**
     * Deactivate account
     * @param args
     */
    [actionTypes.DEACTIVATE_ACCOUNT] (...args) {
        const [, params] = args
        userManager.deactivateAccountAction(params)
    },
    /**
     * Delete account
     * @param args
     */
    [actionTypes.DELETE_ACCOUNT] (...args) {
        const [, params] = args
        return userManager.deleteAccountAction(params)
    },
    /**
     * User data claim
     * @param args
     */
    [actionTypes.USER_DATA_CLAIM] (...args) {
        const [, params] = args
        userManager.userDataClaimAction(params)
    },
    /**
     * Get order certificate
     * @param args
     */
    [actionTypes.GET_CERTIFICATE] (...args) {
        const [, params] = args
        userManager.getCertificateAction(params)
    },
    /**
     * Download fare media certificate
     * @param args
     */
    async [actionTypes.DOWNLOAD_FARE_MEDIA_CERTIFICATE] (...args) {
        const [, params] = args
        await userManager.downloadFareMediaCertificate(params)
    },
    /**
     * Get direct debits
     * @param args
     */
    [actionTypes.GET_DIRECT_DEBITS] (...args) {
        const [, params] = args
        userManager.getDirectDebits(params)
    },
    /**
     * Get order invoice
     * @param args
     */
    [actionTypes.GET_INVOICE] (...args) {
        const [, params] = args
        userManager.getInvoiceAction(params)
    },
    /**
     * Get notifications
     * @param args
     */
    [actionTypes.GET_NOTIFICATIONS] (...args) {
        const [, params] = args
        userManager.getNotificationsAction(params)
    },
    /**
     * Get order
     * @param args
     */
    [actionTypes.GET_ORDER] (...args) {
        const [, params] = args
        userManager.getOrderAction(params)
    },
    /**
     * Get orders
     * @param args
     */
    [actionTypes.GET_ORDERS] (...args) {
        const [, params] = args
        userManager.getOrdersAction(params)
    },
    /**
     * Get personal info
     * @param args
     */
    [actionTypes.GET_PERSONAL_INFO] (...args) {
        const [, params] = args
        return userManager.getPersonalInfoAction(params)
    },
    /**
     * Get wallet's fare medias
     * @param args
     */
    [actionTypes.GET_WALLET] (...args) {
        const [, params] = args
        return userManager.getWalletAction(params)
    },
    /**
     * Link fare media
     * @param args
     */
    [actionTypes.LINK_FARE_MEDIA] (...args) {
        const [, params] = args
        return userManager.linkFareMediaAction(params)
    },
    /**
     * Set email
     * @param args
     */
    async [actionTypes.SET_EMAIL] (...args) {
        const [, params] = args
        return userManager.setEmailAction(params)
    },
    /**
     * Mark notification as read
     * @param args
     */
    [actionTypes.SET_NOTIFICATION_AS_READ] (...args) {
        const [, params] = args
        userManager.setNotificationAsReadAction(params)
    },
    /**
     * Set personal info
     * @param args
     */
    async [actionTypes.SET_PERSONAL_INFO] (...args) {
        const [, params] = args
        return userManager.setPersonalInfoAction(params)
    },
    /**
     * Set phone
     * @param args
     */
    async [actionTypes.SET_PHONE] (...args) {
        const [, params] = args
        return userManager.setPhoneAction(params)
    },
    /**
     * Terminate plan
     * @param args
     */
    [actionTypes.TERMINATE_PLAN] (...args) {
        const [, params] = args
        userManager.terminatePlanAction(params)
    },
    /**
     * Update user personal information
     * @param args
     */
    [actionTypes.UPDATE_PERSONAL_INFO] (...args) {
        const [, params] = args
        userManager.updatePersonalInfoAction(params)
    },
    /**
     * Unlink fare media
     * @param args
     */
    [actionTypes.UNLINK_FARE_MEDIA] (...args) {
        const [, params] = args
        userManager.unlinkFareMediaAction(params)
    },
    /**
     * Subscribe a new account
     * @param args
     */
    [actionTypes.SET_NEW_ACCOUNT] (...args) {
        const [, params] = args
        userManager.setNewSubscriptionAccountAction(params)
    },
    /**
     * Subscribe a new third account
     * @param args
     */
    [actionTypes.SET_NEW_THIRD_ACCOUNT] (...args) {
        const [, params] = args
        userManager.setNewThirdAccountAction(params)
    },
    /**
     * get profiles account
     * @params params
     */
    [actionTypes.GET_NEW_PROFILE_ACCOUNT] (...args) {
        const [, params] = args
        return userManager.getNewProfileAccountAction(params)
    },
    /**
     * Set new profile account
     * @params params
     */
    [actionTypes.SET_NEW_PROFILE_ACCOUNT] (...args) {
        const [, params] = args
        return userManager.setNewProfileAccountAction(params)
    },
    /**
     * Get consents
     * @param args
     */
    [actionTypes.GET_CONSENTS] (...args) {
        const [, params] = args
        return userManager.getConsentsAction(params)
    },
    /**
     * Create provider account
     * @param args
     */
    [actionTypes.CREATE_PROVIDER_ACCOUNT] (...args) {
        const [, params] = args
        return userManager.createProviderAccountAction(params)
    },
    /**
     * Execute action on wallet contract
     * @param args
     */
    [actionTypes.EXECUTE_ACTION_ON_WALLET_CONTRACT] (...args) {
        const [, params] = args
        return userManager.executeActionOnWalletContract(params)
    }
}
