<template>
    <component
        :is="'li'"
        class="AssociatedAccountListItemChoice"
    >
        <ul
            v-if="getShowFareMedias"
            class="AssociatedAccountListItemChoice-list AssociatedAccountListItemChoice-fareMediaList"
        >
            <fare-media-choice-item
                v-for="fareMediaChoiceItem in getFareMedias"
                v-bind="fareMediaChoiceItem"
                :key="fareMediaChoiceItem.id"
                class="FareMediaChoiceModal-item"
                @fare-media-selected="updateSelectedFareMedia"
            />
        </ul>
    </component>
</template>

<script setup>
import { computed } from 'vue'
import FareMediaChoiceItem from './FareMediaChoiceItem'
import { useStore } from 'vuex'
import FareMedia from '@/StoreWeb/models/user/wallet/FareMedia'

const store = useStore()

const props = defineProps({
    associatedAccount: {
        type: Object,
        required: true
    },
    displayedListType: {
        type: String,
        default: 'ALL',
        validator: (val) => ['ALL', 'COUNTERS', 'FAREMEDIAS', 'MTICKETS', 'PLANS', 'SUPPORTS'].includes(val)
    }
})

const emit = defineEmits(['associatedFareMediaChosen'])

const selectedFareMedia = computed(() => store.state.userModule.selectedFareMedia)
const getFareMedias = computed(() => {
    const fareMedias = []
    const associatedFareMedias = props.associatedAccount.getFareMedias()
    associatedFareMedias.forEach(fareMediaItem => {
        if (FareMedia.activeStatuses.includes(fareMediaItem.status.id)) {
            fareMedias.push({
                accountId: fareMediaItem.id,
                icon: fareMediaItem.getIcon(),
                id: fareMediaItem.id,
                isCurrent: selectedFareMedia.value && selectedFareMedia.value.id === fareMediaItem.id && selectedFareMedia.value.providerUserExternalId === fareMediaItem.providerUserExternalId,
                itemData: {
                    fareMediaId: fareMediaItem.id,
                    isTemporary: fareMediaItem.isTemporary,
                    providerId: fareMediaItem.providerId,
                    providerUserExternalId: fareMediaItem.providerUserExternalId,
                    providerUserId: fareMediaItem.providerUserId,
                    status: fareMediaItem?.status?.id
                },
                providerId: fareMediaItem.id,
                subtitle: fareMediaItem.getSubtitle(),
                title: fareMediaItem.getTitle()
            })
        }
    })

    return fareMedias
})
const getShowFareMedias = computed(() => props.displayedListType === 'ALL' || props.displayedListType === 'FAREMEDIAS')

function updateSelectedFareMedia (fareMediaId) {
    emit('associatedFareMediaChosen', fareMediaId)
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.AssociatedAccountListItemChoice {
    margin-bottom: 30px;
    list-style: none;

    &:last-child {
        margin-bottom: 0;
    }

    &-list {
        margin: 0 0 25px;
        padding: 0;
        list-style: none;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>
