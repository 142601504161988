import AddressManager from '@/StoreWeb/managers/AddressManagerSingleton'
const addressManager = AddressManager.getInstance()

export default {
    getAddresses (state) {
        return addressManager.getAddresses(state)
    },
    getShopAddresses (state) {
        return addressManager.getShopAddresses(state)
    },
    getAddressesCount (state) {
        return addressManager.getAddressesCount(state)
    },
    getAddressTemplates (state) {
        return addressManager.getTemplates(state)
    },
    getAddAddressEnabled (state) {
        return addressManager.getAddAddressEnabled(state)
    },
    getRemoveAddressEnabled (state) {
        return addressManager.getRemoveAddressEnabled(state)
    }
}
