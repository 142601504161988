/**
 * This script is used to manage the dependency between the fields of a form
 * using the schema described in a template
 *
 * @TODO: Adapt to the new dependencies between fields that will be created in the templates
 */

import cachios from 'cachios'
import { isEmpty } from '@/StoreWeb/utils/global-utils'
import { reactive, ref } from 'vue'
import cloneDeep from 'lodash.clonedeep'

const selectedTemplate = ref(null)
let formValues = reactive(null)
let initialTemplate = null
let v$ = null

/**
 * Used to initialise script variables and form from template
 * @param formFieldValues
 * @param template
 * @param vuelidate
 */
export function initFieldsDependenciesFromTemplate (formFieldValues, template, vuelidate = null) {
    selectedTemplate.value = template
    if (!initialTemplate) {
        initialTemplate = cloneDeep(template)
    }
    formValues = formFieldValues
    v$ = vuelidate

    browseEachTemplateFieldAndApplyDependenciesSchemas()
}

function browseEachTemplateFieldAndApplyDependenciesSchemas () {
    if (!selectedTemplate.value) {
        return
    }

    selectedTemplate.value.forEach(field => {
        browseAndApplyDependenciesSchemas(field, true)
    })
}

/**
 * Checks whether the given field has a dependency schema with (in other words, an impact on) another field,
 * and if so, applies it.
 * Example: the city is determined from the postcode entered in the zipCode field using an API.
 * @param field
 * @param keepFieldValueOnReset
 */
export function browseAndApplyDependenciesSchemas (field, keepFieldValueOnReset = false) {
    if (!field?.params?.dependenciesSchemas || field.params.dependenciesSchemas.length === 0) {
        return
    }
    field.params.dependenciesSchemas.forEach(schema => {
        applyDependencySchema(formValues.value[field.id], schema, keepFieldValueOnReset)
    })
}

async function applyDependencySchema (fieldValue, schema, keepFieldValueOnReset = false) {
    if (schema.minChars && fieldValue.length < schema.minChars) {
        schema.fields.forEach(field => {
            const fieldIndexInTemplate = selectedTemplate.value.findIndex((item) => item.id === field.id)
            resetImpactedField(fieldIndexInTemplate, field, keepFieldValueOnReset)
        })
        return
    }
    if (schema.type === 'CALL_INTERNAL_API' && schema.apiUrl) {
        await applyInternalCallApiSchema(fieldValue, schema, keepFieldValueOnReset)
    }
}

async function applyInternalCallApiSchema (fieldValue, schema, keepFieldValueOnReset = false) {
    const params = {}
    if (schema.authorization) {
        params.headers = {
            Authorization: 'Bearer ' + cachios.axiosInstance.defaults.headers.common.Authorization,
            'X-API-Key': process.env.VUE_APP_API_KEY
        }
    }
    let data = null
    let url = schema.apiUrl
    if (schema.queryParamName) {
        url += '?' + new URLSearchParams(schema.queryParamName + '=' + fieldValue)
    }
    const response = await fetch(url, params).catch(() => null)
    if (response?.ok) {
        data = await response.json()
    }
    if (!schema?.fields) {
        return
    }
    schema.fields.forEach(field => {
        applyInternalCallApiSchemaOnImpactedField(field, data, keepFieldValueOnReset)
    })
}

function applyInternalCallApiSchemaOnImpactedField (field, responseApiData, keepFieldValueOnReset = false) {
    const fieldIndexInTemplate = selectedTemplate.value.findIndex((item) => item.id === field.id)
    if (!fieldIndexInTemplate) {
        return
    }

    if (isEmpty(responseApiData)) {
        resetImpactedField(fieldIndexInTemplate, field, keepFieldValueOnReset)
        return
    }

    selectedTemplate.value[fieldIndexInTemplate].type = field.type
    selectedTemplate.value[fieldIndexInTemplate].params.subType = field.subType

    if (field.type === 'choice' && field.subType === 'select') {
        const listItemLabel = field.itemLabel ?? 'label'
        const listItemValue = field.itemValue ?? 'value'
        const list = responseApiData.map(item => {
            return {
                name: item[listItemLabel],
                id: item[listItemValue]
            }
        })

        if (list) {
            selectedTemplate.value[fieldIndexInTemplate].params.list = list

            let listIndex = -1
            if (formValues.value[field.id]) {
                listIndex = list.findIndex((item) => {
                    if (typeof item.id === 'string' && typeof formValues.value[field.id] === 'string') {
                        return item.id.toUpperCase() === formValues.value[field.id].toUpperCase()
                    }
                    return item.id === formValues.value[field.id]
                })
            }

            formValues.value[field.id] = listIndex >= 0 ? list[listIndex].id : null
            v$.value[field.id].$reset()
        }
    }
}

function resetImpactedField (fieldIndexInTemplate, field, keepFieldValueOnReset = false) {
    const fieldInTemplate = selectedTemplate.value[fieldIndexInTemplate]
    fieldInTemplate.type = initialTemplate[fieldIndexInTemplate].type
    fieldInTemplate.params.subType = initialTemplate[fieldIndexInTemplate].params.subType
    formValues.value[field.id] = (keepFieldValueOnReset) ? formValues.value[field.id] : ''

    v$.value[field.id].$reset()

    if (initialTemplate[fieldIndexInTemplate].params.list) {
        fieldInTemplate.params.list = initialTemplate[fieldIndexInTemplate].params.list
        return
    }
    fieldInTemplate.params.list = []
}
