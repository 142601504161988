<template>
    <div class="AddressCreateModal">
        <modal
            v-if="showAddressForm"
            v-model="showModal"
            :title="$t('address:create:title')"
        >
            <address-template-selector
                :defaultTemplateCode="'USER'"
                :templateList="getTemplateList"
                class="AddressCreateModal-templateSelector"
                @template-changed="updateSelectedTemplate"
            />
            <v-form @submit.prevent="createAddress">
                <template v-for="field in getTemplate.fields">
                    <v-text-field
                        v-if="field.type === 'text'"
                        :key="field.id"
                        v-model="formFields[field.id]"
                        :label="field.label"
                        :readonly="field.readonly"
                        :required="field.required"
                        items-text="name"
                        item-value="id"
                        :error-messages="v$[field.id].$errors.map(e => e.$message)"
                        class="mb-2"
                        variant="underlined"
                        @update:model-value="updateModelValue(field)"
                    />
                    <v-autocomplete
                        v-if="field.type === 'choice'"
                        :key="field.id"
                        v-model="formFields[field.id]"
                        variant="underlined"
                        :items="field.params.list"
                        :label="field.label"
                        :readonly="field.readonly"
                        :required="field.required"
                        :error-messages="v$[field.id].$errors.map(e => e.$message)"
                        item-title="name"
                        item-value="id"
                        @update:model-value="updateModelValue(field)"
                    />
                </template>
            </v-form>
            <template #actions>
                <v-btn
                    variant="text"
                    @click="closeModal"
                >
                    {{ $t('button:cancel') }}
                </v-btn>
                <v-btn
                    class="bg-primary ml-2"
                    @click="submitForm"
                >
                    {{ $t('button:create') }}
                </v-btn>
            </template>
        </modal>
    </div>
</template>

<script setup>
import * as addressActionTypes from '@/StoreWeb/store/modules/address/action-types'
import { computed, onBeforeMount, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { isEmpty } from 'global-utils'
import { numeric, regex, required } from '@/StoreWeb/utils/i18n-validators'
import { useI18n } from 'vue-i18n'
import { helpers } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import AddressTemplateSelector from '@/StoreWeb/components/address/AddressTemplateSelector'
import uniqid from 'uniqid'
import {
    initFieldsDependenciesFromTemplate,
    browseAndApplyDependenciesSchemas
} from '@/StoreWeb/js/composables/fields-dependencies-from-template-utils'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true
    }
})

const store = useStore()
const { t } = useI18n()

const requestValidator = ref(null)
const templateCode = ref('USER')

const showModal = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue)
})

const getAddressTemplates = computed(() => store.getters.getAddressTemplates)
const getTemplate = computed(() => getAddressTemplates.value.find(item => item.code === templateCode.value))

const getTemplateList = computed(() => {
    const templates = []
    getAddressTemplates.value.forEach(template => {
        if (template.minNb > 0 && template.maxNb > 0) {
            templates.push({
                id: template.code,
                label: template.label
            })
        }
    })
    return templates
})

const showAddressForm = computed(() => !isEmpty(getTemplateList.value))

const formFields = computed(() => {
    const fields = {}
    getTemplate.value?.fields.forEach(field => {
        if (field.id === 'country' && !isEmpty(field.params.list) && isEmpty(field.value)) {
            fields[field.id] = field.params.list[0].id
        } else {
            fields[field.id] = ''
        }
    })

    return reactive(fields)
})

const rules = computed(() => {
    const rules = {}
    const template = getTemplate.value
    if (!isEmpty(template) && !isEmpty(template.fields)) {
        template.fields.forEach(field => {
            rules[field.id] = {}
            if (field.required) {
                rules[field.id].required = required
            }
            if (field.params.pattern) {
                rules[field.id].regexCustom = helpers.withMessage(
                    () => {
                        const transKey = field.params.patternValidationMessage
                        let errorMsg = transKey ? t(transKey) : ''
                        if (isEmpty(errorMsg)) {
                            errorMsg = t('validations:regex')
                        }
                        return errorMsg
                    },
                    regex(new RegExp(field.params.pattern))
                )
            }
            if (field.params.subType === 'number') {
                rules[field.id].numeric = numeric
            }
            if (!isEmpty(field.params.list) && !isEmpty(field.value)) {
                const list = field.params.list.map(element => element.id)
                const contains = (value) => list.includes(value)
                const errorMessage = t('validations.mustBeInList')
                rules[field.id].mustBeInList = helpers.withMessage(errorMessage, contains)
            }
        })
    }
    return rules
})

const v$ = useVuelidate(rules, formFields, { $scope: 'create' })

onBeforeMount(async () => {
    if (isEmpty(getTemplateList.value)) {
        await store.dispatch(addressActionTypes.GET_ADDRESS_TEMPLATES)
    }
    if (getTemplate.value?.fields) {
        initFieldsDependenciesFromTemplate(formFields, getTemplate.value.fields, v$)
    }
})

function updateModelValue (field) {
    v$.value[field.id].$touch()
    browseAndApplyDependenciesSchemas(field)
}

async function submitForm () {
    v$.value.$touch()
    const isValid = await v$.value.$validate()
    requestValidator.value = uniqid()
    if (!v$.value.$invalid && isValid) {
        await createAddress()
    }
}

async function createAddress () {
    await store.dispatch(addressActionTypes.CREATE_ADDRESS, {
        data: formFields.value,
        template: getTemplate.value
    })
        .then(() => {
            store.dispatch(addressActionTypes.GET_ADDRESSES, {
                cachiosOptions: {
                    force: true
                }
            })
        })
        .finally(() => {
            closeModal()
        })
}

function updateSelectedTemplate (value) {
    templateCode.value = value
}

function closeModal () {
    showModal.value = false
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.AddressCreateModal {
    &-templateSelector {
        margin-bottom: 20px;
    }
}
</style>
