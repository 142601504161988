<template>
    <div
        :aria-label="$t('accessibility:pagination')"
        class="Pagination"
        role="navigation"
    >
        <!-- Elements per page -->
        <select-dropdown
            v-if="showItemsPerPageSelector"
            :defaultValue="elementsByPage"
            :inline="true"
            :isThin="true"
            :label="$t('pagination:lines_selector')"
            :options="getOptions"
            class="Pagination-dropdown"
            @select-changed="updateElementsByPage"
        />
        <div class="Pagination-navigation">
            <!-- Previous page -->
            <span
                :aria-label="$t('accessibility:previous_page')"
                :tabindex="getFirstPageIndex"
                class="Pagination-text Accessibility-reachableByTab"
                @click="previousPage"
                @keyup.enter="previousPage"
                @keyup.space="previousPage"
            >
                <span
                    v-if="getNumberOfPages > 1"
                    :class="{ 'Pagination-icon--inactive': isFirstPage }"
                    class="Pagination-icon vuecommon-icon_chevron_left Accessibility-noReachableByClick"
                    tabindex="-1"
                />
                <span
                    v-else
                    class="Pagination-icon Pagination-icon--onlyOne Pagination-icon--inactive Accessibility-noReachableByClick"
                    tabindex="-1"
                >-
                </span>
            </span>
            <!-- First element -->
            <span
                v-if="getNeedFirstElement"
                class="Pagination-fastAccess"
            >
                <span
                    :id="'js-pagination-1'"
                    :aria-label="getAriaLabel(1)"
                    class="Pagination-text Accessibility-reachableByTab"
                    tabindex="0"
                    @click="updatePage(1)"
                    @focus="getAriaLabel(1)"
                    @keyup.enter="updatePage(1)"
                    @keyup.space="updatePage(1)"
                    @mouseleave="hovered=false"
                    @mouseover="hovered=true"
                >
                    <span
                        :style="{ color: getColor(1) }"
                        class="Pagination-page Accessibility-noReachableByClick"
                        tabindex="-1"
                    >
                        1
                    </span>
                </span>
                <span class="Pagination-separator">...</span>
            </span>
            <!-- Regular elements -->
            <span
                v-for="i in getNumberOfPagesDisplayed"
                :id="'js-pagination-'+i"
                :key="'pagination_'+i"
                :aria-label="getAriaLabel(i)"
                class="Pagination-text Accessibility-reachableByTab"
                tabindex="0"
                @click="updatePage(i)"
                @focus="getAriaLabel(i)"
                @keyup.enter="updatePage(i)"
                @keyup.space="updatePage(i)"
                @mouseleave="hovered=false"
                @mouseover="hovered=true"
            >
                <span
                    :class="{ 'Pagination-page--active' : i === getCurrentPage, 'Pagination-page--onlyOne': getNumberOfPages === 1 }"
                    :style="{ color: getColor(i) }"
                    class="Pagination-page Accessibility-noReachableByClick"
                    tabindex="-1"
                >
                    {{ i }}
                </span>
            </span>
            <!-- Last element -->
            <span
                v-if="getNeedLastElement"
                class="Pagination-fastAccess"
            >
                <span class="Pagination-separator">...</span>
                <span
                    :id="'js-pagination-' + getNumberOfPages"
                    :aria-label="getAriaLabel(getNumberOfPages)"
                    class="Pagination-text Accessibility-reachableByTab"
                    tabindex="0"
                    @click="updatePage(getNumberOfPages)"
                    @focus="getAriaLabel(getNumberOfPages)"
                    @keyup.enter="updatePage(getNumberOfPages)"
                    @keyup.space="updatePage(getNumberOfPages)"
                    @mouseleave="hovered=false"
                    @mouseover="hovered=true"
                >
                    <span
                        :style="{ color: getColor(getNumberOfPages) }"
                        class="Pagination-page Accessibility-noReachableByClick"
                        tabindex="-1"
                    >
                        {{ getNumberOfPages }}
                    </span>
                </span>
            </span>
            <!-- Next -->
            <span
                :aria-label="$t('accessibility:next_page')"
                :tabindex="getLastPageIndex"
                class="Pagination-text Accessibility-reachableByTab"
                @click="nextPage"
                @keyup.enter="nextPage"
                @keyup.space="nextPage"
            >
                <span
                    v-if="getNumberOfPages > 1"
                    :class="{ 'Pagination-icon--inactive': isLastPage }"
                    class="Pagination-icon vuecommon-icon_chevron_right Accessibility-noReachableByClick"
                    tabindex="-1"
                />
                <span
                    v-else
                    class="Pagination-icon Pagination-icon--onlyOne Pagination-icon--inactive Accessibility-noReachableByClick"
                    tabindex="-1"
                >-
                </span>
            </span>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import SelectDropdown from '@/StoreWeb/components/common/SelectDropdown'
import i18n from 'i18n'

export default {
    components: {
        SelectDropdown
    },
    props: {
        currentPage: {
            type: Number,
            required: true
        },
        items: {
            type: Array,
            default: () => []
        },
        elementsByPage: {
            type: Number,
            required: true
        },
        showItemsPerPageSelector: {
            type: Boolean,
            default: true
        },
        totalPages: {
            type: Number,
            default: 1
        }
    },
    data () {
        return {
            buttonHovered: false,
            hovered: false,
            needFirstElement: false,
            needLastElement: false,
            page: 1
        }
    },
    computed: {
        ...mapState(['mainColor', 'mainColorDark']),
        getButtonColor () {
            return this.buttonHovered ? this.mainColorDark : this.mainColor
        },
        getCurrentPage () {
            return this.page
        },
        getFirstPageIndex () {
            return this.isFirstPage ? '-1' : '0'
        },
        getLastPageIndex () {
            return this.isLastPage ? '-1' : '0'
        },
        getNeedFirstElement () {
            return this.needFirstElement
        },
        getNeedLastElement () {
            return this.needLastElement
        },
        getNumberOfPages () {
            return this.totalPages > 1 ? this.totalPages : Math.ceil(this.items.length / this.elementsByPage)
        },
        getNumberOfPagesDisplayed () {
            const pages = []
            const pagesMax = this.getNumberOfPages
            const indexMax = 5
            const indexOnSide = Math.round(indexMax / 2)

            for (let i = 1; i < pagesMax + 1; i++) {
                if ((i >= this.page - indexOnSide && i < this.page) || (this.page - i > 0 && this.page - i < indexMax && i > pagesMax - indexMax) || i === this.page || ((i <= this.page + indexOnSide || pages.length < indexMax) && i > this.page)) {
                    pages.push(i)
                }
            }

            // Add first
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.needFirstElement = this.page > indexOnSide + 1

            // Need last
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.needLastElement = pagesMax > indexMax && this.page < pagesMax - indexOnSide

            return pages
        },
        getOptions () {
            return [
                {
                    id: '20',
                    name: '20'
                },
                {
                    id: '50',
                    name: '50'
                },
                {
                    id: '100',
                    name: '100'
                },
                {
                    id: '200',
                    name: '200'
                }
            ]
        },
        isFirstPage () {
            return this.getCurrentPage === 1
        },
        isLastPage () {
            return this.getCurrentPage === this.getNumberOfPages
        }
    },
    watch: {
        currentPage: function (val) {
            this.page = val
            this.$nextTick(() => {
                document.querySelector('#js-pagination-' + this.getCurrentPage).focus()
            })
        }
    },
    mounted () {
        this.page = this.currentPage
    },
    methods: {
        getAriaLabel (i) {
            return i === this.getCurrentPage
                ? i18n.global.t('accessibility:page') + ' ' + this.getCurrentPage + ' ' + i18n.global.t('accessibility:on') + ' ' + this.getNumberOfPages + ' ' + i18n.global.t('accessibility:current_page')
                : i18n.global.t('accessibility:page') + ' ' + i + ' ' + i18n.global.t('accessibility:on') + ' ' + this.getNumberOfPages
        },
        getColor (i) {
            return i === this.getCurrentPage && this.getNumberOfPages > 1 ? this.hovered ? this.mainColorDark : this.mainColor : ''
        },
        updateElementsByPage (value) {
            this.$emit('updateElementsByPage', parseInt(value.selectedId))
        },
        nextPage () {
            if (!this.isLastPage) {
                this.page++
                this.$emit('updateCurrentPage', this.getCurrentPage)
            }
        },
        previousPage () {
            if (!this.isFirstPage) {
                this.page--
                this.$emit('updateCurrentPage', this.getCurrentPage)
            }
        },
        updatePage (value) {
            this.page = parseInt(value)
            this.$emit('updateCurrentPage', this.page)
        }
    }
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

.Pagination {
    @include verticalCenter;

    flex-flow: column wrap;
    justify-content: center;
    margin: 0;
    padding: $padding-small;
    padding-bottom: 0;
    border-top: 1px solid $color-grey-ultraLight;
    font-family: $fontLato;
    font-size: $font-small;
    font-weight: $fontLato-regular;
    color: $color-black-light;

    @media screen and (min-width: $tablet-breakpoint) {
        flex-direction: row;
    }

    &-navigation {
        @include verticalCenter;

        margin: 0 $margin-small;
    }

    &-dropdown,
    &-navigation {
        margin-bottom: $margin-xsmall;

        @media screen and (min-width: $tablet-breakpoint) {
            margin-bottom: 0;
        }
    }

    &-icon {
        cursor: pointer;
        color: $color-grey-dark;
        transition: background-color .3s ease-in-out;

        &:hover {
            color: $color-black;
        }

        &--onlyOne {
            font-weight: $fontLato-bold;
        }

        &--inactive {
            cursor: default;
            color: $color-grey-light;

            &:hover {
                color: $color-grey-light;
            }
        }
    }

    &-fastAccess {
        @include verticalCenter;
    }

    &-separator {
        cursor: default;
        color: $color-grey;
    }

    &-page {
        @include verticalCenter;

        padding: $padding-xxsmall $padding-xsmall;
        border-radius: $borderRadius-medium;
        cursor: pointer;
        transition: color .3s ease-in-out, font-weight .3s ease-in-out;

        &:hover {
            color: $color-black;
        }

        &--active {
            font-weight: $fontLato-bold;
        }

        &--onlyOne {
            cursor: default;
        }
    }

    &-text {
        @include verticalCenter;

        line-height: $font-medium;
    }
}
</style>
