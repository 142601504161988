export default class {
    constructor (id) {
        this._id = id
        this._name = ''
        this._products = []
        this._sectionStyle = ''
        this._sections = []
        this._size = 0
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get name () {
        return this._name
    }

    set name (name) {
        this._name = name
    }

    get products () {
        return this._products
    }

    set products (products) {
        this._products = products
    }

    get sections () {
        return this._sections
    }

    set sections (sections) {
        this._sections = sections
    }

    get sectionStyle () {
        return this._sectionStyle
    }

    set sectionStyle (sectionStyle) {
        this._sectionStyle = sectionStyle
    }

    get size () {
        return this._size
    }

    set size (size) {
        this._size = size
    }

    toJSON () {
        return {
            id: this.id,
            name: this.name,
            products: this.products,
            sections: this.sections,
            sectionStyle: this.sectionStyle,
            size: this.size
        }
    }
}
