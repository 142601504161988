<template>
    <modal
        v-model="showModal"
        :title="$t('cart:reset_anonymous_new_support:modal_title')"
        :width="'small'"
    >
        <v-alert
            density="compact"
            variant="tonal"
            type="info"
        >
            {{ $t('cart:reset_anonymous_new_support:modal_description') }}
        </v-alert>
        <template #actions>
            <v-btn
                class="bg-primary"
                size="large"
                @click.prevent="closeModal"
            >
                {{ $t('button:got_it') }}
            </v-btn>
        </template>
    </modal>
</template>

<script setup>
import { computed } from 'vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true
    }
})

const showModal = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue)
})

function closeModal () {
    showModal.value = false
}
</script>
