import Provider from '@/StoreWeb/models/user/wallet/Provider'
import AccountNormalizer from '@/StoreWeb/normalizers/user/wallet/AccountNormalizerSingleton'
import { isEmpty } from 'global-utils'

export default class {
    constructor () {
        this._accountNormalizer = AccountNormalizer.getInstance()
    }

    get accountNormalizer () {
        return this._accountNormalizer
    }

    normalize (item, params) {
        const provider = new Provider(item.id)

        if (!isEmpty(item.walletAddAccount)) {
            provider.walletAddAccount = item.walletAddAccount
        }
        if (!isEmpty(item.walletDeleteAccountMode)) {
            provider.walletDeleteAccountMode = item.walletDeleteAccountMode
        }
        if (!isEmpty(item.walletName)) {
            provider.walletName = item.walletName
        }
        if (!isEmpty(item.walletCreateAccount)) {
            provider.walletCreateAccount = item.walletCreateAccount
        }
        if (!isEmpty(item.walletCreateThirdPartyAccount)) {
            provider.walletCreateThirdPartyAccount = item.walletCreateThirdPartyAccount
        }
        if (!isEmpty(item.walletAddFareMedia)) {
            provider.walletAddFareMedia = item.walletAddFareMedia
        }
        if (!isEmpty(item.walletOptions)) {
            provider.walletOptions = item.walletOptions
        }
        if (!isEmpty(item.walletManageFareProfile)) {
            provider.walletManageFareProfile = item.walletManageFareProfile
        }
        params = Object.assign(params, {
            providerId: provider.id,
            walletName: provider.walletName,
            walletCreateAccount: provider.walletCreateAccount,
            walletCreateThirdPartyAccount: provider.walletCreateThirdPartyAccount,
            walletAddFareMedia: provider.walletAddFareMedia
        })
        if (!isEmpty(item.accounts)) {
            provider.accounts = this.accountNormalizer.normalizeArray(item.accounts, params)
        }
        return provider
    }

    normalizeArray (items, params) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item, params))
            })
        }

        return normalizedItems
    }
}
