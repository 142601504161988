import HistoryPackage from '@/StoreWeb/models/user/order/HistoryPackage'
import HistoryBasketNormalizer from '@/StoreWeb/normalizers/user/order/HistoryBasketNormalizerSingleton'
import { isEmpty } from 'global-utils'

const historyBasketNormalizer = HistoryBasketNormalizer.getInstance()

export default class {
    normalize (item) {
        const historyPackage = new HistoryPackage(item.id)

        if (!isEmpty(item.baskets)) {
            historyPackage.baskets = historyBasketNormalizer.normalizeArray(item.baskets)
        }
        historyPackage.fareExcludingTaxes = item.fareExcludingTaxes
        historyPackage.fareIncludingTaxes = item.fareIncludingTaxes
        historyPackage.name = item.name
        historyPackage.quantity = item.quantity
        historyPackage.totalFareExcludingTaxes = item.totalFareExcludingTaxes
        historyPackage.totalFareIncludingTaxes = item.totalFareIncludingTaxes
        historyPackage.unit = item.unit
        historyPackage.vatAmout = item.vatAmout
        historyPackage.vatRate = item.vatRate
        historyPackage.version = item.modalPackageVersion

        return historyPackage
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
