<template>
    <div class="ProductAddToCartFlowDebugger">
        <p class="ProductAddToCartFlowDebugger-title h3">
            ProductAddToCartFlowDebugger
        </p>
        <div class="ProductAddToCartFlowDebugger-grid">
            <div
                v-if="getItems.length > 0"
                :style="{ width: `${getColumns * 40}px`, height: `${getRows * 40}px` }"
                class="ProductAddToCartFlowDebugger-wrapper"
            >
                <div
                    v-for="(item, index) in getItems"
                    v-bind="item"
                    :key="index"
                    :style="{ left: `${(item.column-1) * 40}px`, bottom: `${(item.row-1) * 40}px` }"
                    :class="[
                        (item.isVisual ? 'ProductAddToCartFlowDebugger-item--visual' : ''),
                        (item.isCurrent ? 'ProductAddToCartFlowDebugger-item--current' : '')
                    ]"
                    class="ProductAddToCartFlowDebugger-item"
                >
                    {{ item.column }},{{ item.row }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import AddToCartFlowManager from '@/StoreWeb/managers/AddToCartFlowManagerSingleton'
import { mapState } from 'vuex'

export default {
    data () {
        return {
            addToCartFlowManager: AddToCartFlowManager.getInstance(),
            columns: 0,
            items: [],
            rows: 0
        }
    },
    computed: {
        ...mapState({
            addToCartFlowStep: state => state.cartModule.addToCartFlowStep
        }),
        getColumns () {
            return this.columns
        },
        getItems () {
            return this.items
        },
        getRows () {
            return this.rows
        }
    },
    mounted () {
        this.updateFlowChart()
    },
    watch: {
        addToCartFlowStep () {
            this.updateFlowChart()
        },
    },
    methods: {
        updateFlowChart () {
            const res = this.addToCartFlowManager.generateFlowChart(this.addToCartFlowManager.addToCartFlowSteps[0].steps)
            this.columns = res.columns
            this.items = res.items
            this.rows = res.rows
        }
    }
}
/* eslint-enable */
</script>

<style lang="scss" scoped>
@import 'globalScss';

.ProductAddToCartFlowDebugger {
    &-grid {
        position: relative;
        margin-bottom: 20px;
        background: #eee;
    }

    &-item {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 40px;
        height: 40px;
        box-shadow: inset 0 0 0 1px #eee, inset 0 0 0 5px black;
        background: #777;
        color: #fff;

        &--visual {
            background: #fff;
            color: #000;
        }

        &--current {
            background: #ffe54c;
            color: #000;
        }
    }
}
</style>
