import Order from '@/StoreWeb/models/user/order/Order'
import AddressNormalizer from '@/StoreWeb/normalizers/address/AddressNormalizerSingleton'
import HistoryBasketNormalizer from '@/StoreWeb/normalizers/user/order/HistoryBasketNormalizerSingleton'
import HistoryPackageNormalizer from '@/StoreWeb/normalizers/user/order/HistoryPackageNormalizerSingleton'
import HistoryPlanNormalizer from '@/StoreWeb/normalizers/user/order/HistoryPlanNormalizerSingleton'
import HistoryInvoiceNormalizer from '@/StoreWeb/normalizers/user/order/HistoryInvoiceNormalizerSingleton'
import HistoryModalCounterNormalizer from '@/StoreWeb/normalizers/user/order/HistoryModalCounterNormalizerSingleton'
import HistoryPaymentNormalizer from '@/StoreWeb/normalizers/user/order/HistoryPaymentNormalizerSingleton'
import IdLabelPatternNormalizer from '@/StoreWeb/normalizers/patterns/IdLabelPatternNormalizerSingleton'
import { isEmpty } from 'global-utils'

export default class {
    constructor () {
        this._addressNormalizer = AddressNormalizer.getInstance()
        this._historyBasketNormalizer = HistoryBasketNormalizer.getInstance()
        this._historyModalCounterNormalizer = HistoryModalCounterNormalizer.getInstance()
        this._historyPackageNormalizer = HistoryPackageNormalizer.getInstance()
        this._historyPaymentNormalizer = HistoryPaymentNormalizer.getInstance()
        this._historyPlanNormalizer = HistoryPlanNormalizer.getInstance()
        this._historyInvoiceNormalizer = HistoryInvoiceNormalizer.getInstance()
        this._idLabelPatternNormalizer = IdLabelPatternNormalizer.getInstance()
    }

    get addressNormalizer () {
        return this._addressNormalizer
    }

    get historyBasketNormalizer () {
        return this._historyBasketNormalizer
    }

    get historyModalCounterNormalizer () {
        return this._historyModalCounterNormalizer
    }

    get historyPackageNormalizer () {
        return this._historyPackageNormalizer
    }

    get historyPaymentNormalizer () {
        return this._historyPaymentNormalizer
    }

    get historyPlanNormalizer () {
        return this._historyPlanNormalizer
    }

    get historyInvoiceNormalizer () {
        return this._historyInvoiceNormalizer
    }

    get idLabelPatternNormalizer () {
        return this._idLabelPatternNormalizer
    }

    normalize (item) {
        const order = new Order(item.id)

        order.addresses = this.addressNormalizer.normalizeArray(item.addresses)
        order.baskets = this.historyBasketNormalizer.normalizeArray(item.baskets)
        order.counters = this.historyModalCounterNormalizer.normalizeArray(item.counters)
        order.creationDate = item.creationDate
        order.purchaseDate = item.purchaseDate
        order.externalIdLabel = item.externalIdLabel ?? ''
        order.idLabel = item.idLabel ?? ''
        if (!isEmpty(item.invoice)) {
            order.invoice = this.historyInvoiceNormalizer.normalize(item.invoice)
        } else if (!isEmpty(item.invoices)) {
            order.invoice = this.historyInvoiceNormalizer.normalize(item.invoices[0])
        }
        order.invoices = this.historyInvoiceNormalizer.normalizeArray(item.invoices)
        order.operation = item.operation
        order.packages = this.historyPackageNormalizer.normalizeArray(item.packages)
        order.payments = this.historyPaymentNormalizer.normalizeArray(item.payments)
        order.plans = this.historyPlanNormalizer.normalizeArray(item.plans)
        order.status = this.idLabelPatternNormalizer.normalize(item.status)
        order.totalAmountExcludingTaxes = item.totalAmountExcludingTaxes
        order.totalAmountIncludingTaxes = item.totalAmountIncludingTaxes
        order.totalVatAmount = item.totalVatAmount
        order.updateDate = item.updateDate
        order.userEmail = item.userEmail
        order.userFirstName = item.userFirstName
        order.userId = item.userId
        order.userLastName = item.userLastName
        order.external = item.external

        return order
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
