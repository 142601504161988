export default class {
    constructor (
        id
    ) {
        this._email = ''
        this._firstName = ''
        this._id = id
        this._lastName = ''
    }

    get email () {
        return this._email
    }

    set email (email) {
        this._email = email
    }

    get firstName () {
        return this._firstName
    }

    set firstName (firstName) {
        this._firstName = firstName
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get lastName () {
        return this._lastName
    }

    set lastName (lastName) {
        this._lastName = lastName
    }

    toJSON () {
        return {
            email: this.email,
            firstName: this.firstName,
            id: this.id,
            lastName: this.lastName
        }
    }
}
