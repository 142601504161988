import AssociatedAccount from '@/StoreWeb/models/user/wallet/AssociatedAccount'
import ProviderNormalizer from '@/StoreWeb/normalizers/user/wallet/ProviderNormalizerSingleton'
import AccountAssociationTypeNormalizer from '@/StoreWeb/normalizers/account-association/AccountAssociationTypeNormalizerSingleton'
import RecipientUserNormalizer from '@/StoreWeb/normalizers/user/RecipientUserNormalizerSingleton'
import UserPlanNormalizer from '@/StoreWeb/normalizers/user/wallet/UserPlanNormalizerSingleton'
import UserModalCounterNormalizer from '@/StoreWeb/normalizers/user/wallet/UserModalCounterNormalizerSingleton'
import { isEmpty } from 'global-utils'

export default class {
    constructor () {
        this._accountAssociationTypeNormalizer = AccountAssociationTypeNormalizer.getInstance()
        this._providerNormalizer = ProviderNormalizer.getInstance()
        this._recipientUserNormalizer = RecipientUserNormalizer.getInstance()
        this._userModalCounterNormalizer = UserModalCounterNormalizer.getInstance()
        this._userPlanNormalizer = UserPlanNormalizer.getInstance()
    }

    get accountAssociationTypeNormalizer () {
        return this._accountAssociationTypeNormalizer
    }

    get providerNormalizer () {
        return this._providerNormalizer
    }

    get recipientUserNormalizer () {
        return this._recipientUserNormalizer
    }

    get userModalCounterNormalizer () {
        return this._userModalCounterNormalizer
    }

    get userPlanNormalizer () {
        return this._userPlanNormalizer
    }

    normalize (item) {
        const associatedAccount = new AssociatedAccount(item.id)

        if (!isEmpty(item.modalCounters)) {
            associatedAccount.counters = this.userModalCounterNormalizer.normalizeArray(item.modalCounters)
        }
        if (!isEmpty(item.email)) {
            associatedAccount.email = item.email
        }
        if (!isEmpty(item.firstName)) {
            associatedAccount.firstName = item.firstName
        }
        if (typeof item.isMaster !== 'undefined') {
            associatedAccount.isMaster = item.isMaster
        }
        if (!isEmpty(item.lastName)) {
            associatedAccount.lastName = item.lastName
        }
        if (!isEmpty(item.providers)) {
            const recipientUser = this.recipientUserNormalizer.normalize(item)

            const params = {
                recipientUser
            }
            associatedAccount.userId = item.userId
            associatedAccount.providers = this.providerNormalizer.normalizeArray(item.providers, params)
        }
        if (!isEmpty(item.type)) {
            associatedAccount.type = this.accountAssociationTypeNormalizer.normalize(item.type)
        }
        if (!isEmpty(item.userPlans)) {
            associatedAccount.plans = this.userPlanNormalizer.normalizeArray(item.userPlans)
        }

        return associatedAccount
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
