import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
    namespaced: false,
    actions,
    getters,
    mutations,
    state: {
        appliedFilters: [],
        currentCatalogParams: {},
        filteredProductsIds: [],
        filters: [],
        fareMediaAvailableProducts: [],
        fareMedias: [],
        fines: [],
        catalogPendingRequests: {
            checkCompatibility: false,
            getCatalog: false,
            getCatalogByProducts: false,
            getFareMediaCatalog: false,
            getFareMedias: false,
            getFines: false,
            getListByParameterName: false,
            getProductDetails: false,
            getQuotations: false
        },
        productDetails: null,
        productToPutOnCard: null,
        products: [],
        sections: [],
        waitingForActionCatalog: null,
        waitingForFilters: []
    }
}
