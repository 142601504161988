<template>
    <form class="PersonalCardForm">
        <h1
            v-if="false"
            class="h1"
        >
            {{ $t('form:card_holder_infos:title') }}
        </h1>
        <p v-if="false">
            {{ $t('form:card_holder_infos:subtitle') }}
        </p>
        <custom-select
            :defaultValue="getGender"
            :label="$t('form:user_infos:gender:label')"
            :options="genders"
            class="PersonalCardForm-field PersonalCardForm-genderField"
            @select-changed="updateGender"
        />
        <labelled-input
            :defaultValue="getLastName"
            :inputType="'text'"
            :label="$t('form:user_infos:lastname:label')"
            class="PersonalCardForm-field PersonalCardForm-lastNameField"
            @field-value-changed="updateLastName"
        />
        <labelled-input
            :defaultValue="getFirstName"
            :inputType="'text'"
            :label="$t('form:user_infos:firstname:label')"
            class="PersonalCardForm-field PersonalCardForm-firstNameField"
            @field-value-changed="updateFirstName"
        />
        <date-input-field-with-label
            :defaultValue="getBirthdate"
            :inputType="'date'"
            :label="$t('form:user_infos:birthdate:label')"
            class="PersonalCardForm-field PersonalCardForm-birthdateField"
            @field-value-changed="updateBirthdate"
        />
        <labelled-input
            :defaultValue="getEmail"
            :inputType="'text'"
            :label="$t('form:user_infos:email:label')"
            class="PersonalCardForm-field PersonalCardForm-emailField"
            @field-value-changed="updateEmail"
        />
        <labelled-input
            :defaultValue="getPhone"
            :inputType="'text'"
            :label="$t('form:user_infos:phone:label')"
            class="PersonalCardForm-field PersonalCardForm-phoneField"
            @field-value-changed="updatePhone"
        />

        <div class="PersonalCardForm-streetNumber">
            <labelled-input
                :defaultValue="getStreetNumber"
                :inputType="'text'"
                :label="$t('form:address:street_number:label')"
                class="PersonalCardForm-field PersonalCardForm-streetNumberField"
                @field-value-changed="updateStreetNumber"
            />
            <custom-select
                :defaultValue="getStreetNumberSuffix"
                :label="$t('form:address:street_number_suffix:label')"
                :options="streetNumberSuffixes"
                class="PersonalCardForm-field PersonalCardForm-numberSuffixField"
                @select-changed="updateStreetNumberSuffix"
            />
        </div>
        <div class="PersonalCardForm-street">
            <custom-select
                :defaultValue="getStreetType"
                :label="$t('form:address:street_type:label')"
                :options="streetTypes"
                class="PersonalCardForm-field PersonalCardForm-streetTypeField"
                @select-changed="updateStreetType"
            />
            <labelled-input
                :defaultValue="getStreetName"
                :inputType="'text'"
                :label="$t('form:address:street_name:label')"
                class="PersonalCardForm-field PersonalCardForm-streetNameField"
                @field-value-changed="updateStreetName"
            />
        </div>
        <labelled-input
            :defaultValue="getDetail1"
            :inputType="'text'"
            :label="$t('form:address:details1:label')"
            class="PersonalCardForm-field PersonalCardForm-detail1Field"
            @field-value-changed="updateDetail1"
        />
        <labelled-input
            :defaultValue="getDetail2"
            :inputType="'text'"
            :label="$t('form:address:details2:label')"
            class="PersonalCardForm-field PersonalCardForm-detail2Field"
            @field-value-changed="updateDetail2"
        />
        <div class="PersonalCardForm-cityAndZipCode">
            <labelled-input
                :defaultValue="getZipCode"
                :inputType="'text'"
                :label="$t('form:address:zip_code:label')"
                class="PersonalCardForm-field PersonalCardForm-zipCodeField"
                @field-value-changed="updateZipCode"
            />
            <labelled-input
                :defaultValue="getCity"
                :inputType="'text'"
                :label="$t('form:address:city:label')"
                class="PersonalCardForm-field PersonalCardForm-cityField"
                @field-value-changed="updateCity"
            />
        </div>
        <custom-select
            :defaultValue="getCountry"
            :label="$t('form:address:country:label')"
            :options="countries"
            class="PersonalCardForm-field PersonalCardForm-countryField"
            @select-changed="updateCountry"
        />
    </form>
</template>

<script>
import DateInputFieldWithLabel from '@/StoreWeb/components/common/DateInputFieldWithLabel'
import LabelledInput from '@/StoreWeb/components/common/LabelledInput'
import CustomSelect from '@/StoreWeb/components/common/CustomSelect'
import Address from '@/StoreWeb/models/common/Address'
import { mapState } from 'vuex'
import AddToCartFlowManager from '@/StoreWeb/managers/AddToCartFlowManagerSingleton'
import { isEmpty } from 'global-utils'
import i18n from 'i18n'

export default {
    components: {
        DateInputFieldWithLabel,
        LabelledInput,
        CustomSelect
    },
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            addToCartFlowManager: AddToCartFlowManager.getInstance(),
            genders: [
                {
                    id: 'UNKNOWN',
                    name: i18n.global.t('form:user_infos:gender:choices:unknown')
                },
                {
                    id: 'MALE',
                    name: i18n.global.t('form:user_infos:gender:choices:male')
                },
                {
                    id: 'FEMALE',
                    name: i18n.global.t('form:user_infos:gender:choices:female')
                }
            ],
            gender: '',
            firstName: '',
            lastName: '',
            birthdate: '',
            email: '',
            phone: '',
            // Address fields
            countries: Address.getCountryOptions(),
            country: '',
            selectedCountry: '-',

            streetName: '',
            streetNumber: '',

            streetNumberSuffixes: Address.getStreetNumberSuffixeOptions(),
            streetNumberSuffix: '',
            selectedStreetNumberSuffix: '-',

            streetTypes: Address.getStreetTypeOptions(),
            streetType: '',
            selectedStreetType: '-',

            detail1: '',
            detail2: '',
            zipCode: '',
            city: ''
        }
    },
    computed: {
        ...mapState({
            debugMode: state => state.debuggingModule.debugMode
        }),
        getGender () {
            return this.gender
        },
        getFirstName () {
            return this.firstName
        },
        getLastName () {
            return this.lastName
        },
        getBirthdate () {
            return this.birthdate
        },
        getEmail () {
            return this.email
        },
        getPhone () {
            return this.phone
        },
        // Address computed
        getCity () {
            return this.city
        },
        getDetail1 () {
            return this.detail1
        },
        getDetail2 () {
            return this.detail2
        },
        getCountry () {
            return this.country
        },
        getStreetName () {
            return this.streetName
        },
        getStreetNumber () {
            return this.streetNumber
        },
        getStreetNumberSuffix () {
            return this.streetNumberSuffix
        },
        getStreetType () {
            return this.streetType
        },
        getZipCode () {
            return this.zipCode
        }
    },
    mounted () {
        if (
            !isEmpty(this.addToCartFlowManager.currentStep) &&
            !isEmpty(this.addToCartFlowManager.currentStep.productParameterValues)
        ) {
            const gender = this.addToCartFlowManager.currentStep.productParameterValues.find(productParameter => productParameter.code === 'civility')
            if (!isEmpty(gender) && !isEmpty(gender.value)) {
                this.updateGender({
                    selected: {
                        id: gender.value
                    }
                })
            }
            const firstName = this.addToCartFlowManager.currentStep.productParameterValues.find(productParameter => productParameter.code === 'firstName')
            if (!isEmpty(firstName) && !isEmpty(firstName.value)) {
                this.updateFirstName(firstName.value)
            }
            const lastName = this.addToCartFlowManager.currentStep.productParameterValues.find(productParameter => productParameter.code === 'lastName')
            if (!isEmpty(lastName) && !isEmpty(lastName.value)) {
                this.updateLastName(lastName.value)
            }
            const birthDate = this.addToCartFlowManager.currentStep.productParameterValues.find(productParameter => productParameter.code === 'birthDate')
            if (!isEmpty(birthDate) && !isEmpty(birthDate.value)) {
                this.updateBirthdate(birthDate.value)
            }
            const email = this.addToCartFlowManager.currentStep.productParameterValues.find(productParameter => productParameter.code === 'email')
            if (!isEmpty(email) && !isEmpty(email.value)) {
                this.updateEmail(email.value)
            }
            const phone = this.addToCartFlowManager.currentStep.productParameterValues.find(productParameter => productParameter.code === 'phone')
            if (!isEmpty(phone) && !isEmpty(phone.value)) {
                this.updatePhone(phone.value)
            }
            const addressStreetNumber = this.addToCartFlowManager.currentStep.productParameterValues.find(productParameter => productParameter.code === 'addressStreetNumber')
            if (!isEmpty(addressStreetNumber) && !isEmpty(addressStreetNumber.value)) {
                this.updateStreetNumber(addressStreetNumber.value)
            }
            const addressStreetNumberSuffix = this.addToCartFlowManager.currentStep.productParameterValues.find(productParameter => productParameter.code === 'addressStreetNumberSuffix')
            if (!isEmpty(addressStreetNumberSuffix) && !isEmpty(addressStreetNumberSuffix.value)) {
                this.updateStreetNumberSuffix({
                    selected: {
                        id: addressStreetNumberSuffix.value
                    }
                })
            }
            const addressStreetType = this.addToCartFlowManager.currentStep.productParameterValues.find(productParameter => productParameter.code === 'addressStreetType')
            if (!isEmpty(addressStreetType) && !isEmpty(addressStreetType.value)) {
                this.updateStreetType({
                    selected: {
                        id: addressStreetType.value
                    }
                })
            }
            const addressStreetName = this.addToCartFlowManager.currentStep.productParameterValues.find(productParameter => productParameter.code === 'addressStreetName')
            if (!isEmpty(addressStreetName) && !isEmpty(addressStreetName.value)) {
                this.updateStreetName(addressStreetName.value)
            }
            const addressDetail1 = this.addToCartFlowManager.currentStep.productParameterValues.find(productParameter => productParameter.code === 'addressDetail1')
            if (!isEmpty(addressDetail1) && !isEmpty(addressDetail1.value)) {
                this.updateDetail1(addressDetail1.value)
            }
            const addressDetail2 = this.addToCartFlowManager.currentStep.productParameterValues.find(productParameter => productParameter.code === 'addressDetail2')
            if (!isEmpty(addressDetail2) && !isEmpty(addressDetail2.value)) {
                this.updateDetail2(addressDetail2.value)
            }
            const addressPostalCode = this.addToCartFlowManager.currentStep.productParameterValues.find(productParameter => productParameter.code === 'addressPostalCode')
            if (!isEmpty(addressPostalCode) && !isEmpty(addressPostalCode.value)) {
                this.updateZipCode(addressPostalCode.value)
            }
            const addressCity = this.addToCartFlowManager.currentStep.productParameterValues.find(productParameter => productParameter.code === 'addressCity')
            if (!isEmpty(addressCity) && !isEmpty(addressCity.value)) {
                this.updateCity(addressCity.value)
            }
            const addressCountry = this.addToCartFlowManager.currentStep.productParameterValues.find(productParameter => productParameter.code === 'addressCountry')
            if (!isEmpty(addressCountry) && !isEmpty(addressCountry.value)) {
                this.updateCountry({
                    selected: {
                        id: addressCountry.value
                    }
                })
            }
        } else if (this.debugMode) {
            this.updateGender({
                dropdownId: '',
                selected: {
                    id: 'MALE',
                    name: 'Un homme'
                }
            })
            this.updateFirstName('Julien')
            this.updateLastName('Belluco')
            this.updateBirthdate('2000-02-03')
            this.updateEmail('julien.belluco@instant-system.com')
            this.updatePhone('0123456789')
            this.updateCountry({
                dropdownId: '',
                selected: {
                    id: 'FR',
                    name: 'France'
                }
            })
            this.updateStreetName('Evariste Galois')
            this.updateStreetNumber('240')
            this.updateStreetNumberSuffix({
                selected: {
                    id: '-'
                }
            })
            this.updateStreetType({
                selected: {
                    id: 'Rue'
                }
            })
            this.updateDetail1('Garden Space B2')
            this.updateDetail2('Sophia-Antipolis')
            this.updateZipCode('06410')
            this.updateCity('Biot')
        }
    },
    methods: {
        checkFormError () {
            return (
                // this.gender !== 'UNKNOWN' && // TODO : à voir si on accepte de ne rien préciser
                this.firstName !== '' &&
                this.lastName !== '' &&
                this.birthdate !== '' &&
                this.email !== '' &&
                this.phone !== '' &&

                this.city !== '' &&
                this.country !== '-' &&
                this.streetName !== '' &&
                this.streetNumber !== '' &&
                this.streetType !== '' &&
                this.zipCode !== ''
            )
        },
        updateGender (value) {
            this.gender = value.selected
            this.updateProductParameter()
        },
        updateFirstName (value) {
            this.firstName = value
            this.updateProductParameter()
        },
        updateLastName (value) {
            this.lastName = value
            this.updateProductParameter()
        },
        updateBirthdate (value) {
            this.birthdate = value
            this.updateProductParameter()
        },
        updateEmail (value) {
            this.email = value
            this.updateProductParameter()
        },
        updatePhone (value) {
            this.phone = value
            this.updateProductParameter()
        },
        // Address methods
        updateCountry (value) {
            this.country = value.selected
            this.updateProductParameter()
        },
        updateStreetName (value) {
            this.streetName = value
            this.updateProductParameter()
        },
        updateStreetNumber (value) {
            this.streetNumber = value
            this.updateProductParameter()
        },
        updateStreetNumberSuffix (value) {
            this.streetNumberSuffix = value.selected
            this.updateProductParameter()
        },
        updateStreetType (value) {
            this.streetType = value.selected
            this.updateProductParameter()
        },
        updateDetail1 (value) {
            this.detail1 = value
            this.updateProductParameter()
        },
        updateDetail2 (value) {
            this.detail2 = value
            this.updateProductParameter()
        },
        updateZipCode (value) {
            this.zipCode = value
            this.updateProductParameter()
        },
        updateCity (value) {
            this.city = value
            this.updateProductParameter()
        },
        updateProductParameter () {
            this.$emit('updatePersonalCardForm', {
                id: 'PERSONALCARDFORM',
                isProductParameterValid: this.checkFormError(),
                productParameterValues: [
                    {
                        productParameter: 'PERSONALCARDFORM',
                        code: 'CIVILITY',
                        value: this.gender.id
                    },
                    {
                        productParameter: 'PERSONALCARDFORM',
                        code: 'FIRSTNAME',
                        value: this.firstName
                    },
                    {
                        productParameter: 'PERSONALCARDFORM',
                        code: 'LASTNAME',
                        value: this.lastName
                    },
                    {
                        productParameter: 'PERSONALCARDFORM',
                        code: 'BIRTHDATE',
                        value: this.birthdate
                    },
                    {
                        productParameter: 'PERSONALCARDFORM',
                        code: 'EMAIL',
                        value: this.email
                    },
                    {
                        productParameter: 'PERSONALCARDFORM',
                        code: 'PHONE',
                        value: this.phone
                    },
                    {
                        productParameter: 'PERSONALCARDFORM',
                        code: 'ADDRESSSTREETNUMBER',
                        value: this.streetNumber
                    },
                    {
                        productParameter: 'PERSONALCARDFORM',
                        code: 'ADDRESSSTREETNUMBERSUFFIX',
                        value: this.streetNumberSuffix.id
                    },
                    {
                        productParameter: 'PERSONALCARDFORM',
                        code: 'ADDRESSSTREETTYPE',
                        value: this.streetType.id
                    },
                    {
                        productParameter: 'PERSONALCARDFORM',
                        code: 'ADDRESSSTREETNAME',
                        value: this.streetName
                    },
                    {
                        productParameter: 'PERSONALCARDFORM',
                        code: 'ADDRESSDETAIL1',
                        value: this.detail1
                    },
                    {
                        productParameter: 'PERSONALCARDFORM',
                        code: 'ADDRESSDETAIL2',
                        value: this.detail2
                    },
                    {
                        productParameter: 'PERSONALCARDFORM',
                        code: 'ADDRESSPOSTALCODE',
                        value: this.zipCode
                    },
                    {
                        productParameter: 'PERSONALCARDFORM',
                        code: 'ADDRESSCITY',
                        value: this.city
                    },
                    {
                        productParameter: 'PERSONALCARDFORM',
                        code: 'ADDRESSCOUNTRY',
                        value: this.country.id
                    }
                ],
                isQuotationRequired: false
            })
            // this.$emit('updatePersonalCardForm', {
            //     gender: this.gender,
            //     firstName: this.firstName,
            //     lastName: this.lastName,
            //     birthdate: this.birthdate,
            //     email: this.email,
            //     phone: this.phone,
            //     city: this.city,
            //     detail1: this.detail1,
            //     detail2: this.detail2,
            //     country: this.country,
            //     streetName: this.streetName,
            //     streetNumber: this.streetNumber,
            //     streetNumberSuffix: this.streetNumberSuffix,
            //     streetType: this.streetType,
            //     zipCode: this.zipCode
            // })
        }
    }
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

.PersonalCardForm {
    &-field {
        margin-bottom: 15px;
    }

    &-streetNumber,
    &-street,
    &-cityAndZipCode {
        display: flex;
    }

    &-streetNumber,
    &-street {
        align-items: flex-end;
    }

    &-streetNumberField,
    &-streetTypeField,
    &-zipCodeField {
        margin-right: 15px;
    }

    &-streetNumberField,
    &-streetTypeField {
        flex-basis: 35%;
    }

    &-zipCodeField {
        flex-basis: 45%;
    }

    &-streetNumberSuffixField {
        flex-grow: 1;
    }
}
</style>
